
.CalendarDay {
    position: relative;
}
.CalendarDay__selected {
    color: $color-white;
}

.CalendarDay__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &__price {
        font-family: var(--primary-font);
        font-size: 10px;
        line-height: 120%;
        font-weight: 600;
        color: var(--color-subtle-grey);
        // max-width: 44px;
        // display: inline-block;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
    }

    &.CalendarDay__inner-disabled {
        cursor: default;
    }
}

.CalendarDay__selected_start {
    // background: #EEEEF0;
    border-radius: 50% 0 0 50%;
    z-index: 2;
    background-color: transparent;

    .CalendarDay__inner {
        border-radius: 50%;
        // width: 100%;
        // height: 100%;
        background: $color-vibrant-pink;

        &__price {
            color: var(--color-white);
        }
    }

    + .CalendarDay__hovered_span,
    + .CalendarDay__selected_span,
    + .CalendarDay__selected_end {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-100%, 0);
            height: 100%;
            width: 50%;
            background-color: var(--color-shade-gray);
        }
    }
}

.CalendarDay__selected_start.CalendarDay__lastDayOfWeek {
    background: unset;
}

.CalendarDay__selected_end {
    background: #EEEEF0;
    border-radius: 0 50% 50% 0;

    .CalendarDay__inner {
        border-radius: 50%;
        // width: 100%;
        // height: 100%;
        background: $color-vibrant-pink;

        &__price {
            color: var(--color-white);
        }
    }
}

.CalendarDay__selected_end.CalendarDay__firstDayOfWeek {
    background: unset;
}

.CalendarDay__hovered_span.CalendarDay__lastDayOfWeek {
    border-radius: 0 4px 4px 0;
}
.CalendarDay__hovered_span.CalendarDay__firstDayOfWeek {
    border-radius: 4px 0 0 4px;
}
.CalendarDay__hovered_span.CalendarDay__firstDayOfWeek:hover {
    border-radius: 4px;
}
.CalendarDay__hovered_span.CalendarDay__hovered_span:hover {
    border-radius: 0 50% 50% 0;
}

.CalendarDay__selected_span:not(.CalendarDay__lastDayOfWeek) .CalendarDay__end-of-month,
// .CalendarDay__selected_start:not(.CalendarDay__lastDayOfWeek) .CalendarDay__end-of-month,
.CalendarDay__hovered_span:not(.CalendarDay__lastDayOfWeek):not(.CalendarDay__hovered_span:hover) .CalendarDay__end-of-month {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, #EEEEF0, #eeeef000);
}

.CalendarDay__selected_span:not(.CalendarDay__firstDayOfWeek) .CalendarDay__start-of-month,
.CalendarDay__hovered_span:not(.CalendarDay__firstDayOfWeek) .CalendarDay__start-of-month,
.CalendarDay__selected_end:not(.CalendarDay__firstDayOfWeek) .CalendarDay__start-of-month {
    position: absolute;
    top: 0;
    right: 100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, #eeeef000, #EEEEF0);
}

.CalendarDay__selected_start:hover + .CalendarDay__hovered_span:not(.CalendarDay__blocked_minimum_nights) {
    border-radius: 0 4px 4px 0;
    .CalendarDay__end-of-month {
        display: none;
    }
}
// .CalendarDay__default:hover:not(.CalendarDay__hovered_span):not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__blocked_calendar):not(.CalendarDay__selected_span):not(.CalendarDay__selected) {
//     background: #EEEEF0;
//     border-radius: 50%;
// }

.CalendarDay__default:hover {
    &:not(.CalendarDay__hovered_span) {
        &:not(.CalendarDay__blocked_out_of_range) {
            &:not(.CalendarDay__blocked_calendar) {
                &:not(.CalendarDay__selected_span) {
                    &:not(.CalendarDay__selected) {
                        &:not(.CalendarDay__blocked_minimum_nights):not(.CalendarDay__selected_span) {
                            background: #EEEEF0;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}
.CalendarDay__blocked_calendar:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end),
.CalendarDay__blocked_out_of_range:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
    color: $color-subtle-grey;
    &:hover {
        color: $color-subtle-grey;
    }

    .CalendarDay__inner {
        cursor: default;
    }
}

.CalendarDay__blocked_out_of_range:not(.CalendarDay__selected_start):hover,
.CalendarDay__blocked_calendar:not(.CalendarDay__selected_start):hover,
.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected_span):not(.CalendarDay__selected_start):hover {
    background: unset;

    .CalendarDay__inner {
        cursor: default;
    }
}

.CalendarDay__today {
    color: $color-vibrant-pink;
    &:hover {
        color: $color-vibrant-pink;
    }
    &.CalendarDay__selected {
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
}
