//---------MOBILE---------
.cn__cnt__showcase{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #242526;
    &__imgmobile{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity .6s ease;
        filter: brightness(0.6);
    }
    &__info{
        z-index: 1;
        padding: 0 20px;
        font-weight: 700;
        text-align: center;
        pointer-events: none;
        &__title{
            font-size: 42px;
            line-height: 46px;
            letter-spacing: -0.02em;
            font-weight: 700;
            color: #FFFFFF;
            text-transform: capitalize;
        }
        &__subtitle{
            font-size: 18px;
            line-height: 24px;  
            font-weight: 700; 
            letter-spacing: -0.01em; 
            color: rgba(255, 255, 255, 0.6);
            margin-top: 20px;
        }
    }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
    .cn__cnt__showcase {
        &__imgdesktop{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
            transition: opacity .6s ease-in-out;
            filter: brightness(0.6);
        }
        .cnt__img--active{
            opacity: 1;
        }
        &__info{
            margin-top: -25vh;
            padding: 0;
            &__title{
                font-size: 86px;
                line-height: 90px;
            }
            &__subtitle{
                font-size: 18px;
                line-height: 32px;
                margin-top: 5px;
            }
        }
        &__slider{
           position: absolute;
           width: 100%;
           max-width: 1200px;
           padding: 0 30px;
           bottom: 30px;
           z-index: 1;
           &__loader{
                position: relative;
                width: 100%;
                height: 2px;
                background: rgba(255, 255, 255, 0.4);
                &__line{
                    position: absolute;
                    height: 100%;
                    width: 0%;
                    background: #FFFFFF;
                }
           }
           &__options{
               display: grid;
               grid-template-columns: 1fr 1fr 1fr 1fr;
               grid-column-gap: 20px;
                &__option{
                    cursor: pointer;
                    margin-top: 27px;
                    &__title{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px; 
                        color: rgba(255, 255, 255, 0.6);
                        transition: color 0.6s ease;
                    }
                    &__subtitle{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(255, 255, 255, 0.6);
                        margin-top: 11px;
                        transition: color 0.6s ease;
                    }
                }
                .cnt__opt--active{
                    .cn__cnt__showcase__slider__options__option__title, .cn__cnt__showcase__slider__options__option__subtitle{
                        color: rgba(255, 255, 255, 1);
                    }
                }
           }
       }
    } 
}
