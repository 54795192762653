.btn-common {
   font-family: $primary-font;
   font-size: 1rem;
   font-weight: 400;
   line-height: 2.375rem;
   text-align: center;
   height: 2.375rem;
   padding: 0 0.9375rem;
   cursor: pointer;
   border: none;
   border-radius: 0.375rem;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
   text-decoration: none;
   outline: none;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   min-width: 10rem;
   &.large-btn {
      min-width: 17.5rem;
   }
   i {
      font-style: normal;
  }
   img,svg {
      max-height: 1.125rem;
      height: auto;
      width: auto;
      max-width: 0.625rem;
      margin: 0 0.625rem 0 0;
      &.on-hover {
         display: none;
      }
   }
   &:hover, &:focus {
      text-decoration: none;
      outline: none;
      svg {
           path{
              stroke:$color-primary ;
           }
         }
      img {
         display: none;
         &.on-hover {
            display: block;
         }
      }
   }
   @media screen  and (max-width: 600px) {
      height: 2.875rem;
      line-height: 2.875rem;
      border-radius: 0.5rem;
   }
   &.disabled,
   &:disabled {
      cursor: default;
      background: $color-subtle-gray !important;
      color: $color-white;
   }
}