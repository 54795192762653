.wishlist-enabled {
    .MuiDrawer-modal {
        visibility: hidden;
    }
}

@mixin imgBroken($class,$color,$border) {
    #{$class}:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $border;
        background: url('../../../public/static/img/broken-image.svg') no-repeat center center;
        background-size: 16px;
        background-color: $color;
        content: '';
        z-index: -1;
    }
}



.cn__save-wishlist__parent{
.cn__save-wishlist-wrapper{ // Parent element (overlay)
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 1201;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 600px){
        z-index: 1202;
    }
    .cn__save-wishlist{ // Wishlist Element
        position: relative;
        width: 466px;
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 20px 30px 20px;
        transform: translateY(-98vh);
        transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
        .cn__save-wishlist__close{ // Close Button
            cursor: pointer;
        }
        .cn__save-wishlist__info{ // First section including title and item information
            margin-top: 6px;
            .cn__save-wishlist__info__title{ // Save to wishlist title
                font-size: 22px;
                line-height: 26px;
                font-weight: 500;
                text-align: center;
            }
            .cn__save-wishlist__info__selected{ // Section info (where comes the information from the item selected)
                display: flex;
                margin-top: 25px;
                .cn__save-wishlist__info__selected__image{ // Acommodation Image
                    position: relative;
                    border-radius: 10px;
                    width: 137px;
                    height: 92px;
                    margin-right: 10px;
                }
                @include imgBroken('.cn__save-wishlist__info__selected__image',#9888EA,10px);
                .cn__save-wishlist__info__selected__info{ // Acommodation Info
                    width: 279px;
                    position: relative;
                    .cn__save-wishlist__info__selected__info__title{ // Acommodation description
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .cn__save-wishlist__info__selected__info__subtitle{ // Acommodation location
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        color:#797882;
                        text-transform: uppercase;
                        margin-top: 5px;
                    }
                }
            }
            .cn__save-wishlist__info__button{
                position: relative;
                height: 38px;
                margin-top: 20px;
                border: none;
                outline: none;
                text-decoration: none;
                -webkit-appearance: none;
                font-size: 16px;
                background: transparent;
                line-height: 20px;
                font-weight: 400;
                color: #4C35BC;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
        .cn__save-wishlist__lists{ // Lists Section
            .cn__save-wishlist__lists__divider{ // Lists Divider
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 28px;
                .cn__save-wishlist__lists__divider__line{ // Divider line
                    width: 100%;
                    height: 1px;
                    background: #DCDADC;
                }
                .cn__save-wishlist__lists__divider__title{ // Divider title (Your lists)
                    position: absolute;
                    background: #ffffff;
                    color: #ACABB4;
                    padding: 0 6px;
                }
            }
            .simplebar-scrollbar:before{
                width: 4px;
                background: #ACABB4;
                left: auto;
                right: 0;
            }
            .cn__save-wishlist__lists__list{ // List item
                margin-top: 10px;
                display: flex;
                @media screen and (max-width: 600px){
                    margin-top: 0;
                }
                .box {// Checkbox element
                    font-family: $primary-font;
                    font-size: 16px;
                    grid-column: 2;
                    grid-row: 2;
                    justify-self: center;
                    align-self: center;
                    display: flex;
                    flex-direction: column;
                  }

                .item {
                    padding: 10px;
                    border-radius: 6px;
                    transition: background 0.3s ease;
                    cursor: pointer;
                    display:flex;
                    align-items: center;
                    @media screen and (max-width: 600px){
                        display: flex;
                        justify-content: space-between;
                        padding: 10px 20px 10px 10px;
                    }
                }

                @media screen and (min-width: 1136px){
                    .item:hover {
                        background: #EEEEF0;
                    }
                }

                .item > span {
                    display: inline-block;
                    vertical-align: middle;
                }

                .item > span:first-child {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    box-shadow: inset 0 0 0 1px #B3B1B4;
                    border-radius: 4px;
                    transition: all 0.2s ease;
                }

                .item > span:first-child > svg {
                    position: absolute;
                    top: 5px;
                    left: 3px;
                    stroke-dasharray: 20px;
                    stroke-dashoffset: 20px;
                }
                .item > span:last-child {
                    margin-left: 10px;
                    width: calc(100% - 30px);
                }
                @media screen and (min-width: 1136px){
                    .item:hover > span:first-child {
                        box-shadow: inset 0 0 0 1px #4C35BC;
                    }
                }

                input {
                    display: none;
                }

                .input-chkbox:checked + .item > span:first-child {
                    box-shadow: none;
                }

                .input-chkbox:checked + .item > span:first-child > svg {
                    stroke-dashoffset: 0;
                    transition: all .2s ease;
                }

                .input-chkbox:checked + .item > span:last-child {
                    font-weight: 500;
                }
            }
        }
        .cn__save-wishlist__controls{ // Wishlist button (cancel and save)
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            border-top: 1px solid #E9E9EB;
            padding-top: 25px;
            button {
                border: none;
                outline: none;
                text-decoration: none;
                -webkit-appearance: none;
                background: none;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: #242526;
                cursor: pointer;
            }
            .cn__save-wishlist__controls--save{ // Save button styling
                font-weight: 500;
                color: #F35597;
                &:disabled{
                    color:#ACABB4;
                    cursor: auto;
                }
            }
        }
    }
    // Position classes
    .cn__wl-pos-r{ // Position to right
        display: flex;
        justify-content: flex-end;
    }
}


.cn__input{ // Wishlist Input
    position: relative;
    margin-top: 20px;
    .field { // Parent Element
        display: flex;
        flex-direction: column;
    }
    label { // New list name label
        position: absolute;
        order: -1;
        left: 11px;
        transition: all 0.3s ease;
        transition-property: top, font-size;
        top: 8px;
        padding: 0 4px;
        pointer-events: none;
        color: #797882;
        will-change:top,font-size;
    }
    button{ // Create button
        position: absolute;
        right: 6px;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        text-decoration: none;
        -webkit-appearance: none;
        background: none;
        padding: 5px;
        font-size: 12px;
        line-height: 10px;
        color: $color-white;
        border-radius: 6px;
        cursor: pointer;
        display: inline-flex;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        img{ // Return image icon
            margin-left: 10px;
        }
        &:hover{
            background: none;
        }
    }
    input{
        font-family: $primary-font;
        font-weight: 400;
        background: #E9E9EB;
        border-radius: 6px;
        padding: 10px 78px 10px 15px;
        &:focus{ // When user enters the input
            outline: none;
            background: #ffffff;
            border: 1px solid #ACABB4;
        }
        &:not(:placeholder-shown){ // If the input is not empty
            outline: none;
            background: #ffffff;
            border: 1px solid #ACABB4;
        }
        &::placeholder{ // Input placeholder styling
            color: transparent;
        }
    }
    input:focus + label, input:not(:placeholder-shown) + label { // Position the label to the top
        top: -6px;
        background: #ffffff;
        font-size: 12px;
    }

    input:not(:placeholder-shown) + label{ // Show the button when the user starts typing
        & + button {
            visibility: visible;
            opacity: 1;
        }
    }
    .cn__input__alert{
        margin-top: 5px;
        margin-left: 15px;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #F44A3A;
        opacity: 0;
        display: none;
        transition: all 0.3s ease;
    }
}
}

@media screen and (max-width: 600px) {
    .cn__save-wishlist__parent{
    .cn__save-wishlist-wrapper{ // Parent element (overlay)
        height: 100%;
        opacity: 1;
        background: transparent;
        transition: none;
        .cn__save-wishlist{ // Wishlist Element
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 0;
            transform: translate(100%, 0);
            .cn__save-wishlist__wrapper__mobile{
                position: relative;
                width: 100%;
                height: calc(100% - 71px);
                overflow: auto;
                padding: 0px 20px 30px 20px;
                .cn__save-wishlist__info__top{
                    position: sticky;
                    top: 0;
                    background: #ffffff;
                    padding: 20px 0;
                    z-index: 5;
                }
            }
            .cn__save-wishlist__scroll-wrapper{
                position: relative;
                width: 100%;
                height: calc(100% - 71px);
                width: 100%;
                overflow-y: scroll;
                overflow-x: hidden;
            }
            .cn__save-wishlist__close{ // Close Button
                cursor: pointer;
                width: 32px;
                position: relative;
                left: 8px;
            }
            .cn__save-wishlist__info__top{
                .cn__save-wishlist__info__title{ // Save to wishlist title
                    font-size: 22px;
                    line-height: 26px;
                    font-weight: 500;
                    text-align: center;
                    margin-top: -16px;
                }
            }
            .cn__save-wishlist__info__selected__image{ // Acommodation Image
                position: relative;
                border-radius: 15px;
                width: 100%;
                height: 211px;
                margin-right: 0;
            }
            @include imgBroken('.cn__save-wishlist__info__selected__image',#9888EA,15px);
            .cn__save-wishlist__info{ // First section including title and item information
                margin-top: 0;
                position: sticky;
                margin-top: 0;
                top: 61px;
                z-index: 5;
                background: #ffffff;
                padding-bottom: 15px;
                border-bottom: solid 1px #DCDADC;
                .cn__save-wishlist__info__selected{ // Section info (where comes the information from the item selected)
                    flex-direction: column;
                    margin-top: 0px;
                    .cn__save-wishlist__info__selected__info{ // Acommodation Info
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }
            .cn__save-wishlist__lists{ // Lists Section
                .cn__save-wishlist__lists__divider{ // Lists Divider
                    display:none;
                }
                .simplebar-scrollbar:before{
                    width: 4px;
                    background: #000000;
                    left: auto;
                    right: 2px;
                    opacity: 0.8;
                }
                .list__first{
                    margin-top: 10px;
                }
                .cn__save-wishlist__lists__list{ // List item
                    .cn_slct_box{ // Checkbox element
                        .label{ // list name
                            margin-left: 16px;
                        }
                        .cn_checkmark{
                            margin-right: 16px;
                        }
                    }
                }
            }
            .cn__save-wishlist__controls{ // Wishlist button (cancel and save)
                margin-top: 0;
                border-top: 1px solid #E9E9EB;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                padding: 25px 20px;
                position: fixed;
                bottom: 0;
                width: 100%;
                left: 0px;
            }
        }
    }
    .cn__input{ // Wishlist Input
        margin-top: 25px;
        label { // New list name label
            margin-left: 0;
        }
    }
    }
}

.block-scroll {
    overflow: hidden !important;
}