.cn__card4{
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: width .3s ease;
    height: calc(var(--card-width) + (var(--card-width) * 0.25));

    &__img{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        .cn__image-default{
            transition: all 1s ease;
            border-radius: 16px;
        }

        &__info{
            position: absolute;
            width: 100%;
            padding: 0 20px;
            bottom: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            opacity: 1;
            transition: opacity .6s ease;
            &__title{
                font-weight: 700;
                font-size: 23px;
                line-height: 1.2;
                color: #fff;
            }
            &__subtitle{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $color-white;
                letter-spacing: 0.01em;
            }
        }
    }

    &.cnt__card4-current{
        width: calc(((var(--card-width) - var(--card-gap)) * 2) + var(--card-gap));
        .cn__card4__img__info{
            opacity: 1;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__card4{

        width: 215px !important;
        height: 300px;

        &__img{
            &__info{
                opacity: 1;
            }
        }

        &.cnt__card4-current{
            width: 215px !important;
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__card4{

        /* width: calc(50vw - 25px) !important;
        height: 68.534vw; */
        width: 163px !important;
        height: 167px;
        border-radius: 10px;
        .cn__card4__img .cn__image-default {
            border-radius: 10px;
        }

        &__img{
            border-radius: 10px;
            &__info{
                padding: 0 13px;
                &__title{
                    font-size: 18px;
                    line-height: 1;
                    font-weight: 800;
                }
                &__subtitle{
                    font-size: 12px;
                    margin-top: 3px;
                    font-weight: 500;
                    &__price{
                        font-weight: 700;
                    }
                }
            }
        }

        &.cnt__card4-current{
            width: 163px !important;
        }
    }
}
