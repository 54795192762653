//-------------- DESKTOP --------------

.cn__gp__weather{

    position: relative;
    width: 100%;
    margin: 100px auto;
    min-height: 285px;
    background: rgba(244, 74, 58, 0.1);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__info{
        width: auto;
        &__title{

            font-weight: 700;
            font-size: 23px;
            line-height: 1.2;
            letter-spacing: -0.01em;
            color: #000000;
            opacity: 0;
            transform: translate(0,15px);

        }
        &__description{
            display: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #242526;
            margin-top: 10px;
            opacity: 0;
            transform: translate(0,15px);

        }
    }
    &__temp{
        position: relative;
        width: 705px;
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
        opacity: 0;
        margin-top: 50px;
        &__col{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            margin-right: 5%;
            &:first-child{
                margin-right: 2.5%;
            }
            &:last-child{
                margin-right: 0;
            }
            .weather__temp__bar{
                position: relative;
                width: 14px;
                height: 115px;
                &--bar{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: #242526;
                    border-radius: 4px;
                    transition: height 1s ease;
                }
            }
            .weather__temp__month{
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #242526;
                margin-top: 15px;
            }
            .weather__temp__max, .weather__temp__min{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #242526;
            }
            .weather__temp__max{
                margin-top: 11px;
            }
            .weather__temp__min{
                margin-top: 14px;
            }
            .weather__temp__day{
                position: relative;
                top: -3px;
            }
            .weather__temp__night{
                position: relative;
                top: 3px;
            }
        }
    }
}

//-------------- TABLET --------------


@media screen and (max-width:1136px){
    .cn__gp__weather{
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        margin: 80px auto;

        &__info{
            width: auto;
            padding-right:0;
            &__title{
                text-align: center;
                font-size: 22px;
            }
        }
        &__temp{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-shrink: unset;
            &__col{
                margin-right: unset;
                &:first-child{
                    margin-right: unset;
                }
            }
        }
    }
}

//-------------- MOBILE --------------

@media screen and (max-width:600px){
    .cn__gp__weather{
        width: calc(100% - 40px);
        padding: 30px 20px;
        margin: 60px auto;

        &__temp{
            margin-top: 50px;
            &__col{
                .weather__temp__bar{
                    width: 7px;
                }
                .weather__temp__month{
                    font-size: 14px;
                    margin-top: 8px;
                }
                .weather__temp__max, .weather__temp__min{
                    font-size: 2.3vw;
                }
                .weather__temp__max{
                    margin-top: 10px;
                }
            }
        }
    }
}