//------------------- DESKTOP -------------------

.cn__hp__download__wrapper{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    z-index: 0;

    .cn__hp__download{
        position: relative;
        width:100%;
        height: 401px;
        background: linear-gradient(95.99deg, #E8EDED 0%, #F4F4F4 100%);
        padding: 44px 60px;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 20px;

        &__info{
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 502px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 1;

            &__title{
                font-weight: bold;
                font-size: 44px;
                line-height: 53px;
                color: #0F294D;
                font-feature-settings: unset;
                font-variant: unset;
                text-transform: unset;
            }
            &__subtitle{
                width: 65%;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.01em;
                color: #0F294D;
                margin-top: 25px;
            }
            &__buttons{
                display: flex;
                align-items: baseline;
                margin-top: 40px;
                a{
                    &:first-child{
                        margin-right: 20px;
                    }
                }
                img{
                    height: 40px;
                    cursor: pointer;
                    margin-left: 10px;
                }
                &__qr{
                    width: 93px;
                    height: auto !important;
                    cursor: auto !important;
                    margin-left: 0 !important;
                }
                &__get{
                    position: absolute;
                    left: 103px;
                    bottom: 58px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: 0.01em;
                    color: #64748B;
                }
            }
        }
        &__mobile{
            position: absolute;
            height: 100%;
            right: 0;
        }
    }
}

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__download__wrapper{
        padding: 0 30px;
        margin: 100px auto;
        .cn__hp__download{
            padding: 34px 60px;
    
            &__info{
                width: 59%;
                &__title{
                    font-size: 28px;
                    line-height: 120%;
                    font-weight: 700;
                }
                &__subtitle{
                    width: 328px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 120%;
                    margin-top: 16px;
                }

                &__buttons{
                    img{
                        height: 32px;
                        margin-left: 10px;
                    }
                    &__qr{
                        display: none;
                    }
                    &__get{
                        left: 0px;
                        bottom: 50px;
                        font-size: 12px;
                        line-height: 18px;
                    }
                    &__apple{
                        img{
                            margin-left: 0px;

                        }
                    }
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__download__wrapper{
        padding: 0 20px;
        margin: 70px auto;

        .cn__hp__download{
            padding: 6vw 7vw;
            height: 77.6vw;
    
            &__info{
                width: 50%;

                &__title{
                    position: relative;
                    top: 5vh;
                    font-size: 28px;
                    line-height: 1.2;
                }
                &__subtitle{
                    display: none;
                }
    
                &__buttons{
                    img{
                        height: 5.5vw;
                        margin-left: 2vw;
                    }
                    &__apple{
                        img{
                            margin-left: 0 !important;
                        }
                    }
                    &__qr{
                        display: none;
                    }
                    &__get{
                        left: 0;
                        bottom: 8.5vw;
                        font-size: 8.75px;
                        line-height: 1;
                    }
                }
            }
            &__mobile{
                right: 0%;
            }
        }
    }
}