.popularhl-box{
    background: $color-black;  
    border-radius:0.625rem;
    max-width:20.563rem;
    width: 100%;
    font-size: 0.938rem;
    line-height: 1.313rem;  
    color: $color-white;
    position: relative;
    z-index: 1;
    min-height: 23.688rem;
    .popularhl-content{ 
        padding: 1.25rem;
        display: grid;
        position: relative;
        min-height: 23.688rem;
        grid-template-rows: 1fr 40px;
        h5{
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.875rem;    
            margin: auto;
         }
         a{
             color: $color-white;
             span{
                color:$color-primary;
            }
         }
        .popularhl-link{
            text-align: center;
        }
    } 
}

.popularhl-box-nolink{
    background: $color-black;  
    border-radius:0.625rem;
    max-width:24.625rem;
    width: 100%;
    font-size: 0.938rem;
    line-height: 1.313rem;  
    color: $color-white;
    position: relative;
    z-index: 1; 
    .popularhl-content-nolink{ 
        padding: 1.25rem 3.125rem;
        display: grid;
        position: relative;
        min-height: 16rem;
        grid-template-rows: 1fr;
        h5{
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.438rem;    
            margin: auto;
            text-align: center;
         }  
    } 
}