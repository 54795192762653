@import '/src/styles/settings/colors';

.page-new-property {
    @include tablet {
        .header .header-grid {
            grid-template-columns: 0px repeat(7, 1fr 20px) 1fr 50px;
        }
        .header-nav {
            .li--blog,
            .li--currency,
            .li--wishlist,
            .li--listproperty {
                display: none !important;
            }
        }
    }
    @include mobile {
        main {
            height: 100%;

            .wizard {
                &.wizard--mobile-mode {
                    height: 100%;
                }
            }
        }
    }
    main {
        >.wizard {
            background: $color-white;
            position: relative;
            width: 100vw;
            height: 100vh;
            .icon-logo {
                cursor: pointer;
            }
            .wizard__steps {
                li {
                    color: var(--color-subtle-grey);
                    label {
                        // pointer-events: none;
                        .ic-icon {
                            path,
                            circle {
                                // stroke: var(--color-subtle-grey);
                            }
                        }
                    }
                    &.active,
                    &.prev,
                    &.passed {
                        color: var(--color-black);
                        pointer-events: initial;
                        label {
                            pointer-events: initial;
                            .ic-icon {
                                path,
                                circle,
                                rect {
                                    // stroke: var(--color-black);
                                }
                            }
                        }
                    }
                    &.active {
                        background-color: rgba(232, 105, 147, 0.15);
                    }
                    &.disabled {
                        pointer-events: none;
                    }
                    &.disabled:hover {
                        cursor: default;
                    }
                    // &:not(.prev) {
                    //     label {
                    //         .ic-icon {
                    //             path,
                    //             circle,
                    //             rect {
                    //                 fill: var(--color-subtle-grey);
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
            .wizard__footer {
                .n-btn--wizard-save {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                }
                .n-btn--wizard-continue {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    // color: $color-cn-pink;
                }
                .n-btn--wizard-continue:disabled {
                    color: $color-gray-hp;
                }
                .n-btn--wizard-skip {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                }
                .n-btn--wizard-save,
                .n-btn--wizard-back,
                .n-btn--wizard-continue {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .wizard__container {
                .header {
                    position: relative;
                    padding: 14px 0;
                    margin-bottom: 36px;
                    @include onlyTablet {
                        margin: 0 -20px 36px;
                        border-bottom: 1px solid var(--color-light-grey);
                        width: calc(100% + 40px);
                    }
                }
            }
            &.wizard--loading {
                .wizard__content {
                    opacity: 0;
                }
            }
        }
    }
    .section--white {
        padding: 20px;
        border-radius: 15px;
        background: var(--color-white);
        margin-bottom: 20px;
    }
    .lnp-radiokbox {
        margin-bottom: 50px;
        padding-right: 16px;
    }
    .lnp-checkbox {
        margin-bottom: 50px;
        padding-right: 16px;
        >strong {
            padding-left: 13px;
            margin-bottom: 5px;
        }
        >ul {
            li {
                height: 54px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .n-checkbox {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    >label {
                        padding: 7px 10px;
                        width: 100%;
                        >.text {
                            >span:first-child {
                                padding-left: 5px;
                            }
                            small {
                                padding: 2px 5px;
                                &:hover {
                                    background: #D4D3D8;
                                    border-radius: 6px;
                                }
                            }
                        }
                    }
                    .actions {
                        padding: 10px 13px;
                        .n-btn--fee {
                            width: 34px;
                            height: 34px;
                            padding: 0px;
                            justify-content: center;
                            align-items: center;
                            >.ic-icon {
                                margin: 0px;
                            }
                            &:hover {
                                background: red;
                                border-radius: 8px;
                            }
                        }
                    }
                }
                .n-tooltip__inner {
                    width: auto;
                    padding: 5px 10px;
                    background: var(--color-dark-grey);
                    white-space: nowrap;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .input-group {
        margin-bottom: 50px;
        &--cancellation-policy {
            &>strong {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                color: var(--color-black);
                font-variant: all-small-caps;
                font-size: 23px;
                margin-bottom: 20px;
                display: block;
            }
        }
    }
    .section--lnp-property-type {
        height: 100%;
        .breadcrumbs {
            margin: 0;
            // margin-bottom: 10px;
            background: $color-light-gray-shade;
            // background: var(--color-light-grey);
            padding: 20px;
            border-radius: 15px;
            max-width: 100%;
            overflow-x: hidden;
            ul::-webkit-scrollbar {
                display: none;  /* Safari and Chrome */
            }
            ul {
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */
            }
            ul {
                white-space: nowrap;
                display: flex;
                overflow-y: hidden;
                overflow-x: auto;
                .breadcrumbs__item {
                    display: inline-flex;
                    float: unset;
                    padding: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .breadcrumbs__text {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        color: $color-purple;
                        margin-right: 15px;
                        .star-rate {
                            height: 20px;
                            display: flex;
                            .ic-icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: auto;
                                height: auto;
                                svg {
                                    path {
                                        fill: $color-purple;
                                    }
                                }
                            }
                        }
                        &::before {
                            display: block;
                            content: attr(data-text);
                            // content: attr(data-text) / "";
                            height: 0;
                            visibility: hidden;
                            overflow: hidden;
                            user-select: none;
                            pointer-events: none;
                            font-weight: 600;
                        }
                    }
                    .breadcrumbs__text:hover {
                        cursor: pointer;
                    }
                    .breadcrumbs__arrow {
                        display: flex;
                        margin-right: 15px;
                    }
                    .breadcrumbs__arrow:hover {
                        cursor: default;
                    }
                    &--active {
                        .breadcrumbs__text {
                            font-weight: 600;
                        }
                        .breadcrumbs__text:hover {
                            cursor: default;
                            pointer-events: none;
                        }
                    }
                    &--disabled {
                        cursor: default;
                        pointer-events: none;
                        .breadcrumbs__text {
                            color: var(--color-dark-grey);
                        }
                    }
                    &:after {
                        content: none;
                    }
                }
                li:hover {
                    cursor: default;
                }
            }
            ul:hover {
                cursor: default;
            }
        }
        .prop-cards {
            display: grid;
            grid-template-columns: repeat(3, minmax(280px, 320px));
            // grid-template-columns: repeat(auto-fill, minmax(200px, 30%));
            grid-auto-rows: 354px;
            // grid-auto-rows: minmax(220px, 320px);
            justify-content: center;
            column-gap: 20px;
            row-gap: 20px;
            &.prop-cards-w-descr {
                grid-template-columns: 320px 320px;
                grid-auto-rows: minmax(220px, 415px);
            }
        }
        .prop-card {
            border: 1px solid $color-subtle-grey;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            padding: 26px 26px 0 26px;
            transition: all 0.3s ease;
            &.selected {
                border: 1px solid $color-cn-pink;
            }
        }
        .prop-card:hover {
            cursor: pointer;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            transform: translateY(-5px);
        }
        .prop-card-img {
            position: relative;
            flex: 3;
            border-radius: 11px;
            overflow: hidden;
        }
        .prop-card-info {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .prop-card-info__title {
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
        }
        .prop-card-info__description {
            font-size: 14px;
            line-height: 150%;
            font-weight: 600;
            padding-bottom: 26px;
            color: $color-gray-hp;
        }
        .prop-cards-w-descr {
            .prop-card-img {
                flex: 0 0 45%;
                // flex: 1;
            }
            .prop-card-info {
                flex: 1 0 55%;
            }
            .prop-card-info {
                padding-top: 20px;
                flex-direction: column;
                justify-content: unset;
                align-items: unset;
            }
            .prop-card-info__title {
                margin: 16px 0;
            }
        }
        @media screen and (max-width: 900px) {
            .prop-cards {
                grid-template-columns: repeat(2, minmax(280px, 320px));
                grid-auto-rows: 354px;
                // grid-auto-rows: minmax(220px, 320px);
                &.prop-cards-w-descr {
                    grid-template-columns: repeat(2, minmax(220px, 320px));
                    grid-auto-rows: minmax(220px, 360px);
                }
            }
        }
        @media screen and (max-width: 700px) {
            .prop-cards {
                &.prop-cards-w-descr {
                    grid-template-columns: repeat(2, minmax(220px, 320px));
                    grid-auto-rows: minmax(220px, 400px);
                }
            }
        }
        @media screen and (max-width: 600px) {
            .prop-cards {
                grid-template-columns: 1fr;
                grid-auto-rows: 60px;
                justify-content: center;
                column-gap: 16px;
                row-gap: 16px;
                padding-bottom: 16px;
                &.prop-cards-w-descr {
                    grid-template-columns: 1fr;
                    grid-auto-rows: 60px;
                }
                .prop-card {
                    padding: 6px;
                    flex-direction: row;
                    .prop-card-img {
                        flex: 0 0 20%;
                    }
                    .prop-card-info {
                        padding-top: 0;
                        flex: 0 0 80%;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        .prop-card-info__title {
                            font-size: 14px;
                            line-height: 150%;
                            font-weight: 500;
                            margin: 0;
                            margin-left: 10px;
                        }
                        .prop-card-info__description {
                            display: none;
                        }
                    }
                }
            }
        }
        &.lnp-property-type-mob {
            .n-input-guest-room-picker {
                max-width: unset;
                height: 46px;
                .input-value {
                    height: 100%;
                    align-items: center;
                }
            }
            .form__row {
                .form--area-input {
                    .n-input-text_suffix {
                        top: 0;
                        right: 0;
                    }
                    .n-input-text_suffix {
                        .n-select-picker {
                            border: none !important;
                            background: transparent;
                            position: absolute;
                            width: 80px;
                            right: 0;
                            top: 0;
                            .input-value {
                                padding: 9px 10px;
                            }
                            .input-value>.ic-icon {
                                height: unset;
                                width: 40px;
                            }
                            .input-dropdown {
                                max-width: 60px;
                                overflow-y: hidden;
                                .n-radiobox-list>ul li .n-radiobox>label {
                                    flex-direction: row-reverse;
                                    .text {
                                        background-color: transparent;
                                        transform: none;
                                    }
                                }
                            }
                            .n-radiobox>label {
                                input {
                                    padding: 0px;
                                    width: 3px;
                                }
                                >.icon-radiobox {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }            
        }



        h2 {
            font-family: $primary-font;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: center;
            margin-bottom: 20px;
            // margin-bottom: 25px;
        }
        .bx-picker--property-type {
            .n-box-item {
                max-width: 320px;
                margin: 0px;
                min-height: 150px;
                @include mobile {
                    min-height: auto;
                }
                @include tablet {
                    min-height: 150px;
                }
                strong {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                    margin-bottom: 10px;
                }
            }
        }
        .n-box-picker {
            max-width: 1000px;
            margin: auto;
        }
        .progress-wizard {
            // padding-top: 20px;
            // margin-top: 20px;
            height: calc(100% - 60px);
            // height: calc(100vh - 280px);
        }
        .progress-wizard__content {
            height: 100%;
            .progress-wizard__content__item {
                &:first-child {
                    top: 0px;
                    bottom: auto;
                    transition: none;
                    &.past {
                        height: 0%;
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                &:nth-child(2) {
                    &.active {
                        height: 100%;
                    }
                }
            }
        }
        .progress-wizard__content__itm {
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
            transition: opacity 0.3s ease;
            pointer-events: none;
            padding: 20px 0;
            // padding-bottom: 20px;
            .form--area {
                .error {
                    position: absolute;
                    color: var(--color-red);
                    font-size: 0.75rem;
                    line-height: 0.75rem;
                    top: 42px;
                    left: 15px;
                }    
            }
            &.current {
                overflow-y: auto;
                pointer-events: auto;
                opacity: 1;
                scrollbar-gutter: stable both-edges;
            }
            &.ln-property-wizard--step-star {
                .n-box-picker {
                    .n-box-item {
                        border: 1px solid $color-subtle-grey;
                        &.selected {
                            border: 1px solid $color-cn-pink;
                        }
                        strong {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .progress-wizard__content__item {
            h2 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
                margin-bottom: 25px;
            }
            .n-box-picker--common {
                .n-box-item {
                    strong {
                        margin: 0px;
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
            &.prev,
            &.past {
                h2 {
                    opacity: 0.25;
                }
                .n-box-picker {
                    .n-box-item {
                        background: transparent;
                        border: 1px solid var(--color-light-grey);
                        strong {
                            color: var(--color-subtle-grey);
                        }
                        &.selected {
                            background-color: var(--color-shade-grey);
                        }
                    }
                }
            }
            &.prev {
                height: 30%;
                bottom: 70%;
            }
            &.active {
                height: 70%;
                &.last {
                    height: 80%;
                    max-height: 80%;
                    overflow-y: auto;
                }
                &.ln-property-wizard--step-star {
                    height: 65%;
                    max-height: 65%;
                    overflow-y: auto;
                }
                &.ln-property-wizard--step-occupancy.last {
                    height: 65%;
                    max-height: 65%;
                    overflow-y: auto;
                }
            }
            .prev {
                opacity: 1;
            }
        }
        .form--number-of-roomtype {
            max-width: 350px;
            margin: auto;

            .form__row {
                display: grid;
                grid-template-columns: 1fr 100px;
                grid-column-gap: 10px;
            }
            .n-select-picker {
                .input-dropdown {
                    max-width: 100px;
                    padding: 5px;
                    transform: translateY(3px);
                    .n-radiobox-list {
                        li:hover {
                            background: none;
                        }
                        li {
                            .n-radiobox {
                                border-bottom: 0.5px solid #E9E9EB;
                                .icon-radiobox {
                                    display: none;
                                }
                                input {
                                    width: 0;
                                    height: 0;
                                }
                                .text {
                                    span {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                        li:last-child {
                            .n-radiobox {
                                border-bottom: none;
                            }
                        }
                        .n-radiobox:hover {
                            span.text {
                                span {
                                    // cursor: pointer;
                                    color: $color-cn-pink;
                                }
                            }
                        }
                    }
                }
                .input-dropdown::-webkit-scrollbar-track {
                    background: transparent;
                    background-color: transparent;
                }
                .input-dropdown:hover {
                    cursor: pointer !important;
                    * {
                        cursor: pointer !important;
                    }
                }
            }
        }
        .form--area {
            max-width: 350px;
            margin: auto;

            .form__row {
                display: grid;
                grid-template-columns: 1fr 100px;
                grid-column-gap: 10px;
                .n-input-text_suffix {
                    .n-select-picker {
                        border: none !important;
                        background: transparent;
                        position: absolute;
                        width: 80px;
                        right: 0;
                        top: 0;
                        .input-value {
                            padding: 9px 10px;
                        }
                        .input-value>.ic-icon {
                            height: unset;
                            width: 40px;
                        }
                        .input-dropdown {
                            overflow-y: hidden;
                            .n-radiobox-list>ul li .n-radiobox>label {
                                flex-direction: row-reverse;
                                .text {
                                    background-color: transparent;
                                    transform: none;
                                }
                            }
                        }
                        .n-radiobox>label {
                            input {
                                padding: 0px;
                                width: 3px;
                            }
                            >.icon-radiobox {
                                display: none;
                            }
                        }
                    }
                }
                >div {
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }
        }
        .InputGuestRoomPicker-wrapper {
            position: relative;
            .InputGuestRoomPicker-error {
                position: absolute;
                color: var(--color-red);
                font-size: 0.75rem;
                line-height: 0.75rem;
                top: 42px;
                left: 15px;    
            }
        }
        .n-input-guest-room-picker {
            max-width: 350px;
            margin: auto;
            .input-value {
                background: #E9E9EB;
                .text {
                    font-weight: 300;
                }
            }
            &.filled {
                border: 1px solid #E9E9EB;
                .input-value {
                    background: unset;
                }
            }
            .input-dropdown {
                display: none;
            }
            &.active {
                .input-dropdown {
                    display: block;
                }
            }
        }
        .form--roomtype-detail {
            background-color: var(--color-white);
            border-radius: 15px;
            // padding-bottom: 20px;
            padding: 0 16px 30px 16px;
            max-width: 960px;
            margin: auto;
            .form__row {
                height: 54px;
                padding-bottom: 16px;
                display: grid;
                // grid-template-columns: minmax(auto, 280px) 180px 1fr 180px;
                grid-template-columns: minmax(auto, 280px) 180px minmax(auto, 240px) 180px;
                grid-column-gap: 15px;
                &:last-child {
                    margin-bottom: 0px;
                }
                >div:last-child {
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
                .error {
                    position: absolute;
                    top: 42px;
                    left: 15px;
                    font-size: 0.75rem;
                    line-height: 0.75rem;
                }
            }
            .n-input-text {
                .n-input-text_suffix {
                    .n-select-picker {
                        width: 80px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        border: none !important;
                        background: transparent;
                        .input-value {
                            padding: 9px 10px;
                        }
                        .input-value>.ic-icon {
                            height: unset;
                            width: 40px;
                        }
                        .input-dropdown {
                            overflow-y: hidden;
                            .n-radiobox-list>ul li .n-radiobox>label {
                                flex-direction: row-reverse;
                            }
                        }
                        .n-radiobox>label {
                            input {
                                padding: 0px;
                                width: 3px;
                            }
                            >.icon-radiobox {
                                display: none;
                            }
                        }
                    }
                }
                input::placeholder {}
            }
            .n-input-text--focus {
                input::placeholder {
                    opacity: 0;
                }
            }
            .n-radiobox>label>.text {
                background-color: transparent !important;
                transform: none !important;
            }
            .n-input-guest-room-picker {
                min-width: 244px;
            }
        }
        .property-occupancy {
            max-width: 400px;
            margin: auto;
        }
        // .n-input-guest-room-picker .input-dropdown ul li:nth-child(3) {
        //     display: none;
        // }
        @include mobile {
            padding: 20px;
            .bx-picker--property-type {
                width: 100%;
                display: flex;
                flex-direction: column;
                .n-box-item {
                    width: 100%;
                    margin-bottom: 20px;
                    max-width: 100%;
                    position: relative;
                    strong {
                        margin: 0px;
                        font-weight: normal;
                    }
                    p {
                        display: none;
                    }
                    &:after {
                        position: absolute;
                        content: ' ';
                        width: 20px;
                        height: 20px;
                        border: 2px solid var(--color-subtle-grey);
                        box-sizing: border-box;
                        border-radius: 10px;
                        top: 20px;
                        right: 20px;
                    }
                    &.selected {
                        &:after {
                            border: 2px solid var(--color-subtle-grey);
                            background: var(--color-subtle-grey);
                        }
                    }
                }
            }
            .form--hotel-sub-property {
                .n-select-picker__label,
                .n-input-text__label {
                    background: #F7F7F7;
                }
                .n-select-picker {
                    height: 46px;
                    .input-value {
                        padding: 10px;
                    }
                }
                .n-input-text {
                    .n-input-text_suffix {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                    }
                    input {
                        height: 46px;
                        padding-right: 40px;
                        &:focus,
                        &:not(:placeholder-shown) {
                            background: #F7F7F7;
                            border-color: var(--color-subtle-grey);
                        }
                    }
                }
            }
            .form--roomtype-mobile {
                .n-input-text {
                    .n-input-text_suffix {
                        .n-select-picker {
                            width: 60px;
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            border: none !important;
                            .input-value>.ic-icon {
                                width: 40px;
                            }
                            .input-dropdown .n-radiobox-list>ul li .n-radiobox>label {
                                flex-direction: row-reverse;
                            }
                            .n-radiobox>label {
                                input {
                                    padding: 0px;
                                    width: 3px;
                                }
                                >.icon-radiobox {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.n-input-text--suffix {
                        .n-select-picker {
                            height: 100%;
                            .input-value {
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                            }
                        }
                    }
                    &.n-input-text--suffix > input{
                        height: 100%;
                    }
                }
                .form__row {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 10px;
                    grid-row-gap: 20px;
                    padding-bottom: 25px;
                    border-bottom: 1px solid var(--color-light-grey);
                    margin-bottom: 20px;
                    >p {
                        grid-area: 1 / 1 / 1 / span 2;
                    }
                    >.n-input-text:nth-child(2) {
                        grid-area: 2 / 1 / 2 / span 2;
                    }
                    >.n-input-text:nth-child(3) {
                        grid-area: 3 / 1 / 3 / span 2;
                    }
                }
                .n-input-text_suffix {
                    .n-select-picker {
                        background: transparent !important;
                    }
                }
                .n-radiobox>label>.text {
                    background-color: transparent !important;
                    transform: none !important;
                }
            }
        }
        @include onlyTablet {
            .breadcrumbs {
                // margin-bottom: 50px;
            }
            .form--roomtype-detail {
                padding: 20px;
                max-width: 760px;
                .form__row {
                    display: grid;
                    grid-template-columns: minmax(auto, 290px) 160px 120px 114px;
                    grid-column-gap: 10px;
                    .n-input-guest-room-picker {
                        min-width: 120px;
                    }
                }
            }
        }
    }
    .section--lnp-sleeping {
        .form--lnp-sleeping {
            .lnp-location_property-room {
                margin-bottom: 50px;
                @include tablet {
                    width: 100%;
                    max-width: 433px;
                    margin: 0 auto 30px;
                    background: var(--color-white);
                    border-radius: 15px;
                    padding: 30px 30px 30px 65px;
                }
            }
            .lnp-location_room-type {
                margin-bottom: 20px;
            }
            .lnp-location_room-type {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                font-variant: small-caps;
                color: var(--color-black);
            }
            .lnp-location_bedtypes__row {
                position: relative;
                display: grid;
                grid-template-columns: 1fr 36px;
                grid-column-gap: 10px;
                margin: 10px 0;
                .btn--delete {
                    padding: 5px;
                    width: 36px;
                    height: 36px;
                    border-radius: 6px;
                    cursor: pointer;
                    opacity: 1;
                    pointer-events: initial;
                    background: var(--color-shade-grey);
                    @include tablet {
                        opacity: 0;
                        pointer-events: none;
                        &:hover {
                            background: var(--color-shade-grey);
                        }
                    }
                }
                &:hover {
                    .btn--delete {
                        opacity: 1;
                        pointer-events: initial;
                    }
                    .n-select-picker {
                        border-color: var(--color-dark-grey);
                    }
                }
            }
            .lnp-location_bedcount {
                .lnp-location_bedcount-title {
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                }
                .lnp-location_bedcount-hint {
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-dark-grey);
                }
                .lnp-location_bedcount-input {
                    margin-top: 20px;
                }
            }
            .lnp-location_bedtypes-container {
                margin-top: 30px;
                .lnp-location_bedtypes {
                    margin-top: 20px;
                }
                .lnp-location_bedtypes-action-add {
                    margin-top: 10px;
                }
            }
            &.two-cols {
                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap: 20px;
                }
            }
            &.three-cols {
                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-column-gap: 20px;
                }
            }
        }
        .n-select-picker .input-dropdown {
            .form-inline-insert {
                position: relative;
                padding: 15px 16px;
            }
            .n-btn {
                padding-left: 0px;
                .ic-icon {
                    top: -2px;
                }
            }
        }
        @include mobile {
            padding: 0 20px;
            .form--lnp-sleeping .lnp-location_property-room {
                border-bottom: 1px solid var(--color-light-grey);
                padding-bottom: 20px;
                margin-bottom: 20px;
                &:last-child {
                    border: none;
                }
            }
            .n-select-picker.filled.label-on-fill .n-select-picker__label {
                background: #F7F7F7;
            }
        }
    }
    .section--lnp-sleeping-small {
        grid-template-columns: 1fr;
        width: max(400px, 80%);
        margin: auto;
    }
    .section--lnp-location {
        // margin-bottom: 50px;
        .lnp-location_btn {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            color: var(--color-black);
            font-family: var(--primary-font);
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            background-color: var(--color-shade-grey);
            padding: 9px 16px;
            border-radius: 8px;
            .ic-icon {
                margin: 0;
                margin-right: 6px;
                width: unset;
                height: unset;
                // margin: auto;
            }
        }
        .lnp-location-hide-img {
            img[src="/static/map/listings-marker.png"] {
                display: none;
            }
            .map-zoom-controls {
                display: none;
            }
        }
        .lnp-location_content {
            .lnp-location_toolbar {
                margin-bottom: 16px;
                display: flex;
                justify-content: flex-end;

                // grid-column: 1 / span 3;
                // display: flex;
                // justify-content: flex-end;
                // @include mobile {
                //     padding: 20px 30px 0 30px;
                // }
            }
            .lnp-location_form-wrapper {
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr;
                border-radius: 15px;
                .lnp-location_form {
                    // border-radius: 15px 0px 0px 15px;
                    // padding: 0 30px 30px 30px;
                    .lnp-location_form-title {
                        display: none;
                    }
                    .lnp-location_form-subtitle {
                        display: none;
                        // color: var(--color-dark-grey);
                        // font-size: 16px;
                        // line-height: 20px;
                        // margin-top: 5px;
                    }
                    .lnp-location_form-grid-dual {
                        margin-top: 20px;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-auto-rows: auto;
                        gap: 20px;
                    }
                    // .lnp-location_form-grid-sigle {
                    //     margin-top: 20px;
                    // }
                    .n-input-textarea {
                        border: 1px solid var(--color-light-grey);
                        border-radius: 8px;
                        padding: 0;
                        textarea {
                            width: 100%;
                            min-height: 110px;
                            resize: none;
                            display: block;
                            outline: transparent;
                            border: none;
                            font-family: var(--primary-font);
                            padding: 10px;
                            border-radius: inherit;
                            background-color: var(--color-light-grey);
                            &:focus {
                                background-color: var(--color-white);
                            }
                        }
                    }
                    // .error {
                    //     // display: none;
                    // }
                    .lnp-location_form-submit {
                        display: none;
                    }
                    .lnp-location_address-wrapper {
                        position: relative;
                    }
                    .lnp-location_address {
                        width: unset;
                        >input {
                            padding-right: 36px;
                        }
                    }
                    .lnp-location_address-list {
                        min-width: 100%;
                        top: calc(100% + 1px);
                        left: 0;
                        @include mobile {
                            top: 40px;
                        }
                    }
                    .lnp-location_input_clear {
                        animation: appear .3s ease-in-out forwards;
                        opacity: 0;
                        border: none;
                        outline: none;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background-color: #E9E9EB;
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 7px;
                        right: 6px;
                        &.active {
                            display: flex;
                        }
                        .lnp-location_input_clear_icon {
                            width: 10px;
                            height: 10px;
                            // width: rem(10);
                            // height: rem(10);
                            path {
                                stroke: #797882;
                                stroke-width: 2px;
                            }
                        }
                    }
                    .lnp-location_address-confirmation {
                        >div {
                            margin-bottom: 26px;
                        }
                    }

                }

                .list-new-property--map-container {
                    // border-radius: 1.25rem;
                    border-radius: 1rem;
                    max-height: calc(100vh - 68px - 88px);
                    // border-top-left-radius: 15px;
                    // border-bottom-left-radius: 15px;
                    .map-zoom-controls {
                        bottom: 50%;
                        transform: translate(0, 50%);
                    }
                    .list-new-property-map {
                        height: 100%;
                        .gmnoprint a,
                        .gmnoprint span,
                        .gm-style-cc {
                            display: none;
                        }
                        .gmnoprint div {
                            background: none !important;
                        }
                    }
                }
            }
        }
        @include mobile {
            .lnp-location_content {
                .lnp-location_form-wrapper {
                    .lnp-location_toolbar {
                        grid-row: 2;
                        margin-top: 1rem;
                        margin-bottom: 0;
                        // margin: 1rem 0;
                        justify-content: center;
                    }
                    .lnp-map-wrapper {
                        grid-row: 1;
        
                        .map-container {
                            border-radius: unset;
                        }
                    }
                    .lnp-location_form-top {
                        display: none;
                    }
                    .lnp-location_form {
                        margin-top: 4rem;
                        padding: 0 20px;
                        display: grid;
                        grid-template-columns: 1fr;

                        >div {
                            margin-bottom: 26px;
                        }

                        >div:nth-child(1) {
                            grid-row: 1;
                            position: relative;
                        }
                        >div:nth-child(2) {
                            display: block;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                            font-variant: small-caps;
                            margin-bottom: 1rem;
                        }
                        >div:nth-child(3) {
                            display: block;
                            margin-bottom: 0;
                            display: block;
                            color: var(--color-dark-grey);
                            font-size: 16px;
                            line-height: 20px;
                        }
                        >div:nth-child(7) {
                            grid-row: 2;
                        }
                        // div:nth-child(1) {
                        //     grid-row: 1;
                        // }

                        div.lnp-location_form-grid-sigle:nth-child(2) {
                            grid-row: 2;
                        }
                    }
                }
            }
            .n-input-text input {
                height: 36px;
            }
            .lnp-location_btn {
                height: 46px;
                width: 100%;
                justify-content: center;
                align-items: center;
                .ic-icon {
                    margin: 0px;
                }
            }
            .n-input-text > input:focus, .n-input-text > input:not(:placeholder-shown) {
                background: transparent;
            }
            .n-input-text--label-on-fill > input:focus + label,
            .n-input-text--label-on-fill > input:not(:placeholder-shown) + label {
                background: #F7F7F7;
            }
        }
        @include onlyTablet {
            .lnp-location_content {
                .lnp-location_form-wrapper {
                    .list-new-property--map-container {
                        // border-radius: 15px 15px 0 0;
                        .list-new-property-map {
                            min-height: 359px;
                        }
                    }
                    .lnp-location_form {
                        // border-radius: 15px;
                        position: relative;
                        // top: -11px;
                        z-index: 1;
                        background: var(--color-white);
                    }
                }
            }
        }
        @include tablet {
            .lnp-location_content {
                // .lnp-location_toolbar {
                //     grid-column: 1 / span 3;
                //     display: flex;
                //     justify-content: flex-start;
                // }
                .lnp-location_form-wrapper {
                    // margin-top: 20px;
                    padding: 10px 20px 20px 20px;
                    // padding-bottom: 20px;
                    display: grid;
                    grid-template-columns: 1fr;
                    background-color: var(--color-white);
                    // border-radius: 15px;

                    .lnp-location_form-top {
                        margin: 36px 0 26px 0;

                        .lnp-location_form-title {
                            position: relative;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                            font-variant: small-caps;
                            display: block;
                        }
                    }

                    .lnp-location_form {
                        border-radius: 15px;
                        margin-right: 30px;
                        // padding: 0 30px;
                        .lnp-location_form-title {
                            &:not(:first-child) {
                                margin-top: 36px;
                            }
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                            font-variant: small-caps;
                            display: block;
                        }
                        .lnp-location_form-subtitle {
                            display: block;
                            color: var(--color-dark-grey);
                            font-size: 16px;
                            line-height: 20px;
                            margin-top: 5px;
                            margin-bottom: 26px;
                        }
                        .lnp-location_form-grid-dual {
                            margin-top: 20px;
                            display: grid;
                            grid-template-columns: auto auto;
                            grid-auto-rows: auto;
                            gap: 20px;
                        }
                        // .lnp-location_form-grid-sigle {
                        //     margin-top: 20px;
                        // }
                        .n-input-textarea {
                            border: 1px solid var(--color-light-grey);
                            border-radius: 8px;
                            padding: 0;
                            textarea {
                                font-size: 1rem;
                                width: 100%;
                                min-height: 110px;
                                resize: none;
                                display: block;
                                outline: transparent;
                                border: none;
                                font-family: var(--primary-font);
                                padding: 10px;
                                border-radius: inherit;
                                &:focus {
                                    background-color: var(--color-white);
                                }
                                background-color: var(--color-light-grey);
                            }
                        }
                        // .error {
                        //     // display: none;
                        // }
                        .lnp-location_form-submit {
                            display: none;
                        }
                    }
                }
            }
        }
        @include desktop {
            .lnp-location_content {
                .lnp-location_form-wrapper {
                    grid-template-columns: 1fr 1fr;

                    .lnp-location_toolbar {
                        grid-row: 1;
                        grid-column: 2;
                        // margin-bottom: 16px;
                        // display: flex;
                        // justify-content: flex-end;
                    }
                    .lnp-map-wrapper {
                        grid-row: 2;
                        grid-column: 2;
                    }
                    .list-new-property--map-container {
                        position: sticky;
                        top: 0px;
                    }

                    .lnp-location_form {
                        // max-height: ;
                        grid-row: 2;
                        grid-column: 1;
                        .lnp-location_form-title {
                            display: block;
                        }
                    }

                    .lnp-location_form-top {
                        grid-row: 1;
                        grid-column: 1;
                        margin: 0 0 26px 0;
                        position: relative;
                        display: flex;
                        align-items: flex-end;

                        .lnp-location_form-title {
                            position: relative;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                            font-variant: small-caps;
                            display: block;
                        }    
                    }
                }
            }
        }
    }
    .section--lnp-facilities {
        .form--main {
            display: grid;
            grid-template-columns: repeat(3, 33.33%);
            grid-column-gap: 10px;
            margin-top: 10px;
            .form__row:nth-child(1) {
                grid-row: 1;
                grid-column: 1;
            }
            .form__row:nth-child(2) {
                grid-row: 1 / span 2;
                grid-column: 2;
            }
            .form__row:nth-child(3) {
                grid-row: 1;
                grid-column: 3;
            }
            .form__row:nth-child(4) {
                grid-row: 2;
                grid-column: 1;
            }
            .n-checkbox-list {
                >strong {
                    padding-left: 13px;
                    margin-bottom: 25px;
                    display: block;
                    font-size: 23px;
                    line-height: 120%;
                    @include mobile {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                    }
                    @include tablet {
                        margin-bottom: 5px;
                    }
                }
                >ul {
                    li {
                        height: 54px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .n-checkbox {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            &.n-checkbox--is-checked {
                                .icon-checkbox {
                                    rect {
                                        fill: var(--color-purple);
                                        stroke: var(--color-purple);
                                    }
                                }
                            }
                            >label {
                                padding: 7px 10px;
                                width: 100%;
                                @include tablet {
                                    width: calc(100% - 60px);
                                }
                                >.text {
                                    >span:first-child {
                                        padding-left: 5px;
                                        margin-bottom: 10px;
                                        @include tablet {
                                            margin-bottom: 0px
                                        }
                                    }
                                    small {
                                        padding: 2px 5px;
                                        border-radius: 6px;
                                        background: var(--color-light-grey);
                                        @include tablet {
                                            background: transparent;
                                            &:hover {
                                                background: rgba(172, 171, 180, 0.4);
                                            }
                                        }
                                    }
                                }
                            }
                            .actions {
                                padding: 10px 13px;
                                @include tablet {
                                    opacity: 0;
                                    pointer-events: none;
                                    transition: all 0.4 ease-in-out;
                                }
                                .n-btn--fee {
                                    width: 34px;
                                    height: 34px;
                                    padding: 0px;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 8px;
                                    background: var(--color-light-grey);
                                    >.ic-icon {
                                        margin: 0px;
                                    }
                                    @include tablet {
                                        background: transparent;
                                        &:hover {
                                            background: rgba(172, 171, 180, 0.4);
                                        }
                                    }
                                }
                            }
                        }
                        .n-tooltip__inner {
                            width: auto;
                            padding: 5px 10px;
                            background: var(--color-dark-grey);
                            white-space: nowrap;
                            min-width: auto;
                            max-width: auto;
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0.05em;
                            color: var(--color-white);

                            &:before {
                                display: none;
                            }
                        }
                        &:hover {
                            .n-checkbox {
                                .actions {
                                    opacity: 1;
                                    pointer-events: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include mobile {
            .form--main {
                grid-template-columns: 1fr;
                >div.form__row.form__row.form__row {
                    grid-column: 1;
                    grid-row: unset;
                }
                .n-checkbox-list>ul li {
                    min-height: 54px;
                    height: auto;
                    border-bottom: 1px solid var(--color-light-grey);
                    background: transparent !important;
                    .n-checkbox {
                        display: flex;
                        flex-direction: column;
                        >label {
                            padding: 8px 10px 0 10px;
                            flex-direction: row-reverse;
                        }
                        .actions {
                            padding: 0px 10px 7px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @include onlyTablet {
            max-width: 80%;
            margin: 0 auto 30px;
            .form--main {
                display: block;
            }
        }
    }
    .section--lnp-rules {
        .form--main {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 60px;
            margin-top: 10px;
            .col-two {
                grid-template-columns: 1fr;
                grid-row-gap: 20px;
            }
        }
        .lnp-checkbox > strong {
            padding-left: 0px;
        }
        .n-radiobox-list>strong,
        .n-checkbox-list>strong,
        .input-group--checkinout>strong {
            font-variant: all-small-caps;
            font-size: 23px;
        }
        .n-input-stay-length {
            margin-bottom: 50px;
            >strong {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 23px;
                line-height: 21px;
                font-variant: all-small-caps;
                display: block;
                margin-bottom: 15px;
            }
            &__inputs {
                display: grid;
                grid-template-columns: 1fr;
                grid-column-gap: 10px;
                grid-row-gap: 20px;
                margin-bottom: 15px;
            }
            >ul {
                list-style: none;
                margin-bottom: 20px;
                li {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    >p {
                        margin: 0px;
                        color: var(--color-black);
                        display: flex;
                        flex-direction: column;
                        small {
                            color: var(--color-dark-grey);
                        }
                    }
                    .ic-icon {
                        cursor: pointer;
                    }
                }
            }
            >.n-btn {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                flex-direction: row-reverse;
                padding: 8px 2px;
                .ic-icon {
                    margin-left: 0px;
                    margin-right: 10px;
                    path {
                        stroke: var(--color-bright-purple);
                    }
                }
            }
        }
        @include mobile {
            padding: 10px;
            .n-radiobox-list,
            .n-checkbox-list {
                max-width: calc(100vw - 50px);
            }
        }
        @include tablet {
            .form--main {
                grid-template-columns: repeat(2, calc(50% - 30px));
                .col-two {
                    grid-template-columns: repeat(2, calc(50% - 10px));
                    grid-row-gap: 20px;
                }
            }
            .n-input-stay-length>ul li {
                >p {
                    flex-direction: row;
                }
            }
            .n-input-stay-length__inputs {
                grid-template-columns: repeat(2, calc(50% - 10px));
            }
        }
        @include onlyTablet {
            max-width: 70%;
            margin: 0 auto 30px;
            .form--main {
                display: block;
            }
        }
    }
    .section--amenities {
        .section--amenities--inner {
            padding-top: 10px;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .n-clickable-wizard_header {
            margin-bottom: 20px;
        }
        .form--main {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 10px;
            .n-checkbox-list {
                margin-bottom: 30px;
                >strong {
                    font-size: 23px;
                    line-height: 120%;
                    padding-left: 13px;
                    margin-bottom: 5px;
                }
                >ul {
                    li {
                        height: 54px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .n-checkbox {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            &.n-checkbox--is-checked {
                                .icon-checkbox {
                                    rect {
                                        fill: var(--color-purple);
                                        stroke: var(--color-purple);
                                    }
                                }
                            }
                            >label {
                                padding: 7px 10px;
                                width: calc(100% - 60px);
                                >.text {
                                    >span:first-child {
                                        padding-left: 5px;
                                    }
                                    small {
                                        padding: 2px 5px;
                                        &:hover {
                                            background: #D4D3D8;
                                            border-radius: 6px;
                                        }
                                    }
                                }
                            }
                            .actions {
                                padding: 10px 13px;
                                .n-btn--fee {
                                    width: 34px;
                                    height: 34px;
                                    padding: 0px;
                                    justify-content: center;
                                    align-items: center;
                                    >.ic-icon {
                                        margin: 0px;
                                        path {
                                            stroke: var(--color-dark-grey);
                                        }
                                    }
                                }
                            }
                        }
                        .n-tooltip__inner {
                            width: auto;
                            padding: 5px 10px;
                            background: var(--color-dark-grey);
                            white-space: nowrap;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
            .form-inline-insert {
                max-width: 320px;
                .n-btn--adding {
                    padding: 8px 7px;
                }
            }
        }
        .form--footer {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            .n-btn {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 10px 15px !important;
            }
        }
        &.section--vr {
            .n-clickable-wizard_header {
                display: none;
            }
            .form--footer {
                display: none;
            }
        }
        @include mobile {
            width: 100%;
            max-width: 100vw;
            padding: 10px;
            .form--main {
                width: 100%;
                grid-template-columns: 1fr;
            }
            .n-clickable-wizard_slide-content {
                min-width: 300px;
                width: 100vw;
            }
            .n-select-picker.label-on-fill .n-select-picker__label {
                background: #F7F7F7;
                top: 0.38rem;
            }
        }
        @include onlyTablet {
            margin: 0 auto 30px;
            .form--main {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 10px;
                .form--item:nth-child(1) {
                    grid-column: 1;
                    grid-row: 1;
                }
                .form--item:nth-child(2) {
                    grid-column: 1;
                    grid-row: 2;
                }
                .form--item:nth-child(3) {
                    grid-column: 2;
                    grid-row: 4;
                }
                .form--item:nth-child(4) {
                    grid-column: 2;
                    grid-row: 1 / span 2;
                }
                .form--item:nth-child(5) {
                    grid-column: 2;
                    grid-row: 3;
                }
                .form--item:nth-child(6) {
                    grid-column: 1;
                    grid-row: 3 / span 2;
                }
            }
        }
        @include desktop {
            .form--main {
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 10px;
                .form--item:nth-child(1) {
                    grid-column: 1;
                    grid-row: 1;
                }
                .form--item:nth-child(2) {
                    grid-column: 1;
                    grid-row: 2;
                }
                .form--item:nth-child(3) {
                    grid-column: 1;
                    grid-row: 3;
                }
                .form--item:nth-child(4) {
                    grid-column: 2;
                    grid-row: 1 / span 2;
                }
                .form--item:nth-child(5) {
                    grid-column: 2;
                    grid-row: 3;
                }
                .form--item:nth-child(6) {
                    grid-column: 3;
                    grid-row: 1 / span 3;
                }
            }
        }
    }
    .section--lnp-photos {
        .lnp-photos_error_wrapper {
            position: relative;
            width: 100%;
        }
        .lnp-photos_error {
            width: calc(100% - 250px - 60px);
            padding: 0 30px;
            position: fixed;
            // position: absolute;
            bottom: 60px;
            transform: translateY(20px);
            opacity: 0;
            visibility: hidden;
            transition: transform 0.3s ease;
        }
        .lnp-photos_error--active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
        .lnp-photos_property {
            margin-top: 20px;
            .lnp-photos_property_wizard {
                margin-top: 30px;
            }
        }
        .lnp-photos_upload-area {
            position: relative;
            padding: 30px;
            padding-top: 84px;
            border-radius: 15px;
            background-color: var(--color-white);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            outline: 0;
            overflow: visible;
        }
        .lnp-photos_upload-area_note-wrapper {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .lnp-photos_upload-area_note {
                height: 100%;
                max-width: 280px;
                margin: auto;
                border: 1px solid var(--color-dark-grey);
                border-radius: 10px;
                padding: 20px 20px 20px 40px;
                display: flex;
                align-items: center;
                .lnp-photos_upload-area_note-item {
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 400;
                    a {
                        color: var(--color-purple);
                        text-decoration: underline;
                    }
                }
            }
        }
        .lnp-photos_upload-area_actionbar {
            grid-column: 1 / span 3;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-height: 44px;
            .n-btn {
                color: var(--color-white);
                background-color: var(--color-vibrant-pink);
                border-radius: 10px;
                padding: 10px 15px;
            }
        }
        .lnp-photos_upload-area_hint-overlay {
            &.lnp-photos_upload-area_hint-overlay__visible {
                visibility: visible;
                opacity: 1;
            }
            position: absolute;
            visibility: hidden;
            opacity: 0;
            transition: .3s ease-in-out;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .15);
            top: 0;
            left: 0;
            border-radius: inherit;
        }
        .lnp-photos_upload-hint {
            &.lnp-photos_upload-area_abridged-hint {
                max-height: 78px;
                .ic-icon {
                    display: none;
                }
            }
            outline: 0;
            cursor: pointer;
            display: grid;
            justify-content: center;
            align-items: center;
            background: linear-gradient(0deg, #E9E9EB, #E9E9EB),
            #EBEAEB;
            border-radius: 10px;
            height: 348px;
            .lnp-photos_upload-hint_center {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, min-content);
                justify-items: center;
                font-family: var(--primary-font);
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-dark-grey);
                max-width: 94px;
            }
        }
        .lnp-photos_photo-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 17px;
            .n-btn {
                &:first-child,
                &:last-child {
                    flex-direction: row-reverse;
                }
                &:first-child {
                    padding-left: 0;
                    .ic-icon {
                        margin-left: 0;
                        margin-right: 10px;
                        path[stroke] {
                            stroke: var(--color-black);
                        }
                        path[fill] {
                            fill: var(--color-black);
                        }
                    }
                }
                &:last-child {
                    padding-right: 0;
                    .ic-icon {
                        margin-left: 0;
                        margin-right: 10px;
                        path {
                            stroke: var(--color-black-grey);
                        }
                    }
                }
            }
        }
        .lnp-photos_upload-area_active-image-caption {
            margin-top: 15px;
            color: var(--color-black);
            font-size: 16px;
            line-height: 20px;
        }
        .lnp-photos_gallery {
            overflow: auto;
            max-height: 492px;
            display: grid;
            grid-template-columns: repeat(auto-fill, 114px);
            gap: 20px;
            justify-content: center;
            margin-left: 20px;
            .lnp-photos_gallery_image {
                &.lnp-photos_gallery_image--active {
                    // border: 2px solid var(--color-vibrant-pink);
                    img {
                        border: 2px solid var(--color-vibrant-pink);
                    }
                }
                cursor: pointer;
                // height: 78px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // overflow: hidden;
                // border-radius: 8px;
                img {
                    max-width: 100%;
                    width: 100%;
                    // height: 100%;
                    object-fit: cover;
                    height: 78px;
                    border-radius: 8px;
                }
                .n-select-picker {
                    margin-top: 10px;
                    border-radius: 8px;
                    height: unset;
                    .input-value {
                        padding: 3px 5px;
                        .text-group {
                            .text {
                                font-size: 11px;
                                line-height: 12px;
                                font-weight: 400;
                            }
                        }
                        .ic-icon {
                            // height: 14px;
                            // width: 14px;
                            svg {
                                transform: scale(0.8);
                            }
                        }
                    }
                    .input-dropdown {
                        .n-radiobox-list {
                            .n-radiobox {
                                label {
                                    padding: 3px 5px;
                                    input {
                                        flex: 0 0 5px;
                                    }
                                    .text {
                                        justify-content: center;
                                        align-items: center;
                                        overflow: hidden;
                                        span {
                                            font-size: 11px;
                                            line-height: 12px;
                                            font-weight: 400;
                                        }                                    
                                    }
                                    .icon-radiobox {
                                        svg {
                                            transform: scale(0.8);
                                        }
                                    }
                                }                                
                            }
                        }
                    }
                }
            }
        }
        .lnp-photos_upload-area_enable-cat {
            width: 100%;
            padding: 30px 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            .lnp-photos_upload-area_enable-cat-text {
                font-size: 16px;
                line-height: 150%;
                font-weight: 500;
                margin-right: 5px;
            }
            .MuiSwitch-root {
                margin-left: 32px;
            }
        }
        .lnp-photos_upload-area_left {
            position: relative;
        }
        .lnp-photos_upload-area_right {
            // overflow: hidden;
            overflow: visible;
            .form--main {
                // overflow: hidden;
                overflow: visible;
                .lnp-photos_gallery {
                    overflow: visible;
                }
            }
        }
        .lnp-photos_upload-area_active-image-preview {
            overflow: hidden;
            border-radius: 10px;
            height: 348px;
            background: var(--color-light-grey);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                object-fit: contain;
                max-height: 100%;
                display: block;
                margin: auto;
            }
        }
        .lnp-photos_dropdown-dots-wrapper {
            position: absolute;
            top: 20px;
            right: 20px;
            .lnp-photos_dropdown-dots {
                padding: 0;
                border: none;
                .ic-icon {
                    margin: 0;
                    width: auto;
                    height: auto;
                }
            }
            .lnp-photos_dropdown--active.lnp-photos_dropdown--active {
                opacity: 1;
                transform: translateY(0);
                transition: 0.3s ease;
            }
            .lnp-photos_dropdown {
                opacity: 0;
                transform: translateY(-6px);
                position: absolute;
                top: calc(100% + 12px);
                right: -16px;
                background-color: $color-white;
                border-radius: 10px;
                padding: 6px;
                .lnp-photos_dropdown-ul {
                    list-style: none;
                }
                .lnp-photos_dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 3px 2px;
                    border-radius: 4px;
                    // margin-top: 6px;

                    // &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    //     >span.lnp-photos_dropdown-item-symb {
                    //         svg {
                    //             path {
                    //                 fill: var(--color-dark-grey);
                    //             }
                    //         }
                    //     }
                    // }

                    // &:nth-child(5), &:nth-child(1) {
                    //     >span.lnp-photos_dropdown-item-symb {
                    //         svg {
                    //             path {
                    //                 stroke: var(--color-dark-grey);
                    //             }
                    //         }
                    //     }
                    // }

                    &--disabled {
                        cursor: default;
                        pointer-events: none;

                        >span.lnp-photos_dropdown-item-text {
                            color: var(--color-dark-grey);
                        }
                        &:hover {
                            background-color: none;
                        }
                    }
                    .lnp-photos_dropdown-item-symb {
                        flex: 0 0 auto;
                        display: flex;
                        margin-right: 10px;
                    }
                    .lnp-photos_dropdown-item-text {
                        white-space: nowrap;
                        font-size: 14px;
                        line-height: 150%;
                        font-weight: 400;
                        color: $color-black;
                    }
                }
                .lnp-photos_dropdown-item:first-child {
                    // margin-top: 0;
                    .lnp-photos_dropdown-item-symb {
                        svg {
                            path {
                                fill: var(--color-subtle-grey);
                            }
                        }
                    }
                }
                .lnp-photos_dropdown-item:hover {
                    background-color: var(--color-shade-grey);
                    cursor: pointer;
                }
            }
        }
        
        .lnp-photos_inter-header {
            .lnp-photos_inter-header_title {
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
                color: var(--color-black);
            }
            .lnp-photos_inter-header_subtitle {
                margin-top: 5px;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-dark-grey);
            }
        }
        .lnp-photos_rooms {
            margin-top: 50px;
            .slick-slide {
                padding-left: 0;
            }
        }
        @include mobile {
            .lnp-photos_rooms {
                width: 100%;
                max-width: 100%;
            }
            .n-clickable-wizard {
                max-width: 100%;
                min-width: 100%;
                .n-clickable-wizard_slide-content {
                    min-width: 100%;
                }
            }
            .lnp-photos_upload-area {
                grid-template-columns: 1fr;
                .lnp-photos_upload-area_right {
                    margin-top: 30px;
                    .form--main {
                        overflow: visible;
                    }
                }
                .lnp-photos_gallery {
                    justify-content: flex-start;
                    margin: 0px;
                }
            }
            .lnp-photos_upload-area_actionbar {
                grid-column: initial;
            }
        }
        @include onlyTablet {
            .lnp-photos_upload-area {
                grid-template-columns: 1fr;
                .lnp-photos_upload-area_right {
                    margin-top: 30px;
                }
                .lnp-photos_gallery {
                    justify-content: flex-start;
                    margin: 0px;
                }
            }
            .lnp-photos_upload-area_actionbar {
                grid-column: initial;
            }
        }
    }
    .lnp-description {
        .html-editor-wrapper {
            position: relative;
            border: 1px solid transparent;
            border-radius: 6px;

            .editor-error {
                position: absolute;
                top: 102%;
                left: calc(#{$inp-padding-x} + 1px);
                font-size: .75em;
                font-weight: 400;
                line-height: .75em;
                color: $color-error-red;
            }
        }
        .html-editor-error {
            border-color: $color-error-red;
        }
        .sun-editor .se-toolbar {
            z-index: 2;
        }
        .n-input-text > input {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-dark-grey);
            border-radius: 8px;
        }
        .sun-editor {
            border-radius: 6px;
            overflow: hidden;
            .se-wrapper .se-wrapper-wysiwyg {
                border-radius: 8px 8px 0 0;
            }
            .se-resizing-bar {
                border-radius: 0 0 8px 8px;
            }
        }
        > p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-dark-grey);
        }
        .sun-editor-editable p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
        @include mobile {
            padding: 20px;
            .sun-editor {
                margin: 0 -25px;
                width: 100vw !important;
            }
        }
    }
    .section--lnp-price {
        font-family: $primary-font;
        .lnp-price_error_wrapper {
            position: relative;
            width: 100%;
        }
        .lnp-price_error {
            width: calc(100% - 250px - 60px);
            padding: 0 30px;
            position: fixed;
            // position: absolute;
            bottom: 60px;
            transform: translateY(20px);
            opacity: 0;
            visibility: hidden;
            transition: transform 0.3s ease;
        }
        .lnp-price_error--active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
        .lnp-price_content-area {
            .n-input-drop-down,
            .n-input-counter,
            .n-input-text {
                min-width: 140px
            }
            .lnp-price_content-area_action {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                grid-column: 1/ span 2;
            }
            border-radius: 15px;
            background-color: var(--color-white);
            padding: 30px 30px 100px 30px;
            min-height: 552px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 60px;
            .lnp-price_input-group_header {
                color: var(--color-black);
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                text-transform: capitalize;
            }
            .lnp-price_content-area_right {
                .lnp-price_input-group_header {
                    text-transform: none;
                }    
            }
            .lnp-price_input-group_single,
            .lnp-price_input-group_dual {
                margin-top: 20px;
            }
            .lnp-price_input-group_dual {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 17px;
                align-items: flex-start;
                grid-auto-rows: auto;
            }
            .lnp-price_input-group_input-with-hint {
                display: grid;
                row-gap: 10px;
                grid-template-rows: auto 40px;
                .lnp-price_input-group_discount-hint {
                    font-size: 15px;
                }
            }
            .lnp-price_input-group_popover {
                max-height: 385px;
                overflow: auto;
                padding-left: 5px;
                padding-right: 5px;
                .radio {
                    input {
                        min-width: 1.25rem;
                    }
                }
                .radio_label {
                    width: 100%;
                }
                .n-radio-button-popover_item {
                    display: grid;
                    grid-template-columns: 1fr min-content;
                    align-items: center;
                    .n-radio-button-popover_item_text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .ic-icon {
                        transform: translate(5px, 3px);
                        justify-self: flex-end;
                    }
                }
            }
            .lnp-price_input-group_base-price {
                position: relative;
                .n-input-text_prefix {
                    position: absolute;
                    top: 7px;
                    left: 15px;
                }
                input {
                    padding-left: 48px;
                }
            }
            .lnp-price_service-fee,
            .lnp-price_payment-rules,
            .lnp-price_extra-charges {
                margin-top: 50px;
            }
            .lnp-price_service-fee {
                opacity: 0;
                animation: appear .3s ease-in-out forwards;
            }
            .lnp-price_service-fee-info {
                margin-top: 5px;
                color: var(--color-dark-grey);
            }
            .lnp-price_extra-charges {
                .n-input-text {
                    position: relative;
                    .n-input-text_suffix {
                        position: absolute;
                        right: 16px;
                        top: 11px;
                    }
                }
                .lnp-price_extra-charges_input {
                    margin-top: 20px;
                    min-width: 50px;
                    max-width: 210px;
                    width: 100%;
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button  {
                        -webkit-appearance: none;
                        display: none;
                    }
                }
                .lnp-price_extra-charges_input-with-aux {
                    .lnp-price_extra-charges_input {
                        margin-top: 0;
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button  {
                            -webkit-appearance: none;
                            display: none;
                        }
                    }
                    .lnp-price_input-group_dual {
                        margin-top: 0;
                        grid-template-columns: 60px calc(100% - 60px);
                        // grid-template-columns: 60px auto;
                        align-items: center;
                        justify-content: flex-end;
                        text-align: right;
                        @include mobile {
                            grid-template-columns: 1fr;
                        }
                        .n-input-text {
                            input {
                                padding-left: 48px;
                            }
                        }
                        .n-select-picker {
                            min-width: 140px;
                            width: auto;
                            .input-dropdown {
                                .n-radiobox {
                                    label {
                                        padding: 10px;
                                    }
                                    .text {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
                .lnp-price_extra-charges_action-add {
                    margin-top: 20px;
                }
            }
        }
        &.section--vr {
            .n-clickable-wizard_header,
            .lnp-price_content-area_action {
                display: none;
            }
        }
        .lnp-price_extra-charges {
            .n-input-text {
                .n-input-text_suffix {
                    circle {
                        fill: var(--color-subtle-grey);
                    }
                }
            }
        }
        @include mobile {
            max-width: 100vw;
            .lnp-price_content-area {
                grid-template-columns: 1fr;
                grid-row-gap: 30px;
                background: transparent;
                .lnp-price_content-area_action {
                    grid-area: unset;
                }
            }
            .lnp-price_content-area_action {
                grid-column: initial;
            }
            .lnp-price_content-area_right {
                .lnp-price_input-group_dual {
                    grid-template-columns: 1fr
                }
            }
            .lnp-price_content-area .lnp-price_extra-charges .lnp-price_extra-charges_input {
                width: 100%;
                max-width: 100%
            }
            .lnp-price_input-group--span {
                display: none;
            }
        }
        @include onlyTablet {
            max-width: 100vw;
            .lnp-price_content-area {
                grid-template-columns: 1fr;
                grid-row-gap: 30px;
                .lnp-price_content-area_action {
                    grid-area: unset;
                }
            }
            .lnp-price_content-area_action {
                grid-column: initial;
            }
            .lnp-price_content-area .lnp-price_extra-charges {
                > .lnp-price_extra-charges_input {
                    width: 50% !important;
                    max-width: 50% !important;
                }
                .lnp-price_input-group_dual {
                    display: grid;
                    grid-template-columns: 50% 1fr;
                    grid-gap: 17px;
                }
            }
            .lnp-price_content-area .lnp-price_extra-charges .lnp-price_extra-charges_input {
                width: 100%;
                max-width: 100%
            }
        }
    }
    .section--lnp-availability {
        max-width: 1140px;
        margin: 0px auto;
        margin-top: 20px;
        .section--lnp-availability__content {
            margin-top: 20px;
            @media screen and (min-width: 1100px) {
                border: 1px solid #ACABB4;
            }
            overflow: auto;
        }
        .form--lnp-availability-detail {
            @media screen and (max-width: 1190px) {
                margin-top: 35px;
            }
            display: flex;
            padding: 0 30px;
            >.form__row:first-child {
                width: 55%;
            }
            .form__row {
                width: 45%;
                .text {
                    >span:first-child {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: #242526;
                    }
                }
                .sub-label {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-subtle-grey);
                }
            }
        }
        .n-button-selector {
            display: block;
            strong {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black);
                display: block;
            }
            small {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-dark-grey);
                display: block;
                margin-bottom: 10px;
            }
            .n-btn {
                padding: 5px 10px;
            }
        }
        .aside-panel {
            background: #FFFFFF;
            border-radius: 20px 20px 0 0;
            width: 250px;
            height: 48%;
            left: 0px;
            position: fixed;
            bottom: 0px;
            padding: 60px 15px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
            &.active {
                opacity: 1;
                pointer-events: initial;
                height: 78%;
            }
            .aside-panel__close {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }
            .form--box {
                display: flex;
                flex-direction: column;
                .form--inner {
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                    background: #FFFFFF;
                    border-radius: 15px;
                    margin-bottom: 25px;
                    .n-input-text {
                        > .n-input-text_prefix {
                            left: 10px;
                            width: 24px;
                            height: 24px;
                            top: 12px;
                            display: flex;
                            align-self: center;
                        }
                        > input {
                            border: none;
                            border-bottom: 1px solid #F5F6F7;
                            border-radius: 0px;
                            height: 50px;
                            padding-left: 35px;
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                    .input-price > input {
                        padding-left: 65px;
                    }
                    .col-two {
                        grid-gap: 0px;
                        .n-input-text:first-child > input {
                            border-right: 1px solid #F5F6F7;
                            border-radius: 15px 0 0 0;
                        }
                        .n-input-text:last-child > input {
                            border-radius: 0 15px 0 0;
                        }
                    }
                    > .n-input-text:last-child > input {
                        border-radius: 0 0 15px 15px;
                    }
                }
                .n-btn {
                    border-radius: 10px;
                    justify-content: center;
                    margin-bottom: 10px;
                    width: 100%;
                    &.n-btn--save {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
        .row--footer {
            display: flex;
            justify-content: flex-end;
        }
        &.section--vr {
            .n-clickable-wizard_header,
            .row--footer {
                display: none;
            }
        }
        .DayPicker_weekHeader_ul .DayPicker_weekHeader_li small{
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 19px;
            text-align: center;
            color: var(--color-dark-grey);
        }
        &__top {
            padding: 0 5px;
            // margin-top: -30px;
            margin-top: -1rem;
            p {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-dark-grey);
            }
            a {
                color: var(--color-bright-purple);
                font-weight: 600;
                cursor: pointer;
            }
        }
        @include mobile {
            max-width: 100vw;

            .n-clickable-wizard_header {
                padding-left: 20px;
            }
            .section--lnp-availability__content {
                margin: auto;
                
                .DayPicker_weekHeaders {
                    z-index: 1;
                    background-color: var(--color-white);
                    transform: translateY(-8px);
                    padding-bottom: 8px;
                    border-bottom: 1px solid var(--color-light-grey);
                    .DayPicker_weekHeader_ul {
                        display: flex;
                    }
                }

                .DayPicker_transitionContainer {
                    .DayPickerNavigation {
                        left: 0;
                        width: 100%;
                        display: block;
                        bottom: -8px;
                        box-shadow: none;
                        border-top: 1px solid var(--color-light-grey);
                    }
                }    
            }
            .n-clickable-wizard .n-clickable-wizard_content {
                margin: 0px;
                overflow-y: hidden;
            }
            .n-btn--setting-mobile {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                justify-content: space-between !important;
                width: calc(100% - 10px);
                margin: 0 0 30px;
            }
            .aside-panel {
                background: #FFFFFF;
                border-radius: 20px 20px 0 0;
                width: 100%;
                height: auto;
                left: 0px;
                position: fixed;
                bottom: 0px;
                padding: 60px 20px;
                opacity: 0;
                pointer-events: none;
                transition: all 0.3s ease-in-out;
                &.active {
                    opacity: 1;
                    pointer-events: initial;
                    height: auto;
                }

                .input-price {
                    margin-bottom: 30px;
                }
                .form--footer {
                    display: flex;
                    justify-content: space-between;

                    .n-btn {
                        padding: 0px;
                        width: auto !important;
                    }
                }
                // .form--box {
                //     display: flex;
                //     flex-direction: column;
                //     .form--inner {
                //         box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                //         background: #FFFFFF;
                //         border-radius: 15px;
                //         margin-bottom: 25px;
                //         .n-input-text {
                //             > .n-input-text_prefix {
                //                 left: 10px;
                //                 width: 24px;
                //                 height: 24px;
                //                 top: 12px;
                //                 display: flex;
                //                 align-self: center;
                //             }
                //             > input {
                //                 border: none;
                //                 border-bottom: 1px solid #F5F6F7;
                //                 border-radius: 0px;
                //                 height: 50px;
                //                 padding-left: 35px;
                //                 font-family: $primary-font;
                //                 font-style: normal;
                //                 font-weight: normal;
                //                 font-size: 16px;
                //                 line-height: 20px;
                //             }
                //         }
                //         .input-price > input {
                //             padding-left: 45px;
                //         }
                //         .col-two {
                //             grid-gap: 0px;
                //             .n-input-text:first-child > input {
                //                 border-right: 1px solid #F5F6F7;
                //                 border-radius: 15px 0 0 0;
                //             }
                //             .n-input-text:last-child > input {
                //                 border-radius: 0 15px 0 0;
                //             }
                //         }
                //         > .n-input-text:last-child > input {
                //             border-radius: 0 0 15px 15px;
                //         }
                //     }
                //     .n-btn {
                //         border-radius: 10px;
                //         justify-content: center;
                //         margin-bottom: 10px;
                //         width: 100%;
                //         &.n-btn--save {
                //             font-family: $primary-font;
                //             font-style: normal;
                //             font-weight: 600;
                //             font-size: 16px;
                //             line-height: 20px;
                //         }
                //     }
                // }
            }
        }
    }
    .section--lnp-local {
        max-width: 600px;
        margin: auto;
        padding: 30px;
        p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
        a {
            color: var(--color-bright-purple);
            font-weight: 600;
        }
    }
    .section--lnp-local-preview {
        position: relative;
        max-width: 1340px;
        margin: auto;

        // a {
        //     color: var(--color-white);
        // }

        .section--lnp-local-preview-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 8;
        }

        .section--lnp-local-regulations-title {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            margin-bottom: 24px;
            text-align: center;
        }
        .section--lnp-local-regulations-content {
            display: flex;
            justify-content: center;
            align-items: center;
            .section--lnp-local-regulations-content-inner {
                padding: 30px;
                border: 1px solid var(--color-subtle-grey);
                border-radius: 15px;
                p {
                    color: var(--color-black);
                    max-width: 660px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
            .n-checkbox {
                margin-top: 36px;
                .text {
                    font-size: 16px;
                    line-height: 150%;
                    font-weight: 600;
                }
            }
            .n-checkbox:hover {
                cursor: pointer;
            }
            .n-checkbox.n-checkbox-checked {
                .icon-checkbox {
                    svg {
                        rect {
                            fill: var(--color-purple);
                            stroke: var(--color-purple);
                        }
                    }
                }
            }
            // .n-checkbox-checked {
            //     .icon-checkbox {
            //         svg {
            //             rect {
            //                 fill: unset;
            //                 stroke: var(--color-subtle-grey);
            //             }
            //         }
            //     }
            // }
        }

        .section--lnp-local-preview-wrapper {
            border: 1px solid var(--color-field-grey);
            padding: 30px;
            border-radius: 23px;
        }
        .section--lnp-local-preview-title {
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 8px;
        }
        .section--lnp-local-preview-subtitle {
            display: flex;
            justify-content: center;
            align-items: center;            
            .section--lnp-local-preview-subtitle-inner {
                flex: 0 0 auto;
                text-align: center;
                max-width: 500px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                margin-bottom: 24px;
                color: var(--color-dark-grey);
            }
        }
        .lnp-warning {
            margin-bottom: 34px;
        }

        .listings-room--desktop {
            padding-top: 30px;
            .product-location {
                border: none;
                box-shadow: none;
                background-color: unset;
                padding: 0;
            }
            .overview {
                padding-left: 0;
            }
        }
        .listings-hotel--desktop {
            .product-location {
                border: none;
                box-shadow: none;
                background-color: unset;
                padding: 0;
            }
            .listings__grid-area {
                margin-bottom: 20px;
            }
        }
        .listings-section-separator {
            opacity: 0;
        }
        .product-section {
            .product-section_title--spaced {
                font-size: 22px;
                line-height: 26px;
                font-weight: 600;
                font-style: normal;
                color: var(--color-black);
                margin-bottom: 20px;
            }
            .room-types_title {
                font-size: 22px;
                line-height: 26px;
                font-weight: 600;
            }
        }
        .product-location {
            .product-location_main {
                .product-location_map {
                    .listing-details-map {
                        min-height: 450px;
                    }
                }
            }
        }

        @media screen and (max-width:1360px){
            .cn__slider{
        
                &__cards{
                    padding: 5px 30px 20px 35px;
        
                    .cn__card{
                        margin: 20px 0 0 15px;
                        width: 269px;
                        min-width: 335px;
                    }
                }
        
                //--------- simplebar ----------
        
                .simplebar-content-wrapper{
                    scroll-behavior: unset;
                }
                .simplebar-track.simplebar-horizontal{
                    width: calc(100% - 60px);
                    margin-left: 30px;
                }
        
                //States
        
                &__noscrollbar{
                    .simplebar-track{
                        display: unset;
                    }
                }
            }
        }
    }
    @media screen and (max-width:600px) {
        .section--lnp-local-preview {
            padding: 10px;
            .section--lnp-local-preview-subtitle {
                .section--lnp-local-preview-subtitle-inner {
                    max-width: 90vw;
                }
            }
            .listings-header--mobile {
                .listings-title--mobile {
                    display: none;
                }
                .listings-gallary {
                    padding-top: unset;
                    .actions-list {
                        display: none;
                    }
                }
            }
        }
    }


    @include mobile {
        >header,
        .wizard__head {
            display: none;
        }
    }
    .lnp-error_wrapper {
        position: relative;
        width: 100%;
    }
    .lnp-error {
        width: calc(100% - 250px - 60px);
        padding: 0 30px;
        position: fixed;
        // position: absolute;
        bottom: 60px;
        transform: translateY(20px);
        opacity: 0;
        visibility: hidden;
        transition: transform 0.3s ease;
    }
    .lnp-error--active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

.lnp-guest-picker {
    .n-modal__content {
        ul {
            li:last-child {
                display: none;
            }
        }
    }
}


.n-modal--extra-fee {
    display: flex;
    justify-content: center;
    align-items: center;
    .n-modal__dialog {
        border-radius: 15px;
        width: 395px;
    }
    .n-modal__content {
        padding: 0px;
        border-radius: 15px;
        background: var(--color-white);
    }
    .n-modal__footer {
        padding: 0px;
    }
    .form-extra-fee {
        .form__content {
            padding: 30px 30px 15px 30px;
            .form__row {
                padding-bottom: 20px;
                margin-bottom: 15px;
                border-bottom: 1px solid var(--color-light-grey);
                &:last-child {
                    margin: 0px;
                    padding: 0px;
                    border: none;
                }
            }
        }
        .form__footer {
            border-top: 1px solid var(--color-light-grey);
            padding: 25px 15px;
            display: flex;
            justify-content: space-between;
        }
        .form__row--two-col {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
        }
        .n-input-text {
            .n-input-text_prefix {
                opacity: 0;
            }
            &.n-input-text--fill,
            &.n-input-text--focus {
                .n-input-text_prefix {
                    opacity: 1;
                }
            }
        }
        .n-btn[type="submit"]{
            font-weight: 600;
        }
        .n-select-picker .input-dropdown {
            overflow-y: hidden;
        }
    }
    @include mobile {
        .n-modal__dialog {
            position: fixed;
            bottom: 0px;
            left: 0px;
            width: 100%;
            margin: 0px;
            border-radius: 15px 15px 0 0;
        }
    }
}

.n-modal--stay-length {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    .n-modal__dialog {
        border-radius: 15px;
        width: 395px;
    }
    .n-modal__content {
        padding: 0px;
        border-radius: 15px;
        background: var(--color-white);
        overflow: inherit;
    }
    .n-modal__footer {
        padding: 0px;
    }
    .form--stay-length {
        .form__content {
            padding: 30px;
            .form__row {
                // padding-bottom: 20px;
                margin-bottom: 24px;
                &:last-child {
                    margin: 0px;
                    padding: 0px;
                    border: none;
                }
            }
        }
        .form__footer {
            border-top: 1px solid var(--color-light-grey);
            padding: 25px 15px;
            display: flex;
            justify-content: space-between;
            .n-btn--submit {
                font-weight: 600;
            }
        }

        .n-input-date-range-picker {
            background: $color-white;
            .input-value {
                padding: 0;
                .listings-date-range-input {
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr 1px 1fr;
                    width: 100%;
        
                    .listings-date-range-input_date-container {
                        position: relative;
                        display: grid;
                        grid-template-columns: auto 1fr 24px;
                        grid-column-gap: 5px;
                        column-gap: 5px;
                        padding: 0px 8px;
                        width: 100%;
                        white-space: nowrap;
                        align-items: center;
                        min-height: 36px;
                        border-radius: 6px;

                        &.placeholder {
                            color: var(--color-dark-grey);
                        }
                        &.active {
                            &::before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                border-radius: 6px;
                                border: 1px solid var(--color-dark-grey);
                            }
                        }

                        .listings-date-range-input_date-container_clear {
                            display: none;
                            justify-content: center;
                            align-items: center;
                            animation: appear .3s ease-in-out forwards;
                            opacity: 0;
                            border: none;
                            outline: none;
                            cursor: pointer;
                            background-color: #e9e9eb;
                            border-radius: 50%;
                            width: 24px;
                            height: 24px;

                            &.active {
                                display: flex;
                            }
                        }
                    }
                }        
            }

            .input-dropdown {
                margin-top: 8px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .n-modal__dialog {
            width: 100vw;
            transform: translateY(-30%);

            .n-modal__content {
                .form__row {
                    .srp-date-picker-calendar {
                        margin-top: 2px;
                        .input-dropdown {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }
}

.n-modal--cancellation-policy {
    // height: 100vh;
    // justify-content: center;
    // align-items: center;
    // display: flex;
    // .n-modal__dialog {
    //     border-radius: 15px;
    //     width: 380px;
    // }
    // .n-modal__content {
    //     padding: 16px;
    //     // padding: 0px;
    //     border-radius: 15px;
    //     background: var(--color-white);
    //     overflow: auto;
    // }
    // .n-modal__footer {
    //     padding: 0px;
    // }
    .grey-select {
        // bro check this out - redefining variable to customize css
        --color-light-grey: var(--color-subtle-grey) !important;

        >div {
            >p {
                display: none;
            }
        }
        
        label {
            background-color: var(--color-shade-grey) !important;

            &::before {
                background-color: var(--color-shade-grey) !important;
            }
        }
        // div[role="button"] {
        //     border: 1px solid blue;
        // }
    }
    .form__content {
        max-width: 420px;
        // scrollbar-gutter: stable both-edges;
        // *:hover {
        //     cursor: pointer !important;
        // }
        // &:hover {
        //     cursor: pointer !important;
        //     * {
        //         cursor: pointer !important;
        //     }
        // }

        .form__row {
            margin: 0px;
            >.n-radiobox-list {
                position: relative;
                z-index: 2;

                >ul {
                    >li {
                        padding: 0 16px;
                        // border-radius: unset;

                        // &:hover {
                        //     border-radius: unset;
                        // }
                    }
                }

                .n-radiobox {
                    // margin-bottom: 14px;
                    padding: 6px 0;
                    >label {
                        display: grid;
                        grid-template-columns: 1fr auto auto;
                        grid-template-rows: 1;
                        padding: 0;
                        .ic-icon {
                            grid-column: 2;
                            grid-row: 1;
                            // margin-left: 30px;
                            transform: scale(0.55);
                        }
                        .text {
                            grid-column: 1;
                            grid-row: 1;
                            padding-right: 35px;
                            :nth-child(1) {
                                font-size: 1rem;
                                line-height: 150%;
                                font-weight: 500;
                            }
                            :nth-child(2) {
                                font-size: 0.75rem;
                                line-height: 150%;
                                font-weight: 500;
                                color: var(--color-dark-grey);
                            }
                        }
                        >input {
                            width: 0;
                            height: 0;
                            grid-row: 1;
                            grid-column: 3;
                        }
                    }
                }
            }
        }
        .form__row--custom {
            background: var(--color-shade-grey);
            position: relative;
            border-radius: 15px;

            .n-select-picker {
                border: 1px solid var(--color-subtle-grey);
            }
            // &:before {
            //     background: var(--color-shade-grey);
            //     content: ' ';
            //     left: 0px;
            //     top: -61px;
            //     height: 61px;
            //     width: 100%;
            //     position: absolute;
            //     z-index: 0;
            // }
        }
        .custom-row-scroll {
            padding: 10px 16px;
            height: min-content;
            // max-height: 300px;
            // overflow-y: auto;
            .n-btn--adding {
                font-size: 14px;
                line-height: 150%;
                font-weight: 500;
            }
        }
        .n-radiobox-list  {
            >ul {
                > li {
                    margin-bottom: 5px;
                }
            }
        }
        ul.ul-custom-rules {
            list-style: none;
            position: relative;
            // padding: 0 40px;
            >li {
                position: relative;
                padding-right: 40px;
                border-bottom: 1px solid rgba(172, 171, 180, 0.4);
                // margin-top: 5px;
                margin-bottom: 8px;
                // margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                // >div:nth-child(2) {
                //     margin-bottom: 24px;
                // }
                .li__row {
                    display: grid;
                    grid-template-columns: 1fr 125px;
                    grid-column-gap: 14px;
                    align-items: center;
                    margin-bottom: 10px;
                    >span {
                        color: #67696B;
                        // color: var(--color-black);
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                    }
                    .n-select-picker.filled.label-on-fill .n-select-picker__label {
                        background: var(--color-shade-grey);
                    }
                    .n-select-picker {
                        .input-dropdown {
                            max-height: 180px;
                            padding: 6px;
                            .n-radiobox-list {
                                li {
                                    border-radius: 8px;
                                    margin-bottom: 0;
                                }
                                .n-radiobox {
                                    label {
                                        padding: 8px;
                                        .ic-icon {
                                            transform: scale(0.55);
                                        }
                                        .text > span {
                                            font-size: 14px;
                                            line-height: 150%;
                                            font-weight: 500;
                                            color: var(--color-dark-grey);
                                        }
                                    }
                                }
                            }
                            // .n-radiobox {
                            //     label {
                            //         padding: 8px;
                            //         .ic-icon {
                            //             transform: scale(0.55);
                            //         }
                            //         .text > span {
                            //             font-size: 14px;
                            //             line-height: 150%;
                            //             font-weight: 500;
                            //             color: var(--color-dark-grey);
                            //         }
                            //     }
                            // }
                        }
                        .input-value {
                            .text {
                                font-size: 14px;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }
        }
        .n-btn--adding {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            flex-direction: row-reverse;
            padding: 8px 9px;
            // margin-left: 35px;
            position: relative;
            z-index: 1;
            .ic-icon {
                margin-left: 0px;
                margin-right: 10px;
                path {
                    stroke: var(--color-bright-purple);
                }
            }
        }
    }
    .form__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background-color: white;
        border-top: 1px solid var(--color-light-grey);
        // margin-top: 16px;
        // padding-top: 6px;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        .n-btn--back,
        .n-btn--submit {
            font-size: 1rem;
            line-height: 150%;
            font-weight: 500;
        }
        .n-btn--back {
            color: var(--color-dark-grey);
        }
        .n-btn--submit {
            color: var(--color-cn-pink);
        }
    }
}

.n-modal--radio-list {
    .n-modal__dialog {
        width: 312px;
    }
}

.lnp-price_add-extra-charge-modal {
    .lnp-price_add-extra-charge-modal_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lnp-price_add-extra-charge-modal_content {
        .error {
            display: none;
        }
        font-family: var(--font-primary);
        margin: 40px;
        border-radius: 15px;
        padding-top: 30px;
        max-width: 363px;
        width: 100%;
        background-color: var(--color-white);
        outline: 0;
        .lnp-price_add-extra-charge-modal_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            text-align: center;
        }
        .lnp-price_add-extra-charge-modal_input {
            margin-top: 20px;
            padding-left: 30px;
            padding-right: 30px;
        }
        .n-input-text--label-on-fill .n-input-text__label {
            left: 3rem;
        }
        .n-input-textarea {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 20px;
            border: 1px solid var(--color-light-grey);
            border-radius: 8px;
            padding: 0;
            textarea {
                width: 100%;
                min-height: 110px;
                resize: none;
                display: block;
                outline: transparent;
                border: none;
                font-family: var(--primary-font);
                padding: 10px;
                border-radius: inherit;
                &:focus {
                    background-color: var(--color-white);
                }
                background-color: var(--color-light-grey);
            }
        }
        .lnp-price_add-extra-charge-modal_actions {
            margin-top: 20px;
            border-top: 1px solid var(--color-light-grey);
            display: flex;
            justify-content: space-between;
            padding: 15px 10px;
            .n-btn {
                &:first-child {
                    color: var(--color-black);
                }
                &:last-child {
                    font-weight: 600;
                }
            }
        }
    }

    @include mobile {
        .lnp-price_add-extra-charge-modal_title {
            display: none;
        }
        .lnp-price_add-extra-charge-modal_content {
            position: absolute;
            margin: 0px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            max-width: 100%;
            border-radius: 15px 15px 0 0;
        }
    }
}

.lnp-photos_no-header {
    .wizard__head {
        display: none;
    }
    .wizard__content {
        height: calc(100% - 95px);
        max-height: calc(100% - 95px);
    }
}

.lnp-photos_edit-modal {
    .lnp-photos_edit-modal_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lnp-photos_edit-modal_content {
        border-radius: 23px;
        max-width: 1024px;
        min-width: 700px;
        max-height: 90vh;
        position: relative;
        background-color: var(--color-white);

        .lnp-photos_edit-modal_top {
            position: relative;
            border-bottom: 1px solid var(--color-light-grey);
            .lnp-photos_edit-modal_top-close {
                position: absolute;
                top: 16px;
                right: 16px;
                svg {
                    path {
                        stroke: var(--color-dark-grey);
                    }
                }
            }
            .lnp-photos_edit-modal_top-close:hover {
                cursor: pointer;
            }
            .lnp-photos_edit-modal_top-title {
                font-size: 22px;
                line-height: 26px;
                font-weight: 700;
                text-align: center;
                padding: 16px 0;
            }
        }
        .lnp-photos_edit-modal_middle {
            padding: 16px;
            display: grid;
            grid-template-columns: minmax(330px, 460px) minmax(290px, 460px);
            column-gap: 30px;
            .lnp-photos_edit-modal_middle-left {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    max-width: 100%;
                    max-height: calc(90vh - 60px - 70px - 32px);
                    object-fit: contain;
                    margin: auto;
                    border-radius: 10px;
                }                
            }
            .lnp-photos_edit-modal_middle-right {
                height: 100%;
                display: flex;
                flex-direction: column;
                .lnp-photos_edit-modal_middle-right-caption {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .lnp-photos_edit-modal_middle-right-title {
                        flex: 0 0 auto;
                        font-size: 16px;
                        line-height: 150%;
                        font-weight: 500;
                        margin-bottom: 16px;
                    }
                    .n-input-textarea {
                        flex: 1;
                        padding: 0;
                        border: none;
                        textarea {
                            border: 1px solid var(--color-subtle-grey);
                            border-radius: 16px;
                            width: 100%;
                            height: 100%;
                            resize: none;
                            padding: 10px;
                            font-family: $primary-font;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                        }
                    }
                    .lnp-photos_edit-modal_middle-right-buttons {
                        flex: 0 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .n-btn {
                            padding: 8px;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                            flex-direction: row-reverse;
                            .ic-icon {
                                margin: 0;
                                margin-right: 8px;
                            }
                        }
                        .n-btn:nth-child(1) {
                            color: var(--color-purple);
                            .ic-icon {
                                svg {
                                    path {
                                        fill: var(--color-purple);
                                    }
                                }
                            }
                        }
                        .n-btn:nth-child(2) {
                            color: var(--color-black-grey);
                            .ic-icon {
                                svg {
                                    path {
                                        stroke: var(--color-black-grey);
                                    }
                                }
                            }
                        }
                    }    
                }
                .lnp-photos_edit-modal_middle-right-type {
                    margin-top: 30px;
                    flex: 0 0 auto;
                }
            }
        }
        .lnp-photos_edit-modal_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid var(--color-light-grey);
            padding: 16px;
            .n-btn {
                padding: 8px;
            }
            .n-btn:nth-child(1) {
                font-weight: 500;
                color: var(--color-dark-grey);
            }
        }
    }
    @media screen and (max-width: 1100px) {
        .lnp-photos_edit-modal_content {
            max-width: 90vw;
            .lnp-photos_edit-modal_middle {
                column-gap: 16px;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .lnp-photos_edit-modal_content {
            max-width: 100vw;
            width: 100vw;
            min-width: auto;
            overflow: auto;
            .lnp-photos_edit-modal_middle {
                display: block;
            }
        }
    }
}

.lnp-photos_photo-caption-modal {
    .lnp-photos_photo-caption-modal_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lnp-photos_photo-caption-modal_content {
        margin: 40px;
        border-radius: 15px;
        padding: 40px;
        max-width: 380px;
        width: 100%;
        background-color: var(--color-white);
        outline: 0;
    }
    .lnp-photos_photo-caption-modal_actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--color-light-grey);
        padding-top: 10px;
        margin-top: 30px;
        .n-btn {
            &:last-child {
                font-weight: 600;
            }
        }
    }
}

.lnp-photos_photo-caption-modal_form {
    .n-input-text > input {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
    }
}

.n-modal--form-lnp-availability-setting {
    .form--lnp-availability-detail {
        display: flex;
        flex-direction: column;
        .form__row {
            width: 100%;
        }
    }
    .n-modal__header .n-modal__back {
        top: 24px;
    }
    .form__row {
        padding: 20px 0;
        border-bottom: 1px solid var(--color-light-grey);
        &:last-child {
            border: none;
        }
        strong {
            display: block;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            color: var(--color-black);
        }
        small {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-subtle-grey);
        }
        .n-btn {
            margin-top: 20px;
            border-radius: 10px;
        }
    }
    @include mobile {
        .n-modal__header .n-modal__back {
            color: transparent;
        }
    }
}

.n-modal--lnp-availability--radio-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    &--preparation {
        .n-modal__dialog {
            width: 455px;
        }
    }
    &--notice {
        .n-modal__dialog {
            width: 352px;
        }
    }
    @include mobile {
        .n-modal__dialog {
            position: absolute;
            bottom: 0px;
            margin: 0px;
            width: 100%;
        }
        .n-modal__content {
            padding-left: 0px;
            padding-right: 0px;
        }
        .n-radiobox-list {
            padding-left: 20px;
            padding-right: 20px;
            .n-radiobox > label {
                flex-direction: row-reverse;
            }
        }
        .form--footer {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid var(--color-light-grey);
            padding: 20px 31px 0px;

            .n-btn {
                padding: 0px;
            }
            .n-btn--save {
                font-weight: bold;
            }
        }
    }
}

.n-modal--availability--sync {
    display: flex;
    justify-content: center;
    align-items: center;

    .n-modal__dialog {
        width: 363px;
        background: var(--color-white);

        .n-modal__header {
            height: auto;
            padding: 30px 30px 5px;
            &:after {
                background-color: transparent;
            }
            .n-modal__close {
                position: absolute;
                right: 20px;
                top: 20px;
                cursor: pointer;
            }
            h4 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black)
            }
        }
        .n-modal__footer {
            background: var(--color-white);
        }
        .n-modal__content {
            padding-top: 0px;
        }
        p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-subtle-grey);
            margin-top: 0px;
        }
        .form--sync-calendar {
            margin: 20px 0 40px;
            .n-input-text {
                margin-bottom: 20px;
            }
            .form--footer {
                display: flex;
                justify-content: flex-end;
            }
            .n-btn {
                border-radius: 10px;
                font-weight: 600;
            }
        }
        .list--sync-calendar {
            >strong {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-subtle-grey);

                .ic-icon {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-dark-grey);
                    cursor: pointer;
                }
            }
        }
    }
}

.n-modal--availability--sync--detail {
    .n-modal__header {
        padding: 30px 30px 0 30px;
        height: auto;

        &:after {
            display: none;
        }
    }
    .n-modal__content {
        padding-bottom: 0px;
    }
    .n-modal__dialog {
        width: 529px;
        max-width: 529px;
        background: var(--color-white);

        .carousel-slider {
            overflow-x: hidden;
            padding-bottom: 80px;

            .inner {
                img {
                    width: 100%;
                    border-radius: 10px;
                    margin-bottom: 15px;
                    min-height: 160px;
                }
            }
            .slick-dots {
                position: relative;
                bottom: auto;
                top: auto;
                margin-top: 15px;

                li button:before {
                    background-color: var(--color-shade-gray)
                }
                li.slick-active button:before {
                    background: var(--color-subtle-grey);
                }
            }
            .slick-arrow {
                top: calc(100% - 30px);
                border-radius: 10px;
                box-shadow: none !important;
                color: var(--color-black) !important;
                width: auto;
                padding: 10px 15px;

                &.slick-prev {
                    left: 0px;
                }
                &.slick-next {
                    right: 0px;
                    font-weight: 600;
                }
                &:hover {
                    background: var(--color-shade-gray);
                }
            }
        }
    }
    .n-modal__footer {
        background: var(--color-white);
    }
}

.lnp-warning {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}
.lnp-warning--visible {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}
.lnp-warning-inner {
    padding: 8px 40px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    background-color: #F35597;
    border-radius: 2px;
    color: white;
}
@media screen and (max-width: 600px) {
    .lnp-warning-inner {
        text-align: center;
        padding: 8px 16px;
        font-size: 16px;
        line-height: 16px;
    }
}

// .custom_counter {
//     position: absolute;
// }

.sleeping-modal-checkbox {
    ul {
        cursor: pointer;
        * {
            cursor: pointer;
        }
        li {
            >div.n-checkbox {
                display: flex;
                justify-content: space-between;
        
                >label {
                    flex: 1;
                    padding: 16px 22px;
                }
            }
            &:hover {
                background: var(--color-shade-gray);
                border-radius: unset;
            }
        }
    }
    div.form-inline-insert {
        padding: 0 22px;
    }
}
.counter_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .counter_inner {
        display: grid;
        grid-template-columns: 24px minmax(auto, 38px) 24px;
    }

    .counter_left-side,
    .counter_right-side,
    .counter_middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .counter_btn {
        border: none;
        outline: none;
        text-decoration: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
    }

    .counter_btn:hover {
        border-radius: 50%;
        border: 1px solid var(--color-subtle-grey);
        cursor: pointer;
    }

    .minus-icon,
    .plus-icon {
        transform: scale(0.75);
        path {
            stroke: var(--color-subtle-grey);
        }
    }
    .ic-icon svg {
        transform: scale(0.85);
        path {
            stroke: var(--color-subtle-grey);
        }
    }

    .counter_middle {
        .counter_input {
            color: var(--color-subtle-grey);
            font-family: var(--primary-font);
            font-weight: medium;
            width: 100%;
            background: none;
            text-align: center;
        }
        .counter_input::-webkit-outer-spin-button,
        .counter_input::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
            -moz-appearance:textfield;
        }
    }

}

.anim-fadein {
    animation-duration: 0.8s;
    animation-name: fadein;
    // animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.lyp-units-navigation {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;
}

.lyp-units-navigation-item {
    appearance: none;
    background: none;
    outline: none;
    padding-block: unset;
    padding-inline: unset;
    border: none;
    background-color: var(--color-light-grey);
    color: var(--color-dark-grey);
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 1rem;
    line-height: 120%;
    // margin-right: 1rem;
    // margin-top: 8px;
    padding: 10px 16px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    .lyp-units-navigation-item-checkmark {
        font-size: 1rem;
        width: 1.25em;
        height: 1.25em;

        svg {
            path {
                fill: var(--color-dark-grey);
            }
        }
    }

    &:hover {
        cursor: pointer;
    }

    &--selected {
        color: var(--color-black);

        .lyp-units-navigation-item-checkmark {
            svg {
                path {
                    fill: var(--color-black);
                }
            }
        }
    }

    &--checked {
    }

}

@media screen and (max-width: 600px) {
    .lyp-units-navigation {
        margin-top: 1rem;
        padding: 0 1rem;
    }
}
