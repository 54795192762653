.card--review {
    .card__header {
        h3 {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.01em;
            color: var(--color-black);
            margin-bottom: 15px;
            font-variant: unset;
            text-transform: none;
            .star-rate {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;
                color: var(--color-black);
                margin-left: 15px;
                .ic-icon {
                    top: 4px;
                    position: relative;
                }
                svg {
                    width: 25px;
                    height: 25px;
                    path {
                        fill: var(--color-vibrant-pink)
                    }
                }
            }
        }
    }
    .description-list {
        li {
            border-bottom: 1px solid #E9E9EB;
            padding-bottom: 15px;
            margin-bottom: 15px;
            &:last-child {
                border-bottom: none;
            }
        }
        >div {
            margin-bottom: 8px;
        }
        h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);

            letter-spacing: unset;
            font-feature-settings: unset;
            font-variant: unset;
        }
        time {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            display: block;
            color: var(--color-dark-grey);
        }
    }
}