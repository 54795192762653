@import '../../global/mixins/global-mixins.scss';
.seo-section {    
    /* font-size: 1.0625rem !important;
    line-height: 1.25rem; */
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    position: relative;
    .cn-tabs{
        .cn-tabs-nav{
            li{
                //padding:0.938rem 0;
                margin-right: 5rem;
                font-size: 14px;
                text-transform: capitalize;
                display: inline-table; 
                padding: 0; 
                font-weight: 400;   
                color: #ACABB4;    
                @media screen  and (max-width: 600px) {
                    //margin-right: 3.3125rem;
                    //margin-right: 1.0625rem;
                    margin-right: 0;
                } 
                &.cn-active{       
                    background-color: transparent;
                    font-weight: 600;
                    color: #797882;
                    //padding: 0 0 15px 0;
                    div{
                        height: 5px;
                        width: 40px;
                        margin: 0 auto;
                        margin-top: 15px;
                        //background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
                        background:#797882;
                        border-radius: 20px;
                    }
                }
                &.last-child{
                    margin-right: 0; 
                }
            }
            @media screen  and (max-width: 600px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                li{
                    margin-bottom: 25px;
                    p{
                        display: table;
                    }
                    &.cn-active{
                        border-bottom: none;
                        /* p{
                            border-bottom: 3px solid #F35597;
                            padding: 0 0 15px 0;
                        } */
                        div{
                            margin-top: 10px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    .cn-tabs-content{
     background-color: transparent;
     padding: 25px 0;
     @media screen  and (max-width: 600px) {
        padding: 0 0 1.25rem 0;
     }
    }
    .seo-links{
        width: 100%;
        ul{
            display: grid;
            grid-template-columns: repeat(6 , 1fr); 
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                grid-template-columns: repeat(4 , 1fr);
            }
            @media screen  and (max-width: 600px) {
                grid-template-columns: repeat(2 , 1fr);
            }
            li{
                list-style: none;
                margin-bottom: 0.625rem;
                padding-right: 0.625rem;

                
                a{ 
                    color: #ACABB4;  
                    font-size: 14px;
                }
                @media screen  and (max-width: 600px) {
                    &:nth-child(n+20) {
                        display: none;
                    }
                }
            }
        }
    }

}
