.cn__dashboard_notification_section {
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding-inline: 40px;
	padding-block: 22px;
	height: 100%;
    overflow-y: auto;

	&_route-controls {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 80px;

		@media screen and (max-width: 680px) {
			gap: 40px;
		}

		&_button {
			width: 221px;
			height: 46px;
			border: 0.5px solid #dfe2e5;
			filter: drop-shadow(0px 5px 30px rgba(231, 228, 228, 0.5));
			border-radius: 100px;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			/* identical to box height, or 21px */
			display: flex;
			justify-content: flex-end;
			padding-inline: 20px;
			align-items: center;
			letter-spacing: 0.01em;
			cursor: pointer;
			background-color: #e9e9eb;
			color: #64748b;
			background-image: url("/static/icon/settings-filled.svg");
			background-repeat: no-repeat;
			background-position: 20px 50%;
			transition: all 0.25s ease-in-out;
			&:hover {
				transition: all 0.25s ease-in-out;
				background-color: white;
				color: #000000;
			}
		}

		.inactive {
			background-color: white;
			color: #000000;
			&:hover {
				transition: all 0.25s ease-in-out;
				background-color: #e9e9eb;
				color: #64748b;
			}
		}

		&_mark-all-read {
			font-weight: 400;
			font-size: 10px;
			line-height: 150%;
			/* identical to box height, or 15px */

			letter-spacing: 0.01em;
			text-decoration-line: underline;
			cursor: pointer;

			/* Blacks/Black */

			color: #000000;
		}
	}

	&_loader {
		height: 50vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&_card {
		width: 595px;
		height: 77px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 7px;
		display: flex;
		justify-content: flex-start;
		padding: 8px;
		gap: 40px;
		align-items: flex-start;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		max-width: 90%;

		&_image {
			width: 55px;
			height: 55px;
			border-radius: 79.5718px;
		}

		&_booking-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 2px;
			margin-left: -27px;

			&_title {
				font-weight: 600;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: 0.01em;
				color: #000000;
				width: 108px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&_details {
				font-weight: 400;
				font-size: 12px;
				line-height: 150%;
				/* identical to box height, or 18px */

				letter-spacing: 0.01em;

				/* Dark-grey */

				color: #797882;
			}
		}

		&_status {
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;
			/* identical to box height, or 18px */

			letter-spacing: 0.01em;

			/* Purples/Purple Washed */

			color: #7e71bc;
			align-self: flex-start;
		}

		&_data {
			margin-left: auto;
			margin-right: 20px;
			display: flex;
			flex-direction: column;
			gap: 24px;
			justify-content: center;
			align-items: flex-end;
			&_time {
				font-weight: 400;
				font-size: 10px;
				line-height: 150%;
				/* identical to box height, or 15px */

				text-align: right;
				letter-spacing: 0.01em;

				/* Dark-grey */

				color: #797882;
			}
			&_unread_count {
				width: 20px;
				height: 20px;
				left: 545px;
				top: 47px;
				text-align: center;
				margin-right: 10px;

				/* Pinks/Cyclamen */

				background: #e86993;
				border-radius: 101.299px;
				color: white;
				font-size: 12px;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&_card:hover {
		background: #eeeef0;
		transition: all 0.2s ease-in-out;
	}

	@media (max-width: 630px) {
		padding-top: 18px;
		padding-inline: 20px;

		&_card {
			min-width: 335px;
			gap: 13px;

			&_booking-info {
				margin-left: unset;
			}

			&_booking-info_title {
				width: 108px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}

.cn__dashboard_notification-details {
	display: flex;
	padding-top: 9px;
	gap: 14px;
	flex-direction: column;
	&_sub-section {
		padding-inline: 71px;
		&_lower {
			padding-top: 60px;
			display: flex;
			flex-wrap: wrap;
		}
	}
	&_card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 20px;
		gap: 20px;

		width: 403px;
		height: 159px;

		/* Bright-white */

		background: #ffffff;
		border: 0.5px solid #dfe2e5;
		box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
		border-radius: 15px;

		&_title {
			font-weight: 600;
			font-size: 18px;
			line-height: 120%;
			width: 320px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&_description {
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			/* or 21px */
			letter-spacing: 0.01em;
			color: #000000;
		}

		&_show-details-link {
			display: flex;
			width: 100%;
			justify-content: flex-end;
			font-weight: 600;
			font-size: 10px;
			cursor: pointer;
			span {
				color: #000000;
			}
		}
	}
	
	.messagesDetailsView-container.notification_container_bar {
		margin-top: 0;
		.messageDetails-profile .messageDetails-profileDetails h3 {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			color: #000000;
		}
		.messageDetails-rightDetailsHeder {
			h4 {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 600;
				font-size: 10px;
				color: #000000;
			}
		}
	}

	@media (max-width: 680px) {
		padding-inline: 20px;

		.cn__dashboard_notification_section_route-controls {
			display: none;
		}

		.messagesDetailsView-container {
			.messagesDetailHeaderComponent {
				padding-inline: 0 !important;
			}
		}

		&_sub-section {
			padding-inline: 0px;
			&_lower {
				padding-top: 0px;
			}
		}

		&_card {
			width: 335px;
		}
	}
}

.cn__dashboard_notification_settings_section {
	display: flex;
	flex-wrap: wrap;
	padding-inline: 32px;
	margin-top: -21px;
	gap: 14px;
	height: 100%;
    overflow-y: auto;


	&_breadcrumbs {
		padding-top: 20px;
	}

	.cn__dashboard_notification_section_route-controls {
		@media screen and (max-width: 1100px) {
			margin-left: 55px;
			margin-top: 11px;
		}
	}

	&_title {
		display: flex;
		width: 100%;
		gap: 20px;
		margin-top: 27px;
		.toolbar-back-icon {
			cursor: pointer;
		}
		h2 {
			font-weight: 600;
			font-size: 18px;
			line-height: 120%;
			color: #242526;

			@media screen and (max-width: 1100px) {
				margin-left: 55px;
			}
		}
	}

	&_card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 20px;
		gap: 20px;

		width: 432px;
		min-height: 159px;

		/* Bright-white */

		background: #ffffff;
		border: 0.5px solid #dfe2e5;
		box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
		border-radius: 15px;

		&_title {
			font-weight: 600;
			font-size: 18px;
			line-height: 120%;
		}

		&_description {
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			/* or 21px */
			letter-spacing: 0.01em;
			color: #000000;
		}

		&_switch-list {
			display: flex;
			flex-direction: column;
			gap: 40px;
			width: 100%;

			&_item {
				display: flex;
				justify-content: space-between;
				.cn__watchlist_control_header_body_item_toggle input:checked + .slider {
					background-color: $color-vibrant-pink;
				}
				&_typography {
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
					/* identical to box height, or 21px */

					letter-spacing: 0.01em;

					/* Blacks/Black */

					color: #000000;
				}
			}
		}
	}

	@media screen and (min-width: 680px) and (max-width: 1100px) {
		padding-inline: 9px;
		gap: 9px;
		&_card {
			width: 395px;
		}
	}

	@media screen and (max-width: 1100px) {
		&_breadcrumbs {
			display: none;
		}
	}
}
