.cn__shortfooter__wrapper{
    position: relative;
    width: 100%;
    .cn__shortfooter{
        position: relative;
        width: 100%;
        max-width: 1640px;
        padding: 100px 100px 30px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .cn__footer__social {
            display: flex;
            width:352px;
            justify-content: space-between;
        }
        &__title{
            font-weight: 700;
            font-size: 23px;
            line-height: normal;
            letter-spacing: -0.46px;
            color: rgba(36, 37, 38, 0.40);
            span{
                //background: linear-gradient(92.14deg,#f35597 1.79%,#f53959 99.81%);
                background-color: #F35597;
                background-size: 100% 200%;
                background-position: 50% 100%;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
            }
        }
        &__rights{
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: #797882;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__shortfooter__wrapper{
        .cn__shortfooter{
            padding: 0px 30px 30px;
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:700px){
    .cn__shortfooter__wrapper{
        .cn__shortfooter{
            padding: 70px 20px 20px;
            align-items: flex-start;
            flex-direction: column;
            .cn__footer__social {
                width: 100%;
            }
            &__title{
                max-width: 100%;
                font-size: 23px;
                line-height: 34px;
                letter-spacing: -0.02em;
            }
            &__rights{
                margin-top: 8px;
            }
        }
    }
}