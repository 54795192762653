.listings-room {
    background: white;
    &.listings-room--mobile {
        // row-gap: 0;
        .listings-info {
            .features-booking {
                margin-top: 0;
            }
        }
    }

    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-gap: 20px;
    // &__content {
    //     grid-area: 2 / 1 / 2 / -1;
    //     @media (min-width: 1154px) {
    //         grid-area: 2 / 1 / 2 / span 2;
    //     }
    // }
    &__form {
        animation: PDbottomSlide .3s ease-in-out .3s forwards;
        transform: translateY(100%);
        position: fixed;
        bottom: 0px !important;
        z-index: 2;
        left: 0px;
        right: 0px;
        width: 100%;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        .card {
            margin: 0px;
        }
        @include tablet {
            z-index: 10;
        }
        @media (min-width: 1154px) {
            animation: none;
            transform: none;
            // grid-area: 2 / 3 / 2 / -1;
            position: -webkit-sticky;
            position: sticky;
            top: 75px;
            // max-height: 620px;// 671px;
            height: min-content;
            // margin-top: 80px;
            box-shadow: none;
            z-index: 1;
            width: 100%;

            .safety-message {
                margin-top: 20px;
            }
        }
        @media screen and (max-width: 767px) {
            _::-webkit-full-page-media, _:future, :root .safari_only {
                padding-bottom: 65px; //resize
            }
        }
    }
    .list--left-icon {
        color: var(--color-dark-grey);
        li {
            @include text_body-text-bold;
            color: var(--color-black);
            path[fill],
            rect[fill] {
                fill: var(--color-black);
            }
            path[stroke],
            rect[stroke] {
                stroke: var(--color-black);
            }
        }
    }
    .expandable-card-content {
        summary {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            @include desktop {
                line-height: 22px;
            }
        }
        .card__footer .n-btn {
            font-weight: 600;
        }
    }
    .expandable-list {
        &__title {
            @include text-subtitle;
        }
        &__footer {
            .n-btn {
                width: 100%;
            }
        }
    }
    .description-list {
        h5 {
            @include text-subtitle;
            letter-spacing: unset;
            font-feature-settings: unset;
            font-variant: unset;
        }
        > div {
            p {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    &__mobile-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        .n-input-guest-room-picker {
            height: 46px;
            .input-value {
                padding: 10px;
            }
        }
        .n-input-date-range-picker {
            height: 46px;
            .input-value {
                padding: 10px;
            }
        }
    }
    .form-book-mobile {
        padding: 20px 0px;
        .form__field {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
        }
        .n-input-guest-room-picker {
            height: 46px;
            border-color: var(--color-light-grey);
            .input-value {
                padding: 10px;
                background: var(--color-light-grey);
            }
        }
        .n-input-date-range-picker {
            height: 46px;
            .input-value {
                padding: 10px;
            }
        }
    }
    .card--location {
        >.card__content {
            >p {
                color: var(--color-dark-grey);
            }
            >em {
                font-family: $primary-font;
                font-style: italic;
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                color: var(--color-dark-grey);
            }
            .card {
                margin-top: 10px;
                @include text-normal;
                color: var(--color-black);
                .card__title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 8px;
                }
                summary {
                    margin-bottom: 11px;
                }
            }
        }
        small {
            font-style: italic;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: var(--color-dark-grey);
        }
        .map-container {
            margin-bottom: 15px;
        }
        .card--collapse {
            .collapse-css-transition>div {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black);
            }
        }
    }

    .about-host {
        display: grid;
        grid-template-columns: 120px 20px 1fr;
        grid-template-rows: 120px 20px 1fr;
        .contact-host-btn {
            max-width: 140px;
        }
        .a-img,
        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            display: block;
            margin-bottom: 5px;
            cursor: pointer;
        }
        &__info {
            grid-area: 1 / 3;
            width: 100%;
            overflow: hidden;
            .a-title {
                strong {
                    display: block;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                    color: var(--color-black);
                    @include mobile {
                        text-align: left;
                    }
                }
            }
            small {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-dark-grey);
                @include tablet {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
        summary {
            grid-area: 3 / 1 / 3 / -1;
            p {
                @include text-normal;
                margin-bottom: 10px;
            }
        }
        .n-btn {
            width: 100%;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
        @include tablet {
            grid-template-columns: 150px 20px 1fr;
            grid-template-rows: 60px 5px 1fr;
            .a-img,
            img {
                width: 60px;
                height: 60px;
                justify-self: center;
            }
            .about-host__info {
                grid-area: 3 / 1;
                justify-self: center;
                @include align-center;
                flex-direction: column;
                justify-content: flex-start;
            }
            summary {
                grid-area: 1 / 3 / -1 / -1;
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
            .n-btn {
                path {
                    fill: var(--color-dark-grey);
                }
            }
        }
    }
    .map-container {
        height: 430px;
        border-radius: 10px;
        z-index: 0;
        .map-control {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            transform: none;
            &.map-pin {
                top: 0;
            }
            &.map-viewtype {
                top: 45px;
            }
            &.map-fullscreen {
                display: none;
            }
        }
        .search_container {
            z-index: 1;
        }
        @include mobile {
            .map-control {
                &.map-pin {
                    display: none;
                }
                &.map-viewtype {
                    top: 0;
                }
            }
            .map-zoom-controls {
                transform: initial;
                top: 0;
                margin-top: 20px;
                display: none;
            }
        }
    }
    @include mobile {
        .about-host .contact-host-btn {
            max-width: unset;
            .n-btn .ic-icon {
                width: 34px;
                height: 34px;
                position: relative;
                right: -14px;
                margin-bottom: -15px;
                top: -7px;
                path {
                    stroke: var(--color-dark-grey);
                }
            }
        }
    }
    @include tablet {
        /*.nav {
            overflow-x: auto;
            overflow-y: hidden;
            li {
                white-space: nowrap;
            }
        }*/
        .expandable-list__footer {
            .n-btn {
                .ic-icon {
                    display: none;
                }
            }
        }
    }
    @include desktop {
        /*.nav {
            overflow: hidden;
        }*/
        .expandable-list__footer {
            .n-btn {
                .ic-icon {
                    display: none;
                }
            }
        }
    }
}

.n-modal-host-contact {
    .n-modal__footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 30px;
        @include tablet {
            border-radius: 0 0 15px 15px;
        }
    }
    .n-modal__content {
        overflow: inherit;
    }
    .about-host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #E9E9EB;
        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-bottom: 15px;
        }
        &__info {
            text-align: center;
            width: 100%;
            overflow: hidden;
            strong {
                margin: 0px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
    }
    form {
        padding-top: 25px;
        >p {
            margin-bottom: 15px;
            @include text-normal;
            color: var(--color-dark-grey);
        }
        .n-input-guest-room-picker {
            height: 46px;
            .input-value {
                padding: 10px;
            }
        }
        .n-input-date-range-picker {
            height: 46px;
            .input-value {
                padding: 10px;
            }
        }
        .n-input-textarea {
            padding: 0;
            textarea {
                padding: 10px;
                background: transparent;
                border: none;
                min-height: 75px;
                max-height: calc(calc(var(--vh, 1vh) * 100) - 420px);
                width: 100%;
                height: calc(calc(var(--vh, 1vh) * 100) - 430px);
                outline: none !important;
                &:focus {
                    border: none;
                }
                @include desktop {
                    height: 275px;
                }
            }
        }
    }
    .n-btn--submit {
        font-weight: 600;
    }
    .n-btn--back {
        color: var(--color-dark-grey) !important;
        @include desktop {
            color: var(--color-black) !important;
        }
    }
    &.n-modal--fullscreen {
        .n-modal__content {
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }
    @include tablet {
        .about-host {
            flex-direction: row;
            margin: 0 -30px;
            padding: 0px 30px 25px;
            img {
                margin: 0px;
            }
            .about-host__info {
                padding-left: 25px;
                text-align: left;
                strong {
                    margin: 0px;
                }
            }
        }
        form {
            .input--textarea {
                height: 210px;
            }
        }
    }
}

.contact-host-login {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-bright-purple);
    padding: 0 5px;
    cursor: pointer;
}

@keyframes PDbottomSlide {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
