.cn__dashboard_payment_delete_card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 437px;
	text-align: center;
	padding: 10px 45px;
	gap: 20px;
	width: 886px;

    @media screen and (max-width: 680px) {
        width: 100%;
    }

	&_heading {
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		/* or 24px */

		text-align: center;
		letter-spacing: 0.01em;

		/* Blacks/Black */

		color: #000000;
	}

	&_buttonRow {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 21px;
		padding-block: 8px;
		padding-inline: 11px;
		margin-top: 8px;

		/* Bright-white */

		background: #ffffff;
		border-radius: 100px;
		&_btn {
			border: none;
			border-radius: 20px;
			padding-inline: 9px;
			padding-block: 4.5px;
			font-weight: 600;
			font-size: 14px;
			cursor: pointer;
			width: 143px;
			height: 46px;
		}
		.deleteButton {
			background: linear-gradient(92.14deg, #f35597 1.79%, #7868c7 99.81%);
			color: white;
		}
		.cancelButton {
			background: white;
			filter: drop-shadow(0px 5px 30px rgba(231, 228, 228, 0.5));
			color: $color-dark-grey;
		}
	}
}

.cn__dashboard_payment_card {
	margin-top: 16px;
	&_delete_icon {
		position: absolute;
		right: -8px;
		top: -11px;
		z-index: 2;
		cursor: pointer;
	}
}
