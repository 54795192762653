$chartColors: #4C35BC, #9888EA, #5C40E6, #423B66, #4732B3;
$chartColorsLength: length($chartColors);

@mixin imgBroken($class,$color) {
    #{$class}:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: url('../../../public/static/img/broken-image.svg') no-repeat center center;
        background-size: 16px;
        background-color: $color;
        content: '';
        z-index: -1;
    }
}

@mixin mapInfoWindow {
    .map-info-container {
        &.map-info-container__wishlisted {
            .map-info-container_wrap {
                .map-info-container_desc-icon {
                    display: flex;
                }
            }
        }
        &.map-mobile {
            transition: .2s ease-in-out;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(150%);
            background-color: $color-white;
            z-index: 1;
            bottom: 3%;
            max-width: rem(335);
            width: 100%;
            .map-info-container_img {
                img {
                    border-top-left-radius: rem(6);
                    border-bottom-left-radius: rem(6);
                }
            }
            // .map-info-container_desc {
            //     padding-right: rem(10);
            // }
        }

        display: flex;
        border-radius: rem(6);
        width: rem(322);
        height: rem(90);

        .map-info-container_img {
            height: 100%;
            min-width: rem(90);
            width: rem(90);
            img {
                max-width: 100%;
                height: 100%;
            }
        }

        .map-info-container_desc {
            padding: rem(10);
            // padding-right: 0;
            font-size: rem(14);
            font-family: $primary-font;
            font-weight: 400;
            color: $color-black;
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            .map-info-container_bottom-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .map-info-container_distance {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                max-width: rem(200);
            }
            .map-info-container_star-rating {
                display: flex;
                align-items: center;
                font-size: 1rem;
                font-family: $primary-font;
                .map-info-container_star-rating-icon {
                    width: 1rem;
                    height: 1rem;
                    margin-right: rem(5);
                    path {
                        fill: $color-primary;
                    }
                }
            }
        }

        .map-info-container_wrap {
            // display: flex;
            // justify-content: space-between;
            .map-info-container_desc-icon {
                display: none;
                min-width: rem(12);
                margin-left: rem(10);
                justify-content: flex-end;
                path {
                    stroke: $color-black;
                    fill: $color-black;
                }
            }
        }
    }
}

.cn__body--wishlist{
    .container{
        display: block;
        grid-template-columns: unset;
    }
    .DesktopHeader__band{
        background: $color-white;
    }
}

.cn__wishlist{
    //Cookie banner
    .cn__cookie-wrapper{
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }
    .wishlist-map-container {
        height: 100%;
        border-radius: 0;
        .wishlist-map {
            height: inherit;
            border-radius: 0;
            .gm-bundled-control .gmnoprint, .gm-style-cc {
                display: none;
            }
        }
        .map-control, .map-zoom-controls {
            position: absolute;
            display: block;
            z-index: 1;
            &.map-viewtype {
                background: #ffffff;
                border: none;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
                width: 2.25rem;
                height: 2.25rem;
                border-radius: 10px;
                top: 84px;
                left: 20px;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
            .map-zoom-in, .map-zoom-out {
                position: static;
                background: #ffffff;
                border: none;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
                width: 2.25rem;
                height: 2.25rem;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
            .map-zoom-in{
                margin-bottom: 9px;
            }
        }
        .gmnoprint.gm-bundled-control {
            display: none;
        }
        .map-control {
            width: rem(44);
            height: rem(44);
        }
        .map-zoom-controls {
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
        }
        .map-pin, .map-transport{
            display: none;
        }
        .gm-style-iw-t {
            .gm-style-iw-c {
                padding: 0;
                @include mapInfoWindow;
            }
        }
        /*.gm-style-iw-t{
            right: 0!important;
            .gm-style-iw-c{
                padding: 0!important;
                .map-info-container{
                    cursor: pointer;
                    display:flex;
                    .map-info-container_img{
                        width: 90px;
                        height: 95px;
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                    .map-info-container_desc{
                        padding: 10px;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        width: 245px;
                        .map-info-container_distance{
                            position: absolute;
                            bottom: 10px;
                            color:#797882;
                        }
                    }
                }
            }
        }*/

        .map_marker-cluster {
            background: $color-black;
            color: $color-white;
            border-radius: 50%;
            font-weight: bold;
            font-size: rem(12);
            display: flex;
            align-items: center;
            &::before, &::after {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border: rem(2) solid $color-white;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                background: transparent;
                border-radius: 50%;
            }
            &:hover {
                z-index: 9999999 !important;
            }
        }

        .gm-style-iw-a {
            opacity: 0;
            transition: opacity .3s ease-in-out;
            &.map-property-marker, &.map-clusterer-info {
                opacity: 1;

                .gm-style-iw.gm-style-iw-c {
                    padding: 0;
                    border-radius: rem(6);
                    max-width: initial !important;
                }
            }

            &.map-property-marker {
                cursor: pointer;
                .gm-style-iw-t::after {
                    transform: translate(-450%,-9px) rotate(-45deg);
                }
                .gm-style-iw.gm-style-iw-c {
                    height: rem(90);
                }
            }
        }

        .gm-style-iw-a:not(.map-property-marker) {
            opacity: 0;
            transition: opacity .3s ease-in-out;

            &.map-clusterer-info {
                opacity: 1;
                .gm-style-iw.gm-style-iw-c {
                    padding: 0;
                    border-radius: rem(6);
                    max-width: initial !important;
                }
                .gm-style-iw.gm-style-iw-c, .gm-style-iw-t::after {
                    background: $color-black;
                }
                .map-clusterer-container {
                    padding: 0.4rem 0.6rem;
                    font-family: $primary-font;
                    color: $color-white;
                    font-weight: 400;
                    font-size: rem(12);
                    line-height: 1.1rem;
                    span {
                        opacity: .3;
                    }
                }
            }
        }
    }
    .close-btn-container {
        position: absolute;
        z-index: 1;
    }
    position: relative;
    width: 100%;
    margin-top: 88px;
    // margin-top: 77px;
    min-height: calc(100vh - 77px);
    background: #ffffff;
    display: table;
    overflow: hidden;
    /* display: flex;
    justify-content: center; */
    .cn__wishlist__wrapper{
        /* width: calc(100% - 40px);
        padding: 47px 0 69px 0; */
        width: 100%;
        padding: 78px 20px 69px 20px;
        background: #ffffff;

        .cn__wishlist__title{
            font-size: 36px;
            line-height: 44px;
            font-weight: 700;
            margin-top: 35px;
        }
        .cn__wishlist__list{
            border-bottom: 1px solid #E9E9EB;
            .cn__wishlist__list__content__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            padding-bottom: 25px;
            transition: opacity 0.6s ease;
            &:first-child{
                margin-top: 25px;
            }
            .cn__wishlist__list__content__info{
                @media screen and (max-width: 1135px)  {
                    max-width: calc(100% - 182px);
                }
                @media screen and (max-width: 599px)  {
                    max-width: calc(100% - 21px);
                }
                .cn__wishlist__list__content__info__wrapper{
                    .cn__wishlist__list__content__info__title{
                        font-size: 22px;
                        line-height: 26px;
                        font-weight: 700;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 1135px)  {
                            .cn__wishlist__list__content__info__title__p{
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                                &::first-letter{
                                    text-transform: uppercase;
                                }
                            }
                        }
                        @media screen and (max-width: 599px)  {
                            .cn__wishlist__list__content__info__title__p{
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                                &::first-letter{
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .cn__wishlist__list__content__control{
                            position: relative;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            margin-left: 20px;
                            background: transparent;
                            cursor: pointer;
                            transition: all 0.3s ease;
                            img{
                                display: inline-block;
                                margin-right: 10px;
                                &:first-of-type{
                                    margin-right: 0;
                                }
                            }
                            .cn__wishlist__list__content__control__element{
                                position: absolute;
                                padding: 10px;
                                top:0;
                                left:64px;
                                border-radius: 10px;
                                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                                background: #ffffff;
                                opacity: 0;
                                visibility: hidden;
                                transition: all 0.6s ease;
                                z-index: 2;
                                &:before{
                                    content: "";
                                    position: absolute;
                                    transform: translateY(-3px);
                                    left: -20px;
                                    z-index: 1;
                                    border: solid 10px transparent;
                                    border-right-color: #ffffff;
                                }
                                .cn__wishlist__list__content__control__element__option{
                                    padding: 10px 42px 10px 10px;
                                    border-radius: 6px;
                                    transition: all 0.3s ease;
                                    display: flex;
                                    align-items: center;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    span{
                                        height: 24px;
                                        img{
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                        .cn__wishlist__list__content__info__title__dots{
                            border-radius: 50%;
                            padding: 6px;
                            transition: all 0.3s ease;
                        }
                        @media screen and (max-width: 1135px)  {
                            display: flex;
                            .cn__wishlist__list__content__control{
                                margin-left: 20px;
                                top: 0;
                            }
                        }
                        @media screen and (max-width: 599px)  {
                            display: block;
                            .cn__wishlist__list__content__info__title__dots{
                                display: none;
                                position: relative;
                                top: 12px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .cn__wishlist__list__content__info__description{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        margin-top: 10px;
                    }
                }
            }
            .cn__wishlist__list__content__info__title--icon{
                width: 12px;
                transform:rotate(-90deg);
            }
            }
        }
        .cn__wishlist__list__data-info{
            position: fixed;
            bottom: 0;
            left: 100vw;
            width: 100vw;
            height: 100%;
            padding-top: 30px;
            z-index: 10;
            background: #ffffff;
            transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
            overflow: hidden;
            .cn__wishlist__list__data-info__wrapper{
                height: calc(100% - 81px);
                padding: 0 20px;
                overflow: scroll;
                .cn__wishlist__list__data-info__info{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .cn__wishlist__list__data-info__info__title{
                        font-weight: 600;
                        font-size: 32px;
                        line-height: 36px;
                        letter-spacing: -0.02em;
                        max-width: 73%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .cn__wishlist__list__data-info__info__icons{
                        height: 36px;
                        display: flex;
                        margin-left: 6px;
                        align-items: center;
                        img{
                            position: relative;
                            left: 6px;
                            transition: all 0.3s ease;
                            &:first-child{
                                margin-right: 19px;
                            }
                            &:last-child{
                                border-radius: 50%;
                                padding: 6px;
                            }
                        }
                        .cn__options__icon{
                            margin-right: 0 !important;
                        }
                    }
                }
                .cn__wishlist__list__data-info__description{
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    margin-top: 13px;
                }
                .cn__wishlist__list__content__items{
                    width: 100%;
                    visibility: visible;
                    height: auto;
                    max-height: 3000px;
                    .cn__wishlist__list__content__items__card{
                        display: flex;
                        flex-direction: column;
                        margin-top: 25px;
                        /*transform: translateY(20px);
                        opacity: 0;*/
                        cursor: pointer;
                        padding-bottom: 25px;
                        border-bottom: 1px solid #E9E9EB;
                        transition: opacity 0.6s ease;
                        &:last-child{
                            border-bottom: none;
                        }
                        @for $i from 1 through $chartColorsLength {
                            &:nth-of-type(#{$chartColorsLength}n+#{$i}) {
                                    @include imgBroken('.cn__wishlist__list__content__items__card__image__img',nth($chartColors, $i));
                            }
                        }
                        .cn__wishlist__list__content__items__card__image{
                            position: relative;
                            height: 170px;
                            .cn__wishlist__list__content__items__card__image__img{
                                width: 100%;
                                height: 170px;
                                border-radius: 10px;
                            }
                            .cn__wishlist__list__content__items__card__image__options{
                                position: absolute;
                                top: 0;
                                display: flex;
                                div{
                                    background: #FCFCFC;
                                    margin-top: 20px;
                                    margin-left: 20px;
                                    padding: 5px 10px 1px 10px;
                                    //height: 30px;
                                    border-radius: 50%;
                                    svg{
                                        width: 10px;
                                        cursor: pointer;
                                        path{
                                            transition: all 0.3s ease;
                                            fill:#555759;
                                        }
                                    }
                                }
                            }
                        }
                        .cn__wishlist__list__content__items__card__content{
                            position: relative;
                            margin-top: 9px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            .cn__wishlist__list__content__items__card__content__type{
                                position: relative;
                                font-size: 14px;
                                line-height: 16.8px;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                                text-transform: capitalize;
                                span{
                                    height: 14px;
                                    margin-left: 6px;
                                    margin-top: -4px;
                                    img{
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                            .cn__wishlist__list__content__items__card__content__title{
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 600;
                                margin-top: 5px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                            .cn__wishlist__list__content__items__card__content__amenities{
                                font-size: 13px;
                                line-height: 16px;
                                white-space: normal;
                                font-weight: 400;
                                margin-top: 10px;
                                color: #797882;
                                span{
                                    border-right: 1px solid #E9E9EB;
                                    padding: 0 10px;
                                    &:first-child{
                                        padding: 0 10px 0 0;
                                    }
                                    &:last-child{
                                        border: none;
                                        padding: 0 0 0 10px;
                                    }
                                }
                            }
                            .cn__wishlist__list__content__items__card__content__bottom{
                                position: relative;
                                width: 100%;
                                margin-top: 20px;
                                display: flex;
                                justify-content: space-between;
                                .cn__wishlist__list__content__items__card__content__bottom__rating{
                                    display: flex;
                                    align-items: center;
                                    span{
                                        position: relative;
                                        width: 14px;
                                        height: 13px;
                                        margin-right: 5px;
                                        svg{
                                            position: absolute;
                                            top: 0;
                                            width: 100%;
                                            path{
                                                transition: all 0.3s ease;
                                            }
                                        }
                                    }
                                }
                                .cn__wishlist__list__content__items__card__content__bottom__price{
                                    font-weight: 600;
                                    span{
                                        font-weight: 600;
                                    }
                                    a{
                                        color:#242526;
                                        transition: all 0.3s ease;
                                        &:hover{
                                            color:#4C35BC;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            @media screen and (max-width: 599px)  {
                .cn__wishlist__list__content__control__element{
                    position: absolute;
                    padding: 10px;
                    width: 100%;
                    bottom: -160px;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                    border-top: 1px solid #E9E9EB;
                    border-radius: 10px 10px 0 0;
                    z-index: 1;
                    background: #ffffff;
                    /*opacity: 0;
                    visibility: hidden;*/
                    transition: all 0.6s ease;
                    .cn__wishlist__list__content__control__element__option{
                        padding: 10px 42px 10px 10px;
                        border-radius: 6px;
                        transition: all 0.3s ease;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        span{
                            margin-right: 10px;
                            height: 24px;
                            img{
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
            .cn__wishlist__list__map {
                position: fixed;
                top: 0;
                width: 100%;
                height: 100%;
                left: 100%;
                z-index: 1;
                background-image: linear-gradient(to right, #4C35BC , #FA368B);
                transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
                .close-btn-container{
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    background: #ffffff;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
                    width: 2.25rem;
                    height: 2.25rem;
                    border-radius: .625rem;
                    background-color: #555759;
                    border: .0625rem solid #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 14px;
                        height: 14px;
                        path{
                            stroke:#fff;
                        }
                    }
                }
                .wishlist-map-container {
                    &.map-info-container__active {
                        .map-info-container {
                            &.map-mobile {
                                transform: translateX(-50%) translateY(0);
                            }
                        }
                    }
                    .map-info-container {
                        &.map-info-container__wishlisted {
                            .map-info-container_wrap {
                                .map-info-container_desc-icon {
                                    display: flex;
                                }
                            }
                        }
                        &.map-mobile {
                            transition: .2s ease-in-out;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%) translateY(150%);
                            background-color: $color-white;
                            z-index: 1;
                            bottom: 3%;
                            max-width: rem(335);
                            width: 100%;
                            .map-info-container_img {
                                img {
                                    border-top-left-radius: rem(6);
                                    border-bottom-left-radius: rem(6);
                                }
                            }
                        }

                        display: flex;
                        border-radius: rem(6);
                        width: rem(322);
                        height: rem(90);

                        .map-info-container_img {
                            height: 100%;
                            min-width: rem(90);
                            width: rem(90);
                            img {
                                max-width: 100%;
                                height: 100%;
                            }
                        }

                        .map-info-container_desc {
                            padding: rem(10);
                            font-size: rem(14);
                            font-family: $primary-font;
                            font-weight: 400;
                            color: $color-black;
                            width: 100%;
                            display: flex;
                            flex-flow: column nowrap;
                            justify-content: space-between;

                            .map-info-container_distance {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                max-width: rem(200);
                            }
                        }

                        .map-info-container_wrap {
                            // display: flex;
                            // justify-content: space-between;
                            .map-info-container_desc-icon {
                                display: none;
                                min-width: rem(12);
                                margin-left: rem(10);
                                justify-content: flex-end;
                                path {
                                    stroke: $color-black;
                                    fill: $color-black;
                                }
                            }
                        }
                    }
                }
            }
            .cn__wishlist__list__data-info__back{
                position: absolute;
                bottom: 0;
                padding:20px 0 40px 0;
                width: 100%;
                text-align: center;
                background: #ffffff;
                border-top: 1px solid #E9E9EB;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                img{
                    position: absolute;
                    top: 24px;
                    left: 20px;
                }
            }
        }
        .cn__wishlist__addlist{
            font-family: $primary-font;
            border: none;
            outline: none;
            text-decoration: none;
            -webkit-appearance: none;
            background: #574B90;
            color:#ffffff;
            padding: 10px 34px;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 600;
            margin-top: 30px;
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover{
                background: #39315E;
            }
        }
    }
    @media screen and (max-width: 599px)  {
        .cn__wishlist__list__content__control__element{
            position: fixed;
            padding: 10px;
            width: 100%;
            bottom: -160px;
            left: 0;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            border-top: 1px solid #E9E9EB;
            border-radius: 10px 10px 0 0;
            z-index: 1;
            background: #ffffff;
            transition: all 0.6s ease;
            .cn__wishlist__list__content__control__element__option{
                padding: 10px 42px 10px 10px;
                border-radius: 6px;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                span{
                    margin-right: 10px;
                    height: 24px;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    .cn__wishlist__not-found{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        .cn__wishlist__not-found__wrapper{
            text-align: center;
            img{
                display: block;
                margin: 0 auto;
                transform: scale(0);
            }
            .cn-notfound-title{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                margin-top: 40px;
                opacity: 0;
                transform:translateY(20px);
            }
            .cn-notfound-subtitle{
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ACABB4;
                margin-top: 5px;
                opacity: 0;
                transform:translateY(20px);
            }
        }
    }
}

@media screen and (max-width: 599px)  {
    .cn__wishlist {
        min-height: 100vh;
        display: table;
        margin-top: unset;
    }
}


@media screen and (min-width: 601px)  {
    .header-wishlist{
        .header-grid{
            grid-template-columns: 30px repeat(7, 1fr 20px) 1fr 30px !important;
        }
    }
    .cn__wishlist{
        .wishlist-map-container {
            border-radius: 15px;
            .wishlist-map {
                border-radius: 15px;
                // div{
                //     border-radius: 15px;
                // }
            }
            .map-control, .map-zoom-controls {
                &.map-viewtype {
                    top: 20px;
                    left: 20px;
                }
            }
        }
        .cn__wishlist__wrapper{
            padding: 0 30px 69px 30px;
            .cn__wishlist__list__data-info{
                height: 100%;
                .cn__wishlist__list__data-info__wrapper{
                    padding: 0 30px;
                    .cn__wishlist__list__data-info__info{
                        margin-top: 35px;
                        justify-content: unset;
                        .cn__wishlist__list__data-info__info__title{
                            max-width: calc(100% - 242px);
                        }
                        .cn__wishlist__list__data-info__info__icons{
                            height: 36px;
                            img{
                                left:0;
                                &:first-child{
                                    display:none;
                                    margin-right: 0;
                                }
                            }
                            .cn__wishlist__list__content__control{
                                position: relative;
                                width: 36px;
                                height: 36px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                margin-left: 20px;
                                background: transparent;
                                cursor: pointer;
                                transition: all 0.3s ease;
                                img{
                                    display: inline-block;
                                    margin-right: 10px;
                                    &:first-of-type{
                                        margin-right: 0;
                                    }
                                }
                                .cn__wishlist__list__content__control__element{
                                    position: absolute;
                                    padding: 10px;
                                    top:0;
                                    left:64px;
                                    border-radius: 10px;
                                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                                    background: #ffffff;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: all 0.6s ease;
                                    z-index: 2;
                                    &:before{
                                        content: "";
                                        position: absolute;
                                        transform: translateY(-3px);
                                        left: -20px;
                                        z-index: 1;
                                        border: solid 10px transparent;
                                        border-right-color: #ffffff;
                                    }
                                    .cn__wishlist__list__content__control__element__option{
                                        padding: 10px 42px 10px 10px;
                                        border-radius: 6px;
                                        transition: all 0.3s ease;
                                        display: flex;
                                        align-items: center;
                                        font-size: 16px;
                                        line-height: 20px;
                                        font-weight: 400;
                                        span{
                                            height: 24px;
                                            img{
                                                width: 24px;
                                                height: 24px;
                                                margin-right: 10px;
                                                padding: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .cn__wishlist__list__data-info__description{
                        margin-top: 10px;
                    }
                    .cn__wishlist__list__content__items{
                        .cn__wishlist__list__content__items__card{
                            flex-direction: row;
                            margin-top: 25px;
                            padding-bottom: 0;
                            border-bottom: none;
                            transition: opacity 0.6s ease;
                            &:last-child{
                                margin-bottom: 25px;
                            }
                            .cn__wishlist__list__content__items__card__image{
                                width: 259px;
                                .cn__wishlist__list__content__items__card__image__options{
                                    display: block;
                                    div{
                                        margin-top: 10px;
                                        margin-left: 10px;
                                        /* height: 24px;
                                        svg{
                                            width: 24px;
                                            height: 24px;
                                        } */
                                    }
                                }
                            }
                            .cn__wishlist__list__content__items__card__content{
                                margin-top: 0;
                                margin-left: 10px;
                                width: calc(100% - 269px);
                                .cn__wishlist__list__content__items__card__content__bottom{
                                    position: absolute;
                                    margin-top: 0;
                                    bottom: 0;
                                }
                            }
                        }
                    }

                }
                .cn__wishlist__list__map {
                    overflow: hidden;
                    position: relative;
                    top: auto;
                    height: 450px;
                    left: 0;
                    border-radius: 20px;
                    margin-top: 25px;
                    .close-btn-container {
                        display: none;
                    }
                }
                .cn__wishlist__list__data-info__back{
                    position: absolute;
                    bottom: 0;
                    padding:20px 0 40px 0;
                    width: 100%;
                    text-align: center;
                    background: #ffffff;
                    border-top: 1px solid #E9E9EB;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                    img{
                        position: absolute;
                        top: 24px;
                        left: 20px;
                    }
                }
            }
        }
        .cn__wishlist__not-found{
            padding: 0 30px;
        }
    }
}
@media screen and (min-width: 1136px)  {
    .header-wishlist{
        .header-grid{
            grid-template-columns: 100px repeat(7, 1fr 20px) 1fr 100px !important;
        }
    }
    .cn__wishlist{
        display: table;
        .cn__wishlist__wrapper{
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            padding: 0 100px;
            //padding: 71px 0 69px 0;
            .cn__wishlist__title{
                font-size: 36px;
                line-height: 44px;
                font-weight: 700;
                margin-top: 35px;
            }
            .cn__wishlist__list{
                width: 100%;
                display: flex;
                border-bottom: none;
                .cn__wishlist__list__content {
                    position: relative;
                    width: 660px;
                    border-top: 1px solid #E9E9EB;
                    margin-top: -1px;
                    display: table;

                    .cn__wishlist__list__content__top{
                        width: 660px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-top: 30px;
                        padding-bottom: 30px;
                        .cn__wishlist__list__content__info{
                            width: calc(100% - 24px);
                            .cn__wishlist__list__content__info__wrapper{
                                width: 90%;
                                .cn__wishlist__list__content__info__title{
                                    font-size: 22px;
                                    line-height: 26px;
                                    font-weight: 700;
                                    display: flex;
                                    align-items: center;
                                    p{
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        &::first-letter{
                                            text-transform: uppercase;
                                        }
                                    }
                                    .cn__wishlist__list__content__info__title--icon{
                                        margin-left: 10px;
                                        transform: rotate(0);
                                        width: 12px;
                                        cursor: pointer;
                                    }
                                }
                                .cn__wishlist__list__content__info__description{
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    margin-top: 10px;
                                }
                            }
                        }
                        .cn__wishlist__list__content__control{
                            position: relative;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: transparent;
                            cursor: pointer;
                            transition: all 0.3s ease;
                            img{
                                width: 24px;
                            }
                            .cn__wishlist__list__content__control__element{
                                position: absolute;
                                padding: 10px;
                                left: 64px;
                                border-radius: 10px;
                                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                                background: #ffffff;
                                opacity: 0;
                                visibility: hidden;
                                z-index: 1;
                                transition: all 0.6s ease;
                                &:before{
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: -20px;
                                    z-index: 1;
                                    border: solid 10px transparent;
                                    border-right-color: #ffffff;
                                }
                                .cn__wishlist__list__content__control__element__option{
                                    padding: 10px 42px 10px 10px;
                                    border-radius: 6px;
                                    transition: all 0.3s ease;
                                    display: flex;
                                    align-items: center;
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    span{
                                        margin-right: 10px;
                                        height: 24px;
                                        img{
                                            width: 24px;
                                            height: 24px;
                                        }
                                    }
                                    &:hover{
                                        background: #EEEEF0;
                                    }
                                }
                            }
                            &:hover{
                                background:#f7f7f7 !important;
                                /*.cn__wishlist__list__content__control__element{
                                    left: 42px;
                                    opacity: 1;
                                    visibility: visible;
                                }*/
                            }
                        }
                    }
                    .cn__wishlist__list__content__items{
                        position: relative;
                        width: 100%;
                        visibility: hidden;
                        display: none;
                        height: 0;
                        max-height: 580px;
                        .simplebar-scrollbar:before{
                            width: 4px;
                            background: #ACABB4;
                            left: auto;
                            right: 0;
                        }
                        .simplebar-track.simplebar-vertical{
                            right: -12px;
                            opacity: 0;
                            transition: opacity 0.3s ease;
                        }
                        .cn__wishlist__list__content__items__card{
                            display: flex;
                            margin-top: 35px;
                            //transform: translateY(0);
                            opacity: 0;
                            cursor: pointer;
                            transition: opacity 0.6s ease;
                            &:first-child{
                                margin-top: 0;;
                            }
                            @for $i from 1 through $chartColorsLength {
                                &:nth-of-type(#{$chartColorsLength}n+#{$i}) {
                                        @include imgBroken('.cn__wishlist__list__content__items__card__image__img',nth($chartColors, $i));
                                }
                            }
                            .cn__wishlist__list__content__items__card__image{
                                position: relative;
                                height: 170px;
                                .cn__wishlist__list__content__items__card__image__img{
                                    width: 171px;
                                    height: 170px;
                                    border-radius: 10px;
                                }
                                .cn__wishlist__list__content__items__card__image__options{
                                    position: absolute;
                                    top: 0;
                                    div{
                                        background: #FCFCFC;
                                        margin-top: 10px;
                                        margin-left: 10px;
                                        //height: 24px;
                                        padding: 5px 10px 1px 10px;
                                        border-radius: 50%;
                                        svg{
                                            width: 10px;
                                            cursor: pointer;
                                            path{
                                                transition: all 0.3s ease;
                                                fill:#555759;
                                            }
                                        }
                                    }
                                }
                            }
                            .cn__wishlist__list__content__items__card__content{
                                position: relative;
                                width: 100%;
                                margin-left: 20px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                .cn__wishlist__list__content__items__card__content__type{
                                    position: relative;
                                    font-size: 14px;
                                    line-height: 16.8px;
                                    font-weight: 600;
                                    display: flex;
                                    align-items: center;
                                    span{
                                        height: 14px;
                                        margin-left: 6px;
                                        margin-top: -4px;
                                        img{
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }
                                .cn__wishlist__list__content__items__card__content__title{
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 600;
                                    margin-top: 7px;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                                .cn__wishlist__list__content__items__card__content__amenities{
                                    font-size: 13px;
                                    line-height: 16px;
                                    white-space: normal;
                                    margin-top: 10px;
                                    color: #797882;
                                    span{
                                        border-right: 1px solid #E9E9EB;
                                        padding: 0 10px;
                                        &:first-child{
                                            padding: 0 10px 0 0;
                                        }
                                        &:last-child{
                                            border: none;
                                            padding: 0 0 0 10px;
                                        }
                                    }
                                }
                                .cn__wishlist__list__content__items__card__content__bottom{
                                    position: absolute;
                                    width: 100%;
                                    bottom: 0;
                                    display: flex;
                                    justify-content: space-between;
                                    .cn__wishlist__list__content__items__card__content__bottom__rating{
                                        display: flex;
                                        align-items: center;
                                        span{
                                            position: relative;
                                            width: 14px;
                                            height: 13px;
                                            margin-right: 5px;
                                            svg{
                                                position: absolute;
                                                top: 0;
                                                width: 100%;
                                                path{
                                                    transition: all 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                    .cn__wishlist__list__content__items__card__content__bottom__price{
                                        font-weight: 600;
                                        span{
                                            font-weight: 600;
                                        }
                                        a{
                                            color:#242526;
                                            transition: all 0.3s ease;
                                            &:hover{
                                                color:#4C35BC;
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover{
                                /*.cn__wishlist__list__content__items__card__image{
                                    .cn__wishlist__list__content__items__card__image__options{
                                        div{
                                            svg{
                                                path{
                                                    fill:#F35597;
                                                }
                                            }

                                        }
                                    }
                                }*/
                                .cn__wishlist__list__content__items__card__content{
                                    .cn__wishlist__list__content__items__card__content__bottom{
                                        .cn__wishlist__list__content__items__card__content__bottom__rating{
                                            span{
                                                svg{
                                                    path{
                                                        fill:#F35597;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .cn__wishlist__list__map {
                    width: 100%;
                    margin: 0 0 0 20px;
                    margin-top: 117px;
                    border-radius: 20px;
                    transform: translateX(20px);
                    opacity: 0;
                    background-image: linear-gradient(to right, #4C35BC , #FA368B);
                    visibility: hidden;

                }
                &:first-of-type{
                    .cn__wishlist__list__content {
                        border-top: none;
                    }
                }
            }
            .cn__wishlist__addlist{
                margin-bottom: 100px;
            }
        }
        .cn__wishlist__not-found{
            padding: 0 100px;
        }
    }
}
@media screen and (min-width: 1920px)  {
    .cn__wishlist{
        .cn__wishlist__wrapper{
            width: calc(100% - 580px);
        }
    }
}
