@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp2{
    position: relative;
    width: 100%;
    background: var(--color-background);

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 96px 100px 0;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__title{
        position: relative;
        text-align: center;
        //font
        font-weight: 600;
        font-size: dvw(44);
        line-height: 1.2;
        color: #000000;
        max-width: dvw(1030);
        margin: dvw(181) auto 0;
    }

    &__info{
        position: relative;
        width: dvw(1030);
        margin: dvw(50) auto 0;
        padding: dvw(40) 0;
        display: flex;
        justify-content: center;
        border: dvw(1) solid #FFFFFF; 
        border-radius: dvw(15);
        box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25);
        color: #64748B;

        &__option{
            position: relative;
            margin-left: dvw(120);
            &:first-child{
                margin-left: 0;
            }
            &::after{
                position: absolute;
                content: '';
                width: dvw(1);
                height: 100%;
                background: #64748B;
                right: -(dvw(60));
                top: 0;
            }
            &:last-child{
                &::after{
                    display: none;
                }  
            }

            &__title{
                font-size: dvw(44);
                font-weight: 700;
                line-height: 1;
            }
            &__subtitle{
                font-size: dvw(18);
                line-height: 1;
                font-weight: 600;
                margin-top: dvw(10);
            }
        }
    }

    &__subtitle{
        position: relative;
        margin-top: dvw(150);
        font-weight: 700;
        font-size: dvw(32);
        line-height: 1;
        text-align: center;
        color: #000000;

        span{
            position: relative;
             
            &::after{
                position: absolute;
                width: 100%;
                height: dvw(2);
                content: '';
                bottom: 0;
                left: 0;
                background: #000000;
            }
        }
    }
}


@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp2{
    
        &__title{
            margin-top: 181px;
            //font
            font-size: 44px;
            max-width: 1030px;
        }
    
        &__info{
            width: 1030px;
            margin: 50px auto 0;
            padding: 40px 0;
            border: 2px solid #FFFFFF; 
            border-radius: 15px;
    
            &__option{
                margin-left: 120px;

                &::after{
                    right: -60px;
                }
                &__title{
                    font-size: 44px;
                }
                &__subtitle{
                    font-size: 18px;
                }
            }
        }
    
        &__subtitle{
            margin-top: 150px;
            font-size: 32px;
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp2{
    
        &__wrapper{
            padding: 96px 30px 0;
        }
    
        &__title{
            padding: 0 9vw;
        }
    
        &__info{
            box-shadow: 0px 70px 90px -15px rgba(0, 0, 0, 0.25);
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp2{
        padding-bottom: mvw(6);
        display: table;
    
        &__wrapper{
            padding: 0;
        }
    
        &__subtitle{
            margin-top: mvw(60);
            font-size: mvw(32);
            line-height: 1.1;
            max-width: mvw(295);
            text-align: left;
            width: calc(100% - 40px);
            left: 20px;
    
            span{
                &::after{
                    display: none;
                }
            }
        }

        &__subtitle2{
            position: relative;
            width: calc(100% - 40px);
            left: 20px;
            max-width: mvw(295);
            margin-top: mvw(20);
            font-size: mvw(16);
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: #000000;
        }

        .cn__slider{
            position: relative;
            margin-top: mvw(27);

            .cn__slider__cards{
                overflow-x: unset;
            }
        }
    }
}