.cn__hp__placesfor{
    position: relative;
    width: 100%;
    max-width: 1640px;
    padding: 0 100px;
    margin: 100px auto;
    &__options{
        position: relative;
        margin-top: 35px;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-column-gap: 20px;
    }
    .cn__hp__placesfor__card{
        position: relative;
        width: 100%;
        height: 11.112vw;
        max-height: 160px;
        border-radius: 15px;
        overflow: hidden;
        cursor: pointer;
            .cn__hp__placesfor__card__info{
                position: absolute;
                width: 100%;
                padding: 15px;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                &__title{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                    font-feature-settings: unset;
                    font-variant: unset;
                    text-transform: unset;
                }
                img{
                    position: relative;
                    top: 2px;
                }
            }
    }
    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 30px 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }  
    }
    
    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 30px 20px 10px 0;
            display: flex;
            width: 100%;
            overflow-x: unset;
            -ms-scroll-snap-type: unset;
            scroll-snap-type: unset;
            scroll-padding: unset;
        }  
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}


//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__placesfor{
        margin: 60px auto;
        padding: 0;
        .cn__hp__placesfor__card{
            position: relative;
            width: 230px;
            height: 160px;
            flex-shrink: 0;
            margin-left: 15px;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            &:first-child{
                margin-left: 30px;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__placesfor{
        .cn__hp__placesfor__card{
            margin-left: 10px;
            width: 163px;
            &:first-child{
                margin-left: 20px;
            }
            .cn__hp__placesfor__card__info{
                &__title{
                    font-size: 14px;
                    line-height: 17px;
                }
                img{
                    top: 4px;
                }
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #FFFFFF;
            visibility: visible !important;
            height: 24px;
            width: calc(100% - 40px);
            bottom: -14px;
            margin-left: 20px;
            overflow: visible;
            pointer-events: all;
            cursor: pointer;
            z-index: 0;
            &::before{
                position: absolute;
                content: '';
                width: calc(100% + 40px);
                height: 100%;
                left: -20px;
                background: #FFFFFF;
                z-index: -1;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: 1px;
                background: #F4F4F5;
                border-radius: 5px;
                z-index: -1;
            }
            .simplebar-scrollbar{
                width: 100%;
                height: 4px;
                top: auto;
                bottom: 0;
                border-radius: 5px;
                background: #f466a2;
                &::before{
                    display: none;
                }
            }
        }
    }
}