.srp-date-picker {
    height: 3.125rem;
    position: relative;
    // top: 100%;

    .srp-date-picker-dates {
        appearance: none;
        text-align: unset;
        background-color: unset;
        padding: 0;
        border: none;
        outline: none;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 12px 0 6px;
        gap: 6px;
        overflow: hidden;
        cursor: pointer;

        .srp-date-picker-dates-date {
            flex: 0 0 auto;
            width: 4rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: var(--primary-font);
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            color: var(--color-black);
        }

        .srp-date-picker-dates-mid {
            flex: 0 0 auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--active {
            font-weight: 600;
        }
    }

    .DayPicker {
        position: absolute;
    }

    @media screen and (max-width: 600px) {
        height: 100%;
    }

}

.header-wrapper__min {
    @media screen and (max-width: 600px) {
        .srp-date-picker {
            .srp-date-picker-dates {
                .srp-date-picker-dates-date {
                    font-weight: 600;
                }
            }
        }    
    }
}

.date-picker__active {
    .srp-date-picker {
        .srp-date-picker-dates {
            .srp-date-picker-dates-date {
                font-weight: 600;
            }
        }
    }
}

.filters-side-bar {
    .srp-date-picker {
        height: 100%;

        .srp-date-picker-dates {
            gap: 0;
            margin: 0;

            .srp-date-picker-dates-date {
                width: 50px;
            }
        }
    }
}

.srp-date-picker-calendar {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    // border: 1px solid var(--color-light-grey);
    // box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    .date-picker-dates-srp {
        border-bottom: 1px solid var(--color-field-grey);

        .date-picker-dates-srp-inner {
            display: flex;
            justify-content: flex-start;
            padding-left: 20px;
            align-items: center;
        }
    }

    .DayPicker {
        box-shadow: none;
        border-radius: unset;
        padding: 0;
    }

    @media screen and (max-width: 600px) {
        border-radius: unset;
        box-shadow: unset;
        height: 100%;

        .date-picker-dates-srp {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-bottom: none;

            .date-picker-dates-srp-inner {
                width: 100%;
            }
        }

        .date-picker-body {
            position: relative;
            margin-top: calc(25px + 50px);
            height: calc(100% - 25px - 50px - 86px);
        }

        .DayPicker {
            // position: relative;
            // margin-top: calc(25px + 50px);
            // height: calc(100% - 25px - 50px - 86px);
            // height: calc(100% - 25px - 50px);
        }    
    }

    @media screen and (max-width: 1000px) {
        .input-dropdown {
            .date-picker-dates-srp-inner {
                width: 100%;
            }
        }    
    }
}

.srp-date-picker-availability-btn-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 86px;
    border-top: 0.0625rem solid var( --color-light-grey);
    display: flex;
    justify-content: center;
    align-items: center;

    .srp-date-picker-availability-btn {
        appearance: none;
        text-align: unset;
        border: none;
        outline: none;
        border-radius: 30px;
        width: 306px;
        padding: 10px 0;
        background: linear-gradient(92deg, #F35597 1.81%, #7868C7 148.68%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        font-family: var(--primary-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.14px;

        &:disabled {
            background: var(--color-subtle-grey);
        }
    }
}

.srp-date-picker-close-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;

    .srp-date-picker-close {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 50px;
        width: 50px;
        transform: translate(0, 100%);
        display: flex;
        justify-content: center;
        align-items: center;    
        // margin-right: 1rem;
    }
}

.date-picker-modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.5);
    // background-color: $color-white;

    .date-picker-modal-content {
        display: block;
        position: relative;
        overflow: hidden;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        height: calc(100% - 40px);
        background-color: $color-white;
        transform: translateY(100%);
        transition: transform .3s ease-in-out;

        &.ReactModal__Content--after-open {
            transform: translateY(40px);
        }

        &.ReactModal__Content--before-close {
            transform: translateY(100%);
        }
    }

}