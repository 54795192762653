
.page-accommodation-type {
    //Cookie banner
    .cn__cookie-wrapper{
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }
    main {
        overflow-x: hidden;
        padding-bottom: 100px;
        background:  var(--color-white) !important;
        /* @include tablet {
            padding-bottom: 0;
        }
        @include desktop {
            padding-bottom: 0;
        }
        @include large_desktop {
            padding-bottom: 0;
        } */
    }
    .header .header-logo .logo-pink,
    .header .header-logo .logo-purple {
        fill: var(--color-white) !important;
    }
    .header .max-width-wrapper {
        max-width: 1370px;
    }
    .header-nav {
        ul {
            li {
                &:not(.li--currency):not(.li--wishlist):not(.li--login) {
                    display: none;
                }
                li{
                    display: block !important;
                }

                &:last-child {
                    display: block;
                }
                svg {
                    path[stroke],
                    rect[stroke],
                    circle[stroke]:not(.logged-circle) {
                        stroke: var(--color-white);
                    }
                    path[fill],
                    rect[fill],
                    circle[fill] {
                        fill: var(--color-white);
                    }
                }
                p {
                    color: var(--color-white)
                }
            }
        }
    }
    .at-section {
        margin-bottom: 100px;
        .trip_page_top_location {
            display: flex;
            flex-direction: row;
            column-gap: 60px;
            @include mobile {
                flex-direction: unset;
                column-gap: 40px;
                padding-bottom: 15px;
            }
            .trip_page_top_location_detaillist{
                margin-top: 20px;
                @include mobile {
                    width: 180px;
                }
            }
            .trip_page_top_location_title {
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: var(--color-dark-grey)
            }
            .trip_page_top_location_detail{
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: var(--color-subtle-grey);
                margin-top: 5px;
            }
        }
        .cn__hp__featured {
            padding: 0;
        }

        &__head {
            margin-bottom: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            strong {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;
                color: var(--color-black);

                display: block;
                margin-bottom: 10px;
            }
            p {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-dark-grey);
            }
            .sub-title {
                color: var(--color-black);
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
            }
        }
        &__content {
            padding: 0px;
            width: 100%;
            display: block;
            .simplebar-scrollbar {
                background: var(--color-cn-very-light-pink);
                height: 4px;
                width: 100px;
                margin-top: 5px;
                border-radius: 30px;
            }
            .simplebar-scrollbar::before {
                background: var(--color-cn-very-light-pink);
                height: 4px;
                width: 100px;
                margin-top: 5px;
                border-radius: 30px;
            }
            .trip_types_mobile {
                grid-template-columns: 163px 163px 163px;
                grid-gap: 6px;
                padding: 0 0px 10px 0px;
                scroll-padding: 0 0 0 20px;
                position: relative;
                width: auto;
                display: inline-grid;
                img {
                    width: 163px;
                    height: 104px;
                    flex-shrink: 0;
                    border-radius: 10px;
                }
                a {
                    position: relative;
                    display: inline-block;
                }
                h3 {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    text-align: center;
                    color: var(--color-white);
                    font-size: 18px;
                    font-weight: 800;
                    line-height: 120%; 
                    width: 150px;
                }
            }
            .slick-arrow{
                background-color: transparent;
                transform-origin: center center;
                border: 2px solid rgba(121, 120, 130, 0.6);;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                z-index: 0;
                svg{
                    width: auto;
                    height: 10px;
                    path{
                        stroke: rgba(121, 120, 130, 0.6);;
                        stroke-width: 4px;
                    }
                }
                &.slick-next{
                    position: absolute;
                    right: 0;
                    top: -44px;
                    display: inline-flex;
                    svg{
                        margin-left: 2px;
                    }
                }
                &.slick-prev{
                    position: absolute;
                    right: 38px;
                    top: -44px;
                    display: inline-flex;
                    left: auto;
                    svg{
                        margin-left: -2px;
                    }
                }
                &.slick-disabled{
                    opacity: 0.5;
                }
            }
        }
    }
    .card--faq {
        background: var(--color-white);
        border-radius: 15px;
        padding: 0 20px;
        border: 1px solid var(--color-light-grey);
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // padding: 30px;

        .card__title {
            font-family: $primary-font;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.01em;
            color: var(--color-black);
            margin-bottom: unset;
            padding-top: 20px;
        }
        .card__content {
            padding: 0px;
        }
    }
    .at-image-list {
        list-style: none;

        li {
            border-radius: 20px;
            position: relative;
            max-width: 400px;
            max-height: 400px;
            margin-bottom: 20px;

            img {
                border-radius: 20px;
                grid-area: 1/1/span 3/1;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                border-radius: 20px;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: var(--color-white);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 49.48%, rgba(0, 0, 0, 0.1) 100%);
            }
        }

        &.at-image-list--different-type {
            display: grid;
            grid-template-columns: repeat(4 , 1fr);
            grid-gap: 20px;

            li {
                height: 190px;
            }
        }
        &.at-image-list--star-rating {
            display: grid;
            grid-template-columns: repeat(5 , 1fr);
            grid-gap: 20px;

            li {
                height: 190px;

                a {
                    text-transform: none !important;
                }

                summary {
                    min-height: auto;
                }
            }
        }
    }
    .at-inpiration-ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 20px;

        li {
            background: var(--color-shade-gray);
            padding: 15px;
            border-radius: 15px;

            a {
                display: grid;
                grid-template-columns: 1fr 24px;
                grid-template-rows: repeat(2, 24px);
                grid-row-gap: 15px;

                .icon-main {
                    grid-area: 1 / 1;
                }
                .icon-arrow-right {
                    grid-area: 2 / 2;
                }
                span {
                    grid-area: 2/1;
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                }
            }
        }
    }
    .at-image-list--types {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        li {
            // &:before {
            //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            //     position: absolute;
            //     content: ' ';
            //     left: 0px;
            //     top: 0px;
            //     height: 100%;
            //     width: 100%;
            //     border-radius: 15px;
            // }
            img {
                height: 202px;
                border-radius: 15px;
            }
            a {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                justify-content: center;
                align-items: flex-start;
                padding: 20px 60px;
                border-radius: 15px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }
    .at-image-list--trip_types {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;

        li {
            margin: 0px;
            height: 14vw;
            max-height: 191px;

            &:nth-child(2) {
                grid-area: 1 / 2 / span 2 / 2;
                height: initial;
                max-height: initial;
            }
            &:nth-child(3) {
                grid-area: 1 / 3 /span 2/3;
                height: initial;
                max-height: initial;
            }
            &:nth-child(4) {
                grid-area: 1 / 4 / 1 / span 4;
                max-width: initial;
                max-height: 191px;
            }
            &:last-child {
                grid-area: 2 / 4 / 2 / span 4;
                max-width: initial;
                max-height: 191px;
            }
            &:before {
                position: absolute;
                content: ' ';
                border-radius: 20px;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3));
            }
            a {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                align-items: flex-end;
                justify-content: space-between;
                padding: 15px;
                text-transform: inherit;
                background: transparent;

                svg {
                    path {
                        stroke: var(--color-white);
                    }
                }
            }
        }
    }
    .act-collapse-list {
        list-style: none;
        li {
            border-bottom: 1px solid var(--color-light-grey);
            padding: 20px 0;

            &:last-child {
                border: none;
            }
            h4 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black);
                position: relative;
                cursor: pointer;

                .ic-icon {
                    position: absolute;
                    right: 0px;
                    top: -1px;
                    transform: rotate(0deg);
                    transition: all 0.3s ease-in-out;
                }
            }
            p {
                padding-top: 10px;
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-dark-grey);
            }
            &.active {
                h4 {
                    .ic-icon {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
    .list--popular-search {
        ul {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 25px 20px;
        }
    }
    .carousel-slider--at-popular-search {
        .slick-list {
            overflow-x: hidden;
        }
        .slick-arrow {
            top: -36px;
            width: 28px;
            height: 28px;
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(48, 39, 39, 0.2);
            .ic-icon {
                top: 3px;
                left: 3px;
                width: 20px;
                height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: rgba(48, 39, 39, 0.2);
                    }
                }
            }
            &.slick-next {
                right: 0px;
            }
            &.slick-prev {
                right: 38px;
                left: auto;
            }
        }
    }
    .at-section--stays {
        .at-section__content {
            overflow: hidden;
        }
    }
    .entry {
        padding-top: 120px;
        margin-bottom: 100px;
        height: calc(100vh - 40px);
        max-height: 900px;
        position: relative;

        &:before {
            position: absolute;
            content: ' ';
            left: -50px;
            top: -35px;
            width: 100vw;
            height: 100vh;
            z-index: 1;
        }
        > .bg {
            position: absolute;
            left: -50px;
            top: -35px;
            width: 100vw;
            height: calc(100% + 40px);
            object-fit: cover;
        }
        > .cn__image-picture {
            left: -50px;
            top: -35px;
            width: 100vw;
            height: calc(100% + 40px);
            .cn__image-default {
                object-position: top center;
            }
        }
        &__top {
            position: absolute;
            z-index: 2;
            width: 100%;
            @include mobile {
                top: 28%;
                transform: translateY(-28%);
            }

            @include tablet {
                top: 30%;
                transform: translateY(-30%);
            }

            @include desktop {
                top: 35%;
                transform: translateY(-35%);
            }

            h1 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 42px;
                line-height: 46px;
                text-align: center;
                letter-spacing: -0.02em;
                color: var(--color-white);
                margin-bottom: 10px;
                @media (orientation: landscape) and (max-width: 900px) {
                    font-weight: 700;
                    font-size: 20px;
                    margin-bottom: 5px;
                }
            }
            h2 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-light-grey);
                @media (orientation: landscape) and (max-width: 950px) {
                    font-weight: 700;
                    font-size: 12px;
                    margin-bottom: -24px;
                }
            }

            .omnisearch {
                margin-top: 35px;
                place-content: center;
                @media (orientation: landscape) and (max-width: 950px) {
                    margin-top: 5px;
                    @media (max-width: 765px){
                        display: flex;
                        justify-content: center;
                    }
                   
                }
            }
        }
        &__bottom {
            position: absolute;
            bottom: 40px;
            z-index: 1;
            width: 100%;
            .slick-slide {
                padding: 0 35px;
            }
            .cn__values__value__inner {
                border-radius: 15px;
                background: rgba(0, 0, 0, 0.10);
                backdrop-filter: blur(2px);
                min-height: 209px;
                .cn__values__value__inner__description{
                    font-size: 12px;
                    font-weight:500;
                    line-height: 150%;
                    letter-spacing: 0.12px;
                }
                @media screen and (max-width:600px){
                    background: unset;
                    backdrop-filter: unset;
                    .cn__values__value__inner__description{
                        color: var(--color-white);
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 150%;
                        letter-spacing: 0.16px;
                    }
                }
                
            }
            @media (orientation: landscape)and (max-width: 950px) {
                bottom: 3px;
                .cn__values__value__inner__title{
                    font-size: 12px;
                }
                .cn__values__value__inner__description{
                    font-size: 10px;
                }
                .cn__values__value__inner{
                    svg{
                        width: 50px;
                        height: 50px;
                    }
                }
            }
            ul {
                list-style: none;
                display: grid;
                grid-template-columns: 276px 1fr 276px 1fr 276px;
                li:nth-child(2),
                li:nth-child(4) {
                    position: relative;
                    padding: 10px;
                    &:before {
                        position: absolute;
                        content: " ";
                        width: 0.5px;
                        background: var(--color-white);
                        height: 66px;
                        left: 50%;
                        top: calc(50% - 33px);
                    }
                }
            }


            @media screen and (min-width: 600px) and (max-width: 890px) {
                ul {
                    grid-template-columns: 206px 1fr 206px 1fr 206px;
                }
            }
            .entry__bottom__box {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 276px;
                .ic-icon {
                    height: 75px;
                    width: 75px;

                    svg {
                        height: 75px;
                        width: 75px;
                    }
                }
                strong {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: var(--color-white);
                    margin-bottom: 8px;
                }
                p {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: center;
                    color: var(--color-white);
                }
            }
        }
    }
    .at-image-list--ideal-family {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px 20px;

        li {
            height: 300px;
            max-width: max-content;

            a {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                top: auto;
                height: auto;
                bottom: -35px;
                justify-content: space-between;
                padding: 0 6px;
                color: var(--color-black);
                background: transparent;
                text-transform: initial;

                .gradient {
                    background: linear-gradient(135deg,#3198f8,#7c46f1 31.77%,#ed30ab 66.67%,#fd4848);
                    background-size: 100% 200%;
                    background-position: 50% 100%;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    .carousel-slider--ideal-family {
        .slick-list {
            overflow-x: hidden;
        }
        .slick-arrow {
            top: -36px;
            width: 28px;
            height: 28px;
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(48, 39, 39, 0.2);
            .ic-icon {
                top: 3px;
                left: 3px;
                width: 20px;
                height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: rgba(48, 39, 39, 0.2);
                    }
                }
            }
            &.slick-next {
                right: 0px;
            }
            &.slick-prev {
                right: 38px;
                left: auto;
            }
        }
        article {
            height: 340px;
            position: relative;

            img {
                border-radius: 20px;
                grid-area: 1/1/span 3/1;
                height: 100%;
                max-height: 300px;
                width: 100%;
                object-fit: cover;
            }
            a {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                border-radius: 20px;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: var(--color-white);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 49.48%, rgba(0, 0, 0, 0.1) 100%);

                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                top: auto;
                height: auto;
                bottom: 0px;
                justify-content: space-between;
                padding: 0 6px;
                color: var(--color-black);
                background: transparent;
                text-transform: initial;

                .gradient {
                    background: linear-gradient(135deg,#3198f8,#7c46f1 31.77%,#ed30ab 66.67%,#fd4848);
                    background-size: 100% 200%;
                    background-position: 50% 100%;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    .at-image-list--stay-tipe {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;

        li {
            margin: 0px;
            height: 14vw;
            max-height: 191px;

            &:nth-child(1) {
                grid-area: 1 / 1 / span 2 / 1;
                height: initial;
                max-height: initial;
            }
            &:nth-child(3) {
                grid-area: 1 / 3 / 1 / span 2;
                max-width: initial;
                max-height: 191px;
            }
            &:last-child {
                grid-area: 2 / 4 / 2 / span 2;
                max-width: initial;
                max-height: 191px;
            }
            &:before {
                position: absolute;
                content: ' ';
                border-radius: 20px;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3));
            }
            a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                text-transform: uppercase !important;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                border-radius: 20px;
                text-transform: inherit;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            }
        }
    }
    .at--road-trip-list {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;


        li {
            .at--road-trip-list__item-img {
                border-radius: 15px;
                height: 160px;
                width: 100%;
                margin-bottom: 15px;
                position: relative;

                img {
                    border-radius: 15px;
                    height: 160px;
                    width: 100%;
                    object-fit: cover;
                }
                span {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    transform: translateX(-50%);

                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    text-transform: uppercase;
                    color: var(--color-white);
                    white-space: nowrap
                }
            }

            .at--road-trip-list__ul--roads {
                list-style: none;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
                grid-row-gap: 10px;

                li {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-dark-grey);

                    a {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--color-dark-grey);
                    }
                }
            }
        }
        &.loading {
            .at--road-trip-list__item-img {
                >span,
                .react-loading-skeleton {
                    border-radius: 15px;
                    height: 160px;
                    width: 100%;
                    position: relative;
                    top: 0;
                    left: 0px;
                    transform: none;
                }
            }
        }
    }
    .cn__hp__download__wrapper {
        margin: 0px;
        padding: 0px;
    }
    .carousel-slider--blog-category {
        .slick-list {
            overflow-x: visible;
        }
        .slick-arrow {
            top: -36px;
            width: 28px;
            height: 28px;
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(48, 39, 39, 0.2);
            .ic-icon {
                top: 3px;
                left: 3px;
                width: 20px;
                height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: rgba(48, 39, 39, 0.2);
                    }
                }
            }
            &.slick-next {
                right: 0px;
            }
            &.slick-prev {
                right: 38px;
                left: auto;
            }
        }

        .act-blog-category {
            background: var(--color-shade-gray);
            padding: 15px;
            border-radius: 15px;
            width: 260px;
            margin-right: 20px;

            a {
                display: grid;
                grid-template-columns: 1fr 24px;
                grid-template-rows: repeat(2, 24px);
                grid-row-gap: 15px;

                .icon-main {
                    grid-area: 1 / 1;
                }
                .icon-arrow-right {
                    grid-area: 2 / 2;
                }
                span {
                    grid-area: 2/1;
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                }
            }
        }
    }
    .at-description {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-dark-grey);
        text-align: center;
    }
    .cn__shortfooter__wrapper .cn__shortfooter {
        // padding: 0 calc( (100vw - 1340px) / 2) 35px;
        padding-top: 0;
        max-width: initial;

        padding-left: 20px;
        padding-right: 20px;

        @include desktop {
            padding-left: 50px;
            padding-right: 50px;
        }
        @include large_desktop {
            padding-left: calc( (100vw - 1340px) / 2);
            padding-right: calc( (100vw - 1340px) / 2);
        }
    }
    .carousel-slider--star-rating {
        .carousel-slider__item article summary {
            min-height: auto;
        }
    }
    .carousel-slider__item--ac-price {
        .images {
            strong {
                position: absolute;
                left: 20px;
                bottom: 10px;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: var(--color-white);
            }
        }
    }
    .carousel-slider__item--hotel-detail {
        .images {
            height: 210px !important;
            .images_amenities {
                position: absolute;
                bottom: 16px;
                right: 30px;
                display: inline-flex;
                padding: 5px;
                align-items: flex-start;
                gap: 5px;
                border-radius: 30px;
                background: rgba(196, 196, 196, 0.10);
                box-shadow: -7.7px 7.7px 7.7px 0px rgba(255, 255, 255, 0.10) inset, 7.7px -7.7px 7.7px 0px rgba(149, 149, 149, 0.10) inset;
                backdrop-filter: blur(7.699999809265137px);
                .images_amenitie__facility {
                    display: flex;
                    gap: 5px;
                    img {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }
            }
            .images_grsrating {
                position: absolute;
                bottom: 16px;
                left: 20px;
                    .property-card_grs-rating_number {
                        color: var(--color-black);
                        background-color: var(--color-white);
                    }
                    .property-card_grs-rating_adjective {
                        color: var(--color-white);
                    }
                    .property-card_grs-rating_reviews {
                        color: var(--color-white);
                    }
            }
            .images_breakfasfreecancllation {
                position: absolute;
                right: 0;
                margin-right: 10px;
                margin-top: 14px;
            .images_breakfast {
                background: var(--color-light-purple);
                margin-bottom: 7px;
                color: var(--color-bright-purple);
            }
            .images_freecancllation{
                background: var(--color-very-sea-green);
                color: var(--color-very-light-green)
            }
            .chips {
                display: flex;
                height: 20px;
                padding: 2px 8px 4px 8px;
                align-items: flex-end;
                gap: 5px;
                border-radius: 4px;
                    p {
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 113%;
                        letter-spacing: 0.1px;
                    }
                    svg {
                        width: 11px;
                        height: 11px;
                        }
                    }
            }
        }
        .carousel-slider__propertydetail {
            background: var(--color-white);
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            .carousel-slider__propertydetail__title {
                overflow: hidden;
                display: flow-root;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--color-black);
                font-size: 18px;
                font-weight: 600;
            }
            .carousel-slider__propertydetail__propertytype {
                position: relative;
                border: 0.3px solid #64748b;
                box-sizing: border-box;
                border-radius: 5px;
                display: inline-flex;
                padding: 4px 10px;
                margin: 10px 0;
                .property-card_name__type__name__title{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    letter-spacing: 0.01em;
                    color: var(--color-slate-grey);
                }
                .property-card_type-stars {
                    display: inherit;
                    margin-left: 0.3125rem;
                    margin-top: -1px;
                    .ic-icon {
                        width: unset;
                        height: 14px;
                        svg {
                            height: 18px;
                        }
                    }
                    path {
                        fill: var(--color-moderate-orange);
                        fill-opacity: 1;
                    }
                }
            }
            .carousel-slider__propertydetail__fromcenter {
               .property-card_distance {
                   color: var(--color-pure-black);
               }
            }
            .carousel-slider__propertydetail__footer {
                display: grid;
                grid-auto-columns: minmax(0, 1fr);
                grid-auto-flow: column;
                place-items: flex-end;
                margin-top: 13px;
                min-height: 50px;
                @include mobile {
                    min-height: 79px;
                }
                .carousel-slider__propertydetail__footer__facility {
                    display: flex;
                    gap: 5px;
                    flex-wrap: wrap;
                    justify-self: flex-start;
                    span {
                        display: flex;
                        padding: 4px 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 18px;
                        background: var(--color-champagne-pink);
                        color: var(--color-black);
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 150%; 
                        letter-spacing: 0.1px;
                        width: fit-content;
                    }
                }
                .carousel-slider__propertydetail__footer__checkavailblity {
                    .carousel-slider__propertydetail__footer__checkavailblity__button {
                        border-radius: 30px;
                        display: flex;
                        align-self: flex-end;
                        height: 30px;
                        padding: 10px 15px;
                        justify-content: center;
                        align-items: center;
                        background: linear-gradient(92deg, #F35597 1.79%, #7868C7 99.81%);
                        color: var(--color-white);
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 150%; 
                        letter-spacing: 0.12px;
                    }
                }
            }
        }
    }
    @include mobile {
        > main {
            overflow-x: hidden;
        }
        .header.sticky {
            background: transparent;
        }
        .header .menu-mob .hamburguer-menu .hamburguer-menu-lin1,
        .header .menu-mob .hamburguer-menu .hamburguer-menu-lin2,
        .header .menu-mob .hamburguer-menu-inner .hamburguer-menu-lin1,
        .header .menu-mob .hamburguer-menu-inner .hamburguer-menu-lin2 {
            background: var(--color-white);
        }
        .at-section {
            margin-bottom: 70px;

            &__head {
                display: grid;
                grid-template-columns: 1fr 50px;
            }
        }
        .entry {
            &:before {
                left: -20px;
            }
            > .bg {
                left: -35px;
                top: -35px;
                width: calc(100vw + 20px);
            }
            > .cn__image-picture {
                left: -35px;
                top: -35px;
                width: calc(100vw + 20px);
            }
            &__top {
                h1 {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 36px;
                    letter-spacing: -0.04em;
                    margin-bottom: 10px;
                }
                h2 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                }
            }
            &__bottom {
                overflow-x: hidden;
                border-radius: 15px;
                background: rgba(255, 255, 255, 0.10);
                backdrop-filter: blur(2px);
                .entry__bottom__box {
                    max-width: 270px;
                    margin: auto;
                }
                .carousel-slider--act-entry {
                    .slick-arrow.slick-next {
                        right: -15px;
                        display: block!important;
                        border: none;
                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                    .slick-arrow.slick-prev {
                        left: 15px;
                        display: block!important;
                        border: none;
                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                    .slick-arrow {
                        background: transparent;
                        box-shadow: none;

                        path {
                            stroke: var(--color-white);
                        }
                    }
                }
            }
            .omnisearch {
                margin-top: 35px;
                height: 60px;
            }
        }
        .at-inpiration-ul {
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        .at-image-list--trip_types {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;

            li {
                margin: 0px;
                max-height: 191px;

                &:nth-child(2) {
                    grid-area: 1 / 2 / span 2 / 2;
                    max-height: initial;
                }
                &:nth-child(4) {
                    grid-area: auto;
                    max-width: initial;
                    max-height: 191px;
                }
                &:last-child {
                    grid-area: auto;
                    max-width: initial;
                    max-height: 191px;
                }
                svg {
                    path {
                        stroke: var(--color-white);
                    }
                }
            }
        }
        .at-image-list.at-image-list--different-type {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;

            li {
                margin: 0px;
            }
        }
        .at-image-list.at-image-list--star-rating {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;

            li {
                margin: 0px;
            }
        }
        .at--road-trip-list {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 20px;
        }
        .at-image-list--stay-tipe {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;

            li {
                margin: 0px;
                max-height: 191px;

                &:nth-child(1) {
                    grid-area: 1 / 1 / span 2 / 1;
                    max-height: initial;
                }
                &:nth-child(3) {
                    grid-area: auto;
                    max-width: initial;
                    max-height: 191px;
                }
                &:last-child {
                    grid-area: auto;
                    max-width: initial;
                    max-height: 191px;
                }
            }
        }
        .carousel-slider--blog-category {
            height: 100px;
        }
        // .carousel-slider--blog-slide .carousel-slider__item article {
        //     margin: auto;
        // }
        .at-image-list--ideal-family {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 40px;
        }
        .act-collapse-list li h4 {
            padding-right: 15px;
        }
        .carousel-slider--trip-types {
            .carousel-slider__item a {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: var(--color-white);

                flex-direction: row;
                justify-content: space-between;
                padding: 0 15px 15px;
                align-items: flex-end;
                text-transform: inherit;

                path {
                    stroke: var(--color-white);
                }
            }
        }
        .carousel-slider--image-slide .slick-list,
        .carousel-slider--blog-slide .slick-list {
            overflow-x: visible;
        }
        .carousel-slider--image-slide .slick-arrow,
        .carousel-slider--blog-slide .slick-arrow,
        .carousel-slider--blog-category .slick-arrow {
            display: none !important;
        }
    }
    @include onlyTablet {
        > main {
            overflow-x: hidden;
        }
        .entry {
            &:before {
                left: -20px;
            }
            > .bg {
                left: -20px;
            }
            > .cn__image-picture {
                left: -20px;
            }
        }
        .at-inpiration-ul {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        .at-image-list.at-image-list--star-rating {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;

            li {
                margin: 0px;

                &:nth-child(5) {
                    grid-area: 2 / 2 / 2 / span 2;
                    max-width: initial;
                    // max-height: 191px;
                }
            }
        }
    }
    @include large_desktop {
        .header .header-grid {
            grid-template-columns: 15px repeat(7,1fr 20px) 1fr 15px;
        }
        .entry {

            &:before {
                position: absolute;
                content: ' ';
                left: calc( (1340px - 100vw) / 2);
                top: -35px;
                width: 100vw;
                height: 100vh;
                background: linear-goradient(180deg, rgba(0, 0, 0, 0) 59.61%, rgba(0, 0, 0, 0.5) 100%);
                z-index: 1;
                max-height: 940px;
            }
            > .bg {
                position: absolute;
                left: calc( (1340px - 100vw) / 2);
                top: -35px;
                width: 100vw;
                height: calc(100% + 40px);
                object-fit: cover;
            }
            > .cn__image-picture {
                left: calc( (1340px - 100vw) / 2);
                top: -35px;
                width: 100vw;
                height: calc(100% + 40px);
            }
        }
    }

    .carousel-slider .slick-arrow {
        &.slick-prev {
            .ic-icon {
                top: 4px !important;
                left: 4px !important;
            }
        }
        &.slick-next {
            .ic-icon {
                top: 1px !important;
                left: 2px !important;
            }
        }
    }
}

