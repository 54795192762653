.mobilecard__slider{
    .mobilecard__title{
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        letter-spacing: -0.02em;
        color: $color-black;
        padding: 0 20px;
        b{
            font-weight: inherit;
            span{
                font-weight: 900;
                -webkit-text-stroke: 1px;
            }
        }
    }
    .mob-slider-wrapper{
        display: inline-flex !important;
        width: 100%;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px 0;
        scroll-padding: 0 0 0 20px;
        @media screen and (min-width: 601px) {
            scroll-padding: 0 0 0 30px;
        }
        a{
            color: $color-black;
        }
        a:first-child{
            margin-left: 20px;
            .mobilecard{
                margin-left: 0;
            }
        }
        .type1:first-child{
            margin-left: 20px;
        }
        .mobilecard{
            position: relative;
            width: 162px;
            box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
            border-radius: 20px;
            flex-shrink: 0;
            overflow: hidden;
            margin-left: 10px;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            .mobilecard__img{
                position: relative;
                width: 100%;
                height: 106px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .mobilecard__info{
                padding: 10px 10px 15px;
                .mobilecard__info__info{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p{
                        font-family: $secondary-font;
                        font-weight: 900;
                        font-size: 16px;
                        line-height: 20px;
                        -webkit-text-stroke: 0.3px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        padding-right: 10px;
                    }
                    svg{
                        width: 13px;
                    }
                }
                .mobilecard__info__count{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    font-variant: small-caps;
                    color: #797882;
                    margin-top: 6px;
                }
                .mobilecard__info__from{
                    &.mobilecard__info__from--light {
                        span {
                            color: $color-white;
                        }
                    }
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    font-variant: small-caps;
                    color: #797882;
                    margin-top: 6px;
                    span{
                        font-weight: 500;
                        color: $color-black;
                    }
                }
            }
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #f7f7f7;
        visibility: visible !important;
        .simplebar-scrollbar{
            width: 100%;
            background: #f7f7f7;
            &::before{
                width: 100%;
                background:#f7f7f7;
            }
        }
    }
    &.cities{
        background: #100D21;
        color: $color-white;
        .mobilecard__title{
            color: $color-white;
            padding-top: 30px;
        }
        .mob-slider-wrapper{
            padding: 25px 20px 50px 0;
            a{
                color: $color-white;
            }
            .mobilecard{
                .mobilecard__info{
                    background: #312F40;
                    .mobilecard__info__info{
                        svg{
                            path{
                                fill: $color-white;
                            }
                        }
                    }
                }
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #100D21;
            .simplebar-scrollbar{
                background: #100D21;
                &::before{
                    background:#100D21;
                }
            }
        }
    }
}

@media screen and (min-width: 601px) {
    .mobilecard__slider{
        .mobilecard__title{
            font-size: 32px;
            line-height: 37px;
            text-align: center;
            letter-spacing: -0.02em;
            padding: 0 30px;
        }
        .mobilecard__subtitle{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ACABB4;
            padding: 0 30px;
            max-width: 610px;
            margin: 0 auto;
            margin-top: 20px;
        }
        .mob-slider-wrapper{
            padding: 40px 30px 30px 0;
            a:first-child{
                margin-left: 30px;
                .mobilecard{
                    margin-left: 0;
                }
            }
            .type1:first-child{
                margin-left: 30px;
            }
            .mobilecard{
                width: 295px;
                margin-left: 20px;
                .mobilecard__img{
                    height: 203px;
                }
                .mobilecard__info{
                    padding: 10px 25px 20px;
                    .mobilecard__info__info{
                        p{
                            font-size: 18px;
                            line-height: 23px;
                        }
                        svg{
                            width: 16px;
                        }
                    }
                    .mobilecard__info__count{
                        font-size: 16px;
                        margin-top: 7px;
                    }
                    .mobilecard__info__from{
                        &.mobilecard__info__from--light {
                            span {
                                color: $color-white;
                            }
                        }
                        font-size: 16px;
                        margin-top: 7px;
                        span{
                            font-weight: 500;
                            color: $color-black;
                        }
                    }
                }
            }
        }
        &.cities{
            .mob-slider-wrapper{
                padding: 40px 30px 100px 0;
                .mobilecard{
                    width: 232px;
                    .mobilecard__img{
                        height: 250px;
                    }
                }
            }
        }
    }
}