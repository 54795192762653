//Custom variable
:root {
    --continent-bg-color: #EEEDF2;
    --continent-header-bg-color: transparent;
}
//Parent wrapper
.cn__continent__wrapper{
    position: absolute;
    width: 100%;
    overflow: hidden;
    background-color: var(--continent-bg-color);
    transition: all 1s ease-out;
    .header{
        position: absolute;
        background-color: var(--continent-header-bg-color);
        transition: background-color 1s ease-out;
        .header-logo{
            .logo-pink, .logo-purple {
                fill: #FFFFFF !important;
            }
        }
        .hamburguer-menu{
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #FFFFFF !important;
            }
        }
        &.header-continent{
            .header-logo{
                .logo-pink {
                    fill: #fa368b !important;
                }
                .logo-purple {
                    fill: #4c35bc !important;
                }
            }
            .hamburguer-menu{
                .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                    background: #242526 !important;
                }
            }
        }
        &.sticky{
            position: fixed;
        }
        &.header-continent-back{
            background-color: transparent !important;
        }
        .header-nav ul li a{
            color:#FFFFFF;
            svg{
                .blog1,.blog2, .rect1, .listsvg, circle{
                    stroke: #FFFFFF;
                }
                .rect2, path{
                    fill: #FFFFFF;
                }
                .listsvg{
                    fill: none;
                }
            }
        }
        .header-nav {
            .li--blog, .li--help {
                display: none;
            }
        }
    }
    .header,.footer{
        .max-width-wrapper{
            max-width: 1240px;
        }
    }
    .cn__shortfooter__wrapper {
        .cn__shortfooter {
            max-width: 1200px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    //Cookie banner
    .cn__cookie-wrapper{
        left: 50%;
        margin-left: -570px;
        bottom: 16px;
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }
}
//Geo Pages content section
.cn__continent{
    position: relative;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 10px;
    .subscribe-section{
        padding: 0;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .cn__cnt__section4{
        padding-top: 200px;
    }
    .cn__cnt__slider__ssr-slider-track{
        display: flex;
        margin-top: 15px;
        padding-bottom: 30px;
        overflow-x: scroll;
        &__card{
            position: relative;
            flex-shrink: 0;
            width: calc(50% - 10px);
            margin-left: 20px;
            background: #D4EAF5;
            border-radius: 20px;
            &:first-child{
                margin-left: 0;
            }
            &__img{
                position: relative;
                width: 100%;
                height: 200px;
                border-radius:  20px 20px 0 0;
                overflow:hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &__info{
                position: relative;
                padding: 20px 30px 30px 30px;
                &__title{
                    text-align: center;
                    font-size: 32px;
                    font-weight: 700;
                    color: #34AFED;
                }
                &__description{
                    margin-top: 20px;
                    color: rgba(29, 28, 31, 0.45);
                    a{
                        font-weight: 500;
                        color: #34AFED;
                    }
                }
            }
        }
        &.cn__cnt__pcountriesssr{
            .cnt__card1{
                &:first-child{
                    width: 560px;
                    .cnt__card1__info{
                        opacity: 1;
                    }
                }
            }
        }
        &.cn__cnt__multi{
            .cn__cnt__slider__ssr-slider-track__card{
                background: #F9EFE0;
                &__info{
                    &__title{
                        color: #EA3434;
                    }
                    &__description{
                        color: rgba(104, 102, 93, 0.5);
                        a{
                            color: #EA3434;
                        }
                    }
                }
            }
        }
        &.cn__cnt__wheretossr{

        }
    }
}

@media screen and (max-width:1136px){
    //Geo Pages content section
    .cn__continent{
        padding: 0;
        .subscribe-section{
            padding: 0 30px;
            margin-bottom: 100px;
        }
        .cn__cnt__section4{
            padding-top: 200px;
        }
    }
}

@media screen and (max-width:600px){
    //Geo Pages content section
    .cn__continent{
        padding: 0;
        .subscribe-section{
            padding: 0 20px;
            margin-top: 70px;
        }
        .cn__cnt__section4{
            padding-top: 0px;
        }
    }
}