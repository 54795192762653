//Reset title tag css
.cn__subtitle{
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    font-feature-settings: unset;
    font-variant: unset;
    text-transform: unset;
}

.cn--subtitle1{
    &.cn__subtitle{
        font-size: 16px;
        line-height: 20px;
        color: #797882;
        margin-top: 10px;
    }
}