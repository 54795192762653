@import '../../global/mixins/global-mixins.scss';
.card-slider-section-2 {
    width: 100%;
    position: relative;
    .heading-section {
        max-width: 43%;
        float: right;
        text-align: right;
        padding: 0;
        @media screen and (min-width: 1136px) {
            margin-right: 3.125rem;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 0 0 12.3%;
            max-width: 71.5%;
            text-align: center;
            padding: 0 0;
            float: none;
        }
        @media screen and (max-width: 600px) {
            float: none;
            margin: 0;
            max-width: 100%;
            text-align: center;
            padding: 0 1.25rem 0 0;
        }
        h2 {
            font-family: $secondary-font;
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 700;
            color: $color-black;
            margin: 0 0 1.25rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
            @media screen and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 1.5625rem;
            }
            &.desktop {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 601px) {
                    display: none;
                }
            }
            br {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                color: $color-primary;
            }
        }
        p {
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $color-dark;
            margin: 0 0 2.5rem 0;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
    .lSSlideOuter {
        clear: both;
    }
    .card-text-on-img {
        transition: margin ease 0.1s;
        border-radius: 0.625rem;
        background: $color-white;
        width: 100%;
        @media screen and (min-width: 601px) {
            border: 0.1875rem solid $color-white;
        }
        @media screen and (max-width: 600px) {
            width: 17.5rem;
            @include box-shadow-light();
        }
        overflow: hidden;
        margin: 0 auto 0.625rem;
        .card-img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 21.0625rem;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 600px) {
                height: 13.9375rem;
            }
            a {
                display: block;
                height: 100%;
                span {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    @media screen and (max-width: 600px) {
                        height: 223px;
                        border-radius: 10px 10px 0px 0px;
                    }
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                    transition: all 0.6s ease;
                    @media screen and (max-width: 600px) {
                        border-radius: 10px 10px 0px 0px;
                    }
                }
            }
            .slider_rest_info {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            h4 {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                text-align: center;
                transition: all 0.6s ease;
                /*@media screen  and (max-width: 600px) {
                    display: none;
                }*/
                @media screen and (max-width: 600px) {
                    display: none;
                }
                a {
                    @include big-text();
                    font-weight: 500;
                    max-width: 107px;
                    color: $color-white;
                    height: auto;
                }
            }
        }
        .card-text {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 0.625rem 2.0625rem 0.875rem 1.25rem;
            position: relative;
            height: 4.0625rem;
            h4 {
                color: $color-black;
                overflow: hidden;
                margin: 0;
                a {
                    font-size: 1rem;
                    line-height: 1.1875rem;
                    font-weight: 500;
                    color: $color-black;
                    width: 100%;
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    span {
                        color: $color-primary;
                    }
                    .arrow-right {
                        font-style: normal;
                        color: $color-primary;
                    }
                    img {
                        float: right;
                        margin-top: 0;
                        position: absolute;
                        right: 18px;
                        bottom: 18px;
                        width: 13px;
                    }
                }
            }
        }
        /*&:hover {
            background: $color-subtle-white;
            .card-img {
                a {
                    img {
                        filter: grayscale(1);
                    }
                }
            }
        }*/
    }
    .slick-slider {
        .slick-slide {
            width: 295px !important;
            margin-left: 18px;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                width: 295px !important;
                margin-left: 22px;
            }
            @media screen and (max-width: 600px) {
                width: 249px !important;
                margin-left: 10px;
            }
        }
        .slick-slider {
            .slick-track {
                @media screen and (max-width: 600px) {
                    padding-top: 40px;
                }
            }
        }
    }
}


/* Animation specific styles */

.slick-list {
    overflow: visible;
}

.reviews-slider {
    .slick-list {
        overflow: hidden;
    }
}

.card-slider-section-2.section-slider .slick-slide {
    // width: auto !important;
}

.flip-container-desktop,
.card-slider-section-2 .lslide {
    /*-webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
    perspective: 1000;*/
    height: 337px !important;
    width: 100%;
    @media screen and (max-width: 600px) {
        height: 285px !important;
    }
}

.flipper {
    overflow: visible !important;
}


/*.flip-container-desktop:hover .flipper,
.flip-container-desktop.hover .flipper,
.card-slider-section-2 .lslide:hover .card-text-on-img {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}*/

.card-slider-section-2 .lslide .card-text-on-img {
    width: 100% !important;
    height: 100% !important;
}

.flipper,
.card-slider-section-2 .lslide .card-text-on-img {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.front,
.back,
.card-slider-section-2 .lslide .card-img,
.card-slider-section-2 .lslide .card-text {
    /*-webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;*/
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.back,
.card-slider-section-2 .lslide .card-text {
    /*-webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    background: $color-black;*/
}

.card-slider-section-2 {
    .country-box-top {
        h4 {
            z-index: 2;
        }
        .country-box-gradient {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: linear-gradient(180deg, rgba(36, 37, 38, 0) 0%, rgba(36, 37, 38, 0.13) 25%, rgba(36, 37, 38, 0.25) 50%, rgba(36, 37, 38, 0.38) 75%, rgba(36, 37, 38, 0.5) 100%);
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
}

.card-slider-section-2 .card-text-on-img .card-text {
    //background-color: rgba(76,53,188,1);
    background-color: rgba(255, 255, 255, 0.5);
    ;
    &.back {
        padding: 1.25rem;
        text-align: center;
        color: $color-black;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transition: all 0.6s ease;
        @media screen and (max-width: 600px) {
            opacity: 1;
            background: none;
            height: 62px !important;
            top: auto;
            bottom: 0;
            padding: 10px 20px;
            justify-content: unset;
            align-items: unset;
            flex-direction: row;
        }
        h4 {
            font-family: $secondary-font;
            margin: auto;
            margin: auto;
            color: $color-black;
            font-size: 1.5rem;
            line-height: 1.875rem;
            font-weight: normal;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 600px) {
                display: none;
            }
            a {
                color: $color-black;
            }
        }
        a {
            p {
                display: inline;
            }
            span {
                display: block;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            color: $color-black;
            .arrow-right {
                margin-left: 5px;
                vertical-align: middle;
                @media screen and (max-width: 600px) {
                    margin-left: 10px;
                }
            }
            @media screen and (max-width: 600px) {
                color: $color-black;
                display: flex;
                align-items: center;
                font-size: 1.5rem;
                justify-content: flex-end;
                width: 100%;
            }
        }
    }
}

.card-slider-section-2 {
    .slick-track {
        padding-bottom: 10px;
    }
    .section-slider {
        padding: 50px 0;
    }
    @media screen and (min-width: 601px) {
        .slick-slide {
            transition: all 0.6s ease;
            cursor: pointer;
            &:hover {
                transform: translateY(-5px);
                //transform: scale(1.1);
                .card-img h4 {
                    opacity: 0;
                }
                .card-text {
                    &.back {
                        opacity: 1;
                    }
                }
            }
        }
        .lslide {
            &:hover {
                .country-box-top {
                    img {
                        filter: blur(8px);
                        -webkit-filter: blur(8px);
                    }
                }
            }
        }
    }
}