.primary-input { 
    @include primary-input() 
}

.floating-input-con { 
    position:relative;  
    span { 
        padding:0.125rem 0.25rem;
        pointer-events: none;
        position:absolute;
        left: 1rem;
        top: 0.438rem;
        transition: 0.2s; 
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        opacity:0;
    } 
    input:focus + span, input:not(:placeholder-shown) + span {
        background-color: $color-white;
        opacity:1;
        transform:translateY(-100%) translateX(-0.3rem);
        font-size: 0.75rem;
        line-height: 0.75rem;
        color: $color-dark-grey;
    }  
    input:focus + span, input:not(:-ms-input-placeholder) + span {
        opacity:1;
        transform: translateY(-100%) translateX(-0.3rem);
    }  
}