//------------------- DESKTOP -------------------

.cn__footer__wrapper{
    position: relative;
    width: 100%;

    .cn__footer{
        position: relative;
        width: 100%;
        max-width: 1920px;
        padding: 0px 100px 39px;
        margin: 0 auto;

        &__copy{
            font-size: 16px;
            color: #1E2A3B;
        }

        &__top{
            display: flex;
            justify-content: space-between;
            &__titlewrapper{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 33.5%;
            }
            &__title{
                font-weight: 800;
                font-size: 55px;
                line-height: 1;
                letter-spacing: -0.04em;
                color: #A7A7A7;
                max-width: 305px;
                span{
                    background: $color-cn-pink;
                    // background: linear-gradient(92.14deg,#f35597 1.79%,#f53959 99.81%);
                    background-size: 100% 200%;
                    background-position: 50% 100%;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: inline-block;
                    width: 16px;
                }
            }
            /* s */
            &__options{
                position: relative;
                width: 66.5%;
                display: flex;
                grid-column-gap: 10px;
                justify-content: flex-end;

                &__option{
                    font-size: 16px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 24.2%;

                    &__download{
                        flex: 1 1 0%; 
                        justify-content: space-between; 
                    }

                    &__category{
                        font-weight: 600;
                        
                        line-height: 1.5;
                        text-transform: uppercase;
                        padding-right: 15%;
                            &__milion {
                                text-transform: none;
                                font-size: 25px;
                                line-height: 30px;
                            }
                    }
                    &__buttons{
                        position: relative;
                        margin-top: 5px;

                        a{
                            margin-top: 5px;
                            display: table;

                            &:first-child{
                                margin-top: 0;
                            }
                        }

                        img{
                            width:163px;
                        }
                    }
                    &__list{
                        
                        line-height: 1.5;
                        letter-spacing: 0.01em;
                        margin-top: 15px;
                        color: #1E2A3B;
                        cursor: pointer;
                        &:hover{
                            color: #252426;
                        }
                        a{
                            color: #1E2A3B;
                            transition: color 0.3s ease;
                            &:hover{
                                color: #252426;
                            }
                        }
                    }
                }
            }
        }
        &__bottom{
            position: relative;
            margin-top: 31px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__pay{
                position: relative;
                margin-top: 50px;

                &__title{
                    font-size: 12px;
                    line-height: 1.5;
                    letter-spacing: 0.01em;
                    color: #797882;
                }

                &__icons{
                    position: relative;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;

                    &__cards, &__payment{
                        img{
                            margin-right: 15px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }

                    &__divider{
                        position: relative;
                        top: -4px;
                        width: 1px;
                        height: 48px;
                        background:#E9E9EB;
                        margin: 0 30px;
                    }
                }
            }
            &__sec1{
                display: flex;
                
                line-height: 15px;
                letter-spacing: 0.02em;
                color: #1E2A3B;

               
                &__links{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    p{
                        &:first-child{
                            margin-right: 30px;
                        }
                        a{
                            color: #1E2A3B;
                            transition: color 0.3s ease;
                            &:hover{
                                color: #252426;
                            }
                        }
                    }
                }
            }
            &__sec2{
                .cn__footer__social{
                    position: relative;
                    display: flex;
                    align-items: center;
                    align-items: center;
                    a{
                        margin-left: 25px;
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                    svg{
                        transition: transform 0.3s ease;
                        &:hover{
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
        //------------ SLIDER WRAPPER-----------
        .slidermobile__wrapper{
            width: 100%;
            overflow-x: scroll;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            padding: 25px 0 30px;
            scroll-padding: 0 0 0 20px;
        }
        @media screen and (max-width:1136px){
            .slidermobile__wrapper{
                padding: 0 30px 10px 0;
                scroll-padding: 0 0 0 30px;
            }
        }

        @media screen and (max-width:600px){
            .slidermobile__wrapper{
                padding: 0 20px 10px 0;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 10px;
                width: 100%;
                overflow-x: unset;
                -ms-scroll-snap-type: unset;
                scroll-snap-type: unset;
                scroll-padding: unset;
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #FFFFFF;
            visibility: visible !important;
            height: 10px;
            z-index: 0;
            .simplebar-scrollbar{
                width: 100%;
                background: #FFFFFF;
                &::before{
                    width: 100%;
                    background:#FFFFFF;
                }
            }
        }
        //------------ SLIDER WRAPPER-----------
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__footer__wrapper{
        .cn__footer{
            padding: 0 30px 30px;
            &__top{
                display: grid;
                grid-template-columns: 263px 1fr;

                &__title{
                    font-size: 32px;
                    line-height: 40px;
                }
                &__titlewrapper{
                    justify-content: space-between;
                    width: auto;
                }
                &__options{
                    margin-left: 0;
                    grid-column-gap: 26px;
                    width: auto;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;

                    &__option{
                        &__download{
                            display: flex;
                            flex-direction: row; 
                            justify-content: center; 
                            gap: 30px;
                            font-size: 24px
                        }


                        &__buttons{
                            display: flex;
                            flex-direction: column; 
                        }

                        width: auto;
                        font-size: 15px;

                    }

                    &__option--out{

                        .cn__footer__top__options__option__category{
                            padding-right: 0;
                        }

                        .cn__footer__top__options__option__buttons{
                            display: flex;

                            a{
                                margin-top: 0;

                                &:first-child{
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
            &__bottom{
                margin-top: 50px;
                flex-direction: column;
                align-items: flex-start;

                &__pay{
                    margin-top: 30px;
    
                    &__title{
                        
                        color: $color-gray-hp;
                    }
    
                    &__icons{
                        margin-top: 16px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
    
                        &__cards, &__payment{
                            display: grid;
                            width: 100%;
                            grid-template-columns: repeat(10, minmax(0, 1fr));
                            grid-gap: 10px 20px;
                            img{
                                width: 100%;
                                margin-right: 0;
                            }
                        }
    
                        &__divider{
                            position: relative;
                            top: 0;
                            width: 48.5%;
                            height: 1px;
                            background:#E9E9EB;
                            margin: 20px 0;
                        }
                    }
                }
               
                &__sec1{
                    order: 1;
                    margin-top: 30px;
                    flex-direction: column;
                    width: 100%;
                    &__links{
                        flex-direction: row;
                        justify-content: center; 
                    }
                }
                &__sec2{
                    display: flex;
                    justify-content: center; 
                    align-items: center;
                    grid-gap: 60px;
                    flex-direction: column;
                    width: 100%;

                    .cn__footer__social{
                        svg{
                            &:hover{
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__footer__wrapper{
        .cn__footer{
            padding: 0 0 30px;
            &__top{
                flex-direction: column;
                display: block;

                &__titlewrapper{
                    padding: 0 20px;
                    width: 100%;
                }
                &__title{
                    font-size: 32px;
                    line-height: 1;
                }
                &__options{
                    display: block;
                    width: 100%;
                    margin-left: 0;
                    margin-top: 30px;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    &__option{
                        &__download{
                            display: flex;
                            flex-direction: row; 
                            justify-content: center; 
                            gap: 30px;
                            font-size: 24px
                        }

                        &__buttons{
                            display: flex;
                            flex-direction: column; 
                        }

                        flex-shrink: 0;
                        scroll-snap-align: start !important;
                        width: 150px;
                        &:first-child{
                            margin-left: 20px;
                        }

                        &__list{
                            margin-top: 10px;
                        }

                        &--out{
                            width: 100%;
                            margin-top: 30px;
                            padding: 0 20px;
                        }
                    }
                }
            }
            &__bottom{
                margin-top: 30px;
                padding: 0 20px;
                justify-content: center; 
                &__pay{
                    margin-top: 30px;
    
                    &__title{
                        font-size: 14px;
                        color: $color-gray-hp;
                    }
    
                    &__icons{
                        margin-top: 16px;
                        display: flex;
                        flex-direction: column;
    
                        &__cards, &__payment{
                            display: grid;
                            width: 100%;
                            grid-template-columns: repeat(5, minmax(0, 1fr));
                            grid-gap: 10px 20px;
                            img{
                                width: 100%;
                                margin-right: 0;
                            }
                        }
    
                        &__divider{
                            position: relative;
                            top: 0;
                            width: 100%;
                            height: 1px;
                            background:#E9E9EB;
                            margin: 20px 0;
                        }
                    }
                }
                &__sec1{
                    margin-left: 0;
                    margin-top: 40px;
                    flex-direction: column;
                    color: #1E2A3B;

                    &__rights{
                        order: 1;
                        margin-top: 15px;
                    }
                    &__links{
                        justify-content: center; 
                        flex-direction: row;
                        margin-left: 0;
                        &:first-child{
                            margin-left: 0;
                        }

                        p{
                            a{
                                color: #1E2A3B;
                            }
                        }
                    }
                }
                &__sec2{
                    margin-top: 10px;
                    justify-content: center; 
                    flex-direction: row;
                    width: 100%;
                }
            }

            .simplebar-content-wrapper{
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            
            .simplebar-track.simplebar-horizontal{
                background: #FFFFFF;
                visibility: visible !important;
                height: 24px;
                width: calc(100% - 40px);
                bottom: -14px;
                margin-left: 20px;
                overflow: visible;
                pointer-events: all;
                cursor: pointer;
                z-index: 0;
                display: none;
                &::before{
                    position: absolute;
                    content: '';
                    width: calc(100% + 40px);
                    height: 100%;
                    left: -20px;
                    background: #FFFFFF;
                    z-index: -1;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    bottom: 1px;
                    background: #F4F4F5;
                    border-radius: 5px;
                    z-index: -1;
                }
                .simplebar-scrollbar{
                    width: 100%;
                    height: 4px;
                    top: auto;
                    bottom: 0;
                    border-radius: 5px;
                    background: #f466a2;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}