.cn__watchlist_control_header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 58px;
    border: 1px solid #94A3B8;
    border-radius: 20px;
    overflow-x: auto;
    &_body {
        position: relative;
        width: 779px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &_item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            line-height: 15px;
            padding-inline: 5px;
            &_typography {
                font-size: 10px;
                padding-inline: 5px;
                color: #94A3B8;
                &_heading {
                    color: $color-dark-grey;
                    &_primary {
                        color: $color-dark-grey;
                        font-weight: 600;
                        font-size: 14px;
                        white-space: nowrap;
                    }
                    &_secondary {
                        color: $color-dark-grey;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
            &_toggle {
                margin-left: 15px;
                margin-right: 9px;
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 30px;
                    height: 19px;
                  }
                  
                  .switch input { 
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }
                  
                  .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
                  }
                  
                  .slider:before {
                    position: absolute;
                    content: "";
                    height: 17.6px;
                    width: 17.6px;
                    left: 0.75px;
                    bottom: 0.75px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                  }
                  
                  input:checked + .slider {
                    background-color: white;
                  }
                  
                  input:focus + .slider {
                    box-shadow: 0 0 1px $color-vibrant-pink;
                  }
                  
                  input:checked + .slider:before {
                    -webkit-transform: translateX(11px);
                    -ms-transform: translateX(11px);
                    transform: translateX(11px);
                  }
                  
                  /* Rounded sliders */
                  .slider.round {
                    border-radius: 34px;
                  }
                  
                  .slider.round:before {
                    border-radius: 50%;
                  }
            }
            &_add-mobile-number {
                border: 1px solid #94A3B8;
                border-radius: 10px;
                font-size: 10px;
                font-weight: 500;
                padding-inline: 5px;
                color: #94A3B8;
                padding-inline: 7px;
                padding-block: 3px;
                cursor: pointer;
                background-color: $color-white;
                text-align: center;
                width: 128px;
                height: 20px;
            }
        }
    }
}