@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';

$inp-padding-x: 16px;
$inp-padding-y: 6px;
$label-padding-x: 4px;

.extra {
    position: relative;
    font-size: 1rem;

    >label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        // padding: 0.375em 1em;
        padding: $inp-padding-y calc(#{$inp-padding-x} - #{$label-padding-x});
        border: 1px solid transparent;
        color: $color-gray-hp;
        pointer-events: none;
        background-color: transparent;
        overflow: hidden;
        transform: translate(0, 0);
        transition: transform 0.2s ease;

        >span {
            padding: 0 4px;
            color: $color-gray-hp;
            pointer-events: none;
            background-color: transparent;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1em;
            font-weight: normal;
            line-height: 1.5em;
            transition:
                font-size 0.2s ease,
                background-color 0.2s ease,
                line-height 0.2s ease;
        }
    }

    >input,
    >textarea {
        width: 100%;
        // padding: 0.375em 1em;
        padding: $inp-padding-y $inp-padding-x;
        font-size: 1em;
        line-height: 1.5em;
        font-family: $primary-font;
        border: 1px solid $color-light-gray-input;
        border-radius: 6px;
        font-weight: normal;
        color: $color-black;
        height: unset;

        &::placeholder {
            font-family: $primary-font;
            font-size: 1em;
            font-weight: normal;
        }

        &:focus {
            outline: 1px solid $color-gray-hp;

            &+label {
                transform: translate(0, -50%);

                >span {
                    color: $color-black;
                    background-color: $color-white;
                    font-size: 0.75em;
                    line-height: 1em;
                }
            }
        }

        &[type="button"] {
            text-align: unset;
            cursor: pointer;
            background: none;
        }
    }

    >textarea {
        resize: none;
    }

    .extra-prefix {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: auto;
        opacity: 0;
    }

    .extra-suffix {
        position: absolute;
        top: 1px;
        right: 1px;
        // height: 100%;$inp-padding-y
        height: calc(1.5em + #{$inp-padding-y} + #{$inp-padding-y});
        width: auto;
    }

    .extra-error {
        position: absolute;
        // top: 114%;
        top: calc(100% + 5px);
        left: calc(#{$inp-padding-x} + 1px);
        font-size: .75em;
        font-weight: 400;
        line-height: .75em;
        color: $color-error-red;
    }

    &--filled {
        >label {
            transform: translate(0, -50%);

            >span {
                background-color: $color-white;
                font-size: 0.75em;
                line-height: 1em;
            }
        }

        .extra-prefix {
            opacity: 1;
        }
    }

    &--has-placeholder {
        >input,
        >textarea {
            &:placeholder-shown {
                &+label {
                    transform: translate(0, -50%);
    
                    >span {
                        background-color: $color-white;
                        font-size: 0.75em;
                        line-height: 1em;
                    }
                }
            }    
        }
    }

    &--error {
        >label {
            color: $color-error-red;
        }

        >input,
        >textarea {
            outline: 1px solid $color-error-red;

            &:focus {
                outline: 1px solid $color-error-red;

                &+label {
                    color: $color-error-red;
                }
            }
        }

        >.suffix {
            color: $color-error-red;
        }
    }

    &--focused {
        .extra-prefix {
            opacity: 1;
            transition: opacity 0.2s ease;
        }
    }
}