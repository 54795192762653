//---------MOBILE---------
.cn__cnt__wheretovisit{
    position: relative;
    margin:  0 auto;
    padding-top: 100px;
    width: 100%;
    &__title{
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #1D1C1F;
        padding: 0 20px;
    }
    &__slider{
        .slidermobile__wrapper{
            padding-top: 30px !important;
        }
    }
}

//---------TABLET---------
@media screen and (min-width:601px){
    .cn__cnt__wheretovisit{
        margin: 0 auto;
        padding-top: 200px;
        &__title{
            font-size: 44px;
            line-height: 48px;
            padding: 0 30px;
        }
        &__slider{
            .slidermobile__wrapper{
                padding-top: 34px !important;
            }
        }
    }
}


//---------DESKTOP---------
@media screen and (min-width:1136px){
    .cn__cnt__wheretovisit{
        &__title{
            padding: 0;
        }
        &__slider{
            margin-top: 34px;
            display: flex;
        }
    }
}