.cn__contentlocation__wrapper{
    .cn__contentlocation {
        background: #FFFFFF;
        color: #242526;
        border-radius: 15px;
        padding: 30px;
    
        &__header{
            border-bottom: solid 1px #E9E9EB;
            padding-bottom: 37px;
    
            &__title{
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;
            }
    
            &__options{
                position: relative;
                margin-top: 44px;
                display: flex;
    
                &__option{
                    position: relative;
                    margin-left: 6.5%;
                    cursor: pointer;
    
                    &:first-child{
                        margin-left: 0;
                    }
    
                    &::after{
                        position: absolute;
                        content: '';
                        width: 0%;
                        max-width: 50px;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -10px;
                        height: 4px;
                        background: #F35597;
                        border-radius: 2px;
                        transition: width 0.3s ease;
                    }
    
                    &.cn__trigger-cl-option-active{
                        &::after{
                            width: 50%;
                        }
                    }
                }
            }
        }
    
        &__body{
            padding-top: 28px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #797882;
    
            h2{
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }
    }
    .cn__faq{
        margin: 100px auto;
        .cn__faq__questions__question{
            &.cn__faq--question-active{
                .cn__faq__questions__question__answer{
                    max-height: 2000px;
                }
            }
        }
        .cn__faq__questions__question__question__title{
            font-weight: 600;
        }
        .cn__faq__questions__question__answer{
            h2{
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 14px;
            }
        }
    }
}

@media screen and (max-width:600px){
    .cn__contentlocation__wrapper{
        .cn__faq{
            margin: 60px auto;
            padding: 20px 0 10px 0;
        }
    } 
}