@import '../../global/mixins/global-mixins.scss';
.why-section {
    padding-left: 3.125rem;
    width: 100%;
    position: relative;
    .heading-section {
        //max-width: 50%;
        max-width: 602px;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        display: none;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            max-width: 528px;
        }
        @media screen  and (max-width: 600px) {
            //display: none;
        }
        h2 {
            font-family: $secondary-font;
            
            font-size: 42px;
            line-height: 115%;
            letter-spacing: -0.02em;
            text-align: center;

            color: $color-black;
            margin: 0 0 4rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
                margin-bottom: 3.125rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 30px;
            }
            span {
                //color: $color-primary;
                font-weight: 900;
                -webkit-text-stroke: 1px;
            }
        }
    }
    .why-box {
        padding: 30px 19px;
        //display: inline-block;
        vertical-align: top;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* background: rgba(255, 255, 255, 0.5);
        border: 1px solid #FFFFFF; */
        box-sizing: border-box;
        /* backdrop-filter: blur(20px);
        border-radius: 15px; */
        width: 333px;

        @media screen and (max-width: 1136px) {
            //padding: 0;
            text-align: center;
        }
        @media screen and (min-width: 601px) {
            width: 100%;
            height: 100%;
            justify-content: flex-start;
        }
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        img {
            margin: 0 0 0.75rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                margin: 0 0 1.3125rem 0;
            }
        }
        h4 {
            /* font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 500; */

            font-weight: 600;
            font-size: 17px;
            line-height: 20px;

            color: #797882;
            text-align: center;
            margin: 0 0 0.625rem 0;
            @media screen  and (max-width: 600px) {
                margin-top: 15px;
                line-height: 1.5rem;
                margin-bottom: 0;
            }
        }
        p {
            /* font-size: 1.0625rem;
            line-height: 1.25rem;
            font-weight: 300; */

            font-size: 16px;
            line-height: 19px;
            font-weight: 400;

            color: #797882;
            text-align: center;
            @media screen  and (max-width: 600px) { 
                line-height: 1.5rem;
                margin: 0 auto;
                margin-top: 5px;
                max-width: 290px;
                font-size: 1.25rem; 
                color: #ACABB4;
            }
        }
    }
    .lSSlideOuter {
        overflow: visible;
        .lSPager.lSpg {
            padding: 0.938rem 0 0;
            & > li { 
                margin: 0 0.75rem;
                a {
                    background-color: $color-subtle-gray; 
                }
                &:hover, 
                &.active {
                    a {
                        background-color: $color-black;
                    }
                }
            } 
        }
    }
    .slick-dots {
        bottom: -5px; 
    }

    .why-section-wrapper{
        position: relative;
        background: #EEEEF0;
        padding: 20px 0;
        border-radius: 20px;
        max-width: 1600px;
        margin: 0 auto;
        @media screen  and (max-width: 600px) {
            padding: 0;
            border-radius: 0;
        }
    }
}
