.banner-text-form {
    grid-column: 1/18;
    position: relative;
    z-index: 2;
    min-height: 722px; // 100% updated 14.02.2021
    padding: 97px 0;
    @media screen  and (max-width: 600px) {
        z-index: 3;
    }
    /* @media screen  and (max-width: 600px) {
        padding: 0;
    } */
    /*&::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        //background: rgb(255,255,255);
        background:rgba(0,0,0,0.5);
        z-index: 0;
    }*/
    .banner-scroll-down{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 134px;
        transition: opacity 0.6s ease;
    }
}
.stickysearch-box {
    background: #FFFFFF;
    padding: 1.25rem;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 1;
    width: 100%;
    display: none;
    @media screen  and (max-width: 600px) {
        display: block;
    }
    .autocomplete-dropdown-container {
        box-shadow: none;
        border-radius: 10px 10px 0 0;
        top: auto;
        bottom: 71px;
        width: 100vw;
        left: -20px;
    }
    form{
        background:#E9E9EB;
        border-radius: 10px;
        input{
            background: #E9E9EB !important;
        }
    }
    /*.cn__autocomplete-suggestions{
        top: auto !important;
        bottom: 100%!important;
    }*/
}
.banner-text-form {
    //background: #FEDCCB;
    background: $color-black;
    /* height: calc(100vh - 50px);
    min-height: rem(760);
    padding: 100px 0; */
    display: flex;
    align-items: center;
    @media screen and (min-width: 601px) and (max-width: 1136px) {
        min-height: 100vh;
        padding: 0;
    }
    @media screen  and (max-width: 600px) {
        //min-height: calc(var(--vh, 1vh) * 100);
    }
    .videoBgWrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        z-index: -100;
        @media (max-height:800px) and (min-width:600px) {
            min-height: 850px !important;
        }
        .videoBg{
            /*position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @media (min-aspect-ratio: 16/9) {
                  width: 100%;
                  height: auto;
            }
            @media (max-aspect-ratio: 16/9) {
                width: auto;
                height: 100%;
            }*/
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
        }
    }
    .banner-inner {
        width: 100%;
        //display: grid;
        position: relative;
        z-index: 1;

        display: flex !important;
        justify-items: center;
        align-items: center;
        align-self: flex-start; // updated 14.02.2021

        @media (min-width: 600px) {
            margin-top: 250px;       // updated 14.02.2021
        }

        @media (min-width: 1065px) {
            margin-top: 220px;
        }

        @media (min-width: 1137px) {
            margin-top: 62px;       // updated 14.02.2021
        }

        @media screen and (min-width: 1136px) {
            display: flex !important;
            justify-items: center;
            align-items: center;
            flex-direction: column;
            //padding-top: 15rem;
        }
        /* @media screen and (min-width: 1919px) {
            grid-template-columns: 290px repeat(7, 1fr 20px) 1fr 290px;
        } */
        @media screen and (min-width: 1136px) {
            grid-template-columns: 100px repeat(7, 1fr 20px) 1fr 100px;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 30px;
        }
        @media screen  and (max-width: 600px) {
            flex-direction: column;
            padding: 0;
            //margin-top: 8vh;
        }
    }
    .banner-text {
        //grid-column: 3/8;
        width: 610px;
        position: relative;
        //left: 356px;
        width: 610px;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            width:100%;
            grid-column: 1/18;
        }
        @media screen  and (max-width: 600px) {
            grid-column: 1/10;
            max-width: 328px;
        }
        h1 {
            font-family: $secondary-font;
            font-weight: 900;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.025em;
            color: #242526;
            -webkit-text-stroke: 1px;
            /* font-size: 4rem;
            line-height: 5rem;
            font-weight: bold;
            margin-bottom: 1.875rem;
            color: $color-white; */
            //white-space: nowrap;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 3.25rem;
                line-height: 4.0625rem;
                text-align: center;
            }
            @media screen  and (max-width: 600px) {
                text-align: center;
                //color: $color-white;
                /* font-size: 2.125rem;
                line-height: 2.6875rem; */

                font-size: 34px;
                line-height: 43px;
                letter-spacing: -0.02em;
                margin-bottom: 10px;

                //margin-bottom: 1.875rem;
                //text-shadow: 0 1px 2px rgba(36, 37, 38, 0.2)
                span {
                    color: $color-primary;
                }
            }
            span {
                //color: $color-primary;
            }
        }
        .desktop {
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
        // .mobile {
        //     @media screen and (min-width: 601px) {   updated 14.02.2021
        //         display: none;
        //     }
        // }
        p {
            /* font-size: 1.5rem;
            line-height: 1.8125rem;
            font-weight: 400;
            height: 58px;
            color: $color-white; */
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.02em;
            font-weight: 400;
            margin-top: 20px;
            //width: 520px;

            @media screen and (max-width: 1136px) {
                text-align: center;
                margin-bottom: 4.0625rem;
                max-width: 31.25rem;
                margin-right: auto;
                margin-left: auto;
            }
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 1.25rem;
                line-height: 1.5rem;
                margin-bottom: 4.0625rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 1.25rem;
                line-height: 1.5rem;
                text-align: center;
                color: $color-white;
                margin-bottom: 3.125rem;
                //text-shadow: 0 1px 2px rgba(36, 37, 38, 0.2)
            }
            > span {
                //background: url(#{$path-img}brush-stroke.svg) no-repeat;
                font-weight: 600;
                //padding-right: 0.625rem;
                //padding-left: 0.625rem;
                background-position: 0px center;
                display: unset;
                //color: $color-primary;
                .Typist{
                    display: inline-block;
                    .Cursor{
                        display: none;
                    }
                }
                @media screen and (min-width: 1136px) {
                    width: 100%;
                }
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    padding: 0;
                    //display: block;
                    background-position: center;
                    background-size: 9.9375rem auto;
                }
                @media screen  and (max-width: 600px) {
                    padding: 0;
                    display: block;
                    background-position: center 0;
                    background-size: 8.5625rem auto;
                }
            }
        }
    }
    .banner-search.full-width {
        @media screen  and (max-width: 600px) {
            grid-column: 1/10;
        }
    }
    .banner-search {
        grid-column: 12/17;//11/17;
        //width: 511px;
        border-radius: 10px;
        width: 430px;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            //grid-column: 2/17;
            margin: 0 auto;
            text-align: center;
        }
        @media screen  and (max-width: 600px) {
            grid-column: 2/9;
        }
        .search-input {

            background: #FFFFFF;
            border: 1px solid #E9E9EB;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
            border-radius: 10px;

            @media screen and (min-width: 601px) {
                display: none;
            }
            .search-field{
            @media screen  and (max-width: 600px) {
               > .primary-input.input-empty,  .primary-input {
                    background: #FFF;
                    border: 0;
                    font-weight: 500;
                    color:#555759;
                    padding-left: 20px;
                    &::placeholder{
                        color: inherit;
                    }
                }
            }
            }
        }
        .banner-search-inner {
            //background: $color-subtle-white;
            //border-radius: 0.1875rem;
            position: relative;
            background: $color-light-gray-shade;
            border-radius: 10px;
            float: right;
            width: 100%;
            max-width: 31.9375rem;
            padding: 3.125rem 2rem 3.5625rem;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                max-width: 27.3125rem;
                float: none;
                padding: 2.5rem 4.1875rem 5.125rem;
                margin: 0 auto;
            }
            @media screen  and (max-width: 600px) {
                display: none;
            }
        }
        #geoSearch{
            cursor: pointer;
        }
        h2 {
            font-family: $secondary-font;
            font-size: 1.75rem;
            line-height: 2.1875rem;
            font-weight: bold;
            color: $color-black;
            margin-bottom: 20px;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 1.375rem;
                line-height: 1.75rem;
                margin-bottom: 1.5625rem;
            }
        }
        .cn-tabs {
            /* .cn-tabs-nav {
                //margin-bottom: 2.5rem;
                display: flex;
                margin-bottom: 2.3125rem;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    justify-content: center;
                }
                li {
                    @include body-text();
                    color: $color-black;
                    padding-bottom: 0;
                    padding-top: 0;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                    border-right: 0.0625rem solid $color-light-gray;
                    height: 1.25rem;
                    font-weight: 400;
                    text-transform: capitalize;
                    span {
                        display: inline-block;
                        vertical-align: top;
                        padding-bottom: 0.75rem;
                        border-bottom: 0.1875rem solid $color-transparent;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                        border-right: none;
                    }
                    &.cn-active {
                        background: transparent;
                        @include body-emphasized();
                        font-size: 1.0625rem;
                        span {
                            border-bottom: 0.1875rem solid $color-primary;
                            display: block;
                        }
                    }
                }
            } */
            .cn-tabs-nav {
                background: #E9E9EB;
                width: 254px;
                margin: 0 auto;
                display: table;
                margin-bottom: 40px;
                border-radius: 10px;
                padding: 2px;
                .tab-link {
                    padding: 9px 0;
                    border-radius: 8px;
                    p{
                        transform: translateX(10px);
                    }
                    &:before{
                        left:18px !important;
                    }
                }
                li{
                    position: relative;
                    display: inline-table;
                    width: 50%;
                    text-align: center;
                    color: #797882;
                    font-weight: 400;
                    &.cn-active{
                        background: #f7f7f7;
                        font-weight: 600;
                        p{
                            background: linear-gradient(92.14deg,#f35597 1.79%,#f53959 99.81%);
                            background-size: 100% 200%;
                            background-position: 50% 100%;
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        &:first-child{
                            &::before{
                                position: absolute;
                                top: 8px;
                                left: 28px;
                                width: 24px;
                                height: 24px;
                                content: '';
                                background: url(/static/home/hotel-icon-grad.svg);
                            }
                        }
                        &:last-child{
                            &::before{
                                position: absolute;
                                top: 8px;
                                left: 28px;
                                width: 24px;
                                height: 24px;
                                content: '';
                                background: url(/static/home/house-icon-grad.svg);
                            }
                        }
                    }
                    span{
                        display: none;
                    }
                    &:first-child{
                        &::before{
                            position: absolute;
                            top: 8px;
                            left: 28px;
                            width: 24px;
                            height: 24px;
                            content: '';
                            background: url(/static/home/hotel-icon.svg);
                        }
                    }
                    &:last-child{
                        &::before{
                            position: absolute;
                            top: 8px;
                            left: 28px;
                            width: 24px;
                            height: 24px;
                            content: '';
                            background: url(/static/home/house-icon.svg);
                        }
                    }
                }
            }
            .cn-tabs-content {
                padding: 0;
                background: transparent;
                .guests-dd-box{
                    top: 38px !important;
                }
                .cn-input-group,
                .calendar-input {
                    margin-bottom: 1.25rem;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        margin-bottom: 1.5625rem;
                    }
                }
                .cn-autoComplete{
                    .cn-input-group{
                        input{
                            background:#E9E9EB;
                            &:not(:placeholder-shown){
                                background: #ffffff;
                                border: 1px solid #E9E9EB;
                                box-shadow: rgba(36, 37, 38, 0.2) 0px 1px 2px;
                             }
                        }
                    }
                }
                .guests-input-box {
                    margin-bottom: 2.5rem;
                    .guests-input{
                        display: block;
                        cursor: pointer;
                    }
                }
                .btn{
                    //font-size: 1.0625rem;
                    font-size: 16px;
                    line-height: 20px;
                    border-radius: 6px;
                    //padding: 6px 30px;
                    padding: 5px 0;
                    height: auto;
                    min-width: auto;
                    transition: all 0.6s ease;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        padding: 0px 30px;
                    }
                }
                .btn-primary {
                    width: auto;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .search-input{
            .search-field {
                input{
                    padding-left: 0;
                    height: 3.25rem;
                    width: calc(100% - 2.8125rem);
                    font-size: 1.0625rem;
                }
                .add-child {
                        input{
                        padding-left: 0;
                        height: 3.25rem;
                        width: calc(100% - 2.8125rem);
                        font-size: 1.0625rem;
                        }
                }
            }
            span {
                padding: 1rem 0 0 0 ;
                width: 2.8125rem;
            }
         }

         .guests-dd-box{
            .add-child{
                input{
                    box-shadow: none;
                    background: #E9E9EB;
                    &:focus{
                       border: 1px solid #E9E9EB !important;
                       background: #ffffff;
                    }
                    &:not(:placeholder-shown){
                       background: #ffffff;
                       border: 1px solid #E9E9EB;
                    }
                }
                .btn{
                   padding: 0.3125rem 0.625rem!important;
                }
            }
         }
         .clear-guest-dd.btn-text-bright-purple{
             padding: 0.5rem!important;
             margin-left: -0.5rem;
         }
    }
}