@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp{
    position: absolute;
    width: 100%;
    height: 100%;
    --color-background: #FAFAFA;
    --color-background2: #F6F7FA;

    .header .header-grid .header-nav ul .li--listproperty {
        display: none;
    }

    &__wrapper{
        position: relative;
        padding: 0 100px 9vw;
        background: var(--color-background);
    }

    //--- Header
    .header{
        .max-width-wrapper{
            max-width: 1440px;
            padding: 0 50px;

            //--Header options
            .li--blog, .li--currency{
                display: none;
            }
        }
    }

    //---Button
    &__button{
        position: relative;
        padding: dvw(10) dvw(25);
        border-radius: dvw(30);
        background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
        //font
        font-weight: 600;
        font-size: dvw(16);
        line-height: 1;
        text-align: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-flex;
        align-items: center;

        svg{
            width: dvw(24);
            height: dvw(24);
            path{
                stroke: #FFFFFF;
            }
        }

        p{
            pointer-events: none;
            margin-left: dvw(8);
        }

        //--- button classes
        &.lypb1{
            margin-top: dvw(54);
        }
        &.lypb2{
            margin-top: dvw(56);
            left: 50%;
            transform: translateX(-50%);
        }
        &.lypb3{
            margin-top: dvw(50);
            padding: dvw(11) dvw(60);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    //--- Footer
    .cn__footer__wrapper{
        background: var(--color-background);
    }
}



@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp{

        &__wrapper{
            padding: 0 100px 150px;
            .cn__faq{
                max-width: 1240px;
                margin: 0 auto;
            }
        }

        //---Button
        &__button{
            padding: 11px 20px;
            //font
            font-size: 16px;

            svg{
                width: 24px;
                height: 24px;
            }

            //--- button classes
            &.lypb1{
                margin-top: 54px;
            }
            &.lypb2{
                margin-top: 56px;
                left: 50%;
                transform: translateX(-50%);
            }
            &.lypb3{
                margin-top: 50px;
                padding: 11px 60px;
            }
        }

        //--- Footer
        .cn__footer__wrapper{
            .cn__footer{
                max-width: 1440px;
                margin: 0 auto;
            }
        }
    }
}



//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp{

        &__wrapper{
            padding: 0 0 14vw;
        }

        //--- Header
        .header{
            .max-width-wrapper{
                padding: 0;
            }
        }

    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp{

        &__wrapper{
            padding: 0 0 60px;
        }

        //Button
        &__button{
            width: calc(100% - 40px);
            left: 20px;
            justify-content: center;
            font-size: mvw(16);
            padding: mvw(11);
            border-radius: mvw(30);

            svg{
                width: mvw(24);
                height: mvw(24);
            }

            p{
                margin-left: dvw(10);
            }

            &.lypb3{
                margin-top: 10vw;
                padding: 3.5vw 2.5vw;
                width: 100%;
            }
        }
        .lypb1{
            margin-top: mvw(20);
        }

    }
}