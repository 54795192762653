:root {
    --help-top-position: 100px;
    background: $color-white;
}
    .cn__reciept__wrapper {
        position: absolute;
        width: 100%;
        overflow-x: unset;
        background: $color-white;
        .cn__reciept {
            position: relative;
            width: 100%;
            max-width: 650px;
            margin: 0 auto;
            padding-top: 50px;
            padding-bottom: 70px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .cn__reciept__header {
                display: flex;
                justify-content: space-between;
                background: $color-cn-pink;
                padding-top: 31px;
                padding-bottom: 31px;
                padding-left: 24px;
                padding-right: 24px;
                border-radius: 24px;
                width: 100%;
                .cn__reciept__header__logo {
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
                .cn__reciept__header__bookingcode {
                    display: flex;
                    flex-direction: column;
                    color: white;
                    font-size: 12.814px;
                    font-weight: 600;
                    .cn__reciept__header__booking {
                        font-size: 7.208px;
                        font-weight: 400;
                        margin-right: 25px;
                    }
                    .cn__reciept__header__code {
                        font-size: 10.411px;
                        font-weight: 600;
                    }
                }
            }
            .cn__reciept__hotelpolice {
                border-radius: 15px;
                border: 1px solid var(--color-field-grey);
                padding: 20px;
                .cn__reciept__hotelpolice__title {
                    margin-top: 20px;
                    color: var(--color-black);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16.8px;
                }
                .cn__reciept__hotelpolice__container {
                    margin-left: 20px;
                    margin-top: 15px;
                }
                p {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 10px;
                      font-weight: 400;
                }
                b {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14.4px;
                    color: var(--color-black);
                    margin-top: 10px;
                    margin-bottom: 30px;
                }
                ul {
                    margin-left: 30px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    color: var(--color-black);
                    font-size: 10px;
                    li {
                      font-size: 10px;
                      font-weight: 400;
                      margin-top: 10px;
                      line-height: 19.2px;
                    }
                }
            }
            .cn__reciept__hoteldetail {
                display: flex;
                gap: 8px;
                .cn__reciept__hoteldetail__hotel {
                    display: flex;
                    flex: 3;
                    flex-basis: 60%;
                    border-radius: 13px;
                    border: 1px solid #DFE2E5;
                    gap: 30px;
                    img {
                        width: 110px;
                        height: 100%;
                        border-radius: 12px 1px 1px 12px;
                        object-fit: cover;
                    }
                    .cn__reciept__hoteldetail__hoteldetail {
                        align-self: center;
                        font-size: 10px;
                        font-weight: 400;
                        padding-right: 25px;
                        color: $color-pure-black;
                        .accomodation_type {
                            display: flex;
                            place-items: center;
                        }
                        span {
                            font-weight: 600;
                        }
                        .cn__reciept__hoteldetail__hotel__detail__title {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                .cn__reciept__hoteldetail__room {
                    flex: 1;
                    flex-basis: 25%;
                    border-radius: 13px;
                    border: 1px solid #DFE2E5;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    padding-right: 10px;
                    padding-left: 10px;
                    .cn__reciept__hoteldetail__roomcheckinchekout {
                        display: flex;
                        justify-content: center;
                        gap: 14px;
                        margin-top: 12px;
                        align-self: center;
                        align-items: center;
                        div {
                            display: flex;
                            flex-direction: column;
                            font-size: 5.606px;
                            font-weight: 400;
                            align-items: center;
                            color: $color-pure-black;;
                            .big_size {
                                font-size: 20.822px;
                            }
                            .bold_text {
                                font-size: 8.009px;
                                font-weight: 600;
                            }
                            .slight_bold_text {
                                font-size: 8.009px;
                                font-style: italic;
                            }
                            .date {
                                align-self: center;
                                align-items: center;
                                display: flex;
                                gap: 2px;
                                font-size: 8.009px;
                                font-weight: 400;
                            }
                        }
                    }
                    .cn__reciept__hoteldetail__roomguest {
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        font-size: 5.606px;
                        font-weight: 400;
                        gap: 8px;
                        div {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            align-self: center;
                        }
                        span {
                            font-size: 20.822px;
                            font-weight: 400;
                        }
                    }
                }
            }
            .cn__reciept__hoteldetailprice {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: $color-pure-black;;
                padding: 0 32px;
                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                    }
                    .cn__reciept__hoteldetailprice__room__text {
                        font-size: 10px;
                        font-weight: 400;
                    }
                    .cn__reciept__hoteldetailprice__room__price {
                        font-size: 10px;
                        font-weight: 500;
                    }
                    .cn__reciept__hoteldetailprice__room__totalprice {
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .cn__reciept__hoteldetailprice__room__slight {
                        font-size: 10px;
                        font-weight: 400;
                        color: $color-gray-hp;
                        max-width: 530px;
                    }
                    .cn__reciept__hoteldetailprice__pricebold {
                        font-size: 16.017px;
                        font-weight: 600;
                    }
                    .cn__reciept__hoteldetailprice__pricebold__guest {
                        color: #333;
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
            }
            .cn__reciept__roomdetail {
                display: flex;
                flex-direction: column;
                gap: 8px;
                font-weight: 400;
                font-size: 10px;
                color: $color-pure-black;;
                .verybold {
                    font-size: 14px;
                    font-weight: 600;
                }
                .bold {
                    font-size: 12px;
                    font-weight: 600;
                }
                .slightbold {
                    font-size: 10px;
                    font-weight: 600;
                }
                .boldish {
                    font-size: 12px;
                    font-weight: 400;
                }
                .cn__reciept__roomdetail__roominfo {
                    display: flex;
                    flex: 3;
                    flex-basis: 60%;
                    flex-direction: column;
                    gap: 10px;
                    div {
                        border-radius: 15px;
                        border: 1px solid #DFE2E5;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }
                .cn__reciept__roomdetail__cancellation {
                    display: flex;
                    gap: 10px;
                    div {
                        border-radius: 15px;
                        border: 1px solid #DFE2E5;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        place-content: center;
                    }
                    .cn__reciept__roomdetail__cancellationpolicy {
                        width: 30%;
                    }
                    .cn__reciept__roomdetail__cancellation__cost {
                        width: 70%;
                    }
                }
            }
//------------------------ MOBILE ------------------------//
            @media screen and (max-width: 660px) {
                padding: 0 15px;
                padding-top: 40px;
                overflow: hidden;
                .cn__reciept__header {
                    padding-left: 10px;
                    flex-direction: column;
                    gap: 15px;
                    .cn__reciept__header__booking {
                        margin-right: 5px;
                    }
                }
                .cn__reciept__hoteldetail {
                    flex-direction: column;
                    .cn__reciept__hoteldetail__hoteldetail {
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }
                .cn__reciept__roomdetail {
                    flex-direction: column;
                    .cn__reciept__roomdetail__roominfo {
                        flex-direction: column;
                    }
                    .cn__reciept__roomdetail__cancellation {
                        flex-direction: column;
                        .cn__reciept__roomdetail__cancellationpolicy {
                            width: 100%;
                        }
                        .cn__reciept__roomdetail__cancellation__cost {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
