.pane-toolbar {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;

    &.pane-toolbar--flat-mobile {
        align-items: center;
        padding: 0 20px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        //background-color: var(--color-subtle-white);
        background-color: white;
        width: 100%;
        min-height: 36px;
        .back-action {
            .ic-icon {
                svg {
                    top: -5px;
                    left: -12px;
                    position: relative;
                }
            }
        }
    }

    &.pane-toolbar--flat-mobile-minimal {
        background-color: transparent;
        position: absolute;
        z-index: 1;

        .back-action {
            background-color: var(--color-subtle-white);
            border-radius: 50%;
            margin-top: 15px;
            margin-left: 15px;

            .ic-icon {
                width: 36px;
                height: 36px;

                svg {
                    position: relative;
                    top: -2px;
                    left: -3px;
                }
            }
        }
    }

    &.pane-toolbar--flat-desktop {
        background-color: var(--color-subtle-white);
        border-bottom: 1px solid var(--color-light-grey);
        height: 90px;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;

        @media (min-width: 1136px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @media (min-width: 1440px) {
            padding-left: calc( (100vw - 1340px) / 2);
            padding-right: calc( (100vw - 1340px) / 2);
        }

        .pane-toolbar_back {
            display: flex;
            align-items: center;
            height: inherit;
        }

        .pane-toolbar_back-action {
            border-radius: 50%;
            background-color: var(--color-white);
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: var(--color-light-grey);
            }
            .ic-icon {
                width: inherit;
                height: inherit;
            }
        }
        .pane-toolbar_toolbar-actions {
            margin: 0;
            .n-tooltip.n-tooltip--left {
                .n-tooltip__inner {
                    top: 35%;
                    transform: translateY(-35px);
                    &::before {
                        bottom: calc(50% + 10px);
                    }
                }
            }
        }
    }

    &.pane-toolbar--desktop-with-title {
        align-items: flex-end;
    }

    .pane-toolbar_toolbar-actions {
        .n-btn {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            text-decoration: underline;
            column-gap: 3px;
            border-radius: 8px;
            padding: 8px;
        }
    }
    .actions-list:not(.actions-list--mobile) > li {
        margin: 0;
    }
}