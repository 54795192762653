.header {
  

  .stopActionsContainer {
    position: fixed;
    z-index: 9;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background-color: transparent;
  }   

  grid-column: 1/18;
  grid-row: 1;
  padding: 0.9375rem 0;
  z-index: 3;
  border-bottom: 1px solid $color-light-gray;
  display: grid;
  transition: none;
  .li--listproperty {
    white-space: nowrap;
  }
  
  &:not(&.header-home) {
    .header-nav {
      .li--listproperty, .li--wishlist {
        p {
          margin-bottom: 4px;
        }
      }
    }
  }
  .header-nav {
    ul {
      li {
        a {
          color: $color-black;
          font-weight: 400 !important;
        }
      }
      .li--currency {
        div {
          display: flex;
          align-items: center;
          height: 24px;
        }
      }
    }



    .filters-tab-bar_minimized {
      cursor: pointer;
      z-index: 3;
      top: rem(-120);
      opacity: 0;     
      position: relative; 
      width: 100%;
      border: none;
      border-radius: rem(15);
      outline: none;
      font-family: $primary-font;
      font-size: 1rem;
      color: $color-black;
      text-align: center;
      transition: top .3s ease-in-out, opacity .3s ease-in-out;
      display: grid;
      justify-content: flex-end;
      align-items: stretch;
      column-gap: rem(15);
      background-color: transparent;
      grid-template-columns: 1fr;

      @media (min-width: 790px) {
          grid-template-columns: 80% max-content;
          width: 60%;
      }

      .filters-tab-bar_minimized-wrapper {
          border-radius: rem(34);
          background-color: $color-white;
          display: grid;
          grid-template-columns: max-content minmax(10%,auto) 0.9375rem max-content 0.9375rem max-content;
          align-items: center;
          justify-content: space-evenly;
          line-height: rem(44);
          padding-left: rem(15);
          padding-right: rem(20);
          border: rem(1) solid $color-field-grey;
          box-shadow: 0 rem(6) rem(14) rem(-6) rgba(24, 39, 75, .12);

          // &:hover {
          //     background-color: #DADDE0;
          // }
          & > .filters-tab-bar_minimized-term {
            text-overflow: ellipsis;
          }

          & > * {
              overflow: hidden;
              white-space: nowrap;
              text-align: left;
              @media (max-width:900px) {
                font-size: 0.75rem;
              }
          }

          .search-icon__highlighted {
              margin-right: rem(10);
              width: rem(20);
              height: rem(20);
              path {
                  fill: $color-slate-grey;
              }
          }

          .filters-tab-bar_minimized-dates__nodates {
              color: $color-subtle-gray;
          }

          .filters-tab-bar_minimized-separator {
              justify-self: center;
              width: rem(1);
              height: rem(22);
              background-color: #DADBDB;
          }

          .filters-tab-bar_minimized-guests {
              text-align: left;
          }
      }

      .filters-tab-bar_minimized__subbtn {
          display: none;
          @media (min-width: 790px) {
              // width: rem(44);
              // height: rem(44);
              border-radius: rem(25);
              border: rem(1) solid $color-field-grey;
              display: grid;
              grid-template-columns: repeat(2, min-content);
              column-gap: rem(5);
              align-items: center;
              color: $color-black;
              letter-spacing: 0.01em;
              background-color: #fff;
              
              font-size: rem(12);
              line-height: 150%;
              padding: rem(3) rem(12) rem(3) rem(7);
              // background-color: $color-light-gray-input;

              path {
                  fill: $color-black;
              }
          }
      }
  }
  }
  //Logo
  .header-logo {
    .cnword {
      position: relative;
      transition: all 0s ease-in-out;
    }
    .logo-word-remove {
      opacity: 0;
      transform: translateX(5px);
    }
    .logo-purple {
      fill: #4c35bc;
    }
    .logo-pink {
      fill: #fa368b;
    }
    .logo-white {
      fill: $color-white;
    }
  }
  .logoImgWhite {
    //width: 144.54px;
    //grid-column-start: 2;
    width: 185px;
    a {
      width: 100%;
      svg {
        //width: 134.54px;
        width: 170px; //133px;
        @media screen and (min-width: 300px) and (max-width: 600px) {
          width: 133px;
        }
      }
    }
    @media screen and (min-width: 300px) and (max-width: 600px) {
      width: 133px;
    }
    @media screen and (min-width: 601px) and (max-width: 1136px) {
      grid-column: 2/5;
    }
  }
  &.header-transparent {
    border-bottom: none;
    background: transparent;
  }
  /* @media screen  and (max-width: 600px) {
        display: none;
    }*/
  &.header-transparent {
    position: absolute;
    top: 0;
    //top: 50px;
    width: 100%;
    @media screen and (max-width: 600px) {
      border-bottom: none;
      &.header-mob {
        grid-column: 1/10;
        padding: 20px;
        z-index: 5;
        &.header-home {
          &:not(.cn__header--active) {
            .logo-birds_icon {
              path {
                fill: $color-white;
              }
            }
          }
        }
        // &:not(.cn__header--active) {
        //     svg {
        //         path {
        //             fill: $color-white;
        //         }
        //     }
        // }
      }
    }
  }
  &.sticky {
    .menu-mob {
      .menu-btn-mob {
        border-top-color: $color-black;
        border-bottom-color: $color-black;
      }
    }
  }
  &.header-dark {
    &.header-purple {
      background-color: $color-secondary;
      border-color: $color-secondary;
      .header-grid {
        grid-template-columns: 50px repeat(7, 1fr 20px) 1fr 50px;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
          grid-template-columns: 30px repeat(7, 1fr 20px) 1fr 30px;
        }
        .header-nav {
          > ul {
            li {
              a {
                color: $color-white;
              }
              /* svg {
                                rect{
                                    .blog1,.blog2{
                                        stroke: $color-white !important;
                                    }
                                    &.rect1{
                                        stroke: $color-white;
                                    }
                                    &.rect2{
                                        fill: $color-white;
                                    }
                                }
                                path,circle {
                                    stroke: $color-white;
                                }
                                .listsvg{
                                    stroke: $color-white !important;
                                    fill:none !important;
                                }
                            } */
              ul {
                li {
                  a {
                    color: $color-black;
                  }
                }
              }
            }
          }
        }
      }
      &.sticky {
        .header-grid {
          .header-nav {
            > ul {
              li {
                a {
                  color: $color-white;
                  /* svg {
                                        rect{
                                            .blog1,.blog2{
                                                stroke: $color-white !important;
                                            }
                                            &.rect1{
                                                stroke: $color-white;
                                            }
                                            &.rect2{
                                                fill: $color-white;
                                            }
                                        }
                                        path,circle {
                                            stroke: $color-white;
                                        }
                                        .listsvg{
                                            stroke: $color-white !important;
                                            fill:none !important;
                                        }
                                    } */
                }
                ul {
                  li {
                    a {
                      color: $color-black;
                      svg {
                        path {
                          stroke: $color-black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.header-black {
      background-color: $color-black;
      border-color: $color-black;
    }
    @media screen and (max-width: 600px) {
      &.header-mob {
        grid-column: 1/10;
        padding: 20px;
      }
    }
    /*.header-grid {
      .header-nav {
        & > ul {
          & > li {
            & > a {
              color: $color-white;
              svg {
                path {
                  stroke: $color-white;
                }
              }
            }
          }
        }
      }
    }*/
    /*.search-input span svg path {
      fill: $color-white;
    }*/
    .search-input {
      background: rgba(235, 234, 235, 0.2);
      border: none;
      span svg path {
        fill: #e9e9eb;
      }
      input {
        background: none;
        border: none;
        color: #e9e9eb;
        &::-webkit-input-placeholder {
          color: $color-white;
          opacity: 1;
        }
        &::-moz-placeholder {
          color: $color-white;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: $color-white;
          opacity: 1;
        }
        &:-moz-placeholder {
          color: $color-white;
          opacity: 1;
        }
      }
    }
  }
  .header-grid {
    align-items: center;
    @include full-sub-grid();
    .logoImg {
      /*grid-column: 2/3;
      grid-row: 1;*/
      // display: flex; // breaking on safari - logo doesn't show up on landing page
      width: 185px;
      grid-column-start: 2;
      //margin: 0.1875rem 0 0 0;
      a {
        width: 100%;
        svg {
          width: 200px; //133px;
        }
      }
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-column: 2/5;
      }
    }
    .logoImgWhite {
      //width: 144.54px;
      grid-column-start: 2;
      width: 185px;
      a {
        width: 100%;
        svg {
          // width: 134.54px;
          width: 170px; //133px;
        }
      }
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-column: 2/5;
      }
    }
    .header-search {
      /*grid-column: 4/7;
      grid-row: 1;
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-column: 6/10;
      }*/
      width: 311px;
      margin-left: 24px;
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        width: 215px;
        //margin-left: -10px;
      }
    }
    .header-nav {
      grid-column: 4/17;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-column: 9/17;
      }
      /*grid-row: 1;
      text-align: right;*/
      div.login-menu-wrapper {
        @extend .sub-menu;
        position: absolute;
        visibility: 0;
        opacity: 0;
        display: none;
        right: 0;
        top: 2.5rem;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        z-index: 1;
        transition: transform 0.3s ease, visibility 0.3s ease, opacity 0.3s ease;
      }
      > ul {
        .profile-status {
          position: absolute;
          width: 10px;
          height: 10px;
          background: linear-gradient(92.14deg, #f35597 1.79%, #f53959 99.81%);
          border-radius: 50%;
          bottom: -2px;
          right: 2px;
        }
        &.sub-menu {
          visibility: hidden;
          background: $color-white;
          //box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.4);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          position: absolute;
          left: auto;
          right: 0;
          top: 2rem;
          margin: 0;
          opacity: 0;
          min-width: 150px;
          transform: translateY(-1em);
          transition: transform 0.3s ease, visibility 0.3s ease,
            opacity 0.3s ease;
          z-index: 1;
          //z-index: 8;
          //-webkit-mask-image: -webkit-radial-gradient(white, black);
          //mask-image: -webkit-radial-gradient(white, black);
          li {
            margin: 0 0 0.875rem;
            a {
              font-variant: normal;
              font-size: 0.875rem;
              line-height: 1.063rem;
              font-size: 0.875rem;
              line-height: 1.063rem;
              text-transform: unset;
              cursor: pointer;
              span {
                display: inline-block;
                width: 2.5rem;
                font-weight: 600;
                color: $color-subtle-gray;
              }
            }
          }
        }
        &.login-menu {
          @extend .sub-menu;
          display: block;
          right: 0;
          top: 2.5rem;
          //visibility: visible;
          //padding: 0.625rem 0.625rem 1.25rem 0.625rem;
          //box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.4);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          min-width: 220px;
          li {
            margin: 0;
            transition: all 0.6s ease;
            .header_list-space {
              border-top: 2px solid $color-light-gray-input;
              font-weight: 600;
              font-size: 1rem;
              line-height: 1.25rem;
              padding-top: 32px;
              padding-bottom: 32px;
              .header_list-space_icon {
                path {
                  stroke: $color-black;
                }
              }
              span {
                width: auto;
                color: $color-black;
              }
            }
            &:hover {
              background: $color-light-gray;
            }
            /* &:first-child{
              font-size: 1rem;
              line-height: 1.25rem;
              font-weight: 500;
              padding-bottom: 0.625rem;
              border-bottom: solid 1px #E9E9EB;
            } */
            a {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: $color-black !important;
              padding: 15px 25px;
              span {
                position: relative;
                top: 2px;
                width: 24px;
                margin-right: 10px;
                img {
                  margin-right: 0;
                }
              }
              /* ont-variant: normal;
              font-size: 0.875rem;
              line-height: 1.063rem;
              font-size: 0.875rem;
              line-height: 1.063rem;
              text-transform: unset;
              padding: 0.625rem;
              border-radius: 7px;
              color: $color-black !important; */
              /* &:hover {
                background: $color-light-gray;
              } */
              /* span {
                font-weight: 600;
                color: $color-primary;
                font-size: 30px;
                vertical-align: top;
                line-height: 16px;
              } */
            }
            &.lm-1 {
              width: max-content;
              &:hover {
                background: none;
              }
              a {
                padding: 25px;
                color: #797882 !important;
                svg {
                  path {
                    fill: #c4c4c4;
                  }
                }
              }
            }
            &.lm-2 {
              border-top: 2px solid #e9e9eb;
            }
            &.lm-6 {
              // margin-bottom: 10px;
              &:hover {
                background: none;
              }
            }
            /* &:last-child{
              a{
                color:$color-danger !important;
              }
            } */
          }
        }
        padding: 0;
        //margin: 0.5625rem 0 0 0;
        list-style: none;
        display: flex;
        align-items: center;
        .currency--selected {
          //width: 2.8125rem;
        }
        li.li--listproperty {
          p {
            white-space: pre;
          }
        }
        li {
          /*
          &:hover {
            svg {
              transition: 0.4s;
              transform: rotate(180deg);
            }
          }*/
          position: relative;
          ul {
            min-width: 200px;
            li {
              display: block;
              text-align: left;
              margin: 0 0 0.438rem;
              a {
                display: block;
                &.select {
                  font-weight: 600;
                  background-image: url(#{$path-img}big-checkmark.svg);
                  background-position: center right;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-size: 15px auto;
                  span {
                    color: $color-black;
                  }
                }
              }
            }
          }
          /*&.currency-menu {
            overflow-x: auto;
            height: 185px;
          }*/
          &.top-currency {
            //border-bottom: 1px solid $color-light-gray-input;
            //padding-bottom: 0.625rem;
            //margin-bottom: 1.1875rem;
            padding-top: 0.625rem;
            margin-bottom: 0;
            ul {
              min-width: 100%;
              display: unset;
            }
          }
          &.bottom-currency {
            margin-bottom: 0.625rem;
            ul {
              min-width: 100%;
              display: unset;
            }
          }
          /*&:hover > ul {
            display: block;
            transform: translateY(0%);
            transition-delay: 0s, 0s, 0.3s;
            visibility: visible;
            opacity: 1;
          }*/
          padding: 0;
          margin: 0 0 0 30px;
          display: flex;
          align-items: center;
          .header-menu-div {
            display: flex;
            align-items: center;
            span {
              img {
                margin-top: 4px;
                margin-right: 5px;
              }
            }
          }
          


          @media screen and (min-width: 780px) and (max-width: 1136px) {
            margin: 0 0 0 .5rem;
            &.li--currency.currency--selected {
              margin-right: 5px;
            }
          }
          @media screen and (min-width: 601px) and (max-width: 780px) {
            margin: 0 0 0 .5rem;
            &.li--currency.currency--selected {
              margin-right: 5px;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          a {
            @include nav-font();
            /*font-size: 16px;
            text-transform: uppercase;*/
            //color: $color-white;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: 400;
            .header-dark & {
              color: $color-white;
            }
            svg {
              
              margin-right: 5px;
              /* path {
                                stroke: $color-black;
                            } */
            }
            img {
              margin-right: 5px;
            }
            @media not all and (min-resolution: 0.001dpcm) {
              @supports (-webkit-appearance: none) {
                font-size: 1.25rem;
                letter-spacing: 0.7px;
              }
            }
          }
        }
        &.sub-menu-currency {
          background: $color-subtle-white;
          width: 19.8125rem;
          //padding: 1.5625rem 1.25rem;
          padding: 0 1.25rem 0 0.625rem;
          height: 17.375rem;
          overflow: hidden;
          display: block;
          @media screen and (min-width: 601px) and (max-width: 1136px) {
            min-width: 17rem;
          }
          ul {
            li {
              font-size: 0.875rem;
              line-height: 1.0625rem;
              font-weight: 400;
              color: $color-black;
              //margin: 0 0 1.25rem 0;
              margin: 0;
              padding: 0.625rem 0.875rem;
              cursor: pointer;
              border-radius: 6px;
              transition: all 0.3s ease;
              //background:$color-shade-gray;
              a {
                font-size: 0.875rem;
                line-height: 1.0625rem;
                font-weight: 300;
                color: $color-black !important;
                span {
                  //width: 2.5rem;
                  width: 1.625rem;
                  margin-right: 1rem;
                  display: inline-flex;
                  justify-content: center;
                  font-weight: 400;
                  color: $color-subtle-gray;
                }
                &.select {
                  background: url(#{$path-img}currency-tick.svg) no-repeat;
                  background-position: center right;
                  font-weight: 600;
                  color: $color-black;
                  span {
                    font-weight: 600;
                    color: $color-black;
                  }
                }
              }
              &:hover {
                background: #eeeef0;
              }
            }
          }
          .simplebar-track {
            height: calc(100% - 2.4rem);
            pointer-events: all;
            cursor: pointer;
          }
          .simplebar-track.simplebar-vertical {
            top: 18px;
            width: 11px;
            right: -18px;
          }
          .simplebar-scrollbar {
            &::before {
              width: 4px;
              background: #acabb4;
            }
          }
        }
      }
    }
  }
  &.sticky {
    position: fixed;
    left: 0;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    .header-grid {
      .header-nav {
        > ul {
          li {
            a {
              color: $color-black;
              /* svg {
                                .blog1,.blog2{
                                    stroke: $color-black !important;
                                }
                                rect{
                                    &.rect1{
                                        stroke: $color-black;
                                    }
                                    &.rect2{
                                        fill: $color-black;
                                    }
                                }
                                path,circle {
                                    stroke: $color-black;
                                }
                                .listsvg{
                                    stroke: $color-black !important;
                                    fill:none !important;
                                }
                            } */
            }
          }
        }
      }
    }
  }
  &.header-tp {
    .header-grid {
      grid-template-columns: 50px repeat(7, 1fr 20px) 1fr 50px;
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-template-columns: 30px repeat(7, 1fr 20px) 1fr 30px;
      }
      /*.logoImg {
        width: 141px;
        a{
          width: 100%;
          svg {
            width: 131px;
          }
        }
      }*/
      .header-search {
        width: 302px;
        margin-left: 20px;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
          width: 217px;
          //margin-left: -10px;
        }
      }
      .header-nav {
        > ul {
          li {
            a {
              color: $color-black;
              /* svg {
                                .blog1,.blog2{
                                    stroke: $color-black !important;
                                }
                                rect{
                                    &.rect1{
                                        stroke: $color-black;
                                    }
                                    &.rect2{
                                        fill: $color-black;
                                    }
                                }
                                path,circle {
                                    stroke: $color-black;
                                }
                                .listsvg{
                                    stroke: $color-black !important;
                                    fill:none !important;
                                }
                            } */
            }
          }
        }
      }
    }
    &.header-mob {
      background: #f7f7f7 !important;
      .menu-mob {
        .menu-btn-mob {
          border-top-color: $color-black !important;
          border-bottom-color: $color-black !important;
        }
      }
    }
  }
  &.header-home {
    // .hamburguer-menu {
    //     .hamburguer-menu-lin1, .hamburguer-menu-lin2{
    //         background: $color-white !important;
    //     }
    // }
    .header-grid { 
      @media screen and (min-width: 1136px) {
        grid-template-columns: 100px repeat(7, 1fr 20px) 1fr 100px;
      }
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        
        
        grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 30px;
      }
      @media screen and (max-width: 600px) {
        grid-template-columns: 20px repeat(3, 1fr 10px) 1fr 20px;
      }
      /* .header-nav {
                ul {
                    li {
                        a {
                            color: $color-black;
                            font-weight: 400;
                            svg{
                                path, .rect2{
                                    fill: $color-black
                                }
                                .rect1, .blog1, .blog2, .listsvg, circle:not([class]){
                                    stroke: $color-black;
                                }
                                .listsvg{
                                    fill: none;
                                }
                            }
                        }
                    }
                }
            } */
    }
    &.top-header {
      top: 0;
    }
    .header-nav {
      > ul {
        li {
          a {
            color: $color-white;
          }
        }
      }
    }
    .menu-mob {
      a {
        border-top-color: $color-black;
        border-bottom-color: $color-black;
      }
    }
    //Logo
    // .header-logo {
    //     .logo-purple {
    //         fill: $color-white !important;
    //     }
    //     .logo-pink {
    //         fill: $color-white !important;
    //     }
    // }
    &.sticky {
      .hamburguer-menu {
        .hamburguer-menu-lin1,
        .hamburguer-menu-lin2,
        .hamburguer-menu-lin3 {
          background: $color-black !important;
        }
      }
      .header-grid {
        .header-nav {
          > ul {
            li {
              a {
                color: $color-black;
                svg {
                  path,
                  .rect2 {
                    fill: $color-black;
                  }
                  .rect1,
                  .blog1,
                  .blog2,
                  .listsvg,
                  circle:not([class]) {
                    stroke: $color-black;
                  }
                  .listsvg {
                    fill: none;
                  }
                }
              }
            }
          }
        }
      }
      //Logo
      .header-logo {
        .logo-purple {
          fill: #4c35bc !important;
        }
        .logo-pink {
          fill: #fa368b !important;
        }
      }
    }
  }
  &.header-purple {
    border: none;
    background-color: $color-secondary;
    .menu-mob {
      a {
        border-top-color: $color-white;
        border-bottom-color: $color-white;
      }
    }
    &.sticky {
      .menu-mob {
        a {
          border-top-color: $color-white;
          border-bottom-color: $color-white;
        }
      }
    }
  }
  &.header-wishlist {
    position: absolute;
    width: 100%;
    background: #ffffff !important;
    .header-grid {
      grid-template-columns: 100px repeat(7, 1fr 20px) 1fr 100px;
    }
    @media screen and (max-width: 600px) {
      position: fixed;
    }
  }
}

.header-mob {
  display: none;
}

.model {
  h2.text-left {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .header-desk,
  .header-transparent {
    display: none;
  }
  .header-mob {
    display: grid;
    grid-column: 2/9;
    grid-row: 1;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    align-items: center;
  }
  .logo-mob {
    grid-column: 1/2;
    grid-row: 1;
    width: 133px;
    margin: 0.1875rem 0 0 0;
    /*svg {
      display: none;
    }*/
    /*a {
      display: block;
      width: 131px;
      height: 28px;
      background: url(#{$path-img}CuddlyNest-logo.svg);
    }*/
  }
  .menu-btn-mob {
    display: block;
    height: 0.563rem;
    width: 1.25rem;
    border-top: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
  }
  .menu-mob {
    grid-column: 3/4;
  }
  .menu-heading {
    margin-bottom: 0.875rem;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    overflow: hidden;
  }
  .user-email-mobile {
    font-size: 1.375rem;
    line-height: 1.625rem;
    color: $color-dark-grey !important;
  }
  .menu-mob-links {
    ul {
      li {
        list-style: none;
        display: block;
        padding: 0.313rem 0;
        position: relative;
        &.top-currency {
          border-bottom: 1px solid $color-light-gray-input;
          padding-bottom: 0.625rem;
          margin-bottom: 1.1875rem;
        }
        &.currency-menu-mob {
          max-height: 100%;
          overflow-y: auto;
          width: 100%;
        }
        &.devider {
          background-color: #bdbdbd;
          height: 2px;
          width: 100%;
          margin: 0.625rem 0;
          padding: 0;
        }
        &.start-hosting {
          padding-top: 1.25rem;
        }
        &.b-t {
          border-top: 1px solid $color-light-gray;
        }
        &.b-b {
          border-bottom: 1px solid $color-light-gray;
        }
        &.m-t-10 {
          margin-top: 0.625rem;
        }
        &.p-t-15 {
          //padding-top: 0.9375rem;
          padding-top: 0.625rem;
        }
        &.p-b-15 {
          //padding-bottom: 0.9375rem;
          padding-bottom: 0.625rem;
        }
        a {
          text-decoration: none;
          padding: 0.625rem 0;
          display: block;
          @include body-text();
          color: #242526;
          cursor: pointer;
          span {
            display: inline-block;
            width: 2.5rem;
            font-weight: 600;
            color: $color-subtle-gray;
          }
          &.select {
            font-weight: 500;
            //background-image: url(../../assets/img/big-checkmark.svg);
            background-position: center right;
            background-size: contain;
            background-repeat: no-repeat;
            background-size: 15px auto;
          }
          .alert-dot {
            width: 0.4375rem;
            height: 0.4375rem;
            background: $color-primary;
            border-radius: 50%;
            margin: 0 0 0 0 0 0 0.35rem;
            display: inline-block;
            vertical-align: middle;
          }
          em {
            font-style: normal;
            float: right;
          }
          &.color-logout {
            color: #f44a3a;
            padding-bottom: 1.25rem;
            border-bottom: 1px solid $color-light-gray !important;
          }
          svg {
            float: right;
            margin: 0.125rem 0 0 0;
          }
        }
      }
    }
    ul.half-list {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding: 0.625rem 0 0 0;
      margin: 0.625rem 0 0 0;
      border-top: 1px solid $color-light-gray;
      li {
        list-style: none;
        float: left;
        width: 50%;
        padding: 0;
        position: relative;
        a {
          text-decoration: none;
          padding: 0.625rem 0;
          display: block;
          @include body-text();
          color: #242526;
          cursor: pointer;
        }
      }
    }
  }
  .menu-cbox-mob {
    margin-bottom: 3.25rem;
    text-align: right;
  }
  .menu-close-mob {
    margin-bottom: 3.25rem;
    width: 100%;
  }
  .login-social-icons {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 30px 0 70px 0;
    margin: 0.625rem 0 0 0;
    border-top: 1px solid $color-light-gray;
    text-align: center;
    ul {
      list-style: none;
      //display: inline-block;
      vertical-align: top;
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        //margin: 0 15px;
        margin: 0;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        img {
          display: block;
        }
        img.medium {
          width: 31px;
        }
      }
    }
  }
  .model.mobile-menu-currency {
    h2 {
      @include h2-font();
      color: $color-black;
      padding: 0;
      margin: 0 0 3.125rem 0;
    }
    .menu-currency-links {
      padding: 0 0 1.25rem 0;
      ul {
        li {
          &.top-currency {
            border-bottom: 1px solid #e9e9eb;
            padding-bottom: 0.625rem;
            margin-bottom: 1.1875rem;
          }
          ul {
            li {
              @include body-text();
              font-weight: 300;
              color: $color-black;
              margin: 0 0 0.9375rem 0;
              a {
                @include body-text();
                font-weight: 300;
                color: $color-black;
                display: block;
                span {
                  display: inline-block;
                  @include body-text();
                  font-weight: 400;
                  width: 2.5rem;
                  color: $color-subtle-gray;
                }
                &:hover,
                &.select {
                  background: url(#{$path-img}currency-tick.svg) no-repeat;
                  background-position: center right;
                  font-weight: 400;
                  color: $color-black;
                  span {
                    font-weight: 400;
                    color: $color-black;
                  }
                }
              }
            }
          }
        }
      }
    }
    .model-inner {
      .popup-content {
        max-height: calc(100% - 76px) !important;
      }
    }
  }
}

.top-header {
  top: 0;
}

.top-header-homepage {
  top: 70px;
}

.header {
  .menu-mob {
    .hamburguer-menu,
    .hamburguer-menu-inner {
      position: sticky;
      right: 20px;
      width: 24px;
      height: 5px;
      //z-index: 100;
      .hamburguer-menu-lin1,
      .hamburguer-menu-lin2,
      .hamburguer-menu-lin3 {
        transition: all 0.3s ease 0s;
        transform: translate(0px, -50%);
        transform-origin: center center;
        position: absolute;
        top: 50%;
        right: 4px;
        speak: none;
        width: 24px;
        height: 2px;
        background: $color-black;
        border-radius: 3px;
        z-index: 2;
      }
      .hamburguer-menu-lin1 {
        transform: translate(0px, calc(-50% - 4px));
      }
      .hamburguer-menu-lin2 {
        transform: translate(0px, calc(-50% + 4px));
      }
      .hamburguer-menu-lin3 {
        transform: translate(0px, calc(-50% + 12px));
      }
      .h-white {
        position: fixed;
        top: 0;
        right: 0;
        width: 0vw;
        height: 78px;
        background: $color-white;
        transition: width 0.6s ease 0s;
      }
      &.purpleHeader {
        .hamburguer-menu-lin1,
        .hamburguer-menu-lin2,
        .hamburguer-menu-lin3 {
          background: $color-white;
        }
        &.hm-opened {
          .hamburguer-menu-lin1,
          .hamburguer-menu-lin2,
          .hamburguer-menu-lin3 {
            background: $color-black !important;
          }
        }
      }
      /* &.hm-opened{
        .hamburguer-menu-lin1{
          transform: translate(0px, -50%) rotate(45deg);
          width: 25px;
          right: 0px;
        }
        .hamburguer-menu-lin2{
          transform: translate(0px, -50%) rotate(-45deg);
          width: 25px;
          right: 0px;
        }
        .h-white{
          width: 100vw;
        }
      } */
    }
    .hamburguer-menu-inner {
      position: absolute;
      &.hm-opened {
        .hamburguer-menu-lin1 {
          transform: translate(0px, -50%) rotate(45deg);
          width: 25px;
          right: 0px;
        }
        .hamburguer-menu-lin2 {
          transform: translate(0px, -50%) rotate(-45deg);
          width: 25px;
          right: 0px;
        }
        .hamburguer-menu-lin3 {
          display: none;
        }
      }
    }
    .model {
      left: 100vw;
      //top: 78px;
      transition: left 0.6s ease 0s;
      .popup-content {
        margin-top: -4px;
        overflow-x: hidden;
      }
      .model-inner {
        left: 100vw;
        //top: 78px;
        transition: left 0.4s cubic-bezier(0.2, 0.8, 0.4, 1) 0s;
        .login-heading,
        .forgot-heading,
        .register-heading {
          padding-top: 30px;
        }
        //left: 100px;
        padding: 0;
        .model-inner-header {
          position: relative;
          padding: 24px 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .birds {
            position: relative;
            top: 0;
            right: calc(50% + 24px);
            transform: translateX(100%);
          }
        }
        .menu-mob-links {
          margin-top: 5px;
          .user-email-mobile {
            margin-top: 25px;
            margin-bottom: 15px;
            padding: 0;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 20px;
            color: #797882;
            font-weight: 400;
            span {
              position: relative;
              top: 4px;
              margin-right: 15px;
            }
          }
          ul {
            li {
              padding: 15px 0 !important;
              a {
                &.header_list-space {
                  border-top: 2px solid $color-light-gray-input;
                  padding-top: 32px;
                  .header_list-space_icon {
                    width: 2.5rem;
                    display: flex;
                    // path {
                    //     stroke: $color-black;
                    // }
                  }
                  span {
                    width: auto;
                    color: $color-black;
                  }
                }
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #242526;
                display: flex;
                align-items: center;
                padding: 0;
                span {
                  position: relative;
                  top: 2px;
                }
                &.color-logout {
                  border: none !important;
                }
              }
              &.p-b-15 {
                padding: 0;
                margin-top: 45px;
                a {
                  justify-content: space-between;
                  .menu-currency-sec1 {
                    display: flex;
                    align-items: center;
                  }
                  .menu-currency-sec2 {
                    .menu-currency-sec2-grey {
                      margin-top: 3px;
                      font-weight: 400;
                      color: #acabb4;
                    }
                  }
                }
              }
              &.b-b {
                border: none;
              }
            }
          }
        }
        .login-social-icons {
          &.not-connected {
            position: fixed;
            bottom: 0;
            margin-top: 0;
            left: 100vw;
            padding: 30px 0 50px 0;
            transition: left 0.4s cubic-bezier(0.2, 0.8, 0.4, 1) 0s;
          }
          &.connected {
            position: relative;
            margin-top: 15px;
            width: calc(100% + 40px);
            left: -20px !important;
          }
          //width: 100vw;
          ul li:last-child {
            margin-left: 5px;
          }
          ul li a img.pinterest {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .popup-layer {
      display: none;
    }
    .popup-content-wrapper {
      left: 0;
      z-index: 101;
      #popup {
        left: 0;
        .model-inner {
          left: 0;
        }
        .popup-mobile-back {
          left: 0;
        }
        &.login-popup {
          .model-inner {
            .popup-content {
              height: calc(100% - 56px);
            }
          }
        }
        &.register-popup {
          .model-inner {
            .popup-content {
              max-height: calc(100vh - 56px);
            }
          }
        }
      }
    }
  }
  #mob-currency-menu {
    z-index: 101;
    left: 100vw;
    transition: left 0.6s ease 0s;
    .model-inner {
      left: 100vw;
      transition: left 0.6s ease 0s;
      .popup-mobile-back {
        left: 100vw;
        transition: left 0.6s ease 0s;
      }
    }
  }
}
