.banner-text-form {
    display: flex;
    align-items: center;
    .cn__home-bgimage-wrapper{
        .bg__wrapper {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        .cn__home-bgimage{
            width: 100%;
            height: 100%;
            object-fit: fill;
            // object-fit: cover;
            transition: opacity 0.6s ease;
            // filter: brightness(0.8);
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                object-fit: cover;
                // transform: matrix(-1, 0, 0, 1, 0, 0);
                // object-position: 17%;
            }
            @media screen and (min-width: 200px) and (max-width: 600px) {
                object-fit: cover;
            }
        }
    }
    .cn__background-author{
        position: absolute;
        bottom: 24px;
        left: 100px;
        border-radius: 28px;
        font-size: 12px;
        padding: 5px 14px;
        background: rgba(255,255,255,0.761);
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            left: 30px;
        }
        @media screen  and (max-width: 600px) {
            font-size: 10px;
            left: 20px;
        }
        a{
            color: rgba(38,50,56,0.87);
        }
    }
    @media screen and (min-width: 601px) and (max-width: 1136px) {
        min-height: 100vh;
        padding: 0 !important;
    }
    @media screen  and (max-width: 600px) {
        //min-height: 1rem;
        //background: url(#{$path-img}mobile-bg/1.jpg) no-repeat;
        //background: #f7f7f7;
        background-size: cover;
        /* min-height: calc(var(--vh, 1vh) * 100); */
        transition: background-image 0.3s ease;
    }
    @media (orientation:landscape) and (max-height: 450px){
        height: auto;
        padding: 80px 0 !important;
    }
    .banner-inner {
        width: 100%;
        display: grid;

        // margin-top: 7.5rem;

        @media (max-height:800px) and (min-width:600px) {
            //margin:25vh 0 20vh 0;
        }
        @media screen  and (max-width: 600px) {
            margin:auto;
        }
        @media screen and (min-width: 1136px) {
            justify-items: center;
            align-items: top;
        }
        @media screen and (min-width: 1136px) {
            grid-template-columns: 100px repeat(7, 1fr 20px) 1fr 100px;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 30px;
            // width: 302px;
            // margin-left: 70px;
            // margin-top: 30vh;
        }
        @media screen  and (max-width: 600px) {
            grid-template-columns: 20px repeat(3, 1fr 10px) 1fr 20px;
            margin-top: 8vh;

        }
    }
    .banner-text {

        // width: 661px;
        width: 335px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        text-align: center;
        @media screen and (min-width: 300px) and (max-width: 1136px) {
            grid-column: 1/18;
            left: auto;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            width:100%;
            grid-column: 1/18;
        }
        @media screen  and (max-width: 600px) {
            // width: 100%;
            // max-width: 335px;
            grid-column: 1/10;
            width: auto;
        }
        h1 {

            font-family: $secondary-font;
            font-weight: 900;
            font-size: 48px;
            line-height: 52px;

            // font-family: $primary-font;
            // font-weight: 700;
            // font-size: 64px;
            // line-height: 50px;
            text-align: center;
            // letter-spacing: -0.04em;
            // color: $color-white;
            letter-spacing: -0.025em;
            color: $color-black;
            // -webkit-text-stroke: unset;

            @media screen and (min-width: 601px) and (max-width: 1136px) {
                // font-size: 48px;
                // line-height: 52px;
                font-size: 3.25rem;
                line-height: 4.0625rem;
                text-align: center;
            }
            @media screen  and (max-width: 600px) {
                font-size: 34px;
                line-height: 43px;
                letter-spacing: -0.02em;
                margin-bottom: 10px;

                text-align: center;
                // margin-bottom: 31px;
                // font-size: 48px;
                // line-height: 52px;
                // letter-spacing: -0.04em;
                span {
                    color: $color-primary;
                }
            }
            @media screen  and (max-width: 374px) {
                font-size: 1.875rem;
                text-shadow: 0 1px 2px rgba(36, 37, 38, 0.2)
            }
            span {
                color: $color-primary;
            }
        }
        p {
            // font-family: $primary-font;
            font-weight: 500;
            // font-size: 21px;
            // line-height: 25px;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            // letter-spacing: -0.015em;
            letter-spacing: -0.02em;
            // color: rgba(255, 255, 255, 0.8);


            &.desktop {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 601px) {
                    display: none;
                }
                display: block;
            }


            // @media screen and (min-width: 601px) and (max-width: 1136px) {
            //     font-size: 16px;
            //     line-height: 19px;
            // }

            @media screen  and (max-width: 600px) {
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 1.5rem;
                text-align: center;
                color: $color-black;

                margin-bottom: 40px;
                margin-top: 0;
                width: 100%;
                // font-size: 16px;
                // line-height: 19px;
                // text-align: center;
                // color: rgba(255, 255, 255, 0.8);

            }
            .m-span1{
                // opacity: 1;
                opacity: .35;
            }
            .mobile-p{
                // opacity: 1;
                opacity: .7;
                font-weight: 500;
            }
            .m-span2 {
                font-weight: 700;
                min-height: 29px;
                background-position: 0px center;
                display: unset;

                @media screen and (min-width: 1136px) {
                    width: 100%;
                }
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    padding: 0;
                    background-position: center;
                    background-size: 9.9375rem auto;
                }
                @media screen  and (max-width: 600px) {
                    padding: 0;
                    display: block;
                    opacity: 1;
                    background-position: center 0;
                    background-size: 8.5625rem auto;
                }
            }
        }
    }
    .banner-search {
        grid-column: 12/17;
        //width: 511px;
        border-radius: 15px;
        //margin-top: 40px;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            grid-column: 2/17;
            text-align: center;
        }
        @media screen  and (max-width: 600px) {
            //grid-column: 2/9;
            grid-column: unset;
            width: calc(100% - 40px);
        }
        .search-input {

            @media screen and (min-width: 601px) {
                display: none;
            }
            input{
                padding-left: 20px;
            }
        }
        .banner-search-inner {
            //background: $color-subtle-white;
            //border-radius: 0.1875rem;
            position: relative;
            background: $color-light-gray-shade;
            border-radius: 15px;
            float: right;
            width: 100%;
            max-width: 511px;
            //padding: 50px 32px 60px;
            padding: 44px 0 50px;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                max-width: 27.3125rem;
                float: none;
                //padding: 2.5rem 4.1875rem 5.125rem;
                margin: 0 auto;
            }
            @media screen  and (max-width: 600px) {
                display: none;
            }
            .autocomplete-dropdown-container {
                top: 38px;
            }


            .banner-search-titles{
                margin-bottom: 30px;
                .bs-title{
                    font-family: $secondary-font;
                    font-weight: 900;
                    font-size: 32px;
                    line-height: 40px;
                    text-align: center;
                    letter-spacing: -0.025em;
                    color: #242526;
                    -webkit-text-stroke: 1px;
                }
                .bs-subtitle{
                    max-width: 271px;
                    margin: 0 auto;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: #ACABB4;
                    margin-top: 10px;
                }
            }
        }
        h2 {
            font-family: $primary-font;
            font-size: 28px;
            line-height: 26px;
            letter-spacing: -0.015em;
            font-weight: 700;
            text-align: center;
            /* font-size: 1.75rem;
            line-height: 2.1875rem;
            font-weight: bold;
            color: $color-black;
            margin-bottom: 1.6875rem;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 1.375rem;
                line-height: 1.75rem;
                margin-bottom: 1.5625rem;
            } */
        }
        .cn-tabs {
            max-width: 313px;
            margin: 0 auto;
            .DateRangePicker_picker{
                left: -50% !important;
            }
            /* .cn-tabs-nav {
                margin-bottom: 2.3125rem;
                display: flex;
                justify-content: center;
                li {
                    @include body-text();
                    color: $color-black;
                    padding-bottom: 0;
                    padding-top: 0;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                    border-right: 0.0625rem solid $color-light-gray;
                    height: 1.25rem;
                    font-size: 1.0625rem;
                    font-weight: 400;
                    span {
                        display: inline-block;
                        vertical-align: top;
                        padding-bottom: 0.75rem;
                        border-bottom: 0.1875rem solid $color-transparent;
                    }
                    &:first-child {
                        padding-left: 0 ;
                    }
                    &:last-child {
                        padding-right: 0;
                        border-right: none;
                    }
                    &.cn-active {
                        background: transparent;
                        @include body-emphasized();
                        font-size: 1.0625rem;
                        span {
                            border-bottom: 0.1875rem solid $color-primary;
                        }
                        div{
                            width: 20px;
                            height: 3px;
                            border-radius: 20px;
                            background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
                            margin: 0 auto;
                            margin-top: 12px;
                        }
                    }
                }
            } */
            .cn-tabs-content {
                padding: 0;
                background: transparent;
                .cn-input-group,
                .calendar-input {
                    margin-bottom: 1.25rem;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        margin-bottom: 1.5625rem;
                    }
                }
                .search-field{
                    .cn-input-group{
                        input{
                            line-height: 1.125rem;
                        }
                    }
                }
                .cn-input-group{
                    input{
                        border-radius: 6px;
                        //font-size: 1.0625rem;
                        font-size: 16px;
                        line-height: 1.25rem;
                        height: 38px;
                        font-weight: 400;
                        box-shadow: none;
                        @media screen and (min-width: 601px) and (max-width: 1136px) {
                            border-radius: 6px;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            height: 38px;
                        }
                        &:focus {
                            border: 2px solid #4C35BC !important;
                            box-shadow: none;
                            background: $color-white;
                        }
                    }
                }
                .calendar-input-react-date {
                    height: 38px;
                    display: flex;
                    align-items: center;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        height: 38px;
                    }
                    input{
                        &::placeholder{
                            color: $color-dark;
                        }
                    }
                }
                .guests-input-box {
                    height: 38px;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        height: 38px;
                    }
                    margin-bottom: 2.5rem;
                    .cn-input-group{
                        input{
                            &:focus{
                                border: none!important;
                                background: transparent!important;
                            }
                        }
                    }
                }
                .btn-primary {
                    /*display: block;
                    margin-left: auto;
                    margin-right: auto;*/
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 172px;
                    height: 38px;
                    background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%)!important;
                    &:before{
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                        margin-left: -16px;
                        content: '';
                        background: url('/static/home/search.svg');
                    }
                }
                .btn-primary.loader-button{
                    &:before{
                        display: none !important;
                    }
                }
                .btn-primary:disabled{
                    font-weight: 500;
                    background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%)!important;
                    color: #FFFFFF;
                    cursor:pointer !important;
                }
            }
        }
        .autocomplete-loading{
           padding:0.625rem 1.25rem;
        }
    }
}

.placeholder{
    &::placeholder{
        color: $color-black !important;
    }
}