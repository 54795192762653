@import "../../global/mixins/global-mixins.scss";
.model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen  and (max-width: 600px) {
    align-items: flex-start;
    justify-content: left;
  }
  .model-inner {
    position: relative;
    width: 100%;
    max-width: 41.25rem;
    background: $color-subtle-white;
    border: 1px solid $color-white;
    border-radius: 0.125rem;
    padding: 1.875rem 0;
    max-height: 90vh;
    z-index: 1;
    //@include box-shadow();
    @media screen  and (max-width: 600px) {
      background: $color-white;
      max-height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: 3.75rem;
    }
  }
  .close-popup {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 9;
    @media screen  and (max-width: 600px) {
      display: none;
    }
  }
  .popup-mobile-back {
    display: none;
    @media screen  and (max-width: 600px) {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      //height: 5rem;
      text-align: center;
      z-index: 9;
      padding: 1.25rem 1.25rem 1.25rem 1.25rem;
      background: $color-white;
      //@include box-shadow();
      box-shadow: 0px -4px 15px -8px rgba(0,0,0,0.15);
      @include body-text();
      color: $color-black;
      cursor: pointer;
      img {
        float: left;
      }
    }
  }
  h2 {
    margin: 0 0 1.5625rem 0;
    color: $color-black;
    padding: 0 1.875rem;
    text-align: center;
    @media screen  and (max-width: 600px) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
  .popup-content {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 180px);
    padding: 0 1.875rem;
    @media screen  and (max-width: 600px) {
      max-height: 100%;
      //max-height: calc(100% - 50px);
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
}
.popup-layer {
  opacity: 0;
  transition: opacity 0.3s ease;
  background: rgba(0,0,0,0.8);
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  @media screen  and (max-width: 600px) {
    background: transparent;
  }
}
