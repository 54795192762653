// guest
.dashboard-tabHeader {
    display: flex;
    margin:0px -30px;
    border-bottom: solid 1px #EDEDED;
    justify-content: flex-start;
    align-items: flex-end;
    padding:0px 30px;
    position: relative;


    @media (max-width:623px) {
        margin:0px;
    }


    .dashboard-tabHeader-activeTab {
        position: absolute;
        bottom:0px;
        left:30px;
        z-index:1;
        width:123px;
        background-color: #F35597;
        height:39px;
        border-radius: 12px 12px 0px 0px;
    } 

    .dashboard-tabHeader-tab {
        height:39px;
        width:123px;
        line-height: 39px;
        text-align: center;
        background-color: #fff;
        border:solid 1px #EDEDED; 
        border-radius: 12px 12px 0px 0px;
        border-bottom: 0px;
        cursor: pointer;
        position: inherit;
        z-index: 2;

        
        .dashboard-tabHeader-tab-itemLink {
            font-family: $primary-font;
            font-weight: 500;
            font-size: 12px;
            color: #000;
        }   


        &.tabActive {
            background-color: #F35597;
            border-color: #F35597;
            color: #fff;
            .dashboard-tabHeader-tab-itemLink  {
                color: #fff;
            }
        }

    }
}


.dashboardReviews {
    padding:0px 30px 30px 30px;
    height: 100%;
	overflow-y: auto;

    @media (max-width:600px) {
        padding: unset;
    }
    .dashboardReviews-guestreview-ratings{
        display: flex;
        justify-content: space-between;
        min-height: 68px;
        @media (max-width:600px) {
        flex-direction: column;
        }
        .dashboardReviews-guestreview-ratings-titel{
            color: $color-pure-black;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%; 
            letter-spacing: 0.14px;
        }
        .dashboardReviews-guestreview-ratings-numbercontainer{
            align-content: flex-end;
            @media (max-width:600px) {
                display: flex;
                margin-top: 12px;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: center;
            }
            .dashboardReviews-guestreview-ratings-number {
                width: 35px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                border: 1px solid #EBECEF;
                background: #FFF;
                color:  #64748B;
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.14px;
                margin-left: 10px;
            }
            .dashboardReviews-guestreview-ratings-activenumber {
                background: #F7F7F7;
            }
        }
    }
    .dashboardReviews-banner {
        display: flex;
        height: 64px;
        align-items: center;
        gap: 20px;
        align-self: stretch;
        border-radius: 4px;
        background:#F7F7F7;
        margin-top: 10px;
        justify-content: space-around;
        @media (max-width:600px) {
            display: none;
        }
        .dashboardReviews-banner-hoteldetail {
            img {
                border-radius: 5px;
                width: 50px;
                height: 50px;
                margin-right: 5px;
            }
            color: $color-pure-black;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.16px;
            display: flex;
            align-items: center;
        }
        .dashboardReviews-banner-date {
            color: #797882;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            letter-spacing: 0.14px;
        }
        .dashboardReviews-banner-guest {
            color: #797882;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            letter-spacing: 0.14px;
        }
        .dashboardReviews-banner-review {
            border-radius: 10px;
            border: 1px solid  #DFE2E5;
            background: #FFF;
            color: $color-pure-black;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.14px;
            padding: 10px;
        }
    }
    
    .dashboardReviews-guestreview{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
        border-radius: 15px;
        border: 0.5px solid #DFE2E5;
        background:  #FFF;
        margin-top: 20px;
        box-shadow: 0px 5px 30px 0px rgba(231, 228, 228, 0.50);
        @media (max-width:600px) {
            border: unset;
            background: unset;
            margin-top: unset;
            box-shadow: unset;
            padding: unset;
        }
        hr {
            height: 0.5px;
            background: #E9E9EB;
        }
        .dashboardReviews-guestreview-tite{
            color: $color-black;
            font-size: 18px;
            font-weight: 600;
            line-height: 120%;
            .dashboardReviews-guestreview-arrow {
                display: none;
                @media (max-width:600px) {
                    display: inline;
                    margin-right: 16px;
                }
            }
        }
        .dashboardReviews-guestreview_booking {
            display: flex;
            justify-content: space-between;
            .dashboardReviews-guestreview-bookingcode{
                color: $color-pure-black;
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
            }
            .dashboardReviews-guestreview-bookingcodenumber{
                color: $color-black;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin-right: 10px;
                svg {
                    margin-left: 15px;
                    cursor: pointer;
                }
            }
        }
        .dashboardReviews-guestreview-bookingdate {
            color: $color-black;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            justify-content: space-between;
           .dashboardReviews-guestreview-bookingdates{
                display: flex;
                align-items: center;
                gap: 7px;
           }
        .dashboardReviews-guestreview-bookinginfo{
                svg {
                    margin-right: 10px;
                }
            }
        }
        .dashboardReviews-guestreview-user{
            display: flex;
            justify-content: space-between;
            .dashboardReviews-guestreview-primaryreview{
                color: $color-black;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            .dashboardReviews-guestreview-reviewname{
                color: $color-black;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
     .dashboardReviews-guestreview-review{
        color: $color-pure-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        }
        .dashboardReviews-guestreview-noteintro{
            color: #797882;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            letter-spacing: 0.14px;
        }
        .dashboardReviews-guestreview-notes{
            border-radius: 15px;
            border: 0.5px solid #DFE2E5;
            padding: 20px;
            align-items: flex-start;
            gap: 20px;
            background:#F7F7F7;
            font-size: 14px;
            color: $color-pure-black;
        }
    }
    
    .dashboardReviews-generalHeader {
        font-size: 14px;
        color: #64748B;
        font-style: normal;
        padding:0px;
        .simplebar-track.simplebar-horizontal {
            height: 3px;
            bottom: -3px;
        }
        .dashboardReviews-filterNavigation {
            display: flex;
            margin:0px 0px;
            overflow-x: auto;
            flex-wrap:wrap;
            align-items: center;
            
            
            .dashboardReviews-filterNavigation-item {
                margin:0px 10px;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                border:solid 1px #EEEEF0; 
                height: 44px;
                padding:0px 10px;
                line-height: 44px;
                display:flex;
                border-radius: 10px;
                align-items: center;
                background: #fff;
                transition: 100ms ease;
                font-weight: 600;
                cursor: pointer;
                max-width: max-content; 
                margin-bottom: 15px;

                width: 100%;
                height: 100%;
                object-fit: cover;

                &:first-child {
                    margin-left: 0px;
                }
                &.active, &:hover {
                    background: #EEEEF0;
                    color: #64748B;
                }
                svg {
                    margin-right: 10px;
                    width:18px;
                    height:18px;
                    min-width:0px;
                }
            }
        }
    }

    .dashboardReviews-card {
        background-color: #fff;
            padding:20px;
            border-radius: 15px;
            border: 0.5px solid #DFE2E5;
            box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
            @media (max-width:600px) {
                border: unset;
            }
    }

    .dashboardReviews-overviewContainer {
        display:flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin:30px -15px 0px -15px;
        @media (max-width:1160px) {
            flex-direction: column;
            margin:30px -0px 0px -0px;
        }
        .titles {
            color: $color-pure-black;;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 12px;
            line-height: 24px;
        }
    }

    .generalOverviewComponent {
        flex:1;
        min-width: 0px;
        width: 50%;
        margin:0px 15px;
        @media (max-width:1160px) {
            width: 100%;
            margin:0px 0px;
        }
    }

    .dashboardReviews-overviewProfileContainer {
        margin-top: 10px;
        display: flex;
        width:100%;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;

        .dashboardReviews-overviewProfileDetails {
            flex:1;
            h4 {
                color: #000000;
                font-size: 18px;
                font-weight: 400;
                line-height: 21.6px;
            }
            .dashboardReviews-overviewProfileDetail-actions {
                margin-top: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;

               .dashboardReviews-overviewProfileDetail-actionsRating {
                font-weight: 400;
                font-size: 14px;
                color:#ACABB4; 
                display: inline;
                vertical-align: middle;
               } 

               .dashboardReviews-overviewProfileDetail-actionsLink {
                color: #000000;
                font-weight: 600;
                font-size: 10px;
                cursor: pointer;
                svg {
                    width:7px;
                    height:7px;
                    margin-left: 5px;
                }
               }
            }
        }
    }

    .dashboardReviews-overviewProfileDetail-header {
        display: flex;
        justify-content: space-between;
        h3 {
            padding-bottom: 0px !important;
        }
        .dashboardReviews-overviewProfileDetail-headerIcon {
            margin-left: 8px;
            width:10px;
            height: 10px;
        }
    }

    .dashboardReviews-overviewHotelsList {
        .dashboardReviews-overviewHotelsList-container {
            background: #FFFFFF;
            box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
            border-radius: 15px;
            position: relative;
            padding: 3px 6px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 10px 0px;

            .dashboardReviews-overviewHotelsList-status {
                flex: 1;
                text-align: right;
            }
            .dashboardReviews-overviewHotelsList-containerDetails {
                h3 {
                    margin-bottom: 10px;
                    padding-bottom:0px;
                }
                p {
                    color: #797882;
                    font-weight: 400;
                    font-size: 14px;
                    span  {
                        color: #242526;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
            .dashboardReviews-overviewHotelsList-containerImg {
                border-radius: 8px;
                width:100px;
                height:100px;
                overflow: hidden;
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }
    

    .dashboardReviews-overviewContent {
        .dashboardReviews-overviewContent-profileHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @media (max-width:1160px) {
            margin-top:10px;
        }
        h3 {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 12px;
            line-height: 24px;
        }

        h4 {
            color: $color-subtle-grey;
            font-weight: 400;
            font-size: 14px;
        }

        hr {
            border: 0.5px solid #E9E9EB;
            width:100%;
            height:0.5px;
            margin:0px;
        }
        
    }
    
    .dashboardReviews-overviewContent-about {
        background: #FFFFFF;
        border: 0.5px solid #DFE2E5;
        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
        margin-top: 0px;
        padding:15px;
        color: #ACABB4;
        font-weight: 400;
        min-height: 100px;
        font-size: 16px;
        margin-top: 10px;
        word-break: break-word;
    }


    .dashboardReviews-overviewContent-verificationContainer {
        .dashboardReviews-overviewContent-verificationItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actionButton {
                display: none;
            }
            .accountProfile-verification-container {
                color: $color-subtle-grey;
                font-weight: 400;
                font-size: 16px;
            }
            h6 {
                color: #ACABB4;
                font-weight: 400;
                font-size: 16px;
            }
        }
    }


    .dashboardReviews-overviewReviewMessageList {
        .dashboardReviews-overviewReviewMessageItem {
            margin-bottom: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .dashboardReviews-overviewReviewMessageItem-content {
                flex:1;
                h4 {
                    font-weight: 400;
                    font-size: 18px;
                    color: #000000;
                    margin-bottom: 14px;
                }
                p {
                    color: #ACABB4;
                    font-weight: 400;
                    font-size: 14px; 
                    margin-left: 15px;   
                }
            }
            .dashboardReviews-overviewReviewMessageItem-profileImage {
                width:60px;
                height:60px;
                border-radius: 30px;
                overflow: hidden;
                img {
                    width: 100%;
                    height:100%;
                }
            }
        }
    }

    .dashboardReviews-breadcrumb-anchorLink {
        display:inline-block;
       
    }

    .dashboardReviews-breadcrumb {
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media (max-width:623px) {
            padding:10px 16px 25px 16px;
        }

        svg {
            width:12px;
            height:10px;
            margin:0px 5px;
            path {
                stroke: #ACABB4;
            }
        }
        a, p {
            font-size: 12px;
            color:#000;
            font-weight: 600;
        }

        h5 {
            font-size: 18px;
            font-weight: 600;
            color: #000;
        }

        .dashboardReviews-breadcrumb-backArrow {
            width:15px;
            height:15px;
            margin-right: 10px;
            path {
                stroke: #000000;
            }
        }
        
    }

    @media (max-width:623px) {
        padding:0px 6px;
        .dashboardReviews-breadcrumb-anchorLink {
            display: none;
        }
    }

    
}


.reviewRatingForm {
    @media (max-width:623px) {
        background: #FFFFFF;
        border: 0.5px solid #DFE2E5;
        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
        border-radius: 15px;
        margin:20px auto;
        padding:20px 14px;
        width:94%;
        overflow: hidden;
    }
    .reviewRatingForm-submitAction {
        button {
            background-image: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
            border-radius: 30px;
            text-transform: initial;
            color: #F7F7f7;
            font-weight: 600;
            font-size: 16px;
            padding-top: 11px;
            padding-bottom: 11px;
            text-transform: none;
            max-width: 322px;
            width:100%;
            display:block;
            margin:0px auto;
            height:45px;
            border:none; outline: none;
            font-family: "Poppins";

        }
        button:disabled {
            background-color: gray;
            opacity: 0.5;
            background-image: unset;
        }
    }
    .reviewRatingForm-reviewContainer {
        h5 {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
        }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.01em;
            color: #797882;
            margin-top: 20px;
        }
        textarea {
            font-size: 14px;
            font-weight: 400;
            padding:15px;
            background: #F7F7f7; 
            resize: none;
            width:100%;
            border:none;
            border-radius: 10px;
            outline: none;
            margin-top:20px;
        }
        &.reviewRatingForm-ratingContainer-withBorder {
            border-bottom:  0.5px solid #E9E9EB;
            padding-bottom: 20px;
        }

        .reviewRatingForm-ratingContainer-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
           
            p {
                color:#000;
                font-size: 14px;
                font-weight: 400;
            }

            .reviewRatingForm-ratingContainer-radioBoxes {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                @media (max-width:623px) {
                    flex-wrap: wrap;
                }
                .reviewRatingForm-ratingContainer-radio {
                    width:62px; 
                    min-width: 50px;
                    text-align: center;
                    padding: 10px 0px 6px;
                    border-radius: 8px;
                    margin-left: 4px;
                    transition: 300ms ease;
                    flex:1;
                   

                    &.editable {
                        &:hover {
                            background:#EEEEF0; 
                        }
                    }

                    p {
                        margin-top: 3px;
                        font-size:10px;
                        font-weight: 400;
                        color:#797882;
                    }
                    .reviewRatingForm-ratingContainer-radio-icon {
                        width:23.27px;
                        height:23.27px;
                    }

                    &.active {
                        background:#EEEEF0;
                        P {
                            color:#64748B; 
                            font-weight: 600;
                        }
                    }

                }
            }

            @media (max-width:523px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .reviewRatingForm-ratingContainer-radioBoxes {
                    margin-top: 20px;
                    .reviewRatingForm-ratingContainer-radio {
                        margin-left:0px;
                        .reviewRatingForm-ratingContainer-radio-icon {
                            width:23px;
                            height:23px;
                        }
                    }
                }
            }
        }
    }

}

.reviewRatingForm-confirmationModal {
    max-width: 640px;
    width:640px;
    background-color: #DFE2E5;
    

    .reviewRatingForm-confirmationModal-item {
        border-top: solid 1px #EDEDED;
        padding-top:10px;
    }
    .reviewRatingForm-confirmationModal-card {
        padding: 20px;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        background: #FFFFFF;
        border: 0.5px solid #DFE2E5;
        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
        border-radius: 15px;

        p {
            font-size: 16px;
            color:#000000
        }
    }
}

.reviewCards-mobileContainer {
    display: flex;
    flex-direction: row;
    border-radius: 15px;
    background: $color-white;
    box-shadow: 0px 5px 30px 0px rgba(231, 228, 228, 0.50);
    gap: 10px;
    .reviewCard-image {
        img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
    }
    .reviewCard-detail {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .reviewCard-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: $color-pure-black;
            letter-spacing: 0.16px;
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100vw - 180px);
            }
        }
        .reviewCard-checkinTime {
            display: flex;
            gap: 8px;
            font-size: 14px;
            font-weight: 400;
            color: $color-dark-grey;
        }
        .reviewCard-bookingCode {
            font-size: 14px;
            font-weight: 500;
            color: $color-black;
            display: flex;
            gap: 8px;
        }
        .reviewCard-smileyIcon {
            display: flex;
            gap: 15px;
            align-items: center;
            text-transform: capitalize;
        }
    }
}
.reviewCards-container {
    display: flex;
    align-items: flex-start;
    padding: 0px;

    max-width: 100%;
    width:100%;
    height: 197px;

    @media (min-width:622px) { 
        background: #FFFFFF;
        border: 0.5px solid #DFE2E5;
        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
        border-radius: 15px;
        overflow:hidden;
        cursor: pointer;
    }
    
    transition:300ms ease;

    &:hover {
        box-shadow: 0px 0px 30px rgba(231, 228, 228, 0.5);
    }

    .reviewCards-contentContainer {
        max-width:400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex:1;
        min-width: 0px;
        @media (max-width:623px) {
            padding-top:0px;
        }
    }
    .reviewCards-imageContainer {
            position: relative;
            min-width: 225px;
            height: 100%;
            flex:1;
            max-width: 225px;
            overflow: hidden;
            img {
                height: 100%;
                width:100%;
                object-fit: cover;
            }
    }

    .reviewCards-contentContainer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            white-space: nowrap;
            flex: 1;
            margin-right: 10px;
            min-width: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: 600;
            color: #242526;
        }
    }

    .reviewCards-contentContainer-statRating {
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000;
        }
    }
    

    .reviewCards-contentContainer-bookingCode {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
            margin-left:15px;
            font-weight: 400;
            font-size: 16px;
            svg {
                cursor: pointer;
                margin-left:15px;
            }
        }
        h4 {
            font-size: 16px;
            font-weight: 600;
            color: #242526;
        }
    }

    .reviewCards-contentContainer-datesContainer {
        display:flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 0px;
        flex:1;
        .reviewCards-contentContainer-dates {
            display: flex;
            flex-direction: column;
            .reviewCards-contentContainer-dates-checkinCheckout, .reviewCards-contentContainer-time-checkinCheckout {
                &:not(.no-border){border-right:1px solid #acabb4; }
                height:23px;
                padding-right:10px;
                display: flex;  
                align-items: center;
                svg {
                    margin-right:10px;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #000;
                }
            }

            .reviewCards-contentContainer-time-checkinCheckout {
                p {
                    font-size: 10px;
                    font-weight: 600;
                    color:#000000; 
                }
            }
        }
    }


    &.mobile {
        display: none;
    }

    @media (max-width:623px) {
        flex-direction: column;
        height: auto;
        &.desktop {
            display:none; 
        }

        &.mobile {
            display: flex;
        }

        .reviewCards-imageContainer {
            width: 100%;
            max-width: 100%;
        }
    }
   
}

.reviewCards-contentContainer-starRating {
    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }
}



.dashboardReviews.dashboardHostReviews {
    .dashboardHostReviews-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .dashboardHostReviews-filterFlex {
        display: flex;
        align-items: center;
        position: relative;
        border:solid #DFE2E5 1px;
        background-color: #fff;
        border-radius: 12px 12px;
        height:44px;
        padding:5px 10px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        svg {
            margin-right:10px;
        }
    }

    


    .dashboardHostReviews-avgFilter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        padding-bottom: 5px;
        .simplebar-horizontal {
            height:3px;
        }

        .dashboardHostReviews-avgFilter-tabHeader {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            .dashboardHostReviews-avgFilter-tabHeader-item {
                height:41px;
                border:solid 1px #EBECEF;
                margin-right: 15px;
                background-color: #fff;
                border-radius: 12px 12px;
                padding:8px 14px;
                white-space: nowrap;
                color: #64748B;
                font-weight: 600;
                font-size: 14px;
                line-height: 26px;
                cursor: pointer;
                &.active {
                    background-color: #7868C7;
                    color: #fff;
                }
            }
        }

        
        
    }
    
    .dashboard-tabHeader {
        display: flex;
        margin:0px -30px;
        justify-content: flex-start;
        border-bottom: 0px;
        align-items: flex-end;
        padding:0px 30px;
        position: relative;
    
    
        @media (max-width:623px) {
            margin:0px;
        }
        
    
        .dashboard-tabHeader-tab {
            height:50px;
            margin-right: 16px;
            width:123px;
            line-height: 50px;
            text-align: center;
            background-color: #DFE2E5;
            border:solid 1px #DFE2E5; 
            border-radius: 12px 12px;
            cursor: pointer;
            position: inherit;
            z-index: 2;
            
    
            
            .dashboard-tabHeader-tab-itemLink {
                font-family: $primary-font;
                font-weight: 400;
                font-size: 14px;
                color: #000;
            }   
            
    
            &.tabActive {
                background-color: #fff;
                color: #000;
                .dashboard-tabHeader-tab-itemLink  {
                    color: #000;
                }
            }
    
        }
    }

    .dashboardHostReviews-table {
        margin-top: 20px;
        background-color: #fff;
        border:solid 1px #DFE2E5; 
        border-radius: 15px;
        padding:20px;
        box-shadow: 0px 5px 30px 0px #E7E4E480;
        h5 {
            font-weight: 600;
            font-size: 18px;
            color: #000000;
        }

        .dashboardHostReviews-table-title {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: solid 1px #E9E9EB;
        }
        .dashboardHostReviews-table-contentContainer {
            .simplebar-content-wrapper {
                min-height: 0px;
            }
            .simplebar-content-wrapper .simplebar-content {
                display: block;
                flex:auto;
            }
            height: max-content;
            min-height: 0px;
            overflow-x: auto;
            width: 100%;
            .simplebar-track.simplebar-vertical {
                height: 1px;
                bottom: -3px;
            }
            .dashboardHostReviews-table-contentContainer-item {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                flex-direction: row;
                width: 100%;

                .dashboardHostReviews-table-contentContainer-itemAction {
                    min-width: 100px;
                    text-align: right;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    p {
                        white-space: nowrap;
                        flex:1;
                        margin-right: 4px;
                        overflow: hidden;
                        font-weight: 400;
                        font-size: 14px;
                        color: #000;
                    }
                    svg {
                        flex-basis: 15px;
                        min-width: 15px;
                    }
                }

                
                .dashboardHostReviews-table-contentContainer-itemReviewStatus {
                    width: 100px;
                    border-radius: 10px;
                    padding:10px;
                    border: solid 1px #E9E9EB;
                    text-align: center;
                    p {
                        white-space: nowrap;
                        flex:1;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-weight: 400;
                        font-size: 14px;
                        color: #000;
                    }
                }
                .dashboardHostReviews-table-contentContainer-itemContent {
                    flex-basis: 180px;
                    min-width: 180px;
                    display: flex;
                    flex-wrap: nowrap;
                    text-align: center;
                    p {
                        flex:1;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-weight: 400;
                        font-size: 14px;
                        color: #797882;
                    }
                }
                .dashboardHostReviews-table-contentContainer-itemTitle {
                    cursor: pointer;
                    flex-basis: 210px;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    h5 {
                        flex:1;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 16px;
                    }
                    img {
                        max-width: 50px;
                        flex-basis:50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 8px;
                        overflow: hidden;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}


.dashboardHostReviews-dateFilter {
        
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 67px;
    .dashboardHostReviews-dateFilter-icon {
        cursor: pointer;
    }
    .disable_scroll {
        visibility: hidden;
    }
    .simplebar-track.simplebar-horizontal {
        height: 3px;
    }

    .dashboardHostReviews-dateFilter-tabHeader {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        .dashboardHostReviews-dateFilter-tabHeader-item {
            border:solid 1px #EBECEF;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 12px 12px;
            padding:4px 14px;
            color: #000;
            font-weight: 400;
            font-size: 16px;
            min-width: 57px;
            text-align: right;
            transition: 300ms ease;
            cursor: pointer;
            span {
                font-size: 10px;
            }
            &.active,  &:hover  {
                background-color: #7868C7;
                border-color: #7868C7;
                color:#fff;
            }
        }
    }
}