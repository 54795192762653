@mixin mobileMixin {
    &.main_mobile {
        padding-top: 154px;
        // padding-top: 130px;
        max-width: 100vw;
        background-color: $color-white;

        &.expanded-padding {

            // padding-top: 170px;

            .info-banner-bar--mobile, .top-cities {

                margin-top: 50px;

            }

        }

        .info-banner-bar--mobile {
            margin: 20px 0;
            .cn__CTA__bookinfo1 {
                padding: 0 20px 0 5px;
                .cn__CTA__bookinfo1__info {
                    margin: 0;
                }
                .cn__CTA__bookinfo1__icon {
                    transform: scale(calc(50/80));
                }
                .cn__CTA__bookinfo1__info__title {
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 600;
                }
            }
        }

        &.main_mobile_filters {
            .header-wrapper {
                height: 100%;
                overflow: auto;
                transition: .3s ease;
                &.short {
                    height: 100%;
                }
                &.expanded {
                    height: 100%;
                }

                .filters-tab-bar {
                    background-color: $color-white;
                    transition: initial;
                    height: initial;
                    overflow: initial;
                    position: initial;

                    .filters-tab-bar_mobile-head {
                        animation: appear .3s ease-in-out;
                        position: fixed;
                        top: 0;
                        left: 0;
                        background-color: $color-white;
                        padding: 0 20px;
                        width: 100%;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        // justify-content: center;
                        z-index: 2;
                        box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);

                        .filters-tab-bar_mobile-close {
                            flex: 0;
                        }
                        .filters-tab-bar_mobile-exit {
                            background: none;
                            border: none;
                            // position: absolute;
                            outline: none;
                            // right: rem(20);
                            // top: 50%;
                            // transform: translateY(-50%);
                            cursor: pointer;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: rgba(121, 120, 130, 0.1);
                            display: grid;
                            place-content: center;

                            .filters-tab-bar_exit-icon {
                                width: rem(15);
                                height: rem(15);
                                path {
                                    stroke-linecap: round;
                                    stroke: $color-black;
                                }
                            }
                        }

                        .filters-tab-bar_mobile-title {
                            flex: 1;
                            color: $color-black;
                            font-size: 16px;
                            line-height: 150%;
                            font-weight: 600;
                            text-align: center;
                        }

                        .filters-tab-bar_mobile-clear {
                            flex: 0;
                            font-size: 12px;
                            line-height: 150%;
                            font-weight: 400;
                            white-space: pre;
                        }
                    }
                    .filters-tab-bar_extended {
                        margin-top: 50px;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 80px;
                    }
                    .filters-tab-bar_prop-count-container {
                        width: 100%;
                        background: inherit;
                        position: fixed;
                        bottom: 0px;
                        left: 0px;
                        right: 0px;
                    }
                    .filters-tab-bar_prop-count-wrapper {
                        display: flex;
                        padding: 20px;
                        .filters-tab-bar_prop-count {
                            width: 100%;
                            font-family: $primary-font;
                            background: none;
                            border: none;
                            outline: none;
                            background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
                            border-radius: 30px;
                            font-size: 16px;
                            line-height: 150%;
                            font-weight: 400;
                            color: $color-white;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }

        .header-mob {
            animation: appear .3s ease-in-out;

            &.header-transparent {
                background-color: $color-light-gray-shade;
                // transition: .3s ease;
                position: fixed;
                // transform: translateY(0);
                top: 0;
                padding: 12px 20px;
                .logo-mob {
                    width: auto;
                    height: auto;
                    margin: 0;
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .header-wrapper {
            position: fixed;
            top: 0;
            width: 100%;
            // height: 130px;
            height: 154px;
            background-color: $color-light-gray-shade;
            // transition: 0.3s ease;
            // transition: .3s .3s ease-in-out;
            box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);
            border-bottom: 1px solid $color-light-gray-input;
            .MobileHeader_wrapper {
                z-index: 2;
                background-color: $color-light-gray-shade;
            }

            &.short {
                height: 92px;
                .filters-tab-bar {
                    .filters-tab-bar_minimized {
                        top: 0;
                        // transition: .3s ease;
                        .filters-tab-bar_minimized-inner {
                            margin: 20px;
                        }
                    }
                }
                .header-wrapper_container {
                    .MobileHeader_wrapper {
                        top: -80px;
                    }
                    .header-mob {
                        &.header-transparent {
                            top: -56px;
                            // transform: translateY(-55px);
                        }
                    }
                }
            }
            &.expanded {
                height: 200px;
                // height: 176px;
                .filters-tab-bar {
                    .filters-tab-bar_minimized {
                        transform: translateY(-72px);
                        opacity: 0;
                    }
                    .filters-tab-bar_maximized {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
                .header-wrapper_container {
                    .MobileHeader_wrapper {
                        top: 0;
                    }
                    .header-mob {
                        &.header-transparent {
                            top: 0;
                            // transform: translateY(0);
                        }
                    }
                }
            }

            // &.header-wrapper__min {
            //     overflow: hidden;
            //     height: rem(80);
            //     .header-mob {
            //         top: -15%;
            //     }
            //     .filters-tab-bar {
            //         transform: translateY(-58px);
            //         .filters-tab-bar_maximized {
            //             opacity: 0;
            //         }
            //         .filters-tab-bar_minimized {
            //             transform: translateY(0);
            //             opacity: 1;
            //         }
            //     }
            // }


            .filters-tab-bar_minimized {
                display: grid;
                align-items: center;
                color: $color-black;
                top: 56px;
                border: none;
                background: none;
                outline: none;
                font-family: $primary-font;
                font-size: 1rem;
                width: 100%;
                transform: translateY(0);
                opacity: 1;
                // transition: opacity .3s ease;
                // transition: transform .3s ease;
                position: absolute;
                z-index: 1;
                left: 0;

                .filters-tab-bar_minimized-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .filters-tab-bar_minimized-inner {
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 400;
                    padding: 14px 10px 14px 14px;
                    width: 100%;
                    margin: 0 20px 20px 20px;
                    display: grid;
                    grid-template-columns: 30% 0.9375rem 30% 0.9375rem 30%;
                    background-color: $color-white;
                    border: 1px solid $color-field-grey;
                    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
                    border-radius: 34px;

                    & > * {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .filters-tab-bar_minimized-term {
                        display: flex;
                        justify-content: center;
                    }
                    .search-icon__highlighted {
                        margin-right: 10px;
                        height: 21px;
                        display: flex;
                        justify-content: center;
                    }
                    .filters-tab-bar_minimized-separator {
                        justify-self: center;
                        width: rem(1);
                        height: rem(22);
                        background-color: #DADBDB;
                    }
                    .filters-tab-bar_minimized-term,
                    .filters-tab-bar_minimized-dates,
                    .filters-tab-bar_minimized-guests {
                        white-space: nowrap;
                    }

                    .filters-tab-bar_minimized-term-text {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: calc(100vw - 320px);
                        font-weight: bold;
                        font-size: rem(14);
                        font-family: var(--primary-font);
                    }

                    .filters-tab-bar_minimized-dates,
                    .filters-tab-bar_minimized-guests {
                        font-size: rem(12);
                        font-family: var(--primary-font);
                    }
                }
                .filters-tab-bar_minimized-container {
                    display: grid;
                    grid-template-columns: 1fr repeat(2, max-content);
                    align-items: center;
                    column-gap: rem(10);
                    font-weight: 500;
                    font-size: rem(13);
                    line-height: rem(20);
                    letter-spacing: 0.01em;
                    &:first-child {
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    // grid-template-columns: auto rem(15) max-content rem(15) auto;
                    // line-height: rem(40);
                    // background-color: $color-light-gray-input;
                    background-color: $color-field-grey;
                    // border-bottom-left-radius: rem(6);
                    // border-bottom-right-radius: rem(6);
                    border-radius: rem(15);
                    margin: rem(15) rem(20);

                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    width: 100%;
                    padding: rem(13) rem(20);
                    // padding-left: rem(20);
                    // padding-right: rem(20);
                    .filters-tab-bar_minimized-dates__nodates {
                        color: $color-subtle-gray;
                    }
                    // max-width: calc(100vw - 50px);

                    // .filters-tab-bar_minimized-term {
                    //     justify-self: flex-end;
                    //     text-align: right;
                    //     padding-left: rem(10);
                    //     white-space: nowrap;
                    //     overflow: hidden;
                    //     text-overflow: ellipsis;
                    //     width: 100%;
                    //     max-width: rem(170);
                    // }

                    // .filters-tab-bar_minimized-separator {
                    //     justify-self: center;
                    // }

                    // .filters-tab-bar_minimized-guests {
                    //     text-align: left;
                    //     padding-right: rem(10);
                    // }
                }
            }

            .filters-tab-bar {
                padding-left: rem(20);
                padding-right: rem(20);
                // transition: transform .3s .3s ease;
                background-color: inherit;

                .filters-tab-bar_maximized {
                    // animation: appear .3s ease-in-out;
                    display: block;
                }

                .filters-tab-bar_minimized {
                    background-color: var(--color-subtle-white);
                    color: $color-black;
                    top: 80px;
                    // top: 56px;
                    border: none;
                    outline: none;
                    font-family: $primary-font;
                    font-size: 1rem;
                    width: 100%;
                    transform: translateY(0);
                    opacity: 1;
                    // transition: opacity .3s ease;
                    // transition: transform .3s ease;
                    position: absolute;
                    z-index: 1;
                    left: 0;

                    .filters-tab-bar_minimized-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        outline: 0;
                        border: none;
                        background: none;
                        color: var(--color-black);
                        font-family: var(--primary-font);

                        * {
                            color: var(--color-black);
                            font-family: var(--primary-font);
                        }
                    }
                    .filters-tab-bar_minimized-inner {
                        font-size: 14px;
                        line-height: 150%;
                        font-weight: 400;
                        padding: 14px 10px 14px 14px;
                        width: 100%;
                        margin: 0 20px 20px 20px;
                        display: grid;
                        grid-template-columns: auto 0.9375rem auto 0.9375rem auto;
                        background-color: $color-white;
                        border: 1px solid $color-field-grey;
                        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
                        border-radius: 34px;

                        .filters-tab-bar_minimized-term {
                            display: flex;
                            justify-content: center;
                        }
                        .search-icon__highlighted {
                            margin-right: 10px;
                            height: 21px;
                            display: flex;
                            justify-content: center;
                        }
                        .filters-tab-bar_minimized-separator {
                            justify-self: center;
                            width: rem(1);
                            height: rem(22);
                            background-color: #DADBDB;
                        }
                        .filters-tab-bar_minimized-term,
                        .filters-tab-bar_minimized-dates,
                        .filters-tab-bar_minimized-guests {
                            white-space: nowrap;
                        }

                        .filters-tab-bar_minimized-term-text {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: calc(100vw - 320px);
                        }
                    }
                    .filters-tab-bar_minimized-container {
                        display: grid;
                        grid-template-columns: 1fr repeat(2, max-content);
                        align-items: center;
                        column-gap: rem(10);
                        font-weight: 500;
                        font-size: rem(13);
                        line-height: rem(20);
                        letter-spacing: 0.01em;
                        &:first-child {
                            text-align: left;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        // grid-template-columns: auto rem(15) max-content rem(15) auto;
                        // line-height: rem(40);
                        // background-color: $color-light-gray-input;
                        background-color: $color-field-grey;
                        // border-bottom-left-radius: rem(6);
                        // border-bottom-right-radius: rem(6);
                        border-radius: rem(15);
                        margin: rem(15) rem(20);

                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        width: 100%;
                        padding: rem(13) rem(20);
                        // padding-left: rem(20);
                        // padding-right: rem(20);
                        .filters-tab-bar_minimized-dates__nodates {
                            color: $color-subtle-gray;
                        }
                        // max-width: calc(100vw - 50px);

                        // .filters-tab-bar_minimized-term {
                        //     justify-self: flex-end;
                        //     text-align: right;
                        //     padding-left: rem(10);
                        //     white-space: nowrap;
                        //     overflow: hidden;
                        //     text-overflow: ellipsis;
                        //     width: 100%;
                        //     max-width: rem(170);
                        // }

                        // .filters-tab-bar_minimized-separator {
                        //     justify-self: center;
                        // }

                        // .filters-tab-bar_minimized-guests {
                        //     text-align: left;
                        //     padding-right: rem(10);
                        // }
                    }
                }

                .filters-tab-bar_maximized {
                    position: absolute;
                    top: 80px;
                    // top: 56px;
                    left: 0;
                    right: 0;
                    background-color: inherit;
                    opacity: 0;
                    transform: translateY(-98px);
                    transition: all .3s ease;
                    // transition: transform .3s ease;
                    margin: 0 auto;
                    @include tabs;
                    @include filtersTabBarPrimary;

                    .filters-tab-bar_primary {
                        box-shadow: none;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: repeat(2, auto);
                        // gap: rem(10);
                        border-radius: rem(15);
                        margin: 0 20px 20px 20px;
                        .search-field_wrapper {
                            grid-column: 1 / span 2;
                            margin-left: initial;
                            margin-right: initial;
                            border-bottom: rem(1) solid $color-shade-gray;
                            // .search-icon {
                                // left: rem(4);
                                // &.search-icon__highlighted {
                                    // path {
                                        // fill: $color-white;
                                    // }
                                // }
                            // }
                            .search-field.cn-autoComplete {
                                .cn-input-group {
                                    .primary-input.autoCompleteSearch {
                                        border-top-left-radius: rem(15);
                                        border-top-right-radius: rem(15);
                                    }
                                }
                            }
                        }
                        .filters-date-picker {
                            border-radius: initial;
                            border-bottom-left-radius: rem(15);
                            box-shadow: none;
                            background-color: $color-field-grey;
                            border-right: rem(1) solid $color-shade-gray;
                            // &.date-picker__selected {
                            //     box-shadow: none;
                            // }
                            // &.date-picker__active {
                            //     background-color: $color-white;
                            // }
                        }
                        .guest-selector {
                            box-shadow: none;
                            border-radius: initial;
                            border-bottom-right-radius: rem(15);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-right: 0;
                            padding-right: rem(14);
                            &.guest-selector__active {
                                background-color: $color-white;
                            }
                            .guest-selector_track {
                                color: $color-black;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }

                /* .filters-tab-bar_extended {
                    margin-bottom: rem(100);

                    .filters-tab-bar_amenities {
                        .amenities-collection {
                            .amenities-chip {
                                margin-top: rem(10);
                            }
                        }
                    }

                    .drop-down-collection {

                        .drop-down-popover {
                            padding-top: rem(20);
                            padding-bottom: rem(20);
                            font-family: $primary-font;
                            font-weight: 400;
                            color: $color-black;
                            font-size: 1rem;
                            background: none;
                            border: none;
                            outline: none;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            border-bottom: rem(1) solid $color-light-gray-input;

                            .drop-down-popover_track {
                                justify-self: start;

                                .outer__active {
                                    font-weight: 500;

                                    .outer_info-container {
                                        display: flex;
                                        align-items: center;

                                        .outer_info-content {
                                            white-space: nowrap;
                                            overflow: hidden;
                                            max-width: rem(230);
                                            text-overflow: ellipsis;
                                        }

                                        .outer_info-closable-icon {
                                            cursor: pointer;
                                            margin-left: rem(10);

                                            &:hover {
                                                path {
                                                    stroke: $color-black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .drop-down-popover_toggle {
                                .carret-icon {
                                    transform: rotate(-90deg);
                                }
                            }

                            .inner-wrapper-layout {
                                @include innerWrapperLayoutContentMixin;
                            }
                        }
                    }
                } */
                @include extendedFiltersAccordeon;
                .filters-tab-bar_extended {
                    .show-more-less {
                        max-height: calc(100vh - 130px);
                        .show-more-less_wrapper {
                            display: block;
                        }
                        .simplebar-scrollbar::before {
                            width: rem(4);
                            background: $color-subtle-grey;
                        }

                        .show-more-less_action-container {
                            padding-left: rem(20);
                            padding-right: initial;
                        }

                        .neighbourhood-inner_loader {
                            margin-bottom: rem(20);
                            display: grid;
                            grid-template-columns: max-content 1fr;
                            column-gap: rem(15);
                            .neighbourhood-inner_loader-particle {
                                &:last-child {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .drop-down {
                        .drop-down_toggle {
                            padding-top: 13px;
                            padding-bottom: 13px;
                            width: 100%;
                            max-width: unset;
                        }
                        .drop-down_inner {
                            .inner-wrapper-layout {
                                .inner-wrapper-layout_content-area {
                                    .budget-inner {
                                        .price-widget {
                                            .price-widget_slider-wrapper {
                                                padding-left: rem(50);
                                                padding-right: rem(50);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .extra-bar {
            padding-top: 0;
            .extra-bar_right {
                .drop-down-popover {
                    .sort-outer {
                        // flex-direction: row-reverse;
                        letter-spacing: 0.01em;
                        font-weight: 500;
                        column-gap: rem(3);
                        svg {
                            path, rect {
                                fill: $color-black;
                            }
                        }
                    }
                }
            }
        }

        .main_layout {
            .main_center {
                .scrollable-property-container {
                    // padding-top: rem(170); // rem(220);

                    // .scrollable-property-container__loader {
                    //     height: calc(100vh - 290px);
                    // }

                    &.scrollable-property-container__exists {
                        padding-bottom: rem(70);
                    }

                    .searched-listing {
                        padding-left: rem(20);
                        padding-right: rem(20);
                    }

                    .no-properties {
                        margin: rem(40) rem(20);
                    }
                    @include propertyCardMobile;
                    .property-card {
                        .property-card_slider-container {
                            .swiper-pagination-bullets-dynamic{
                                bottom: 27px;
                            }
                            .swiper-button-prev, .swiper-button-next {
                                display: none;
                            }
                        }
                        .property-card_content-wrapper {
                            .property-card_content {
                                display: flex;
                                flex-direction: column;
                                .property-card_type {
                                    order: 3;
                                    // font-weight: 500;
                                    font-size: rem(14);
                                    line-height: rem(17);
                                    // text-transform: uppercase;
                                    margin-top: rem(5);
                                    margin-bottom: 0;
                                    .property-card_additional-headlines {
                                        display: none;
                                    }
                                }
                                .property-card_name {
                                    order: 2;
                                    margin-top: rem(5);
                                    font-weight: 600;
                                }
                                .property-card_distance {
                                    order: 4;
                                    margin-top: rem(7);
                                }
                                .property-card_additional-headlines {
                                    order: 3;
                                    display: flex;
                                }
                            }

                        }
                    }
                    // .property-card {
                    //     // display: block;
                    //     // height: auto;
                    //     .property-card_wishlist-marker {
                    //         height: rem(40);
                    //         width: rem(40);
                    //     }
                    //     grid-template-columns: 100%;
                    //     grid-template-rows: 220px auto;
                    //     background-color: $color-white-background;

                    //     .property-card_content-wrapper {
                    //         padding: rem(10) 0 0 0;
                    //     }

                    //     .property-card_sticker {
                    //         top: rem(24);
                    //         right: rem(15);
                    //         bottom: auto;
                    //         left: auto;
                    //     }

                    //     .property-card_image-slider {

                    //         &.react-loading-skeleton {
                    //             height: 100%;
                    //         }
                    //         height: rem(220);
                    //         border-radius: rem(20);

                    //         img {
                    //             height: rem(220);
                    //             position: relative;
                    //             z-index: -1;
                    //             filter: brightness(.7);
                    //         }
                    //     }
                    //     .property-card_promo-section {
                    //         .property-card_promo-label {
                    //             margin-bottom: rem(10);
                    //         }
                    //     }
                    //     /* .property-card_distance */ .property-card_additional-headlines {
                    //         margin: initial;
                    //         .property-card_additional-headlines_bedrooms,
                    //         .property-card_additional-headlines_bathrooms {
                    //             display: flex;
                    //             align-items: center;
                    //             path, rect, circle {
                    //                 fill: $color-subtle-grey;
                    //                 stroke: $color-subtle-grey;
                    //             }
                    //         }
                    //     }
                    //     .property-card_footer {
                    //         // margin-top: rem(7);
                    //         .property-card_rating, .property-card_price {
                    //             .react-loading-skeleton {
                    //                 width: rem(100);
                    //             }
                    //         }
                    //         .property-card_price {
                    //             grid-template-columns: repeat(3, max-content);
                    //             grid-template-rows: repeat(2, auto);
                    //             column-gap: rem(15);
                    //             align-items: center;
                    //             .property-card_price-original {
                    //                 grid-column: 1 / 2;
                    //                 margin: initial;
                    //             }
                    //             .property-card_price-actual {
                    //                 grid-column: 1 / span 4;
                    //                 &.property-card_price-actual__discounted {
                    //                     grid-column: 2 / 3;
                    //                 }
                    //             }
                    //             .property-card_price-discount {
                    //                 grid-column: 3 / 4;
                    //                 grid-row: 1 / 2;
                    //                 margin: initial;
                    //             }
                    //             .property-card_price-total {
                    //                 grid-column: 1 / span 4;
                    //                 justify-self: flex-start;
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}

//.search-results.search-results_mobile.search-results_mobile_map {
.poi-suggestion-pin-map,
.portal-map-control {
    .map-control.map-pin {
        top: 9%;
    }

    .move-to-bottom {
        transform: translateY(2.75rem) translateZ(1px) !important;
    }
}
//}

.DateRangePicker_picker__portal {
    .DayPicker_transitionContainer {
        box-sizing: content-box;
        padding-bottom: rem(45);
    }

    .DayPickerNavigation_button {
        &.DayPickerNavigation_button__disabled {
            display: none;
        }
    }
}

.search-results {
    &.search-results_mobile {
        .filters-tab-bar {
            .filters-tab-bar_extended {
                .drop-down-collection {
                    .drop-down {
                        .checkbox {
                            .checkbox_label {
                                column-gap: rem(15);
                                .checkbox_label-text {
                                    margin-left: 0;
                                    display: grid;
                                    width: 100%;
                                    grid-template-columns: 1fr auto;
                                    font-size: rem(14);
                                    .checkbox_label-text_count {
                                        margin-left: rem(10);
                                    }
                                }
                            }
                            .checkbox_checkmark{
                                width: rem(18);
                                height: rem(18);
                            }
                        }
                    }
                }
            }
        }
    }
}
