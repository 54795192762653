@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';

.lyp-pricing {
    font-family: $primary-font;
}

.lyp-pricing-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    padding: 20px;

    >section {
        margin-bottom: 50px;
    }
}

.lyp-pricing-section-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    margin-bottom: 20px;
}

.lyp-pricing-section-content {
    .extra-number {
        .extra-prefix {
            padding-left: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #797882;
            pointer-events: none;
        }

        .extra-suffix {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .extra-text-input {
            padding-left: 52px;
        }

        .extra-text-input::-webkit-inner-spin-button,
        .extra-text-input::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }

        >label {
            padding-right: 1.5rem;
        }
    }
}


.lyp-pricing-content__base-price {
    grid-column: 1;
    grid-row: 1;

    .lyp-pricing-section-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    }
}

.lyp-pricing-content__service-fee {
    grid-column: 1;
    grid-row: 2;
    // height: auto;

    .lyp-pricing-section-title {
        margin-bottom: 8px;
    }

    .lyp-pricing-section-content {
        color: var(--color-dark-grey);
    }
}

.lyp-pricing-content__payment-rules {
    grid-column: 1;
    grid-row: 3;
    align-self: flex-end;

    .lyp-pricing-section-content {
        max-width: 400px;
    }
}

.lyp-pricing-content__discounts {
    grid-column: 2;
    grid-row: 1;

    &.lyp-pricing-content__discounts {
        margin-bottom: 60px;
    }

    .lyp-pricing-section-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;

        .discount-item {
            position: relative;

            p {
                padding: 0 8px;
                // margin-top: 10px;
                position: absolute;
                color: var(--color-dark-grey);
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translate(0, calc(100% + 14px));

                font-size: 14px;
                line-height: 120%;
            }
        }

        .extra-number {
            .extra-prefix {
                padding-left: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #797882;
            }

            .extra-text-input {
                padding-left: 40px;
            }
        }
    }
}

.lyp-pricing-content__extra-charges {
    grid-column: 2;
    grid-row: 2/4;

    .lyp-pricing-section-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;

        >div {
            margin-top: 20px;
        }

        .security-deposit {
            grid-column: 1;
            grid-row: 1;

            margin-top: 0;
        }

        .cleaning-fee {
            grid-column: 1;
            grid-row: 2;
        }

        .extra-guest-charges {
            grid-column: 1;
            grid-row: 3;
        }

        .after-guests {
            grid-column: 2;
            grid-row: 3;

            display: flex;
            .extra-number {
                .extra-text-input {
                    padding-left: 1rem;
                }
                // .extra-prefix {
                //     padding-left: 0;
                // }
            }

            >span {
                flex: 0 0 auto;
                padding-right: 6px;
                width: 70px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: var(--color-dark-grey);
            }
        }

        .damage-protection-fee {
            grid-column: 1;
            grid-row: 4;
        }

        .damage-protection-covers {
            grid-column: 2;
            grid-row: 4;

            display: flex;

            >span {
                flex: 0 0 auto;
                padding-right: 6px;
                width: 70px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: var(--color-dark-grey);
            }
        }
    }
}

.lyp-pricing-content__custom-extra-charges {
    grid-column: 2;
    grid-row: 4;

    .lyp-pricing-section-content {
        max-width: 260px;
    }

    .custom-extra-charges {
        grid-column: 1;
        grid-row: 5;

        .custom-charge-wrapper {
            margin-top: 20px;
            position: relative;

            @media screen and (min-width: 1024px) {
                .action-buttons {
                    opacity: 0;
                    transition: opacity 0.2s ease;
    
                    &:hover {
                        opacity: 1;
                    }
                }
    
                &:hover {
                    .action-buttons {
                        opacity: 1;
                    }
                }    
            }
        }
    }

    .custom-extra-charges-btn {
        grid-column: 1;
        grid-row: 6;
        margin-top: 20px;
    }
}

.lyp-custom-charges-modal {
    .content {
        padding: 20px;
        width: 320px;
        max-width: 90vw;

        div.extra {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        .extra-number {
            .extra-prefix {
                padding-left: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #797882;
                pointer-events: none;
            }
            .extra-text-input {
                padding-left: 52px;
            }
        }
    }
    .actions {
    }
}

@media screen and (max-width: 900px) {
    .lyp-pricing-content {
        // display: grid;
        grid-template-columns: 1fr;
        // column-gap: 60px;
        // padding: 20px;

        >section {
            margin-bottom: 50px;
        }

        .lyp-pricing-content__discounts {
            grid-column: 1;
            grid-row: 4;
        }
        .lyp-pricing-content__extra-charges {
            grid-column: 1;
            grid-row: 5;
        }
        .lyp-pricing-content__custom-extra-charges {
            grid-column: 1;
            grid-row: 6;
        }
    }
}
