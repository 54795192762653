//------------------- DESKTOP -------------------

.cn__card9{
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0,0,0);

    &__img{
        position: relative;
        width: 100%;
        height: 13.195vw;
        max-height: 190px;

        .cn__image-default{
            filter: brightness(0.7);
            transition: all 0.35s ease;
        }

        &__info{
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: 0 15px 15px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            &__sec1{
                display: flex;
                &__grs{
                    padding: 10px;
                    background: #ffffff;
                    border-radius: 10px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #242526;
                    margin-right: 10px;
                }
                &__rateinfo{
                    &__status{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                    &__rates{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &__info{
        background: #ffffff;
        padding: 15px 20px 20px;
        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #242526;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        &__rating{
            margin-top: 5px;
        }
        &__type{
            margin-top: 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
            color: #ACABB4;
        }
        &__distance{
            margin-top: 5px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #ACABB4;
        }
        &__price{
            margin-top: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #242526;
            text-align: right;
            span{
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
            }
        }
    }

    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 30px 20px -20px rgba(87,75,144, 0.15);
    }

}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){

    .cn__card9{
        &__img{
            height: 190px;
        }
    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){

    .cn__card9{
        width: 162px!important;
        &__img{
            height: 109px;

            &__info{
                padding: 0 10px 10px;

                &__sec2{
                    display: none;
                }
            }
        }
        &__info{
            padding: 10px 10px 15px;
            &__title{
                font-size: 14px;
                line-height: 17px;
            }
            &__rating{
                display: none;
            }
            &__type{
                margin-top: 5px;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                text-transform: uppercase;
                color: #ACABB4;
            }
            &__price{
                font-size: 14px;
                text-align: left;
                span{
                    font-size: 14px;
                }
            }
        }
    }

}