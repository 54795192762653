@import '../../global/mixins/global-mixins.scss';
.card-slider-section {
    width: 100%;
    position: relative;
    grid-column: 1/18 !important;
    @media screen and (min-width: 601px) and (max-width: 1136px) {
        grid-column: 1/18 !important;
        .slick-slider{
            margin-left: 30px;
        }
    }
    @media screen  and (max-width: 600px) {
        grid-column: 1/18 !important;
        .slick-slider{
            margin-left: 20px;
        }
    }
    .heading-section {
        margin: 0 0 0 24.5%;
        //max-width: 47.7%;
        max-width: 610px;
        text-align: center;
        //padding: 0 5.125rem;  
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 0 0 12.3%;
            max-width: 71.5%;
            text-align: center;
            padding: 0 0;
        }
        @media screen  and (max-width: 600px) {
            margin: 0;
            padding: 0 1.25rem;
            max-width: 100%;
            text-align: center;
            //padding: 0 1.25rem 0 0;
        }
        h2 {
            font-family: $secondary-font;
            /* font-size: 3rem;
            line-height: 100%;
            font-weight: bold; */

            font-size: 42px;
            line-height: 115%;
            letter-spacing: -0.02em;
            font-weight: 400;

            color: $color-black;
            margin: 0 0 1.25rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 1.5625rem;
            }
            br {
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                //color: $color-primary;
                font-weight: 900;
                -webkit-text-stroke: 1px;
            }

            b {
                font-weight: 400;
            } 
            &.desktop{
                @media screen  and (max-width: 1146px) {
                    display: none;
                }
            }
            &.mobile{
                @media screen and (min-width: 601px) and (max-width: 1146px) {
                    display: block;
                    margin-bottom: 1.25rem;
                }
                @media screen  and (max-width: 600px) {
                    display: block;
                    margin-bottom: 0;
                }
            }
        }
        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $color-gray-font;
            margin: 0 0 2.5rem 0; 
            @media screen  and (max-width: 1146px) {
                margin:  0;
            }
        }
    }
    .card-img-text {
        transition: margin ease 0.1s;
        //border-radius: 0.625rem;
        border-radius: 20px;
        background: $color-white;
        //@include box-shadow-light();
        //box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
        box-shadow: 0px 2px 2px rgba(87, 75, 144, 0.1);
        transition: all .35s ease;
        width: 100%;
        cursor: pointer;
        @media screen  and (max-width: 600px) {
            //width: 20.938rem;
        }
        overflow: hidden;
        margin: 0 auto 0.625rem;
        
        .card-img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 14.532vw;
            max-height: 209px;
            overflow: hidden;
            
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                height: 205px;
            }
            @media screen  and (max-width: 600px) {
                //height: 25.0625rem;
                height: 106px;
            } 
            a {
                display: block;
                width: 100%;
                height: 100%;
                span{
                    width: 100%;
                    height: 100%;
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    //filter: grayscale(0);
                    @media screen  and (max-width: 1136px) {
                        //filter: grayscale(0);
                    }
                }
            }
            .slider_rest_info{
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            .width-100{
                height: inherit;
                max-height: inherit;
                .lazy-load-image-background{
                    height: 100%;
                    width: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .card-text {
            display: inline-block;
            vertical-align: top;
            width: 100%; 
            //padding: 0.625rem 1.875rem 0.8125rem 1.5625rem;
            padding: 10px 25px 20px 25px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            
            @media screen  and (max-width: 600px) {
                //padding: 0.5rem 1.875rem 0.6875rem 0.9375rem;
                padding: 10px 10px 15px 10px;
            }
            transition: transform .5s ease;
            h4 {
                color: $color-black;
                height: 1.9375rem;
                //overflow: hidden;
                margin: 0 0 0.3125rem 0;
                display: flex;
                width: 100%;
                @media screen  and (max-width: 600px) {
                    margin: 0;
                }
                a {
                    font-family: $secondary-font;
                    /* font-size: 1.625rem;
                    line-height: 1.9375rem;
                    font-weight: 500; */
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 25px;
                    -webkit-text-stroke: 0.3px;
                    /* font-variant: small-caps;
                    text-transform: lowercase; */
                    color: $color-black;
                    width: 100%;
                    /*display: inline-block;
                    vertical-align: top;*/
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-decoration: none;
                    @media screen  and (max-width: 600px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    p{
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    img {
                        float: right;
                        margin-top: 0.4375rem;
                    }
                }

                .arrow {
                    float: right;
                    //margin-top: 0.4375rem;
                    margin-top: 4px;
                    width: 14px;
                    position: relative;
                    transition: transform .5s ease;
                    @media screen  and (max-width: 600px) {
                        //margin-top: 11px;
                    }
                }
            }
            .card-text-bottom {
                @include small-text();
                color: $color-dark;
                display: block;
                vertical-align: top;
                width: 100%;
                margin: 0;
            }
            span{
                // font-variant-caps: all-small-caps;
                /* font-size: 1rem;
                line-height: 1.1875rem; */
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                color:#797882;
                //margin-top: 5px;
            }
        }
        /*&:hover {
            background: $color-subtle-white;
        }*/
        @media screen and (min-width: 601px) {
            &:hover {
                box-shadow: 0px 30px 20px -20px rgba(87, 75, 144, 0.15);
                transform: translateY(-5px);
                .arrow {
                    transform: translateX(5px);
                }
            }
        }
    }

    .slick-arrow{
        background-color: transparent;
        transition:  0.3s ease;
        transform: translate(0);
        transform-origin: center center;
        border: 3px solid #797882;
        &.slick-prev{
            position: absolute;
            //top: -48px;
            top: 100%;
            right: 155px;
            display: inline-flex;
            left: auto;
            svg{
                height: 14px;
                margin-left: -4px;
                transition: all 0.3s ease;
                path{
                    stroke: #797882;
                    stroke-width: 4px;
                }
            }
        }
        &.slick-next{
            position: absolute;
            //top: -48px;
            top: 100%;
            right: 100px;
            display: inline-flex;
            svg{
                height: 14px;
                margin-left: 4px;
                transition: all 0.3s ease;
                stroke-width: 2;
                path{
                    stroke: #797882;
                    stroke-width: 4px;
                }
            }
        }
        /* &:hover{
            transform: scale(0.9);
            svg{
                transform: scale(1);
            }
        } */
        @media screen  and (max-width: 1136px) {
            display: none !important;
        }
    }
    .section-slider{
        .slick-slide{
            //width: 315px !important;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                width: 315px !important;
            }
            @media screen  and (max-width: 600px) {
                width: 172px !important;
            }
            /* &.slick-current{
                margin-left: 20px;
            } */
        }
        .slick-slider{
            .slick-track{
                @media screen  and (max-width: 600px) {
                    //padding-top: 40px;
                    padding-top: 25px;
                }
            }
        }
    }
}


