.listings-booking-summary {
    &.booking-summary {

        background: none;
        padding: 0;

        .booking-form {

            .n-input-date-range-picker,
            .n-input-guest-room-picker {
                display: grid;
                min-height: 48px;
                // place-content: center;
                border-radius: initial;
                border: none;

                .input-value {
                    min-height: inherit;
                    padding: 0 20px 0 20px;
                    align-items: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    * {
                        font-size: 13px;
                        letter-spacing: 0.02em;
                    }

                    @media (min-width: 700px) {
                        * {
                            font-size: 1rem;
                            letter-spacing: initial;
                        }
                    }

                    .ic-icon {
                        svg {
                            path {
                                stroke: var(--color-subtle-grey);
                            }

                            rect {
                                fill: var(--color-subtle-grey);
                            }
                        }
                    }
                }
            }

            .n-input-date-range-picker {
                .input-dropdown {
                    // top: unset;
                    // right: unset;
                    // bottom: unset;
                    // left: unset;
                }
            }
        }

        &.booking-summary--tablet {
            padding-bottom: 20px;

            &.listings-booking-summary_hotel {
                padding-bottom: 0;
                margin: auto;
            }
            // Rehan and Jones motherfuckers
            // @media screen and (min-width: 601px) and (max-width: 780px) {
            //     .booking-form {
            //         .n-input-date-range-picker {
            //             .input-dropdown {
            //                 .DayPicker {
            //                     width: 500px !important;
            //                     & > div > div {
            //                         width: 500px !important;
            //                     }
            //                 }
            //                 .DayPicker_transitionContainer {
            //                     width: 500px !important;
            //                 }
            //                 .CalendarDay {
            //                     padding: 8px 6px;
            //                     width: 30px !important;
            //                 }
            //                 .DayPicker_weekHeader {
            //                     left: 0px !important;
            //                     &:last-child {
            //                         left: 240px !important;
            //                     }
            //                 }
            //                 .DayPicker_weekHeader_li {
            //                     width: 30px !important;
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        &.booking-summary--desktop {
            .booking-form {

                .n-input-date-range-picker,
                .n-input-guest-room-picker {
                    min-height: 54px;

                    // &.active {
                    //     border-radius: 16px;
                    //     border: 1px solid var(--color-field-grey);
                    // }

                    .input-value {
                        padding-left: 0;

                        .ic-icon {
                            svg {
                                path {
                                    stroke: var(--color-dark-grey);
                                }

                                rect {
                                    fill: var(--color-dark-grey);
                                }
                            }
                        }
                    }
                }

                .n-input-date-range-picker {
                    .input-dropdown {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

.booking-summary {

    .input-value.input-value.input-value.input-value {
        padding: 0;
    }

    &.booking-summary--mobile,
    &.booking-summary--tablet {
        margin-top: 30px;
        .booking-form {
            .booking-form_picker-container {
                .n-input-date-range-picker {
                    .input-value {
                        justify-content: center;
                    }
                }
            }
            .booking-form_picker-container {
                .n-input-guest-room-picker {
                    .input-value {
                        justify-content: center;
                    }
                }
            }
        }

        .booking-form {
            grid-template-columns: 1fr 1px 1fr;

            @media screen  and (max-width: 600px) {
                border: 1px solid #DFE2E5;
                box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
                border-radius: 34px;

                .booking-form_separator{
                    background-color: #DADBDB !important;
                }
            }

            .booking-form_separator {
                height: calc(100% - 18px);
                background-color: #DADBDB;
                // background-color: var(--color-white);
                margin-top: auto;
                margin-bottom: auto;
            }

        }
    }

    &.booking-summary--mobile {
        .booking-details {
            column-gap: 10px;
            margin-bottom: 10px;
            @media screen  and (max-width: 384px) {
                display: flex;
                place-content: center;
                grid-column-gap: 10px;
            }
        }
        .safety-message {
            margin-top: 30px;
            margin-bottom: 25px;
        }
    }

    &.booking-summary--tablet {
        margin-top: 0;
        max-width: 500px;

        .booking-details {
            .booking-details_text {
                @media (min-width: 700px) {
                    font-size: 1rem;
                    letter-spacing: initial;
                }
            }
        }
    }

    &.booking-summary--desktop {
        .booking-form {
            display: block;

            .listings-guest-room-input {
                min-height: 54px;
                margin-left: 7px;
                margin-right: 7px;
                padding-left: 14px;
                border-top: 0.5px solid #DFE2E5;
                // border-top: 1px solid #DFE2E5; //var(--color-white);
                width: 100%;
                justify-content: flex-start;
            }
        }

        .booking-details {
            //grid-template-columns: max-content 1px max-content;
            grid-template-columns: minmax(0,1fr) 1px minmax(0,1fr);
            column-gap: 10px;
            overflow: hidden;

            .booking-details_text {
                font-size: rem(15);
                justify-content: flex-start;
            }
        }

        .listings-guest-room-input_icon {
            transform: translateY(-1px);
        }
    }

    .listings-guest-room-input_icon {
        path {
            fill: var(--color-black);
        }
    }

    .booking-form {
        display: grid;
        //background-color: var(--color-shade-gray);
        border-radius: 16px;
        border: 0.5px solid #DFE2E5;
    }

    .listings-date-range-input {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        // column-gap: 5px;
        width: 100%;

        .listings-date-range-input_separator {
            //background-color: var(--color-white);
            background: #DFE2E5;
            // height: 48px;
            height: 34px;
            margin-bottom: 10px;
            align-self: flex-end;
        }

        .listings-date-range-input_date-container {
            position: relative;
            display: grid;
            grid-template-columns: auto 1fr 24px;
            column-gap: 5px;
            white-space: nowrap;
            padding: 0 12px;
            align-items: center;
            min-height: 54px;
            border-radius: 16px;
            &.active {
                // padding: 0 11px;
                // border: 1px solid var(--color-field-grey);
                // &::before {
                //     content: "";
                //     position: absolute;
                //     width: calc(100% - 2px);
                //     height: calc(100% - 2px);
                //     border-radius: 16px;
                //     // border: 1px solid var(--color-field-grey);
                // }
            }
            &.placeholder {
                color: var(--color-dark-grey);
                path {
                    fill: var(--color-dark-grey);
                }
            }
            &.disabled {}
            &.disabled:hover {
                cursor: default;
            }
            .listings-date-range-input_icon {
                width: 30px;
                height: 30px;
            }

            .listings-date-range-input_date-container_clear {
                animation: appear .3s ease-in-out forwards;
                opacity: 0;
                border: none;
                outline: none;
                display: none;
                // display: flex;
                justify-content: center;
                align-items: center;
                // right: rem(13);
                cursor: pointer;
                background-color: #E9E9EB;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                // width: rem(24);
                // height: rem(24);
                &.active {
                    display: flex;
                }
                .listings-date-range-input_date-container_clear_icon {
                    width: 10px;
                    height: 10px;
                    // width: rem(10);
                    // height: rem(10);
                    path {
                        stroke: #797882;
                        stroke-width: 2px;
                    }
                }
            }
        }
    }

    .listings-guest-room-input {
        display: grid;
        grid-template-columns: max-content 1fr;
        column-gap: 3px;
        white-space: nowrap;
        place-content: center;
        align-items: center;
        .listings-guest-room-input_text {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .booking-details {
        margin-top: 14px;
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        align-items: center;
        column-gap: 5px;
        @media screen  and (max-width: 384px) {
            text-transform: capitalize;
        }
        .booking-details_text {
            /* display: flex;
            align-items: center;
            justify-content: center; */
            float: left;
            font-size: 13px;
            letter-spacing: 0.01em;
            color: var(--color-dark-grey);
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            @media screen  and (max-width: 384px) {
                    display: flex;
                    gap: 10px;
                    width: unset;
                    svg {
                        top: 3px;
                        position: relative;
                    }
                }
            @media screen and (min-width: 601px) and (max-width: 1100px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .ic-icon {
                margin-right: 8px;
                width: 21px;
                height: 21px;
                flex-shrink: 0;
                float: left;

                svg {
                    width: inherit;
                    height: inherit;
                }
            }

            div {
                position: relative;
                top: 2px;
                @media screen  and (max-width: 384px) {
                    text-transform: capitalize;
                    top: unset;
                }
            }
        }

        .booking-details_separator {
            background-color: var(--color-subtle-grey);
            height: 14px;
            display: grid;
            place-content: center;
            @media screen  and (max-width: 384px) {
               width: 0.5px;
            }
        }
    }
}

.safety-message {
    background-color: #E9F5FA;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 25px;
    display: block;
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    grid-column-gap: 10px;
    grid-row-gap: 5px;

    strong {
        font-family: var(--primary-font);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-dark-grey);
        align-self: center;
    }

    p {
        font-family: var(--primary-font);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-dark-grey);
        grid-area: 2 / 2;
    }

    .ic-icon {
        svg {
            path {
                stroke: var(--color-dark-grey);

                &:last-child {
                    fill: var(--color-dark-grey);
                }
            }
        }
    }
}

.n-input-date-range-picker {
    .n-modal__dialog {
        display: block;
    }
    .n-modal__header.n-modal__header.n-modal__header {
        height: 92px;
        max-height: 92px;
        .n-modal__close {
            top: 34px;
            right: 30px;
        }
        .LDP-mobile-calendar-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            .LDP-mobile-calendar-header_title {
                font-size: 16px;
                line-height: 125%;
                font-weight: 600;
                color: var(--color-black);
            }
            .LDP-mobile-calendar-header_dates {
                color: var(--color-subtle-grey);
                line-height: 0;
                opacity: 0;
                transition: .3s ease-in-out;
                font-size: 13px;
                letter-spacing: 0.02em;
                overflow: hidden;
                .LDP-mobile-calendar-header_dates_startdate,
                .LDP-mobile-calendar-header_dates_enddate {
                    font-weight: 600;
                }
            }
            .LDP-mobile-calendar-header_dates--active {
                line-height: 20px;
                opacity: 1;
            }
        }
    }
    .n-modal__content.n-modal__content.n-modal__content {
        height: calc(100% - 92px);
        max-height: calc(100% - 92px);
        overflow-y: auto;
        .DayPicker_weekHeader {
            border-bottom: 1px solid #dbdbdb;
            .DayPicker_weekHeader_ul {
                max-width: 342px;
                padding: 0 13px;
                margin: 0 auto;
            }
        }
    }
}