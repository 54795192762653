//------------------- DESKTOP -------------------

.cn__thanksgiving{
    position: absolute;
    width: 100%;
    height: 100%;

    .header{
        .max-width-wrapper{
            max-width: unset;
            width: calc(100% - 200px);
        }
        .logo-pink, .logo-purple {
            fill: #FFFFFF !important;
        }
        .li--blog {
            display: none !important;
        }
        .hamburguer-menu-lin1, .hamburguer-menu-lin2{
            background: #FFFFFF !important;
        }
        &.header_menu--active{
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #242526 !important;
            }
        }
        .header-nav ul li a{
            color:#FFFFFF;
            svg{
                .rect1,.blog1,.blog2,.listsvg, .svghouse, circle:not(.logged-circle){
                    stroke:#FFFFFF
                }
                .rect2,path:not(.listsvg,.svghouse,.menu-first){
                    fill: #FFFFFF;
                }
            }
        }
    }

    &__hero{
        position: relative;
        width: 100%;
        height: 100%;

        .cn__image-picture{
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &__top{
            position: relative;
            width: calc(100% - 300px);
            margin: 0 auto;
            top: 45%;
            transform: translateY(-50%);
            text-align: center;
            color: #FFFFFF;
            z-index: 2;

            &__title{
                font-weight: 800;
                font-size: 42px;
                line-height: 46px;
                letter-spacing: -0.02em;
            }

            &__subtitle{
                font-size: 27px;
                line-height:35px;
                margin-top: 24px;

                span{
                    position: relative;
                    font-weight: 700;
                    color: #fbff38;

                    &::after{
                        position: absolute;
                        width: 100%;
                        height: 6px;
                        content: '';
                        background: url('/static/deals/thanksgiving/line.svg') no-repeat;
                        background-size: 100%;
                        bottom: -8px;
                        left: 0;
                    }
                }
            }
        }

        &__bottom{
            position: absolute;
            width: 100%;
            bottom: 50px;
        }
    }

    &__offer1 {
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;

        .cn__slider{
            margin-top: 25px;
        }

        &__cards{
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4,minmax(0,1fr));
            grid-gap: 20px;
            margin-top: 40px;
        }
    }

    &__subscribe{
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;
    }

    .cn__faq{
        width: calc(100% - 300px);
        margin: 100px auto;
    }

    &__breadcrumbs{
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;
        display: flex;

        a{
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            text-decoration: none;
            color: #797882;
        }
    }

    .cn__shortfooter__wrapper{
        .cn__shortfooter{
            padding: 0 150px 30px;
            max-width: unset;
        }
    }
    //Cookie banner
    .cn__cookie-wrapper{
        left: 50%;
        margin-left: -570px;
        bottom: 16px;
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }

}
.cn__thanksgiving__banner{
    position: relative;
    width: 100%;
    height: 68vh;
    max-height: 690px;
    border-radius: 20px;
    overflow: hidden;

    .cn__image-picture{
        transform: matrix(-1, 0, 0, 1, 0, 0);

        .cn__image-default{
            object-position: 0 72%;
            filter: brightness(0.7);
        }
    }

    &__info{
        position: absolute;
        width: 100%;
        padding: 0 50px;
        bottom: 56px;
        color: #FFFFFF;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &__info{
            &__title{
                font-weight: bold;
                font-size: 53px;
                line-height: 40px;
                letter-spacing: -0.025em;
            }

            &__subtitle{
                font-size: 27px;
                line-height: 1.7;
                margin-top: 18px;

                span{
                    position: relative;
                    font-weight: 700;
                    color: #fbff38;

                    &::after{
                        position: absolute;
                        width: 100%;
                        height: 6px;
                        content: '';
                        background: url('/static/deals/thanksgiving/line.svg') no-repeat;
                        background-size: 100%;
                        bottom: -8px;
                        left: 0;
                    }
                }
            }
        }

        a{
            flex-shrink: 0;
        }
        button{
            position: relative;
            font-family: $primary-font;
            flex-shrink: 0;
            width: auto;
            height: auto;
            padding: 10px 20px 8px 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            background: linear-gradient(92.14deg,#f35597 1.79%,#f53959 99.81%);
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            margin-left: 30px;
            p{
                position: relative;
                top: -1px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__thanksgiving{

        .header{
            .max-width-wrapper{
                width: 100%;
            }
        }

        &__hero{
            &__top{
                width: 100%;
                padding: 0 30px;

                &__subtitle{
                    line-height:45px;
                }

            }
        }

        &__offer1 {
            position: relative;
            width: 100%;
            margin: 100px auto;

            .cn__title, .cn__subtitle{
                padding: 0 30px;
            }

            .cn__slider{
                margin-top: 25px;
            }
        }

        .cn__faq{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        &__subscribe{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        &__breadcrumbs{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        .cn__shortfooter__wrapper{
            .cn__shortfooter{
                padding: 0 30px 30px;
            }
        }

    }
    .cn__thanksgiving__banner{
        height: 37vh;
        max-height: 690px;
        background: #a7a4a4;

        .cn__image-picture{
            .cn__image-default{
                object-position: 0 72%;
            }
        }

        &__info{
            padding: 0 30px;
            bottom: 30px;

            &__info{
                &__title{
                    font-size: 36px;
                    line-height: 1;
                }

                &__subtitle{
                    font-size: 16px;
                    line-height: 2;
                    margin-top: 6px;
                }
            }

            button{
                font-size: 12px;
                line-height: 1;
                p{
                    top: 0;
                }
                svg{
                    height: 16px;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__thanksgiving{

        &__hero{

            .cn__image-picture{
                transform: none;
                .cn__image-default{
                    object-position: 21%;
                }
            }

            &__top{
                top: 30%;
                padding: 0 20px;

                &__title{
                    font-weight: 800;
                    font-size: 8vw;
                    line-height: 1.2;
                    padding: 0 6vw;
                }

                &__subtitle{
                    font-size: 4vw;
                    line-height: 1.2;
                    margin-top: 2vh;

                    span{
                        &::after{
                            display: none;
                        }
                    }
                }

            }

            &__bottom{
                bottom: 9vh;
            }

        }

        &__offer1 {
            margin: 60px auto;

            .cn__title, .cn__subtitle{
                padding: 0 20px;
            }

            .cn__slider{
                .cn__slider__cards .cn__card{
                    width: calc(100vw - 105px);
                }
            }
        }

        .cn__faq{
            width: calc(100% - 40px);
            margin: 60px auto;
        }

        &__subscribe{
            width: calc(100% - 40px);
            margin: 60px auto;
        }

        &__breadcrumbs{
            width: calc(100% - 40px);
            margin: 60px auto;

            a{
                font-size: 14px;
            }
        }

        .cn__shortfooter__wrapper{
            .cn__shortfooter{
                padding: 0 20px 30px;
            }
        }

    }
    .cn__thanksgiving__banner{
        height: 37vh;

        &__info{
            padding: 0 20px;
            bottom: 20px;

            &__info{
                &__title{
                    font-size: 23px;
                    line-height: 1;
                }

                &__subtitle{
                    font-size: 15px;
                    line-height: 1.2;
                    width: 90%;

                    span{
                        &::after{
                            display: none;
                        }
                    }
                }
            }

            button{
                display: none;
            }
        }
    }
}