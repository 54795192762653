//-------------- DEKSTOP --------------

.cn__gp__slider__staystypes{
    position: relative;
    width: 100%;
    margin: 80px auto;
    &__title{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #000000;
    }
    &__aslider{
        position: relative;
        margin-top: 15px;

    }
}

//-------------- TABLET --------------

@media screen and (max-width:1136px){
    .cn__gp__slider__staystypes{
        &__title{
            padding: 0 30px;
        }
        &__aslider{
            .simplebar-track{
                display: none !important;
            }
        }
    }  
}

//-------------- MOBILE --------------

@media screen and (max-width:600px){
    .cn__gp__slider__staystypes{
        margin: 60px auto;
        &__title{
            padding: 0 20px;
        }
        &__aslider{
            .ss__slider__wrapper{
                scroll-padding: 0 0 0 20px;
            }
        }
    }
}