.cn__gp__slider__breakfast{
    position: relative;
    width: 100%;
    margin: 80px auto;
    --min-height1: 'auto';
    --min-height2: 'auto';

    
    &__title{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #000000;
    }
    &__slider{
        position: relative;
        width: 100%;
        margin-top: 15px;
    }
}

@media screen and (max-width:1136px){
    .cn__gp__slider__breakfast{
        margin: 60px auto;

        &__title{
            padding: 0 30px;
        }
        .simplebar-track{
            display: none !important;
        }
    }  
}

@media screen and (max-width:600px){
    .cn__gp__slider__breakfast{
        margin: 50px auto 0;
        &__title{
            padding: 0 20px;
        }
    }
}