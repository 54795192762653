.sidenav-account-container {
    @media screen and (max-width: 481px) { 
        padding-top: 0px;

        .sidenav-account-main {
            padding-left:15px;
            padding-right:15px;
        }
    }
}


.sidenav-account-wedgit {
    
    .sidenav-account-detail {
        margin-top:8px;
        h6 {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $color-pure-black;
            text-transform: capitalize;
        }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: $color-subtle-grey;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .sidenav-account-editAction {
        color: $color-pure-black;
        font-size: 10px;   
        margin-top: 5px;
        text-transform: initial;
        border-radius: 20px;   
        padding:0px 3px 0px 10px;
        svg {
            width:15px;
        }
    }
}