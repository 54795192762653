.phone-input__container.phone-input__container {
    width: 334px;
    max-width: 100%;
    height: 46px;
}
.phone-input__container.phone-input__container
.phone-input__input.phone-input__input {
    background-color: $color-white;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid $color-dark-grey;
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    font-family: $primary-font, $secondary-font;
    color: $color-pure-black;
    font-size: 1rem;
    line-height: 150%;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    padding-left: 79px;
}
.phone-input__container.phone-input__container
.phone-input__button.phone-input__button {
    background-color: $color-light-gray-input;
    border: 1px solid $color-dark-grey;
    width: 72px;
    border-radius: 100px 0 0 100px;
    height: 100%;
    .selected-flag:hover, .selected-flag:focus {
        background-color: $color-light-gray-input;
    }
    .open.selected-flag {
        background-color: $color-light-gray-input;;
    }
}
.phone-input__container.phone-input__container
.phone-input__button.phone-input__button
.selected-flag.selected-flag {
    border-radius: 100px 0 0 100px;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.phone-input__container {
.phone-input__button
.selected-flag
.flag
.arrow {
    display: block;
    left: 26px;
    border: solid $color-subtle-grey;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(-135deg);
}
.phone-input__button .selected-flag .flag .up {
    transform: rotate(45deg);
    top: 28%;
}
.phone-input__input {
    border: solid 1px $color-dark-grey;
    padding-left: 79px;
}
}
.phone-input__container.phone-input__container
.phone-input__dropdown {
    box-sizing: border-box;
    margin: 6px 0 0 0;
    width: 284px;
    height: 212px;
    max-height: 212px;
    padding-left: 20px;
    padding-right: 6px;
    border-radius: 10px;
    box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    border: none;
    background-color: $color-white;
    color: $color-black;
    font-family: $primary-font, $secondary-font;
    font-size: 0.875rem;
    line-height: 150%;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
}
.phone-input__container.phone-input__container
.phone-input__dropdown::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
}
.phone-input__container.phone-input__container
.phone-input__dropdown::-webkit-scrollbar-thumb {
    height: 38px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $color-subtle-grey;
}
.phone-input__container.phone-input__container
.phone-input__dropdown::before {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
}
.phone-input__container.phone-input__container
.phone-input__dropdown::after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
}

.phone-input__container.phone-input__container
.phone-input__dropdown
.country {
    border-radius: 18px;
    padding: 0;
    display: grid;
    grid-template-columns: auto 44px 1fr;
    grid-template-rows: 40px;
    column-gap: 10px;
}
.phone-input__container.phone-input__container
.phone-input__dropdown
.country
.flag {
    grid-column: 1;
    grid-row: 1;
    margin-top: 14px;
    margin-left: 14px;
}
.phone-input__container.phone-input__container
.phone-input__dropdown
.country
.country-name {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
}
.phone-input__container.phone-input__container
.phone-input__dropdown
.country
.dial-code {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $color-black;
}