.dual-caret {
    .dual-caret_icon-wrapper {
        .dual-caret_icon {
            display: block;
            &:first-child {
                transform: rotate(180deg);
                margin-bottom: rem(2);
            }
        }
    }
}
