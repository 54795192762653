@import '../../global/mixins/global-mixins.scss';
.card-slider-section-3 {
    width: 100%;
    position: relative;
    .heading-section {
        max-width: 55%;
        text-align: left;
        padding: 0;
        @media screen and (min-width: 1136px) {
            margin-left: 0;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 0 0 12.3%;
            max-width: 71.5%;
            text-align: center;
            padding: 0 0;
            float: none;
        }
        @media screen  and (max-width: 600px) {
            float: none;
            margin: 0;
            max-width: 100%;
            text-align: center;
            padding: 0 1.25rem 0 0;
        }
        h2 {
            font-family: $secondary-font;
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 700;
            color: $color-black;
            margin: 0 0 1.25rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 0.5rem;
            }
            &.desktop-only {
                @media screen and (max-width: 1136px) {
                    display: none;
                }
            }
            &.desktop {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            &.tablet {
                @media screen and (min-width: 1136px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 601px) {
                    display: none;
                }
            }
            br {
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                color: $color-primary;
            }
        }
        p {
            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $color-dark;
            margin: 0 0 2.5rem 0;
            max-width: 38.0625rem;
            @media screen  and (max-width: 600px) {
                margin-bottom: 3.75rem;
            }
            &.desktop {
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 601px) {
                    display: none;
                }
            }
        }
    }
    .card-img-text {
        transition: margin ease 0.1s;
        border-radius: 0.625rem;
        background: $color-white;
        @include box-shadow-light();
        width: 100%;
        @media screen  and (max-width: 600px) {
            width: 280px;
        }
        overflow: hidden;
        margin: 0 auto 0.625rem;
        .card-img {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 20.375rem;
            overflow: hidden;
            @media screen  and (max-width: 600px) {
                height: 13.9375rem;
            }
            a {
                display: block;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    filter: grayscale(0);
                    @media screen  and (max-width: 1136px) {
                        filter: grayscale(0);
                    }
                }
            }
        }
        .card-text {
            display: inline-block;
            vertical-align: top;
            width: 100%; 
            padding: 0.625rem 1.875rem 0.8125rem 1.5625rem;
            position: relative;
            h4 {
                color: $color-black;
                height: 1.9375rem;
                overflow: hidden;
                margin: 0 0 0.3125rem 0;
                a {
                    font-size: 1.625rem;
                    line-height: 1.9375rem;
                    font-weight: 500;
                    font-variant: small-caps;
                    text-transform: lowercase;
                    color: $color-black;
                    width: 100%;
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    img {
                        float: right;
                        margin-top: 0.4375rem;
                        @media screen and (min-width: 601px) {
                            display: none;
                        }
                    }
                }
            }
            .card-text-bottom {
                @include small-text();
                color: $color-black;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                margin: 0;
                span {
                    font-weight: 500;
                }
            }
        }
        &:hover {
            background: $color-subtle-white;
            .card-img {
                a {
                    img {
                        filter: grayscale(1);
                    }
                }
            }
        }
    }
}
