html {
    font-size: 16px;
}

body {
    position: relative;
    padding: 0;
    font-family: $primary-font;
    color: $color-black;
    background: $color-light-gray-shade;
    &.scroll-none {
        overflow: hidden !important;
    }
    &.bg-white {
        background: $color-white;
    }
}
.OnboardingTooltip {
    opacity: 0;
    transition: opacity 0.3s ease;
}



a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cn__loader {
    position: relative;
    z-index: 2;
    max-width: 100% !important;
    max-height: 100% !important;
    height: 32px !important;
    width: 32px !important;
    margin: 0 !important;
    display: block!important;
}

.container {
    /* @include full-sub-grid();
  overflow: hidden; */
    @include full-sub-grid();
    position: absolute;
    width: 100%;
    height: 100%;
    &.inner-pages {
        position: relative;
    }
}

.for-desktop {
    @media screen and (max-width: 1136px) {
        display: none;
    }
}

.for-tablet-mobile {
    @media screen and (min-width: 1136px) {
        display: none;
    }
}