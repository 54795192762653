//---------MOBILE---------

//-------------------------------------CARD1 (Popular Countries)---------------------------------------
.cnt__card1 {
  position: relative;
  width: 285px;
  height: 387px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: 15px;
  overflow: hidden;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  &__info {
    position: absolute;
    width: 100%;
    padding: 0 20px;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
    &__subtitle{
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &:first-child {
    margin-left: 20px;
  }
}
//-------------------------------------CARD2 (What to Know)---------------------------------------
.cnt__card2 {
  width: 162px;
  height: 162px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;

  .cn__cnt__card__image {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    p {
      position: absolute;
      width: 100%;
      margin-top: 20px;
      padding: 0 5px;
      font-weight: 700;
      font-size: 21px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #ffffff;
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.7);
    }
  }
  &:first-child {
    margin-left: 20px;
  }
  &.cnt__card2__cities{
    .cn__cnt__card__image{
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        margin-top: 0;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 23px;
        line-height: 28px;
        letter-spacing: 0.02em;
      }
    }
  }
  &.cnt__card2__st{
    .cn__cnt__card__image p{
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
    }
  }
}
//-------------------------------------CARD3 (Where to Visit)---------------------------------------
.cnt__card3 {
  position: relative;
  width: 285px;
  height: 451px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: 15px;
  overflow: hidden;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  &__info {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      position: relative;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      padding: 0 20px;
    }
    img {
      margin-top: 8px;
    }
  }
  &:first-child {
    margin-left: 20px;
  }
}
.cnt__card3__modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: calc(100% - 40px);
  border-radius: 20px;
  //min-height: 50%;
  max-height: calc(100% - 40px);
  background: #F0F1F2;
  z-index: 5001;
  padding: 62px 20px 58px 20px;
  margin-left: 100%;
  overflow: auto;
  svg{
    position: absolute;
    top: 20px;
    right: 12px;
    width: 32px;
    height: 32px;
  }
  p{
    font-size: 16px;
    line-height: 20px;
    color: $color-black;
    a{
      font-weight: 500;
      text-decoration: underline;
      color: inherit;
    }
  }
}
.cnt__card3__modal__overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 5000;
  opacity: 0;
}
//-------------------------------------CARD4 (Blog)---------------------------------------
.cnt__card4 {
  position: relative;
  width: 285px;
  height: 285px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: 15px;
  overflow: hidden;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  &__info {
    position: absolute;
    width: 100%;
    padding: 0 20px;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      max-width: 70%;
    }
  }
  &:first-child {
    margin-left: 20px;
  }
}
//-------------------------------------CARD5 (Discover More )---------------------------------------
.cnt__card5 {
  background: #faefc6;
  border-radius: 15px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  padding: 15px;
  &:first-child {
    margin-left: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #242526;
    }
    &__arrow {
      margin-left: 15px;
    }
  }
}
//-------------------------------------CARD DEFAULT (stays with views)---------------------------------------
.cnt__cardDef {
  position: relative;
  width: 285px;
  height: 387px;
  flex-shrink: 0;
  margin-left: 10px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  border-radius: 15px;
  overflow: hidden;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
  }
  &__info {
    position: absolute;
    width: 100%;
    padding: 0 20px;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  &:first-child {
    margin-left: 20px;
  }
}

//---------TABLET/DESKTOP---------

@media screen and (min-width: 601px) {
  //-------------------------------------CARD1 (Popular Countries)---------------------------------------
  .cnt__card1 {
    margin-left: 20px;
    &:first-child {
      margin-left: 30px;
    }
  }
  //-------------------------------------CARD2 (What to Know)---------------------------------------
  .cnt__card2 {
    position: relative;
    width: 100%;
    height: 22.071vw;
    margin-left: 0px;
    .cn__cnt__card__image {
      p {
        margin-top: 10px;
        padding: 0 10px;
        font-size: 13px;
        line-height: 2.5vw;
      }
    }
    &:first-child {
      margin-left: 0px;
    }
    &.cnt__card2__st{
      .cn__cnt__card__image p{
        font-size: 30px;
      }
    }
  }
  //-------------------------------------CARD3 (Where to Visit)---------------------------------------
  .cnt__card3 {
    margin-left: 20px;
    &:first-child {
      margin-left: 30px;
    }
  }
  //-------------------------------------CARD4 (Blog)---------------------------------------
  .cnt__card4 {
    position: relative;
    width: auto;
    height: 31vw;
    margin-left: unset;
    cursor: pointer;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      position: absolute;
      width: 100%;
      padding: 0 20px;
      bottom: 20px;
      align-items: flex-end;
      img {
        position: relative;
        top: -4px;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
  //-------------------------------------CARD5 (Discover More )---------------------------------------
  .cnt__card5 {
    margin-left: 20px;
    cursor: pointer;
    &:first-child {
      margin-left: 0px;
    }
  }
  //-------------------------------------CARD DEFAULT (Stays with Views)---------------------------------------
  .cnt__cardDef {
    margin-left: 20px;
    &:first-child {
      margin-left: 30px;
    }
  }
}

//---------DESKTOP---------

@media screen and (min-width: 1136px) {
  //-------------------------------------CARD1 (Popular Countries)---------------------------------------
  .cnt__card1 {
    position: relative;
    width: 173px;
    height: 551px;
    margin-left: 20px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    border-radius: 15px;
    overflow: hidden;
    transition: width 0.3s ease;
    cursor: pointer;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      position: absolute;
      width: 100%;
      padding: 0 20px;
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.6;
      transition: opacity 0.6s ease;
      &__title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &.cnt__card-current {
      width: 560px;
      .cnt__card1__info {
        opacity: 1;
      }
    }
  }
  //-------------------------------------CARD2 (What to Know)---------------------------------------
  .cnt__card2 {
    position: relative;
    width: 100%;
    height: 264px;
    .cn__cnt__card__image {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;
      p {
        margin-top: 20px;
        padding: 0 15px;
        font-size: 21px;
        line-height: 28px;
      }
    }
  }
  //-------------------------------------CARD3 (Where to Visit)---------------------------------------
  .cnt__card3 {
    width: 173px;
    height: 665px;
    margin-left: 20px;
    transition: width 0.3s ease;
    cursor: pointer;
    &__info {
      height: auto;
      top: auto;
      width: 133px;
      bottom: 30px;
      left: 20px;
      display: block;
      transition: opacity 0.3s ease;
      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: unset;
        text-transform: unset;
        text-align: left;
        padding: 0;
      }
      img {
        margin-top: 8px;
      }
    }
    &__info2 {
      position: absolute;
      bottom: 30px;
      width: 100%;
      padding: 0 20px;
      opacity: 0;
      transform: translateY(-5px);
      transition: all 0 ease;
      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
      }
      &__description {
        margin-top: 10px;
        font-size: 18px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        a{
          color: inherit;
          font-weight: 500;
          text-decoration: underline;
        }
      }
      &__discover {
        margin-top: 40px;
        a{
          display: flex;
          justify-content: space-between;
          p {
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
          }
        }
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &.cnt__card3-current {
      width: 367px;
      .cnt__card3__info {
        opacity: 0;
      }
      .cnt__card3__info2 {
        transform: translateY(0px);
        transition: opacity 0.3s ease, transform 0.8s ease;
        transition-delay: 0.3s;
        opacity: 1;
      }
    }
  }
  //-------------------------------------CARD4 (Blog)---------------------------------------
  .cnt__card4 {
    position: relative;
    width: auto;
    height: 402px;
    margin-left: unset;
    cursor: pointer;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      position: absolute;
      width: 100%;
      padding: 0 30px;
      bottom: 30px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  //-------------------------------------CARD DEFAULT (Stays with Views )---------------------------------------
  .cnt__cardDef {
    position: relative;
    width: 173px;
    height: 551px;
    margin-left: 20px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    border-radius: 15px;
    overflow: hidden;
    transition: width 0.3s ease;
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__info {
      position: absolute;
      width: 100%;
      padding: 0 20px;
      bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: 0.6;
      transition: opacity 0.6s ease;
      &__title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &.cnt__card-current {
      width: 560px;
      .cnt__card1__info {
        opacity: 1;
      }
    }
  }
}
