.align-center {
    @include align-center
}

.text-500 {
    @include text-500;
}

.color--dark-grey {
    color: var(--color-dark-grey);
}

.col-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}