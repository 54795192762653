//------------------- DESKTOP -------------------

.cn__card2{
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;

    &__img{
        position: relative;
        width: 100%;
        height: 11.042vw;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        .cn__image-default{
            border-radius: 20px 20px 0 0;
        }

        &__discount{
            position: absolute;
            background: #F35597;
            padding: 8px 12px;
            border-radius: 10px;
            top: 16px;
            left: 16px;
            //font
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            span{
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
        }
        &__dates{
            position: absolute;
            bottom: 10px;
            left: 20px;
            //font
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }

    &__info{
        position: relative;
        background: #FFFFFF;
        padding: 10px 20px 20px 20px;
        border-radius: 0 0 20px 20px;

        &__expires{
            position: absolute;
            top: -13px;
            right: 20px;
            background: #FFFFFF;
            border: 1px solid #F7F7F7;
            border-radius: 5px;
            padding: 5px 10px;
            //font
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: #242526;
        }

        &__type{
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 8px;
            p{
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #ACABB4;
                margin-right: 5px;
                text-transform: uppercase;
            }
        }

        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #242526;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            margin-top: 12px;
        }

        &__location{
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 5px;

            svg{
                margin-left: -5px;
                margin-top: -2px;
            }
            p{
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #ACABB4;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                span{
                    font-weight: 500;
                }
            }
        }

        &__rest{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 19px;

            &__view{
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #555759;
                span{
                    position: relative;
                    left: 5px;
                    top: 2px;
                }
            }

            &__prices{
                &__old{
                    font-size: 16px;
                    line-height: 20px;
                    text-align: right;
                    text-decoration-line: line-through;
                    color: #797882;
                }
                &__new{
                    margin-top: 3px;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: right;
                    color: #242526;
                    span{
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 30px 20px -20px rgba(87,75,144, 0.15);
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__card2{
        &__img{
            height: 21vw;
        }
        &:hover{
            transform: none;
            box-shadow: 0 2px 2px rgba(87,75,144,0.15);
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__card2{
        &__img{
            height: 42.4vw;

            &__discount{
                left: 16px;
            }
            &__dates{
                bottom: 5px;
                left: 16px;
            }
        }

        &__info{
            padding: 10px 16px 16px 16px;

            &__expires{
                right: 16px;
            }

            &__rest{
                margin-top: 14px;
            }
        }
    }
}
