.calendar-input {
		@include input-group-box();
		display: grid;
		grid-template-columns: 1fr 10px 1fr;
		box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2); 
		align-items: center;
		position:relative; 
		&.cn-input-empty{
			background: $color-light-gray;
			border: 1px solid $color-light-gray;
			.input-empty{
				background: $color-light-gray;
				border: 1px solid $color-light-gray; 
			  } 
			  } 
		input{
		   padding: 0 20px;
		   width: 100%; 
		}  
		input:focus{
			outline: none;
		}
	input{ 
		border-radius: 0.375rem; 
		@media screen  and (max-width: 600px) {
			border-radius: 0.5rem;
		}
	}  
}

.calendar-input.disable { 
	span{
		svg{
		fill: #807D82;
	} 
	} 

}

.calendar-input.has-error{
	margin-bottom: 30px;
	}

.calendar-input.has-error .error{
	padding:7px 0;
    color: $color-danger;
    border-top:2px solid  $color-danger; 
    font-size: 12px;
    line-height: 15px; 
	position: absolute;
	bottom: -29px;
	left: 0;
	width: 100%;
	}