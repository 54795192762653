//Reset title tag css
.cn__title{
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    font-feature-settings: unset;
    font-variant: unset;
    text-transform: unset;
}

.cn--title1{
    &.cn__title{
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #000000;
    }
}
