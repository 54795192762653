@charset 'UTF-8';

// 1. Settings
@import './settings/colors', './settings/font', './settings/responsive',
  './settings/variables', './settings/z-index';

// 2. Tools
@import './tools/functions', './tools/_mixins';

// 3. Generic
@import './generic/reset', './generic/_base', './generic/typography', './generic/triangle';

// 4. Elements
@import //'elements/blockquote',
  //'elements/button',
  //'elements/code',
  //'elements/form',
  //'elements/headings',
  //'elements/icons',
  //'elements/images',
  //'elements/links',
  //'elements/lists',
  //'elements/paragraphs',
  //'elements/small',
  //'elements/tables';
  './generic/global/headings/h1.scss',
  './generic/global/headings/h2.scss', './generic/global/headings/h3.scss',
  './elements/buttons/btn-common.scss', './elements/buttons/btn-primary.scss',
  './elements/buttons/btn-text-common.scss',
  './elements/buttons/btn-text-primary.scss',
  './elements/buttons/btn-text-secondary.scss',
  './elements/buttons/btn-text-black.scss',
  './elements/buttons/btn-secondary.scss',
  './elements/buttons/btn-text-bright-purple.scss',
  './elements/inputs/input-common.scss', './elements/inputs/primary-input.scss',
  './elements/inputs/search-input.scss',
  './elements/inputs/places-auto-complete.scss',
  './elements/inputs/floating-placeholder.scss',
  './elements/inputs/timezone-input.scss',
  './elements/inputs/calendar-input.scss',
  './generic/global/dropdown-menu/guest-input.scss';

// 6. Components
@import //-------- Wishlist -----------
  './components/Control',
  './components/wishList', './components/SaveWishList', './components/Message',
  //-------- Wishlist -----------
  "./components/geoLocationAutoComplete",
  "./components/Cookie", "./components/Covid",
  "./components/header", "./components/footer", "./components/slick",
  "./components/datePicker", "./components/banner-text-form.scss",
  "./components/tabs-panel.scss", "./components/map",
  "./components/MobileCard.scss", "./components/Omnisearch", "components/RequestCallDialog/RequestCallDialog";

@import "./pages/search-results.scss";
@import "./pages/geo-page.scss";

@import "./pages/reservation.scss";
@import "./pages/covid19.scss";
@import "./pages/valentines.scss";
@import "./pages/recipt.scss";
//-------- GeoPages --------
@import './pages/geopages.scss';
// Components
@import './components/GeoPages/_gp--Search', './components/GeoPages/_gp--Values',
  './components/GeoPages/_gp--Navigation', './components/GeoPages/_gp--Map',
  './components/GeoPages/_gp--Weather',
  './components/GeoPages/_gp--DiscoverMore', './components/GeoPages/_gp--Icon',
  './components/GeoPages/_gp--Card', './components/GeoPages/_gp--Slider',
  './components/GeoPages/_gp--SliderMobile',
  './components/Snacks',
  './components/GeoPages/Sliders/_gp--S--WorldRenowned',
  './components/GeoPages/Sliders/_gp--S--StaysTypes',
  './components/GeoPages/Sliders/_gp--S--UnderAmount',
  './components/GeoPages/Sliders/_gp--S--BreakfastIncluded',
  './components/GeoPages/Sliders/_gp--S--Accessible',
  './components/GeoPages/Sliders/_gp--S--Amenities',
  './components/GeoPages/Sliders/_gp--S--NearAttractions',
  './components/GeoPages/Sliders/_gp--S--PetFriendly',
  './components/GeoPages/Sliders/_gp--S--NearAirport',
  './components/GeoPages/Sliders/_gp--S--WithInternet',
  './components/GeoPages/Sliders/_gp--S--WithInternet',
  './components/GeoPages/Sliders/_gp--S--Family',
  './components/GeoPages/Sliders/_gp--S--PopularSearches';
//-------- GeoPages --------
//-------- Continent --------
@import './pages/continent.scss';
// Components

@import
  './components/Continent/_cnt--Showcase',
  './components/Continent/_cnt--Welcome',
  './components/Continent/_cnt--WhatToKnow',
  './components/Continent/_cnt--MultiSlider',
  './components/Continent/_cnt--WhereToVisit',
  './components/Continent/_cnt--StaysWithViews',
  './components/Continent/_cnt--Blog',
  './components/Continent/_cnt--DiscoverMore',
  './components/Continent/_cnt--Faq',
  './components/Continent/_cnt--Card',
  './components/Continent/_cnt--Slider',
  './components/Continent/_cnt--SliderMobile',
  './components/Continent/Sliders/_cnt--S--PopularCountries',
  './components/Continent/Sliders/_cnt--S--PopularCities',
  './components/Continent/Sliders/_cnt--S--StayTypes',
  './components/Continent/Sliders/_cnt--S--Recommended';
//-------- Continent --------



// ------ Switcher -----
@import './pages/switcher.module.scss';

//-------- NewsRoom--------
@import "./pages/newsroom.scss";
//Components
@import "./components/NewsRoom/_ns--Card";
//-------- NewsRoom --------

//-------- Review page -------
@import './pages/review.scss';

//-------- About Us Page --------
@import "./pages/about-us.scss";
//-------- Contact-Us Page --------
@import './pages/contact-us.scss';
// ------Cancellation Policies Page ------
@import './pages/cancellation.scss';
// Components
@import './components/Cancellation/_cancel-Main',
  './components/Cancellation/_cancel-Sec1';

//-------- HoliHomepage --------
@import './pages/holi.scss';
// Components
@import
    './components//HoliHomepage//_hh--Card',
    './components/HoliHomepage/_hh--MobileSlider',
    './components/HoliHomepage/_hh--Main',
    './components/HoliHomepage/_hh--PopularPlaces',
    './components/HoliHomepage/_hh--Celebrate',
    './components/HoliHomepage/_hh--Discover',
    './components/HoliHomepage/_hh--Deals',
    './components/HoliHomepage/_hh--Accommodation';
//-------- HoliHomepage --------

//---Help Page-----
@import './pages/help.scss';
//---Help Page-----

//---Terms and Conditions Page-----
@import './pages/terms-and-contitions.scss';
//---Terms and Conditions Page-----

//---Privacy Policy Page-----
@import './pages/privacy-policy.scss';
//---Privacy Policy Page-----

//---Maintenance Page-----
@import './pages/maintenance.scss';
//---Maintenance Page-----

//---List Your Property Page-----
@import './pages/list-your-property.scss';
@import
    'components/ListYourProperty/_lyp--LYP1',
    'components/ListYourProperty/_lyp--LYP2',
    'components/ListYourProperty/_lyp--LYP3',
    'components/ListYourProperty/_lyp--LYP4',
    'components/ListYourProperty/_lyp--LYP5',
    'components/ListYourProperty/_lyp--LYP6',
    'components/ListYourProperty/_lyp--LYP7',
    'components/ListYourProperty/_lyp--LYP8',
    'components/ListYourProperty/Cards/_lyp--LYPCard1',
    'components/ListYourProperty/Cards/_lyp--LYPCard2';
//---List Your Property Page-----

//---Care Program Page-----
@import './pages/care-program.scss';
//---Care Program Page-----

@import './pages/eid-al-adha.scss';

//-------- Homepage --------
@import './pages/homepage.scss';
// Components
@import
    'components/HomePage/_hp--Header\(meantime\)',
    './components/HomePage/_hp--Notification',
    './components/HomePage/_hp--Heading',
    './components/HomePage/_hp--Hero',
    './components/HomePage/_hp--Words',
    './components/HomePage/_hp--RecentSearches',
    './components/HomePage/_hp--GoNearBy',
    './components/HomePage/_hp--Values',
    './components/HomePage/_hp--PlacesFor',
    './components/HomePage/_hp--Deals',
    './components/HomePage/_hp--Trending',
    './components/HomePage/_hp--TheEndOf',
    './components/HomePage/_hp--Join',
    './components/HomePage/_hp--EidBanner',
    './components/HomePage/_hp--NextTrip',
    './components/HomePage/_hp--LearMore',
    './components/HomePage/_hp--Featured',
    './components/HomePage/_hp--Media',
    './components/HomePage/_hp--Download',
    './components/HomePage/_hp--CPBanner',
    './components/HomePage/_hp--Seo',
    './components/HomePage/_hp--losNotification',
    './components/PhoneInput';
//-------- Homepage --------

//-------- DEALS --------
//-------- Thanksgiving --------
@import './components/Deals/ThanksGiving/_cn--ThanksGiving';
//-------- Thanksgiving --------
//-------- Blackfriday --------
@import './components/Deals/BlackFriday/_cn--blackFriday';
//-------- Blackfriday --------
//-------- DEALS --------

//-------- CTA --------
@import './components/CTA/_cn--CTA-Login';
@import './components/CTA/_cn--CTA-Completebooking';
@import './components/CTA/_cn--CTA-Loadingsrp';
@import './components/CTA/_cn--CTA-Loginsrp';
@import './components/CTA/_cn--CTA-Freecancellation';
@import './components/CTA/_cn--CTA-Bookinfo1';
@import './components/CTA/_cn--CTA-Bookinfo3';

//-------- CTA --------
//-------- Cross Entities --------
//@import './pages/crossentities.scss';
//-------- Checkout --------
@import './pages/checkout.scss';
//-------- Checkout --------

//--------- General ----------
@import './components/Image/_cn--Image';
@import './components/Title/_cn--Title';
@import './components/Subtitle/_cn--Subtitle';
@import './components/Slider/_cn--Slider';
@import './components/Slider/Cards/_cn--Card1';
@import './components/Slider/Cards/_cn--Card2';
@import './components/Slider/Cards/_cn--Card3';
@import './components/Slider/Cards/_cn--Card4';
@import './components/Slider/Cards/_cn--Card5';
@import './components/Slider/Cards/_cn--Card6';
@import './components/Slider/Cards/_cn--Card7';
@import './components/Slider/Cards/_cn--Card9';
@import './components/Button/_cn--Button';
@import './components/Faq/_cn--Faq';
@import './components/NewFooter/_cn--Footer';
@import './components/NewFooter/_cn--ShortFooter';
@import './components/Message/_cn-Message';
@import './components/Currency/_cn--CurrencyDesktop';
@import './components/Currency/_cn--CurrencyMobile';
@import './components/Currency/_cn--CurrencyCard';
@import './components/Values/_cn--Values';
@import './components/ContentLocation/_cn--ContentLocation';
//--------- General ----------

// form
@import './elements/form';

@import './generic/global/card-img-text-slider/card-img-text-slider.scss';
@import './generic/global/card-text-on-img/card-text-on-img.scss';
@import './generic/global/card-img-text-slider-2/card-img-text-slider-2.scss';

@import './generic/global/breadcrumbs/breadcrumbs.scss';
@import './generic/global/banner-text-form/banner-text-form.scss';
@import './generic/global/daterangepicker/daterangepicker.scss';
@import './generic/global/dropdown-menu/guest-input.scss';
@import './generic/global/inputs/plusminus-input.scss';
@import './generic/global/tabs-panel/tabs-panel.scss';
//@import './generic/global/lightslider/lightslider.scss';
@import './generic/global/card-img-text-slider/card-img-text-slider.scss';
@import './generic/global/card-text-on-img/card-text-on-img.scss';
@import './generic/global/card-img-text-slider-2/card-img-text-slider-2.scss';
@import './generic/global/cities-slider/cities-slider.scss';
@import './generic/global/part-slider/part-slider.scss';
@import './generic/global/why-section/why-section.scss';
@import './generic/global/cabin-section/cabin-section.scss';
@import './generic/global/subscribe/subscribe.scss';
@import './generic/global/reviews/reviews.scss';
@import './generic/global/seo-links/seo-links.scss';
@import './generic/global/model/model.scss';
@import './generic/global/login-popup/login-popup.scss';
@import './generic/global/forgot-password/forgot-password.scss';
@import './generic/global/register-popup/register-popup.scss';
@import './generic/global/home-search-mobile/home-search-mobile.scss';
@import './generic/global/notifications/notification-common.scss';
@import './generic/global/notifications/notification-successful.scss';
@import './generic/global/notifications/notification-failed.scss';
@import './generic/global/notifications/notification-info.scss';
@import './generic/global/notifications/generalalerts-cta.scss';
@import './generic/global/notifications/popularhighlight-alerts.scss';
@import './generic/global/subscribe/subscribe.scss';
@import './generic/global/checkbox_radio/select_item.scss';
@import "./components/calendar";
@import "./pages/dashboard.module.scss";
@import './components/datePickerInOutDates.scss';
@import './components/srpDatePicker.scss';
@include marginsPaddings;

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Simplebar right -XXXX fix */
.simplebar-mask, .simplebar-offset {
  right: 0 !important;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1 !important;
}

.auth-dialog-open{
  overflow: hidden !important;
}

.home-gradient {
  grid-template-columns: unset;
  display: unset;
}
.covid {
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 1600px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  @media (min-width: 601px) {
    padding: 0 30px;
  }
  @media (min-width: 1136px) {
    padding: 0 100px;
  }
}
[data-rsbs-overlay] {
  border-top-left-radius: 24px  ;
  border-top-right-radius: 24px  ;
  z-index: 9999 ;
  height: 85vh;
  --rsbs-overlay-h: 85vh;
}
#launcher {
    z-index: unset !important;;
}
.max-width-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  &.covid {
    position: absolute;
    width: 100%;
    max-width: 1600px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  &.subscribe {
    padding: 0 100px;
    @media screen and (min-width: 601px) and (max-width: 1136px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
  }
  &.seo {
    padding: 0 100px;
    @media screen and (min-width: 601px) and (max-width: 1136px) {
      padding: 0 30px;
    }
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
  }
}
.banner-text-form {
  grid-column: 1/18;
}

.card-slider-section {
  grid-column: 2/18;
  padding: 8.75rem 0 0 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding: 5.625rem 0 0 0;
  }
  @media screen and (max-width: 600px) {
    padding: 50px 0 0 0;
  }
}

.card-slider-section-2 {
  grid-column: 2/18;
  padding: 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
  }
  @media screen and (max-width: 600px) {
    overflow: hidden;
    grid-column: 2/10;
  }
}
.card-slider-section-3 {
  grid-column: 2/18;
  padding: 4.6875rem 0 4.6875rem 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  @media screen and (max-width: 600px) {
    padding: 3.125rem 0;
    overflow: hidden;
    grid-column: 2/10;
  }
}

.cities-section {
  grid-column: 2/18;
  padding: 9.375rem 0 0 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding-top: 6.25rem;
  }
  @media screen and (max-width: 600px) {
    overflow: hidden;
    grid-column: 2/10;
    padding-top: 3.125rem;
  }
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    .heading-section {
      margin: 0 auto;
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.part-section {
  padding-top: 150px;
  grid-column: 2/18;
  .slick-slider {
    padding: 0 92.5px;
    @media screen and (max-width: 1136px) {
      margin-left: 0;
      padding: 0 0 0 30px;
    }
    @media screen and (max-width: 600px) {
      margin-left: 0;
      padding: 0 0 0 20px;
    }
  }
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding: 100px 0 0 0px !important;
  }
  @media screen and (max-width: 600px) {
    display: block;
    padding: 70px 0 0 0 !important;
  }

  @media screen and (min-width: 601px) {
    .slick-track {
      min-width: 1300px;
    }
  }
}
.why-section {
  grid-column: 1/18;
  padding: 196px 100px 0px;
  border-radius: 20px;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding: 70px 30px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 40px 0 0;
    overflow: hidden;
    grid-column: 2/9;
    border-radius: 0px;
  }

  .mobile {
    display: none;
    .slick-prev {
      left: 20px;
      transform: rotate(180deg);
      transform-origin: top;
      margin-top: 10px;
      &.slick-disabled {
        display: none !important;
      }
    }
    .slick-next {
      right: 20px;
      &.slick-disabled {
        display: none !important;
      }
    }
    img {
      margin: 0 auto;
      width: auto;
    }
    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .desktop {
    display: flex;
    @media screen and (min-width: 601px) {
      justify-content: space-between;
      .lslide {
        margin-right: 20px;
        width: 33.33%;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
.cabin-section {
  grid-column: 2/17;
  padding: 9.375rem 0 0 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding-top: 4.375rem;
  }
  @media screen and (max-width: 600px) {
    overflow: hidden;
    grid-column: 1/17;
    padding: 4.375rem 0 3.125rem;
  }
}
.reviews-section {
  grid-column: 2/17;
  padding: 7.5rem 0 0 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding-top: 4.375rem;
    padding-bottom: 3.125rem;
    grid-column: 1/18;
  }
  @media screen and (max-width: 600px) {
    padding-top: 4.375rem;
    padding-bottom: 3.125rem;
    overflow: hidden;
    grid-column: 1/18;
  }
}
.seo-section {
  grid-column: 2/17;
  padding: 100px 0 90px 0;
  @media screen and (min-width: 601px) and (max-width: 1136px) {
    padding: 100px 0 65px 0;
  }
  @media screen and (max-width: 600px) {
    padding-top: 50px;
    padding-bottom: 30px;
    overflow: hidden;
    grid-column: 2/9;
  }
}

.how-well-section {
  padding-top: 100px;
  .slick-slider {
    padding: 0 92.5px;
  }
  @media screen and (max-width: 1136px) {
    padding-left: 0;
    .slick-slider {
      margin-left: 0;
      padding: 0 0 0 30px;
    }
  }
  @media screen and (max-width: 600px) {
    padding-top: 50px;
    .slick-slider {
      margin-left: 0;
      padding: 0 0 0 20px;
    }
  }

  .heading-section {
    margin: 0 auto;
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.where-next-section {
  padding-left: 6.25rem;
  @media screen and (max-width: 1136px) {
    padding-left: 0;
    padding-top: 4.375rem;
  }
  .heading-section {
    max-width: 100%;
    @media screen and (max-width: 1136px) {
      margin: 0 auto;
    }
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    margin-right: 100px;
    h2,
    p {
      max-width: 47.7%;
    }
    p {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    @media screen and (max-width: 1136px) {
      text-align: center;
      align-items: center;

      h2,
      p {
        max-width: 100%;
      }
    }
  }
  .slick-arrow {
    &.slick-prev {
      right: auto;
      left: 0px;
    }
    &.slick-next {
      left: 76px;
      right: auto;
    }
  }
}

.accessible-section {
  padding-top: 150px;
  .slick-slider {
    padding: 0 92.5px;
  }
  @media screen and (max-width: 1136px) {
    padding-top: 100px;
    padding-left: 0;
    .slick-slider {
      margin-left: 0;
      padding: 0 0 0 30px;
    }
  }
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    .slick-slider {
      margin-left: 0;
      padding: 0 0 0 20px;
    }
  }

  .heading-section {
    max-width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0 100px;

    p {
      max-width: 47.7%;
    }
    h2 {
      @media screen and (max-width: 600px) {
        line-height: 37px;
        margin-bottom: 20px !important;
      }
    }

    @media screen and (max-width: 1136px) {
      text-align: center;
      align-items: center;
      margin: 0 auto;
      padding: 0 1.25rem;
      h2,
      p {
        max-width: 100%;
      }
    }

    @media screen and (min-width: 601px) and (max-width: 1136px) {
      max-width: 71.5%;
      margin: 0 auto;
    }
  }

  .card-img-text {
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }
}

.section-slider {
  clear: both;
  .lslide {
    @media screen and (min-width: 601px) and (max-width: 1146px) {
      margin-right: 20px;
    }
    @media screen and (min-width: 300px) and (max-width: 600px) {
      margin-right: 10px;
    }
  }
  .slick-track {
    padding-bottom: 30px;
  }
  .mobile {
    display: none;
    img {
      margin: 0 auto;
    }
    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .desktop {
    display: flex;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
.cities-section {
  .slick-slide {
    padding: 0 10px;
    @media screen and (max-width: 1146px) {
      padding: 0;
    }
  }
}
.slick-slide {
  padding: 0 7.5px;
  @media screen and (max-width: 1146px) {
    padding: 0;
  }
}

.why-slider {
  display: flex;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.display-none {
  display: none;
}

.display-block {
  display: flex;
}

.primary-input-reset {
  padding: 0 !important;
  .DateInput,
  .DateInput_input__focused,
  .guests-input,
  .DateInput_input,
  .DateRangePickerInput,
  .guests-input input {
    background: none;
    border: 0;
    box-shadow: none;
  }
}
mark {
  background-color: transparent;
  font-style: normal;
}
@media screen and (min-width: 300px) and (max-width: 600px) {
  html,
  .error-body {
    height: 100%;
    padding: 0 !important;
  }
  .bdy_bg_white_mble {
    background: $color-white;
  }
}
.input-form {
  position: relative;
}
.simple-link {
  @include body-emphasized();
  color: $color-secondary;
  img {
    width: 0.75rem;
    margin: 0 0 0 0.125rem;
    transition: all 0.15s ease;
  }
  &:hover {
    img {
      margin: 0 0 0 0.375rem;
    }
  }
}
@media screen and (min-width: 1137px) {
  .back-to-top {
    display: none;
  }
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .back-to-top {
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 99999;
  }
  .scroll-none {
    .back-to-top {
      z-index: 1;
    }
  }
}
@media screen and (max-width: 600px) {
  .back-to-top {
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 99999;
  }
  .scroll-none {
    .back-to-top {
      z-index: 1;
    }
  }
}

.tooltip {

  max-width: 292px !important;
  @media (max-width: 600px) {
    max-width: 50% !important;
    min-width: 50px !important;
  }

  color: $color-shade-gray !important;
  border-radius: 10px !important;
  padding: 20px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  opacity: 1 !important;
  ul.cancellation-policies-tooltip {
    list-style-type: none !important;
    padding-top: 0 !important;
    li:not(:first-of-type) {
      padding-top: 20px !important;
    }
  }
}

.header-links > a {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.inner-model-3d {
  overflow: hidden;
  border-radius: 15px !important;
  width: 600px !important;
}

.inner-model-3d-mobile {
  overflow: hidden;
  width: 100vw !important;
}

.tab_container {
  .Mui-selected {
    background: #FDE9E0;
    border: none;
  }
  .MuiTabs-indicator {
    height: 0px !important;
  }
}
