@import '../mixins/global-mixins.scss';
.guests-input-box{
  position: relative;
  cursor: pointer;
  span svg{
    transition: 0.4s;
  }
  &.g-open {
    .btn-text-bright-purple{
      @media screen  and (max-width: 600px) {
        padding: 0;
        &:hover{
          background: none;
        }
      }
    }
    @media screen  and (max-width: 600px) {
      cursor: default;
    }
    .guests-input {
      @media screen  and (max-width: 600px) {
        display: none;
       }
    }
    .guests-dd-box{
      display: block;
      @media screen  and (max-width: 600px) {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 9;
      }
      h2{
        display: none;
        @media screen  and (max-width: 600px) {
          display: block;
         }
        padding: 0 1.875rem;
        text-align: center;
        margin:1.875rem 0   0;
      }
    }
    span svg {
      transform: rotate(180deg);
    }
  }
}
.guests-input {
	@include input-group-box();
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    border: 1px solid $color-light-gray;
    display: grid;
    grid-template-columns: 1fr 0;
    column-gap: 0;
    align-items: center;
    position: relative;
    &.cn-input-empty{
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        .input-empty{
            background: $color-light-gray;
            border: 1px solid $color-light-gray;
          }
          }

          input{
            border-radius: 0.375rem;
            //margin-left: 20px;
            @media screen  and (max-width: 600px) {
              height: 2.875rem;
              border-radius: 0.5rem;
            }
      }
    span {
    }
    input {
        width: calc(100% - 34px);
    }
    input:focus{
        outline: none;
    }

}

.guests-dd-box{
  // display: none;
  position: relative;
  width: 100%;
  background: $color-white;
  border: 1px solid $color-white;
  border-radius: 0.625rem;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 42px;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  @include box-shadow();
  text-align: left;
  cursor: default;
  @media screen  and (max-width: 600px) {
    padding: 0;
    border-radius: 0;
    border: none;
    @include box-shadow-none();
  }
  .mble-guest-close {
    display: none;
    @media screen  and (max-width: 600px) {
      position: absolute;
      top: 0.9375rem;
      right: 0.9375rem;
      z-index: 1;
      cursor: default;
      display: block;
    }
  }
  ul {
    @media screen  and (max-width: 600px) {
      padding: 3.6rem 1.25rem 1.25rem 1.25rem;
    }
    li{
      display: grid;
      grid-template-columns: 1fr 100px;
      @media screen  and (max-width: 600px) {
        grid-template-columns: 1fr 130px;
        }
      list-style: none;
      align-items: center;
      margin-bottom: 1.25rem;
      button {
        margin: 0;
        @media screen  and (max-width: 1136px) {
          cursor: default;
        }
      }
      &.rooms-row{
        @media screen  and (max-width: 600px) {
        border-top: 1px solid $color-light-gray;
        padding-top: 1.25rem;
        }

      }
      .plusminus-input{
        box-shadow: none;
        border:none;
      }
      &:last-child{
        margin-bottom: 1.125rem;
      }
      span{
        i{
          font-style: normal;
          display: block;
          color:$color-dark;
        }
      }
      &.guests-room{
        border-top: 1px solid $color-light-gray; 
        margin: 0;
        padding: 0.313rem 0;
        }
      .floating-input-con{
        padding-right: 1.25rem;
      }
      &.add-child{
        grid-template-columns: 1fr 26px;
        > .btn-text-bright-purple {
          padding: 0;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          height: 1rem;
          svg{
            margin: 0;
          }
        } 
      }
      
    }
  }
  .guests-dd-btns{
    padding: 0.938rem 0 0;
    border-top:  1px solid $color-light-gray;;
    overflow: hidden;
    button {
      @media screen  and (max-width: 1136px) {
        cursor: default;
      }
    }
    .btn-text-black{
      float: left;
    }
    .btn-text-primary{
      float: right;
    }
    @media screen  and (max-width: 600px) {
      margin-left: 0;
      width: 100%;
      bottom: 0;
      padding: 0.938rem 1.25rem 2.188rem 1.25rem;
      background:transparent;
      @include box-shadow();
      position: absolute;
    }
  }
}