.tabs {
    display: flex;
    justify-content: center;
    font-family: $primary-font;
    font-size: 1rem;
    line-height: rem(20);
    .tabs_container {
        display: inherit;
        .tab {
            user-select: none;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: rem(41);
            }
            .tab_slider {
                margin: rem(3) auto 0 auto;
                width: 0;
                border-radius: rem(6);
                height: rem(2);
                background-color: $color-primary;
                transition: width .3s ease-in-out;
            }
            &.tab__active {
                font-weight: 500;
                .tab_slider {
                    width: 100%;
                }
            }
        }
    }
    
    
}