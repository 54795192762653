.cn__geopages{
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 25px 30px 30px 0;
            scroll-padding: 0 0 0 30px;
        }  
    }
    
    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 25px 20px 20px 0;
            scroll-padding: 0 0 0 20px;
        }  
    }
    .gpsldr .simplebar-track.simplebar-horizontal{
        background: #f7f7f7;
        visibility: visible !important;
        height: 20px;
        .simplebar-scrollbar{
            width: 100%;
            background: #f7f7f7;
            &::before{
                width: 100%;
                background:#f7f7f7;
            }
        }
    }
    /* .gpsldr .simplebar-wrapper {
        padding-bottom:10px;
    } */

}