.room {
    $self: &;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 100px 1fr auto;
    grid-gap: 10px;
    &__img {
        width: 150px;
        height: 100px;
        position: relative;
        .n-img {
            width: 100%;
            height: 100%;
        }
    }
    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        align-self: flex-start;
        position: relative;
        .ic-icon {
            display: none;
            position: absolute;
            right: 0px;
            svg {
                position: relative;
                top: -1px;
            }
        }
    }
    >.list {
        grid-area: 2 / 1 / 2 / -1;
        margin: 0px;
        ul {
            display: grid;
            grid-template-columns: 120px 1fr;
            grid-template-rows: repeat(2, 24px);
            grid-row-gap: 10px;
            li {
                @include text-normal;
            }
        }
        &--item-3 {
            li:last-child {
                grid-area: 2 / 1 / 2 / -1;
            }
        }
    }
    .details {
        grid-area: 3 / 2;
        .n-btn {
            padding: 0px;
        }
    }
    &--big-img {
        grid-template-rows: 26px repeat(2, max-content);
        #{$self}__img {
            grid-area: 2 / 1 / 2 / -1;
            width: 100%;
            height: auto;
            max-height: 200px;
        }
        h4 {
            grid-area: 1 / 1 / 1 / -1;
        }
        .list {
            grid-area: 3 / 1 / 3 / -1;
        }
    }
    @include mobile {
        h4 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            padding-right: 20px;
        }
    }
    @include tablet {
        grid-template-columns: 225px auto;
        grid-template-rows: 25px 125px auto;
        #{$self}__img {
            grid-area: 2 / 1;
            width: 225px;
            height: 150px;
        }
        h4 {
            grid-area: 1 / 2;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-variant: inherit;
            text-transform: initial;
            display: block;
        }
        >.list {
            grid-area: 2 / 2;
        }
        &--big-img {
            grid-template-columns: 300px 1fr;
            grid-template-rows: 20px 70px 1fr;
            #{$self}__img {
                grid-area: 1 / 1 / span 3 / 1;
                width: 300px;
                height: 210px;
                max-height: 210px;
            }
            h4 {
                grid-area: 1 / 2;
            }
            .list {
                grid-area: 2 / 2;
            }
            .details {
                grid-area: 3 / 2;
            }
        }
    }
    @include desktop {
        grid-template-columns: 150px auto;
        grid-template-rows: 25px 1fr auto;
        grid-gap: 10px 20px;
        #{$self}__img {
            grid-area: 1 / 1 / 2 / 1;
            width: 150px;
            height: 100px;
        }
        &--big-img {
            grid-template-columns: 300px 1fr;
            grid-template-rows: 20px 70px 1fr;
            #{$self}__img {
                width: 300px;
                height: 210px;
                max-height: 210px;
            }
        }
    }
}