$primary-font: var(--primary-font);

.carousel-slider {
    $self: &;
    &__item {
        overflow-y: hidden !important; // had to override slider library css
    }
    .slick-arrow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        top: 45%;
        z-index: 1;
        &.slick-prev {
            left: 30px;
            .ic-icon {
                left: -1px;
                top: 8px;
                left: 7px;
                transform: rotate(180deg);
            }
        }
        &.slick-next {
            right: 30px;
            .ic-icon {
                top: 9px;
                left: 9px;
            }
        }
        .ic-icon {
            width: 24px;
            height: 24px;
            position: absolute;
        }
    }
    .slick-arrow.slick-arrow.slick-arrow {
        border: 3px solid #797882;
        svg {
            path {
                stroke: #797882;
            }
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 10px;
        li {
            &.slick-active button:before {
                background-color: #FFFFFF;
            }
            button:before {
                background-color: #B3B1B4;
            }
        }
    }
    &--similar {
        margin-bottom: 15px;
        .carousel-slider__item {
            outline: none;
            display: block !important;
            article {
                position: relative;
                margin-right: 30px;
                display: flex;
                flex-direction: column;
                width: 249px;
                border-radius: var(--radius-default);
                top: 0px;
                transition: all 0.3s ease-in-out;
                .images {
                    border-radius: var(--radius-default) var(--radius-default) 0 0;
                    height: 180px;
                    width: 249px;
                    position: relative;
                    filter: gray;
                    -webkit-filter: grayscale(1);
                    filter: grayscale(1);
                    overflow: hidden;
                    >img {
                        object-fit: cover;
                        height: 180px;
                        width: 249px;
                    }
                    >.slick-slider {
                        height: 180px;
                        width: 249px;
                        margin: 0px;
                        .carousel-slider__item {
                            img {
                                height: 180px;
                                width: 249px;
                                object-fit: cover;
                            }
                        }
                    }
                }
                summary {
                    background: #fff;
                    padding: 5px 10px 15px;
                    border-radius: 0 0 var(--radius-default) var(--radius-default);
                    h4 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--color-black);
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    strong {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        font-variant: all-small-caps;
                        color: var(--color-dark-grey);
                    }
                    ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;
                        li {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        .ic-icon {
                            path {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
                &:hover {
                    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
                    @include desktop {
                        top: -5px;
                    }
                    >div {
                        filter: none;
                    }
                    summary {
                        h4 {
                            font-weight: 600;
                        }
                        ul {
                            .ic-icon {
                                path {
                                    fill: var(--color-vibrant-pink);
                                }
                            }
                        }
                    }
                }
            }
        }
        @include mobile {
            .slick-active {
                article {
                    .images {
                        filter: none;
                    }
                    summary {
                        h4 {
                            font-weight: 600;
                        }
                        ul {
                            .ic-icon {
                                path {
                                    fill: var(--color-vibrant-pink);
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-slide {
            padding: 0px;
        }
        .slick-arrow {
            top: 54%;
        }
        .slick-arrow.slick-prev {
            left: 10px;
        }
        .carousel-slider .slick-arrow.slick-next {
            right: 10px;
        }
    }
    &--theme-page {
        margin-bottom: 15px;
        .carousel-slider__item {
            outline: none;
            display: block !important;
            article {
                position: relative;
                margin-right: 30px;
                display: flex;
                flex-direction: column;
                width: 249px;
                border-radius: var(--radius-default);
                top: 0px;
                transition: all 0.3s ease-in-out;
                box-shadow: shadow(0px 2px 20px rgba(75, 118, 144, 0.2));
                .images {
                    border-radius: var(--radius-default) var(--radius-default) 0 0;
                    height: 180px;
                    width: 249px;
                    position: relative;
                    overflow: hidden;
                    &:before {
                        position: absolute;
                        content: ' ';
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 100%);
                    }
                    >img {
                        object-fit: cover;
                        height: 180px;
                        width: 249px;
                    }
                    >.slick-slider {
                        height: 180px;
                        width: 249px;
                        margin: 0px;
                        .carousel-slider__item {
                            img {
                                height: 180px;
                                width: 249px;
                                object-fit: cover;
                            }
                        }
                    }
                    &__footer {
                        position: absolute;
                        left: 15px;
                        bottom: 15px;
                        width: calc(100% - 30px);
                        height: 40px;

                        .rating {
                            display: grid;
                            grid-template-columns: 42px 62px;
                            grid-template-rows: 20px 20px;
                            grid-column-gap: 10px;

                            strong {
                                font-family: $primary-font;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                color: var(--color-black);
                                width: 42px;
                                height: 40px;
                                background: var(--color-white);
                                border-radius: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            b {
                                grid-area: 1 / 2;
                                font-family: $primary-font;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                color: var(--color-white);
                            }
                            small {
                                grid-area: 2 / 2;
                                font-family: $primary-font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.02em;
                                color: var(--color-light-grey);
                            }
                        }
                    }
                }
                summary {
                    background: #fff;
                    padding: 5px 10px 15px;
                    border-radius: 0 0 var(--radius-default) var(--radius-default);
                    h4 {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--color-black);
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    strong {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 19px;
                        font-variant: all-small-caps;
                        color: var(--color-dark-grey);
                    }
                    ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;
                        li {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 20px;
                            color: var(--color-black);
                        }
                        .ic-icon {
                            path {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
        @include mobile {
            .slick-active {
                article {
                    .images {
                        filter: none;
                    }
                    summary {
                        h4 {
                            font-weight: 600;
                        }
                        ul {
                            .ic-icon {
                                path {
                                    fill: var(--color-vibrant-pink);
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-slide {
            padding: 0px;
        }
        .slick-arrow {
            top: 54%;
        }
        .slick-arrow.slick-prev {
            left: 10px;
        }
        .carousel-slider .slick-arrow.slick-next {
            right: 10px;
        }
    }
    &--image-slide {
        .slick-list {
            overflow-x: hidden;
        }
        .slick-track {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .slick-slide {
            padding: 0 5px;
            transition: 0.3s;
        }
        .slick-slide:hover {
            transform: translateY(-5px);
        }
        .carousel-slider__item {
            height: 190px;
            border-radius: 20px;
            position: relative;

            &:before {
                position: absolute;
                content: ' ';
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 49.48%, rgba(0, 0, 0, 0.1) 100%);
            }
            img {
                width: 100%;
                height: 190px;
                object-fit: cover;
                border-radius: 20px;
            }
            a {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: var(--color-white);

                strong {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    text-transform: uppercase;
                    color: var(--color-white);
                    display: block;
                }
                small {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    text-transform: uppercase;
                    color: var(--color-white);
                    display: block;
                    opacity: 0.6;
                }
            }
        }
        .slick-arrow {
            top: -36px;
            width: 28px;
            height: 28px;
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(48, 39, 39, 0.2);
            .ic-icon {
                top: 3px;
                left: 3px;
                width: 20px;
                height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: rgba(48, 39, 39, 0.2);
                    }
                }
            }
            &.slick-next {
                right: 0px;

                .ic-icon {
                    top: 3px;
                    left: 3px;
                }
            }
            &.slick-prev {
                right: 38px;
                left: auto;

                .ic-icon {
                    top: 2px;
                    left: 2px;
                }
            }
        }
        &.loading {
            .carousel-slider__item {
                &:before {
                    display: none;
                }
                .react-loading-skeleton {
                    height: 190px;
                    border-radius: 20px;
                }
            }
        }
    }
    &--blog-slide {
        // margin-bottom: 15px;
        .carousel-slider__item {
            outline: none;
            display: block !important;

            article {
                position: relative;
                margin: 0 5px;
                display: flex;
                flex-direction: column;
                border-radius: var(--radius-default);
                top: 0px;
                transition: all 0.3s ease-in-out;
                box-shadow: shadow(0px 2px 20px rgba(75, 118, 144, 0.2));
                .images {
                    border-radius: var(--radius-default) var(--radius-default) 0 0;
                    height: 190px;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                    &:before {
                        position: absolute;
                        content: ' ';
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 100%);
                    }
                    >img {
                        object-fit: cover;
                        height: 190px;
                        width: 100%;
                    }
                    >.slick-slider {
                        height: 180px;
                        width: 249px;
                        margin: 0px;
                        .carousel-slider__item {
                            img {
                                height: 180px;
                                width: 249px;
                                object-fit: cover;
                            }
                        }
                    }
                }
                summary {
                    background: #fff;
                    padding: 15px 20px;
                    border-radius: 0 0 var(--radius-default) var(--radius-default);
                    min-height: 105px;

                    &.small {
                        min-height: 45px;
                    }

                    h4 {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--color-black);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    strong {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        font-variant: all-small-caps;
                        color: var(--color-subtle-grey);
                        display: block;
                        margin-bottom: 10px;
                    }
                    .item_product_title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 150%;
                    }
                    .item_product_subtitle {
                        white-space: unset;
                    }
                    p {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: 0.02em;
                        color: var(--color-black);
                        margin-bottom: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        @include mobile {
            .slick-active {
                article {
                    .images {
                        filter: none;
                    }
                    summary {
                        h4 {
                            font-weight: 600;
                        }
                        ul {
                            .ic-icon {
                                path {
                                    fill: var(--color-vibrant-pink);
                                }
                            }
                        }
                    }
                }
            }
        }
        .slick-track {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .slick-slide {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            padding: 0px;
            transition: 0.3s;
        }
        .slick-slide:hover {
            filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.25));
            transform: translateY(-5px);
        }
        .slick-list {
            overflow-x: hidden;
        }
        .slick-arrow {
            top: -36px;
            width: 28px;
            height: 28px;
            background: transparent;
            box-shadow: none;
            border: 1px solid rgba(48, 39, 39, 0.2);
            .ic-icon {
                top: 3px;
                left: 3px;
                width: 20px;
                height: 20px;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: rgba(48, 39, 39, 0.2);
                    }
                }
            }
            &.slick-next {
                right: 0px;

                .ic-icon {
                    top: 3px;
                    left: 3px;
                }
            }
            &.slick-prev {
                right: 38px;
                left: auto;

                .ic-icon {
                    top: 2px;
                    left: 2px;
                }
            }
        }
        &.loading {
            .carousel-slider__item {
                &:before {
                    display: none;
                }
                .images .react-loading-skeleton {
                    border-radius: var(--radius-default) var(--radius-default) 0 0;
                    height: 190px;
                    width: 100%;
                    position: relative;
                }
            }
        }
    }
    &--gallery {
        overflow-x: hidden;
        margin: 0 -5px;
        #{$self}__main-slider {
            #{$self}__item {
                img {
                    width: 100%;
                    // 88px for header, 60px for modal margin, 10px for footer, 170px for thumbnail
                    max-height: calc(calc(var(--vh, 1vh) * 100) - 370px);
                    height: calc(calc(var(--vh, 1vh) * 100) - 370px);
                    object-fit: cover;
                }
                p {
                    padding: 15px 40px;
                    width: calc(100% - 80px);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    box-sizing: content-box;
                    overflow: hidden;
                }
            }
            .slick-arrow {
                &.slick-prev {
                    left: 35px;
                }
                &.slick-next {
                    right: 35px;
                }
            }
        }
        #{$self}__navigation {
            padding: 0 20px;
            .slick-track {
                height: 100px;
                overflow-y: hidden;
                min-width: 270px;
            }
            #{$self}__item {
                width: 166px;
                height: 94px;
                padding: 0 10px;
                outline: none;
                #{$self}__item__cover {
                    width: 156px;
                    height: 100px;
                    overflow: hidden;
                    padding: 5px;
                    img {
                        width: 143px;
                        height: 90px;
                        filter: gray;
                        -webkit-filter: grayscale(1);
                        filter: grayscale(1);
                        border-radius: 10px;
                    }
                }
            }
            .slick-current {
                #{$self}__item {
                    #{$self}__item__cover {
                        img {
                            filter: none;
                            box-shadow: 0 0 0px 2px var(--color-vibrant-pink);
                        }
                    }
                }
            }
        }
        &--dots {
            #{$self}__main-slider {
                #{$self}__item {
                    img {
                        width: 100%;
                        // 88px for header, 60px for modal margin, 10px for footer, 170px for thumbnail
                        max-height: calc(calc(var(--vh, 1vh) * 100) - 370px);
                        height: calc(calc(var(--vh, 1vh) * 100) - 370px);
                        object-fit: cover;
                    }
                    p {
                        position: relative;
                        bottom: auto;
                    }
                }
            }
            #{$self}__navigation {
                height: 20px;
                margin: auto;
                .slick-track {
                    height: 20px;
                    overflow-y: visible;
                    min-width: auto;
                }
                .slick-slide {
                    max-height: 20px;
                }
                #{$self}__item {
                    width: 20px;
                    height: 20px;
                    background: transparent;
                    padding: 3px;
                    .dot {
                        background: #999 !important;
                        border-radius: 5px !important;
                        height: 10px !important;
                        min-width: 10px !important;
                        opacity: 0.4;
                        width: 10px !important;
                        transform: scale(0.8);
                    }
                }
                .slick-track {
                    .slick-slide {
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity 0.3s ease-in-out;
                        &.slick-active {
                            pointer-events: initial;
                            opacity: 1;
                        }
                        &.slick-current {
                            .dot {
                                transform: scale(1);
                                opacity: 1 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.slider-masonry {
    position: relative;
    ul {
        width: 100%;
        display: grid;
        min-height: 490px;
        position: relative;
        grid-template-columns: 2fr repeat(2, 1fr);
        li {
            font-size: 0;
            list-style: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: 1px solid var(--color-white);
            cursor: pointer;
        }
    }
    .more-image {
        .more-image__title {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-left: 10px;
        }
        &:hover {
            border-color:#242526;
        }
        &:active {
            transform: scale(0.96);
            border-color: rgb(0, 0, 0);
            background: rgb(247, 247, 247);
            color: rgb(34, 34, 34)
        }
        @include align-center;
        @include text-normal;
        padding: 0.5rem 1rem;
        border: 1px solid #dfe2e5;
        border-radius: 1.875rem;
        position: absolute;
        // width: 160px;
        height: 48px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        right: 20px;
        bottom: 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        color: var(--color-dark-grey);
        cursor: pointer;
        z-index: 1;
        transition: .15s ease-in-out;
        .ic-icon {
            margin-right: 10px;
            svg {
                margin-right: -4px;
            }
        }
    }
    &--item-1 {
        ul {
            grid-template-columns: repeat(1, 1fr);
            min-height: 400px;
            max-height: 400px;
            li {
                &:first-child {
                    border-top-left-radius: var(--radius-default);
                    border-bottom-left-radius: var(--radius-default);
                }
                &:last-child {
                    border-top-right-radius: var(--radius-default);
                    border-bottom-right-radius: var(--radius-default);
                }
            }
        }
    }
    &--item-2 {
        ul {
            grid-template-columns: repeat(2, 1fr);
            min-height: 400px;
            max-height: 400px;
            li {
                &:first-child {
                    border-radius: var(--radius-default) 0 0 var(--radius-default);
                }
                &:last-child {
                    border-radius: 0 var(--radius-default) var(--radius-default) 0;
                }
            }
        }
    }
    &--item-3 {
        ul {
            grid-template-columns: repeat(3, 1fr);
            li {
                &:first-child {
                    border-radius: var(--radius-default) 0 0 var(--radius-default);
                }
                &:last-child {
                    border-radius: 0 var(--radius-default) var(--radius-default) 0;
                }
            }
        }
    }
    &--item-4 {
        ul {
            grid-template-columns: repeat(3, 1fr);
            li {
                &:first-child {
                    border-radius: var(--radius-default) 0 0 var(--radius-default);
                }
                &:last-child {
                    border-radius: 0 var(--radius-default) var(--radius-default) 0;
                }
            }
        }
    }
    &--item-5 {
        ul {
            li {
                &:first-child {
                    grid-area: 1 / 1 / 3 / 2;
                    border-radius: var(--radius-default) 0 0 var(--radius-default);
                }
                &:nth-child(3) {
                    border-radius: 0 var(--radius-default) 0 0;
                }
                &:last-child {
                    border-radius: 0 0 var(--radius-default) 0;
                }
            }
        }
    }
}

// @media screen and (min-width: 600px) and (max-width: 1136px) {
//     .slider-masonry {
//         min-height: 359px;
//     }
// }