//Desktop and tablet component
.cn__gp__navigation--desktop{
    position: fixed;
    width: 64px;
    height: 64px;
    color: #000000;
    right: 50px;
    bottom: 32px;
    z-index: 30;
    &__button{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
    }
    &__modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.5);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease;
        &__info{
            position: absolute;
            background: #FFFFFF;
            border-radius: 20px;
            filter: drop-shadow(0px 2px 20px rgba(87, 75, 144, 0.2));
            padding: 30px 30px 61px;
            right: 50px;
            bottom:116px;
            opacity: 0;
            transform: translate(20px,0);
            &__title{
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: -0.01em;
                color: rgba(121, 120, 130, 0.6);
                pointer-events: none;
            }
            &__options{
                position: relative;
                margin-top: 20px;
                display: inline-grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;
                grid-row-gap: 0px;
                &__option{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    padding: 10px;
                    border-radius: 10px;
                    img{
                        margin-right: 5px;
                    }
                    h2{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                    }
                    &:hover{
                        background: #EEEEF0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1136px){
    .cn__gp__navigation--desktop{
        right: 30px;
        bottom: 32px;
        &__modal{
            &__info{
                @media screen and (max-width:800px){
                    width: calc(100% - 60px);
                }
                right: 30px;
                &__options{
                    grid-column-gap: 0;
                }
            }
        }
    } 
}

//Mobile component
.cn__gp__navigation--mobile{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    padding: 30px 20px;
    margin: 60px auto 0;
    &__title{
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        font-variant: small-caps;
        color: rgba(121, 120, 130, 0.6);
    }
    &__option{
        display: flex;
        align-items: center;
        margin-top: 30px;
        img{
            margin-left: -5px;
            margin-right: 5px;
        }
        h2{
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }
}
.cn__gp__navigation--mobile__slider{
    position: fixed;
    top: 0;
    width: 100%;
    background: #FFFFFF;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    .cn__gp__navigation--mobile__slider__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        padding: 22px 0;
        scroll-padding: 0 0 0 20px;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        &__option{
            display: flex;
            align-items: center;
            padding: 5px 10px;
            background: #EDF2EF;
            border-radius: 10px;
            margin-left: 10px;
            flex-shrink: 0;
            img{
                margin-left: -5px;
                margin-right: 5px;
            }
            h2{
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
        }
    }
    .simplebar-track{
        display: none;
    }
}