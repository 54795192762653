.aside-property-detail_empty {
    width: rem(320);
    margin-left: rem(20);
}
.aside-property-detail {
    animation: appear .3s ease-in-out;
    width: rem(320);
    padding: rem(20) rem(15) rem(20);
    background: $color-white;
    border-radius: rem(10);
    margin-top: rem(87);
    margin-left: rem(20);
    font-size: 1rem;
    align-self: flex-start;
    .aside-property-detail_gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        .aside-property-detail_image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: rem(67);
            border-radius: rem(2);
        }
    }
    .aside-property-detail_guests-row {
        width: 95%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: rem(20) auto auto auto;
    }
    .aside-property-detail_amenities-wrapper {
        &.aside-property-detail_amenities-wrapper-main {
            margin-top: rem(10);
            // padding-top: rem(10);
            border-top: rem(1) solid $color-light-gray-input;
            .aside-property-detail_amenities {
                margin-top: rem(-10);
            }
        }
    }
    .aside-property-detail_amenities {
        width: 95%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        .aside-property-detail_amenity {
            width: 50%;
            margin-top: rem(20);
        }
    }
    .aside-property-detail_amenities-other {
        width: 95%;
        margin: auto;
        margin-top: rem(20);
        color: $color-gray-font;
    }
}
