.footer {
    grid-column: 1/18;
    display: grid;
    border: 1px solid #E9E9EB;
    background: #EEEEF0;
    //background-color: $color-black;
    color: $color-black;
    padding: 50px 0 32px 0;
    position: relative;
    /*@media screen  and (max-width: 600px) {
        padding: 40px 0 142px 0;
    }*/
  }

  .footer-checkout {
    grid-column: 2/17;
    display: grid;
    background-color: $color-light-gray-shade;
    color: $color-gray-font;
    padding: 20px 50px 50px 50px;
    position: relative;
    border-top:1px solid $color-light-gray-input;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

  }
  .footer-inner-checkout {
      display: flex;
      a{
          color:$color-gray-font;
          cursor: pointer;
          line-height: 20px;
          margin-right: 40px;
      }
  }


  .footer-grid {
    width: 100%;
    display: grid;
    
    @media screen  and (max-width: 1136px) {
        grid-template-rows: repeat(6 ,auto);
      }
    /* @media screen and (min-width: 1919px) {
      grid-template-columns: 290px repeat(7, 1fr 20px) 1fr 290px;
    } */
    @media screen and (min-width: 1136px) /* and (max-width: 1919px) */ {
      grid-template-columns: 50px repeat(7, 1fr 20px) 1fr 50px;
    }
    @media screen and (min-width: 601px) and (max-width: 1136px) {
      grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 30px;
    }
    @media screen  and (max-width: 600px) {
      grid-template-columns: 20px repeat(3, 1fr 10px) 1fr 20px;
    }
  }
  .footer-tagline-socail {
    width:389px;
    grid-column-start: 2;
        @media screen  and (max-width: 1136px) {
            width: auto;
            grid-column: 4/15;
            grid-row: 1;
            text-align: center; 
        }
        @media screen  and (max-width: 600px) {
            grid-column: 2/9;
            text-align: left;
        }
  }
  .footer-copy-right {
    grid-column: 2/10;
    grid-row: 4;
    display: flex;
    align-items: flex-end;
    @media screen  and (max-width: 1136px) {
        grid-column: 4/15;
        grid-row: 5;
        text-align: center; 
        display: unset;
        align-items: unset;
        margin-top: 30px;
    }

    @media screen  and (max-width: 600px) {
        grid-column: 2/9; 
    } 
        .copy-right{
            /* @include supplementary-text();
            color: $color-subtle-gray;
            font-weight: 400; */
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: #797882;
            text-align:center;
            a {
                color: $color-subtle-gray;
            }
            .icon {
                background-image: url(#{$path-img}heart.svg);
                width: 0.75rem;
                height: 0.75rem;
                display: inline-block;
            }
        }
  }
  .footer-divider{ 
    border-top: 1px solid $color-subtle-gray; 
    grid-column: 2/17;
    grid-row: 3;
    margin-top:2.5rem;
    margin-bottom: 3.125rem;
    @media screen  and (max-width: 1136px) {
        grid-column: 4/15;
        grid-row: 4; 
        margin-top:50px;
        margin-bottom: 30px;
   }
   @media screen  and (max-width: 600px) {
    grid-column: 2/9; 
}
  }
  .footer-member-section {
    position: relative;
    right: 230px;
    grid-column-start: 17;
    grid-row: 4;
    width: 230px;
    display:flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen  and (max-width: 1136px) {
        grid-column: 4/15;
        grid-row: 6;
        text-align: center;
        border-top: none;
        padding: 0; 
        margin-top: 28px; 
        width: auto;
        right: auto;
        //flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    @media screen  and (max-width: 600px) {
        grid-column: 2/9; 
    
    }  
   
        em {
            position: relative;
            top: 4px;
            @include supplementary-text();
            padding-right: 0.25rem;
            color: $color-subtle-gray;
            font-weight: 400;
            @media screen  and (max-width: 1136px) {
                 display: block;
                 //padding-right: 12px;
                 margin-top: 6px;
                 //margin-bottom: 0.8em;
            }
            
        }
        #vrma-logo{
            //background-image: url(#{$path-img}vrma.svg);
            width: 4.4375rem;
            height: 1.3125rem;
            display: inline-block;
            vertical-align: bottom;
        }
  }
  .footer-slogan {  
    /* font-family: $secondary-font;
    color:$color-black;
    font-style: normal;
    font-weight: bold;
    font-weight: 900;
    font-size: 42px;
    line-height: 46px;
    letter-spacing: -0.02em;
    -webkit-text-stroke:1px; */
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.04em;
    color: rgba(36, 37, 38, 0.4);

    @media screen  and (max-width: 1136px) {
        font-size: 2.25rem;
        line-height: 2.813rem; 
    }
    em {
        color: $color-primary;
        font-style: normal;
    }
  }
.footer-social-links {  
    /* grid-row: 2;
    grid-column-start: 2; */
    grid-row: 4;
    position: relative;
    width: 264px;
    right: 264px;
    grid-column-start: 17;

    display: flex;
    align-items: flex-end;
    @media screen  and (max-width: 1136px) {
        grid-row: 3;
        display: flex;
        grid-column: 4/15;
        margin-top: 3.125rem;
        width: auto;
        right: auto;
    }
    @media screen  and (max-width: 600px) { 
        grid-column: 2/9;
        margin-top: 53px;
    }
    ul {
        padding:0;
        @media screen  and (max-width: 1136px) {
            margin:auto auto 0;
        }
        @media screen  and (max-width: 600px) {
            margin: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        li {
            display: inline-block;
            padding: 0 24px 0 0;
            @media screen  and (max-width: 1136px) {
                padding: 0 27.2px 0 0;
            }
            @media screen  and (max-width: 600px) {
                padding: 0;
            }
            a {
                display: block;
                text-align: center;
                width: 1.5rem;
                height: 1.5rem;
                color:$color-white;
                -webkit-transition: all 0.8s ease 0s;
                -moz-transition: all 0.8s ease 0s;
                -o-transition: all 0.8s ease 0s;
                transition: all 0.8s ease 0s;
                @media screen  and (max-width: 600px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                
                &:hover {
                    opacity: 0.7;
                }
            }
            &:last-child {
                padding: 0;
            }
        }
    }
}
.footer-listings {
    grid-column: 10/17;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    @media screen  and (max-width: 1136px) {
        grid-column: 4/15;
        grid-row: 2;
        margin-top: 3.125rem;
        grid-template-columns: 2fr 1fr;
    }
    @media screen  and (max-width: 600px) {
        grid-column: 2/9; 
        grid-template-columns: repeat(2, 1fr);
        //display: none;
    }

    .footer-listing-inner {
        display: grid;
        grid-template-rows: 100% max-content 1fr;
        margin: 0 0 51px 0;
        @media screen  and (max-width: 1136px) {
            margin: 0;
        }
        ul {
            margin: 0;
            padding: 0;
            position: relative;
            li {
                display: block;
                float: none;
                padding: 0;
                margin: 0 0 8px 0;
                @media screen  and (max-width: 1136px) {
                    margin: 0 0 13px 0; 
                }
                &.mailinglist-mob{
                    display: none;
                    @media screen  and (max-width: 1136px) {
                        display: none;
                    } 
                }
                a { 
                    color: #797882;
                    @include body-text();
                    text-decoration: none;
                    font-weight: 400;
                    @media screen  and (max-width: 1136px) {
                        font-size: 1rem;
                        line-height: 1.25rem;
                    }
                    &:hover, &:focus {
                        text-decoration: underline;
                        outline: 0;
                    }
                }
            }
        }
    }
}

.footer-subscribe{ 
    grid-column: 14/17;
    grid-row: 2;
    //width: 295px;
    @include body-text();
    @media screen  and (max-width: 1136px) {
        display: none;
    }
    p {
        color: $color-light-gray-shade;
    }
    .cn-input-group{
        position: relative;
        margin-top: 0.625rem;;
        input{
        padding-right: 3.125rem;
        color:$color-dark-grey;
        @include body-text();

        &::-webkit-input-placeholder {
            color: $color-dark-grey;
            opacity: 1;
        }
        &::-moz-placeholder {
            color: $color-dark-grey;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: $color-dark-grey;
            opacity: 1;
        }
        &:-moz-placeholder {
            color: $color-dark-grey;
            opacity: 1;
        }
        }
        button{
            position: absolute;
            min-width: auto;
            right: 0;
            top: 0;
            height: 2.375rem; 
            svg{
                margin: 0;
                width: 15px;
                max-width: unset;
                transition: all 0.3s ease;
            }  
            &:hover{
                background: transparent;
                svg{
                    path{
                        stroke:transparent;
                    }
                    transform: translateX(3px);
                }
            }    
            &:focus{
                background: transparent;
                svg{
                    path{
                        stroke:transparent;
                    }
                }
            }     
        }
    }
    
}

.stickysearch-box { 
    background: #FFFFFF;
    padding: 1.25rem;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: none;
    @media screen  and (max-width: 600px) {
        display: block;
    }
    .autocomplete-dropdown-container {
        top: auto;
        bottom: 42px;
    }
}

.footer.footer-home{
    @media screen  and (max-width: 600px) {
        padding: 40px 0 40px 0;
    }
}

body{
    @media screen  and (max-width: 600px) {
        padding-bottom: 80px; // maricon => !important; // Sticky Footer in mobile is present
        &.sicky-search-none {
            padding-bottom: 0px !important;
        }
    }
}

