.sort-by-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-gray-font;
    font-family: $primary-font;
    font-size: 1rem;
    // width: rem(265);
    .sort-by-selector_trigger {
        cursor: pointer;
        font-family: $primary-font;
        font-size: 1rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        outline: none;
        margin-left: rem(25);
        padding: rem(10);
        border-radius: rem(6);
        transition: .3s ease-in-out;
        &:hover {
            background-color: $color-light-gray-input;
        }
        .sort-by-selector_icon {
            margin-left: rem(10);
        }
    }
}

.sort-by-selector_popover-root {
    background: $color-white;
    z-index: 2;
    .sort-by-selector_popover {
        animation: appear .3s ease-in-out;
        border-radius: rem(6);
        border: rem(1) solid $color-light-gray-input;
        box-shadow: 0 rem(2) rem(10) rgba(0, 0, 0, .15);
        padding: rem(10);
        .sort-by-selector_popover-trigger {
            &.sort-by-selector_popover-trigger__selected {
                font-weight: 500;
            }
            white-space: nowrap;
            width: 100%;
            text-align: left;
            font-family: $primary-font;
            font-size: 1rem;
            background: none;
            border: none;
            outline: none;
            padding: rem(10);
            transition: background .3s ease-in-out;
            border-radius: rem(6);
            display: block;
            &:hover {
                background: #EEEEF0;
            }
        }
    }
}
