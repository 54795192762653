//------------------- DESKTOP -------------------

.cn__hp__trending{
    position: relative;
    width: calc(100% - 200px);
    max-width: 1720px;
    padding: 0;
    margin: 100px auto;

    .cn__slider{
        position: relative;
        margin-top: 25px;
    }

    //HEADING
    .cn__hp__heading__subtitle{
        max-width: 681px;
    }

    //CARD
    .cn__cnt__slider__pcountries__slider--slider{
        margin-top: 40px;
    }
    .cnt__card1{
        width: 215px;
        height: 300px;

        .cnt__card1__img{
            filter: brightness(0.9);
        }
    }
    .cnt__card1.cnt__card-current{
        width: 447px;
    }
    .cnt__card1__info{
        flex-direction: column;
        align-items: flex-start;
        bottom: 10px;
    }

    //ARROWS
    .cn__cnt__slider__pcountries__slider__prev, .cn__cnt__slider__pcountries__slider__next{
        top: -65px;
    }
    .cnt__pcountry--arrow{
        transition: opacity 0.3s ease;
    }
    .cnt__pcountry--arrow--disabled{
        opacity: 0.5;
        pointer-events: none;
    }

    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 0 0 34px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 25px 0;
            scroll-padding: 0 0 0 30px;
        }
        //card
        .cnt__card1__info{
            img{
                display: none;
            }
        }
    }

    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 25px 0;
            scroll-padding: 0 0 0 20px;
        }
    }
    /* .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 18px;
        z-index: 0;
        @media screen and (max-width:1136px){
            height: 10px;
        }
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    } */
    //------------ SLIDER WRAPPER-----------
}

//DESKTOP 1280
@media screen and (min-width:1280px){
    .cn__hp__trending{
        //CARD
        .cnt__card1{
            width: 14.454vw;
            height: 23.438vw;
        }
        .cnt__card1.cnt__card-current{
            width: 28.907vw;
        }
    }
}
//DESKTOP 1366
@media screen and (min-width:1366px){
    .cn__hp__trending{
        //CARD
        .cnt__card1{
            width: 15.301vw;
            height: 21.962vw;
        }
        .cnt__card1.cnt__card-current{
            width: 30.601vw;
        }
    }
}
//DESKTOP 1440
@media screen and (min-width:1440px){
    .cn__hp__trending{
        //CARD
        .cnt__card1{
            width: 15vw;
            height: 20.834vw;
        }
        .cnt__card1.cnt__card-current{
            width: 30vw;
        }
    }
}
//DESKTOP 1536
@media screen and (min-width:1536px){
    .cn__hp__trending{
        //CARD
        .cnt__card1{
            width: 15.235vw;
            height: 19.532vw;
        }
        .cnt__card1.cnt__card-current{
            width: 30.469vw;
        }
    }
}
//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__trending{
        //CARD
        .cnt__card1{
            width: 305px;
            height: 423px;
        }
        .cnt__card1.cnt__card-current{
            width: 610px;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__trending{
        position: relative;
        width: 100%;
        margin: 60px auto;

        //CARD
        .cn__cnt__slider__pcountries__slider{
            margin-top: 40px;
        }
        .cnt__card1{
            margin-left: 15px;
            &:first-child{
                margin-left: 30px;
            }
        }
    }
}


//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__trending{
        width: 100%;
        margin: 60px auto 74px auto;
        overflow: unset;

        //CARD
        .cn__cnt__slider__pcountries__slider{
            margin-top: 20px;
        }
        .cnt__card1{
            margin-left: 10px;
            width: 163px;
            height: 167px;
            &:first-child{
                margin-left: 20px;
            }
        }

        .cnt__card1__info{
            bottom: 8px;
            padding: 0 12px;
            &__title{
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.03em;
            }
            img{
                display: none;
            }
        }

        //SLIDER
        .slidermobile__wrapper{
            padding: 0 20px 10px 0 !important;
            display: flex !important;
            width: 100% !important;
            overflow-x: unset !important;
            -ms-scroll-snap-type: unset !important;
            scroll-snap-type: unset !important;
            scroll-padding: unset !important;
        }
    }
}
