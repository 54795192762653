//-------------- DESKTOP --------------

.cn__gp__slider__family {
  position: relative;
  width: 100%;
  margin: 100px auto;
  --min-height1: 'auto';
  --min-height2: 'auto';

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #000000;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #242526;
    margin-top: 10px;
  }
  &__slider {
    position: relative;
    width: 100%;
    margin-top: 15px;
  }
}

//-------------- TABLET --------------

@media screen and (max-width: 1136px) {
  .cn__gp__slider__family {
    margin: 80px auto;

    &__title,
    &__subtitle {
      padding: 0 30px;
    }
    .simplebar-track{
      display: none !important;
    }
  }
}

//-------------- MOBILE --------------

@media screen and (max-width: 600px) {
  .cn__gp__slider__family {
    margin: 60px auto;
  }
}

.cn__gp__card__info__text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 1px 0px 0px -5px;
}
