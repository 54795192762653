//------------------- DESKTOP -------------------

.cn__hp__media{
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 20px;
    justify-content: center;
    &__wrapper{
        display: flex;
        width: 781px;
        padding: 20px;
        height: 67px;
        justify-content: space-between;
        background: #D9D9D9;
        border-radius: 19px;
        align-items: center;
        // gap: 20px;
        &__item{
            display: grid;
            place-items: center;
            flex-shrink: 0;
            color: #797882;
            font-size: 16px;
            font-weight: 400;
            line-height: 120%; 
            img{
                filter: grayscale(1);
                opacity: 1;
                object-fit: contain;
                // width: fit-content;
                height: 21.794px;
                flex-shrink: 0;
            }
            &:nth-child(2){
                img{
                    filter: grayscale(1);
                opacity: 1;
                }
            }
            &:nth-child(3){
                img{
                    filter: grayscale(95%);
                    opacity: 1;
                }
            }
            &:nth-child(4){
                img{
                    filter: grayscale(1);
                    opacity: 1;
                }
            }
            &:nth-child(5){
                img{
                    filter: grayscale(1);
                    opacity: 1;
                    // height: 40px;
                }
            }
            &:nth-child(7){
                img{
                   filter: brightness(0);
                    opacity: 1;
                }
            }
        }
    }
}
@media screen and (max-width:1420px){
    .cn__hp__media{
        justify-content: flex-end;
        right: 147px;
    }
}
@media screen and (max-width:1280px){
    .cn__hp__media{
        bottom: 93px;
        right: unset;
    }
}
//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__media{
        // margin: 60px auto;
        padding: 0px 30px;
        position: absolute;
        // bottom: 171px;
        justify-content: center;
        position: absolute;
        bottom: 8px;
    }
}
//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__media{
        padding: 0px 20px;
        // bottom: -90px;
        position: relative;
        padding: unset;
        margin: unset;
        margin-top: -29px;
        &__wrapper div:first-child {
            grid-column: span 3; /* Make the first element span all 3 columns */
            margin-right: auto;
            margin-left: auto;
        }
        &__wrapper{
            padding-left: 50%;
            padding-right: 50%;
            padding-top: 10px;
            place-items: flex-start;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 14px;
            column-gap: 25px;
            height: unset;
            border-radius: unset;
            width: 100%;
            justify-content: center;
            align-items: center;
            -webkit-align-items: center;
            -webkit-justify-content: center;
            @media screen and (max-width:340px){
                column-gap: 10px;
            }
            &__item{
                img{
                    // object-fit: unset;
                    // width: auto;
                    height: 22.7px;

                }
                &:nth-child(2){
                    // width: 103%;
                    order: 2;
                    img{
                        // width: 116.395px;
                        // height: 20.989px;
                    }
                }
                &:nth-child(3){
                    // width: 77.92%;
                    order: 5;
                    img{
                        // width: 92.066px;
                        // height: 22.897px;
                    }
                }
                &:nth-child(4){
                    // width: 66.28%;
                    order: 3;
                    img{
                        // width: 75.37px;
                        // height: 15.265px;
                    }
                }
                &:nth-child(5){
                    order: 6;
                    // width: 100%;
                    img{
                        // width: 93.02px;
                        // height: 34px;
                    }
                }
                &:nth-child(6){
                    order: 4;
                    // width: 100%;
                    img{
                        // width: 43.886px;
                        // height: 16.696px;
                    }
                }
                &:nth-child(7){
                    order: 7;
                    // width: 100%;
                    img{
                        // width: 36.731px;
                        // height: 26.714px;
                    }
                }
            }
        }
    }
}