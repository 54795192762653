//----------------------- DESKTOP -----------------------

.cn__cookie-wrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 3;
    transition: opacity 0.6s ease, bottom 0.6s ease;
    .cn__cookie{
        width: 630px;
        background: #FFFFFF;
        backdrop-filter: blur(30px);
        border-radius: 14px;
        padding-left: 49px;
        padding-right: 49px;
        padding-top: 60px;
        padding-bottom: 53px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        box-sizing: border-box;
        color: #000000;
        height: 455px;
        .cn__cookie__header {
            display: flex;
            justify-content: center;
        }
        .cn__cookie--title{
            font-weight: 600;
            font-size: 30px;
            line-height: 120%;
            display: flex;
            justify-content: center;
            color: $color-pure-black;
            margin-bottom: 20px;
            margin-top: 50px;
        }
        .cn__cookie--description{
            margin-top: 10px;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            color: $color-gray-hp;
        }
        .cn__cookie--description--two{
            margin-top: 10px;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            color: $color-gray-hp;
            a{
                color: $color-purple;
                text-decoration: underline;
                font-weight: 700;
            }
        }
        .cn__cookie__button-wrapper{
            display: grid;
            grid-template-columns: repeat(2,minmax(0,1fr));
            color: #000000;
            margin-top: 40px;
            @media screen and (min-width: 920px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .cn__cookie--more, .cn__cookie--accept{
                cursor: pointer;
                text-align: center;
                line-height: 1;
                padding: 9.5px;
                border-radius: 20px;
                @media screen and (min-width: 920px) {
                    width: 195px;
                    height: 40px;
                    line-height: 1.5;
                }
            }
            .cn__cookie--more{
                background: $color-subtle-grey;
                color: $color-light-gray-shade;
                font-weight: 600;
                width: 245px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .cn__cookie--accept{
                font-weight: 600;
                color: $color-light-gray-shade;
                background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
                width: 245px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

//----------------------- MOBILE -----------------------
@media screen  and (max-width: 600px) {
    .hide {
        display: none;
    }
    .cn__cookie-wrapper{
        width: 100%;
        margin-left: 0;
        transition: opacity 1s ease;
        z-index: 3;
        .cn__cookie{
            width: auto;
            height: auto; 
            margin-left: 10px; 
            margin-right: 10px; 
            padding: 20px;
            border-radius: 4px;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            line-height: 21px;
            .cn__cookie__header {
                display: none;
            }
            .cn__cookie--title {
                margin-top: 20px;
                font-size: 24px;
            }
            .cn__cookie--description{
                font-size: 12px;
            }
            .cn__cookie--description--two{
                font-size: 12px;
                font-weight: 400;
            }
            .cn__cookie__button-wrapper{
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                .cn__cookie--more, .cn__cookie--accept{
                    padding: 11px;
                    width: 139px;
                    height: 33px;
                    border-radius: 20px;
                
                }
            }
        }
    }
}

.cn__cookie-show{
    opacity: 1;
    visibility: visible;
}
.cn__cookiebannerldp {
    z-index: 10;
}
