.geo-page {
    .header.header-desk {
        border-bottom: none;
        background: transparent;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.geo-page-mapview {
        .header.header-mob {
            z-index: 0;
        }
    }

    .cn__autocomplete-suggestions.autocomplete-dropdown-container {
        transform: translateY(-1.25rem);
    }
}
