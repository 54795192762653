.n-modal {
    $self: &;
    position: fixed;
    width: 100%;
    height: 100%;
    height: calc(var(--vh, 1vh) * 100);
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: -9;
    pointer-events: none;
    --gp-section-compact-padding: 32px !important;
    --gp-section-compact-padding-condensed: 16px !important;
    --gp-section-standard-padding: 48px !important;
    --gp-section-standard-padding-condensed: 24px !important;
    &--active {
        z-index: 991;
        opacity: 1;
        pointer-events: auto;
        .modal-dialog {
            margin: 2.75rem auto 0;
            pointer-events: auto;
        }
    }
    &__cover {
        background: rgba(100, 100, 100, 0.5);
        transition: all 0.1s ease-in-out;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
    }
    &__dialog {
        background: #F7F7F7;
        border-radius: 15px;
        min-height: 150px;
        margin: 30px auto;
        transition: all 0.4s ease-in-out;
        max-width: 660px;
        width: 90%;
        z-index: 992;
        --gp-section-compact-padding: 32px !important;
        --gp-section-compact-padding-condensed: 16px !important;
        --gp-section-standard-padding: 48px !important;
        --gp-section-standard-padding-condensed: 24px !important;
        &__title {
            position: relative;
            display: flex;
            padding: 10px 15px;
            .back {
                height: 40px;
                width: 40px;
                padding: 7px;
                margin: 0 10px 0 0;
                cursor: pointer;
            }
            .close {
                background: #aaa;
                position: absolute;
                top: 7px;
                right: 7px;
                padding: 5px 7px;
                border-radius: 50%;
                cursor: pointer;
                width: 34px;
                height: 34px;
                z-index: 999;
                cursor: pointer;
                svg {
                    height: 12px;
                    width: 12px;
                }
            }
            h1 {
                margin: 0px;
            }
        }
        &__body {
            position: relative;
            padding: 10px 15px;
        }
    }
    &__header {
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px;
        &:after {
            width: calc(100% - 60px);
            left: 30px;
            bottom: 0px;
            position: absolute;
            content: ' ';
            height: 1px;
            background-color: var(--color-light-grey);
        }
        h4 {
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            letter-spacing: -0.01em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 30px;
        }
        #{$self}__back {
            position: absolute;
            left: 0px;
            top: 27px;
            cursor: pointer;
            flex-direction: row-reverse;
            @include text-normal;
            align-items: center;
            padding-top: 0px;
            .ic-icon {
                height: 30px;
                width: 30px;
                margin: 0px;
            }
        }
    }
    &__footer {
        background: #F7F7F7;
        &--empty {
            padding: 10px;
            border-radius: 0 0 15px 15px;
        }
    }
    &__content {
        padding: 30px;
        overflow-y: auto;
        // 88px for header, 10px for footer, 60px for margin
        max-height: calc(100vh - 88px - 10px - 60px)
    }
    &__close {
        position: absolute;
        right: 30px;
        top: 32px;
        cursor: pointer;
    }
    &--fullscreen {
        width: 100%;
        min-width: 100%;
        height: 100vh;
        height: -webkit-fill-available;
        min-height: calc(var(--vh, 1vh) * 100);
        position: fixed;
        top: 0px !important;
        left: 0px !important;
        --gp-section-compact-padding: 32px !important;
        --gp-section-compact-padding-condensed: 16px !important;
        --gp-section-standard-padding: 48px !important;
        --gp-section-standard-padding-condensed: 24px !important;
        #{$self}__dialog {
            margin: 0px;
            border-radius: 0px;
            width: 100%;
            max-width: 100%;
            height: 100vh;
            height: -webkit-fill-available;
            min-height: calc(var(--vh, 1vh) * 100);
            display: flex;
            flex-direction: column;
            padding-bottom: 80px;
            position: relative;
        }
        #{$self}__header {
            padding: 30px;
            height: 80px;
            max-height: 80px;
            &:after {
                display: none;
            }
            h4 {
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
            }
            #{$self}__close {
                top: 20px;
                right: 20px;
            }
        }
        #{$self}__content {
            padding: 20px;
            height: 100%;
            max-height: calc(calc(var(--vh, 1vh) * 100) - 80px);
            overflow-x: hidden;
        }
        #{$self}__footer {
            padding: 0px;
            border: 1px solid #E9E9EB;
            box-sizing: border-box;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            height: 80px;
            max-height: 80px;
            min-width: 100%;
            width: 100%;
            position: fixed;
            left: 0px;
            bottom: 0px;
            #{$self}__back {
                cursor: pointer;
                @include text-normal;
                width: 100%;
                padding: 25px;
                justify-content: center;
                .ic-icon {
                    height: 30px;
                    width: 30px;
                    margin: 0px;
                    position: absolute;
                    left: 10px;
                    top: 21px;
                }
            }
        }
        #{$self}--has-header {
            #{$self}__content {
                max-height: calc(100vh - 160px);
            }
        }
    }
    &--not-scrollable {
        #{$self}__content {
            overflow: hidden;
        }
    }
}

.n-tooltip {
    $self: &;
    position: relative;
    display: inline-block;
    line-height: 14px;
    &__inner {
        position: absolute;
        top: 0px;
        left: 50%;
        max-width: 292px;
        min-width: 145px;
        width: max-content;
        white-space: break-spaces;
        padding: 10px;
        opacity: 0;
        box-sizing: border-box;
        transform: translateX(-50%) translateY(calc(-100% - 8px));
        pointer-events: none;
        border-radius: 10px;
        background: #FCFCFC;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        @include text-normal;
        font-size: 12px;
        &:before {
            position: absolute;
            width: 0;
            height: 0;
            content: ' ';
            bottom: 2px;
            left: calc(50% - 10px);
            background: transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid #FCFCFC;
            transform: translateY(100%);
        }
    }
    &--right {
        .n-tooltip__inner {
            left: calc(100% + 9px);
            top: calc(50% - 17px);
            transform: none;
            &:before {
                left: -10px;
                bottom: calc(50% - 10px);
                border-top: 10px solid transparent !important;
                border-bottom: 10px solid transparent !important;
                border-right: 10px solid #FCFCFC;
                border-left: none;
                transform: translateY(0);
            }
        }
    }
    &--bottom {
        .n-tooltip__inner {
            left: -136px;
            top: calc(100% + 10px);
            transform: none;
            width: 500px;
            &:before {
                left: 0px;
                bottom: calc(50% - 10px);
                border-top: 0px solid transparent !important;
                border-bottom: 0px solid transparent !important;
                border-right: 0px solid #ffffff;
                border-left: none;
                transform: translateY(0);
            }
        }
    }
    &--left {
        #{$self}__inner {
            left: auto;
            right: calc(100% + 10px);
            top: 50%;
            transform: translateY(-50%);
            &:before {
                left: auto;
                right: -10px;
                bottom: calc(50% - 10px);
                border-top: 10px solid transparent !important;
                border-bottom: 10px solid transparent !important;
                border-left: 10px solid #FCFCFC;
                border-right: none;
                transform: translateY(0);
            }
        }
    }
    &--dark {
        #{$self}__inner {
            background: var(--color-black);
            color: var(--color-shade-grey);
        }
        &#{$self}--top {
            #{$self}__inner {
                &:before {
                    border-top-color: var(--color-black);
                }
            }
        }
        &#{$self}--right {
            #{$self}__inner {
                &:before {
                    border-right-color: var(--color-black);
                }
            }
        }
        &#{$self}--left {
            #{$self}__inner {
                &:before {
                    border-left-color: var(--color-black);
                }
            }
        }
    }
    &__modal {
        .n-modal__content {
            >div {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }
        @include tablet {
            .n-modal__content {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }
}

.n-tooltip--enable.n-tooltip--hover:hover,
.n-tooltip--enable.n-tooltip--click.n-tooltip--active {
    .n-tooltip__inner {
        opacity: 1;
        z-index: 9;
        pointer-events: initial;
    }
}

.n-mobile-action {
    $self: &;
    background: rgba(100, 100, 100, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: -9;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    &--active {
        z-index: 991;
        opacity: 1;
        pointer-events: auto;
        #{$self}__dialog {
            pointer-events: auto;
            bottom: 10px;
        }
    }
    &__dialog {
        background: #F7F7F7;
        border-radius: 15px;
        min-height: 150px;
        transition: all 0.4s ease-in-out;
        width: 90%;
        z-index: 992;
        bottom: -100px;
        position: absolute;
        left: 5%;
    }
    &__header {
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 5px 40px 5px 5px;
        h4 {
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            letter-spacing: -0.01em;
        }
        #{$self}__close {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
    }
    &__content {
        padding: 20px;
    }
    &--fullscreen {
        #{$self}__dialog {
            margin: 0px;
            border-radius: 0px;
            width: 100%;
            max-width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        #{$self}__header {
            padding: 30px;
            height: 80px;
            &:after {
                display: none;
            }
            h4 {
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
            }
        }
        #{$self}__content {
            padding: 20px;
            height: 100%;
            max-height: 100vh;
        }
        #{$self}__footer {
            padding: 0px;
            border: 1px solid #E9E9EB;
            box-sizing: border-box;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            height: 80px;
            position: relative;
            #{$self}__back {
                cursor: pointer;
                @include text-normal;
                width: 100%;
                padding: 25px;
                justify-content: center;
                .ic-icon {
                    height: 30px;
                    width: 30px;
                    margin: 0px;
                    position: absolute;
                    left: 10px;
                    top: 21px;
                }
            }
        }
    }
    &--not-scrollable {
        #{$self}__content {
            overflow: hidden;
        }
    }
}

.n-toastr {
    $self: &;
    position: fixed;
    padding: 30px 50px 30px 30px;
    background: var(--color-white);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    z-index: 4;
    pointer-events: none;
    transition: all 0.6s ease;
    &__close {
        position: absolute;
        top: calc(50% - 12px);
        right: 20px;
        cursor: pointer;
    }
    &--active {
        opacity: 1;
        pointer-events: initial;
    }
    @include mobile {
        top: -50px;
        left: 5px;
        width: calc(100% - 10px);
        &#{$self}--active {
            top: 5px;
            opacity: 1;
        }
    }
    @include tablet {
        right: -300px;
        top: 30px;
        &#{$self}--active {
            right: 30px;
            opacity: 1;
        }
    }
}