@mixin imgBroken($class,$color) {
    #{$class}:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: url('../../../public/static/img/broken-image.svg') no-repeat center center;
        background-size: 16px;
        background-color: $color;
        content: '';
        z-index: -1;
    }
}


.confirmation-page{
    .header{
        position: relative;
    }
    .confirmation{
        position: relative;
        width: 100%;
        min-height: calc(100vh - 160px);
        max-width: 1600px;
        margin: 0 auto;
        grid-column: 2/17;
        display: grid;
        margin-bottom: 31px;
        @media screen and (min-width:1700px){
            padding: 0 50px;
        }
        .confirmation-title{
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -0.02em;
            margin-top: 35px;
        }
        .confirmation-subtitle{
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            margin-top: 10px;
        }
        .confirmation-wrapper{
            display: flex;
            margin-top: 30px;
            .map{
                margin-left: -10px;
                flex-grow: 1;
                border-radius: 0px 15px 15px 0px;
                .map-container{
                    border-radius: 0px 15px 15px 0px;
                    .listing-details-map{
                        min-height: auto;
                    }
                    .map-fullscreen, .map-pin, .map-viewtype{
                        display: none;
                    }
                    .map-control{
                        width: 36px;
                        height: 36px;
                        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                    }
                }
            }
        }
        .confirmation-moreinfo{
            margin-top: -5px;
            max-width: 660px;
            .moreinfo-item{
                margin-top: 30px;
                .moreinfo-title{
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    font-variant: unset;
                    text-transform: unset;
                    font-feature-settings: unset;
                }
                .moreinfo-p{
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    margin-top: 10px;
                    &.moreinfo-p-email{
                        font-weight: 600;
                        color: #797882;
                    }
                }
            }
        }
        .moreinfo-notconfirmed{
            margin-top: 15px;
            margin-left: 490px;
            font-style: italic;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #797882;
        }
    }
    .confirmation-loader{
        position: relative;
        width: 100%;
        min-height: calc(100vh - 160px);
        grid-column: 2/17;
        display: grid;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:1136px){
    .confirmation-page{
        .confirmation{
            position: relative;
            width: 100%;
            min-height: calc(100vh - 160px);
            grid-column: 2/17;
            display: grid;
            margin-bottom: 100px;
            .confirmation-wrapper{
                flex-direction: column;
                .map{
                    margin-left: 0;
                    margin-top: -10px;
                    flex-grow: unset;
                    height: 465px;
                    border-radius: 0px 0px 15px 15px;
                    .map-container{
                        border-radius: 0px 0px 15px 15px;
                        .listing-details-map{
                            min-height: 100%;
                        }
                    }
                    .map-control{
                        display: none;
                    }
                }
            }
            .confirmation-moreinfo{
                margin-top: 0;
                max-width: 100%;
                .moreinfo-item{
                    .moreinfo-p{
                        &.moreinfo-p-email{
                            margin-top: 5px;
                        }
                    }
                }
            }
            .moreinfo-notconfirmed{
                margin-left: 0px;
            }
        }
    }
}

@media (max-width:600px){
    .confirmation-page{
        .confirmation{
            grid-column: 1/10;
            .confirmation-title{
                margin-top: 20px;
                font-size: 28px;
                line-height: 30px;
                padding: 0 20px;
            }
            .confirmation-subtitle{
                font-size: 16px;
                line-height: 20px;
                padding: 0 20px;
            }
            .confirmation-wrapper{
                .map{
                    height: 525px;
                }
            }
            .confirmation-moreinfo{
                padding: 0 20px;
            }
            .moreinfo-notconfirmed{
                padding: 0 20px;
            }
        }
    }
    .footer-checkout{
        grid-column: 2/9;
        display: flex;
        padding: 20px 0 31px 0;
        .footer-inner-checkout {
            grid-template-columns: auto 50px auto;
        }
    }
}




//Info box inherit from Sarmad
/* .summary-box{
    display: grid;
    grid-column: 9/17;
    justify-content: center;
    align-items: baseline;
    z-index: 1;
    max-width: 490px;

    @media (min-width:600px) and (max-width:1136px){
        display: block;
        max-width: 100%;
    }
    @media (max-width:600px){
        display: block;
        max-width: 100%;
    }

    .summary-collapsed {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        row-gap: 15px;
        grid-template-rows: 20px 25px;
        background: #EEEEF0;
        padding:30px 20px 25px 20px ;
        .dates{
            span {
                display: inline-block;
                font-weight: 500;
            }
            @media (max-width:600px) {
                grid-column: 1/7;
            }
        }
        .collapsed-total-price {
            font-weight: 500;
            grid-column:1/7;
        }
        .show-details-btn{
            grid-column:1/7;
            display: none;
            background-color: $color-shade-gray;
            @media (max-width:600px) {
                color: #797882;
                width: 180px;
                height: 20px;
                border:none;
                text-align: inherit;
                font-size: 16px;
                line-height: 20px;
                display: inline-block;

                span, img {
                    display: inline-block;
                }
                img{
                    height: 9px;
                    padding-left: 10px;
                }
            }
        }
    }
    .summary{
        // max-height: 506px;
        background: #EEEEF0;
        border-radius: 15px;
        // min-width: 490px;
        padding:25px;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        row-gap: 20px;
        grid-template-rows: 20px 94px;
        @media (min-width:600px) and (max-width:1136px){
            grid-template-rows: 94px;
        }
        @media (max-width:600px) {
            border-radius: 0;
            grid-auto-rows: auto;
            padding:20px;
        }

        @media (min-width:1136px) {
            min-width: 498px;
        }
        grid-auto-rows: 20px;
        .title{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            grid-column: 1/7;
            max-height: 25px;
            font-size: 16px;
            line-height: 20px;
        }
        .tablet-title{
            display: none;
        }
        @media (min-width:600px) and (max-width:1136px){
            .title {
                display: none;
            }
            .tablet-title {
                display: block;
            }
        }
        .property-type-info{
            grid-column:1/7;
            display: grid;
            grid-template-columns: 130px repeat(5,1fr);
            grid-template-rows: 22px 22px 40px;
            .property-type{
                grid-column: 2/7;
                grid-row: 1/2;
                margin-left:15px;
                line-height: 19px;
                color: #797882;
                font-variant:small-caps;
            }
            .location{
                grid-row: 2/3;
                grid-column: 2/7;
                margin-left:15px;
                line-height: 1.25rem;
                color: #242526;
            }
            .property-img{
                position: relative;
                grid-row: 1/4;
                max-width:130px;
                border-radius:6px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }
            @include imgBroken('.property-img',#9888EA);

            @media (min-width:600px) and (max-width:1136px){

                .tablet-title {
                    grid-row: 1/2;
                    grid-column: 2/7;
                    margin-left:15px;
                    font-size: 16px;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .property-type{
                    grid-column: 2/7;
                    grid-row: 2/3;
                }
                .location{
                    grid-row: 3/4;
                    grid-column: 2/7;
                }

            }
        }
        .dates{
            grid-column:1/5;
            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 500;
            }
            @media(max-width:1136px) {
                grid-column: 1/4
            }
            @media (max-width:600px) {
                grid-column: 1/7;
            }
        }
        .change-btn{
            color: $color-bright-blue;
            line-height: 1.25rem;
            width: 54px;
            height: 1.25rem;
            border:none;
            background-color: inherit;
            grid-column-start: -1;
            grid-column-end:6;
            justify-self: end;
            @media (min-width:600px) and (max-width:1136px){
                grid-row:4/5;
                grid-column: 1/2;
                justify-self: start;
            }
            @media (max-width:600px) {
                grid-column: 1/7;
                grid-row:6/7;
                justify-self: start;
            }
        }
        .number-of-guests {
            grid-column:1/7;
            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 500;
            }
        }
        .cancellation{
            grid-column:1/7;

            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 500;
            }
            .more-info{
                position: relative;
                left: 25px;
                bottom: 3px;
            }
            @media (min-width:600px) and (max-width:1136px){
                grid-column: 4/7;
                grid-row:2/3;
            }
            .cancellation-link {
                color: $color-black;
                // border-bottom: 1px solid $color-black;
            }
        }
        .hr-separator{
            grid-column: 1/7;
            border-top: 1px solid $color-light-gray-input;
        }
        .rooms-nights-amount{
            grid-column: 1/5;

        }
        .price{
            grid-column-start: -1;
            grid-column-end: 6;
            justify-self: end;
        }
        .fee-with-tooltip{
            grid-column:1/5;
            .more-info{
                position: relative;
                left: 10px;
                top: 2px;
            }
        }
        .total{
            grid-column:1/5;
            font-weight: 500;
        }
        .total-price{
            grid-column-start: -2;
            justify-self: end;
            font-weight: 500;
        }
        .hide-details-btn{
            display: none;
            background-color: $color-shade-gray;
            @media (max-width:600px) {
                color: #797882;
                width: 180px;
                height: 20px;
                border:none;
                text-align: inherit;
                font-size: 16px;
                line-height: 20px;
                display: inline-block;

                span, img {
                    display: inline-block;
                }
                img{
                    height: 9px;
                    padding-left: 10px;
                }
            }
        }
        .additional-charges {
            grid-column: 1/7;
            margin-top:-15px;

        }
        .left-aligned, .right-aligned, .additional-charges {
            color: $color-dark-grey;
            font-weight: 400;
            font-size: 16px;
            margin-top:-15px;
        }
    }
} */



.summary-box{
    display: grid;
    grid-column: 9/17;
    justify-content: center;
    align-items: baseline;
    line-height: 20px;
    z-index: 1;
    max-width: 490px;

    /* @media (min-width:600px) and (max-width:1136px){
        grid-column: auto;
        order:1;
        justify-content: initial;
    }
    @media (max-width:600px){
        grid-column: auto;
        order:1;
        justify-content: initial;
    } */
    @media (min-width:600px) and (max-width:1136px){
        display: block;
        max-width: 100%;
    }
    @media (max-width:600px){
        display: block;
        max-width: 100%;
    }

    .summary-collapsed {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        row-gap: 15px;
        grid-template-rows: 20px 25px;
        background: #EEEEF0;
        padding:30px 20px 25px 20px ;
        .dates{
            span {
                display: inline-block;
                font-weight: 400;
            }


            @media (max-width:600px) {
                grid-column: 1/7;
            }
        }
        .collapsed-total-price {
            font-weight: 600;
            grid-column:1/7;
        }
        .show-details-btn{
            grid-column:1/7;
            display: none;
            background-color: $color-shade-gray;
            @media (max-width:600px) {
                color: #797882;
                width: 185px;
                height: 20px;
                border:none;
                text-align: inherit;
                font-size: 16px;
                font-family: $primary-font !important;
                line-height: 20px;
                display: inline-block;

                span, img {
                    display: inline-block;
                }
                img{
                    height: 9px;
                    padding-left: 10px;
                }
            }
        }
    }
    .summary{
        // max-height: 506px;
        background: #EEEEF0;
        border-radius: 15px;
        // min-width: 490px;
        padding:25px;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        row-gap: 20px;
        grid-template-rows: 20px 94px;
        @media (min-width:600px) and (max-width:1136px){
            grid-template-rows: 94px;
        }
        @media (max-width:600px) {
            border-radius: 0;
            grid-auto-rows: auto;
            padding:20px;
        }

        @media (min-width:1136px) {
            min-width: 498px;
        }
        grid-auto-rows: minmax(20px, min-content);
        .title{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            grid-column: 1/7;
            max-height: 25px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
        }
        .tablet-title{
            display: none;
        }
        @media (min-width:600px) and (max-width:1136px){
            .title {
                display: none;
            }
            .tablet-title {
                display: block;
            }
        }
        .property-type-info{
            grid-column:1/7;
            display: grid;
            grid-template-columns: 130px repeat(5,1fr);
            grid-template-rows: 22px 22px 40px;
            .property-type{
                grid-column: 2/7;
                grid-row: 1/2;
                margin-left:15px;
                line-height: 19px;
                color: #797882;
                font-variant:small-caps;
            }
            .location{
                grid-row: 2/3;
                grid-column: 2/7;
                margin-left:15px;
                line-height: 1.25rem;
                color: #242526;
            }
            .property-img{
                grid-row: 1/4;
                max-width:130px;
                border-radius:6px;
                height: auto;
                max-height: 110px;
            }
            @media (min-width:600px) and (max-width:1136px){

                .tablet-title {
                    grid-row: 1/2;
                    grid-column: 2/7;
                    margin-left:15px;
                    font-size: 16px;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 600;
                }
                .property-type{
                    grid-column: 2/7;
                    grid-row: 2/3;
                }
                .location{
                    grid-row: 3/4;
                    grid-column: 2/7;
                }

            }
        }
        .dates{
            grid-column:1/5;
            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 600;
            }
            @media(max-width:1136px) {
                grid-column: 1/4
            }
            @media (max-width:600px) {
                grid-column: 1/7;
            }
        }
        button.change-btn{
            color: $color-bright-blue;
            width: 54px;
            height: 1.25rem;
            border:none;
            background-color: inherit;
            grid-column-start: -1;
            grid-column-end:6;
            justify-self: flex-end;
            font-family: $primary-font !important;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            @media (min-width:600px) and (max-width:1136px){
                grid-row:4/5;
                grid-column: 1/2;
                justify-self: flex-start;
            }
            @media (max-width:600px) {
                grid-column: 1/7;
                grid-row:6/7;
                justify-self: flex-start;
            }
        }
        .number-of-guests {
            grid-column:1/7;
            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 600;
            }
        }
        .cancellation{
            grid-column:1/7;

            span {
                position: relative;
                bottom: 6px;
                left: 13px;
                font-weight: 600;
            }
            .more-info{
                position: relative;
                left: 25px;
                bottom: 3px;
            }
            @media (min-width:600px) and (max-width:1136px){
                grid-column: 4/7;
                grid-row:2/3;
            }
            .cancellation-link {
                color: $color-black;
                // border-bottom: 1px solid $color-black;
            }
        }
        .hr-separator{
            grid-column: 1/7;
            border-top: 1px solid $color-light-gray-input;
        }
        .rooms-nights-amount{
            grid-column: 1/5;

        }
        .price{
            grid-column-start: -1;
            grid-column-end: 6;
            justify-self: flex-end;
        }
        .fee-with-tooltip{
            grid-column:1/5;
            .more-info{
                position: relative;
                left: 10px;
                top: 2px;
            }
        }
        .total{
            grid-column:1/5;
            font-weight: 600;
        }
        .total-price{
            grid-column-start: -2;
            justify-self: flex-end;
            font-weight: 500;
            font-size: 18px;
        }
        .hide-details-btn{
            display: none;
            background-color: $color-shade-gray;
            @media (max-width:600px) {
                color: #797882;
                width: 180px;
                height: 20px;
                border:none;
                text-align: inherit;
                font-size: 16px;
                font-family: $primary-font !important;
                line-height: 20px;
                display: inline-block;

                span, img {
                    display: inline-block;
                }
                img{
                    height: 9px;
                    padding-left: 10px;
                }
            }
        }
        .additional-charges {
            grid-column: 1/7;

        }
        .left-aligned, .right-aligned, .additional-charges {
            color: $color-subtle-grey;
            font-weight: 400;
            font-size: 16px;
        }
        .additional-charges {
            font-weight: 600 !important;
        }
        .on-arrival {
            font-style: italic;
            color: $color-subtle-grey;
        }
        .arrival-time {
            color: $color-subtle-grey;
            grid-column: 1/7;
            .heading {
                font-weight: 600;
                line-height: 20px;
            }
            .text {
                padding-top:5px;
                font-weight: 400;
                font-style: italic;
            }
        }
        .tax-breakdown {
            color: $color-subtle-grey;
            grid-column: 1/7;
            display: grid;
            font-style: italic;
            .heading {
                font-weight: 600;
                line-height: 20px;
                font-style: normal;
            }
            .tax-breakdown--item {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .tax-breakdown--item__val{
                    justify-self: flex-end;
                }
            }
        }
        .comments,.policies {
            color: $color-subtle-grey;
            grid-column: 1/7;
            font-style: italic;
            .heading {
                font-weight: 600;
                line-height: 20px;
                font-style: normal;
            }
        }
    }
}

