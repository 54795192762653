//------------------- DESKTOP -------------------

.cn__CTA__loginsrp__wrapper{
    width: 100%;
    margin-bottom: rem(40);

    &.cn__CTA__loginsrp__wrapper--mobile {
        padding: 0 20px;

        .cn__CTA__loginsrp{
            border-radius: 15px;
            align-items: flex-start;

            .cn__CTA__loginsrp__icon {
                display: none;
            }

            &__info{
                margin-left: 17px;
                display: flex;
                align-items: flex-start;
                justify-content: unset;
                flex-direction: column;

                &__title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-self: center;
                    text-align: center;
                    span {
                        margin-bottom: 13px;
                    }
                    &:not(span) {
                        font-size: 12px;
                    }
                }

                button{
                    margin-top: 13px;
                    padding: 9px 48px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &.cn__CTA__loginsrp__wrapper--tablet {
        padding-left: rem(20);
        padding-right: rem(20);
    }
}

.cn__CTA__loginsrp{
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    border: 1px solid $color-field-grey;
    box-shadow: 0 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 20px;

    &__icon{
        position: relative;
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        background: rgba(243, 85, 151, 0.11);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{

            transform-origin: center center;
            animation-name: move;
            animation-duration: 2.5s;
            animation-iteration-count: infinite;

            @keyframes move {
                0%{ transform:scale(1) }
                50%{ transform:scale(0.8) }
                100%{ transform:scale(1) }
            }
        }
    }

    &__info{
        position: relative;
        width: 100%;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title{
            color: $color-black;
            letter-spacing: 0.01em;
            font-size: 16px;
            line-height: 150%;

            span {
                font-weight: 600;
                font-size: 18px;
                letter-spacing: 0.02em;
            }
        }

        button{
            position: relative;
            display: block;
            flex-shrink: 0;
            pointer-events: initial;
            padding: 9px 48px;
            margin-left: 20px;
            outline: none;
            border: none;
            border-radius: 10px;
            background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
            cursor: pointer;
            //font
            color: #FFFFFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            font-family: $primary-font;
        }
    }
}
