.guest-selector {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 1rem;
	border: none;
	background: none;
	outline: none;
	font-family: $primary-font;
	cursor: pointer;
	// transform: translateY(29px);
	border-radius: rem(6);
	padding-left: rem(10);
	padding-right: rem(10);
	&:hover {
		background-color: $color-light-gray-input;
	}
	// .guest-selector_activator {
	//     border: none;
	//     background: none;
	//     outline: none;
	//     font-family: $primary-font;
	//     font-weight: 500;
	//     font-size: 1rem;
	//     width: rem(281);
	//     max-width: rem(281);
	//     text-align: left;
	//     .guest-selector_track {
	//         background-color: $color-light-gray-input;
	//         border-radius: rem(6);
	//         padding: rem(10) rem(20);
	//         color: #757575;
	//     }
	// }

	.guest-selector_room-info {
		display: none;
	}

	.guest-selector_toggle {
		border: none;
		background: none;
		outline: none;
		margin-left: rem(10);
		cursor: pointer;
		.carret-icon {
			transition: 0.3s ease-in-out;
			width: rem(10);
		}
	}

	&.guest-selector__active {
		.guest-selector_toggle {
			.carret-icon {
				transform: rotate(0deg);
			}
		}
	}
}

.guest-selector_popover-root {
	position: absolute;
	z-index: 3;
}

.guest-selector_popover_bigsize {
	min-width: 462px !important;
	@media (max-width: 600px) {
		min-width: unset !important;
	}
}

.guest-selector_popover {
	background: $color-white;
	opacity: 0;
	transition: 0.3s ease-in-out;
	min-width: 356px;
    max-width: fit-content;
	border-radius: rem(20);
	border: rem(1) solid $color-light-gray-input;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
	// box-shadow: 0 rem(2) rem(10) rgba(0, 0, 0, 0.15);
	padding: rem(30) rem(30) rem(20);
	@media (max-width: 600px) {
		max-width: unset !important;
	}
	&.guest-selector_popover__hide {
		opacity: 0;
	}

	&.guest-selector_popover__show {
		opacity: 1;
	}

	&.guest-selector_hotel {
		.subject-row {
			&:not(:first-child) {
				border-top: rem(1) solid $color-light-gray-input;
			}
		}
	}

	&.guest-selector_vr {
		.subject-row {
			.subject-row_wrapper {
				//padding-top: 0;
			}
		}
	}

	.subject-row {
		&:first-child {
			.subject-row_wrapper {
				padding-top: 0;
			}
		}
		&:last-child {
			.subject-row_wrapper {
				border-bottom: none;
			}
		}
		.subject-row_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: rem(20) 0;
			border-bottom: 0.5px solid #e9e9eb;

			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.01em;
			color: #242526;

			.subject-row_subtitle {
				font-weight: 400;
				color: $color-dark-grey;
			}
		}
	}

	.child-manager {
		.child-manager_input-row {
			position: relative;
			animation: appear 0.3s ease-in-out forwards;
			margin-bottom: rem(20);
			display: flex;
			align-items: center;
			justify-content: space-between;
			.child-manager_input-append {
				position: absolute;
				right: 57%;
			}
			.child-manager_input-remove {
				margin-left: rem(6);
				border: none;
				background: none;
				outline: none;
				border-radius: 50%;
				min-width: rem(34);
				height: rem(34);
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: 0.3s ease-in-out;
				&:hover {
					background: $color-light-gray-input;
				}
				.child-manager_input-remove-icon {
					path {
						stroke: $color-bright-purple;
					}
				}
			}
		}

		.child-manager_action {
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			background: none;
			outline: none;
			color: $color-bright-purple;
			font-size: 1rem;
			font-family: $primary-font;
			line-height: rem(20);
			cursor: pointer;
			border-radius: rem(6);
			width: rem(121);
			height: rem(30);
			margin-bottom: rem(20);
			transition: background 0.3s ease-in-out;
			transform: translateX(-15px);
			&:hover {
				background: $color-light-gray-input;
			}
			.child-manager_action-icon {
				margin-right: rem(10);
				path {
					stroke: $color-bright-purple;
				}
			}
		}
	}

	.guest-selector_action-bar {
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.action-bar_btn {
			&:first-child {
				transform: translateX(-10px);
			}
			&:last-child {
				transform: translateX(10px);
			}
		}
		.guest-selector_action {
			// height: rem(46);
			// line-height: rem(46);
			// @media (min-width: 601px) {
			// 	height: rem(40);
			// 	line-height: rem(40);
			// }
			border-radius: 30px;
			width: 306px;
			padding: 10px 0;
			background: linear-gradient(92deg, #F35597 1.81%, #7868C7 148.68%);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 150%;
			letter-spacing: 0.14px;
	
			// border-radius: 20px;
			font-family: $primary-font;
			color: $color-white;
			// background-color: $color-primary;
			// font-size: 1rem;
			// font-weight: 500;
			border: none;
			width: 60%;
			cursor: pointer;
			outline: none;
		}
	}
}

.cn__childAgeSelector {
	display: flex;
	flex-direction: column;
	width: 100%;
    height: max-content;
	margin-top: 7px;
	&-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
        margin-left: 0;
		column-gap: 8px;
	}
	&_typography {
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		/* or 21px */
		letter-spacing: 0.01em;
		/* Black */
		color: #242526;
	}
	&_wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-block: 10px;
		margin-bottom: 6px;
		border: 1px solid var(--color-subtle-grey);
    	border-radius: 15px;
		padding-left: 8px;
    	padding-right: 8px;
	}
	&_select {
        &_wrapper {
            display: flex;
            align-items: center;
            gap: 5.77px;
			width: 40px;
			height: 40px;
			background: var(--color-shade-grey);
			align-items: center;
			text-align: center;
			justify-content: center;
			border-radius: 30px;
			color: $color-black;
        }
		width: min-content;
		height: min-content;
		padding: 0;
		display: flex;
		color: $color-black;
		align-items: center;
		text-align: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.01em;
		background: var(--color-shade-grey);
        cursor: pointer;
		&:focus {
			outline: none;
		}
		&_option {
			background: white;
			padding-block: 10px;
		}
	}
}
