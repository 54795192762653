@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(359deg);
    }
}

.loader {
    // width: 300px;
    // height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    .loader_circle {
        width: rem(36);
        height: rem(36);
        padding: rem(4);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: linear-gradient(0deg, #EEEDF7 20%, #2A04E1 100%);
        animation: spin .6s linear 0s infinite;

        .loader_circle-core {
            width: 100%;
            height: 100%;
            background-color: #f7f7f7;
            border-radius: 50%;
        }
    }
}