.autocomplete-dropdown-container {
   display: flex;
   flex-direction: column;
   position: absolute;
   z-index: 1;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
   //border-radius: 6px;
   //padding: 0.313rem;
   border-radius: 10px;
   padding: 1.25rem;
   background: $color-white;
   //top: 42px;
   //top:52px;
   top: auto;
   width: 100%;
   
   .suggestion-item, .suggestion-item--active {
       //padding: 10px 15px;
       padding: 0.625rem;
       cursor: pointer;
       position: relative;
       z-index: 9999;
       white-space: nowrap;
       text-overflow: ellipsis;
       overflow: hidden;
       pointer-events: auto;
       border-collapse: collapse;
       border-radius: 6px;
       width: 100%;
       span {
           width: auto;
           float: none;
       }
       
   }

    .suggestion-item--active {
       background: #EEEEF0;
   }

   .autocomplete{
       padding:  0.625rem 1.25rem;
   }
}
.search-field {
   position: relative;
   @media screen and (min-width: 601px) and (max-width: 1136px) {
      text-align: left;
   }
}