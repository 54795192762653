.notification-info {
    @extend .notification-common;
    @media screen  and (max-width: 600px) {
        border-bottom-color: $color-secondary;
    }
    .notification-left {
        span {
            background: $color-secondary;
        }
    }
}