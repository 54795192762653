//---------MOBILE---------
.cn__cnt__blog{
    position: relative;
    background: #FAF6E7;
    margin: 0 auto;
    width: 100%;
    padding: 40px 0 30px;
    &__title{
        font-weight: bold;
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #F86A2D;
        padding: 0 20px;
    }
}

//---------TABLET---------
@media screen and (min-width:601px){
    .cn__cnt__blog{
        position: relative;
        background: #FAF6E7;
        margin: 0 auto;
        border-radius: 20px;
        width: 100%;
        padding: 40px 40px 20px;
        &__title{
            font-size: 36px;
            line-height: 40px;
            letter-spacing: -0.03em;
        }
    }
}