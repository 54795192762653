//-------------- DESKTOP --------------

.cn__gp__slider__worldrenowned{
    position: relative;
    width: 100%;
    margin: 100px auto;
    --min-height1: 'auto';
    --min-height2: 'auto';

    &__title{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #000000;
    }
    &__slider{
        position: relative;
        width: 100%;
        margin-top: 15px;
    }
}

//-------------- TABLE --------------

@media screen and (max-width:1136px){
    .cn__gp__slider__worldrenowned{
        margin: 80px auto;

        &__title{
            padding: 0 30px;
        }
        .simplebar-track{
            display: none !important;
        }
    }  
}

//-------------- MOBILE --------------


@media screen and (max-width:600px){
    .cn__gp__slider__worldrenowned{
        margin: 60px auto;
        &__title{
            padding: 0 20px;
        }
    }
}


//General Elements

.cn__gp__slider__showall{
    position: relative;
    left: 100vw;
    margin-left: -107px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    p{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-right: 10px;
    }
}
@media screen and (min-width:1136px){
    .cn__gp__slider__showall{
        position: absolute;
        left: unset;
        margin-left: unset;
        right: 0;
        top: unset;
        bottom: -20px;
    }
}