//------------------- DESKTOP -------------------

.cn__blackfriday{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;

    .header{
        .max-width-wrapper{
            max-width: unset;
            width: calc(100% - 200px);
        }
        .logo-pink, .logo-purple {
            fill: #FFFFFF !important;
        }
        .li--blog {
            display: none !important;
        }
        .hamburguer-menu-lin1, .hamburguer-menu-lin2{
            background: #FFFFFF !important;
        }
        &.header_menu--active{
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #242526 !important;
            }
        }
    }

    &__hero{
        position: relative;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0;
        transition: all 0.6s ease;

        &__top{
            position: relative;
            width: calc(100% - 300px);
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            color: #FFFFFF;
            z-index: 2;

            &__title{
                width: 60%;
            }

            &__subtitle{
                font-weight: 600;
                font-size: 22px;
                line-height: 46px;
                letter-spacing: -0.02em;
                margin-top: -4%;
            }

            .omnisearch{
                .omnisearch_content{
                    margin: 6vh 0 0 0;
                }
            }

            &__counter{
                position: relative;
                color: #FFFFFF;
                margin-top: 6vh;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                z-index: -1;

                &__times{
                    position: relative;
                    margin-top: 2vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 18px;

                    @supports not (gap: 1em) {
                        margin: -9px;

                        & > * {
                          margin: 9px;
                        }
                    }

                    &__box{
                        position: relative;
                        width: 6.875vw;
                        padding: 20px 0;
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: 10px;

                        &::after{
                            position: absolute;
                            content: ":";
                            font-size: 30px;
                            line-height: 1;
                            letter-spacing: -0.02em;
                            right: -11px;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                        &__numbers{
                            font-weight: 700;
                            font-size: 43px;
                            line-height: 52px;
                            letter-spacing: -0.02em;
                        }
                    }
                }
            }
        }

        &__bottom{
            position: absolute;
            width: 100%;
            bottom: 5vh;

            &__title{
                font-size: 1.5vw;
                line-height: 1;
                text-align: center;
                color: #FFFFFF;

                span{
                    position: relative;
                    font-weight: 700;
                    color: #fbff38;

                    &::after{
                        position: absolute;
                        width: 100%;
                        height: 6px;
                        content: '';
                        background: url('/static/deals/thanksgiving/line.svg') no-repeat;
                        background-size: 100%;
                        bottom: -8px;
                        left: 0;
                    }
                }

                &.cn__blackfriday__hero__bottom__title--after{
                    padding: 0 20%;
                }
            }
        }
    }

    &__before__info {
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto 0;
        //Font
        font-size: 22px;
        line-height: 1.7;
        letter-spacing: -0.01em;
        color: #000000;
        span{
            font-weight: 700;
        }
    }

    &__after__title{
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto 0;
        //Font
        font-weight: 800;
        font-size: 32px;
        line-height: 46px;
        letter-spacing: -0.025em;
        color: #000000;
    }

    &__after__subtitle{
        position: relative;
        width: calc(100% - 300px);
        margin: 15px auto 0;
        //Font
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: rgba(36, 37, 38, 0.4);
    }

    &__offer1 {
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;

        .cn__slider{
            margin-top: 25px;

            .cn__card7{
                height: calc(var(--card-width) * 1.3);
                .cn__card7__info{
                    top: unset;
                    transform: none;
                    bottom: 10%;
                }
            }
        }

        &.cn__blackfriday__offer1--before{
            margin: 33px auto 100px;
        }

        &.cn__blackfriday__offer1--after{
            margin: 42px auto 100px;
        }

        &__cards{
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4,minmax(0,1fr));
            grid-gap: 20px;
            margin-top: 40px;
        }
    }

    &__subscribe{
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;
    }

    .cn__faq{
        width: calc(100% - 300px);
        margin: 100px auto;
    }

    &__breadcrumbs{
        position: relative;
        width: calc(100% - 300px);
        margin: 100px auto;
        display: flex;

        a{
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            text-decoration: none;
            color: #797882;
        }
    }

    .cn__shortfooter__wrapper{
        .cn__shortfooter{
            padding: 0 150px 30px;
            max-width: unset;
        }
    }
    //Cookie banner
    .cn__cookie-wrapper{
        left: 50%;
        margin-left: -570px;
        bottom: 16px;
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }

}
.cn__blackfriday__banner{
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    background: #000000;
    color: #FFFFFF;
    display: grid;
    place-items: center;
    padding-bottom: 3vw;

    @media screen and (min-width:1920px){
        padding-bottom: 50px;
    }

    &__wrapper{
        position: relative;
        width: 100%;

    }

    &__sec1{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left, &__right{
            position: relative;
            width: 50%;
        }

        &__left{
            position: relative;
            width: 50%;
            &__img{
                position: relative;
                width: 100%;
            }
            &__subtitle{
                font-weight: 600;
                font-size: 1.3vw;
                line-height: 1;
                text-align: center;
                letter-spacing: -0.02em;
                margin-top: -8%;
                z-index: 2;

                @media screen and (min-width:1920px){
                    font-size: 22px;
                }

            }
        }

        &__right{
            padding-right: 60px;
            top: 6vh;

            @media screen and (min-width:1920px){
                top: 2vh;
            }

            &__title{
                font-size: 1vw;
                line-height: 1;

                @media screen and (min-width:1920px){
                    font-size: 16px;
                }
            }
            &__subtitle{
                font-size: 1vw;
                line-height: 1;
                text-align: right;
                margin-top: 21px;

                @media screen and (min-width:1920px){
                    font-size: 16px;
                }
            }
            &__times{
                position: relative;
                display: grid;
                grid-template-columns: repeat(4,minmax(0,1fr));
                gap: 18px;
                margin-top: 21px;

                @supports not (gap: 1em) {
                    margin: -9px;

                    & > * {
                      margin: 9px;
                    }
                }

                &__box{
                    position: relative;
                    padding: 3vh 0;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 10px;
                    display: grid;
                    place-items: center;

                    @media screen and (min-width:1920px){
                        padding: 20px 0;
                    }

                    &::after{
                        position: absolute;
                        content: ":";
                        font-size: 30px;
                        line-height: 1;
                        letter-spacing: -0.02em;
                        right: -11px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                    &__day{
                        font-size: 1.1vw;
                        margin-top: 0.1vh;

                        @media screen and (min-width:1920px){
                            font-size: 16px;
                        }
                    }

                    &__numbers{
                        font-weight: 700;
                        font-size: 3vw !important;
                        line-height: 1;
                        letter-spacing: -0.02em;

                        @media screen and (min-width:1920px){
                            font-size: 43px !important;
                        }
                    }
                }
            }
        }
    }

    &__sec2{
        position: relative;
        width: 100%;
        margin-top: 6vh;
        padding: 0 60px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @media screen and (min-width:1920px){
            margin-top: 57px;
        }

        &__info{
            font-size: 1.4vw;
            line-height: 1.7;
            text-align: center;

            @media screen and (min-width:1920px){
                font-size: 27px;
            }

            span{
                position: relative;
                font-weight: 700;
                color: #fbff38;

                &::after{
                    position: absolute;
                    width: 100%;
                    height: 6px;
                    content: '';
                    background: url('/static/deals/thanksgiving/line.svg') no-repeat;
                    background-size: 100%;
                    bottom: -8px;
                    left: 0;
                }
            }

        }
        a{
            flex-shrink: 0;
        }
        button{
            position: relative;
            font-family: $primary-font;
            flex-shrink: 0;
            width: auto;
            height: auto;
            padding: 10px 20px 8px 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            background: linear-gradient(92.14deg, #7804DD 1.79%, #A257EF 99.81%);
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            margin-left: 30px;
            p{
                position: relative;
                top: -1px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__blackfriday{

        .header{
            .max-width-wrapper{
                width: 100%;
            }
        }

        &__hero{
            &__top{
                width: 100%;
                padding: 0 30px;

                &__title{
                    width: 80%;
                }

                &__subtitle{
                    margin-top: -9%;
                }
                &__counter{
                    &__times{
                        &__box{
                            width: 13.875vw;
                        }
                    }
                }
            }
            &__bottom{
                bottom: 12vh;
                &__title{
                    font-size: 2vw;
                    line-height: 1.7;
                }
            }
        }

        &__before__info {
            width: 100%;
            padding: 0 30px;
        }

        &__after__title{
            width: 100%;
            padding: 0 30px;
        }

        &__after__subtitle{
            width: 100%;
            padding: 0 30px;
        }

        &__offer1 {
            position: relative;
            width: 100%;
            margin: 100px auto;

            .cn__title, .cn__subtitle{
                padding: 0 30px;
            }

            .cn__slider{
                .cn__card7{
                    height: calc(var(--card-width) * 1.2);
                }
            }

        }

        .cn__faq{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        &__subscribe{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        &__breadcrumbs{
            width: calc(100% - 60px);
            margin: 100px auto;
        }

        .cn__shortfooter__wrapper{
            .cn__shortfooter{
                padding: 0 30px 30px;
            }
        }

    }
    .cn__blackfriday__banner{

        &__sec1{

            &__left{
                position: relative;
                width: 60%;
                &__subtitle{
                    font-size: 1.7vw;
                    margin-top: -10%;
                }
            }

            &__right{
                top: 5vh;
                margin-top: 0;
                width: 40%;
                padding-right: 40px;
                &__title{
                    font-size: 1.2vw;
                }
                &__subtitle{
                    font-size: 1.2vw;
                    margin-top: 12px;
                    text-align: left;
                }
                &__times{
                    gap: 10px;
                    margin-top: 12px;

                    @supports not (gap: 1em) {
                        margin: -6px;

                        & > * {
                          margin: 6px;
                        }
                    }

                    &__box{
                        padding: 1.3vh 0;

                        &::after{
                            font-size: 2vw;
                            line-height: 1;
                            right: -6px;
                        }

                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &__sec2{
            width: 100%;
            margin-top: 3vh;
            padding: 0 40px;

            &__info{
                font-size: 1.9vw;
                text-align: left;
            }
            button{
                font-size: 12px;
                line-height: 1;
                p{
                    top: 0;
                }
                svg{
                    height: 16px;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__blackfriday{

        &__hero{

            .cn__image-picture{
                transform: none;
                .cn__image-default{
                    object-position: 21%;
                }
            }

            &__top{
                top: 40%;
                padding: 0 20px;

                &__title{
                    width: 100%;
                }

                &__subtitle{
                    font-size: 3.5vw;
                    line-height: 1.2;
                    margin-top: -15%;
                }
                &__counter{
                    margin-top: 33vw;

                    &__times{
                        gap: 14px;

                        @supports not (gap: 1em) {
                            margin: -7px;

                            & > * {
                              margin: 7px;
                            }
                        }

                        &__box{
                            position: relative;
                            width: 22vw;
                            padding: 2vh 0;
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 10px;
                            font-size: 3vw;

                            &::after{
                                position: absolute;
                                content: ":";
                                font-size: 22px;
                                right: -9px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &__numbers{
                                font-size: 8.8vw;
                                line-height: 1;
                            }
                        }
                    }
                }
            }

            &__bottom{
                bottom: 8vw;
                &__title{
                    font-size: 3.8vw;
                    line-height: 1.2;
                    padding: 0 40px;
                    span{
                        display: inline-block;
                        &::after{
                            display: none;
                        }
                    }
                }
            }

        }

        &__before__info {
            margin: 40px auto;
            padding: 0 20px;
            //font
            font-size: 14px;
            letter-spacing: -0.01em;
        }

        &__after__title{
            margin: 40px auto 0;
            padding: 0 20px;
        }

        &__after__subtitle{
            padding: 0 20px;
        }

        &__offer1 {
            margin: 60px auto;

            .cn__title, .cn__subtitle{
                padding: 0 20px;
            }

            .cn__slider{
                .cn__slider__cards .cn__card{
                    width: calc(100vw - 105px);
                }

                .cn__card7{
                    height: calc(var(--card-width) * 0.8);
                }
            }
        }

        .cn__faq{
            width: calc(100% - 40px);
            margin: 60px auto;
        }

        &__subscribe{
            width: calc(100% - 40px);
            margin: 60px auto;
        }

        &__breadcrumbs{
            width: calc(100% - 40px);
            margin: 60px auto;

            a{
                font-size: 14px;
            }
        }

        .cn__shortfooter__wrapper{
            .cn__shortfooter{
                padding: 0 20px 30px;
            }
        }

    }
    .cn__blackfriday__banner{
        height: auto;
        max-height: unset;
        background: #1D1C1F;
        border-radius: 10px;

        &__wrapper{
            top: 0;
        }

        &__sec1{
            flex-direction: column;
            padding-bottom: 6vh;

            &__left{
                top: -3vh;
                width: 100%;

                &__subtitle{
                    display: none;
                }
            }

            &__right{
                top: unset;
                width: 100%;
                padding-right: 0;
                margin-top: -8vh;

                &__title{
                    font-size: 3.7vw;
                    text-align: center;
                }
                &__subtitle{
                    display: none;
                }
                &__times{
                    width: 70%;
                    margin: 0 auto;
                    gap: 7px;
                    margin-top: 12px;

                    @supports not (gap: 1em) {
                        margin: -3.5px;

                        & > * {
                          margin: 3.5px;
                        }
                    }

                    &__box{
                        padding: 2.5vh 0;

                        &::after{
                            font-size: 3vw;
                            line-height: 1;
                            right: -4px;
                        }

                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }

                        &__day{
                            font-size: 2vw;
                            margin-top: 0.1vh;
                        }

                        &__numbers{
                            font-size: 6vw !important;
                        }
                    }
                }
            }
        }

        &__sec2{
            display: none;
        }

        svg{
            position: absolute;
            right: 9px;
            top: 8px;
            z-index: 3;
        }
    }
}