@import '../settings/variables.scss';

nav ul {
    list-style:none;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
    padding:0;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    text-decoration: none;
    outline: 0;

    &:hover {
        text-decoration: none;
    }
}

ins {
    background-color:$color-light-gray;
    color:#000;
    text-decoration:none;
}


mark {
    background-color:$color-light-gray;
    color:#000; 
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

hr {
    display:block;
    height:1px;
    border:0;   
    border-top:1px solid $color-subtle-gray;
    margin:1em 0;
    padding:0;
}

input {
    height: 38px;
    @include body-text();
    border:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 38px;
    width: 100%;
    border: none;
    padding: 0 20px;
    &focus{
        outline: none;
    }
}

select::-ms-expand {
    display: none;
}