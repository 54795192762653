.cn__button{
    position: relative;
    width: auto;
    height: auto;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    &__title{
        font-family: $primary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #FFFFFF;
        margin-left: 10px;
    }
    &.cn__button__linear{
        background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
    }
    &.cn__button__linearnew{
        background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
    }
    &.cn__button__grey{
        background: #E9E9EB;
        .cn__button__title{
            color: #242526;
        }
    }
}