//---------MOBILE---------
.cn__cnt__welcome {
  width: 100%;
  padding-top: 100px;

  &__main-section {
    text-align: center;

    &__heading {
      background: linear-gradient(to left,#F53959,#F35597);
      background-size: 100% 200%;
      background-position: 50% 100%;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: left;
      padding: 0 20px;
      font-weight: 700;
      line-height: 40px;
      font-size: 36px;
      letter-spacing: -0.02em;
    }

    &__description {
      margin-top: 15px;
      padding: 0 20px;
      font-weight: 500;
      text-align: left;
      letter-spacing: -0.01em;
      color: rgba(29, 28, 31, 0.45);
      max-width: 790px;
      font-size: 19px;
      line-height: 23px;
      letter-spacing: -0.01em;
    }

    &__heighlighted-text {
      font-weight: 500;
      font-size: 19px;
      line-height: 23px;
      letter-spacing: -0.01em;
      background: linear-gradient(to left,#F53959,#F35597);
      background-size: 100% 200%;
      background-position: 50% 100%;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width: 601px) {
  .cn__cnt__welcome {
    max-width: 790px;
    margin: 0 auto;
    padding-top: 200px;

    &__main-section {
      text-align: center;

      &__heading {
        text-align: center;
        padding: 0 30px;
        font-size: 64px;
        line-height: 68px;
        letter-spacing: -0.03em;
        margin: 0 auto;
      }

      &__description {
        margin-top: 25px;
        padding: 0 30px;
        text-align: center;
        letter-spacing: -0.01em;
        max-width: 790px;
        font-size: 22px;
        line-height: 28px;
      }

      &__heighlighted-text {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.01em;
      }
    }
  }
}


