.search-results-page{
    .MuiBackdrop-root{
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.MuiDrawer-root {
    & + .cn__portal {
        z-index: 1300;
    }
    .filters-side-bar {
        display: grid;
        .filters-side-bar_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .filters-side-bar_close-action_wrapper {
              position: initial;
              width: auto;
              height: auto;
              margin-top: rem(20);
              margin-right: rem(20);
              .filters-side-bar_close-action {
                position: inherit;
                transform: none;
              }
              .filters-side-bar_close-action_decor {
                display: none;
              }
            }
            .filters-side-bar_logo {
              visibility: initial;
              opacity: initial;
            }
        }
        .filters-side-bar_inline {
            width: 95%;
            float: none;
            grid-template-columns: 34% auto auto;
            margin-top: rem(20);
            margin-right: initial;
            justify-self: center;
            max-height: 40px;

            .search-field_wrapper {
                .autocomplete-dropdown-container{
                    min-width: rem(393);
                    width: 100%;
                }
            }
            .DateRangePicker_picker {
                position: fixed;
                top: 126px !important;
                left: 13px !important;

                @include tablet{
                    top: 136px !important;
                }
            }
        }
    }
}
.filters-side-bar {
    position: relative;
    &.filters-side-bar__animatable {
        transition: .3s ease-out;
    }
    z-index: 2;
    width: rem(414);
    // overflow: hidden;
    height: 100vh;
    min-height: rem(600);
    .filters-side-bar_close-action_wrapper {
        position: absolute;
        right: rem(-87);
        top: rem(5);
        background-color: $color-white;
        color: $color-black;
        font-weight: 500;
        font-family: $primary-font;
        text-transform: capitalize;
        font-size: rem(16);
        line-height: rem(20);
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(55);
        height: rem(55);
        border: none;
        border-top-right-radius: rem(20);
        border-bottom-right-radius: rem(20);
        outline: 0;
        // box-shadow: rem(9) rem(-9) 0.875rem 0 rgba(24, 39, 75, .12);
        @include tablet {
            box-shadow: none;
        }
        .filters-side-bar_close-action {
            font-family: $primary-font !important;
            font-size: rem(16);
            position: absolute;
            right: 3px;
            padding: 15px 14.5px;
            border: rem(1) solid $color-prussian-blue;
            display: inherit;
            align-items: inherit;
            justify-content: inherit;
            border-radius: rem(12);
            cursor: pointer;
            background-color: $color-white;
            transform: translateX(-20px) translateY(-2px);
        }
        .filters-side-bar_close-action_icon {
            transform: rotate(90deg);
            //margin-right: rem(15);
            width: 12px;
            margin: 0;
            position: relative;
            left: -1px;
            path {
                stroke: $color-black;
                stroke-width: 2px;
            }
        }
        .filters-side-bar_close-action_decor {
            position: absolute;
            bottom: rem(-48);
            left: 0;
            display: none;
        }
    }
    .filters-side-bar_logo {
        transition: .3s ease-in-out;
        visibility: hidden;
        opacity: 0;
        .header-logo {
            position: relative;
            top: 10px;
            width: rem(170);
            margin-left: rem(20);
            margin-top: rem(15);
            .cnword {
                display: none;
            }
            .logo-purple {
                fill: $color-bright-purple;
            }
            .logo-pink {
                fill: $color-primary;
            }
        }
    }

    // @include tabs;
    // .tabs {
    //     max-width: rem(343);
    //     margin-top: rem(50);
    //     margin-left: rem(10);
    //     padding: 0;
    // }

    // .filters-date-picker_wrapper {
    //     margin-top: rem(-1);
    //     height: rem(1);
    //     flex: 1;
    //     margin-left: rem(10);
    // }

    .filters-side-bar_inline {
        // max-width: rem(353);
        //margin-left: rem(20);
        //margin-right: rem(20);
        //margin-top: rem(10);
        /* display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        padding-bottom: rem(10); */
        //width: calc(100% - 2.5rem);
        //margin-left: 1.25rem;
        //display: flex;
        //flex-flow: wrap;
        position: relative;
        // width: calc(100% - 80px);
        display: grid;
        //grid-template-columns: 1fr max-content max-content;
        grid-template-columns: 1fr 117px 93px;
        border: 1px solid #DFE2E5;
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
        border-radius: 34px;
        margin-top: rem(11);
        margin-left: rem(20);
        margin-right: rem(1);
        // You motherficking piece of crap
        // float: right;
        // margin-top: -35px;
        // margin-right: 2px;
        //float: right;

        @include search;
        .search-field_wrapper {
            /* grid-column: 1 / span 3; */
            // width: rem(341);
            /* width: 100%;
            flex-shrink: 0;
            margin-top: 1.5625rem; */
            // margin-left: rem(12);
            /* border-bottom: rem(1) solid $color-shade-gray;; */
            .search-icon {
                left: rem(8);
                width: 14px;
                height: 14px;
                &.search-icon__highlighted {
                    path {
                        fill: none;
                    }
                }
            }
            .search-field.cn-autoComplete {
                .cn-input-group {
                    position: relative;
                    &::after{
                        position: absolute;
                        width: 1px;
                        height: 22px;
                        background: #DADBDB;
                        content: '';
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .primary-input.autoCompleteSearch {
                        border: none;
                        //background-color: $color-field-grey;
                        //padding-left: rem(45);
                        padding-right: rem(8);
                        background-color: transparent;
                        border-radius: 34px;
                        box-shadow: none !important;
                        padding-left: 25px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 13px;
                        /* border-radius: initial;
                        border-top-left-radius: rem(15);
                        border-top-right-radius: rem(15); */
                        box-shadow: none;
                        &:hover {
                            background-color: transparent;
                            cursor: pointer;
                            box-shadow: none
                        }
                        &:focus {
                            border: none;
                            background-color: transparent;
                            box-shadow: none
                        }
                    }
                }
            }
            .cn__autocomplete-suggestions{
                position: absolute;
                width: 333px;
                margin-top: 10px;
            }
        }

        @include guestSelector;
        .guest-selector {
            // border-radius: 0.375rem;
            padding: 0 12px 0 10px;
            height: rem(38);
            box-shadow: none;
            border-radius: unset;
            width: 100%;
            background-color: transparent;
            justify-content: center;

            .carret-icon {
                display: none;
            }
            &:hover:not(.guest-selector__active) {
                background-color: transparent;
            }
            &.guest-selector__active {
                background-color: transparent;
                box-shadow: none;
            }
            .filters-guest-selector_mobile-icon{
                display: none
            }
            .guest-selector_track{
                font-size: 13px !important;
                font-weight: 400 !important;
                margin-left: 0;
            }

            &__guests{
                position: relative;
                display: flex;
                align-items: center;
                margin-right: 5px;
                font-size: 13px;
                color: $color-black;
                svg{
                    flex-shrink: 0;
                    margin-right: 7px;
                }
            }
            &__rooms{
                position: relative;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: $color-black;
                svg{
                    flex-shrink: 0;
                    margin-right: 2px;
                }
            }
        }

        @include datePicker;
        .filters-date-picker {
           /*  border-radius: initial;
            border-bottom-left-radius: rem(15);
            box-shadow: none;
            background-color: $color-white;
            border-right: rem(1) solid $color-shade-gray;
            width: 50% !important;
            flex-shrink: 0; */
            border-radius: unset;
            box-shadow: none;
            background-color: transparent !important;
            width: 100% !important;
            cursor: pointer;
            display: grid;

            .filters-date-picker_icon{
                display: none;
            }
            &.date-picker__active {
                background-color: transparent;
                border-radius: unset;
                box-shadow: none;
            }
            &.date-picker__selected {
                &:hover:not(.date-picker__active) {
                    background-color: transparent;
                    border-radius: unset;
                    box-shadow: none;
                }
                // box-shadow: none;
                //width: rem(170);
                .DateInput:last-child {
                    margin-right: 0;
                }
            }

            .DateInput {
                width: rem(50)!important;
                &:first-child {
                    margin-right: rem(10);
                }
                &:last-child {
                    margin-left: rem(10);
                }
            }
            .DateInput_input{
                font-size: 13px !important;
                font-weight: 400 !important;
            }

            .DateRangePickerInput{
                position: relative;
                &::after{
                    position: absolute;
                    width: 1px;
                    height: 22px;
                    background: #DADBDB;
                    content: '';
                    right: -3px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .DateRangePickerInput_arrow{
                background: url(/static/icon/dash.svg) no-repeat center center;
                width: 8px;
            }
        }

        &.filters-side-bar_inline_search{
            grid-template-columns: 1fr;
            .search-field_wrapper{
                .cn-input-group::after{
                    display: none;
                }
            }
            .guest-selector{display: none;}
            .filters-date-picker{display: none;}
        }
    }

    .amenities-collection {
        // display: flex;
        // flex-wrap: wrap;
        // margin: rem(10) rem(20) rem(20) rem(20);
        margin-left: rem(20);
        margin-right: rem(20);
        margin-bottom: rem(20);
        .swiper-container.swiper-container-horizontal {
            .swiper-button-next,
            .swiper-button-prev {
                color: $color-black;
                outline: 0;

                &::after {
                    margin-top: rem(5);
                    font-weight: bold;
                    font-size: initial;
                    background: $color-light-gray-shade;
                    box-shadow: 0 0 rem(20) rem(30) $color-light-gray-shade;
                }

                &.swiper-button-disabled {
                    opacity: 0;
                    color: $color-black;
                }
            }

            .swiper-button-prev {
                transform: translateX(-12px);
            }

            .swiper-button-next {
                transform: translateX(12px);
            }
            .swiper-wrapper {
                .swiper-slide {
                    width: fit-content;
                }
            }
        }
    }
    .filters-side-bar_container {
        margin-top: rem(10);
        overflow: hidden;
        width: inherit;
    }
    .filters-side-bar_wrapper {
        // width: inherit;
        background-color: $color-white;
        //padding-bottom: rem(10);
        font-family: $primary-font;
        // height: 100vh;
        // min-height: inherit; 186px
        max-height: calc(100vh - 94px);
        min-height: calc(100vh - 94px);
        overflow-y: auto;
        // padding-right: rem(17);
        padding: 0 16px 10px 22px;
        box-sizing: content-box;
        width: calc(100% - 50px);

        @media (min-width: 601px) {
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: var(--color-shade-gray);
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--color-subtle-grey);
                border-radius: 2px;
            }
        }

        .drop-down-collection {
            overflow-x: hidden;
            min-height: calc(100vh - 94px);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            //margin-left: rem(10);
            .drop-down {
                //margin-bottom: rem(10);
                animation: appear .3s ease-in-out forwards;
                .drop-down_toggle {
                    //padding-left: rem(10);
                    padding: 13px 0;
                    max-width: 100%;

                    .carret-icon{
                        path{
                            stroke: #ACABB4;
                        }
                    }

                    &:hover{
                        background-color: transparent;
                    }
                }
                .outer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //padding: rem(7) rem(10);
                    padding: 0;
                    &.outer__active {
                        background-color: $color-champagne-pink;
                        border-radius: rem(33);
                        padding: 9px 2px 9px 9px;
                        .outer_info-container {
                            .clearable-tag {
                                background-color: $color-champagne-pink;
                            }
                        }
                        .outer_icon{
                            path{
                                fill: $color-black;
                            }
                        }
                    }
                    .outer_icon {
                        margin-right: rem(10);
                        width: rem(24);
                        &.neighborhood-icon {
                            * {
                                stroke: $color-dark-grey;
                            }
                        }
                        &.hotel-chain-icon {
                            path {
                                fill: $color-slate-grey;
                            }
                        }
                    }
                    .sort-outer {
                        svg {
                            display: none;
                        }
                    }
                }
                .outer__active {
                    font-weight: 600;
                    .outer_info-container {
                        display: flex;
                        align-items: center;
                        .outer_info-content {
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: rem(230);
                            text-overflow: ellipsis;
                        }
                        @include clearableTag;
                    }
                }
                &:not(:first-child) {
                    //margin-top: rem(10);
                }

                .inner-wrapper-layout {
                    .filters-progressive-wrapper {
                        .category-separator {
                            border-top: rem(1) solid $color-light-gray-input;
                        }
                    }
                    .inner-wrapper-layout_content-area {
                        padding: rem(10);
                        .bathrooms-and-beds-type-inner {
                            .bathrooms-and-beds-type-inner__section-checkboxes {
                                .checkbox {
                                    &:not(:last-child) {
                                        margin-bottom: 0;
                                    }
                                    .checkbox_label {
                                        .checkbox_checkmark {
                                            order: initial;
                                        }
                                        .checkbox_label-text {
                                            padding-right: 0;
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                        .services-inner {
                            .services_checkbox {
                                &.services_checkbox__ungroupped {
                                    .checkbox_label {
                                        padding-left: rem(10);
                                    }
                                }
                            }
                        }
                        .services-inner_category, .amenities-inner_category {
                            &:not(:first-child), &:first-child {
                                margin-top: rem(20);
                                .services_checkbox, .amenities_checkbox {
                                    &:first-child {
                                        margin-top: rem(10);
                                    }
                                }
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                            .category_title {
                                padding-left: rem(10);
                                font-size: rem(14);
                            }
                            .services_checkbox, .amenities_checkbox {
                                &:first-child {
                                    margin-top: rem(20);
                                }
                                // .checkbox_label {
                                //     padding-left: 0;
                                // }
                            }
                        }
                        .amenities-inner_category {
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                        .budget-inner {
                            .price-widget {
                                &.price-widget__set-own-active {
                                    .price-widget_slider-container {
                                        position: relative;
                                        margin-left: 40px;
                                        margin-right: 40px;
                                        height: rem(120);
                                        opacity: 1;
                                        transition: height .3s ease-in-out, opacity .3s .3s ease-in-out;
                                    }
                                    .price-widget_slots {
                                        height: 0;
                                        opacity: 0;
                                        transition: opacity .3s ease-in-out, height .3s .3s ease-in-out;
                                    }
                                }
                                .price-widget_title {
                                    font-size: rem(14);
                                    color: $color-black;
                                    text-align: center;
                                }
                                .price-widget_slider-container {
                                    position: relative;
                                    margin-left: 40px;
                                    margin-right: 40px;
                                    height: 0;
                                    opacity: 0;
                                    transition: opacity .3s ease-in-out, height .3s .3s ease-in-out;
                                }
                                .price-widget_slider-wrapper {
                                    display: flex;
                                    align-items: flex-end;
                                    padding-top: rem(100);
                                    // padding-left: rem(40);
                                    // padding-right: rem(40);
                                    .price-widget_slider {
                                        .price-widget_slider-track {
                                            background: $color-light-gray-input;
                                            border-radius: rem(1);
                                            height: rem(2);
                                            top: rem(10);
                                            &.price-widget_slider-track-1 {
                                                background: $color-bright-purple;
                                            }
                                        }
                                        .price-widget_slider-thumb {
                                            border-radius: 50%;
                                            width: rem(20);
                                            height: rem(20);
                                            background: $color-bright-purple;
                                            border: rem(2) solid $color-white;
                                            outline: none;
                                            transition: width .2s ease-in-out, height .2s ease-in-out, transform .2s ease-in-out;
                                            &.price-widget_slider-thumb__active, &:hover {
                                                width: rem(25);
                                                height: rem(25);
                                                transform: translateY(calc(50% - 15px));
                                            }
                                        }
                                    }
                                }
                                .price-widget_toggle {
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;
                                    color: $color-black;
                                    font-size: rem(14);
                                }
                                .price-widget_slots {
                                    height: rem(200);
                                    opacity: 1;
                                    transition: height .3s ease-in-out, opacity .3s .3s ease-in-out;
                                    overflow: hidden;
                                }
                                .price-widget_inputs {
                                    display: flex;
                                    justify-content: center;
                                    margin-bottom: rem(25);
                                    .price-widget_input-wrapper {
                                        position: relative;
                                        &:last-child {
                                            margin-left: rem(20);
                                        }
                                        .price-widget_input-price {
                                            position: absolute;
                                            left: 5%;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            z-index: 1;
                                        }
                                        .floating-input_input {
                                            padding-left: 30%;
                                        }
                                    }
                                }
                            }
                        }
                        .star-rating-inner {
                            // .checkbox:first-child {
                            //     .checkbox_label {
                            //         padding-top: 0;
                            //     }
                            // }
                            .checkbox_label {
                                width: 100%;
                            }
                            .checkbox_label-text {
                                display: grid;
                                width: 100%;
                                grid-template-columns: 80px 1fr;
                                align-items: center;
                            }
                            .star-rating_stars {
                                display: flex;
                            }
                            .star-rating-inner_icon {
                                path {
                                    fill: $color-black;
                                    fill-opacity: 1;
                                }
                            }
                            .star-rating_text {
                                display: inline-block;
                                margin-right: rem(10);
                            }
                        }
                        .accommodation-type-inner, .brand-name-type-inner {
                            .checkbox_label {
                                align-items: flex-start;
                                .accommodation-type_label-secondary {
                                    color: $color-gray-font;
                                    font-size: .7rem;
                                }
                            }
                        }
                        .cancellation-policy-inner {
                            .cancellation-policy_checkbox {
                                .checkbox_label {
                                    padding-right: 0;
                                    .cancellation-policy_more-info-icon {
                                        margin-left: rem(10);
                                    }
                                }
                            }
                        }
                        .show-more-less {
                            @media (min-width: 1010px) {
                                min-width: initial;
                            }
                            max-height: calc(100vh - 400px);
                            .show-more-less_wrapper {
                                display: block;
                            }
                            .simplebar-scrollbar::before {
                                width: rem(4);
                                background: $color-subtle-grey;
                            }

                            .show-more-less_action-container {
                                padding-left: rem(14);
                                padding-right: initial;
                            }

                            .neighbourhood-inner_loader {
                                margin-bottom: rem(12);
                                display: grid;
                                grid-template-columns: max-content 1fr;
                                column-gap: rem(15);
                                .neighbourhood-inner_loader-particle {
                                    &:last-child {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .checkbox {
                            border-radius: 1.125rem;
                            // padding-left: 0.625rem;
                            // padding-right: 0.625rem;

                            &:not(:last-child) {
                                margin-bottom: rem(4);
                            }
                            .checkbox_label {
                                width: 100%;
                                padding-left: unset;
                                padding-right: rem(15);

                                .checkbox_checkmark{
                                    order: 2;
                                }

                                .checkbox_label-text {
                                    display: grid;
                                    width: 100%;
                                    margin-left: 0;
                                    grid-template-columns: 1fr auto;
                                    padding-inline-end: 10px;

                                    div:nth-child(2){
                                        margin-left: rem(10);
                                    }
                                    .checkbox_label-text_count {
                                        margin-left: rem(10);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .drop-down-collection_separator {
                border-bottom: rem(1) solid $color-light-gray-input;

                &:last-child{
                    display: none;
                }
            }
        }

        @include tablet {
            max-height: calc(100vh - 180px);
            min-height: calc(100vh - 180px);

            .drop-down-collection {
                min-height: calc(100vh - 180px);
            }
        }
    }
}

/*.filters-date-picker_outside-container {
    z-index: 1;

    @include datePicker;
    .filters-date-picker {
        cursor: pointer;
        &.date-picker__selected {
            width: rem(170);
            .DateInput:last-child {
                margin-right: 0;
            }
        }

        .DateInput {
            width: rem(75);
            &:first-child {
                margin-right: rem(10);
            }
            &:last-child {
                margin-left: rem(10);
            }
        }
    }
} */

.cn-autocomplete-items_popover {
    width: rem(341);
}
