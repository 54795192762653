@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp8{
    position: relative;
    width: 100%;
    background: var(--color-background);
    padding: dvw(100) 0;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__box{
        position: relative;
        display: grid;
        grid-template-columns: 51% 49%;

        &__sec1{
            position: relative;
            &__info{
                position: sticky;
                top: 32vh;

                &__title{
                    font-weight: 700;
                    font-size: dvw(54);
                    line-height: 1.1;
                    color: #000000;
                    max-width: dvw(398);
                }

                &__subtitle{
                    font-size: dvw(23);
                    line-height: 1;
                    margin-top: dvw(50);
                }
            }
        }
        &__sec2{
            position: relative;
            display: grid;
            grid-template-columns: repeat(1,minmax(0,1fr));
            gap: dvw(20);

            &__card{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #64748B;
                background: #FFFFFF;
                border-radius: dvw(15);
                padding: dvw(50);
                transition: all 0.3s ease;
                cursor: pointer;

                &__number{
                    font-size: dvw(90);
                    font-weight: bold;
                    line-height: 1;
                    
                    opacity: 0.2;
                }

                &__info{
                    padding-left: dvw(59);

                    &__title{
                        font-weight: 600;
                        font-size:dvw(28);
                        line-height: 1.2;
                    }
    
                    &__subtitle{
                        font-size: dvw(16);
                        line-height: 1.5;
                        letter-spacing: 0.01em;
                        margin-top: dvw(10);
                    }
                }

                &:hover{
                    box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25);
                    transform: translateY(-5px);
                }
            }
        }
    }
}

@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp8{
        padding: 150px 0;
    
        &__box{
    
            &__sec1{
                &__info{
                    top: 32vh;
    
                    &__title{
                        font-size: 54px;
                        max-width: 398px;
                    }
    
                    &__subtitle{
                        font-size: 23px;
                        margin-top: 50px;
                    }
                }
            }
            &__sec2{
                gap: 20px;
    
                &__card{
                    padding: 50px;
    
                    &__number{
                        font-size: 90px;
                    }
    
                    &__info{
                        padding-left: 59px;
    
                        &__title{
                            font-size: 28px;
                        }
        
                        &__subtitle{
                            font-size: 16px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp8{
        padding: 9vw 0 16vw;

        &__wrapper{
            padding: 0 30px;
        }

        &__box{

            &__sec1{
                position: relative;
                &__info{
                    position: sticky;
                    top: 35vh;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp8{
        padding: mvw(60) 0;

        &__wrapper{
            padding: 0 20px;
        }

        &__box{
            position: relative;
            display: block;

            &__sec1{
                &__info{    
                    &__title{
                        font-size: mvw(32);
                        line-height: 1;
                        max-width: mvw(313);
                    }
                    &__subtitle{
                        font-size: mvw(16);
                        line-height: 1.5;
                        margin-top: mvw(20);
                        max-width: mvw(275);
                    }
                }
            }

            &__sec2{
                position: relative;
                display: grid;
                grid-template-columns: repeat(1,minmax(0,1fr));
                gap: 0;
                margin-top: mvw(20);
    
                &__card{
                    background: none;
                    box-shadow: unset;
                    border-radius: 0;
                    padding: mvw(40) 0;
                    align-items: flex-start;
    
                    &__number{
                        position: relative;
                        top: -2vw;
                        font-size: mvw(90);
                    }
    
                    &__info{
                        padding-left: mvw(22);
    
                        &__title{
                            font-size: mvw(23);
                        }
        
                        &__subtitle{
                            font-size: mvw(16);
                            margin-top: mvw(10);
                        }
                    }

                    &:hover{
                        box-shadow: none;
                        transform: none;
                    }
                }
            }
        }
    }
}