.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none; 
  font-size: 15px;
  line-height: 1em;
  &:before {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
  }
  &:after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }
  .drp-calendar {
    display: none;
    max-width: 270px;
  }
  .drp-calendar.left {
    padding: 8px 0 8px 8px;
  }
  .drp-calendar.right {
    padding: 8px;
  }
  .drp-calendar.single {
    .calendar-table {
      border: none;
    }
  }
  .calendar-table {
    .next {
      span {
        color: #fff;
        border: solid black;
        border-width: 0 2px 2px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
    .prev {
      span {
        color: #fff;
        border: solid black;
        border-width: 0 2px 2px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }
    th {
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      min-width: 32px;
      width: 32px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid transparent;
      white-space: nowrap;
      cursor: pointer;
    }
    td {
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      min-width: 32px;
      width: 32px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid transparent;
      white-space: nowrap;
      cursor: pointer;
    }
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
    table {
      width: 100%;
      margin: 0;
      border-spacing: 0;
      border-collapse: collapse;
    }
  }
  td.available {
    &:hover {
      background-color: #eee;
      border-color: transparent;
      color: inherit;
    }
  }
  th.available {
    &:hover {
      background-color: #eee;
      border-color: transparent;
      color: inherit;
    }
  }
  td.week {
    font-size: 80%;
    color: #ccc;
  }
  th.week {
    font-size: 80%;
    color: #ccc;
  }
  td.off {
    background-color: #fff;
    border-color: transparent;
    color: #999;
  }
  td.off.in-range {
    background-color: #fff;
    border-color: transparent;
    color: #999;
  }
  td.off.start-date {
    background-color: #fff;
    border-color: transparent;
    color: #999;
  }
  td.off.end-date {
    background-color: #fff;
    border-color: transparent;
    color: #999;
  }
  td.in-range {
    background-color: #ebf4f8;
    border-color: transparent;
    color: #000;
    border-radius: 0;
  }
  td.start-date {
    border-radius: 4px 0 0 4px;
  }
  td.end-date {
    border-radius: 0 4px 4px 0;
  }
  td.start-date.end-date {
    border-radius: 4px;
  }
  td.active {
    background-color: #357ebd;
    border-color: transparent;
    color: #fff;
    &:hover {
      background-color: #357ebd;
      border-color: transparent;
      color: #fff;
    }
  }
  th.month {
    width: auto;
  }
  td.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  option.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  select.monthselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
    margin-right: 2%;
    width: 56%;
  }
  select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
    width: 40%;
  }
  select.hourselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }
  select.minuteselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }
  select.secondselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }
  select.ampmselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
  }
  .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
    select.disabled {
      color: #ccc;
      cursor: not-allowed;
    }
  }
  .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    vertical-align: middle;
    .btn {
      margin-left: 8px;
      font-size: 12px;
      font-weight: bold;
      padding: 4px 8px;
    }
  }
  .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
  }
  .ranges {
    float: none;
    text-align: left;
    margin: 0;
    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      width: 100%;
    }
    li {
      font-size: 12px;
      padding: 8px 12px;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      background-color: #08c;
      color: #fff;
    }
  }
}
.daterangepicker.opensleft {
  &:before {
    right: 9px;
  }
  &:after {
    right: 10px;
  }
}
.daterangepicker.openscenter {
  &:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.daterangepicker.opensright {
  &:before {
    left: 9px;
  }
  &:after {
    left: 10px;
  }
}
.daterangepicker.drop-up {
  margin-top: -7px;
  &:before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
  }
  &:after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
  }
}
.daterangepicker.single {
  .daterangepicker {
    .ranges {
      float: none;
    }
  }
  .drp-calendar {
    float: none;
  }
  .drp-selected {
    display: none;
  }
}
.daterangepicker.show-calendar {
  .drp-calendar {
    display: block;
  }
  .drp-buttons {
    display: block;
  }
  .ranges {
    margin-top: 8px;
  }
}
.daterangepicker.auto-apply {
  .drp-buttons {
    display: none;
  }
}
.daterangepicker.show-ranges.single.rtl {
  .drp-calendar.left {
    border-right: 1px solid #ddd;
  }
}
.daterangepicker.show-ranges.single.ltr {
  .drp-calendar.left {
    border-left: 1px solid #ddd;
  }
}
.daterangepicker.show-ranges.rtl {
  .drp-calendar.right {
    border-right: 1px solid #ddd;
  }
}
.daterangepicker.show-ranges.ltr {
  .drp-calendar.left {
    border-left: 1px solid #ddd;
  }
}
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
    direction: ltr;
    text-align: left;
    .ranges {
      ul {
        width: 140px;
      }
      float: left;
    }
    .drp-calendar.left {
      clear: left;
      margin-right: 0;
      .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 8px;
      }
    }
    .drp-calendar.right {
      margin-left: 0;
      .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .drp-calendar {
      float: left;
    }
  }
  .daterangepicker.single {
    .ranges {
      ul {
        width: 100%;
      }
      float: left;
    }
    .drp-calendar.left {
      clear: none;
    }
    .drp-calendar {
      float: left;
    }
  }
}
@media (min-width: 730px) {
  .daterangepicker {
    .ranges {
      width: auto;
      float: left;
    }
    .drp-calendar.left {
      clear: none !important;
    }
  }
  .daterangepicker.rtl {
    .ranges {
      float: right;
    }
  }
}

.daterangepicker .calendar-table th {
  color: $color-dark; 
}
@media (min-width: 564px){
  .daterangepicker { 
      padding: 1.875rem;
  }
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td { 
  border: none; 
}
.daterangepicker .drp-selected  {
  display: none;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.9375rem; 
  font-weight: bold;
  padding: 0; 
  border: none;
  background: no-repeat;
}
.daterangepicker .drp-buttons .btn.cancelBtn {
  float: left;
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.daterangepicker .drp-buttons { 
  padding: 1.25rem 0 0; 
  border-top: 1px solid $color-light-gray; 
  @media screen  and (max-width: 600px) { 
    padding: 1.25rem; 
  }
}
.daterangepicker .drp-buttons .btn.btn-primary{
  font-weight: 500;
  color: $color-primary; 
  display: inline-block;
  min-width: auto;
  padding: 0;
  line-height: 0;
  height: auto;
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td{ 
  font-size: 	0.9375rem;
  line-height: 1.125rem;  
  color: $color-black;
  width: 1.75rem;
  height: 2rem;
  position: relative; 
  font-variant: small-caps;
  text-transform: lowercase;  
}
.daterangepicker .calendar-table th{
   color:$color-dark;
}
td.weekend.active.start-date.available {
  z-index: 1;
  position: relative;
}
.daterangepicker td.available:hover, 
.daterangepicker th.available:hover {
  background-color: $color-light-gray-DRhover;
  border-color: transparent; 
  color: inherit;
  border-radius: 100%;
}
.daterangepicker td.start-date.end-date { 
  background: none;
  color: $color-primary;
}
.daterangepicker .calendar-table th.month {
  font-weight: 500;
  font-variant: unset;
  text-transform: unset;
  color:$color-black;
} 
.daterangepicker {
  td.off,
  td.off.in-range,
  td.off.start-date,
  td.off.end-date {
    background-color: $color-white;
    border-color: transparent;
    color: $color-subtle-gray;
  }
}
.daterangepicker td.in-range {
  background-color: $color-light-gray-daterange;
  border-color: transparent;
  color: $color-black;
  border-radius: 0; 
}
.daterangepicker {
  td.active,
  td.active:hover { 
      background-color: $color-light-gray-daterange;
      border-color: transparent;
      color: $color-white;
      border-radius:0;
  }
}
.daterangepicker .calendar-table td.start-date  {
  border-bottom-left-radius: 100%;
  border-top-left-radius: 100%;
  z-index: 1;
}
.daterangepicker { 
  .calendar-table {
      td.start-date:before,
      td.today.start-date:before,
      td.end-date:before  { 
      background-color: $color-primary;
      width: 100%;
      height: 100%;
      content: ""; 
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 100%;
      z-index: -1;
      visibility: visible;
    }
  }
}
.daterangepicker .calendar-table td.today.start-date.end-date:before   {
  visibility: hidden;
}
.daterangepicker .calendar-table td.end-date  {
  border-bottom-right-radius: 100%;
  border-top-right-radius: 100%;
  z-index: 1;
}


.daterangepicker .calendar-table td.off.endse,
.daterangepicker .calendar-table td.off.ends:before {
  visibility: hidden;
}

.daterangepicker .drp-buttons .cancelBtn.btn-text-black {
  float: left;
}