@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------
.cn__careprogram__banner{
    position: relative;

    //-- Image
    .cn__image-default{
        object-position: 0 -15vh;
        filter: brightness(0.8);
    }

    //-- Button
    .cn__button{
        position: relative;
        margin: 8px auto 0;

        &__title{
            margin-left: 0;
        }
    }
}


//------------------- TABLET/MOBILE -------------------
@include tablet {
    .cn__careprogram__banner{

        //-- Image
        .cn__image-default{
            object-position: center center;
        }

        //-- Button
        .cn__button{
            width: 100%;
            margin: 12px auto 0;
        }

    }
}


//------------------- MOBILE -------------------
@include mobile {
    .cn__careprogram__banner{

        //-- Image
        .cn__image-default{
            filter: brightness(0.6);
        }

        //-- Button
        .cn__button{
            max-width: 197px;
            margin: 12px auto 0;
        }

    }
}