//------------------- DESKTOP -------------------

.cn__hp__seo{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;

    .cn__hp__heading {
        padding: 0px;
        margin-bottom: 32px;

        .cn__hp__heading__title {
            color: $color-black;
        }
    }

    &__options{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        &__option{
            position: relative;
            font-size: 16px;
            line-height: 24px;
            color: $color-black-gun-metal;
            cursor: pointer;
            margin-left: 20px;
            &:first-child{
                margin-left: 0;
            }
            &::after{
                position: absolute;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 0;
                bottom: -5px;
                height: 3px;
                background: #F35597;
                content: "";
                margin-top: 5px;
                -webkit-transition: width .3s ease;
                transition: width .3s ease;
            }
            &.cnhpseo--active{
                font-weight: 600;
                color: $color-black-gun-metal;
                &::after{
                    width: 20px;
                }
            }
        }
    }
    &__listing{
        position: relative;
        width: 100%;
        margin-top: 35px;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-gap: 16px;
        &__option{
            a{
                display: table;
                &:visited{
                    color: $color-gray-hp;
                }
            }
            &__title{
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                // padding-bottom: 3px;
                color: #1A1A1A;
                transition: color 0.3s ease;
                &:hover{
                    color: $color-black-gun-metal
                }
            }
            &__count{
                font-size: 15px;
                line-height: 1.5;
                font-weight: 400;
                color: $color-gray-hp;
                transition: color 0.3s ease;
                color: #595959;
                
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }  
    }
    
    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 10px 0;
            display: flex;
            width: 100%;
            overflow-x: unset;
            -ms-scroll-snap-type: unset;
            scroll-snap-type: unset;
            scroll-padding: unset;
        }  
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__seo{
        padding: 0 30px;
        margin: 60px auto;
        &__listing{
            grid-template-columns: repeat(4, minmax(0, 1fr));
            &__option{
                &__title{
                    &:hover{
                        color:$color-gray-hp;
                    }
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__seo{
        margin: 60px auto 74px auto;
        padding: 0 20px;
        .cn__hp__heading__title {
            // color: $color-black-gun-metal;
            line-height: 1.2;
        }
        &__options{
            [data-simplebar]{
                width: calc(100vw - 40px);
            }
            .slidermobile__wrapper{
                display: inline-flex!important;
                width: 100%;
                padding: 0 0 10px;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            .simplebar-track{
                display: none;
            }
            &__option{
                text-align: center;
            }
        }
        &__listing{
            margin-top: 16px;
            display: block;
            width: calc(100% + 20px);
            &__option{
                width: 150px;
            }
            .slidermobile__wrapper{
                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-auto-flow: column;
                grid-auto-columns: 1fr;        
                grid-gap: 16px;
            }
            .simplebar-track.simplebar-horizontal{
                background: #FFFFFF;
                visibility: visible !important;
                height: 24px;
                width: calc(100% - 20px);
                bottom: -14px;
                overflow: visible;
                pointer-events: all;
                cursor: pointer;
                z-index: 0;
                &::before{
                    position: absolute;
                    content: '';
                    width: calc(100% + 40px);
                    height: 100%;
                    left: -20px;
                    background: #FFFFFF;
                    z-index: -1;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    bottom: 1px;
                    background: #F4F4F5;
                    border-radius: 5px;
                    z-index: -1;
                }
                .simplebar-scrollbar{
                    width: 100%;
                    height: 4px;
                    top: auto;
                    bottom: 0;
                    border-radius: 5px;
                    background: #f466a2;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}