//---------MOBILE---------
.cn__cnt__stayswithviews{
    position: relative;
    margin: 0 auto;
    padding-top: 100px;
    width: 100%;
    &__title{
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #34AFED;
        padding: 0 20px;
    }
    &__subtitle{
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #1D1C1F;
        margin-top: 40px;
        padding: 0 20px;
    }
    &__description{
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.01em;
        color: rgba(29, 28, 31, 0.45);
        margin-top: 10px;
        padding: 0 20px;
    }
    &__slider{
        .slidermobile__wrapper{
            padding-top: 30px !important;
            .cnt__cardDef{
                width: 162px;
                height: 162px;
                &__info{
                    display: none;
                }
            }
        }
    }
    &__slider2{
        display: flex;
        flex-direction: column;
        padding: 50px 20px;
        margin-top: 55px;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        background: #D4EAF5;
        &__sec1{
            &__title{
                font-weight: 700;
                font-size: 28px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: #34AFED;
            }
            &__img{
                position: relative;
                margin-top: 15px;
                width: 100%;
                height: 273px;
                border-radius: 20px;
                overflow: hidden;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1.1);
                    transition: transform 1s ease;
                    filter: brightness(0.7);
                }
            }
        }
        &__sec2{
            position: relative;
            margin-top: 71px; 
            font-weight: 500;
            font-size: 17px;
            line-height: 21px;
            letter-spacing: -0.015em;
            color: rgba(29, 28, 31, 0.45);
            p{
                a{
                    color:#34AFED;
                }
            }
            .cnt__stays--arrow{
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 3px solid rgba(48, 39, 39, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                strong{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        height: 14px;
                        margin-left: 2px;
                        path{
                            stroke-width: 4px;
                            stroke: rgba(48, 39, 39, 0.4);
                        }
                    }
                }
            }
            &__next{
                right: 0;
                top: -55px;
            }
            &__prev{
                right: 60px;
                top: -55px;
                transform: rotate(-180deg);
            }  
        }
    }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
    .cn__cnt__stayswithviews{
        margin: 0 auto;
        padding: 0 30px;
        padding-top: 200px;
        @media screen and (min-width:1136px){
            padding: 0;
            padding-top: 200px;
        }
        &__title{
            font-size: 44px;
            line-height: 48px;
            padding: 0;
        }
        &__subtitle{
            font-size: 28px;
            line-height: 32px;
            margin-top: 50px;
            padding: 0;
        }
        &__description{
            font-size: 22px;
            line-height: 29px;
            padding: 0;
        }
        &__slider{
            margin-top: 30px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
            .cnt__cardDef{
                width: auto;
                height: 21.095vw;
                @media screen and (min-width:1136px){
                    height: 264px
                }
                margin-left: 0 !important;
                &__info{
                    display: none;
                }
            }
        }
        &__slider2{
            flex-direction: unset;
            padding: 0;
            @media screen and (min-width:1136px){
                padding: 0;
            }
            height: 727px;
            margin-top: 100px;
            background-color: #D4EAF5;
            border-radius: 20px;
            overflow: hidden;
            &__sec1{
                position: relative;
                width: 49%;
                flex-shrink: 0;
                &__title{
                    position: absolute;
                    width: 100%;
                    top: 130px;
                    font-size: 42px;
                    line-height: 46px;
                    color: #FFFFFF;
                    z-index: 1;
                    padding: 0 20px;
                    p{
                        opacity: 0;
                        transform: translateY(-10px);
                        transition: opacity 0.3s ease;
                    }
                }
                &__img{
                    position: absolute;
                    top: 0;
                    margin-top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: unset;
                    overflow: hidden;
                }
            }
            &__sec2{
                margin-top: 130px;
                font-size: 18px;
                line-height: 25px;
                padding: 0 30px;
                @media screen and (min-width:1136px){
                    padding: 0;
                }
                &__info{
                    position: relative;
                    width: 100%;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity 0.3s ease, transform 0.6s ease;
                    p{
                        max-width: 351px;
                        @media screen and (min-width:1136px){
                            margin-left: 56px;
                        }
                        a{
                            color:#34AFED;
                        }
                    }
                }
                .cnt__stays--arrow{
                    width: 28px;
                    height: 28px;
                    border: 2px solid rgba(48, 39, 39, 0.4);
                    strong{
                        svg{
                            height: 10px;
                        }
                    }
                }
                &__next{
                    right: auto;
                    top: auto;
                    left: 70px;
                    @media screen and (min-width:1136px){
                        left: 96px;
                    }
                    bottom: 50px;
                }
                &__prev{
                    right: auto;
                    top: auto;
                    left: 30px;
                    @media screen and (min-width:1136px){
                        left: 56px;
                    }
                    bottom: 50px;
                }  
            }
        }
    } 
}