@import '../../global/mixins/global-mixins.scss';
.part-section {
    width: 100%;
    /* padding-left: 6.25rem !important;
    padding-right: 6.25rem !important; */

    /* .slick-next{
        right: 0 !important;
    }
    .slick-prev{
        right: 54px !important;
    }
 */

    @media screen  and (max-width: 1136px) {
        padding-left: 0 !important;
    }
    position: relative;
    /* .slick-arrow{
        &.slick-prev{
            right: auto;
            left: 0px;
        }
        &.slick-next{
            left: 76px;
            right: auto;
        }
    } */
    .heading-section {
        max-width: 610px;
        float: right;
        text-align: right;
        padding: 0;
        margin-right: 100px;
        /* @media screen and (min-width: 1919px) {
            margin-right: 18.125rem;
        } */
        @media screen and (min-width: 1136px) /* and (max-width: 1919px) */ {
            margin-right: 100px;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 auto;
            max-width: 71.5%;
            text-align: center;
            padding: 0 0;
            float: none;
        }
        @media screen  and (max-width: 600px) {
            float: none;
            margin: 0;
            max-width: 100%;
            text-align: center;
            padding: 0 1.25rem;
        }
        h2 {
            font-family: $secondary-font;
            
            font-size: 48px;
            line-height: 115%;
            letter-spacing: -0.02em;

            color: $color-black;
            margin: 0 0 1.25rem 0;
            display: block;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 1.5625rem;
            }
            &.desktop {
                @media screen and (max-width: 1146px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 1146px) {
                    display: none;
                }
                @media screen  and (max-width: 600px) {
                    margin-bottom: 0 !important;
                }
            }
            br {
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                //color: $color-primary;
                font-weight: 900;
            }
        }
        p {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            color: $color-dark;
            margin: 0 0 0.625rem 0;
            @media screen and (min-width: 601px) and (max-width: 1146px) {
                margin:  0;
            }
            @media screen  and (max-width: 600px) {
                display: none;
            }
        }
    }
    .lSSlideOuter {
        clear: both;
    }
    /* .country-box {
        transition: all .35s ease;
        border-radius: 0.625rem;
        background: $color-white;
        @include box-shadow-light();
        width: 100%;
        @media screen  and (max-width: 600px) {
            
        }
        overflow: hidden;
        margin: 0 auto 0.625rem;
        .country-box-top {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 20.375rem;
            overflow: hidden;
            @media screen  and (max-width: 600px) {
                height: 13.9375rem;
            }
            div {
                height: 100%;
            }
            a, span {
                display: block;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    filter: grayscale(0);
                    @media screen  and (max-width: 1136px) {
                        filter: grayscale(0);
                    }
                }
            }
        }
        .country-box-bottom {
            display: inline-block;
            vertical-align: top;
            width: 100%; 
            padding: 0.625rem 1.875rem 0.8125rem 1.5625rem;
            position: relative;
            h4 {
                color: $color-black;
                height: 1.9375rem;
                margin: 0 0 0.3125rem 0;
                a {
                    font-size: 1.625rem;
                    line-height: 1.9375rem;
                    font-weight: 500;
                    font-variant: small-caps;
                    text-transform: lowercase;
                    color: $color-black;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                        float: right;
                        margin-top: 0.4375rem;
                    }
                }
            }
            .country-box-text {
                @include small-text();
                color: $color-dark;
                display: block;
                vertical-align: top;
                width: 100%;
                margin: 0;
            }
        }
    } */
}
