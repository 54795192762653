//------------------- DESKTOP -------------------

.cn__hp__featured{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    .cn__hp__heading__title {
        color: $color-black;
    }
    &__loading{
        position: relative;
        margin-top: 30px;
        &__titles{
            position: relative;
            width: 80%;
            max-width: 723px;
            height: 22px;
            border-radius: 8px;
            background: #F8F2FC;
            margin: 0 auto;
        }
        &__articles{
            position: relative;
            width: 100%;
            margin-top: 39px;
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-column-gap: 20px;
            &__article{
                position: relative;
                width: 100%;
                height: calc(11.112vw + 54px);
                background: #F8F2FC;
                border-radius: 15px;
            }
        }
        &__buttons{
            position: relative;
            width: 80%;
            max-width: 482px;
            height: 44px;
            border-radius: 8px;
            background: #F8F2FC;
            margin: 50px auto 0;
        }
    }
    &__info{
        position: relative;
        margin-top: 30px;
        &__titles{
            position: relative;
            display: flex;
            justify-content: center;
            &__title{
                position: relative;
                font-weight: 400;
                margin-left: 40px;
                font-size: 18px;
                line-height: 22px;
                font-feature-settings: unset;
                font-variant: unset;
                text-transform: unset;
                cursor: pointer;
                &:first-child{
                    margin-left: 0;
                }
                &::after{
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    width: 0;
                    bottom: -15px;
                    height: 4px;
                    background: #F35597;
                    border-radius: 2px;
                    content: "";
                    margin-top: 5px;
                    -webkit-transition: width .3s ease;
                    transition: width .3s ease;
                }
                &.cnhpfeatured--active{
                    font-weight: 600;
                    &::after{
                        width: 27.31%;
                    }
                }
            }
        }
        &__articles{
            position: relative;
            width: 100%;
            margin-top: 39px;
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-column-gap: 20px;
            &__article{
                position: relative;
                a{color:#242526}
                &__img{
                    position: relative;
                    width: 100%;
                    height: 11.112vw;
                    border-radius: 16px;
                    overflow: hidden;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-transform: translate3d(0, 0, 0);
                    -moz-transform: translate3d(0, 0, 0);
                    transform: translate3d(0,0,0);
                    background: #F8F2FC;
                    img{
                        filter: brightness(1);
                        transition: transform 0.6s ease;
                    }
                }
                &__title{
                    position: relative;
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 1.5;
                    font-weight: 400;
                }
                &:hover{
                    .cn__hp__featured__info__articles__article__img{
                        img{
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
        .cn__button__grey{
            margin: 50px auto 0;
            width: 233px;
        }
    }
    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        padding: 25px 0 30px;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .simplebar-track{
        display: none;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }  
    }
    
    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 10px 0;
            scroll-padding: 0 0 0 20px;
        }  
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}

//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__featured{
        &__info{
            &__articles{
                grid-column-gap: 25px;
                &__article{
                    &__img{
                        max-height: 226px;
                    }
                }
            }
        }
    } 
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__featured{
        padding: 0;
        margin: 60px auto;
        &__loading{
            padding-left: 30px;
            overflow: hidden;
            &__articles{
                background: #F8F2FC;
                width: 100%;
                height: 206px;
                border-radius: 15px 0 0 15px;
                &__article{
                    display: none;
                }
            }
        }
        &__info{
            position: relative;
            margin-top: 30px;
            &__titles{
                display: block;
                .simplebar-content-wrapper{
                    scroll-behavior: smooth;
                }
                .slidermobile__wrapper{
                    padding: 0 20px 25px 0;
                }
                &__title{
                    flex-shrink: 0;
                    margin-left: 30px;
                    scroll-snap-align: start;
                    scroll-snap-stop: normal;
                    &:first-child{
                        margin-left: 30px;
                    }
                }
            }
            &__articles{
                position: relative;
                width: 100%;
                margin-top: 20px;
                display: block;
                .simplebar-content-wrapper{
                    scroll-behavior: smooth;
                }
                &__article{
                    flex-shrink: 0;
                    width: 225px;
                    margin-left: 15px;
                    scroll-snap-align: start;
                    scroll-snap-stop: normal;
                    &:first-child{
                        margin-left: 30px;
                    } 
                    &__img{
                        height: 160px;
                    }
                    &:hover{
                        .cn__hp__featured__info__articles__article__img{
                            img{
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

//TABLET 601
@media screen and (min-width:601px) and (max-width:763px){
    .cn__hp__featured{
        .cn__hp__heading{
            text-align: left;
            .cn__hp__heading__subtitle{
                margin-left: 0;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__featured{
        padding: 0;
        &__loading{
            padding-left: 20px;
            &__titles{
                width: 100%;
                height: 17px;
                border-radius: 4px 0 0 4px;
            }
            &__articles{
                background: #F8F2FC;
                width: 100%;
                height: 165px;
                border-radius: 15px 0 0 15px;
            }
            &__buttons{
                margin: 40px auto 0;
            }
        }
        &__info{
            margin-top: 20px;
            &__titles{
                &__title{
                    margin-left: 15px;
                    font-size: 14px;
                    line-height: 17px;
                    &:first-child{
                        margin-left: 20px;
                    }
                }
                .simplebar-content-wrapper{
                    -ms-overflow-style: none;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
            &__articles{
                margin-top: 15px;
                &__article{
                    width: 163px;
                    margin-left: 10px;
                    &__img{
                        height: 104px;
                    }
                    &:first-child{
                        margin-left: 20px;
                    } 
                    &__title{
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                .slidermobile__wrapper{
                    padding: 0 20px 10px 0;
                    display: flex;
                    width: 100%;
                    overflow-x: unset;
                    -ms-scroll-snap-type: unset;
                    scroll-snap-type: unset;
                    scroll-padding: unset;

                    -ms-overflow-style: none;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .simplebar-content-wrapper{
                    -ms-overflow-style: none;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .simplebar-track.simplebar-horizontal{
                    background: #FFFFFF;
                    visibility: visible !important;
                    height: 24px;
                    width: calc(100% - 40px);
                    bottom: -14px;
                    margin-left: 20px;
                    overflow: visible;
                    pointer-events: all;
                    cursor: pointer;
                    z-index: 0;
                    &::before{
                        position: absolute;
                        content: '';
                        width: calc(100% + 40px);
                        height: 100%;
                        left: -20px;
                        background: #FFFFFF;
                        z-index: -1;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 2px;
                        bottom: 1px;
                        background: #F4F4F5;
                        border-radius: 5px;
                        z-index: -1;
                    }
                    .simplebar-scrollbar{
                        width: 100%;
                        height: 4px;
                        top: auto;
                        bottom: 0;
                        border-radius: 5px;
                        background: #f466a2;
                        &::before{
                            display: none;
                        }
                    }
                }  
            }
            .cn__button__grey{
                margin: 50px auto 0;
            }
        }
    }
}