@import '../mixins/global-mixins.scss';
.cn_slct_box {
	//display: block;
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 1.25rem;
	margin-bottom: 0;
	cursor: pointer;
	font-size: 0.938rem;
	line-height: 1.125rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	@media screen  and (max-width: 600px) {
		font-size: 1rem;
		line-height: 1.25rem;
		padding-left: 0;
	}
	.label{
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 300;
		margin-left: 10px;
	}
}
.cn_slct_box:hover {
	font-weight: 500;
}
.cn_slct_box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.cn_checkmark {
	/*position: absolute;
	top: 0.25rem;
	left: 0;
	height: 0.625rem;
	width: 0.625rem;
	border: 1px solid $color-subtle-gray; 
	border-radius: 1px;
	background-size: 0.813rem;
	background-repeat: no-repeat;
	background-position: center;*/
	position: absolute;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	border: 1px solid #ACABB4;
	border-radius: 4px;
	@media screen  and (max-width: 600px) {
		width: 1.25rem;
		height: 1.25rem; 
		right: 0;
		left: auto;
		top: 0;
		border-width: 0.125rem;
		border-radius: 0.25rem;
	}
}
//.cn_slct_box:hover input ~ .cn_checkmark,
.cn_slct_box input:checked ~ .cn_checkmark  {
	/*transform: rotate(45deg);
	border-bottom: 2px solid $color-black;
	border-right: 2px solid $color-black;
	border-top: none;
	border-left: none;
	border-radius: 0;
	background: none;
	width: 0.5rem;
	height: 0.813rem;
	top: 0;
	left: 0.25rem;*/
	border:none;
	width: 1rem;
	height: 1rem; 
	background: url('/static/img/big-checkmark.svg') no-repeat center center;
	background-size: cover;
	@media screen  and (max-width: 600px) {
		right: 3px;
		left: auto; 
	}
} 
.container input:checked~span.label { 
	font-weight: 500;
}
.mobT{
	font-size: 1rem;
	line-height: 1.25rem;
	padding-left: 0;
}
.mobT .cn_checkmark{
	width: 1.25rem;
	height: 1.25rem; 
	right: 0;
	left: auto; 
}
.mobT.cn_slct_box:hover input ~ .cn_checkmark,
.mobT.cn_slct_box input:checked ~ .cn_checkmark {  
	right: 3px;
	left: auto; 
}