//---------MOBILE---------
.cn__cnt__slider__staytypes{
    position: relative;
    background: #E2E1E6;
    margin: 40px auto 0;
    border-radius: 20px;
    width: calc(100% - 40px);
    padding: 30px 0 20px;
    &__title{
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #1D1C1F;
        padding: 0 20px;
    }
    &__slider{
        .cnt__card2{
            width: 285px;
            height: 316px;
            .cn__gp__card__image{
                height: 100%;
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #E2E1E6 !important;
            height: 20px;
        }
    }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
    .cn__cnt__slider__staytypes{
        position: relative;
        background: #E2E1E6;
        margin: 100px auto 0;
        border-radius: 20px;
        width: calc(100% - 60px);
        padding: 50px 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__title{
            font-size: 28px;
            line-height: 32px;
            text-align: center;
            width: 100%;
        }
        &__slider{
            width: 80%;
            margin-top: 40px;
            .cnt__card2{
                width: unset;
                height: 316px;
                .cn__gp__card__image{
                    height: 100%;
                }
            }
            .slick-track{
                padding: 0;
            }
            .slick-list{
                margin-left: -10px;
            }
            .slick-arrow{
                width: 28px;
                height: 28px;
                border-width: 2px;
                svg{
                    height: 10px;
                }
                &.slick-prev{
                    right: auto;
                    left: -48px;
                }
                &.slick-next{
                    right: -48px;
                }
            }
        }
    }
}


//---------DESKTOP---------
@media screen and (min-width:1136px){
    .cn__cnt__slider__staytypes{
        width: 100%;
        flex-direction: unset;
        justify-content: space-between;
        &__title{
            text-align: left;
            width: 307px;
        }
        &__slider{
            width: 54.8%;
            .slick-list{
                margin-left: -30px;
            }
            .slick-arrow{
                &.slick-prev{
                    left: -68px;
                }
                &.slick-next{
                    right: -30px;
                }
            }
        }
    }
}