//---------MOBILE---------
.cn__cnt__slider__pcities{
  position: relative;
  margin: 40px auto 0;
  width: 100%;
  &__title{
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      letter-spacing: -0.02em;
      color: #1D1C1F;
      padding: 0 20px;
  }
  &__subtitle{
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: rgba(29, 28, 31, 0.45);
      margin-top: 10px;
      padding: 0 20px;
  }
  &__slider{
      position: relative;
      width: 100%;
      margin-top: 15px;
      .slidermobile__wrapper{
        padding: 0 20px 20px 0;
      }
      .cn__gp__card{
        &__image{
          p{
            margin-top: 0;
            font-weight: 800;
            font-size: 23px;
            line-height: 28px;
            letter-spacing: 0.02em;
            text-transform: unset;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
  }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
  .cn__cnt__slider__pcities{
    position: relative;
    margin: 89px auto 0;
    width: 100%;
    padding: 0 30px;
    @media screen and (min-width:1200px){
      padding: 0;
    }
    &__title{
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #1D1C1F;
        padding: 0;
    }
    &__subtitle{
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: rgba(29, 28, 31, 0.45);
        margin-top: 10px;
        padding: 0;
    }
    &__slider{
        position: relative;
        width: 100%;
        margin-top: 15px;
        .cn__gp__card{
          &__image{
            p{
              margin-top: 0;
              font-weight: 800;
              font-size: 23px;
              line-height: 28px;
              letter-spacing: 0.02em;
              text-transform: unset;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
        .slick-track{
          padding: 0;
        }
        .slick-arrow{
          top: -26px;
          width: 28px;
          height: 28px;
          border-width: 2px;
          svg{
            height: 10px;
          }
          &.slick-prev{
            right: 38px;
          }
        }
    }
  }
}