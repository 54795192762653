//------------------- DESKTOP -------------------

.cn__dashboard_pas_container {
	padding-inline: 51px;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	&_heading {
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		/* identical to box height, or 22px */

		color: #242526;
	}
	&_input {
		width: 228px;
		height: 46px;
		left: 465px;
		padding-inline: 15px;
		padding-block: 13px;

		/* Bright-white */

		background: #ffffff;
		border: 0.5px solid #dfe2e5;
		box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
		border-radius: 100px;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		/* identical to box height, or 21px */

		letter-spacing: 0.01em;

		/* Greys/Subtle Grey */

		color: #acabb4;
	}
	&_input:focus {
		border: 1px solid #797882;
	}
	&_labeled-input {
		label {
			margin-left: 15px;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			/* identical to box height, or 24px */

			letter-spacing: 0.01em;

			/* Dark-grey */

			color: #797882;
		}
		display: flex;
		flex-direction: column;
		gap: 5px;
		&_validation-errors {
			margin-left: 15px;
			font-size: 12px;
			color: $color-primary;
			max-width: 200px;
		}
	}
	&_current {
		display: flex;
		flex-direction: column;
	}
	&_new {
		display: flex;
		gap: 28px;
		flex-wrap: wrap;
		&_button {
			min-width: 165px;
			height: 45px;
			/* new gradient */
			border: none;
			background: linear-gradient(92.14deg, #f35597 1.79%, #7868c7 99.81%);
			border-radius: 20px;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			/* identical to box height, or 24px */

			text-align: center;
			letter-spacing: 0.01em;

			color: #f7f7f7;
			align-self: flex-start;
			margin-top: 30px;
			padding-inline: 7px;
			padding-block: 11px;
			display: flex;
			justify-content: center;
			align-items: center;
			.cn__dashboard_pas_loader {
				height: 20px;
				width: 20px;
				background: linear-gradient(92.14deg, #f35597 1.79%, #7868c7 99.81%);
				.loader_circle {
					height: 20px;
					width: 20px;
				}
			}
		}
		.disabled {
			background: #dfe2e5;
			.cn__dashboard_pas_loader {
				background: #dfe2e5;
			}
		}
	}
	//-----------MOBILE-------------//
	@media screen and (max-width: 680px) {
		border: 0.5px solid #dfe2e5;
		box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
		border-radius: 15px;
		margin-inline: 20px;
		margin-top: 7px;
		padding-top: unset;
		padding-inline: unset;
		padding: 20px;
		&_heading {
			font-size: 16px;
		}
		&_input {
			width: 100%;
		}
		&_labeled-input {
			label: {
				font-size: 14px;
			}
			width: 100%;
		}
		&_new_button {
			width: 100%;
		}
	}

	.cn__dashboard_pas_loader {
		height: 20px;
		width: 20px;
		background: none;
	}
}
