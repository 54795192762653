//------------------- DESKTOP -------------------

.cn__card6{
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;
    height: var(--card-width);
    .cn__image-default{
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.7);
        transition: all 0.35s ease;
        border-radius: 20px;
    }
    &__info{
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 0.6vw 1.1vw 1.1vw;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__title{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            font-feature-settings: unset;
            font-variant: unset;
            text-transform: unset;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &:hover{
        transform: scale(0.98);
        .cn__image-default{
            filter: brightness(0.95);
        }
    }

}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){

    .cn__card6{
        width: 270px !important;
        height: 270px;
        &__info{
            padding: 0 12px 15px 20px;
        }
        &:hover{
            transform: none;
            img{
                filter: brightness(0.7);
            }
        }

    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){

    .cn__card6{
        width: 163px !important;
        height: 163px;
        &__info{
            bottom: 0;
            padding: 0 2px 10px 10px;
            &__title{
                font-size: 14px;
                line-height: 17px;
                -webkit-line-clamp: 2;
            }
        }
    }

}
