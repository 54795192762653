

.screenTitle {
	font-style: normal;
	font-weight: 600 !important;
	font-size: 23px !important;
}

.cn__dashboard_payment {
	display: flex !important;
	flex-direction: column;
	padding-inline: 46px !important;
	justify-content: center;
	gap: 20px;
	form {
		width: 100% !important;
	}

	&_submit {
		width: 335px;
		height: 46px;
		left: 0px;

		background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
		border: none;
		border-radius: 30px;font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		/* identical to box height, or 24px */
		
		text-align: center;
		letter-spacing: 0.01em;
		
		color: #F7F7F7;
	}
}

.cn__dashboard_payment_mobile {
	display: unset !important;
	form {
		display: flex !important;
		flex-direction: column;
		gap: 20px;
	}
	.cn__dashboard_payment_submit {
		width: 335px;
		height: 46px;
		left: 20px;
		background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
		border: none;
		border-radius: 30px;
		align-self: center;
	}
}

.dashboard-container {
	background-color: var(--color-white);
	min-height: 100vh;
	overflow: hidden;

	


	.simplebar-scrollbar:before {
		background-color: $color-subtle-grey;
	}

	.profileUpload-container {
		> span {
			right: 30px;
			bottom: 10px;
		}
	}
	.baseContainer-scroller-mobile {
		overflow-y: auto;
		overflow-x: hidden;
		.MuiTabs-flexContainer {
			overflow-x: auto;
		}
		.MuiTabs-flexContainer::-webkit-scrollbar {
			display: none;
		}
		.MuiTabs-flexContainer::-webkit-scrollbar-thumb {
			display: none;
	    }
		.MuiTabs-flexContainer::-webkit-scrollbar-button {
			display: none; 
	    }
	}
	[data-simplebar].baseContainer-scroller, .baseContainer-scroller-mobile  {
		flex: 1; 
		&.active {
			z-index: 9999;
		}
		.simplebar-content-wrapper {
			min-height: 100%;
			display: flex;
			flex-direction: column;
			.simplebar-content {
				display: flex;
				flex-direction: column;
				flex: 1;
				main {
					flex: 1;
					display: flex;
					flex-direction: column;
					overflow-x: hidden;
				}
			}
		}
	}
	::-webkit-file-upload-button {
		cursor: pointer;
	}

	button {
		cursor: pointer;
	}

	.notification-container {
		box-sizing: border-box;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 999999;
		width: 320px;
		padding: 0px 15px;
		max-height: calc(100% - 30px);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.notification {
		box-sizing: border-box;
		padding: 15px 15px 15px 58px;
		border-radius: 2px;
		color: #fff;
		background-color: #ccc;
		box-shadow: 0 0 12px #999;
		cursor: pointer;
		font-size: 1em;
		line-height: 1.2em;
		position: relative;
		opacity: 0.9;
		margin-top: 15px;
	}

	.notification .title {
		font-size: 1em;
		line-height: 1.2em;
		font-weight: bold;
		margin: 0 0 5px 0;
	}

	.notification:hover,
	.notification:focus {
		opacity: 1;
	}

	.notification-enter {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}

	.notification-enter.notification-enter-active {
		visibility: visible;
		transform: translate3d(0, 0, 0);
		transition: all 0.4s;
	}

	.notification-exit {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}

	.notification-exit.notification-exit-active {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
		transition: all 0.4s;
	}

	.notification:before {
		position: absolute;
		top: 50%;
		left: 15px;
		margin-top: -14px;
		display: block;
		font-family: "Notification";
		width: 28px;
		height: 28px;
		font-size: 28px;
		text-align: center;
		line-height: 28px;
	}

	.notification-info {
		background-color: #efb008;
	}

	.notification-success {
		background-color: #6fcf97;
	}

	.notification-warning {
		background-color: #f29141;
	}

	.notification-error {
		background-color: #f44a3a;
	}

	.dashboard-calendar {
		@media (max-width: 600px) {
			.omnisearch_content_action-area_date-range-picker .DayPicker {
				margin-top: 24px;
			}
			.dashboard-calender-closeicon {
				position: absolute;
				width: 285px;
				margin: 10px auto;
				top: 0;
				right: 18px;
			}
		}
		.omnisearch_content_action-area_date-range-picker {
			height: 100% !important;
			@media (max-width: 600px) {
				ul {
					display: flex;
				}
				.CalendarMonthGrid__vertical,.CalendarMonthGrid__vertical_scrollable {
					width: unset !important;
				}
			}
		}
		.dashboard-calender-closeicon {
			display: flex;
    		justify-content: flex-end;
			svg {
				cursor: pointer;
			}
		}
	}

	.pointer {
		cursor: pointer;
	}
	
	.n-tooltip__inner {
		@media screen and (max-width: 481px) { 
			display: none !important;
		}
	}

	&_tooltip {
		z-index: 50 !important;
		
		&_title {
			font-weight: 700;
			font-size: 12px;
			line-height: 150%;
			/* or 18px */

			text-align: center;
			letter-spacing: 0.01em;
		}
		&_description {
			font-weight: 600;
			font-size: 10px;
			line-height: 150%;
			/* or 15px */

			text-align: center;
			letter-spacing: 0.01em;
		}
	}

	.dashboardPagination {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin:40px 0px;
		.MuiPaginationItem-root {
			width: rem(36);
			height: rem(36);
			font-family: $primary-font;
			font-size: 1rem;
			font-weight: 500;
			border-radius: rem(15);
			color: $color-black;
			&.Mui-selected {
				background-color: $color-primary;
				color: $color-white;
			}
		}
	}


	.listingTabs-breadcrumContainer {
		position: relative;
		top:-34px;
		display: flex;
    	justify-content: space-between;
		@media screen and (max-width: 600px) {
			flex-direction: column;
			gap: 10px;
		}
		.listingTabs-breadcrumContainer-addproperty{
			width: 165px;
			color: $color-light-gray-shade;
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			line-height: 150%;
			letter-spacing: 0.16px;
			align-content: space-evenly;
			height: 45px;
			flex-shrink: 0;
			border-radius: 20px;
			background: linear-gradient(92deg, #F35597 1.79%, #7868C7 99.81%);
		}
	}

	.cn__hostDashboard_listingCard-models.modelComponent_content {
		min-width: 0px !important;
	}


	@import "../components/Dashboard/_BaseContainer.scss";
	@import "../components/Dashboard/_NavTabs.scss";
	@import "../components/Dashboard/_AccountSideNavWedgit.scss";
	@import "../components/Dashboard/_SideNavListing.scss";
	@import "../components/Dashboard/BookingDetails.module.scss";
	@import "../components/Dashboard/DashboardPropertyCard";
	@import "../components/Dashboard/DashboardWatchlist";
	@import "../components/Dashboard/WatchlistControlHeader";
	@import "../components/Dashboard/_AccountProfileForm.scss";
	@import "../components/Dashboard/Messages.scss";
	@import "../components/Dashboard/_DashboardNotifications.scss";
	@import "../components/Dashboard/DashboardCurrencySection";
	@import "../components/Dashboard/DashboardReviews";
	@import "../components/Dashboard/DeleteCard";
	@import "../components/Dashboard/DashboardPrivacyAndSecurity";

	// components
	@import "../components/Dashboard/_ModelComponent.scss";
	@import "../components/Dashboard/Breadcrumbs.module.scss";
	@import "../components/Dashboard/DeleteListModalBody.module.scss";
	@import "../components/Dashboard/ConfirmCancellationModalBody.module.scss";
}
