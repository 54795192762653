.eid.eid.eid.eid {
    .cn__slider__cards {
        margin: 0;
        gap: 20px;
        overflow: initial;

        .simplebar_wrapper {
            .simplebar-content-wrapper {
                scroll-snap-type: x mandatory;
            }
        }

        .cn__card {
            margin: unset;
            background: none;
            background-color: #fff;
        }
    }

    .property-card {
        cursor: default;
        width: calc(var(--card-width) - (var(--card-gap)));
        height: auto;
        max-width: 27.125rem;
        grid-template-columns: 100%;
        grid-template-rows: 220px auto;

        .property-card__imginfo {
            width: 100%;
            justify-content: flex-end;
        }

        .property-card_slider-container {
            border-radius: 20px 20px 0 0;

            .swiper-container {
                border-radius: 20px 20px 0 0;

                .swiper-wrapper {
                    .swiper-slide {
                        text-align: center;
                        font-size: 18px;
                        background: #fff;
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        height: 230px;

                        img {
                            display: block;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .property-card_mobile-attrs {
            .property-card_amenities {
                margin-bottom: 10px;
            }
        }

        .property-card_content-wrapper {
            padding: 20px;

            .property-card_content {
                .property-card_name {
                    width: 100%;
                }

                .property-card_distance {
                    width: 100%;
                }
            }

            .property-card_footer {
                .property-card_footer_right {
                    flex: 0 0 auto;
                }

                .property-card_check_availability {
                    display: inline-block;
                    padding: 6px 24px;
                    border-radius: 30px;
                    background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
                    font-size: 12px;
                    line-height: 150%;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }

    .cn__card4 {
        height: 550px;

        .cn__card4__img__info {
            padding: 0 18px;
            margin-bottom: 60px;

            .cn__card4__img__info__title {
                text-transform: none;
                font-size: 28px;
                font-weight: 900;
                color: #fff;
                opacity: 0.6;
            }
        }
    }

    .cnt__card4-current {
        .cn__card4__img__info {
            .cn__card4__img__info__title {
                opacity: 1;
            }
        }
    }


    @media screen and (max-width:1100px) {
        .property-card {
            min-width: 340px;
        }

        .cn__card4 {
            height: 450px;

            .cn__card4__img__info {
                margin-bottom: 40px;

                .cn__card4__img__info__title {
                    font-size: 24px;
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width:600px) {
        .cn__card4 {
            height: 390px;

            .cn__card4__img__info {
                margin-bottom: 20px;
            }
        }
    }
}