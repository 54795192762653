.radio {
    display: flex;
    align-items: center;
    position: relative;
    // margin-bottom: rem(20);
    cursor: pointer;
    user-select: none;
    color: $color-black;
    font-size: rem(14);
    font-family: $primary-font;

    @media screen and (max-width:600px){
        font-size: rem(14);
    }

    //
    // width: max-content;
    padding: rem(10);
    border-radius: rem(6);
    cursor: pointer;
    transition: background .3s ease;

    &:hover {
        background: $color-Shade-gray;
    }

    & input {
        // position: absolute;
        // opacity: 0;
        // cursor: pointer;
        // height: 0;
        // width: 0;

        //
        appearance: none;
        vertical-align: middle;
        width: rem(20);
        height: rem(20);
        border-radius: rem(10);
        background: none;
        border: none;
        box-shadow: inset 0 0 0 rem(1) $color-subtle-gray;
        margin-right: rem(10);
        transition: box-shadow .2s ease;
        pointer-events: none;

        &:focus {
            outline: none;
        }

        // &:hover {
        //     box-shadow: inset 0 0 0 rem(1) $color-bright-purple;
        // }

        &:checked {
            box-shadow: inset 0 0 0 6px $color-black;
        }
    }

    // .radio_checkmark {
    //     height: rem(20);
    //     width: rem(20);
    //     background-color: $color-white;
    //     border-radius: 50%;
    //     border: rem(1) solid #ACABB4;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     transition: .2s ease-in-out;
    // }

    // .radio_label {
    //     margin-left: rem(10);
    // }

    // &:hover input ~ .radio_checkmark {
    //     background-color: $color-light-gray-input;
    // }

    // & input:checked ~ .radio_checkmark {
    //     background-color: $color-bright-blue;
    // }

    // .radio_checkmark::after {
    //     content: '';
    //     display: none;
    // }

    // & input:checked ~ .radio_checkmark::after {
    //     display: block;
    // }

    // & .radio_checkmark::after {
    //     width: rem(8);
    //     height: rem(8);
    //     border-radius: 50%;
    //     background: $color-white;
    // }
}