.page-404 {
    >main {
        >section {
            display: flex;
            flex-direction: column;
            padding: 100px 0 0 0;
            .detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: calc(100vh - 421px);
                h1 {
                    font-family: Merriweather;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 80px;
                    text-align: center;
                    margin-bottom: 20px;
                }
                h4 {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 40px;
                }
                .n-btn {
                    font-weight: bold;
                }
            }
        }
    }
}