.cn__covid19__wrapper{
    position: absolute;
    width: 100%;
    //Cookie banner
    .cn__cookie-wrapper{
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }
}
.cn__covid19{
    position: relative;
    color: $color-black;
    margin-top: 78px;
    padding: 10px 0 0;
    width: 100vw;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &__title{
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.02em;
        padding-bottom: 46px;
        border-bottom: 1px solid #E9E9EB;
        width: calc(100% - 40px);
        margin-left: 20px;
    }

    &__subtitle{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        margin-top: 30px;
        padding: 0 20px;
    }

    p{
        margin-top: 15px;
        padding: 0 20px;
    }

    &__first-p{
        margin-top: 30px !important;
    }

    &__types{
        margin-top: 15px;
        padding: 0 20px;
        ul{
            list-style-type: none;
            li{
                position: relative;
                padding-left: 45px;
                img{
                    position: absolute;
                    left: 0;
                    top: -3px;
                }
                h3{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    font-feature-settings: unset;
                    font-variant: unset;
                    text-transform: unset;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &__links{
        margin-top: 9px;
        margin-bottom: 30px;
        padding: 0 20px;
        a{
            display: block;
            text-decoration: none;
            font-weight: 600;
            color: $color-bright-purple;
            margin-top: 15px;
            font-size: 16px;
        }
    }

    &__articles{
        position: relative;
        width: 100%;
        margin-bottom: 15px;

        &__slidermob{
            display: inline-flex !important;
            width: 100%;
            overflow-x: scroll;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            padding: 20px 0 15px 0;
            scroll-padding: 0 0 0 20px;

            a{
                text-decoration: none;
                color: $color-black;
                flex-shrink: 0;
                scroll-snap-align: start;
                scroll-snap-stop: normal;
                width: 249px;
                margin-left: 10px;

                &:first-child{
                    margin-left: 20px;
                }

                .covid__article{
                    position: relative;
                    border-radius: 20px;
                    background: $color-white;
                    box-shadow: 0 2px 2px rgba(87,75,144,.1);
                    transition: all .35s ease;
                    &__img{
                        position: relative;
                        width: 100%;
                        height: 180px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 20px 20px 0 0;
                        }
                    }
                    &__info{
                        padding: 20px 15px;
                        p{
                            padding: 0;
                        }
                        &__title{
                            margin: 0;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                        &__description{
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #797882;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .simplebar-track.simplebar-horizontal {
            background: #f7f7f7;
            visibility: visible !important;
        }
    }

    &__contact{
        margin-top: 0;
        padding: 30px 0 0 0;
        border-top: 1px solid #E9E9EB;
        width: calc(100% - 40px);
        margin-left: 20px;
    }
    &__contact-a{
        margin-top: 15px;
        margin-bottom: 30px;
        a{
            text-decoration: none;
            font-weight: 600;
            color: $color-bright-purple;
        }
    }
}

//------------------------- TABLET -------------------------//

@media screen and (min-width: 600px) {
    .cn__covid19{

        padding: 0;
        max-width: 528px;
        margin: 0 auto;
        margin-top: 107px;
        line-height: 22px;

        &__title{
            padding-bottom: 48px;
            width: 100%;
            margin-left: 0;
        }

        &__subtitle{
            margin-top: 15px;
            padding: 0;
        }
        p{
            padding: 0;
        }

        &__first-p{
            margin-top: 40px !important;
        }

        &__types{
            padding: 0;
        }

        &__links{
            margin-bottom: 42px;
            padding: 0;
            a{
                line-height: 20px;
            }
        }
        &__articles{
            position: relative;
            width: 100%;
            margin-bottom: 96px;
            .slick-slider{
                .slick-list{
                    overflow: hidden;
                    padding: 25px 0;
                    width: calc(100% + 10px);
                    margin-left: -5px;
                }
                .slick-slide{
                    padding: 0 5px;
                }
                a{
                    text-decoration: none;
                    color: $color-black;
                    .covid__article{
                        position: relative;
                        border-radius: 20px;
                        background: $color-white;
                        box-shadow: 0 2px 2px rgba(87,75,144,.1);
                        transition: all .35s ease;
                        &__img{
                            position: relative;
                            width: 100%;
                            height: 180px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px 20px 0 0;
                            }
                        }
                        &__info{
                            padding: 20px;
                            &__title{
                                margin: 0;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                            &__description{
                                margin-top: 5px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20px;
                                color: #797882;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
            .slick-arrow {
                background-color: transparent;
                -webkit-transition: .3s ease;
                transition: .3s ease;
                -webkit-transform: translate(0);
                transform: translate(0);
                -webkit-transform-origin: center center;
                transform-origin: center center;
                border: 3px solid #797882;
                width: 46px;
                height: 46px;
                text-align: center;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }
            .slick-arrow.slick-prev {
                position: absolute;
                top: 100%;
                right: 55px;
                display: inline-flex;
                left: auto;
                svg{
                    height: 14px;
                    margin-left: -4px;
                    path{
                        stroke: #797882;
                        stroke-width: 4px;
                    }
                }
            }
            .slick-arrow.slick-next {
                position: absolute;
                top: 100%;
                right: 0px;
                display: inline-flex;
                svg{
                    height: 14px;
                    margin-left: 4px;
                    path{
                        stroke: #797882;
                        stroke-width: 4px;
                    }
                }
            }
        }
        &__contact{
            width: 100%;
            margin-left: 0;
            padding-top: 30px;
        }
        &__contact-a{
            margin-bottom: 48px;
            a{
                text-decoration: none;
                font-weight: 600;
                color: $color-bright-purple;
                font-size: 16px;
            }
        }
    }
}

//------------------------- DESKTOP -------------------------//

@media screen and (min-width: 1136px) {
    .cn__covid19{

        padding: 0;
        max-width: 660px;
        margin: 0 auto;
        margin-top: 107px;
        line-height: 22px;

        &__title{
            padding-bottom: 35px;
        }

        &__subtitle{
            margin-top: 40px;
        }

        &__first-p{
            margin-top: 25px !important;
        }
        &__links{
            margin-bottom: 40px;
        }
        &__articles{
            position: relative;
            width: 100%;
            margin-bottom: 96px;
            .slick-slider{
                .slick-list{
                    overflow: hidden;
                    padding: 25px 0;
                    width: calc(100% + 20px);
                    margin-left: -10px;
                }
                .slick-slide{
                    padding: 0 10px;
                }
                a{
                    text-decoration: none;
                    color: $color-black;
                    .covid__article{
                        position: relative;
                        border-radius: 20px;
                        background: $color-white;
                        box-shadow: 0 2px 2px rgba(87,75,144,.1);
                        transition: all .35s ease;
                        &__img{
                            position: relative;
                            width: 100%;
                            height: 180px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 20px 20px 0 0;
                            }
                        }
                        &__info{
                            padding: 20px;
                            &__title{
                                margin: 0;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                height: 40px;
                            }
                            &__description{
                                margin-top: 5px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20px;
                                color: #797882;
                                display: -webkit-box;
                                -webkit-line-clamp: 5;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                        }
                        &:hover{
                            box-shadow: 0 30px 20px -20px rgba(87,75,144,.15);
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }

    }
}