//---------MOBILE---------
.cn__cnt__slider__pcountries{
    position: relative;
    margin: 100px auto 0;
    width: 100%;
    &__title{
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #1D1C1F;
        padding: 0 20px;
    }
    &__subtitle{
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: rgba(29, 28, 31, 0.45);
        margin-top: 10px;
        padding: 0 20px;
    }
    &__slider{
        position: relative;
        width: 100%;
        margin-top: 15px;
        .slidermobile__wrapper{
            padding: 0 20px 20px 0;
        }
        .slick-slide {
            width: 193px;
        }
    }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
    .cn__cnt__slider__pcountries{
        margin: 150px auto 0;
        &__title{
            font-size: 28px;
            line-height: 26px;
            padding: 0 30px;
            @media screen and (min-width:1140px){
                padding: 0;
            }
        }
        &__subtitle{
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: rgba(29, 28, 31, 0.45);
            margin-top: 10px;
            padding: 0;
            padding: 0 30px;
            @media screen and (min-width:1140px){
                padding: 0;
            }
        }
        &__slider{
            position: relative;
            width: 100%;
            @media screen and (min-width:1136px){
                margin-top: 0;
            }
            &--slider{
                position: relative;
                width: 100%;
                display: flex;
                margin-top: 15px;
                transition: transform 0.6s ease;
            }
            .cnt__pcountry--arrow{
                position: absolute;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                border: 2px solid rgba(48, 39, 39, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                strong{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        height: 10px;
                        margin-left: 2px;
                        path{
                            stroke-width: 4px;
                            stroke: rgba(48, 39, 39, 0.4);
                        }
                    }
                }
            }
            &__next{
                right: 0;
                top: -40px;
            }
            &__prev{
                right: 38px;
                top: -40px;
                transform: rotate(-180deg);
            }
        }
    }
}

