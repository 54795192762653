//------------------- DESKTOP -------------------

.cn__card5{
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;
    height: calc(var(--card-width) + (var(--card-width) * 0.25));

    .cn__image-default{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.35s ease;
        border-radius: 16px;
    }
    &__info{
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 1.1vw;
        color: #FFFFFF;
        &__title{
            font-weight: 700;
            font-size: 23px;
            line-height: 1.2;
            text-transform: uppercase;
        }
        &__subtitle{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $color-white;
            letter-spacing: 0.01em;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 30px 20px -20px rgba(87,75,144, 0.15);
    }

}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){

    .cn__card5{
        width: 215px !important;
        height: 300px;
        &__info{
            bottom: 10px;
            padding: 0 20px;
            &__title{
                font-size: 20px;
            }
        }
        &:hover{
            transform: none;
            box-shadow: 0 2px 2px rgba(87,75,144,0.15);
        }

    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){

    .cn__card5{
        /* width: 150px !important;
        height: 167px; */
        width: 163px !important;
        height: 167px;
        border-radius: 10px;

        .cn__image-default{
            border-radius: 10px;
        }

        &__info{
            bottom: 0;
            padding: 13px;
            &__title{
                font-size: 18px;
            }
            &__subtitle{
                font-size: 12px;
            }
        }
    }

}
