.back-action {
    padding: 0;

    .ic-icon {
        width: 30px;
        height: 30px;
        margin: 0;

        svg {
            width: inherit;
            height: inherit;
        }
    }
}