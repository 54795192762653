@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp6{
    position: relative;
    width: 100%;
    background: var(--color-background2);
    padding: dvw(100) 0;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__box{
        position: relative;
        padding: dvw(50) 0;
        background: #F4F8FF;
        border: dvw(1) solid rgba(100, 116, 139, 0.5);
        box-shadow: 0px 60px 65px -50px rgba(0, 0, 0, 0.2);
        border-radius: dvw(15);
        color: #64748B;

        &__title{
            font-weight: 700;
            font-size: dvw(54);
            line-height: 1.2;
            color: #E76B74;
            text-align: center;
        }

        &__subtitle1{
            font-size: dvw(32);
            line-height: 1.2;  
            text-align: center;  
            max-width: dvw(859);
            margin: 0 auto;
            margin-top: dvw(50);


            span{
                position: relative;
                font-weight: 600;
                color: #E76B74;
            }
        }

        &__subtitle2{
            font-size: dvw(23);
            line-height: 1;
            text-align: center;
            margin-top: dvw(50);
        }

        &__subtitle3{
            font-size: dvw(16);
            line-height: 1.5;
            text-align: center;
            margin-top: dvw(50);
        }
    }
}


@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp6{
        padding: 100px 0;
    
        &__box{
            padding: 50px 0;
    
            &__title{
                font-size: 54px;
            }
    
            &__subtitle1{
                font-size: 32px; 
                padding: 0 6.72vw;
                margin-top: 50px;
    
                span{
    
                    &::after{
                        height: 2px;
                        bottom: -1vpx;
                    }
                }
            }
    
            &__subtitle2{
                font-size: 23px;
                margin-top: 58px;
            }
    
            &__subtitle3{
                font-size: 16px;
                margin-top: 50px;
            }
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp6{
        padding: 6vw 0 9vw;
    
        &__wrapper{
            padding: 0 30px;
        }
    
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp6{
        padding: mvw(60) 0;
    
        &__wrapper{
            padding: 0 20px;
        }

        &__box{
            padding: mvw(20);
            border-radius: mvw(15);
    
            &__title{
                font-size: mvw(32);
            }
    
            &__subtitle1{
                font-size: mvw(16);
                line-height: 1.5;   
                padding: 0;
                margin-top: mvw(40);
                max-width: 100%;
            }
    
            &__subtitle2{
                font-size: mvw(16);
                line-height: 1.5;
                margin-top: mvw(40);
            }
    
            &__subtitle3{
                font-size: mvw(10);
                margin-top: mvw(40);
            }
        }
    }
}