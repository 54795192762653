//DESKTOP//
.cn__ns__card1 {
  width: 100%;
  height: 470px;
  margin-top: 25px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0,0,0);
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    transform: scale(1.1);
  }
  &__info {
    position: absolute;
    left: 30px;
    bottom: 30px;
    color: white;
    &__description {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.025em;
      font-weight: 600;
      width: 826px;
      position: relative;
      overflow: hidden;
      display: block;
      span{
        display: block;
        position: relative;
        padding: 0 4px;
        -webkit-transform: translate(0px, 100%);
        transform: translate(0px, 100%);
      }
    }
    &__date {
      margin-top: 15px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      position: relative;
      overflow: hidden;
      display: block;
      span{
        display: block;
        position: relative;
        padding: 0 4px;
        -webkit-transform: translate(0px, 100%);
        transform: translate(0px, 100%);
      }
    }
  }
}
.cn__ns__card2 {
  position: relative;
  width: 100%;
  &__img {
    position: relative;
    width: 100%;
    height: 266px;
    border-radius: 15px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0,0,0);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.640s cubic-bezier(0.45,0.05,0.55,0.95);
    }
  }
  &__info {
    position: relative;
    width: 100%;
    margin-top: 15px;

    &__description {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000;
    }
    &__date {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      margin-top: 5px;
      color: rgba(36, 37, 38, 0.5);
    }
  }
  &:hover{
    .cn__ns__card2__img{
      img{
        transform: scale(1.05);
      }
    }
  }
}
.cn__newsroom__main__block2 {
  &__general {
    .cn__ns__card2 {
      position: relative;
      width: 100%;
      &__img {
        width: 100%;
        height: 140px;
        border-radius: 10px;
        margin-top: 40px;
      }
    }
  }
}

//TABLET//
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .cn__ns__card1 {
    height: 400px;
    &__info {
      right: 30px;
      bottom: 63px;
      height: 72px;
      &__description {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.cn__ns__card2 {
  &__img {
    height: 206px;
  }
}

//MOBILE//
@media screen and (max-width: 600px) {
  .cn__ns__card1 {
    height: 420px;
    &__info {
      left: 20px;
      right: 20px;
      bottom: 20px;
      &__description {
        line-height: 32px;
        width: 100%;
        height: auto;
        font-size: 28px;
      }
      &__date {
        margin-top: 10px;
      }
    }
  }
  .cn__ns__card2 {
    &:first-child {
      margin-top: 0px;
    }
    &__img {
      height: 195px;
    }
  }
  .cn__newsroom__main__block2 {
    &__banner {
      margin-top: 25px;
      padding: 25px 10.5px;
      height: auto;
      &__heading {
        padding: unset;
      }
    }
    &__general {
      grid-template-columns: 1fr;
      grid-column-gap: unset;
      margin-top: 50px;
      grid-row-gap: 25px;
      .cn__ns__card2 {
        display: grid;
        grid-template-columns: 107px 1fr;
        grid-column-gap: 20px;
        align-items: center;
        &__info {
          margin-top: 0px;
          &__description {
            font-size: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &__img {
          height: 107px;
          margin-top: 0px;
        }
      }
    }
  }
}
