.list {
    ul {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        li {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-dark-grey);
            align-self: center;
        }
    }
    &--two-col {
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &--three-col {
        ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &--four-col {
        ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
    }
    &--left-icon {
        ul {
            display: grid;
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .ic-icon {
                    margin-right: 10px;
                }
            }
        }
    }
}

.list-detail-modal {
    .list {
        margin-bottom: 13px;
        >strong {
            @include text-subtitle;
            display: block;
            margin-bottom: 15px;
        }
        ul {
            grid-gap: 8px;
            li {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black);
                margin-bottom: 0px;
            }
        }
    }
    .n-btn {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        padding: 0px;
        .ic-icon {
            path {
                stroke: var(--color-dark-grey);
            }
        }
    }
    @include desktop {
        .n-btn {
            width: auto;
        }
    }
}

.modal-list-detail-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .list {
        margin-bottom: 25px;
        strong {
            margin-bottom: 15px;
            display: block;
        }
        ul {
            grid-gap: 10px;
            li {
                color: var(--color-black);
                margin-bottom: 15px;
            }
        }
    }
    .n-modal--fullscreen {
        .n-modal__content {
            max-width: calc(calc(var(--vh, 1vh) * 100) - 200px);
        }
    }
    @include desktop {
        .list {
            ul {
                grid-gap: 15px;
                li {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.expandable-list {
    $self: &;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    &__title {
        color: var(--color-black);
        @include text-normal;
        font-weight: 600;
        margin-bottom: 15px;
    }
    &__content {
        list-style: none;
        li {
            @include text_body-text;
            color: var(--color-black);
            margin-bottom: 15px;
        }
    }
    &__footer {
        .n-btn {
            @include text_body-text-bold;
        }
    }
    &--two-col {
        #{$self}__content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
        }
    }
    &--four-col {
        #{$self}__content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 10px;
        }
    }
}

.description-list {
    list-style: none;
    li {
        margin-bottom: 25px;
    }
    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
        margin-bottom: 5px;

        letter-spacing: unset;
        font-feature-settings: unset;
        font-variant: unset;
    }
    >div {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
    }
    &--two-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column: 20px;
    }
    &--review {
        dt {
            font-weight: normal;
            margin-bottom: 8px;
            strong {
                font-weight: 600;
                display: block;
                padding: 7px 0;
            }
        }
        dd {
            color: var(--color-dark-grey);
            padding-bottom: 13px;
            border-bottom: 1px solid #E9E9EB;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}