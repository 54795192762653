@mixin tabletMixin {
    &.main_tablet {
        .info-banner-bar {
            margin-top: rem(10);
        }
        .header-wrapper {
            width: 100%;
            &.header-wrapper__min {
                // overflow: hidden;
                height: rem(88);
                .filters-tab-bar_secondary{
                    display: none;
                }
                .filters-tab-bar_minimized {
                    top: calc((((88px - 46px) / 2) + 46px) * (-1));
                    // top: rem(-47);
                }
            }
        }

        .filters-tab-bar {
            // border-bottom: rem(1) solid $color-light-gray-input;
            padding-bottom: rem(20);

            // .filters-tab-bar_maximized {
            //     padding-right: rem(20);
            // }

            .filters-tab-bar_secondary {

                .drop-down-popover {
                    .drop-down-popover_track {
                        .outer__active {
                            .outer_info-container {
                                .outer_info-content {
                                    @media (min-width: 601px) {
                                        max-width: rem(85);
                                    }
                                    @media (min-width: 700px) {
                                        max-width: rem(110);
                                    }
                                    @media (min-width: 750px) {
                                        max-width: rem(140);
                                    }
                                    @media (min-width: 800px) {
                                        max-width: initial;
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }


        .main_layout {
            .scrollable-property-container {
                @include propertyCardMobile;
                &.scrollable-property-container__exists {
                    padding-bottom: rem(150);
                }
                // .scrollable-property-container__loader {
                //     height: calc(100vh - 400px);
                // }
            }
        }
    }

    .scrollable-property-container_wrapper {
        display: grid;
        gap: rem(20);

        @media (min-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media (min-width: 730px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 1025px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}