@import './tools/_mixins';

//------------------- DESKTOP -------------------

.cn__careprogram{

    //---Header
    .header{
        .max-width-wrapper{
            max-width: 1440px;
            padding: 0 50px;
            .li--blog, .li--currency, .li--listproperty{
                display: none;
            }
        }
    }

    //---Footer
    .cn__footer__wrapper{
        background: #FAFAFA;
    }
    .cn__footer{
        background: #FAFAFA;
        padding-top: 8vw !important;
        max-width: 1440px !important;
    }
}

//------------------- TABLET/MOBILE -------------------

@include tablet {
    .cn__careprogram{
        .header{
            .max-width-wrapper{
                padding: 0;
            }
        }
    }
}

//------------------- MOBILE -------------------

@include mobile {
    .cn__careprogram{

        //--Button
        .cn__lyp__button.lypb1{
            width: 100%;
            left: 0;
            margin-top: 13vw;
        }

        //---Footer
        .cn__footer{
            background: #FAFAFA;
            padding-top: 20vw !important;
        }
    }
}