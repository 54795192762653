@import '../../global/mixins/global-mixins.scss';
@import '../../global/reviews/reviews-box.scss';
@import '../../global/reviews/rating-stars.scss';

.reviews-section {
    width: 100%;
    position: relative;
    .reviews-con {
        grid-template-columns: repeat(8,1fr);
        grid-column-gap: 20px; 
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            grid-column-gap: 20px;
        }
        display: grid;
        h3 {
            font-family: $secondary-font;
            font-size: 48px;
            line-height: 115%;
            letter-spacing: -0.02em;

            text-align: center;
            grid-column: 3/7;
            font-variant: unset;
            text-transform: unset;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                grid-column: 2/8;
            }
            @media screen and (min-width: 300px) and (max-width: 600px) {
                grid-column: 1/9;
                font-size:2rem;
            }
            &.desktop{
                @media screen and (min-width: 300px) and (max-width: 600px) {
                    display: none;
                }
            }
            &.mobile{
                display: none;
                @media screen and (min-width: 300px) and (max-width: 600px) {
                    display: block;
                    line-height: 2.375rem;
                    padding: 0 1.25rem;
                }
            }
            span{
                font-weight: 900;
            }
        }
        > p {
            grid-column: 3/7;
            text-align: center;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                grid-column: 2/8;
            }

            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            margin-top: 1.25rem;
            color: $color-dark-grey;
            @media screen and (min-width: 300px) and (max-width: 600px) {
                display: none;
            }
        }
        .reviews-slider {
            grid-column: 2/8;
            //margin: 1.875rem auto 0;
            position: relative;
            width: 100%;
            //max-width: 87.3%;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                max-width: 100%;
                grid-column: 1/9;
                padding: 0 20px;
            }
            @media screen and (min-width: 300px) and (max-width: 600px) {
                grid-column: 1/9; 
                padding: 0;
                max-width: 100%;
                margin:1.125rem 0 0;
                height: 425px;
            }
            .slick-list{
                @media screen and (min-width: 300px) and (max-width: 600px) {
                    padding: 0 50px;
                }   
            }
            .slick-track{
                height: 482px;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    height: auto;
                }
                @media screen and (min-width: 300px) and (max-width: 600px) {
                    height: 425px; 
                    margin-top: 10px;
                }
                .slick-slide{
                    padding: 29px 20px 0 20px;
                    transition: all 0.6s ease;
                    @media screen and (min-width: 601px) and (max-width: 1136px) {
                        padding: 29px 10px 0 10px;
                    }
                    @media screen and (min-width: 300px) and (max-width: 600px) {
                        padding: 0 6px 0 0;        
                    }
                }
                .slick-center{
                    transform: scale(1.06);
                    margin-left: 1px;
                }
            }
            .slick-arrow{
                /*position: absolute;
                top: -29px;*/
                margin-top: -32px;
                transition: all 0.3s ease;
                display: inline-flex;
                transform-origin: center center;
                transform: translate(0);
                svg{
                    height: 14px;
                    path{
                        stroke: #797882;
                        stroke-width: 4px;
                    }
                }
                &:hover{
                    transform: scale(0.9);
                }
            }
            .slick-prev{
                //right: -9%;
                width: 46px;
                height: 46px;
                left: -8.125rem;
                background: #E9E9EB;
            }
            .slick-next{
                //right: -17%;
                width: 46px;
                height: 46px;
                right: -8.125rem;
                background: #E9E9EB;
            }
            .lSSlideOuter {
                overflow: visible;
            }
            .reviews-box {
                //margin: 0.625rem; 
                @media screen and (min-width: 300px) and (max-width: 600px) { 
                    margin: 5px; 
                }
                .visited-img {
                    position: relative;
                    display: flex;
                    height: 176px;
                    transition: all 0.5s ease-in-out;
                    @media screen and (min-width: 300px) and (max-width: 600px) { 
                        height: 168px;
                    }
                    span{
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        //display: inherit;
                        //max-width: 100%; 
                        border-radius: 10px 10px 0 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                &:hover{
                    @media screen and (min-width: 1137px) {
                        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.15);
                        transform: scale(1.05);
                        .visited-img {
                            height: 297px;
                        }
                        .simple-link{
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.3s 0.5s ease;
                        }
                        .reviews-content{
                            height: 128px;
                            .visited-review{
                                opacity: 0;
                                visibility: hidden;
                                transition: none;
                            }
                        }
                    }
                }
            }
            .lSSlideOuter .lSPager.lSpg {
                padding: 0.938rem 0 0; 
            }
            .lSSlideOuter .lSPager.lSpg > li { 
                margin: 0 0.75rem;
            } 
            .lSSlideOuter .lSPager.lSpg > li a {
                background-color: $color-subtle-gray; 
            }
            .lSSlideOuter .lSPager.lSpg > li:hover a, .lSSlideOuter .lSPager.lSpg > li.active a {
                background-color: $color-black;
            }
            .lSAction{
                @media screen and (min-width: 300px) and (max-width: 600px) {
                    display: none;
                }
            }
            .lSAction > a{
                opacity: 1;
                width: 60px;
                height: 60px;
            }
            .lSAction > .lSPrev { 
                left: -9rem;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    left: -6rem;
                }
            }
            .lSAction > .lSNext { 
                right: -6rem;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    right: -6rem;
                }
            }
            .reviews-arrow {
                background-color: #FFFFFF;
                border-radius: 100%;
                display: flex;
                width: 60px;
                height: 60px;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
            .slick-dots { 
                bottom: -51px;
            }
        }
    }
}