//------------------- DESKTOP -------------------

.cn__hp__deals{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 0 auto 100px;
    &.cn__hp__recentsearches--available {
        margin-top: 60px;
    }
    &__titles{
        position: relative;
        margin-top: 30px;
        &__title{
            position: relative;
            font-weight: 400;
            margin-left: 40px;
            font-size: 18px;
            line-height: 22px;
            font-feature-settings: unset;
            font-variant: unset;
            text-transform: unset;
            cursor: pointer;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            &:first-child{
                margin-left: 0;
            }
            &::after{
                position: absolute;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 0;
                bottom: -15px;
                height: 4px;
                background: #F35597;
                border-radius: 2px;
                content: "";
                margin-top: 5px;
                -webkit-transition: width .3s ease;
                transition: width .3s ease;
            }
            &.cnhpdeals--active{
                font-weight: 600;
                &::after{
                    width: 27.31%;
                }
            }
        }
    }
    &__slider{
        position: relative;
        width: 100%;
        //margin-top: 15px;
        .slick-list{
            overflow: hidden;
            margin-left: -8px;
            padding: 10px 0;
        }
        .slick-track{
            display: inline-flex;
        }
        .slick-arrow{
            background-color: transparent;
            transform-origin: center center;
            border: 2px solid rgba(121, 120, 130, 0.6);;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 0;
            transition: opacity .4s ease;
            svg{
                height: 10px;
                path{
                    stroke: rgba(121, 120, 130, 0.6);;
                    stroke-width: 4px;
                }
            }
            &.slick-next{
                position: absolute;
                right: 0;
                top: -51px;
                display: inline-flex;
                svg{
                    margin-left: 2px;
                }
            }
            &.slick-prev{
                position: absolute;
                right: 38px;
                top: -51px;
                display: inline-flex;
                left: auto;
                svg{
                    margin-left: -2px;
                }
            }
            &.slick-disabled{
                opacity: 0.5;
                pointer-events: none;
            }
        }
        &__card{
            position: relative;
            width: 18.75vw;
            height: 38.837vw;
            flex-shrink: 0;
            max-width: 270px;
            max-height: 551px;
            border-radius: 15px;
            overflow: hidden;
            display: grid;
            place-items: center;
            cursor: pointer;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0,0,0);
            transition: all 0.35s ease;
            img{
                transition: all 0.35s ease;
            }
            &__info{
                z-index: 1;
                color: #FFFFFF;
                width: 100%;
                padding: 0 20px;
                &__title{
                    font-weight: 900;
                    font-size: 36px;
                    line-height: 40px;
                    text-align: center;
                    letter-spacing: -0.04em;
                    text-transform: uppercase;
                }
                &__subtitle{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 5px;
                    text-align: center;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            &:hover{
                transform: translateY(-5px);
                box-shadow: 0px 15px 10px -10px rgba(125,125,125,0.6);
                img{
                    filter: brightness(0.95);
                }
            }
        }
    }
    .cn__slider__arrows{
        top: -57px;
    }
    .cn__button__grey {
        margin: 30px 0 0 0;
        width: 314px;
        float: right;
    }
    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        padding: 0 0 34px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 25px 0;
            scroll-padding: 0 0 0 30px;
        }
    }

    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 25px 0;
            scroll-padding: 0 0 0 20px;
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 18px;
        z-index: 0;
        @media screen and (max-width:1136px){
            height: 10px;
        }
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}

//DESKTOP 1280
@media screen and (min-width:1280px){
    .cn__hp__deals{
        &__slider{
            .slick-slide {
                padding: 0 10px;
            }
            &__card{
                width: 18.36vw;
                height: 43.047vw;
                max-width: unset;
                max-height: unset;
            }
        }
    }
}
//DESKTOP 1366
@media screen and (min-width:1366px){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 19.4vw;
                height: 40.337vw;
                max-width: unset;
                max-height: unset;
            }
        }
    }
}
//DESKTOP 1440
@media screen and (min-width:1440px){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 18.75vw;
                height: 38.264vw;
                max-width: unset;
                max-height: unset;
            }
        }
    }
}
//DESKTOP 1536
@media screen and (min-width:1536px){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 19.141vw;
                height: 35.873vw;
            }
        }
    }
}
//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__deals{
        &__slider{
            .slick-slide {
                padding: 0 12.5px;
            }
            &__card{
                width: 385px;
                height: 551px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__deals{
        padding: 0;
        margin: 40px auto;
        &__titles{
            &__title{
                flex-shrink: 0;
                margin-left: 30px;
                &:first-child{
                    margin-left: 30px;
                }
            }
            .simplebar-content-wrapper{
                scroll-behavior: smooth;
            }
        }
        &__slider{
            .simplebar-content-wrapper{
                scroll-behavior: smooth;
            }
            &__card{
                width: 269px;
                height: 440px;
                flex-shrink: 0;
                margin-left: 15px;
                scroll-snap-align: start;
                scroll-snap-stop: normal;
                &:first-child{
                    margin-left: 30px;
                }
                &:hover{
                    transform: none;
                    box-shadow: none;
                    img{
                        filter: brightness(0.9);
                    }
                }
            }
        }
        .cn__button__grey {
            margin: 5px 0 0 30px;
            float: left;
        }
    }
}

//TABLET 601
@media screen and (min-width:601px) and (max-width:763px){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 257px;
                height: 435px;
            }
        }
    }
}
//TABLET 764
@media screen and (min-width:764px) and (max-width:799){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 270px;
                height: 435px;
            }
        }
    }
}
//TABLET 800
@media screen and (min-width:800px) and (max-width:1135px){
    .cn__hp__deals{
        &__slider{
            &__card{
                width: 270px;
                height: 435px;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__deals{
        margin: 40px auto 74px auto;
        &__titles{
            margin-top: 16px;
            &__title{
                flex-shrink: 0;
                margin-left: 15px;
                font-size: 14px;
                line-height: 17px;
                &:first-child{
                    margin-left: 20px;
                }
            }
        }
        &__slider{
            &__card{
                width: 163px;
                height: 160px;
                margin-left: 10px;
                &:first-child{
                    margin-left: 20px;
                }
                &__info{
                    &__title{
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: -0.03em;
                    }
                    &__subtitle{
                        font-size: 14px;
                        line-height: 16px;
                        margin-top: 0;
                    }
                }
            }
            .slidermobile__wrapper{
                padding: 0 20px 10px 0 !important;
                display: flex !important;
                width: 100% !important;
                overflow-x: unset !important;
                -ms-scroll-snap-type: unset !important;
                scroll-snap-type: unset !important;
                scroll-padding: unset !important;
            }
            .simplebar-track.simplebar-horizontal{
                background: #FFFFFF;
                visibility: visible !important;
                height: 24px;
                width: calc(100% - 40px);
                bottom: -14px;
                margin-left: 20px;
                overflow: visible;
                pointer-events: all;
                cursor: pointer;
                z-index: 0;
                &::before{
                    position: absolute;
                    content: '';
                    width: calc(100% + 40px);
                    height: 100%;
                    left: -20px;
                    background: #FFFFFF;
                    z-index: -1;
                }
                &::after{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 2px;
                    bottom: 1px;
                    background: #F4F4F5;
                    border-radius: 5px;
                    z-index: -1;
                }
                .simplebar-scrollbar{
                    width: 100%;
                    height: 4px;
                    top: auto;
                    bottom: 0;
                    border-radius: 5px;
                    background: #f466a2;
                    &::before{
                        display: none;
                    }
                }
            }
        }
        .cn__button__grey {
            margin: 5px 0 0 20px;
        }
    }
}
