.cn__gp__search__bg {
  position: relative;
  width: 100%;
  border-radius: 20px;
  display: flex;
  color: #ccc;
  font-size: 40px;
  background: #edf2ef;
  margin: 40px auto 0;
  @media screen and (max-width: 600px) {
    background: transparent;
  }

  & .cn__gp__search__bg_leftCol {
    width: 51%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .cn__gp__search__bg_leftColPadd {
      width: 100%;
      max-width: 366px;

      & h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
        color: #000000;
      }
      & h2 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #797882;
      }
      & .btn-primary {
        background: linear-gradient(92.14deg, #f35597 1.79%, #f53959 99.81%);
        box-shadow: 0px 4px 16px rgba(243, 85, 151, 0.3);
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 25px 10px 15px;
        width: 100%;
        margin-top: 15px;
      }
      & .btn-primary:before {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        margin-left: -16px;
        content: '';
        background: url(/static/home/search.svg);
      }
      @media screen and (min-width: 200px) and (max-width: 767px) {
        padding: 50px 26px 50px 8%;
        background: transparent;
      }
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        padding: 50px 50px 50px 8%;
      }
    }
    .cn__gp__search__bg {
      @media screen and (min-width: 200px) and (max-width: 767px) {
        background: transparent;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  & .cn__gp__search__bg_rightCol {
    width: 49%;
    text-align: right;
    display: inline-block;
  }
  & .cn__gp__search__bg_rightCol img {
    height: 413px;
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }
  & .cn__gp__search__bg_locationdiv {
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    margin-top: 25px;
    box-shadow: 0px 0px 20px 0px;
    .n-input-date-range-picker.horizontal {
      .input-dropdown-calendar {
        padding-bottom: 0;
        .DayPicker_transitionContainer {
          box-sizing: content-box;
          padding-bottom:30px;
        }
      }
    }
    .search-field{
      .cn-input-group{
        position: relative;
        height: 50px !important;
        input{
          position: absolute;
          height: 50px !important;
        }
      }
    }
    .form__field.datepicker_box{
      display: flex;
      align-items: center;
      //height: 48px !important;
      margin-bottom: 0 !important;
      .input-value{
        margin-top: 2px;
        .left-icon{
          margin-right: 5px;
          display: block;
          position: relative;
          width: 24px;
          height: 24px;
          svg{
            position: absolute;
            left: 0;
            path,rect{
              stroke: #ACABB4 !important;
            }
          }
        }
        .text{
          padding: 2px 0 0 0;
        }
      }
      .input-dropdown{
        margin-top: 10px;
        @media screen and (max-width: 1200px) {
          margin-top: 10px;
        }
      }
      .filled{
        .text{
          color: #242526;
        }
      }
    }
    .cn__gp__search__bg_rightCol{
      .filled{
        top: -3px;
      }
      .text-group{
        .left-icon{
          margin-right: 5px;
          display: block;
          position: relative;
          width: 24px;
          height: 24px;
          svg{
            position: absolute;
            left: 0;
            path,rect{
              stroke: #ACABB4 !important;
            }
          }
        }
        .text{
          padding: 2px 0 0 0;
        }
      }
      .input-dropdown{
        margin-top: 2px !important;
        @media screen and (max-width: 1200px) {
          margin-top: 6px !important;
          right: -12px;
        }
      }
    }
  }
  & #banner_search input {
    background: transparent;
    border: 0;
    height: 60px;
    background: url(/static/geopages/Location-outlined.svg);
    background-repeat: no-repeat;
    background-position: left;
    border-radius: 0;
    text-indent: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #242526;
    box-shadow: none;
    margin-left: 10px;
  }
  & .border_bottominput {
    border-bottom: 1px solid #eeeef0;
  }
  & .form__field.datepicker_box {
    width: 50%;
    float: left;
    border-right: 1px solid #eeeef0;
    height: 60px;
  }

  & .n-input-date-range-picker {
    border: 0;
    background: transparent;
    height: 40px;

    @media screen and (max-width: 600px) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    /* @media screen and (max-width: 1200px) {
      height: 51px;
    } */
  }
  & .cn__gp__search__bg_rightCol .n-input-guest-room-picker {
    border: 0;
    background: transparent;
    height: 40px;
    .input-dropdown {
      .n-input-counter {
        display: grid;
        grid-template-columns: repeat(3, max-content);
        align-items: center;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $color-black;
          min-width: 25px;
          text-align: center;

          padding: 10px 16px;
          background: #EEEEF0;
          border-radius: 50%;

          &.n-input-counter--disabled{
            color: #ACABB4;
          }
        }
        button {
          background: transparent;
          border: none;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          outline: none;
          cursor: pointer;
          &.btn-decrease svg {
            position: relative;
            //top: -4px;
          }
          &.btn-increase svg {
            position: relative;
            top: 2px;
          }
          .ic-icon {
            width: 24px;
            height: 24px;
          }
          &:hover:not(:disabled) {
            background: #eeeef0;
          }
          &:disabled {
            opacity: .5;
            cursor: auto;
          }
        }
      }
    }

    /* @media screen and (max-width: 1200px) {
      height: 51px;
    } */
  }
  & .input-dropdown {
    @media screen and (min-width: 1000px) and (max-width: 1100px) {
      margin-right: -12%;
    }
  }
  & .n-input-date-range-picker .input-dropdown {
    @media screen and (min-width: 1000px) and (max-width: 1100px) {
      margin-top: 7px;
    }
  }
  #date_pickertext .input-dropdown {
    @media screen and (min-width: 1000px) and (max-width: 1100px) {
      margin-top: 7px;
    }
  }

  & .input-dropdown {
    @media screen and (min-width: 700px) and (max-width: 768px) {
      margin-right: -15px;
    }
  }
  & .n-input-date-range-picker .input-dropdown {
    @media screen and (min-width: 700px) and (max-width: 768px) {
      margin-top: 7px;
    }
  }
  #date_pickertext .input-dropdown {
    @media screen and (min-width: 700px) and (max-width: 768px) {
      margin-top: 7px;
    }
  }

  & .form__field.datepicker_box span.text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #acabb4;
    padding: 14px 0px 0px 14px;
  }
  & .cn__gp__search__bg_rightCol span.text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #acabb4;
    padding: 0px 0px 0px 14px;
  }
  & #banner_search input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #242526;
    text-indent: 10px;
  }
  & #date_pickertext .n-input-guest-room-picker .input-dropdown ul li > span {
    text-align: left;
  }
}
.n-input-date-range-picker .input-value .left-icon path,
.n-input-guest-room-picker .input-value .left-icon path {
  stroke: #abaaaf;
}
#date_pickertext .input-dropdown {
  margin-top: 15px;
}
.n-input-date-range-picker .input-dropdown {
  margin-top: 15px;
}

@media screen and (max-width: 1136px) {
  .cn__gp__search__bg {
    width: calc(100% - 60px);
    margin: 40px auto 0;
    .cn__gp__search__bg_leftCol {
      .cn__gp__search__bg_leftColPadd {
        padding: 0 30px;
        h1 {
          margin-bottom: 10px;
        }
        .cn__gp__search__bg_locationdiv {
          margin-top: 30px;
        }
        .btn-primary {
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cn__gp__search__bg {
    width: calc(100% - 40px);
    margin: 10px auto 0;
    .cn__gp__search__bg_leftCol {
      .cn__gp__search__bg_leftColPadd {
        padding: 0;
        h1 {
          margin-bottom: 10px;
          color: #ffffff;
        }
        h2{
          color: rgba(255, 255, 255, 0.7);
        }
        .cn__gp__search__bg_locationdiv {
          margin-top: 30px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        }
        .btn-primary {
          margin-top: 20px;
        }
      }
    }
  }
}

.n-modal--input-guest-room-picker ul .n-input-counter {
  min-width: 120px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $color-black;
    min-width: 25px;
    text-align: center;
  }
  button {
    background: var(--color-light-grey);
    border: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    &.btn-decrease svg {
      position: relative;
      //top: -4px;
    }
    &.btn-increase svg {
      position: relative;
      top: 2px;
    }
    .ic-icon {
      width: 24px;
      height: 24px;
    }
    &:hover:not(:disabled) {
      background: #eeeef0;
    }
    &:disabled {
      opacity: .5;
      cursor: auto;
    }
  }
}
