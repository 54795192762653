.messages-container {
    padding:0px 40px;
    height: 100%;
    overflow-y: auto;

    .messages-filterNavComponent {
        .messages-filterNavComponentScrollbar {
            margin-bottom: 40px;
            padding:0px;
            .simplebar-track.simplebar-horizontal {
                height: 3px;
                bottom: -3px;
            }
        }
        width:100%;
        ul {
            display:flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding:0px;
            list-style: none;
            height:30px;
            li {
                margin-right:15px;
                padding:0px 12px;
                height:30px;
                border:solid 1px  #242526;
                color:#242526;
                font-size: 10px;
                cursor: pointer;
                line-height: 28px;
                text-align: center;
                border-radius: 30px;
                transition: 300ms ease;
                &:hover, &.active {
                    background: #FDE9E0;
                    border:solid 1px #FDE9E0;
                }
            }
        }
    }
    .messages-messagesListComponent {
        max-width: 595px;
        width:100%;
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width:100%;
            li {
                cursor: pointer;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
                border-radius: 7px;
                padding:20px;
                width:100%;
                transition: 300ms ease;

                .messages-messagesListComponentThreadContainer {
                    display:flex;
                    justify-content: flex-start;
                    align-items: center;
                    width:100%;
                }

                &:hover {
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
                }
                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                .messages-messagesListComponentThreadImage {
                    width: 55px;
                    height: 55px;
                    min-width: 55px;
                    min-height:55px;

                    overflow: hidden;
                    border-radius: 55px;
                    margin-right: 15px;
                    img {
                        width:100%;
                        object-fit: cover;
                        height:100%;
                    }
                }

                .messages-messagesListComponentThreadContent {
                    flex: 1;
                    min-width: 0px;
                    .messages-messagesListComponentThreadContentTitle {
                        display:flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 0px;
                        .messages-messagesListComponentThreadContentTitleDetails {
                            display: flex;
                            flex:70%;
                            min-width:0px;
                            justify-content: space-between;
                            align-items: center;
                            @media (max-width:480px) {
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                            }
                        }
                        h3 {
                            max-width: 200px;
                            width: 100%;
                            min-width: 0px;
                            overflow: hidden;
                            font-weight:600;
                            flex:1;
                            font-size:16px;
                            color:#000000;
                            white-space: nowrap;
                            margin-right: 15px;
                            text-overflow: ellipsis;
                            font-variant: none;
                            text-transform: none;
                        }
                        p {
                            font-size:10px;
                            color:#797882;



                            span {
                                font-size:12px;
                                color:#7E71BC;
                            }
                            &.messages-messagesListComponentThreadContentDate {
                                flex: 30%;
                                text-align: right;
                                min-width: 0px;;
                            }
                        }



                    }
                    .messages-messagesListComponentThreadContentDetails {
                        p {
                            font-size: 12px;
                            color: #797882;

                        }
                    }
                    .messages-messagesListComponentThreadContentDescription {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        p {
                            color: #000000;
                            font-size: 10px;
                            width: 70%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .messages-messagesListComponentThreadContentDescriptionNotification {
                            background:$color-primary;
                            width:20px;
                            height:20px;
                            font-size: 12px;
                            overflow: hidden;
                            border-radius: 20px;
                            line-height: 20px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}


// messagesDetailsView-container Message Details
.messagesDetailsView-container {
    margin-top:-24px;
    display:flex;
    flex-direction: column;
    flex:1;
    .messagesDetailsView-container-errorHandler {
        align-self: center;
        margin-top: 25%;

    }
    .messagesImageScreenManager {
        max-width: 90%;
        height: 90%;
        .modelComponent_body {
            min-height: 0px;
            overflow: hidden;
            margin-top:15px;
            border-radius: 5px;
        }
        .slick-messagesImageContainer {
            width:100%;
            height:100%;
            text-align: center;
            img {
                max-width: 100%;
                object-fit: contain;
                max-height: 100%;
            }
        }
    }

    .messagesDetailHeaderComponent {
        border-bottom:#EDEDED 1px solid;
        padding:10px 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .messageDetails-rightDetailsHeder {
            display:flex;
            align-items: center;
            margin-top: 5px;


            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                margin-right:30px;
                cursor: pointer;

                @media (max-width:623px) {
                    margin-right:10px;
                };

                svg {
                    margin-left: 5px;
                    vertical-align: middle;
                }
            }

            @media (max-width:480px) {
                margin-right:0px;
                place-self: flex-end;
                h4 {
                    margin-right:0px;
                }
                .pointer {
                    display:none;
                }
            };
        }
        .messageDetails-leftDetailsHeder {
            display:flex;
            align-items: center;
            min-width: 0px;
            flex: 1;
            .messageDetails-arrowIcon {
                margin-right: 20px;
            }

            .messageDetails-profile {
                display:flex;
                align-items: center;
                flex:1;
                min-height: 0px;
                min-width: 0px;
                .messageDetails-profileImage {
                    width:55px;
                    height:55px;
                    margin-right: 15px;
                    overflow: hidden;
                    border-radius: 30px;
                    img {
                        width:100%;
                        height:100%;
                    }
                }
                .messageDetails-profileDetails {
                    min-width: 0px;
                    flex:1;
                    margin-right: 10px;

                    @media (min-width:480px) {
                        margin-right: 40px;
                    }
                    h3{
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 21px;
                        font-variant: none;
                        text-transform:none;
                        // text-transform: capitalize;
                        color:#000000;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 100%;
                        text-overflow: ellipsis;
                    }
                    .messageDetails-profileDetailsDescription {
                        width:100%;
                        p {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            color:#797882;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width:100%;
                            white-space:nowrap;
                        }
                        h4 {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 18px;
                            color:#7E71BC;
                        }
                    }
                }
            }


        }
    }



    .messagesDetailsThreadComponent {
        flex:1;
        position: relative;
        display:flex;
        flex-direction: column;
        max-width: 900px;
        margin: 0px auto;
        width: 100%;


        .messagesDetailsThread-list {
            flex:1;
        }
        .messagesDetailsThread-list {
            padding:0px 40px;
            display: flex;
            flex:1;
            flex-direction: column;





            .messagesDetailsThread-dateViewsContainer {
                margin: 15px 0px 25px 0px;
                text-align: center;
                .messagesDetailsThread-dateViews {
                    padding:5px 20px;
                    text-align:center;
                    font-size: 12px;
                    color:#000;
                    background:#EEEEF0;
                    display: inline-block;
                    margin:0px auto;
                    border-radius: 20px;
                }
            }
            .messagesDetailsThread-listItemGuest {
                display:flex;
                justify-content: flex-start;
                align-items: baseline;

                .messagesDetailsThread-listItemChatMessage {
                    background-color: #EEEEF0;
                    border-radius: 8px 8px 8px 0px;
                }
            }

            .messagesDetailsThread-listItemUser {
                display:flex;
                justify-content: flex-start;
                align-items: baseline;
                flex-direction: row-reverse;
                .messagesDetailsThread-listItemChatMessage {
                    background-color: #FDE9E0;
                    border-radius: 8px 8px 0px 8px;
                }
            }
            .messagesDetailsThread-listItemImage {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                position: relative;
                bottom:-10px;
                overflow: hidden;
                place-self:flex-end;
                img {
                    object-fit: contain;
                    width: 100%;
                }
            }

            .messagesDetailsThread-listItemChatMessageImage {
                width:143px;
                height:143px;
                border-radius: 15px;
                margin:10px 0px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .messagesDetailsThread-listItemChatMessage {
                max-width:80%;
                margin:0px 10px;
                padding: 8px 10px;
                width:100%;
                min-height: 70px;
                position: relative;
                .timeOfMessage {
                    position: absolute;
                    left:0px;
                    right:0px;
                    top:6px;
                    font-size: 11px;
                    font-weight: 400;
                    text-align: center;
                    color: rgba(0,0,0,0.7);
                }
                h5 {
                    max-width: 40%;
                    position: relative;
                    z-index: 999;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 11px;
                    font-weight: 400;
                }
            }
        }
        .messagesDetailsThreadFooter {
           justify-self: baseline;
           .imageSnippet {
            border-top:solid 1px #eee;
            margin-top:2px;
            padding: 5px 0px;
                .imgSnippet-imgContainer {
                    width:80px;
                    height:80px;
                    position: relative;
                    border:solid 4px #FDE9E0;
                    border-radius: 10px;
                    overflow: hidden;
                    .imageSnippet-imgCross {
                        position: absolute;
                        top:3px;
                        right:3px;
                        border-radius: 16px;
                        height:16px;
                        width:16px;
                        text-align:center;
                        vertical-align: middle;
                        line-height: 13px;
                        cursor: pointer;
                        background: #FDE9E0 ;
                        svg {
                            width:8px;
                            height:8px;
                        }
                    }
                    img {
                        width: 100%;
                        object-fit: contain;
                    }
                }
           }
           .messagesDetailsThreadFooter-messageField {
                background: #FFFFFF;
                border: 0.5px solid #DFE2E5;
                box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                border-radius: 100px;
                width:100%;
                min-height:46px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding:0px 15px;

                .displayNone {
                    display:none;
                }
                .messageBoxContainer {
                    margin:0px 15px;
                    flex:1;
                    max-height: 120px;
                    overflow-y: auto;
                }
                .messageBox {
                    word-wrap: break-word;
                    overflow-y:auto;
                    background: transparent;
                    min-height: 40px;
                    border:none;
                    outline: none;
                    line-height: 25px;
                    padding: 15px 0px;
                    resize: none;
                    outline: none !important;
                    border:none !important;
                }
                button {
                    background :transparent;
                    border:none;
                    outline:none;
                }
                button.send {
                    background-image: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                    border-radius: 32px;
                    text-transform: initial;
                    color: var(--color-white);
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: none;
                    width: 32px;
                    display:block;
                    margin:0px auto;
                    height:32px;
                    border:none; outline: none;
                    align-self: bottom;

                    &:disabled {
                        background:#EEEEF0;
                        cursor: not-allowed;
                    }
                    svg {
                        * {
                            stroke: #fff;
                        }
                    }
                }
           }
        };
    }
}