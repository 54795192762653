.button-group-counter {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-group-counter_value {
        padding: 10px 16px;
        text-align: center;
        background: #EEEEF0;
        border-radius: 50%;

        &--disabled{
            color: #ACABB4;
        }
    }

    .button-group-counter_btn {
        border-radius: 50%;
        border: none;
        background: none;
        outline: none;
        width: rem(34);
        height: rem(34);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background .3s ease-in-out;
        cursor: pointer;
        &:disabled {
            .button-group-counter_btn-icon {
                path {
                    stroke: #ACABB4;
                }
            }
            cursor: initial;
        }
        /* &:not(:disabled):hover {
            background: #EEEEF0;
        } */
        .button-group-counter_btn-icon {
            path {
                stroke: $color-black;
            }
        }
    }
}