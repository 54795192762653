@import '../../global/mixins/global-mixins.scss';
.notification-common {
   display: flex;
   align-items: center;
   width: 100%;
   max-width: calc(100% - 100px);
   background: $color-white;
   position: relative;
   z-index: 1;
   border: 0.5px solid $color-light-gray-input;
   padding: 1.25rem 0;
   @media screen and (min-width: 1919px) {
      max-width: calc(100% - 580px);
   }
   @media screen  and (max-width: 600px) {
      max-width: 95%;
      margin: auto;
      border-radius: 0.625rem 0.625rem 0px 0px;
      border-bottom: 0.1875rem solid;
   }
   .notification-left {
      width: 5.625rem;
      text-align: center;
      span {
         display: inline-flex;
         align-items: center;
         justify-content: center;
         width: 30px;
         height: 30px;
         border-radius: 50%;
      }
   }
   .notification-center {
      padding: 0.3125rem 0;
      width: calc(100% - 26.125rem);
      @media screen  and (max-width: 600px) {
         width: calc(100% - 7.5rem);
      }
      .notification-title {
         @include body-emphasized();
         color: $color-black;
         margin-bottom: 0.625rem;
      }
      .notification-text {
         @include body-text();
         color: $color-black;
      }
   }
   .notification-right {
      display: inline-flex;
      align-self: center;
      padding: 0;
      justify-content: left;
      width: 21.4375rem;
      @media screen  and (max-width: 600px) {
         display: none;
      }
   }
   .alert-close {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
      cursor: pointer;
  }
  &.style-2 {
      .notification-center {
         .notification-title {
            display: none;
         }
      }
      @media screen and (min-width: 601px) {
         .alert-close {
            position: absolute;
            top: 0;
            right: 0.625rem;
            bottom: 0;
            height: 1.5rem;
            margin: auto;
         }
      }
      @media screen  and (max-width: 600px) {
         border-radius: 0;
         border: 0.5px solid $color-light-gray-input !important;
         padding-bottom: 3.4375rem;
         .notification-left {
            width: 4.375rem;
         }
         .notification-center {
            width: calc(100% - 6.875rem);
            padding: 0.3125rem 0 0.625rem 0;
         }
         .notification-right {
            display: flex;
            position: absolute;
            bottom: 15px;
            right: 5px;
            justify-content: right;
         }
      }
   }
}
.model.alert {
   align-items: flex-start;
}


.popup-status{
   display: table;
   margin: 0;
   max-width: 300px;
   left:auto;
   right: -400px;
   top: 32px;
   border-radius: 10px;
   transition: all 0.6s ease;
   .notification-successful {
      width: 100%;
      max-width: 300px;
      border-radius: 10px;
      .notification-inf{
         display: flex;
         align-items: center;
         width: 100%;
         .notification-center{
            display: table;
            width: 71%;
            padding-right: 20px;
         }
      }
   }
   .notification-failed {
      width: 100%;
      max-width: 300px;
      border-radius: 10px;
      .notification-inf{
         display: flex;
         align-items: center;
         width: 100%;
         .notification-center{
            display: table;
            width: 71%;
            padding-right: 20px;
         }
      }
   }
   .notification-info {
      width: 100%;
      max-width: 300px;
      border-radius: 10px;
      .notification-inf{
         display: flex;
         align-items: center;
         width: 100%;
         .notification-center{
            display: table;
            width: 71%;
            padding-right: 20px;
         }
      }
   }
}