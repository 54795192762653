@import './tools/_functions';

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__card1{
        position: relative;
        flex-shrink: 0;
        background: #EEF2F5;
        border-radius: mvw(15);
        padding: mvw(20);
        margin: mvw(20) 0 0 mvw(10);
        color: #64748B;
        font-weight: 600;
        width: max-content;

        &__title{
            font-size: mvw(32);
            line-height: 1;
        }

        &__subtitle{
            font-size: mvw(18);
            line-height: 1;
            margin-top: mvw(10);
        }
    }
}