.lnp-description {
    display: grid;
    grid-auto-columns: minmax(300px, 660px);
    justify-content: center;
    gap: 20px;
    .sun-editor .se-wrapper .se-placeholder {
        font-family: var(--primary-font);
        font-size: 1rem;
    }
}
.lnp-description-article-link {
    font-size: 16px;
    line-height: 120%;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    a {
        color: var(--color-purple);
        text-decoration: underline;
    }
}

@media screen  and (max-width: 600px) {
    .lnp-description-article-link {
        margin-top: 2rem;
    }
}