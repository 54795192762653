.switcherComponent {
    border: 1px solid var(--color-field-grey);
    display: flex;
    padding: 20px 36px;
    background: var(--color-white);
    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.8);
    align-items: center;
    border-radius: 15px;

    &.userDropDownSwitcher{
        padding: 0px;
        background-color: transparent;
        border:none;
    }

    .switcherComponent-innerWrapper {
        position: relative;
        background-color: var(--color-white);
        border: 1px solid var(--color-field-grey);
        border-radius: 30px;
        height:46px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0px 16px;
        .switcherComponent-blob {
            background: linear-gradient(92deg, $color-primary 1.79%,  $color-purple 99.81%);
            transition: transform 100ms ease;
            position: absolute;
            top:6px;
            left:0px;
            bottom:6px;
            min-width: 50%;
            flex:1;
            border-radius: 30px;
            z-index: 9;
        }
        .switcherComponent-innerWrapper-navigator {
            min-width: 50%;
            position: relative;
            cursor: pointer;
            z-index: 99;
            flex:1;
            text-align: center;
            font-family: $primary-font;
            font-weight: 400;
            font-size: 14px;
            color: $color-primary;
            &.active {
                color: $color-white;
                font-weight: bold;
            }
        }
    }
}