
.los-notification {
    // width: 0;
    // height: 0;
    position: relative;
}
.los-notification--home .los-notification-container {
    top: 50px;
    left: -54px;
}
.los-notification--home--mobile {
    top: calc(50% - 26px);
    left: calc(50% - 130px);
}
.los-notification--srp .los-notification-container {
    top: 28px;
    left: -5px;
}

.los-notification-container {
    pointer-events: none;
    // z-index: 999;
    // position: absolute;
    position: relative;
    width: 260px;
    height: 53px;
    border: 2px solid $color-pastel-red;
    border-radius: 20px;
    display: flex;
    background-color: $color-pastel-red;
    // opacity: 0;
    // transform: translateY(-20px);
    // transition: all 0.3s ease;
}
// .los-notification-container--active {
//     opacity: 1;
//     transform: translateY(0);
// }

.los-notification-arrow-up {
    z-index: 1;
    position: absolute;
    top: -11px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-pastel-red;
}
.los-notification--home--mobile .los-notification-arrow-up {
    display: none;
}
.los-notification-symbol {
    flex: 0 0 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.los-notification-text {
    flex: 1;
    background-color: $color-white;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    color: $color-pastel-red;
    padding: 6px 20px;
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
}
