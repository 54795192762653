.cn__gp__discover {
  height: auto;
  border-radius: 20px;
  background-color: rgba(84, 58, 244, 0.1);
  margin: 100px auto;
  @media screen and (max-width:1136px){
    width: calc(100% - 60px);
  }
  @media screen and (max-width:600px){
    width: calc(100% - 40px);
    margin: 60px auto;
  }

  &__main-section-1 {
    text-align: center;
    margin: 50px auto;
    padding: 30px 30px 25px;

    &__title {
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      font-family: $primary-font;
      letter-spacing: -0.01px;
    }
    &__description {
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      margin-top: 10px;
    }
    &__des_blog {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: rgba(36, 37, 38, 0.6);
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__list-blog-icon {
      margin-right: 10px;
    }
    &__up-arrow-icon {
      margin-left: 15px;
    }
  }

  &__main-section-2 {
    padding: 0px 30px 50px;
    margin-top: -50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    text-align: center;
    position: relative;
    
    a{
      position: relative;
      @media screen and (min-width: 601px) and (max-width: 1136px) {
        height: 26.303vw;
      }
      @media screen and (max-width: 600px) {

      }
    }

    &__section-1 {
      width: 348px;
      height: 202px;
      border-radius: 15px;
      display: grid;
    }
    &__blog-img {
      border-radius: 15px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__img-description {
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      position: absolute;
      width: 100%;
      padding: 20px;
      bottom: 0;
      text-align: left;
    }
  }

  &__main-mobile-section {
    width: 100%;
    height: auto;

    &__main-section-3 {
      border-radius: 20px;
      margin: 50px auto;
      padding: 30px 20px;
    }

    &__titlepart {
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -0.01px;
    }

    &__descriptional-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      margin-top: 10px;
    }
    &__link-description {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: rgba(36, 37, 38, 0.6);
      max-width: 92%;
    }
    &__link-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
     
    }
    &__arrow-icon {
      font-size: 0px;
      margin-left: 10px;
    }
    &__cn__gp__discover__main-section-3__blog-text {
      text-align: left;
    }
    &__description_blog {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      color: rgba(36, 37, 38, 0.6);
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
