@import '../../global/mixins/global-mixins.scss';
@media screen  and (max-width: 600px) {
    .home-search-mobile {
        &.model {
            .model-inner {
                padding: 0;
                overflow-y: auto;
            }
        }
        .date-section {
            @media (max-width:400px) {
                padding: 1.25rem .625rem !important;
            }    
        }
        .home-mobile-calendar {
            .DayPicker_weekHeaders {
                display: grid;
                
                .DayPicker_weekHeader {
                    margin-top: 0;
                    border: 0;
                }
            }

            .CalendarMonthGrid div:nth-of-type(1) .CalendarMonth{
                    .CalendarMonth_caption {
                        margin-top:28px;
                    }
                
            }
            .DayPickerNavigation__verticalDefault {
                border-radius:0 !important;
                margin-left:-1.35rem;
                left:0;
                width: 100vw;
                @media (max-width:400px) {
                    margin-left: -0.75rem;
                }
            }
            .CalendarDay__selected_span::before {
                @media(min-width: 480px) {
                    left: -1.3125rem !important;     
                }
                
            }
            .CalendarDay {
                width: calc( 100vw / 9 ) !important;
                height: calc( 100vw / 8 ) !important;
                padding: 0.6875rem 0.75rem !important;
                @media(min-width: 480px) {
                    font-size: 1.125rem !important;     
                }
            }
            table {
                overflow: hidden;
            }
        }
        .home-search-mobile-model-close{
            position: absolute;
            right: 1.5625rem;
            top: 1.5625rem;
            z-index: 2;
        }
        .home-search-mobile-banner-sticky{
            position:sticky;  
            top: 0;
            z-index: 1;
            .breadcrumbs {
                background-color: $color-white;
                //width: 100%;
            }
        }


        .home-search-mobile-content{
            .add-child{
                input{
                    padding: 0 15px;
                }
            }
            .btn-text-bright-purple{
                &:hover{
                    background: none;
                }
            }
            .CalendarDay { 
                padding: 15px 8px;
                outline: none;
            }
             .DateRangePicker_picker { 
                z-index: 0;
            }
            .guests-input-box{
                height: auto;
            }
            .guests-dd-box span{
                width: 100% !important;
            } 
            .guest-section{
                .primary-input-reset, .guests-room{
                    border: none;
                }
            }
            .DayPicker_transitionContainer__vertical{
                overflow: unset;
            }
            .DayPickerNavigation__verticalDefault{
                //width: 100%;
                bottom: 0;
                border-radius: 10px;
                border: 2px solid #EBEAEB;
                &::before{
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 0;
                    background: #EBEAEB;
                    left: 50%;
                    z-index: 2;
                }
                .DayPickerNavigation_button__verticalDefault{
                    border-radius: 10px 10px 0 0;
                    box-shadow: none;
                    svg{
                        width: 1.25rem;
                        path{
                            stroke: #797882;
                            stroke-width: 40px;
                        }
                    }
                }

            }
        }
        .home-search-mobile-banner {
            background-image:url(#{$path-img}search-mobile-banner.jpg);
            background-color: $color-black;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 13.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                font-family: $secondary-font;
                font-size: 1.875rem;
                line-height: 2.375rem;
                font-weight: bold;
                color: $color-white;
                text-align: center;
                padding: 0 1.5rem;
                margin: 0;
                span {
                    color: $color-primary;
                }
            }
        }
        .breadcrumbs {
            padding: 0;
            border-bottom: 1px solid $color-light-gray;
            width: calc(100% - 2.5rem);
            margin: 0 1.25rem;
            @media(max-width:400px){
                width: calc(100% - 1.25rem);
                margin: 0 0.625rem;
            }
            ul {
                li {
                    font-variant: none;
                    text-transform: none;
                    margin-bottom: -0.125rem; 
                    @media screen  and (max-width: 375px) {
                        font-size:1rem;
                    }
                    &::after {
                        background-image:url(#{$path-img}breadcrumbs-arrow.svg);
                        content: "";
                        position: relative;
                        top: 1.25rem;
                        width: 6px;
                        height: 14px;
                        display: inline-block; 
                        padding: 0 15px;
                        background-repeat: no-repeat;
                        background-position: center;

                    }
                    &:last-child{
                        &::after {
                            display: none;
    
                        } 
                    }
                    a {
                        padding: 1.25rem 0 1.25rem 0;
                        display: inline-block;
                        vertical-align: top;
                        border-bottom: 0.3125rem solid transparent;
                    }
                    &.current {
                        a {
                            font-weight: 500;
                            color: $color-black;
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }
        .accommodation-section {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            padding: 1.875rem 1.25rem;
            .as--wrapper{
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.6s ease;
            }
            .accommodation-box {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                border: 1px solid $color-light-gray;
                @include box-shadow-light();
                margin: 0 0 1.5625rem 0;
                border-radius: 0.625rem;
                overflow: hidden;
                a {
                    display: flex;
                }
                .accommodation-img {
                    width: 39.2%;
                    background: $color-black;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .accommodation-text {
                    width: 60.8%;
                    padding: 1.25rem 0.9375rem;
                    h2 {
                        @include body-emphasized();
                        font-family: "Poppins", sans-serif;
                        color: $color-black;
                        padding: 0;
                        margin: 0 0 0.3125rem 0;
                        text-align: left;
                    }
                    p {
                        @include body-text();
                        color: $color-dark;
                        padding: 0;
                        margin: 0;
                    }
                }
                &:nth-child(2) {
                    a {
                        flex-flow: row-reverse;
                    }
                }
            }
        }
        .guest-section {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            padding: 1.875rem 1.25rem 6.5rem;
            position: relative;
            z-index: 0;
            .gs--wrapper{
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.6s ease;
            }
            ul {
                padding: 0;
                li {
                    display: grid;
                    grid-template-columns: 1fr 100px;
                    list-style: none;
                    align-items: center;
                    margin-bottom: 1.25rem;
                    grid-template-columns: 1fr 130px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        i {
                            font-style: normal;
                            display: block;
                            color: #807D82;
                        }
                    }
                    .plusminus-input {
                        box-shadow: none;
                        border: none;
                    }
                    &.add-child {
                        grid-template-columns: 1fr 26px !important;
                        span{
                            width: auto !important;
                            padding: 0 3px;
                        }
                          input {
                            width: 100% ;
                        }
                    }
                }
            }
            .guest-search-btn {
                position: absolute;
                bottom: 1.875rem;
                left: 1.25rem;
                right: 1.25rem;
                z-index: 1;
                .btn-primary {
                    width: 100%;
                }
            }

            .guests-dd-btns {
                display: none;
            }

            .guests-dd-box {
                border: none;
                box-shadow: none;
                position: relative;
                top: 0;
            }

              .guests-dd-box > input {
                padding-left: 10px;
            }

        }
    }
}