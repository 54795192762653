
body {
    position: absolute;
    width: 100%;
    height: 100%;
}

.cn__cancellation {
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    overflow-x: unset;
}
  .cn__shortfooter__wrapper .cn__shortfooter{
    padding: 0 50px 30px;
    @media screen and (max-width: 1136px) {
      padding: 0 30px 30px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 20px 30px;
    }
  }
  //Cookie banner
  .cn__cookie-wrapper{
    .cn__cookie{
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
        outline: 1px solid #DFE2E5;
    }
  }
}
