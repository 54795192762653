.checkbox {
    font-family: $primary-font;
    font-size: 1rem;
    &.checkbox--disabled {
        opacity: .5;
        pointer-events: none;
    }
    .checkbox_label {
        padding: rem(10);
        transition: background .3s ease;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    .checkbox_label > span {
        display: inline-block;
        vertical-align: middle;
    }

    .checkbox_label > .checkbox_checkmark {
        position: relative;
        width: rem(18);
        height: rem(18);
        box-shadow: inset 0 0 0 rem(1) $color-subtle-gray;
        border-radius: rem(4);
        transition: all .2s ease;
        min-width: rem(18);
        border: 1px solid $color-subtle-grey;
    }

    .checkbox_label > .checkbox_checkmark > svg {
        position: absolute;
        /* top: rem(4);
        left: rem(2); */
        width: 80%;
        height: auto;
        top: rem(3);
        left: rem(1);
        stroke-dasharray: 20px;
        stroke-dashoffset: 20px;
    }

    .checkbox_label > .checkbox_label-text {
        margin-left: rem(10);
        display: flex;
        align-items: center;
        font-size: rem(14);
    }

    // .checkbox_label:hover > .checkbox_checkmark {
    //     box-shadow: inset 0 0 0 rem(1) $color-bright-purple;
    // }

    .checkbox_input {
        display: none;
    }

    .checkbox_input:checked + .checkbox_label > .checkbox_checkmark {
        box-shadow: none;
        background: $color-black;
    }

    .checkbox_input:checked + .checkbox_label > .checkbox_checkmark > svg {
        stroke-dashoffset: 0;
        transition: all .2s ease;
        path {
            stroke: $color-white;
        }
    }
}
