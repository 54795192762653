//------------------- DESKTOP -------------------

.cn__dashboard_currency_setion {
    position: relative;

    &__title{
        font-weight: 500;
        //@include nav-font();
        position: relative;
        color: #FFFFFF;
        cursor: pointer;
        //Font
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.01em;

        &::after{
            // position: absolute;
            width: -webkit-calc(100% + 16px);
            width: calc(100% + 24px);
            height: -webkit-calc(100% + 8px);
            height: calc(100% + 8px);
            top: -4px;
            left: -12px;
            content: "";
            background: rgba(255, 255, 255, 0.2);
            border-radius: 18px;
            z-index: -1;
            opacity: 0;
            transition: opacity 0.4s ease;
        }

        &:hover{
            &::after{
                opacity: 1;
            }
            & + div{
                // bottom: -38px;
                opacity: 1;
            }
        }

    }

    &__tooltip{
        position: absolute;
        top: 30px;
        // bottom: -48px;
        left: 50%;
        transform: translateX(-50%);
        background: #FFFFFF;
        width: 99px;
        border-radius: 5px;
        opacity: 0;
        // transition: opacity 0.3s ease, bottom 0.4s ease;
        transition: opacity 0.3s ease;

        //---- Triangle
        &::before{
            position: absolute;
            left: 50%;
            top: -7px;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 8px solid #FFFFFF;
            transform: translateX(-50%);
        }

        &__title{
            font-size: 10px;
            line-height: 1;
            color: #797882 !important;
            padding: 5px 10px;
            text-align: center;
            cursor: default;
        }
    }

    &.cn__dashboard_currency_setion--dark {
        .cn__dashboard_currency_setion__title{
            color: #242526;
            transition: color 0.3s ease;
            &:hover{
                color: #FFFFFF;
                &::after{
                    background: #242526;
                }
            }
        }
        .cn__dashboard_currency_setion__tooltip{
            background: #242526;
            &::before{
                border-bottom: 8px solid #242526;
            }

            &__title{
                color: #FFFFFF !important;
            }
        }
    }

    &__portal{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(36, 37, 38, 0.2);
        display: grid;
        place-items: center;
        z-index: 5000;
        transition: opacity 0.6s ease;
        opacity: 1;
        overflow: hidden;

        // &.cn__currencydesktop--active{
        //     opacity: 1;
        // }

        &__box{
            height: 100%;
            overflow-y: auto;        
            position: relative;
            max-width: 1122px;
            background: #FCFCFC;
            border-radius: 20px;
            // overflow: hidden;
            opacity: 1;
            transition: opacity 0.3s ease, transform 0.4s cubic-bezier(0.26,0.67,0.48,0.91);
 
            &__header{
                position: relative;
                padding: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);
                border-radius: 10px 10px 0px 0px;

                &__title{
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 1;
                    letter-spacing: -0.01em;
                    color: #242526;
                }

                svg{
                    cursor: pointer;
                }
            }

            &__info{
                position: relative;
                width: 100%;
                padding: 30px;

                &__top{
                    padding-bottom: 14px;
                    // border-bottom: 0.5px solid #E9E9EB;

                    &__title{
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 1;
                        color: #242526;
                    }

                    &__currencies{
                        margin-top: 10px;
                        display: grid;
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                        grid-column-gap: 20px;
                        max-width: 76%;
                    }
                }

                &__bottom {
                    position: relative;
                    padding: 20px 0 0 0;

                    &__title{
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 1;
                        color: #242526; 
                    }

                    &__currencies{
                        margin-top: 10px;
                        display: grid;
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                        grid-gap: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-height:650px) {
    .cn__dashboard_currency_setion {

        &__portal{

            &__box{
                height: 96vh;

                &__header{
                    padding: 20px 30px;
                }
            }
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__dashboard_currency_setion {

        &__portal{

            &__box{
                width: calc(100% - 60px);
                height: auto;
                max-height: unset;

                &__info{

                    &__top{

                        &__currencies{
                            grid-column-gap: 10px;
                            grid-template-columns: repeat(1, minmax(0, 1fr));
                        }
                    }

                    &__bottom {

                        &__currencies{
                            grid-gap: 10px;
                            grid-template-columns: repeat(1, minmax(0, 1fr));
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 680px){
    .cn__dashboard_currency_setion {

        &__portal{

            &__box{
                height: 100%;
                overflow-y: auto;
                width: 100%;
                // width: calc(100% - 60px);

                &__header {
                    display: none;
                }

                &__info{
                    .cryptocurrency_title {
                        padding-left: unset;
                    }
                    &__top{
                        &__title{
                            font-size: 14px;
                        }
                        &__currencies{
                            grid-column-gap: 10px;
                        }
                    }

                    &__bottom {
                        &__title{
                            font-size: 14px;
                        }
                        &__currencies{
                            grid-gap: 10px;
                        }
                    }
                }
            }
        }
    }
}
