//------------------- DESKTOP -------------------

.cn__CTA__login__wrapper {
    position: absolute;
    top: 80px;
    right: 100px;
    z-index: 3;
    @media screen and (min-width:1920px){
        right: calc(50% - 860px);
    }
}

.cn__CTA__login{
    position: relative;
    margin-top: 10px;
    opacity: 0;
    visibility: hidden;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 10px 30px 12px 30px;
    color: #242526;
    width: auto;
    min-width: 295px;
    max-width: 100%;
    transition: visibility 0.3s ease, margin-top 0.4s ease-in-out;

    &.cn__CTA__login--show{
        opacity: 0;
        transition: visibility 0.3s ease, margin-top 0.4s ease;
        margin-top: 0;
        visibility: visible;
        opacity: 1;
    }

    //---- Triangle
    &::before{
        position:absolute;
        right:10px;
        top:-13px;
        content:'';
        display:block;
        width:0;
        height:0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 14px solid #FFFFFF;
    }


    &__close{
        position: absolute;
        top: 0px;
        right: 2px;
        margin-right: 0 !important;
        width: rem(32);
        height: rem(32);
        display: grid;
        place-items: center;

        svg{
            cursor: pointer;
            pointer-events: auto;
            width: rem(12);
            height: rem(12);

            path {
                stroke: $color-black;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
            }
        }
    }

    &__header{
        display: flex;
        align-items: center;
        &__icon{
            flex: 0 0 auto;
            padding: 4px 7px;
            background: rgba(243, 85, 151, 0.11);
            border-radius: 50%;
            svg{
                position: relative;
                top: 2px;
                path{
                    fill: none !important;
                }
            }
        }
        &__title{
            flex: 1;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: #242526;
            margin-left: 14px;
        }
    }

    &__info{
        position: relative;
        max-width: 262px;
        margin: 0 auto;
        margin-top: 10px;
        //font
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #797882;
        span{
            font-weight: 500;
            color: #7868c7;
        }
    }

    button{
        position: relative;
        display: block;
        pointer-events: initial;
        padding: 9px 29px;
        margin: 0 auto;
        margin-top: 10px;
        outline: none;
        border: none;
        border-radius: 100px;
        background: $color-subtle-grey;
        cursor: pointer;
        //font
        color: #FFFFFF;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        font-family: $primary-font;
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__CTA__login__wrapper {
        padding: 0 30px;
        right: 0;
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__CTA__login__wrapper {
        top: 66px;
        right: 0px;
        padding: 0 20px;
        z-index: 4;
    }
    .cn__CTA__login{
        padding: 10px 34px 12px 14px;
        min-width: auto;

        &__close{
            top: 2px;
        }

        &__header{
            display: none;
        }

        &__info{
            margin-top: 0;
            max-width: 170px;
            font-size: 10px;
            line-height: 150%;
        }

        button{
            padding: 5px 25px;
            font-size: 10px;
            line-height: 150%;
        }
    }
}