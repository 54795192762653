//------------------- DESKTOP/TABLET -------------------

.header-home{
    + .cn__hp__hero  {
        .omnisearch_content {
            visibility: visible;
        }
    }


    top: unset !important;
    //wrapper
    .max-width-wrapper{
        max-width: 1920px;
    }
    // Header elements
    .header-logo{
        .logo-pink, .logo-purple {
            fill: #FFFFFF !important;
        }
    }
    .hamburguer-menu{
        .hamburguer-menu-lin1, .hamburguer-menu-lin2, .hamburguer-menu-lin3 {
            background: #FFFFFF !important;
        }
    }
    .header-nav > ul li a{
        color:#FFFFFF;
        svg{
            .rect1,.blog1,.blog2,.listsvg, .svghouse, circle:not(.logged-circle){
                stroke:#FFFFFF
            }
            .rect2,path:not(.listsvg,.svghouse,.menu-first){
                fill: #FFFFFF;
            }
        }
    }

    .header-omnisearch {
        opacity: 0;
        transition: 100ms ease;
        display: none;
    }
    //When header fixed
    &.cn__header--fixed{
        + .cn__hp__hero  {
            .omnisearch_content {
                display: none;
            }
        }

        position: fixed;
        background: #FFFFFF;
        top: -89px !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        transition: top 0.6s ease;

        .header-omnisearch {
            opacity:1;
            display: block;
            .omnisearch_content.omnisearch_content--desktop {
                margin-left:0px;
                margin-right:0px;
                border: 1px solid #ACABB4;
                box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
                .omnisearch_content_input-group-area {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    grid-template-columns: 1fr 1fr 1fr max-content;
                    max-width: 752px;

                    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #242526;
                        font-weight: 600;
                        opacity: 1; /* Firefox */
                    }

                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #242526;
                        font-weight: 600;
                    }

                    ::-ms-input-placeholder { /* Microsoft Edge */
                        font-weight: 600;
                        color: #242526;
                    }

                    .omnisearch_content_input-area_labelled-input-wrapper,
                    .omnisearch_content_input-area_labelled-input-wrapper,
                    &:not(.omnisearch_content_input-area_input-slot--active)
                    .floating-input_input {
                            font-weight: 600;
                            color: #242526;
                            * {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-weight: 600;
                                color: #242526;
                            }
                        }


                    .omnisearch_content_search-action-area {
                        width:35px;
                        height:35px;
                    }
                }
            }
        }
        .header-logo{
            .logo-pink {
                fill: #fa368b !important;
            }
            .logo-purple {
                fill: #4c35bc !important;
            }
        }
        .hamburguer-menu{
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #242526 !important;
            }
        }
        .header-nav > ul li a{
            color:#242526;
            font: revert;
            text-transform: uppercase;
            font-size: 14px;
            p {
                white-space: nowrap;
            }
            svg{
                .rect1,.blog1,.blog2,.listsvg, .svghouse, circle:not(.logged-circle){
                    stroke:#242526
                }
                .rect2,path:not(.listsvg,.svghouse,.menu-first){
                    fill: #242526;
                }
            }
        }
        .cn__header__logicon{
            background: $color-black;
            svg{
                path{
                    fill: $color-white !important;
                }
            }
        }

        //Currency
        .cn__currency__desktop__title{
            color: #242526;
            transition: color 0.3s ease;
            &:hover{
                color: #FFFFFF;
                &::after{
                    background: #242526;
                }
            }
        }
        .cn__currency__desktop__tooltip{
            background: #242526;
            &::before{
                border-bottom: 8px solid #242526;
            }

            &__title{
                color: #FFFFFF !important;
            }
        }
        //When appearing fixed header
        &.cn__header--active{
            top: 0 !important;
            z-index: 1;
        }

        .header-grid .header-nav .omnisearch.header-omnisearch
        {
            margin-right:2em;
        }

        .omnisearch_input-wrapper_mobile_trigger {
            margin-top: 0px;
            height: 40px;
            min-height: 0px;
            border: 1px solid #ACABB4;
            box-shadow: 0px 6px 14px -6px rgb(24 39 75 / 12%);
            padding-left: 15px;
            padding-right: 10px;
            svg {
                background-color: #F35597;
                border-radius: 25px;
                overflow: hidden;
                width: 25px;
                height: 25px;
                padding: 2px;
                 path {
                     fill: #fff;;
                 }
            }
        }

        .omnisearch_content_input-area_labelled-input-wrapper {
            text-overflow: ellipsis;
            width: auto;
            overflow: hidden;
            white-space: nowrap;
        }

        @media screen and (max-width:1400px) and (min-width:764px) {
            top:-144px !important;
            .header-grid .header-nav {
                grid-column: 4/17;
                > ul li:not(:first-child) {
                  margin:0 0 0 .5em;
                }
            }

        }
        @media screen and (max-width:1180px) and (min-width:764px){
            .header-grid .header-nav {
                grid-column: 6/17;
                > ul li:not(:first-child)  {
                  margin:0 0 0 .5em;
                }
                .omnisearch.header-omnisearch {
                    margin-right:1em;
                }
            }
        }

        @media screen and (max-width: 764px){
            .header-grid .header-nav {
                grid-column: 7/17;
               .omnisearch.header-omnisearch {
                    margin-right:.5em;
                    width: 100%;
               }
            }
        }

        @media screen and (min-width: 601px) and (max-width: 780px){
            .header-grid .header-nav > ul li {
                margin: 0 0 0 .5em;
            }
        }
        @media screen and (max-width: 600px){
            &.header-mob {
                grid-template-columns: 1fr 1fr max-content;
                grid-column: none;
               .omnisearch.header-omnisearch {
                   width: 100%;
                   grid-template-columns: auto;
               }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .header-home{
        margin-top: 50px;
        //When header fixed
        &.cn__header--fixed{
            margin-top: 0;
            top: -85px !important;
        }
    }
}