@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';

.close {
    position: absolute;
    display: flex;
    height: 14px;
    width: 14px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: 6px;

    svg {
        height: 100%;
        width: 100%;
        // transform: scale(1.3);
    }

    &:hover {
        cursor: pointer;
    }
}

.content {
    text-align: center;
    color: $color-white;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
}

