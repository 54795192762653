@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp7{
    position: relative;
    width: 100%;
    background: var(--color-background2);
    padding: 0 0 dvw(88);
    color: #000000;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__title{
        font-weight: 700;
        font-size: dvw(54);
        line-height: 1.2;
        text-align: center;
        max-width: dvw(1030);
        margin: 0 auto;
    }

    &__subtitle{
        font-size: dvw(23);
        line-height: 1.2;
        text-align: center;
        margin: 0 auto;
        margin-top: dvw(50);
        max-width: dvw(745);
    }

    &__map{
        position: relative;
        display: block;
        width: 100%;

        &__wrapper{
            position: relative;
            width: 80%;
            margin: dvw(50) auto 0;
        }

        &__card {
            position: absolute;
            display: flex;
            align-items: flex-start;
            padding: 0.6vw 1.8vw 0.6vw 0.7vw;
            background: #FCFCFC;
            border: dvw(1) solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 22px 14px -17px rgba(0,0,0,0.31);
            border-radius: dvw(15);
            color: #64748B;

            svg{
                width: dvw(18);
                height: dvw(18);
                margin-right: dvw(10);

                path,rect{
                    fill: #64748B;
                    stroke: #64748B;
                }
                &.lypmaphotel{
                    path{
                        stroke: none;
                    }
                }
            }

            &__info{
                &__title{
                    font-weight: 600;
                    font-size: dvw(18);
                    line-height: 1;
                }
    
                &__subtitle{
                    color: #94A3B8;
                    font-size: dvw(12);
                    line-height: 1;
                    margin-top: dvw(8);
                }
            }
        }
    }

    &__subtitle2{
        font-size: dvw(16);
        line-height: 1;
        text-align: right;
        margin-top: dvw(50);
    }
}


@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp7{
        padding: 0 0 150px;
    
        &__title{
            font-size: 54px;
            padding: 0 125px;
        }
    
        &__subtitle{
            font-size: 23px;
            margin-top: 50px;
            padding: 0 300px;
        }
    
        &__map{
    
            &__wrapper{
                margin: 50px auto 0;
            }
    
            &__card {
                padding: 8px 32px 8px 8px;
                border: 2px solid #FFFFFF;
                border-radius: 15px;
    
                svg{
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
    
                &__info{
                    &__title{
                        font-size: 18px;
                    }
        
                    &__subtitle{
                        font-size: 14px;
                        margin-top: 3px;
                    }
                }
            }
        }
    
        &__subtitle2{
            font-size: 16px;
            margin-top: 50px;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp7{
    
        &__wrapper{
            padding: 0 30px;
        }
    
        &__map{
            width: 100%;
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp7{
        padding: 0 0 mvw(60);

        &__wrapper{
            padding: 0;
        }

        &__title{
            font-size: mvw(32);
            text-align: left;
            padding: 0 20px;
            max-width: 100%;
        }
    
        &__subtitle{
            font-size: mvw(16);
            line-height: 1.5;
            text-align: left;
            margin-top: mvw(20);
            padding: 0 20px;
            max-width: 100%;
        }
    
        &__map{
            width: 100%;
            margin: 0;

            &__wrapper{
                position: relative;
                flex-shrink: 0;
                width: mvw(852);
            }

            &__card {
                padding: 2vw 6vw 2vw 2.5vw;
                border: 0.5vw solid #FFFFFF;
                border-radius: 3vw;
    
                svg{
                    width: 4vw;
                    height: 4vw;
                    margin-right: 2vw;
                }
    
                &__info{

                    &__title{
                        font-size: 4.3vw;
                    }
        
                    &__subtitle{
                        font-size: 3.2vw;
                        margin-top: 1.5vw;
                    }
                }
            }
        }

        .cn__slider{
            position: relative;
            margin-top: mvw(24);

            .cn__slider__cards{
                padding: 7vw 0 30px 16px;
            }
        }
    
        &__subtitle2{
            font-size: mvw(10);
            line-height: 1.5;
            margin-top: mvw(30);
            padding: 0 20px 0 43%;
        }
    }
}