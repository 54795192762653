//------------------- DESKTOP -------------------

.cn__faq{
    position: relative;
    padding: 30px 30px 20px 30px;
    border-radius: 15px;
    background: #FFFFFF;

    &__title{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #242526;
    }

    &__questions{
        position: relative;
        margin-top: 30px;

        &__question{
            border-top: 1px solid #E9E9EB;
            margin-bottom: 10px;
            padding-top: 20px;

            &:first-child{
                padding-top: 0px;
                border-top: none;
            }

            &:last-child{
                margin-bottom: 0;
            }

            &__question{
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                padding-bottom: 10px;

                &__title{
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    color: #242526;
                }

                svg{
                    flex-shrink: 0;
                    transition: transform 0.3s ease;
                }
            }

            &__answer{
                position: relative;
                width: 100%;
                height: auto;
                max-height: 0;
                font-size: 16px;
                line-height: 1.5;
                color: #797882;
                overflow: hidden;
                transition: all 0.3s ease;
                padding-right: 20px;
            }

            &.cn__faq--question-active{
                .cn__faq__questions__question__question{
                    svg{
                        transform: rotate(90deg);
                    }
                }
                .cn__faq__questions__question__answer{
                    max-height: 500px;
                }
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__faq{
        width: calc(100% - 60px);
        margin: 0 auto;
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__faq{
        padding: 20px 20px 10px 20px;
        width: calc(100% - 40px);

        &__questions{
            margin-top: 22px;

            &__question{

                &__question{
                    align-items: flex-start;

                    &__title{
                        padding-right: 10px;
                    }

                }
            }
        }
    }
}
