@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__card2{
    position: relative;
    padding: dvw(35) dvw(20);
    background: #FFFFFF;
    border: dvw(1) solid #DFE2E5;
    border-radius: dvw(15);
    transition: all 0.4s ease;
    cursor: pointer;
    flex-shrink: 0;

    &__icon{
        position: relative;
        svg{
            width: dvw(48);
        }
    }
    &__title{
        font-weight: 600;
        font-size: dvw(28);
        line-height: 1.2;
        color: #0F294D;
        margin-top: dvw(30);
    }
    &__subtitle{
        font-size: dvw(16);
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: #000000;
        margin-top: dvw(10);
    }

    &:hover{
        background: linear-gradient(45.83deg, #FF958A 0%, #FFC5BF 98.59%);
        box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25); 
        border: 0.5px solid transparent;
        transform: translateY(-5px);
        .cn__lyp__card2__icon{
            svg{
                .lypir2{
                    stroke: white;
                }
                .lypir1{
                    fill: white;
                }
            }
        }
        .cn__lyp__card2__title, .cn__lyp__card2__subtitle{
            color: #FFFFFF;
        }
    }
}

@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__card2{
        padding: 34px 20px;
    
        &__icon{
            svg{
                width: 48px;
            }
        }
        &__title{
            font-size: 28px;
            margin-top: 30px;
        }
        &__subtitle{
            font-size: 16px;
            margin-top: 10px;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__card2{
        &:hover{
            background: #FFFFFF;
            border: 0.5px solid #DFE2E5;
            box-shadow: none; 
            transform: none;
            .cn__lyp__card2__icon{
                svg{
                    .lypir2{
                        stroke: #FC9595;
                    }
                    .lypir1{
                        fill: #FDE9E0;
                    }
                }
            }
            .cn__lyp__card2__title{
                color: #0F294D;
            }
            .cn__lyp__card2__subtitle{
                color: #000000;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__card2{
        margin: mvw(20) 0 0 mvw(10);
        padding: mvw(35) mvw(18);
        width: mvw(279);
        border-radius: mvw(15);
    
        &__icon{
            position: relative;
            svg{
                width: mvw(48);
            }
        }
        &__title{
            font-size: mvw(28);
            margin-top: mvw(30);
        }
        &__subtitle{
            font-size: mvw(16);
            margin-top: mvw(10);
        }
    }
}