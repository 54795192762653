.action-bar {
    display: flex;
    justify-content: space-between;
    margin: 0 rem(20);
    padding: rem(20) 0;
    border-top: rem(1) solid $color-light-gray-input;
    .action-bar_btn {
        border: none;
        background: none;
        outline: none;
        color: $color-black;
        font-size: 1rem;
        font-weight: 400;
        font-family: $primary-font;
        line-height: rem(20);
        cursor: pointer;
        border-radius: rem(6);
        padding: rem(5) rem(10);
        &:hover {
            background: $color-light-gray-input;
        }
        &.action-bar_btn__primary {
            color: $color-primary;
            font-weight: 600;
        }
    }
}