//------------------- DESKTOP -------------------

.cn__hp__theendof{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    .cn__slider {
        position: relative;
        margin-top: 25px;
    }
    &__slider{
        position: relative;
        margin-top: 25px;
        width: 100%;
        .slick-list{
            overflow: hidden;
            margin-left: -8px;
            padding: 10px 0;
        }
        .slick-track{
            display: inline-flex;
        }
        .slick-arrow{
            background-color: transparent;
            transform-origin: center center;
            border: 2px solid rgba(121, 120, 130, 0.6);;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 0;
            svg{
                height: 10px;
                path{
                    stroke: rgba(121, 120, 130, 0.6);;
                    stroke-width: 4px;
                }
            }
            &.slick-next{
                position: absolute;
                right: 0;
                top: -44px;
                display: inline-flex;
                svg{
                    margin-left: 2px;
                }
            }
            &.slick-prev{
                position: absolute;
                right: 38px;
                top: -44px;
                display: inline-flex;
                left: auto;
                svg{
                    margin-left: -2px;
                }
            }
            &.slick-disabled{
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
    &__card{
        position: relative;
        flex-shrink: 0;
        width: 18.75vw;
        height: 18.75vw;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0,0,0);
        border-radius: 15px;
        transition: all 0.35s ease;
        a{
            color: #242526;
            .cn__image-default{
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: brightness(0.9);
                transition: all 0.6s ease;
            }
            .cn__hp__thendof__card__info{
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 0 0.6vw 1.1vw 1.1vw;
                color: #FFFFFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__title{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    font-feature-settings: unset;
                    font-variant: unset;
                    text-transform: unset;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        &:hover{
            transform: scale(0.98);
            a{
                img{
                    filter: brightness(0.95);
                }
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }
    }

    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 10px 0;
            display: flex;
            width: 100%;
            overflow-x: unset;
            -ms-scroll-snap-type: unset;
            scroll-snap-type: unset;
            scroll-padding: unset;
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__theendof{
        margin: 60px auto;
        padding: 0;
        &__card{
            width: 270px;
            height: 270px;
            flex-shrink: 0;
            margin-left: 15px;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            &:first-child{
                margin-left: 30px;
            }
            a{
                .cn__hp__thendof__card__info{
                    padding: 0 12px 15px 20px;
                }
            }
            &:hover{
                transform: none;
                box-shadow: none;
                a{
                    img{
                        filter: brightness(0.7);
                    }
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__theendof{
        &__card{
            width: 163px;
            height: 163px;
            margin-left: 10px;
            &:first-child{
                margin-left: 20px;
            }
            a{
                .cn__hp__thendof__card__info{
                    padding: 0 2px 10px 10px;
                    align-items: flex-end;
                    &__title{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        -webkit-line-clamp: 2;
                    }
                    img{
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #FFFFFF;
            visibility: visible !important;
            height: 24px;
            width: calc(100% - 40px);
            bottom: -14px;
            margin-left: 20px;
            overflow: visible;
            pointer-events: all;
            cursor: pointer;
            z-index: 0;
            &::before{
                position: absolute;
                content: '';
                width: calc(100% + 40px);
                height: 100%;
                left: -20px;
                background: #FFFFFF;
                z-index: -1;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: 1px;
                background: #F4F4F5;
                border-radius: 5px;
                z-index: -1;
            }
            .simplebar-scrollbar{
                width: 100%;
                height: 4px;
                top: auto;
                bottom: 0;
                border-radius: 5px;
                background: #f466a2;
                &::before{
                    display: none;
                }
            }
        }
    }
}
