

.request_call_dialog_overlay {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    padding:0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    .thankyou_call_dialog {
        background: $color-white;
        width:666px;
        max-width: 100%;
        min-height: 275px;
        box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
        border-radius: 20px;
        position: relative;
        padding:20px;
        
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &__close_icon {
            position: absolute;
            top:25px; 
            right:25px;
            width:12px;
            height:12px;
            cursor: pointer;
            * {
               stroke: $color-black; 
            }
        }

        &__content-details {
            svg {
                margin-bottom: 20px;
            }
            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                text-align: center;
                color: $color-black;  
                margin-bottom: 5px;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: $color-gray-hp;
            }
        }

        .cn__button {
            width: 200px;
            margin:0px auto; 
            margin-top: 25px;
            border-radius: 10px;
            background: $color-primary;
            .cn__button__title {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: $color-white;
                margin-left:0px;
            }
            
        }
    }

    .request_call_dialog {
        max-width: 1262px;
        height:769px; 
        position: relative;
        transform: translateY(0);
        width:100%;
        max-height:100%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F0F8FF;
        border-radius: 20px;
        padding: 0px 0px;

        
        &__inner-wrapper {
            margin:0px auto;
            position: relative;
            max-width: 1015px;
            padding:38px 15px;

            &__header {
                text-align: center;
                h1 {
                    font-weight: 700;
                }
                &__close-icon {
                        position: absolute;
                    right:15px;
                    top:20px;
                    cursor: pointer;
                    width:12px;
                    height: 12px;
                    * {
                        stroke: #000;
                    }
                }
            }

            .request_call__form { 
            
                
                .request_call__form__wrap {
                    display: flex;
                    flex-wrap: wrap;
                }
                &__container {
                    padding:0px 10px;
                    width: 100%;
                    &.col-2 {
                        width:100%;
                        @media (min-width:760px) {
                            width:50%
                        }
                    }
                    display: flex;
                    flex-direction: column;
                    label:not(.request_call__form__fields) {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        padding-left: 20px;
                        display: inline-block;
                        font-size: 16px;
                        color: $color-dark-grey;
                        margin-bottom: 5px;
                        span {
                            color: $color-danger;
                        }
                    } 
                    
                    &__radio-group {
                        display: flex;
                        justify-content: space-between;
                    }
                    .request_call__form__fields {
                        &:focus {
                            outline-color: #000;
                        }
                        &__error {
                            color: $color-error-red;
                            text-align: right;
                            margin-right: 15px;
                            font-style: italic;
                            font-weight: 600;
                            min-height: 18px;
                            font-size: 12px;
                        }
                        .phone-input__container {
                            width: 100%;
                            input.phone-input__input:focus {
                                outline: auto;
                            }
                            .phone-input__button {
                                background-color: transparent;
                                border: none;
                                
                                .selected-flag:hover, .selected-flag:focus {
                                    background-color: transparent;
                                }
                            }
                        }
                        &.radio {
                            max-width: 30%;
                            background: none;
                            padding:0px;
                            input[type="radio"] {
                                display: none;
                            }
                                            
                            .radio_label {
                                background: #FFFFFF;
                                border: 2px solid #DFE2E5;
                                box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                                border-radius: 100px;
                                width: 147px;
                                max-width: 100%;
                                height: 46px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                color: $color-subtle-grey;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            input[type="radio"]:checked +  .radio_label{
                                border-color: $color-black;
                                color: $color-black;
                            }
                        }
                        
                    }                  
                    input.request_call__form__fields, .textarea.request_call__form__fields  {
                        background: $color-white;
                        width: 100%;
                        border: 1px solid $color-field-grey;
                        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                        border-radius: 100px;
                        height: 46px;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        padding:0px 30px;
                        line-height: 150%;
                        color: $color-subtle-gray;
                    } 
                    .textarea.request_call__form__fields {
                        resize: vertical;
                        height: 128px;
                        min-height: 128px;
                        background: $color-white;
                        border: 1px solid $color-field-grey;
                        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                        border-radius: 26px;
                        padding-top:15px;
                        padding-bottom: 15px;
                    }
                    .request_call__form-limit {
                        position: relative;
                        .request_call__form__limit-indicator {
                            position: absolute;
                            bottom:15px;
                            right:17px;
                            background-color: $color-white;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            color: $color-subtle-light-grey;
    
                        }
                    }

                    .request_call__form__fields__checkbox {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        color: $color-dark-grey;
                        user-select: none;
                        .request_call__form__fields__checkbox-box {
                            border: 1px solid $color-black;
                            border-radius: 4px;
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 13px;
                            text-align: center;
                            svg {
                                width:10px;
                                opacity: 0;
                                transition: opacity 300ms ease;
                                path {
                                    stroke-width: 3px;
                                    stroke: $color-white;
                                }
                            }
                        }
                        
                        input[type="checkbox"] {
                            display: none;
                            &:checked + .request_call__form__fields__checkbox-box {
                                background: $color-black;
                                svg {
                                    opacity: 1;
                                }
                            }
                        }
                    
                    }
                    
                }

                &__actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    .cancel_btn {
                        background: $color-white;
                        margin-right:25px;
                        .cn__button__title {
                            color: $color-cn-pink;
                        }
                    }
                    .cn__button {
                        height: 46px;
                        width: 210px;
                        max-width: 50%;
                        border-radius: 30px;
                        &:disabled {
                            opacity: 0.5;
                            cursor: not-allowed;
                        }
                        .cn__button__title {
                            margin-left: 0px;
                        }
                    }
                }
                    
            }
        }
        
    }

}
