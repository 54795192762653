.cn__wishlist__message{
    position: fixed;
    right: -100vw;
    top: 30px;
    padding: 30px 20px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    transition: all 0.6s ease;
    .cn__wishlist__message__title{
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin-right: 50px;
        span{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            word-break: break-word;
            height: 16px;
            margin-right: 5px;
            float: left;
        }
    }
    .cn__wishlist__message__cancel{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color:#4C35BC;
        margin-right: 20px;
        cursor: pointer;
    }
    .cn__wishlist__message__close{
        cursor: pointer;
    }
}
.cn__wishlist__message__overlay{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    right: -100vw;
    z-index: 5;
}
@media screen and (max-width: 1135px)  {
    .cn__wishlist__message{
        width: calc(100% - 60px);
    }
}

@media screen and (max-width: 600px)  {
    .cn__wishlist__message{
        width: calc(100% - 40px);
        .cn__wishlist__message__title{
            margin-right: 30px;
        }
    }
}