//------------------- DESKTOP -------------------

    .cn__hp__learnmore{
        position: relative;
        width:100%;
        max-width: 1920px;
        padding: 0 100px;
        margin: 100px auto;
        display: flex;
        justify-content: space-between;

        &__reference{
            &__wrapper{
                position: sticky;
                top: 39vh;
                opacity: 0;
                margin-right: 100px;
                width: 608px;
            }
            &__title{
                font-weight: 600;
                font-size: 32px;
                line-height: 1.2;
                color: #000000;
            }
            &__subtitle-wrapper {
                display: flex;
                justify-content: center;
                margin-top: 50px;
            }
            &__subtitle{
                background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                border-radius: 10px;
                font-size: 14px;
                line-height: 150%;
                font-weight: 600;
                a{
                    display: block;
                    padding: 8px 58px;
                    color: $color-white;
                }
            }
        }
        &__images{
            position: relative;
            &__info{
                opacity: 0;
                margin-top: 40px;
                &:first-child{
                    margin-top: 0!important;
                }
                &__title{
                    font-weight: 800;
                    font-size: 69px;
                    line-height: 1;
                    background: linear-gradient(180deg, #F35597 0%, #7868C7 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    // color: #F178B6;
                    padding-left: 2px;
                    display: initial;
                }
                &__subtitle{
                    font-size: 23px;
                    line-height: 1;
                    color: #797882;
                    //margin-top: 5px;
                }
            }
        }
    }

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__learnmore{

        padding: 0 30px;
        margin: 60px auto;

        &__reference{
            &__wrapper{
                margin-right: 60px;
                width: calc(100% - 60px);
            }
            &__title{
                font-size: 24px;
                line-height: 30px;
            }
            &__subtitle{
                font-size: 16px;
                line-height: 19px;  
            }
        }
        &__images{
            position: relative;
            &__info{
                margin-top: 15px;
                &__title{
                    font-size: 50px;
                    line-height: 58px;
                }
                &__subtitle{
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:763px){
    .cn__hp__learnmore{

        padding: 0 20px;
        margin: 60px auto;
        flex-direction: column;

        &__reference{
            &__wrapper{
                margin-right: 0;
                width: 100%;
            }
            &__title{
                font-size: 18px;
                line-height: 22px;
                @media screen and (min-width:601px) and (max-width:764px){
                    font-size: 32px;
                    line-height: 40px;
                }
            }
            &__subtitle{
                font-weight: 600;
                margin-top: 17px;
            }
        }
        &__images{
            position: relative;
            margin-top: 59px;
            &__info{
                margin-top: 19px;
                &:first-child{
                    margin-top: 59px;
                }
                .cn__hp__learnmore__images__info__title{
                    font-size: 44px;
                    line-height: 1.2;
                }
            }
        }
    }
}