.cn__gp__card {
    border-radius: 20px;
    box-shadow: 0 2px 2px rgb(87 75 144 / 10%);
    margin: 0 auto .625rem;
    overflow: hidden;
    transition: all .35s ease;
    cursor: pointer;
    &:hover{
        box-shadow: 0 30px 20px -20px rgb(87 75 144 / 15%);
        transform: translateY(-5px);
    }
    &__image{
        position: relative;
        background: rgba(121, 120, 130, 0.6);
        height: 13.195vw;
        max-height: 190px;
        border-radius: 20px 20px 0px 0px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(0.8);
        }
        &__amenityleft{
            position: absolute;
            left: 15px;
            bottom: 15px;
            display: flex;
        }
        &__sec1{
            position: absolute;
            left: 15px;
            bottom: 15px;
            display: flex;
            &__rate{
                padding: 10px;
                background: #ffffff;
                border-radius: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #242526;
                margin-right: 10px;
            }
            &__rateinfo{
                color:#ffffff;
                &__status{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-white;
                }
                &__rates{
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: #E9E9EB;
                }
            }
        }
        &__sec2{
            position: absolute;
            right: 15px;
            bottom: 15px;
            display: flex;
            align-items: center;
        }
    }
    &__info{
        background: #ffffff;
        padding: 15px 20px 20px;
        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #242526;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        &__rating{
            margin-top: 5px;
        }
        &__type{
            margin-top: 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
            color: #ACABB4;
        }
        &__distance{
            margin-top: 5px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.02em;
            color: #ACABB4;
        }
        &__price{
            margin-top: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #242526;
            text-align: right;
            span{
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
    &.card1{
        .cn__gp__card__info{
            &__price{
                text-align: left;
            }
        }
    }
    &.card3{
        background: #EEEEF0;
        border-radius: 15px;
        margin: 0;
        box-shadow: unset;
        padding: 15px 5px 15px 15px;
        margin-left: 20px;
        overflow: unset;
        color: #242526;
        .cn__gp__icon, .cn__gp__card__selection{
            opacity: 0.6;
            transition: opacity 0.3s ease;
        }
        .cn__gp__card__selection{
            display: flex;
            align-items: center;
            margin-top: 10px;
            p{
                font-weight: 600;
                margin-right: 5px;
                white-space: nowrap;
                color: $color-black;
                span{
                    font-weight: 900;
                }
            }
            img{
                margin-top: 2px;
            }
        }
        &:hover{
            .cn__gp__icon, .cn__gp__card__selection{
                opacity: 1;
            }
            transform: none;
        }
        &.nats{
            padding: 30px 5px 30px 15px;
            background: #D5E7F2;
            .cn__gp__card__att{
                display: flex;
                align-items: center;
                color: #242F33;
                p{
                    font-size: 16px;
                    line-height: 20px;
                }
                &__title{
                    color: #3D474D;
                    font-weight: 600;
                }
                img{
                    margin-left: 5px;
                }
            }
        }
    }
    &:first-child{
        margin-left: 0;
    }
}



@media screen and (max-width:1136px){
    .cn__gp__card {
        width: 269px;
        flex-shrink: 0;
        margin-left:20px;
        box-shadow: 0 2px 20px rgb(87 75 144 / 20%);
        scroll-snap-align: start;
        scroll-snap-stop: normal;

        &:hover{
            box-shadow: 0 2px 2px rgb(87 75 144 / 10%);
            transform: unset;
        }
        &__image{
            height: 190px;
        }
        &.card3{
            width: auto;
            .cn__gp__icon, .cn__gp__card__selection{
                opacity: 1;
            }
        }
        &:first-child{
            margin-left: 30px;
        }
    }
}

@media screen and (max-width:600px){
    .cn__gp__card {
        width: 162px;
        margin-left:10px;
        box-shadow: 0 2px 20px rgb(87 75 144 / 20%);

        &:hover{
            box-shadow: 0 2px 20px rgb(87 75 144 / 20%);
        }

        &__image{
            height: 109px;
            &__amenityleft{
                left: 10px;
                bottom: 10px;
            }
        }
        &__info{
            padding: 10px 10px 15px;
            &__title{
                font-size: 14px;
                line-height: 17px;
            }
            &__price{
                font-size: 14px;
                text-align: left;
                span{
                    font-size: 14px;
                }
            }
        }
        &.card1, &.card2 {
            .cn__gp__card__image{
                &__sec1{
                    left: 10px;
                    bottom: 10px;
                }
                &__sec2{
                    display: none;
                }
            }
            .cn__gp__card__info{
                &__rating, &__distance{
                    display: none;
                }
            }
        }
        &.card3{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 20px;
        }
    }
}