.btn-text-bright-purple {
    @extend .btn-common;
    @extend .btn-text-common;
    background: none;
    color: $color-bright-purple;
    &:hover {
        background: rgba($color: $color-secondary, $alpha: 0.1);
        color:$color-bright-purple;
        svg path{
            stroke:$color-bright-purple;
        }
    }
}