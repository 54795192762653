.cn__cnt__faq{
  position: relative;
  width: 100%;
  padding: 0 20px;
  margin: 130px auto 0;
  &__title{
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #1D1C1F;
  }
  &__questions{
    margin-top: 11px;
    &__question{
      &__closed{
        position: relative;
        width: 100%;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
          font-size: 17px;
          line-height: 21px;
          font-weight: 600;
          max-width: -webkit-calc(100% - 40px);
          max-width: calc(100% - 40px);
          -webkit-font-feature-settings: unset,;
          font-feature-settings: unset,;
          font-variant: unset;
          text-transform: unset;
          color: #1D1C1F;
        }
        svg{
          transform: rotate(-90deg);
          path{
            stroke:#1D1C1F;
            stroke-width: 2px;
          }
        }
      }
      &__answer{
        position: relative;
        margin-bottom: 0;
        max-height: 0;
        transition: all .6s ease;
        p{
          font-weight: 400;
          font-size: 17px;
          line-height: 21px;
          color: rgba(29, 28, 31, 0.45);
          opacity: 0;
          transition: opacity .6s ease;
          a{
            color: #573CD6;
          }
        }
        &.active{
          p{
            opacity: 1;
          }
        }
      }
    }
  }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width: 601px) {
  .cn__cnt__faq{
    position: relative;
    width: 100%;
    padding: 0 30px;
    @media screen and (min-width: 1136px) {
      padding: 0;
    }
    margin: 100px auto 0;
    &__title{
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #1D1C1F;
    }
    &__questions{
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 50px;
      &__Deskquestion{
        &__title{
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #1D1C1F;
          font-feature-settings: unset,;
          font-variant: unset;
          text-transform: unset;
        }
      }
      &__question{
        &__answer{
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: rgba(29, 28, 31, 0.45);
          margin-top: 10px;
          p{
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
}