//---------MOBILE---------
.cn__cnt__discovermore {
  position: relative;
  margin-top: 50px;
  &__parent-div {
    &__heading {
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #1d1c1f;
      padding: 0 20px;
    }
    &__description {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: rgba(29, 28, 31, 0.45);
      margin-top: 10px;
      padding: 0 20px;
    }
  }
  .slidermobile__wrapper{
    padding-top: 20px !important;
  }
  .simplebar-track.simplebar-horizontal {
    background: #faf6e7;
    visibility: visible !important;
    height: 20px;
  }
}

//---------TABLET---------
@media screen and (min-width: 601px) {
  .cn__cnt__discovermore {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    &__parent-div {
      &__heading {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #1d1c1f;
        padding: 0;
      }
      &__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: rgba(29, 28, 31, 0.45);
        margin-top: 10px;
        padding: 0;
      }
    }
    .slidermobile__wrapper{
      &::after{
        position: absolute;
        width: 10px;
        height: 88px;
        content: '';
        background-image: linear-gradient(to right, rgba(#FAF6E7, 0), #FAF6E7);
        right: 0;
      }
    }
    .simplebar-track.simplebar-horizontal {
      background: #faf6e7;
      visibility: visible !important;
      height: 20px;
    }
  }
}

//---------DESKTOP---------
@media screen and (min-width: 1136px) {
  .cn__cnt__discovermore {
    position: relative;
    margin-top: 48px;
    margin-left: 0px;
    &__parent-div {
      &__heading {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #1d1c1f;
      }
      &__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: rgba(29, 28, 31, 0.45);
        margin-top: 10px;
      }
    }
    .simplebar-track.simplebar-horizontal {
      background: #faf6e7;
      visibility: visible !important;
      height: 20px;
    }
  }
}
