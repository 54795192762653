.galert-cta{
    background: $color-white; 
    box-shadow: 0px 2px 20px rgba(36, 37, 38, 0.15);
    border-radius: 0.188rem;
    max-width: 17.5rem;
    width: 100%;
    font-size: 0.938rem;
    line-height: 1.313rem;  
    color: #807D82;
    position: relative;
    z-index: 1;
    .galert-cta-content{ 
    padding: 1.25rem;
        h5{
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.25rem;  
            color: #242526;
            margin-bottom: 0.5rem;
         }
    }
    .galert-cta-button{
        padding: 0.938rem 0;
        border-top: 1px solid $color-light-gray;
        overflow: hidden;
        margin-left: 1.25rem;
        width: calc(100% - (2.5rem));
        .btn-text-primary{
            float: right;
        }
    }
}