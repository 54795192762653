//------------------- DESKTOP -------------------

.cn__card1{
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;

    &__img{
        position: relative;
        width: 100%;
        height: 13.195vw;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        &__title{
            position: relative;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            padding: 0 20px;
            font-weight: 900;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.04em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    &__info{
        background: #FFFFFF;
        padding: 11px 20px;

        &__price{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.04em;
            color: #242526;
        }
        &__inventory{
            font-size: 12px;
            line-height: 24px;
            letter-spacing: -0.04em;
            color: #242526;
        }
    }

    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 30px 20px -20px rgba(87,75,144, 0.15);
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__card1{
        &__img{
            height: 24vw;
        }
        &:hover{
            transform: none;
            box-shadow: 0 2px 2px rgba(87,75,144,0.15);
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__card1{
        transition: none;

        &__img{
            height: 64vw;
        }
    }
}
