//------------------- DESKTOP -------------------

.cn__slider{
    position: relative;
    width: 100%;
    height: auto;
    --card-width: 300px;
    --card-gap: 20px;

    &__cards{
        position: relative;
        min-width: 100%;
        display: inline-flex;
        overflow-x: scroll;
        padding: 5px 0 20px 0;
        margin: calc(-1 * var(--card-gap)) 0 0 calc(-1 * var(--card-gap));


        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;


        .cn__card{
            //Spacing
            margin: var(--card-gap) 0 0 var(--card-gap);
            width: calc(var(--card-width) - (var(--card-gap)));
            overflow: hidden;
            flex-shrink: 0;
            background: #a7a4a4;
            //background: #4c35bc;

            .property-card-price {
                margin-top: 6px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .property-card_price-actual,
                .property-card_price-info {
                    color: var(--color-dark-grey);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 150%;
                }

                .property-card_price-total {
                    color: var(--color-another-black);
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 120%;

                    @media screen and (max-width: 600px) {
                        .property-card_price-actual__active {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 150%;
                        }
                    }
                }
            }
        }
    }

    //--------- simplebar ----------

    .simplebar-content-wrapper{
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .simplebar-track.simplebar-horizontal{
        background: transparent;
        height: 24px;
        width: calc(100%);
        bottom: -14px;
        overflow: visible;
        pointer-events: all;
        cursor: pointer;
        z-index: 0;
        &::before{
            position: absolute;
            content: '';
            width: calc(100%);
            height: 100%;
            left: -20px;
            z-index: -1;
        }
        &::after{
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            bottom: 1px;
            background: rgba(48, 39, 39, 0.1);
            border-radius: 5px;
            z-index: -1;
        }
        .simplebar-scrollbar{
            width: 100%;
            height: 4px;
            top: auto;
            bottom: 0;
            border-radius: 5px;
            background: #f466a2;
            &::before{
                display: none;
            }
        }
    }

    &__arrows{
        position: absolute;
        top: -51px;
        right: 0;
        display: flex;

        &__arrow{
            position: relative;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            border: 3px solid $color-gray-hp;
            // border: 2px solid rgba(48, 39, 39, 0.4);
            display: grid;
            place-items: center;
            cursor: pointer;
        }

        &__prevarrow{
            margin-right: 10px;

            svg{
                position: relative;
                left: -1px;
                transform: rotate(180deg);
            }
        }
        &__nextarrow{

            svg{
                position: relative;
                left: 1px;
            }
        }

        &__disabled{
            opacity: .5;
            pointer-events: none;
        }
    }

    //States

    &__noscrollbar{
        .simplebar-track{
            display: none;
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__slider{

        &__cards{
            // padding: 5px 30px 20px 35px;

            .cn__card{
                margin: 20px 0 0 15px;
                width: 269px;
            }
        }

        //--------- simplebar ----------

        .simplebar-content-wrapper{
            scroll-behavior: unset;
        }
        .simplebar-track.simplebar-horizontal{
            width: calc(100% - 60px);
            margin-left: 30px;
        }

        //States

        &__noscrollbar{
            .simplebar-track{
                display: unset;
            }
        }
    }
}



//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__slider{

        &__cards{
            padding: 5px 20px 20px 30px;
            .cn__card{
                margin: 20px 0 0 10px;
                width: calc(100vw - 40px);
            }
        }

        //--------- simplebar ----------

        .simplebar-track.simplebar-horizontal{
            width: calc(100% - 40px);
            margin-left: 20px;
            bottom: -5px;
        }

        //States

        &__noscrollbar{
            .simplebar-track{
                display: unset;
            }
        }
    }
}
