//---------MOBILE---------
.cn__cnt__multislider__mobile{
    position: relative;
    background: #F9EFE0;
    margin: 55px auto 0;
    width: 100%;
    padding: 50px 20px 94px;
    &__sec1{
        &__title{
            font-weight: 700;
            font-size: 28px;
            line-height: 26px;
            text-align: center;
            letter-spacing: -0.04em;
            text-transform: uppercase;
            color: #E84C4C;
        }
        &__img{
            position: relative;
            margin-top: 15px;
            width: 100%;
            height: 273px;
            border-radius: 20px;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &__sec2{
        position: relative;
        margin-top: 71px; 
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        color: rgba(104, 102, 93, 0.5);
        p{
            a{
                color:#E84C4C;
            }
        }
        .cnt__stays--arrow{
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 3px solid rgba(48, 39, 39, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            strong{
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    height: 14px;
                    margin-left: 2px;
                    path{
                        stroke-width: 4px;
                        stroke: rgba(48, 39, 39, 0.4);
                    }
                }
            }
        }
        &__next{
            right: 0;
            top: -55px;
        }
        &__prev{
            right: 60px;
            top: -55px;
            transform: rotate(-180deg);
        }
    }
}

//---------TABLET/DESKTOP---------
@media screen and (min-width:601px){
    .cn__cnt__multislider {
        position: relative;
        background: #F9EFE0;
        margin: 100px auto 0;
        border-radius: 20px;
        width: 100%;
        height: 754px;
        display: flex;
        overflow: hidden;
        width: calc(100% - 60px);
        @media screen and (min-width:1136px){
            width: 100%;
        }

        &__sec1{
            position: relative;
            width: 51%;
            overflow: hidden;
            padding: 50px 30px 30px;
            display: flex;
            flex-direction: column;
            &__cat{
                &__cats{
                    display: flex;
                    justify-content: space-between;
                    p{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: #EA3434;
                        opacity: 0.4;
                        cursor: pointer;
                        text-align: center;
                        &.cnt__mlt__active{
                            opacity: 1;
                        }
                    }
                }
                &__loader{
                    position: relative;
                    width: 100%;
                    height: 2px;
                    margin-top: 30px;
                    background: #F8E5CA;
                    &__line{
                        position: absolute;
                        height: 100%;
                        width: 25%;
                        background: #EA3434;
                    }
                }
            }
            &__info{
                position: relative;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    position: absolute;
                    width: 294px;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 130%;
                    color: rgba(104, 102, 93, 0.5);
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 1s ease;
                    a{
                       color: #EA3434; 
                    }
                    &.cnt__mlt__active{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        &__sec2{
            position: relative;
            width: 49%;
            overflow: hidden;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition: opacity 1s ease;
                &.cnt__mlt__active{
                    opacity: 1;
                }
            }
        }
    } 
}
