.notification-failed {
    @extend .notification-common;
    @media screen  and (max-width: 600px) {
        border-bottom-color: $color-danger;
    }
    .notification-left {
        span {
            background: $color-danger;
        }
    }
}