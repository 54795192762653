//------------------- DESKTOP -------------------

.cn__values{
    position: relative;
    width: calc(100% - 300px);
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;

    &--appear-animation {
        animation: fade 0.3s ease;
    }

    .slick-arrow{
        display: inline-table !important;
        right: 0;
        left: 0;
        svg{
            path{
                stroke: #FFFFFF;
            }
        }
        &.slick-prev{
            right: unset;
            left: 20px;
        }
        &.slick-next{
            right: 10px;
            left: unset;
        }
    }
    .slick-slide{
        position: relative;
        &:nth-child(2) {
            &::before{
                position: absolute;
                content: '';
                width: 1px;
                height: 48%;
                background: rgba(255,255,255,0.5);
                top: 80px;
                left: -5px;
            }
            &::after{
                position: absolute;
                content: '';
                width: 1px;
                height: 48%;
                background: rgba(255,255,255,0.5);
                top: 80px;
                right: -5px;
            }
        }
    }

    &__value {
        position: relative;
        &__inner{
            position: relative;
            width: 100%;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #FFFFFF;
            text-align: center;

            &__title{
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.02em;
                font-weight: 700;
            }
            &__description{
                font-size: 15px;
                line-height: 18px;
                margin-top: 8px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
   
    .cn__values{
        width: 100%;
    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__values{
        .slick-slide{
            &:nth-child(2) {
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
        }

        .slick-arrow{
            svg{
                width: 2.5vw;
            }
        }

        &__value {
            &__inner{
                &__svg{
                    width: 20vw;
                }
                &__title{
                    font-size: 5.4vw;
                    line-height: 1;
                }
                &__description{
                    font-size: 4vw;
                    line-height: 1.3;
                    margin-top: 12px;
                }
            }
        }

    }
}


@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}