@import '../../global/mixins/global-mixins.scss';

.cn__hp__subscribe{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    @media screen and (max-width: 1136px) { 
        margin: 60px auto;
        padding: 0 30px;
    }
    @media screen  and (max-width: 600px) {
        padding: 0 20px;
    }
}

.subscribe-section {
    width: 100%;
    position: relative;

    // this ":before" is hack for #fragment navigation with header height offset
    &:before {
        content: " ";
        display: block;
        visibility: hidden;
        height: 88px;
        margin-top: -88px;
        @media screen and (max-width: 600px) {
            height: 77px;
            margin-top: -77px;    
        }
    }

    .heading-section { 
        text-align: left; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 1136px) /* and (max-width: 1919px)  */{
            margin-left: 0;
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) { 
            text-align: center;
            padding: 0 0;
            float: none;
            flex-direction: column;
        }
        @media screen  and (max-width: 600px) {
            float: none;
            margin: 0;
            max-width: 100%;
            text-align: center;
            padding: 0;
            flex-direction: column;
        }
        h2 {
            font-weight: 600;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: $color-black;
            margin: 0;
            @media screen  and (max-width: 600px) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 20px;
            }   
        }
        p {    
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-top: 9px;
            color: $color-grey-cultured;
            max-width: 560px;

            @media screen and (min-width: 601px) and (max-width: 1136px) {
                width: 528px;
                margin: 12px auto 35px auto;
            } 
            @media screen  and (max-width: 600px) {
                margin-bottom: 42px;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: rgba(207, 214, 249, 0.7);
            } 
        }
    } 
    .subscribe-section-con{
        // background: #F0F8FF;
        background: linear-gradient(96.36deg, rgba(243, 85, 151, 0.6) 37.18%, rgba(120, 104, 199, 0.6) 71.92%);
        border-radius: 20px;
        padding: 66px 74px;
        filter: drop-shadow(0px 30px 46px rgba(0, 0, 0, 0.04));
        @media screen and (min-width: 601px) and (max-width: 1136px) { 
            padding: 55px 30px;
        }
        @media screen  and (max-width: 600px) {
            padding: 22px 25px; 
        }
        .subscribe-img{ 
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                grid-area: 1 / 1 / 1 /2;
                text-align: center;
                margin-bottom: 1.3125rem;
            } 
            img{
                max-width: 100%;
                height: auto;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                display: none;
            }
            }
        }
    }
    .subscribe-form {
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        max-width: 428px;
        grid-gap: 10px;
        align-items: center;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 auto;
        } 
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 auto;
        } 
        @media screen  and (max-width: 600px) {
            grid-template-columns: 1fr 88px;
            grid-gap: 20px;
            .cn-input-group{
                width: calc(100% + 14px);
            }
        }
        .subscribe-form_inputfields {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            @media screen  and (max-width: 600px) {
               row-gap: 6px;
               max-width: 270px;
            }
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                row-gap: 9px;
            } 
        }  
        .btn{
            max-width: 123px;
            min-width: 0;
            transition: all 0.3s ease;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.25rem;
            background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
            // background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%) !important;
            @media screen  and (max-width: 600px) {
                font-size: 12px;
                line-height: 20px;
                height: 38px;
            }
        }
        .primary-input{
            font-weight: 400;
            background: #FFFFFF;
            border: none;
            @media screen  and (max-width: 600px) {
                height: 38px;
            }
        }
    }
}
