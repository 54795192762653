@import './tools/_functions';

//Omnisearch
@media (min-width: 764px) {
    .cn__hp__omnisearch {
        display: grid;
        place-content: flex-start;
        color: $color-black;
        .omnisearch_content {
            max-width: 889px;
            width: 100%;
            margin: 24px 0 0;
        }
        .omnisearch_content_input-area_input-slot {
            &.omnisearch_content_input-area_destination-input .floating-input_input {
                @media (min-width: 763px) and (max-width: 900px) {
                    width:218px;
                }
            }
            &.omnisearch_content_input-area_guest-input {
                width:fit-content;
                &:hover{
                    width: unset;
                    }
            }
        }

    }
}



//------------------- DESKTOP -------------------

.cn__hp__hero__words{
    position: absolute;
    width: 100%;
    z-index: 3;
    color: #F7F7F7;
    top: 12%;
    padding-left: 100px;
    &__title{
        text-shadow: 0 1px 1px rgb(9, 30, 66, 0.31);
        font-weight: 600;
        font-size: 96px;
        line-height: 101%;
        color: #FFF;
        @media (min-width: 1001px) and (max-height: 770px) and (min-height: 450px) {
            font-size: 50px;
        }
        span{
            display: block;
            overflow: hidden;
            font-weight: 700;
            font-size: 44px;
            line-height: 120%;
            // Enable for Christmas
            // color: var(--color-white);
            background: linear-gradient(271.69deg, #F35597 54.27%, #7868C7 98.03%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            margin-top: 5px;
        }
    }
    &__subtitle{
        font-weight: 300;
        font-size: 24px;
        line-height: 120%;
        margin-top: 5px;
        color: $color-white;
        max-width: 587px;
            &__night {
                color: $color-white;
            }
    }
    &__placeholder{
        position: relative;
        margin-top: 30px;
    }
}



//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__hero__words{
        padding: 0 30px;

        &__title{
            font-size: 32px;
            span {
                font-size: 32px;
            }
            //line-height: 46px;
        }
        &__subtitle{
            font-weight: 300;
            font-size: 18px;
            line-height: 150%;
            margin-top: 9px;
        }
    }
}
@media screen and (min-width: 1850px) {
    .cn__hp__hero__words{
        padding: 0 calc((100vw - 1850px) / 2);
    }
}
//TABLET 601
@media screen and (min-width:601px) and (max-width:763px){
    .cn__hp__hero__words{
        top: 14%;
        &__title{
            font-size: 32px;
            line-height: 36px;
        }
        &__subtitle{
            font-size: 18px;
            margin-top: 9px;
            line-height: 36px;
        }
    }
}
//TABLET 764
@media screen and (min-width:764px) and (max-width:800px){
    .cn__hp__hero__words{
        &__title{
            font-size: 32px;
            line-height: 40px;
               span {
                   font-size: 32px;
               }
        }
        &__subtitle{
            font-size: 18px;
            margin-top: 9px;
            line-height: 36px;
        }
    }
    .cn__hp__omnisearch {
        .omnisearch_content {
            margin: 23px 0 0;
        }
    }
}


//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__hero__words{
        padding: 0 20px;
        top: 13vh;
        left: auto;
        transform: translate(0);

        &__title{
            line-height: 1.2;
            font-weight: 600;
            font-size: 55px;
            line-height: 120%;
            text-align: center;
            span {
                font-size: 24px;
            }
        }
        &__subtitle{
            text-align: center;
        }

    }
}