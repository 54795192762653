//------------------- DESKTOP -------------------

.cn__hp__nexttrip{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    .cn__hp__nexttrip__slider__wrapper--captioned {
        column-gap: 18px;
        row-gap: 30px;
    }
    &__slider{
        &__wrapper{
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: 20px;
            margin-top: 30px;
        }
        &__card{
            position: relative;
            width: 100%;
            height: 160px;
            border-radius: 16px;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0,0,0);
            transition: transform .35s ease;
            cursor: pointer;
            a{

                position: relative;
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                img{
                    position: absolute;
                    transition: all 1s ease;
                }
                .cn__hp__nexttrip__slider__card__title{
                    padding: 0 20px;
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 1.2;
                    text-align: center;
                    text-transform: unset;
                    z-index: 0;
                    font-feature-settings: unset;
                    font-variant: unset;
                    transition: transform 0.6s ease;
                }
                &:hover{
                    img{
                        transform: scale(1.05);
                        filter: brightness(0.95);
                    }
                    .cn__hp__nexttrip__slider__card__title{
                        transform: translateY(-5px);
                    }
                }
            }
            &:hover {
                transform: translateY(-5px);
            }
        }
    }
    .cn__hp__nexttrip__slider__card {
        &.cn__hp__nexttrip__slider__card--captioned {
            box-shadow: 0 4px 2px -4px grey;
            border-radius: 20px;
            transition: .3s;
            height: auto;
            border-bottom: 3px solid #bdb5b5;
            &:hover {
                box-shadow: 0 60px 65px -50px rgba(0, 0, 0, .2);
            }

            .cn__hp__nexttrip__slider__card__link {
                flex-direction: column;

                &__top{
                    position: relative;
                    width: 100%;
                    height: dvw(190);
                    @media screen and (min-width:1921px){
                        height: 253px;
                    }
                }
                .cn__hp__nexttrip__slider__card__title {
                    text-align: left;
                    padding: initial;
                    font-weight: bold;
                    font-size: 23px;
                    line-height: 120%;
                    text-transform: lowercase;
                        span {
                        text-transform: none;
                    }
                }
                .cn__hp__nexttrip__slider__card__title::first-letter {
                    text-transform: uppercase;
                }
                &:hover {
                    .cn__hp__nexttrip__slider__card__title,
                    .cn__image-default {
                        transform: none;
                    }
                }
            }
        }
        .cn__hp__nexttrip__slider__card__caption {
            color: $color-pure-black;
            align-self: flex-start;
            padding: 21px 20px 25px;
            width: 100%;
            border: 1px solid rgba(100,116,139,.5);
            border-bottom: none;
            .cn__hp__nexttrip__slider__card__description {
                margin-top: 5px;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: .01em;
            }
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
}

//DESKTOP 1280
@media screen and (min-width:1280px){
    .cn__hp__nexttrip{
        &__slider{
            &__card{
                height: 12.5vw;
            }
        }
        .cn__hp__nexttrip__slider__card {
            &.cn__hp__nexttrip__slider__card--captioned {
                height: auto;
            }
        }
    }
}
//DESKTOP 1366
@media screen and (min-width:1366px){
    .cn__hp__nexttrip{
        &__slider{
            &__card{
                height: 11.714vw;
            }
        }
        .cn__hp__nexttrip__slider__card {
            &.cn__hp__nexttrip__slider__card--captioned {
                height: auto;
            }
        }
    }
}
//DESKTOP 1440
@media screen and (min-width:1440px){
    .cn__hp__nexttrip{
        &__slider{
            &__card{
                height: 11.112vw;
            }
        }
        .cn__hp__nexttrip__slider__card {
            &.cn__hp__nexttrip__slider__card--captioned {
                height: auto;
            }
        }
    }
}
//DESKTOP 1536
@media screen and (min-width:1536px){
    .cn__hp__nexttrip{
        &__slider{
            &__card{
                height: 10.417vw;
            }
        }
        .cn__hp__nexttrip__slider__card {
            &.cn__hp__nexttrip__slider__card--captioned {
                height: auto;
            }
        }
    }
}
//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__nexttrip{
        &__slider{
            &__wrapper{
                grid-gap: 28px;
                margin-top: 40px;
            }
            &__card{
                height: 222px;
                a{
                    .cn__hp__nexttrip__slider__card__title{
                        font-size: 24px;
                    }
                }
            }
        }
        .cn__hp__nexttrip__slider__card {
            &.cn__hp__nexttrip__slider__card--captioned {
                height: auto;
            }
        }
    }
}

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__nexttrip{
        padding: 0;
        margin: 60px auto;
        &__slider{
            &__wrapper{
                position: relative;
                width: auto;
                display: inline-grid;
                grid-template-columns: 293px 293px 293px 293px;
                grid-gap: 15px;
                margin-top: 30px;
                overflow-x: scroll;
                -ms-scroll-snap-type: x mandatory;
                scroll-snap-type: x mandatory;
                padding: 0 30px 10px 30px;
                scroll-padding: 0 0 0 30px;
            }
            &__card{
                scroll-snap-align: start;
                scroll-snap-stop: normal;
                a{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;

                    img{
                        position: absolute;
                    }
                    .cn__hp__nexttrip__slider__card__title{
                        padding: 0 20px;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: -0.04em;
                        z-index: 0;
                    }
                }
                &:hover{
                    img{
                        transform: none !important;
                    }
                    .cn__hp__nexttrip__slider__card__title{
                        transform: none !important;
                    }
                }
            }

            .cn__hp__nexttrip__slider__card__link {
                &__top{
                    height: 190px !important;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__nexttrip{
        .cn__hp__nexttrip__slider__wrapper--captioned {
            display: flex;
            width: max-content;
            column-gap: 10px;
        }
        margin: 60px auto 74px auto;
        &__slider{
            &__wrapper{
                grid-template-columns: 163px 163px 163px 163px;
                grid-gap: 10px;
                padding: 0 20px 10px 20px;
                scroll-padding: 0 0 0 20px;
            }
            &__card{
                height: 104px;
                border-radius: 10px;
                a{
                    .cn__hp__nexttrip__slider__card__title{
                        padding: 0 10px;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: -0.03em;
                    }
                }
            }
        }
        .cn__hp__nexttrip__slider__card {
            &:hover{
                box-shadow: none;
                transform: none;
            }
            &.cn__hp__nexttrip__slider__card--captioned {
                width: 162px;
                height: auto;
                border-radius: 10px;
                .cn__hp__nexttrip__slider__card__link {
                    &__top{
                        height: 103px !important;
                    }
                    .cn__hp__nexttrip__slider__card__title {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 110%;
                        letter-spacing: .01em;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                        text-transform: capitalize;
                    }
                }
                .cn__hp__nexttrip__slider__card__caption {
                    padding: 16px 11px;
                    .cn__hp__nexttrip__slider__card__description {
                        margin-top: 4px;
                        font-size: 12px;
                        line-height: 110%;
                    }
                }
            }
        }
        .simplebar-track.simplebar-horizontal{
            background: #FFFFFF;
            visibility: visible !important;
            height: 24px;
            width: calc(100% - 40px);
            bottom: -14px;
            margin-left: 20px;
            overflow: visible;
            pointer-events: all;
            cursor: pointer;
            z-index: 0;
            &::before{
                position: absolute;
                content: '';
                width: calc(100% + 40px);
                height: 100%;
                left: -20px;
                background: #FFFFFF;
                z-index: -1;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: 1px;
                background: #F4F4F5;
                border-radius: 5px;
                z-index: -1;
            }
            .simplebar-scrollbar{
                width: 100%;
                height: 4px;
                top: auto;
                bottom: 0;
                border-radius: 5px;
                background: #f466a2;
                &::before{
                    display: none;
                }
            }
        }
        &.cn__hp__nexttripfirst{
            margin: 40px auto 74px auto;
        }
    }
}