//------------------- DESKTOP -------------------

.cn__hp__notification{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 40px auto;
    &__wrapper{
        padding: 20px;
        border-radius: 15px;
        display: flex;
        align-items: flex-start;
        &__info{
            color: #242526;
            padding-left: 20px;
            width: 100%;
            &__title{
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
            &__subtitle{
                font-size: 14px;
                line-height: 20px;
                margin-top: 10px;
            }
        }
        &__cancel{
            cursor: pointer;
        }
        &.cn__hp__notification--green{
            background: #EDF8EF;
        }
        &.cn__hp__notification--blue{
            background: #EDF4F8;
        }
    }
}


//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__notification{
        padding: 0 30px;
        margin: 60px auto;
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__notification{
        padding: 0 20px;
        margin: 30px auto;
        &__wrapper{
            padding: 20px 20px 10px;
            &__info{
                &__title{
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
    }
}