.cn__sec1__cancelfaq {
    width: 100%;
    max-width: 82%;
    margin: 28px auto 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 33.6px;
    }
    > * {
        margin-top: 42px;
    }
    &__preview {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: auto;

        @media only screen and (min-width: 1100px) {
            width: 110%;
            margin-left: -5%;
        }
        &__table {
            background: $color-white;
            min-width: 1281px;
            min-height: 300px;
            border-radius: 32px;
            text-align: start;
            margin-bottom: 5px;
            td:last-child {
                border: none;
            }
            th,
            td {
                margin: 10px;
                margin-top: 0;
                min-width: 163px;
                font-weight: 400;
                line-height: 24px;
            }
            td {
                font-size: 16px;
                justify-content: center;
                text-align: left;
                padding-inline: 20px;
                border-right: 1px solid $color-light-gray-input;
            }
            th {
                font-weight: 500;
                font-size: 18px;
                line-height: 21.6px;
                min-width: 5px;
                width: fit-content;
            }
            tr:last-child > td {
                border-bottom: 19px solid $color-white;
                &:last-child {
                    padding-bottom: 9px;
                    border: none;
                }
            }
            &__headers__horizontal {
                text-align: left;
                padding-top: 19px;
                padding-bottom: 10px;
                padding-inline: 20px;
                span {
                    border-bottom: 1px solid $color-light-gray-input;
                }
            }
            &__headers__vertical {
                text-align: center;
                display: flex;
                justify-content: center;
                > div {
                    width: 168px !important;
                    height: 49px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-block: 10px;
                    border-radius: 20px;
                    color: $color-white;
                }
                .custom {
                    background: $color-custom-blue;
                }
                .flexible {
                    background: $color-success;
                }
                .moderate {
                    background: $color-moderate-orange;
                }
                .rigid {
                    background: $color-rigid-red;
                }
                .non-refundable {
                    background: $color-subtle-grey;
                }
            }
        }
    }
    .cn__sec1__cancellationpage__questions__arrow__hidden {
        display: none;
    }
    &__questions {
        &__question {
            border-radius: 15px;
            padding: 5px 20px;
            transition: background-color 0.6s ease;
            transition-delay: 0s;
            margin-block: 15px;
            .internal__questions {
                div p {
                    font-size: 18px !important;
                    font-weight: 500;
                    line-height: 21.6px;
                }
                font-size: 16px;
                .cn__sec1__cancelfaq__questions__question__answer {
                    display: none;
                }
            }
            @media screen and (max-width: 600px) {
                border-radius: 0px;
            }
            &__closed {
                position: relative;
                width: 100%;
                padding: 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                @media screen and (min-width: 600px) {
                    width: unset;
                    min-width: 25%;
                    padding: 0;
                }
                @media screen and (min-width: 1136px) {
                    width: unset;
                    padding: 0;
                    min-width: 13%;
                }
                @media screen and (min-width: 1439px) {
                    width: unset;
                    padding: 0;
                    white-space: nowrap;
                }
                h3 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-black;
                    display: inline-block;
                    width: 95%;
                }
                p {
                    width: 90%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 33.6px;
                    color: $color-black;
                    @media screen and (max-width: 767px) {
                        width: 80%;
                    }
                }
                svg {
                    path {
                        stroke: $color-subtle-grey;
                        stroke-width: 2px;
                    }
                }
            }
            &__answer {
                position: relative;
                margin-bottom: 0;
                max-height: 0;
                padding: 33px;
                padding-top: 0;
                padding-bottom: 0;
                transition: max-height 0.6s ease 0.3s;
                .internal__answer {
                    padding-top: 33px;
                    font-weight: 400;
                    line-height: 24px;
                }
                .cn__sec1__cancelfaq__questions__wrapper {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 21px;
                    color: $color-pure-black;
                    opacity: 0;
                    transition: all 0.6s ease;
                    transition-delay: 0;
                    a {
                        color: #573cd6;
                    }
                    .cn__sec1__cancelfaq__questions__paragraph {
                        position: relative;
                        width: 100%;
                        padding: 0 40px;
                        color: $color-black;
                    }
                    .cn__sec1__cancelfaq__questions__description {
                        position: relative;
                        width: 100%;
                        color: $color-black;
                        margin-bottom: 30px;
                        font-weight: 500;
                        line-height: 27.6px;
                    }
                    .cn__sec1__cancellationpage__data__days_container {
                        position: relative;
                        padding: 0 40px;
                        display: flex;
                        flex-direction: column;
                        &__days {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: $color-black;
                            width: 100%;
                            h5 {
                                text-align: right;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: $color-pure-black;
                                margin: 10px 0 20px 0;
                            }
                            p {
                                font-size: 12px;
                                line-height: 15px;
                                letter-spacing: 0.02em;
                                color: $color-pure-black;
                                opacity: 1;
                                font-weight: 400;
                                span {
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    .internal__container {
                        display: flex;
                        flex-direction: column;
                        width: 80%;
                        padding: 0 40px 0 50px;
                        hr {
                            width: 115%;
                            margin-left: -15%;
                        }
                    }
                }
            }
            &.cancelfaq__active {
                @media screen and (min-width: 600px) {
                    display: flex;
                    padding-block: 50px;
                    padding-inline: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .Custom {
                        color: $color-custom-blue;
                    }
                    .Flexible {
                        color: $color-success;
                    }
                    .Moderate {
                        color: $color-moderate-orange;
                    }
                    .Rigid {
                        color: $color-rigid-red;
                    }
                    .Non-refundable {
                        color: $color-subtle-grey;
                    }
                }
                padding-bottom: 20px;
                background: $color-white !important;
                transition: background-color 0.6s ease;
                transition-delay: 0.6s;
                .cn__sec1__cancelfaq__questions__question__closed {
                    p {
                        font-size: 28px;
                    }
                    .cn__sec1__cancellationpage__questions__arrow {
                        transform: rotate(90deg);
                    }
                }
                .cn__sec1__cancelfaq__questions__question__answer {
                    max-height: 1000px;
                    transition: max-height 0.6s ease;
                    transition-delay: 0;
                    .cn__sec1__cancelfaq__questions__wrapper {
                        transition: opacity 0.6s ease;
                        transition-delay: 0.6s;
                        opacity: 1;
                        font-size: 23px;
                        line-height: 27.5px;
                        .cn__sec1__cancellationpage__data__days_container {
                            display: flex;
                        }
                    }
                }
            }
            &.cancelfaq__inner__active {
                padding-bottom: 20px;
                background: $color-white !important;
                transition: background-color 0.6s ease;
                transition-delay: 0.6s;
                .cn__sec1__cancelfaq__questions__question__closed {
                    p {
                        font-size: 28px;
                    }
                    .cn__sec1__cancellationpage__questions__arrow__internal {
                        transform: rotate(90deg);
                    }
                }
                .cn__sec1__cancelfaq__questions__question__answer {
                    max-height: 1000px;
                    transition: max-height 0.6s ease;
                    transition-delay: 0;
                    display: block;
                    .cn__sec1__cancelfaq__questions__wrapper {
                        transition: opacity 0.6s ease;
                        transition-delay: 0.6s;
                        opacity: 1;
                        font-size: 23px;
                        line-height: 27.5px;
                        .cn__sec1__cancellationpage__data__days_container {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .cn__sec1__cancelfaq {
        padding: 0 30px;
        &__questions {
            &__question {
                border-radius: 15px;
                padding: 5px 20px;
                &__closed {
                    position: relative;
                    width: 100%;
                    padding: 15px 0;
                    padding-top: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    h3 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: $color-black;
                        display: inline-block;
                        width: 95%;
                    }
                    p {
                        width: 90%;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 33.6px;
                        color: $color-black;
                        @media screen and (max-width: 767px) {
                            width: 80%;
                        }
                    }
                    svg {
                        path {
                            stroke: #acabb4;
                            stroke-width: 2px;
                        }
                    }
                }
                &__answer {
                    position: relative;
                    margin-bottom: 0;
                    max-height: 0;
                    transition: all 0.6s ease;
                    .cn__sec1__cancelfaq__questions__wrapper {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        opacity: 0;
                        transition: opacity 0.6s ease;
                        a {
                            color: #573cd6;
                        }
                        .cn__sec1__cancelfaq__questions__paragraph {
                            position: relative;
                            width: 100%;
                            padding: 0 40px;
                            color: $color-black;
                        }
                        .cn__sec1__cancelfaq__questions__description {
                            position: relative;
                            width: 100%;
                            padding: 0 40px;
                            color: $color-black;
                            margin-bottom: 30px;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21.6px;
                        }
                        .cn__sec1__cancellationpage__data__days_container {
                            position: relative;
                            width: 100%;
                            padding: 0 40px;
                            &__days {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: $color-black;
                                width: 100%;
                                h5 {
                                    text-align: right;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: $color-pure-black;
                                    margin: 10px 0 20px 0;
                                }
                                p {
                                    font-size: 12px;
                                    line-height: 15px;
                                    letter-spacing: 0.02em;
                                    color: $color-pure-black;
                                    opacity: 1;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    &.active {
                        p {
                            opacity: 1;
                        }
                    }

                    .internal__container {
                        width: unset !important;
                        width: 100%;
                        padding: 0 40px 0 50px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .cancelfaq__active {
        border: 1px solid $color-subtle-grey;
        .Custom__bg {
            background: unset;
        }
        .Flexible__bg {
            background: unset;
        }
        .Moderate__bg {
            background: unset;
        }
        .Rigid__bg {
            background: unset;
        }
        .Non-refundable__bg {
            background: unset;
        }
    }
    .Custom__bg {
        background: $color-custom-blue;
    }
    .Flexible__bg {
        background: $color-success;
    }
    .Moderate__bg {
        background: $color-moderate-orange;
    }
    .Rigid__bg {
        background: $color-rigid-red;
    }
    .Non-refundable__bg {
        background: $color-subtle-grey;
    }
    .cn__sec1__cancelfaq {
        margin: 20px auto 80px;
        &__questions {
            &__question {
                border-radius: 10px;
                &__closed {
                    width: 100%;
                    padding-top: 4px;
                    padding-bottom: 4px;
                }
                p {
                    font-size: 23px !important;
                }
                svg {
                    path {
                        stroke: $color-pure-black;
                        stroke-width: 2px;
                    }
                }
                .internal__questions {
                    div p {
                        font-size: 12px !important;
                        font-weight: 500;
                        line-height: 18px;
                    }
                }
                &__answer {
                    padding: 0;
                    .cn__sec1__cancelfaq__questions__wrapper {
                        .cn__sec1__cancelfaq__questions__paragraph {
                            padding: 0 12%;
                        }
                        .cn__sec1__cancelfaq__questions__description {
                            padding: 0;
                            padding-top: 25px;
                            margin-bottom: 0;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                        }
                        .cn__sec1__cancellationpage__data__days_container {
                            padding: 0;
                            &__days {
                                h5 {
                                    margin: 10px 0;
                                }
                            }
                        }
                        .internal__container {
                            min-width: 100%;
                            padding: 0;
                            hr {
                                width: 100%;
                                margin: 0;
                            }
                        }

                        .internal__answer {
                            padding-top: 15px;
                            padding-inline: 5px;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 15px;
                        }
                    }
                }
            }
        }
    }
}
