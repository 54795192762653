

.cn__autocomplete-suggestions{
    position: absolute;
    width: 100%;
    top: 100%;
    border-radius: 10px;
    background:#ffffff;
    padding: 0px 19px 0px 10px;
    z-index: 11;
    overflow: hidden;
    li{
        list-style-type: none;
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:focus{
            border: 0px solid black;
            outline: none;
        }
        @media screen and (min-width: 1136px) {
            &:hover{
                background:#EEEEF0;
            }
            &.noHover:hover {
                background: unset;
                cursor: auto;
            }
        }
        &:first-child{
            margin-top: 15px;
        }
        &:last-child{
            margin-bottom: 15px;
        }
        .cn__as__icon{
            width: 24px;
        }
        .cn__as__info{
            margin-left: 15px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            .cn__as__info-title{
                font-size: 1rem;
                line-height: 1.2;
                color:#242526;
                font-weight: 400;
                margin-bottom: 4px; 
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;    
                span{
                    padding:0!important;
                    float: unset;
                    .highlight{
                        font-weight: 500;
                    }
                }       
            }
            .cn__as__info-subtitle{
                font-size: 0.875rem;
                line-height: 1.175rem;
                color: $color-light-black;
                font-weight: 400;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .cn__as__info-subtitle.suggest_title {
                font-variant: all-small-caps;
                color: #ACABB4;
                font-size: 1.125rem;
                line-height: 1.1875rem;
                font-weight: 500;
                letter-spacing: 0.01em;
            }
        }
        .omnisearch_aside {
            // flex: 0 0 102px;
            // flex: 0 0 min-content;
            margin-left: 15px;
        }
        .omnisearch_stars {
            .ic-icon {
                display: flex;
                width: unset;
                height: unset;
                margin-right: 4px;
                position: relative;
                svg {
                    width: unset;
                }
            }
            .ic-icon:last-child {
                margin-right: unset;
            }
        }
    }
    .simplebar-track{
        height: calc(100% - 30px);
      }
    .simplebar-track.simplebar-vertical{
        top: 15px;
        width: 11px;
        right: -16px;
    }
    .simplebar-scrollbar{
        &::before{
            width: 4px;
            background:#ACABB4;
        }
    }
}


@media screen  and (max-width: 1136px) {
    .cn__autocomplete-suggestions{
        //padding: 0px 18px 0px 10px;
        padding: 0px 0px 0px 10px;
        li{
            padding: 10px;
        }
        .simplebar-content{
            padding: 0px 18px 0px 0px !important;
        }
        .simplebar-scrollbar{
            &::before{
                width: 4;
                background:#f00;
                opacity: 1 !important;
            }
        }
    }
}

@media screen  and (max-width: 600px) {
    .cn__autocomplete-suggestions {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        li {
            .omnisearch_aside {
                flex: 1;
            }
        }
    }
}