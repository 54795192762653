.card {
    $self: &;
    margin-bottom: 20px;
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        margin-bottom: 15px;
        color: var(--color-black);
    }
    &__header {
        padding: 20px;
        h4 {
            @include text_small-header;
            color: var(--color-black);
            margin-bottom: 15px;
        }
    }
    &__content {
        padding: 20px;
    }
    &__footer {
        padding: 0.75rem 0;
    }
    &--no-padding {
        #{$self}__header,
        #{$self}__content,
        #{$self}__footer {
            padding: 0px;
        }
    }
    &--radius {
        border-radius: 10px;
    }
    &--white {
        background: #ffffff;
    }
    &--collapse {
        #{$self}__content {
            p,
            >div {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                word-break: break-word;
            }
        }
        #{$self}__footer {
            button {
                .ic-icon {
                    transition: transform 0.3s ease-in-out;
                    transform: rotate(0deg);
                }
            }
        }
    }
    &--collapsed {
        // #{$self}__content {
        //     >div {
        //         max-height: 1000px;
        //     }
        // }
        #{$self}__footer {
            button {
                .ic-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &--expandable {
        #{$self}__content {
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 15px;
            }
        }
        #{$self}__footer {
            button {
                .ic-icon {
                    transition: transform 0.3s ease-in-out;
                    transform: rotate(0deg);
                }
            }
        }
    }
    &--expanded {
        #{$self}__content {
            >div {
                max-height: 1000px;
            }
        }
        #{$self}__footer {
            button {
                .ic-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }
}