:root{
    --cn--maintenance-height: 100vh;
}

.cn__maintenance__wrapper{
    position: absolute;
    width: 100%;
    height: var(--cn--maintenance-height);
    background: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    .cn__maintenance{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 727px;
        text-align: center;
        padding: 0 20px;
        svg{
            width: 150px;
            height: 80px;
            opacity: 0;
            transform: translateY(-50px);
            margin-left: -20px;
            overflow: visible;
            .cn__maintenance__st0{
                fill:none;
                stroke:#FA368B;
                stroke-width:4;
                stroke-linecap:round;
                stroke-linejoin:round;
                stroke-miterlimit:10;
            }
            .cn__maintenance__st1{
                fill:none;
                stroke:#4C35BC;
                stroke-width:4;
                stroke-linecap:round;
                stroke-linejoin:round;
                stroke-miterlimit:10;
            }
            .cn__maintenance__st2{
                fill:#FA368B;
            }
            .cn__maintenance__st3{
                fill:#4C35BC;
            }

            #nest-leaf2{
                transform-origin: center;
                transform: rotate(0deg) translate(0, 0); 
                animation: move 7s infinite alternate-reverse;
            }

            @keyframes move {
                0%{
                    transform: rotate(0deg) translate(0, 0); 
                }
                50%{
                    transform: rotate(-19deg) translate(9px, -25px);
                }
                100%{
                    transform: rotate(10deg) translate(-1px, 17px);
                }
            }

            #nest-leaf1{
                transform-origin: center;
                transform: rotate(0deg) translate(0, 0); 
                animation: move2 7s infinite alternate-reverse;
            }

            @keyframes move2 {
                0%{
                    transform: rotate(0deg) translate(0, 0); 
                }
                50%{
                    transform: rotate(-8deg) translate(-3px, -9px);
                }
                100%{
                    transform: rotate(14deg) translate(6px, 16px);
                }
            }
        }
        &__title{
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: -0.025em;
            margin-top: 40px;
            opacity: 0;
            transform: translateY(40px);
        }
        &__subtitle{
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: -0.025em;
            color: rgba(36, 37, 38, 0.5);
            margin-top: 10px;
            opacity: 0;
            transform: translateY(40px);
        }
    }
}

@media screen and (min-width:1136px){
    .cn__maintenance__wrapper{
        .cn__maintenance{
            svg{
                width: 180px;
                height: 96px;
            }
            &__title{
                font-size: 32px;
                line-height: 36px;
                margin-top: 40px;
            }
            &__subtitle{
                font-size: 32px;
                line-height: 36px;
                margin-top: 20px;
            }
        }
    }  
}