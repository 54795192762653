@import '../../global/mixins/global-mixins.scss';

.reviews-box{
    //@include box-shadow();
    position: relative;
    background-color: $color-white;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 24.625rem;
    @media screen and (min-width: 601px) and (max-width: 1146px) {
        grid-template-rows: 18.75rem;
    }
    @media screen and (min-width: 300px) and (max-width: 600px) {
            grid-template-columns: 1fr;
            grid-gap: 0;
            grid-template-rows: 10.375rem auto;
        }
    grid-gap: 20px;*/
    .reviews-content {
        position: relative;
        font-size: 0.938rem;
        line-height: 1.125rem;
        padding: 1.25rem 0.625rem 0 0.625rem;
        height: 249px;
        width: 100%;
        transition: all 0.5s ease-in-out;
        @media screen and (min-width: 601px) and (max-width: 1146px) {
            padding:0.625rem;
            height: 194px;
        }
        @media screen and (min-width: 300px) and (max-width: 600px) {
            //padding: 1.5625rem 0.625rem 3.5rem 0.625rem;
            height: 237px;
        }
        text-align: center;
        .rating-stars-box{
            margin-bottom: 1.875rem;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                margin-bottom: 0.938rem;
            }
            @media screen  and (max-width: 600px) {
                margin-bottom: 1.1875rem;
            }
        }
        .visited-review {
            font-size: 0.875rem;
            line-height: 144.68%;
            font-weight: 300;
            transition: all 0.3s 0.5s ease;
            @media screen and (min-width: 300px) and (max-width: 1136px) {
                font-size: 0.875rem;
                }
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                max-height: 180px;
                overflow: hidden;
            }
            font-family: "Merriweather", serif;
            margin-bottom: 3.75rem;
            line-height: 144.68%;
            .visited-review-info{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                color: $color-black;
            }
        }
        .simple-link{
            position: absolute;
            display: inline-flex;
            align-items: center;
            top: 0;
            left: 50%;
            transform: translate(-50%,15px);
            opacity: 0;
            visibility: hidden;
            transition: none;
            img{
                width: 10px;
            }
        }
        .review-property-info{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 0.625rem 1.25rem;
            width: 100%;
            
            .visited-property {
                font-weight: 600; 
                margin-bottom: 0.3215rem;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: $color-black;
            }
            .visited-location {
                font-size: 0.75rem;
                line-height: 0.875rem;
                font-weight: 300; 
                color: $color-dark-grey;
                @media screen and (min-width: 601px) and (max-width: 1136px) {
                    display: none;
                }
            }
        }
        
    }
}
 
.quotes-mark{
    color:$color-primary;
}