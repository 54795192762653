//------------------- DESKTOP -------------------

.cn__CTA__freecancellation__wrapper {
    position: fixed;
    top: -100%;
    z-index: 2;
    opacity: 0;
    transition: 0.5s ease;
    &.cn__CTA__freecancellation--show {
        opacity: 1;
        top: rem(68);
    }
    &.cn__CTA__freecancellation__wrapper--mobile {
        .cn__CTA__freecancellation__info {
            padding-right: 40px;

            .cn__CTA__freecancellation__info__title {
                font-size: 16px;
                line-height: 19px;
            }
            .cn__CTA__freecancellation__info__subtitle{
                display: none;
            }
        }
    }
}

.cn__CTA__freecancellation{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    padding: 20px;
    display: flex;
    align-items: center;

    &__info{
        position: relative;
        width: 100%;
        margin-left: 20px;
        padding-right: 44px;

        &__title{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #000000;
        }

        &__subtitle{
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: #000000;
            margin-top: 10px;
        }
    }

    &__close{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        width: rem(13);
        height: rem(13);

        path {
            stroke: $color-black;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
        }
    }
}
