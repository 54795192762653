$appBarHeight: 77px;

.drawer-container  {
    .MuiBackdrop-root {
        background-color: rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 481px) {
        top: $appBarHeight !important;
        .MuiBackdrop-root {
            display:none;
        }
        .MuiDrawer-paper {
            box-shadow: none; 
            top: $appBarHeight;
            bottom:0px;
            height:auto;
            max-width: 100%;
        }
    }

}

.base-container  {
    .base-container-toolbar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .toolbar-back-icon {
            cursor: pointer;
            margin-right:10px;
            display: flex;
            svg {
                width:20px;
            }
        }
        .toolbar-icon {
            padding:0px;
            border-radius: 0;
            position: absolute;
            right: 40px;
            &:hover {
                background:none;
            }
            svg {
                height:32px;
                width:32px;
            }
        }
    } 

    

    @media screen and (max-width: 481px) {

        .base-container-content{
            height: $appBarHeight;
        } 
        .base-container-toolbar {
            padding:0px 15px;
            justify-content: center;
            h6 {
                font-size: 18px;
                font-family: var(--primary-font);
            }
            .toolbar-back-icon {
                position: absolute;
                left:15px;
            }
            .toolbar-icon {
                position: absolute;
                right: 15px;
            
                svg {
                    height:22px;
                    width:22px;
                }
            } 
        }
    }
}
