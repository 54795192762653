.listings-toolbar-actions {
    &.actions-list {
        &.actions-list--mobile {
            margin: 0;
            transform: translateY(3px);
            position: absolute;
            right: 14px;
            top: 85px;
            z-index: 1;
            @media screen and (min-width: 600px) {
               display: none;
            }
            li{
                path {
                stroke:#ffffff;
                 }
            }
            .action-list-share-icon{
                path{
                    stroke: #ffffff;
                    fill: #ffffff;
                    stroke-width: 0.2;
                }
            }
           
        }
       

        list-style: none;
        display: flex;
        margin-top: -10px;

        >li {
            margin-left: 15px;

            .n-btn {
                color: #242526;
                font-weight: normal;
                padding: 6px 8px;
                @include align-center;
                cursor: pointer;

                .ic-icon {
                    @include sqr_24;
                }

                &:hover {
                    background-color: var(--color-light-grey);
                }
            }
            .save_icon{
                svg {
                    path {
                        stroke: var(--color-black-grey);
                    }
                }
            }
        }

        .n-tooltip__inner {
            border-radius: 20px;
            padding: 5px 0px;
            width: 221px;
            li {
                padding-left: 30px;
            }
        }
    }

    .actions-share-list {
        margin: 0px;
        z-index: 999;

        ul {
            grid-row-gap: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        li {
            padding: 10px;
            cursor: pointer;
            color: var(--color-black);
            padding-top: 10px; 
            padding-bottom: 10px; 
            margin-left: 14px;
            margin-right: 14px;
              

            &:hover {
                background: var(--color-champagne-pink);
                border-radius: 30px;  
            }
            .ic-icon{
                padding-top: 4px;
            }
        }
    }

    .n-mobil-action--share {
        .list {
            grid-row-gap: 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
                padding: 20px 0;
                color: var(--color-black);

                &:first-child {
                    border-bottom: 1px solid var(--color-light-grey);
                }
            }
        }
    }

    .n-btn--wishlist {
        path {
            fill: rgb(85, 87, 89);
        }
    }
}