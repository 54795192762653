@import '../../global/mixins/global-mixins.scss';
.forgot-popup {
    .model-inner {
        max-width: 21.9375rem;
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
        max-height: 98vh;
        border-radius: 10px;
        @media screen  and (max-width: 600px) {
            padding-top: 1.875rem;
            padding-bottom: 6.875rem;
            max-height: 100vh;
            max-width: 100%;
        }
    }
    .popup-content {
        width: 100%;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        max-height: calc(100vh - 6.875rem);
    }
    .forgot-heading {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        text-align: center;
        padding: 0 0 1.25rem 0;
        margin: 0;
        @include body-emphasized();
        color: $color-black;
        @media screen  and (max-width: 600px) {
            padding: 0 0 1.5625rem 0;
            @include h2-font();
        }
    }
    .forgot-text {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        text-align: left;
        padding: 0 0 1.875rem 0;
        margin: 0;
        @include body-text();
        color: $color-black;
    }
    .forgot-never-mind{
        text-align: center;
        margin-bottom: 1.25rem;
        @media screen  and (max-width: 600px) {
            display: none;
        }
        a {
            @include body-text();
            color: $color-bright-purple;
            &:hover {
                @include body-emphasized();
                color: $color-bright-purple;
            }
        }
    }
    .cn-input-group {
        margin: 0 0 0.9375rem 0;
    }
    .btn-primary {
        width: 100%;
        margin: 0 0 1.25rem 0; 
        background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
    }
    .reset-text {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        text-align: left;
        padding: 1.25rem 0 0 0;
        margin: 0;
        @include supplementary-text();
        color: $color-dark;
        border-top: 0.0625rem solid $color-light-gray-bw-border;
        @media screen  and (max-width: 600px) {
            display: none;
        }
    }
}