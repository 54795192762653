//--------------------------- DESKTOP/TABLET ---------------------------//
.cn__holi__discover{
    position: relative;
    margin: 150px auto 150px;
    width: 100%;
    max-width: 1340px;
    padding: 0 50px;
    .cn__holi__sectitle, .cn__holi__secsubtitle{
        text-align: right;
    }
    &__slider{
        margin-top: 30px;
        &__wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    }
}
//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__holi__discover{
        margin: 70px auto 70px;
        padding: 0 30px;
        &__slider{
            margin-top: 20px;
            &__wrapper{
                grid-column-gap: 10px;
            }
        }
    }  
}
//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__holi__discover{
        position: relative;
        margin: 70px auto 0;
        width: 100%;
        max-width: unset;
        padding: 0;
        .cn__holi__sectitle, .cn__holi__secsubtitle{
            text-align: center;
        }
        &__slider{
            margin-top: 0;
        }
    }
}