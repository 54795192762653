@import '/src/styles/settings/colors';

.lyp-sleeping {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    .n-select-picker {
        margin-bottom: 18px;
    }    

    .error {
        position: absolute;
        margin-left: 6px;
    }

    .n-select-picker {
        margin-bottom: 18px;
    }    
}

.lyp-sleeping-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(370px, 438px));
    gap: 30px;
}
.lyp-sleeping-grid.sleeping-single-card {
    grid-template-columns: minmax(370px, 438px);

    .lyp-sleeping-card-bathrooms-vr-bottom {
        display: block;
        grid-template-columns: unset;
        gap: unset;
    }
}

.lyp-sleeping-card {
    border: 1px solid $color-light-gray-input;
    border-radius: 21px;
    padding: 30px;
}

.lyp-sleeping-card-name {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: $color-purple;
    margin-bottom: 18px;
}

.lyp-sleeping-card-title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    // color: $color-black;
    margin-bottom: 18px;
}

.lyp-sleeping-card-subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 18px;
}

.lyp-sleeping-card-subsubtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 18px;
    color: $color-dark-grey;
}

.bed-type-chip {
    .counter_wrapper {
        padding: 0;
    }
    .input-value {
        cursor: default;
        height: 100%;
        padding-right: 6px;
    }
    .input-value:hover {
        cursor: default;
    }
}

.lyp-sleeping-card-bedrooms {
    .extra {
        margin-bottom: 18px;
        height: 38px;

        .extra-suffix {
            pointer-events: none;
        }
    }
    .extra-select {
        margin-bottom: 18px;
    }
    .bedroom-type {
        margin-bottom: 18px;

        >ul {
            display: flex;
            gap: 18px;
            li div.n-radiobox label {
                padding: 0;
            }
            >li {
                label {
                    padding: 0;
                    cursor: pointer;
                    >input {
                        cursor: pointer;
                    }
                }    
            }
            li:hover {
                background: none;
            }
        }
    }

    .lyp-sleeping-card-bed-types {
        .text-group {
            .text {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }
}

.lyp-sleeping-card-bed-types-dialog {
    .lnp-checkbox {
        ul li {
            min-width: 300px;
        }
    }
}

.lyp-sleeping-card-bathrooms {
    .extra {
        margin-bottom: 24px;
    }

    .bathroom-type ul {
        display: flex;
        gap: 18px;
        li div.n-radiobox label {
            padding: 0;
        }
        >li {
            label {
                padding: 0;
                cursor: pointer;
                >input {
                    cursor: pointer;
                }
            }
        }
        li:hover {
            background: none;
        }
    }
    .bathroom-count {
        margin-bottom: 24px;

        >input {
            border-color: var(--color-subtle-grey);
        }
        >input:hover {
            border-color: var(--color-dark-grey);
        }
    }
}

.lyp-sleeping-card-bathrooms-vr {
    grid-column: 1/-1;
}

.lyp-sleeping-card-bathrooms-vr-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    .lyp-sleeping-card-subtitle {
        margin-bottom: 0;
    }
    .lyp-sleeping-card-subsubtitle {
        // margin-bottom: 0;
    }
}

@media screen and (max-width: 900px) {
    .lyp-sleeping-grid {
        grid-template-columns: minmax(370px, 438px);
        // gap: 30px;
    }

    .lyp-sleeping-card-bathrooms-vr-bottom {
        display: block;
        grid-template-columns: unset;
        gap: unset;
    }
}
