@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';


section.section--lnp-property-type {
    h2.lyp-property-type--title {
        font-family: var(--primary-font);
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -.01em;
        text-align: center;
        padding: 20px 0;
        margin: 0;
    }
}

.lyp-property-type {
    padding-right: 26px;
    position: relative;
    height: calc(100% - 60px);
    // padding: 0;
    overflow: auto;
    scrollbar-gutter: stable both-edges;

    .rooms {
        // scrollbar-gutter: stable both-edges;

        .rooms-row {
            position: relative;
            // height: 54px;
            padding-bottom: 16px;
            display: grid;
            grid-template-columns: minmax(auto, 280px) 190px minmax(auto, 240px) 180px;
            grid-column-gap: 16px;
            margin-bottom: 16px;

            .extra {
                width: unset;
                min-width: 180px;

                &.num_of_rooms {
                    min-width: 190px;
                }
            }
        }
    }
}

.star-rating {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-rating__item {
    border: 1px solid $color-subtle-grey;
    padding: 20px;
    border-radius: 15px;
    background-color: $color-white;
    font-weight: 400;
    color: $color-dark-grey;
    white-space: nowrap;
    margin-left: 20px;
    // margin-bottom: 20px;

    &--selected {
        border: 1px solid $color-cn-pink;
    }
}

.star-rating__item:hover {
    border: 1px solid $color-dark-grey;
    cursor: pointer;
}

.content-lyp-wrapper {
    display: flex;
    justify-content: center;
    padding: 4px;
}

.anim-fadein {
    animation-duration: 0.8s;
    animation-name: fadein;
    // animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.guest-picker-lyp {
    .guest-picker-lyp_summary {
        appearance: none;
        text-align: unset;
        background-color: unset;
        color: unset;
        width: 100%;
        position: relative;
        display: flex;
        border: 1px solid $color-light-gray-input;
        border-radius: 6px;
        padding: 8px 16px;
        // height: 38px;

        &:focus {
            outline: none;
            border: 1px solid $color-gray-hp;
        }

        &--empty {
            background: $color-light-gray-input;

            .guest-picker-lyp_summary-text {
                color: $color-dark-grey;
            }
        }

        .guest-picker-lyp_summary-symb {
            flex: 0 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
        }

        .guest-picker-lyp_summary-symb--chevron {
            display: flex;
            justify-content: center;
            align-items: center;
            transform-origin: center;
            transform: rotate(0deg);
            // transition: transform 0.2s ease-in-out;
        }

        .guest-picker-lyp_summary-text {
            font-family: $primary-font;
            flex: 1;
            margin-left: 12px;
            font-size: 16px;
            line-height: 120%;
        }
    }

    .guest-picker-lyp_summary:hover {
        cursor: pointer;
        // border: 1px solid $color-gray-hp;
    }

    // .guest-picker-lyp_details {
    //     // animation: appear 0.3s ease;
    //     border: 1px solid $color-light-gray-input;
    //     border-radius: 6px;
    //     background-color: $color-white;
    //     padding: 16px;
    //     display: flex;
    //     justify-content: center;
    //     box-shadow: 0 3px 3px rgba(0, 0, 0, .15);

    //     .omnisearch_content_action-area_guest-picker {
    //         margin-top: 0;
    //         grid-row-gap: 16px;
    //     }
    // }

    &--active {
        .guest-picker-lyp_summary {
            // border: 1px solid $color-gray-hp;

            .guest-picker-lyp_summary-symb--chevron {
                transform: rotate(180deg);
            }
        }

        .guest-picker-lyp_details {}
    }
}

.guest-picker-lyp_details {
    // animation: appear 0.3s ease;
    // border: 1px solid $color-light-gray-input;
    // border-radius: 6px;
    // background-color: $color-white;
    // padding: 16px;
    display: flex;
    justify-content: center;
    // box-shadow: 0 3px 3px rgba(0, 0, 0, .15);

    .omnisearch_content_action-area_guest-picker {
        margin-top: 0;
        grid-row-gap: 16px;
    }
}


.guest-picker-lyp--custom {
    max-width: 380px;
    min-width: 280px;
    position: relative;
}


.lyp-next-btn {
    appearance: none;
    text-align: unset;
    background-color: unset;
    color: unset;
    border: none;

    align-self: flex-start;
    margin-left: 16px;
    background-color: $color-cn-pink;
    color: $color-white;
    font-family: $primary-font;
    padding: 10px 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    line-height: 120%;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: $color-subtle-grey;

        &:hover {
            cursor: default;
        }
    }
}


.input-with-measurement {
    width: 190px;

    >input {

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none;
        }
    }

    .extra-suffix {
        min-width: 80px;

        .n-select-picker {
            border: none;
            background: none;
            height: 100%;

            &:hover {
                border: none;
            }

            .input-value {
                height: 100%;
                padding: 7px 10px;

                .text-group {
                    display: block;
                    margin-right: 4px;

                    .text {
                        display: inline-block;
                        // font-family: $primary-font;
                        color: $color-gray-hp;
                        font-size: 1em;
                        font-weight: normal;
                        line-height: 1.5em;
                    }
                }

                .ic-icon {
                    transition: unset;
                }
            }

            .input-dropdown {
                border-radius: 6px;
                box-shadow: 0 3px 3px rgba(0, 0, 0, .15);

                .n-radiobox-list {
                    .n-radiobox {

                        >label {
                            padding: 6px 8px;
                        }

                        .ic-icon {
                            flex: 0 0 auto;
                        }

                        .text {
                            width: unset;

                            >span {
                                color: $color-gray-hp;
                                font-size: 1em;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }

    // &.extra--error {
    //     .suffix {
    //         .n-select-picker {    
    //             .input-value {
    //                 .text-group {    
    //                     .text {
    //                         color: $color-error-red;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}


@media screen and (max-width: 600px) {
    section.section--lnp-property-type.section--lnp-property-type {
        padding-bottom: 0;
    }

    .lyp-property-type {
        position: relative;
        height: calc(100% - 60px);
        padding: 0;
        overflow: auto;

        .prop-wrapper {
            display: block;
        }

        .rooms {
            padding: 0 2px;
            width: 100%;

            .rooms-row {
                width: 100%;
                margin: 30px 0;
                padding-bottom: 30px;
                // border-bottom: 1px solid $color-light-gray-input;
                display: block;

                >div {
                    width: 100%;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .rooms-row:last-child {
                padding-bottom: 0;
                border: none;
            }
        }
    }

    .star-rating {
        flex-wrap: wrap;

        .star-rating__item {
            margin-bottom: 20px;
        }
    }
}