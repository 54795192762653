//--------------------------- DESKTOP/TABLET ---------------------------//
.cn__holi__main{
    position: relative;
    width: 100%;
    height: 100vh;
    background: #010252;
    &__imgbg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        transition: opacity .6s ease;
    }
    &__maintitle{
        position: absolute;
        width: 400px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: #FFFFFF;
        margin-top: -108px;
        h1{
            font-style: italic;
            font-weight: 900;
            font-size: 60px;
            line-height: 72px;
            letter-spacing: -0.04em;
            text-transform: uppercase;
            text-align: center;
        }
        h2{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.01em;
            margin-top: 10px;
            text-align: center;
        }
    }
    &__discountinfo{
        position: absolute;
        width: 100%;
        max-width: 1340px;
        bottom: 87px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 50px;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        span{
            margin-left: -8px;
        }
    }
    .covid {
        padding: 0 50px;
        max-width: 1340px;
    }
}
//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__holi__main{
        &__discountinfo{
            padding: 0 30px;
        }
        .covid {
            padding: 0 30px;
        }
    }     
}
//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__holi__main{
        position: relative;
        height: 100%;
        background: #010252;
        &__imgbg{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            transition: opacity .6s ease;
        }
        &__maintitle{
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            color: #FFFFFF;
            margin-top: -108px;
            padding: 0 20px;
            h1{
                font-size: 45px;
                line-height: 54px;
            }
        }
        &__discountinfo{
            width: 100%;
            max-width: 100%;
            bottom: 69px;
            left: unset;
            transform: unset;
            padding: 0 20px;
        }
        .covid {
            padding: 0 20px;
            bottom: 17px;
        }
    }
}