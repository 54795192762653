.menu_open_body {
  overflow: hidden;
}
.cn__contactus__wrapper {
  background-color: $color-light-gray-shade;

  .subscribe {
    margin-top: 150px;
  }

  
  .cn__shortfooter__wrapper {
    margin-top:100px;
  }

  .cn__footer__social {
    @media (min-width:700px) {
      text-align: center;
    }
    margin:25px 0px;

    a {
      margin:0px 15px;
      @media screen and (max-width:700px){
        margin: unset;
      }
      &:last-child{
        margin-right: 0px;
      }
      &:first-child{
        margin-left: 0px;
      }

      svg {
          transition: transform 0.3s ease;
          &:hover {
            transform: translateY(-5px);
        }
      }
    }
  }

  .cn__contactus {
    padding:0px 15px;
    padding-top: 88px;
    margin:0px auto;
    &__top_header_section {
      max-width: 1100px;
      margin:50px auto;
      text-align: center;
      span {
        color: $color-cn-pink;
      }
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 120%;
        color: #000000;
        line-height: 53px;
      }
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 44px;
        text-align: center;
        color: #797882;
        line-height: 53px;
      }

      @media (max-width:620px) {
        & {
          margin-top: 50px;
        }
        h2 {
          font-size: 2rem;
          line-height: normal;
        }
        h5 {
          font-size: 1.5rem;
          line-height: normal;
        }
        
      }
    }

    &__contact_options {
      display:flex; 
      text-align: center;
      justify-content: center;
      max-width: 100%;
      margin:100px 0px 100px 0px;
      flex-wrap: wrap;

      @media (max-width:620px) { 
        margin: 0px;
      }


      &__items {
        margin:15px 16px 50px 16px;
        position: relative;
        background: $color-white;
        width:218px;
        flex-wrap: wrap;
        height:214px;
        border-radius:27px; 
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center; 
        cursor: pointer;
        position: relative;
        &.shouldHidden {
          overflow: hidden;
          cursor: default;
        }
        &__whatsapp{
          .cn_contactus_contact_options__items_details_hover {
            display: none;
          }
          &:hover {
            height: 320px;
            @media (max-width:620px) {
              height:214px;
            }
            .cn_contactus_contact_options__items_details_hover{
              @media (max-width:620px) {
                display: none;
              }
              display: block;
              img {
                margin-top: 10px;
                margin-bottom: 10px;
              }
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                letter-spacing: 0.01em;
                text-decoration-line: underline;
                color: #797882;
                margin-bottom: 5px;
              }
            }
          }
        }
       
        .isInnerDialogClose {
          position: absolute;
          left:0px;
          right:0px;
          bottom:0%;
          height:100%;
        }
        .isInnerDialogOpen {
          position: absolute;
          left:0px;
          right:0px;
          bottom:100%;
          height:100%;
        }
        &__inner-dialog-container {
            position: absolute;
            top:0px; 
            left:0px;
            right:0px;
            bottom:0px;
            padding:15px; 
            background:$color-white;
          &__close-icon {
            position: absolute;
            right:15px;
            width:15px;
            height: 15px;
            cursor: pointer;
          }
         
          &__content {
            display: flex; 
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            
            div {
              margin:10px 0px;
              width:100%;
              h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                text-align: center;
                color: $color-black;
              }
              a {
                line-height: 36px;
                height: 36px;
                width: 100%;
                display:block;
                background: $color-gray-tollfree;
                border-radius: 32px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: $color-white;
                &:hover {
                  background-color: $color-cn-pink;
                }
              }
            }
          }
        }

        &__icon {
          margin-bottom: 17px;
          svg {
            position: relative;
            top:0px;
            transition: 200ms ease;
          }
        }
        &__details {
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: $color-black;
          }
        }
        &__actions {
          position: absolute;
          bottom: -27px;
          left:20px; right:20px;
          &__btn {
            transition: 200ms ease;
            display: block;
            font-style: normal;
            height:56px;
            display: flex;
            align-items: center;
            padding:10px;
            justify-content: center;
            font-weight: 600;
            color: $color-white;
            font-size: 15px;
            line-height: 19px;
            background: $color-dark-grey;
            border-radius: 16px;
            cursor: pointer;
          }
        }

        &:hover {
          .cn__contactus__contact_options__items__icon {
            svg {
              top:-10px;
            }
          }
          .cn__contactus__contact_options__items__actions__btn {
            background: $color-cn-pink;
          }
        }
      }
    } 

    &__faqs {
      margin-top:80px;
      display: block;
      background: $color-light-bg;
      border-radius: 16px;
      width:100%;
      padding:50px 15px 62px 15px;
      position: relative;
      flex-wrap: wrap;
      margin-bottom: 60px;
      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width:1100px;
        margin:0px auto 25px auto;
        flex-wrap: wrap;
        
        h1 {
          max-width: 664px;
          color: $color-dark-grey; 
          font-size: 32px;
          @media (max-width: 664px) {
            font-size: 22px;
            line-height: normal;
          }
          margin-bottom: 25px;
          span {
            color: $color-black;
          }
        }
        h1:first-child {
          max-width: 355px;
          color: $color-black;
        }
        
      }
      &__search {
        position: absolute;
        bottom:-25px;
        left:0px;
        right:0px;
        z-index: 1;
        &__wrap {
          max-width: 926px;
          margin:0px auto;
          display: flex;
          position: relative;
          background: $color-white;
          border: 0.5px solid $color-field-grey;
          box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.17);
          border-radius: 15px;
          align-items: center;
          justify-content: flex-start;
          padding:0px 18px;
          .search-icon {
              width:35px;
              height: 35px;
              cursor: pointer;
          }
          

          &__close-icon {
            opacity: 0;
            background: $color-black;
            width: 20px;
            height: 20px;
            padding: 5px;
            border-radius: 20px;
            cursor: pointer; 
            * {
              stroke: #fff;
            }
          }


          &:hover {
            .cn__contactus__faqs__search__wrap__close-icon {
              opacity: 1;
            }
          }

          input {
            all: unset;
            width: 100%;
            color: $color-grey-rare;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            justify-self: stretch;
            margin:0px 15px;
            padding:18px 0px;
          }
        }
      } 
      &__results {
        margin-top: 25px;
        &__wrap {
          margin:0px auto;
          max-width: 926px;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: flex-start;
          padding:0px 0px;
          flex-direction: column;

          &__list {
            background: $color-white;
            border: 1px solid $color-field-grey;
            box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
            border-radius: 22px;
            padding:24px 48px;
            width: 100%;
            h4 {
              font-weight: 700;
              font-size: 23px;
              color: $color-black;
            }

            &__accordion {
              margin-top: 30px;
              position: relative;
              &__container {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                cursor: pointer;
                
                h5 {
                  color: $color-black;
                  font-weight: 600;
                  font-size: 18px;
                }

                .accordion_dropdown_icon {
                  transform: rotate(-90deg);
                  text-align: center;
                  transition:transform 300ms ease;

                  &.open {
                    transform: rotate(0deg);
                  }
                }

              }
              &__typography {
                position: relative;
                margin:15px 0px 15px 0px;
                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: $color-black;
                  span {
                    color: $color-primary;
                  }
                }
              }
                .cn__contactus__faqs__results__wrap__list__accordion__typography{
                  display: none;
                }
            }
              .helpfaq__active {
                .cn__contactus__faqs__results__wrap__list__accordion__typography{
                  display: block;
                }
                .cn__contactus__faqs__results__wrap__list__accordion__container{
                  .accordion_dropdown_icon{
                    transform: rotate(0deg);
                  }
                }
              }
          }
        }
      }
    }
  }
}