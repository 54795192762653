//------------------- DESKTOP -------------------

.cn__hp__recentsearches{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 40px auto 30px;
    .cn__hp__heading{
        &__title{
            color: $color-black;
            font-weight: 700;
            font-size: 23px;
            line-height: 1;
            letter-spacing: -0.02em;
        }
        &__subtitle{
            font-size: 18px;
            line-height: 120%;
            font-weight: 400;
            margin-top: 15px;
        }
    }

    &__card {
        position: relative;
        border: solid 0.8px #797882;
        margin-bottom: 4px;
        border-radius: 18px;
        margin-left: 15px;
        display: flex;
        // align-items: flex-start;
        align-items: center;
        padding: 16px 24px 16px 16px;
        transition: all 0.3s ease;
        flex-shrink: 0;
        max-width: 288px;

        &:first-child{
            margin-left: 0;
        }

        img{
            margin-right: 8px;
            pointer-events: none;
            flex-shrink: 0;
        }

        &__title{
            font-size: 14px;
            line-height: 21px;
            margin-top: 0;
            color: #000000;
            pointer-events: none;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 600;
        }
        &__subtitle{
            font-size: 12px;
            line-height: 21px;
            line-height: 1;
            color: #1E2A3B;
            pointer-events: none;
            margin-top: 4px;
        }

        &__cancel{
            position: relative;
            width: 12px;
            height: 12px;
            top: -12px;
            cursor: pointer;
            margin-left: 15px;
            flex-shrink: 0;

            path{
                stroke: #242526;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
            }
        }

        &:hover{
            background: #FDE9E0;
            cursor: pointer;
        }
    }

    //------------ SLIDER WRAPPER-----------
    .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 18px 0;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 30px 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }
    }

    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 16px 20px 10px 0;
            scroll-padding: 0 0 0 20px;
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 18px;
        z-index: 0;
        @media screen and (max-width:1136px){
            height: 10px;
        }
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    }
    //------------ SLIDER WRAPPER-----------
}


//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__recentsearches{
        padding: 0;
        // margin: 60px auto;
        &__card {
            flex-shrink: 0;
            margin-left: 15px;
            &:first-child{
                margin-left: 30px;
            }
            &:hover{
                background: transparent;
                border: solid 1px #DFE2E5;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__recentsearches{
        padding: 0;
        margin: 30px auto 50px;
        margin-bottom: 20px;
        .cn__hp__heading{
            &__title{
                font-size: 16px;
                line-height: 1;
            }
            &__subtitle{
                display: none;
            }
        }
        &__card {
            &:first-child{
                margin-left: 20px;
            }
        }
    }
}