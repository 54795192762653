//-------------- DESKTOP --------------


.cn__gp__map{
    position: relative;
    margin: 0 auto 100px;
    &__title{
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #242526;
    }
    &__description{
        width: 50%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #242526;
        margin-top: 15px;
    }
    &__wrapper{
        position: relative;
        margin-top: 35px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .map-container{
            background-color: $color-dark-grey;
            border-radius: 20px;
            min-height: 297px;
            .gmnoprint a, .gmnoprint span, .gm-style-cc {
                display:none;
            }
            .gmnoprint div {
                background:none !important;
            }
            .map-fullscreen {
                display: none;
                margin-top: rem(20);
                margin-left: rem(20);
                width: rem(36);
                height: rem(36);
                left: 0;
                top: 0;
                background: $color-white;
                box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
                border-radius: rem(10);
                path {
                    stroke: #555759;
                }
            }
        }
        &__cities{
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .cn__gp__map__right{
                width: 200%;
                &__lists{
                    width: 50% !important;
                    float: unset !important;
                    list-style-type: none;
                    &.cn__gp__map__right__lists--two-columns{
                        columns: 2;
                        -webkit-columns: 2;
                        -moz-columns: 2;
                    }
                    a {
                        margin-top: 20px;
                        padding: 0 0 0 20px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #242526;
                        cursor: pointer;
                        &:first-child{
                            margin-top: 0;
                        }
                        .cn__gp__map__right__lists__title{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        .cn__gp__map__right__lists__description{
                            font-size: 14px;
                            line-height: 15px;
                            color: #797882;
                            margin-top: 2px;
                        }
                    }
                }
            }
            .cn__gp__map__right__sec1{
                padding-left: 20px;
                &__title{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .cn__gp__map__list {
                    width: 100%;
                    list-style-type: none;
                    a {
                        margin-top: 20px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #000000;
                        cursor: pointer;
                        &:first-child{
                            margin-top: 0;
                        }
                        .cn__gp__map__right__lists__title{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                        .cn__gp__map__right__lists__description{
                            font-size: 14px;
                            line-height: 15px;
                            color: #797882;
                            margin-top: 2px;
                        }
                        svg {
                            min-width: 13px;
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
            .cn__gp__map__right__sec2{
                padding-left: 20px;
                &__title{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .cn__gp__map__list {
                    width: 100%;
                    list-style-type: none;
                    a {
                        padding: 0;
                        margin-top: 20px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #000000;
                        cursor: pointer;
                        &:first-child{
                            margin-top: 0;
                        }
                        .cn__gp__map__right__lists__title{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        .cn__gp__map__right__lists__description{
                            font-size: 14px;
                            line-height: 15px;
                            color: #797882;
                            margin-top: 2px;
                        }
                        svg {
                            min-width: 13px;
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
        }
    }
}



//-------------- TABLET --------------

@media screen and (max-width:1136px){
    .cn__gp__map{
        position: relative;
        width: calc(100% - 60px);
        margin: 0 auto 80px;
        
        &__description{
            width: 100%;
        }
        &__wrapper{
            margin-top: 20px;
            display: block;
            .map-container{
                .geopage-map{
                    min-height: inherit;
                    position: unset !important;
                }
            }
            &__cities{
                grid-column-gap: 20px;
                margin-top: 20px;
                .cn__gp__map__right__sec1{
                    padding-left: 0;
                    margin-bottom: 35px;
                    @media screen and (max-width:600px){
                        margin-bottom: 0px; 
                    }
                }
                .cn__gp__map__right__sec2{
                    padding-left: 0;
                }
            }
        }
        .cn__geopages__heading{
            padding: 0;
        }
    }
}

//-------------- MOBILE --------------

@media screen and (max-width:600px){
    .cn__gp__map{
        position: relative;
        width: calc(100% - 40px);
        margin: 0 auto 60px;
        &__description{
            width: 100%;
        }
        &__wrapper{
            margin-top: 20px;
            display: block;
            .map-container{
                .geopage-map{
                    min-height: inherit;
                    position: unset !important;
                }
            }
            &__cities{
                grid-template-columns:  1fr;
                grid-column-gap: 20px;
                margin-top: 20px;
                .cn__gp__map__right__sec2{
                    margin-top: 20px;
                }
            }
        }
    }
}