.cn-tabs{
    width: 100%;
      .cn-tabs-nav{
        margin: 0px;
        padding: 0px;
        list-style: none;
          li{
            background: none;
            color: $color-black;
            display: inline-block;
            padding:0.625rem 0.938rem;
            cursor: pointer;
              &.cn-active{
                background: $color-light-gray;
                color: $color-black;
              }
            }
          }
        }
 
.cn-tabs-content{
    display: none;
    background:  $color-light-gray;
    padding: 0.938rem;
      &.cn-active{
        display: inherit;
      }
}
