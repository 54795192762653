.cn__dashboard_watchlist {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding-inline: 26px;
	padding-block: 15px;
	max-width: 806px;

	&_sms-notification-modal {
		width: 100%;
	}

	&_change-date-modal {
		min-width: 473px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 27px;
		caret-color: transparent;
		&_title {
			font-weight: 600;
			font-size: 23px;
			line-height: 120%;
		}

		&_calendar {
			display: flex;
			justify-content: center;
			width: 100%;
			&_input {
				width: 151px;
				height: 30px;
				margin-inline: 30px;
				border: 1px solid #acabb4;
				border-radius: 12px;
				text-align: center;
				cursor: pointer;
				background: url("/static/icon/calendar-filled.svg") no-repeat 7px -1px;
				padding-left: 30px;

				&_label {
					display: none;
				}
			}
		}

		&_button {
			/* Auto layout */
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;

			text-align: center;
			letter-spacing: 0.01em;

			color: #acabb4;

			width: 334px;
			height: 45px;

			/* Whites/Bright White */

			background: #ffffff;
			border: 1px solid #dfe2e5;
			border-radius: 30px;
		}

		@media (max-width: 680px) {
			min-width: unset;
			max-width: 100%;
			&_calendar {
				div {
					display: flex;
					justify-content: space-between;
					font-weight: 500;
					font-size: 14px;
					line-height: 150%;
					letter-spacing: 0.01em;
					color: #000000;
				}
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				gap: 15px;
				&_input {
					width: 151px;
					height: 30px;
					margin-left: 30px;
					margin-inline-end: 0px;
					border: 1px solid #acabb4;
					border-radius: 12px;
					text-align: center;
					cursor: pointer;

					&_label {
						display: block;
					}
				}
			}
			&_button {
				width: 207px;
			}
		}
	}

	&_unsub-confirmation-modal {
		width: 100%;
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		&_wrapper {
			@media screen and (max-width: 680px) {
				max-width: 181px !important;
			}
		}
		&_body {
			display: flex;
			justify-content: center;
			align-items: center;
			@media screen and (max-width: 680px) {
				flex-direction: column;
				gap: 16px !important;
				max-width: 181px;
			}
		}
	}

	&_verify-number-modal {
		width: 100%;
	}
}
.cn__dashboard_watchlist_notfound {
	max-width: unset;
}
