//--------------------------- DESKTOP/TABLET ---------------------------//
.cn__holi__popularplaces{
    position: relative;
    margin: 150px auto 126px;
    width: 100%;
    max-width: 1340px;
    padding: 0 50px;
    /* &__slider{
        margin-top: 30px;
        &__wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    } */
    &__slider{
        position: relative;
        width: 100%;
        margin-top: 30px;
        .cn__holi__slider{
            position: relative;
            width: 100%;
            clear: both;
            .slick-slide{
                padding: 0 10px;
            }
            .slick-list{
                width: calc(100% + 20px);
                margin-left: -10px;
                overflow: hidden;
                padding: 5px 0 24px;
            }
            .slick-arrow {
                width: 46px;
                height: 46px;
                z-index: 1;
                background-color: transparent;
                border: 3px solid #797882;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    height: 14px;
                    path{
                        stroke: #797882;
                        stroke-width: 4px;
                    }
                }
            }
            .slick-next {
                position: absolute;
                top: calc(100% + 25px);
                right: 0;
                display: inline-flex;
            }
            .slick-prev {
                position: absolute;
                top: calc(100% + 25px);
                right: 55px;
                display: inline-flex;
                left: auto;
            }
        }
    }
}
//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__holi__popularplaces{
        margin: 70px auto 70px;
        padding: 0 30px;
        /* &__slider{
            margin-top: 20px;
            &__wrapper{
                grid-column-gap: 10px;
            }
        } */
        &__slider{
            margin-top: 20px;
            .cn__holi__slider{
                .slick-slide{
                    padding: 0 5px;
                }
                .slick-arrow {
                    width: 32px;
                    height: 32px;
                    svg{
                        height: 10px;
                        path{
                            stroke-width: 6px;
                        }
                    }
                }
                .slick-next {
                    top: calc(100% + 17px);
                }
                .slick-prev {
                    top: calc(100% + 17px);
                    right: 40px;
                }
            }
        }
    }  
}
//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__holi__popularplaces{
        position: relative;
        margin: 70px auto 0;
        width: 100%;
        max-width: unset;
        padding: 0;
        .cn__holi__secsubtitle{
            display: none;
        }
        &__slider{
            margin-top: 0;
        }
    }
}