.show-map-trigger {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-bright-purple;
    font-size: 1rem;
    font-weight: 400;
    font-family: $primary-font;
    border-radius: rem(6);
    transition: .3s ease-in-out;
    padding-left: rem(10);
    padding-right: rem(10);
    height: rem(39);
    &:hover {
        background-color: $color-light-gray-input;
    }
    .show-map-trigger_label {
        margin-left: rem(10);
    }
}