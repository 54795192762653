
.deals-page{ // Deals page class for the body || this class should be moved to main.scss
    position: absolute;
    width: 100%;
    height: 100%;
}
.valentinestile{ //------------------------------------- Homepage Tile
    position: relative;
    width: 100vw;
    max-width: 1600px;
    margin: 0 auto;
    height: auto;
    background: #f7f7f7;
    padding: 196px 100px 0 100px;
    grid-column: 1/18;
    .valentinestile__card{
        width: 100%;
        height: 511px;
        background:url('/static/valentines/valentinesback.jpg') no-repeat center center;
        background-size: cover;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        .valentinestile__card__info{
            position: relative;
            width: 100%;
            color: #000000;

            .valentinestile__card__title{
                font-family: $secondary-font;
                margin-top: 80px;
                font-weight: 900;
                font-size: 36px;
                line-height: 45px;
                letter-spacing: -0.01em;
                text-align: center;
                span{
                    font-weight: 900;
                    background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
                    background-size: 100% 200%;
                    background-position: 50% 100%;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .valentinestile__card__title__info{
                font-family: $secondary-font;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 20px;
            }
            .valentinestile__card__subtitle{
                position: absolute;
                left: 40px;
                bottom: 40px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: left;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

@media screen and (max-width: 1136px) {
    .valentinestile{
        padding: 70px 30px 30px 30px;
    } 
}

@media screen and (max-width: 600px) {
    .valentinestile{
        grid-column: 1/18;
        padding: 40px 20px 0 20px;
        .valentinestile__card{
            height: 411px;
            .valentinestile__card__info{
                width: 100%;  
                padding: 0 20px;  
                .valentinestile__card__title{
                    font-size: 24px;
                    line-height: 28px;
                }
                .valentinestile__card__title__info{
                    font-size: 16px;
                    line-height: 19px;
                    margin-top: 10px;
                }
                .valentinestile__card__subtitle{
                    width: 100%;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center;
                    left: 0;
                    bottom: 20px;
                    padding: 0 20px;
                }
            }
        }
    }   
}

.cn__valentines{ // Main parent div
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    color: #242526;
    background: #f7f7f7;
    *{ // General properties for each element inside the parent element
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
    }

    input{
        font-size: 16px;
    }

    //Cookie banner
    .cn__cookie-wrapper{
     
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }

    /*-------- GENERAL --------*/

    .valentines__loader{ //------------------------------------- Loader
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: inherit;
        z-index: 1;
    }

    .cn__valentines__timer{ //------------------------------------- Counter
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        bottom: 52px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        letter-spacing: 0.05em;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #ffffff;
        .cn__valentines__timer__title{
            opacity: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.05em;
        }
        .cn__valentines__timer__time{
            display: flex;
            margin-top: 10px;
            .cn__valentines__timer__time__info{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 22px;
                p{
                    font-weight: 400;
                    &:first-of-type{
                        font-family: 'Fira Mono', monospace;
                        font-weight: 900;
                        font-size: 38px;
                        line-height: 46px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 600px) {
        .cn__valentines__timer{
            .cn__valentines__timer__time{
                display: flex;
                margin-top: 10px;
                .cn__valentines__timer__time__info{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 22px;
                    p{
                        font-weight: 400;
                        &:first-of-type{
                            font-size: 40px;
                            line-height: 50px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-height: 450px) {
        .cn__valentines__timer{
            bottom: 16px;
            font-size: 12px;
            line-height: 16px;
            .cn__valentines__timer__title{
                opacity: 0;
            }
            .cn__valentines__timer__time{
                display: flex;
                margin-top: 4px;
                .cn__valentines__timer__time__info{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 16px;
                    p{
                        font-weight: 400;
                        &:first-of-type{
                            font-size: 30px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }  
    }

    .cn__valentines__faq{ //------------------------------------- Faq
        position: relative;
        width: 100%;
        background: #f7f7f7;
        color: #797882;
        display: table;

        &.faq__no-tiles{
            padding: 150px 0 0 0;
            @media screen and (max-width: 600px) {
                padding: 80px 0 0 0;
            }
        }
        &.faq__with-tiles{
            padding: 94px 0 0 0;
            @media screen and (max-width: 1136px) {
                padding: 79px 0 0 0;
            }
            @media screen and (max-width: 600px) {
                padding: 5px 0 0 0;
            }
        }

        .cn__valentines__faq__wrapper{
            width: 100%;
            margin: 0 auto;
            .cn__valentines__faq__align{
                display: flex;
                padding: 0;
                max-width: 1340px;
                margin: 0 auto;
                @media screen and (max-width: 1440px) {
                    padding: 0 50px;
                }
            }
            .cn__valentines__faq__title{
                width: 50%;
                font-family: $primary-font;
                font-size: 34px;
                line-height: 40px;
                font-weight: 700;
                letter-spacing: -0.01em;
                color: #242526;
                margin-top: 16px;
                padding-right: 32px;
                @media screen and (max-width: 600px) {
                    padding-right: 0;
                }
            }
            .cn__valentines__faq__questions{
                width: 50%;
                .cn__valentines__faq__questions__question{
                    position: relative;
                    padding: 0 20px;
                    border-radius: 15px;
                    transition: all 0.3s ease;
                    &:hover{
                        background: #EEEEF0 !important;
                    }
                    .cn__valentines__faq__questions__question__closed{
                        position: relative;
                        width: 100%;
                        padding: 20px 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        h3{
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                            max-width: calc(100% - 40px);
                            font-feature-settings: unset;
                            font-variant: unset;
                            text-transform: unset;
                        }
                        svg{
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                            transform: rotate(-90deg);
                        }
                    }
                    .cn__valentines__faq__questions__question__answer{
                        position: relative;
                        margin-bottom: 0;
                        max-height: 0;
                        transition: all 0.6s ease;
                        p{
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 400;
                            opacity: 0;
                            transition: opacity 0.6s ease;
                            color: #797882;
                            pointer-events: none;
                            strong{
                                font-weight: 700;
                            }
                            a{
                                color: #F35597;
                                font-weight: 700;
                                cursor: pointer;
                                pointer-events: visible;
                            }
                        }
                        &.active {
                            p{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .cn__valentines__faq__cn{
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: #797882;
                background: #EEEEF0;
                margin-top: 130px;
                padding: 50px 0 100px 0;
                .cn__valentines__faq__cn__wrapper{
                    max-width: 680px;
                    margin: 0 auto;
                }
                .cn__valentines__faq__cn__title{
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 26px;
                    font-variant: unset;
                    text-transform: unset;
                    color: #242526;
                }
                .cn__valentines__faq__cn__description{
                    margin-top: 15px;
                }
                .cn__valentines__faq__cn__link{
                    position: relative;
                    margin-top: 15px;
                    display: inline-flex;
                    align-items: center;
                    color: #F35597;
                    font-weight: 500;
                    cursor: pointer;
                    .cn__bf__faq__arrow{
                        margin-left: 6px;
                        transition: all 0.6s ease;
                    }
                    .cn__bf__faq__line{
                        position: relative;
                        width: 20px;
                        height: 1px;
                        background: #F35597;
                        margin-right: 8px;
                        transition: all 0.6s ease;
                    }
                    &:hover{
                        .cn__bf__faq__arrow{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1136px) {
        .cn__valentines__faq{
            padding: 70px 0 0 0;
            .cn__valentines__faq__wrapper{
                .cn__valentines__faq__align{
                    padding: 0 30px;
                }
                .cn__valentines__faq__questions{
                    .cn__valentines__faq__questions__question{
                        &:hover{
                            background: unset;
                        }
                    }
                }
                .cn__valentines__faq__cn{
                    margin-top: 130px;
                    padding: 50px 0 100px 0;
                    .cn__valentines__faq__cn__wrapper{
                        max-width: calc(100% - 60px);
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 600px) {
        .cn__valentines__faq{
            padding: 5px 0 0 0;
            .cn__valentines__faq__wrapper{
                .cn__valentines__faq__align{
                    display: block;
                    padding: 0;
                }
                .cn__valentines__faq__title{
                    width: 100%;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 26px;
                    padding: 0 20px;
                    margin-top: 0;
                }
                .cn__valentines__faq__questions{
                    width: 100%;
                    padding: 0;
                    margin-top: 20px;
                    .cn__valentines__faq__questions__question{
                        border-radius: 0 !important;
                        .cn__valentines__faq__questions__question__closed{
                            h3{
                                line-height: 22px;
                            }
                        }
                        .cn__valentines__faq__questions__question__answer{
                            position: relative;
                            margin-bottom: 0;
                            max-height: 0;
                            transition: all 0.6s ease;
                            p{
                                padding: 0;
                            }
                        }
                    }
                }
                .cn__valentines__faq__cn{
                    margin-top: 80px;
                    padding: 40px 0 79px 0;
                    .cn__valentines__faq__cn__wrapper{
                        max-width: calc(100% - 40px);
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .tiles-section { //------------------------------------- Tiles
        position: relative;
        padding: 56px 100px;
        background: #f7f7f7;
        width: 100%;
        max-width: 1540px;
        margin: 0 auto;
        .simplebar-content{
            display: inline-grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
            &::before, &::after{display: none;};
            color: #242526;
        }
        a{
            text-decoration: none;
            color: inherit;
        }
        .card{
            position: relative;
            width: 100%;
            height: auto;
            border-radius: 10px;
            font-family: $primary-font;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            margin-top: 20px;
            opacity: 0;
            .card-wrapper{
                background: #ffffff;
                border-radius: 10px;
            }
            .card__img-wrapper{
                width: 100%;
                height: 167px;
                overflow: hidden;
                border-radius: 10px 10px 0 0;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0,0,0);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px 10px 0 0;
                    transform-origin: center;
                    transition: all 1s ease;
                }
            }
    
            &:hover{
    
                .card__img-wrapper{
                    img{
                        transform: scale(1.1);
                    }
                }
    
            }
    
    
            .card__info{
                padding: 10px;
                .card__info__title{
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
                .card__info__place{
                    font-size: 19px;
                    line-height: 19px;
                    font-variant: all-small-caps;
                    margin-top: 5px;
                    color: #ACABB4;
                }
                .card__info__when{
                    margin-top: 5px;
                    color: #ACABB4;
                }
                .card__info__discount{
                    display: table;
                    margin-top: 10px;
                    padding: 5px 10px;
                    background: #F35597;
                    color: #ffffff;
                    border-radius: 10px 0px 0px 10px;
                    float: right;
                    position: relative;
                    left: 10px;
                    font-size: 12px;
                    font-weight: 500;
                }
                .card__info__rest{
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    clear: both;
                    position: relative;
                    top: 10px;
                    margin-bottom: 10px;
                    .card__info__rating{
                        display: flex;
                        align-items: center;
                        color: #242526;
                        img{
                            width: 15px;
                            margin-right: 3px;
                        }
                    }
                    .card__flex{
                        display: flex;
                        align-items: baseline;
                        .card__info__price-prev{
                            margin-right: 15px;
                            font-size: 14px;
                            color: #797882;
                            text-decoration: line-through;
                        }
                        .card__info__price-actual{
                            font-weight: 600;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .tiles-section {
            position: relative;
            //padding: 100px 0 85px 0;
            padding: 56px 0;
            .simplebar-content{
                display: flex;
                grid-template-columns: unset;
                grid-gap: unset;
                &::before, &::after{display: unset;}
            }
            a{
                margin-right: 20px;
                &:first-child{
                    padding-left: 30px;
                }
                &:last-child{
                    padding-right: 50px;
                    margin-right: 0px;
                }
            }
            .card{
                position: relative;
                flex-shrink: 0;
                width: 320px;
                height: auto;
                border-radius: 10px;
                font-family: $primary-font;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                box-sizing: unset;
                padding-bottom: 15px;
                img{
                    width: 100%;
                    border-radius: 10px 10px 0 0;
                }
                &:hover{
                    .card__img-wrapper{
                        img{
                            transform: none;
                        }
                    } 
                }
                .card__info{
                    padding: 10px;
                    .card__info__title{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                    }
                    .card__info__place{
                        line-height: 19px;
                        margin-top: 5px;
                        color: #ACABB4;
                    }
                    .card__info__when{
                        margin-top: 5px;
                        color: #ACABB4;
                    }
                    .card__info__discount{
                        display: table;
                        margin-top: 10px;
                        padding: 5px 10px;
                        background: #F35597;
                        border-radius: 10px 0px 0px 10px;
                        float: right;
                        position: relative;
                        left: 10px;
                    }
                    .card__info__rest{
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        clear: both;
                        position: relative;
                        top: 10px;
                        margin-bottom: 10px;
                        .card__info__rating{
                            display: flex;
                            align-items: center;
                            color: #ACABB4;
                            img{
                                width: 15px;
                                margin-right: 3px;
                            }
                        }
                        .card__flex{
                            display: flex;
                            align-items: baseline;
                            .card__info__price-prev{
                                margin-right: 15px;
                                font-size: 14px;
                                color: #797882;
                                text-decoration: line-through;
                            }
                            .card__info__price-actual{
                                font-weight: 600;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .simplebar-track.simplebar-horizontal{
                background: #f7f7f7;
                .simplebar-scrollbar{
                    width: 100%;
                    background: #f7f7f7;
                    &::before{
                        width: 100%;
                        background:#f7f7f7;
                    }
                }
            }
        }  
    }
    
    @media screen and (max-width: 600px) {
        .tiles-section {
            position: relative;
            //padding: 132px 0 85px 0;
            padding: 80px 0;
            a{
                &:first-child{
                    padding-left: 20px;
                }
                &:last-child{
                    padding-right: 20px;
                }
            }
            .card{
                width: 250px;
                font-family: $primary-font;
                .card__info{
                    background: #EEEEF0;
                    border-radius: 0 0 10px 10px;
                    .card__info__place{
                        color: #797882;
                    }
                    .card__info__when{
                        color: #797882;
                    }
                    .card__info__rest{
                        .card__info__rating{
                            color: #797882;
                        }
                        .card__flex{
                            .card__info__price-prev{
                                color: #797882;
                            }
                        }
                    }
                }
            }
        } 
    }

    /*-------- SCREEN 1 --------*/

    .valentines-screen1{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .valentines-image{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: opacity .6s ease;
                @media screen and (max-width: 599px) {
                    object-position: 81% 0;
                }
            }
        }
        .cn__valentines1{
            position: relative;
            width: 100%;
            min-height: 100%;
            .cn__valentines__home{
                position: absolute;
                top: 32px;
                left: 32px;
                cursor: pointer;
                .cn__valentines__home__logo{
                    width: 133px;
                    opacity: 0;
                }
            }
            .cn__valentines__info{
                position: absolute;
                width: calc(100% - 50px);
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                .cn__valentines__info__title{
                    font-family: $secondary-font;
                    font-size: 50px;
                    line-height: 45px;
                    font-weight: 400;
                    letter-spacing: -0.02em;
                    opacity: 0;
                    transform: translateY(20px);
                    text-align: center;
                    margin-top: -5%;
                    @media screen and (max-width: 599px) {
                        margin-top: -47%;
                    }
                    @media screen and (min-width: 600px) and (max-width: 1135px) {
                        margin-top: -37%;
                    }
                    span:first-of-type{
                        color: #F35597;
                        font-weight: 900;
                        -webkit-text-stroke: 1px;
                    }
                    span:last-child{
                        font-family: $primary-font;
                        font-size: 24px;
                        line-height: 29px;
                        text-transform: uppercase;
                        font-variant: all-small-caps;
                        font-weight: 500;
                        letter-spacing: -0.02em;
                    }
                }
                .cn__valentines__info__subtitle{
                    font-family: $primary-font;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    margin-top: 10px;
                    font-variant: all-small-caps;
                }
            }
        }
    } 
    @media screen and (max-width: 600px) {
        .valentines-screen1{
            .cn__valentines1{
                .cn__valentines__home{
                    top: 20px;
                    left: 20px;
                }
                .cn__valentines__info{
                    width: 100%;
                    .cn__valentines__info__title{
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 700;
                        padding: 0 20px;
                        span{
                            -webkit-text-stroke: 0.7px !important;
                            &:last-child{
                                font-size: 20px;
                            }
                        }
                    }
                    .cn__valentines__info__subtitle{
                        font-size: 18px;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    
    @media screen and (max-height: 450px) {
        .valentines-screen1{
            .cn__valentines1{
                .cn__valentines__home{
                    top: 20px;
                    left: 20px;
                }
                .cn__valentines__info{
                    width: 100%;
                    .cn__valentines__info__title{
                        margin-top: 16px;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 700;
                        span{
                            &:last-child{
                                font-size: 20px;
                            }
                        }
                    }
                    .cn__valentines__info__subtitle{
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-height: 550px) {
        .valentines-screen1{
            height: 717px;
        }
    }

    /*-------- SCREEN 2 --------*/

    .valentines-screen2{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .cn__valentines2{
            position: relative;
            width: 100%;
            min-height: 100%;
            background: #f7f7f7;
            font-family: $primary-font;
        
            .cn__valentines__timer{
                color: #242526;
            }
        
            .cn__valentines2__logo{
                position: relative;
                margin-top: 51px;
                margin-left: 100px;
                width: 133px;
                cursor: pointer;
                opacity: 0;
                visibility: hidden;
            }
            .cn__valentines2__until{
                position: relative;
                margin-top: 127px;
                margin-left: 102px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                opacity: 0;
                visibility: hidden;
            }
            .cn__valentines2__title{
                position: relative;
                font-family: $secondary-font;
                margin-top: 10px;
                margin-left: 100px;
                font-size: 50px;
                line-height: 55px;
                font-weight: 400;
                letter-spacing: -0.02em;
                width: calc(100% - 650px);
                opacity: 0;
                visibility: hidden;
                .cn__bf2__pink{
                    color:#F35597;
                    font-weight: 900;
                    -webkit-text-stroke: 1px;
                }
                .cn__bf2__sign{
                    font-size: 45px;
                    font-weight: 400;
                    position: relative;
                    top: -10px;
                }
            }
            .cn__valentines2__info{
                background: #242526;
                color: #ffffff;
                height: 50px;
                margin-top: 20px;
                text-transform: uppercase;
                font-size: 25px;
                line-height: 30px;
                font-weight: 400;
                letter-spacing: 0.01em;
                font-variant: all-small-caps;
                width: 0;
                display: flex;
                align-items: center;
                &__info{
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    margin-left: 100px;
                    @media screen and (max-width: 1136px) {
                        margin-left: 0;
                    }
                    @media screen and (max-width: 600px) {
                        padding: 0 20px;
                        img{
                            width: 32px;
                        }
                    }
                    p{
                        padding: 0 12px;
                    }
                }
            }
            .banner-search{
                position: absolute;
                top: 50%;
                transform: translate(20px,-50%);
                right: 100px;
                width: 418px;
                background: #ffffff;
                padding: 59px 44px 50px 44px;
                border-radius: 20px;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                z-index: 10;
                opacity: 0;
                visibility: hidden;
                .banner-search-titles{
                    display: none;
                }
                input{
                    //background: #E9E9EB;
                    //box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
                    border-radius: 6px;
                    color: #242526;
                    font-weight: 400;
                    border-color: #E9E9EB;
                    &::placeholder{
                        color: #ACABB4;
                    }
                }
                @media screen and (min-width: 601px) {
                    .cn__autocomplete-suggestions li .cn__as__info .cn__as__info-title{
                        color: #242526;
                        font-weight: 400;
                    }
                    .cn__autocomplete-suggestions li .cn__as__info .cn__as__info-subtitle{
                        font-weight: 400;
                    }
                    .cn__autocomplete-suggestions li .cn__as__info .cn__as__info-title span .highlight{
                        color: #242526;
                    } 
                
                    .DateRangePicker input {
                        background: transparent;
                        box-shadow: none;
                    }
                    .DateRangePickerInput_arrow{
                        background: url('/static/valentines/date-icon.svg');
                    }
                    .primary-input.input-empty{
                        background: #E9E9EB;
                        border-color: #E9E9EB;
                    }
                    .DayPicker__horizontal, .CalendarMonth, .DateRangePicker_picker, .CalendarMonthGrid{
                        background: #ffffff;
                        font-weight: 400;
                    }
                    .CalendarDay__default, .CalendarMonth_caption{
                        color: #242526;
                    }
                    .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover{
                        color: #797882;
                    }
                    .CalendarDay__today{
                        color:#F35597 !important;
                    }
                    .CalendarDay__default:hover{
                        background: #F35597;
                        color:#ffffff !important;
                    }
                    .DayPickerNavigation_rightButton__horizontalDefault{
                        background-image: url('/static/valentines/bf-right-arrow.svg');
                    }
                    .DayPickerNavigation_leftButton__horizontalDefault{
                        background-image: url('/static/valentines/bf-left-arrow.svg');
                    }
                    .CalendarDay__selected_start, .CalendarDay__selected_end{
                        background: #F35597;
                        color:#ffffff !important;
                    }
                    .btn-primary {
                        transition: all 0.3s ease;
                        display: flex;
                        justify-content: center;
                        background: #F35597 !important;
                        &:disabled{
                            background: #F35597 !important;
                        }
                        &::before{
                            position: relative;
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                            margin-left: -16px;
                            content: "";
                            background: url(/static/home/search.svg);
                        }
                    }
                }
                .banner-search-inner{
                    h2{display: none;}
                }
                .search-input{display: none;}
                .cn-tabs .cn-tabs-nav {
                    background: #f7f7f7;
                    width: 100%;
                    display: table;
                    margin-bottom: 60px;
                    border-radius: 10px;
                    padding: 2px;
                    .tab-link {
                        padding: 9px 0;
                        border-radius: 8px;
                        p{
                            transform: translateX(10px);
                        }
                        &:before{
                            left:38px !important;
                        }
                    }
                    li{
                        position: relative;
                        display: inline-table;
                        width: 50%;
                        text-align: center;
                        color: #242526;
                        font-weight: 400;
                        &.cn-active{
                            background: #E9E9EB;
                            font-weight: 600;
                        }
                        span{
                            display: none;
                        }
                        &:first-child{
                            &::before{
                                position: absolute;
                                top: 8px;
                                left: 28px;
                                width: 24px;
                                height: 24px;
                                content: '';
                                background: url(/static/valentines/hotel-icon.svg);
                            }
                        }
                        &:last-child{
                            &::before{
                                position: absolute;
                                top: 8px;
                                left: 28px;
                                width: 24px;
                                height: 24px;
                                content: '';
                                background: url(/static/valentines/house-icon.svg);
                            }
                        }
                    }
                }
                @media screen and (min-width: 601px) {
                    .guests-dd-box{
                        top: 38px;
                        background: #ffffff;
                        border-color: #ffffff;
                        color: #242526;
                        font-weight: 400;
                        input{
                            background: inherit;
                            color: #242526;
                            box-shadow: inherit;
                            &::placeholder{
                                color: inherit;
                            }
                        }
                        .primary-input{
                            background: #e9e9eb;
                            &:focus{
                                background: #ffffff;
                                border: 1px solid #ebeaeb;
                                & + span {
                                    background: #ffffff;
                                }
                            }
                            &::placeholder {
                                color: #ACABB4;
                            }
                            &:not(:placeholder-shown){
                                background: #ffffff;
                                border: 1px solid #ebeaeb;
                            }
                            
                        }
                        .plusminus-input{
                            background: #ffffff;
                        }
                        .quantity-up, .quantity-down{
                            svg{
                                path{
                                    stroke: #ACABB4;
                                }
                            }
                            &:disabled{
                                opacity: 0.2;
                            }
                        }
                        .btn-text-bright-purple{
                            svg{
                                path{
                                    stroke: #4C35BC;
                                }
                            }
                            @media screen and (max-width: 1136px) {
                                margin-left: -14px;
                                &:hover{
                                    background: none;
                                }
                            }
                        }
                        .btn-text-black, .btn-text-primary{
                            transition: all 0.3s ease;
                        }
                        .btn-text-black:hover{
                            background: #ffffff;
                        }
                        .btn-text-primary:hover{
                            background: #F35597;
                            color: #ffffff;
                        }
                        .btn-text-primary{
                            color: #F35597;
                        }
                    }
                }
            }
            .cn__valentines2__markedinfo{
                position: absolute;
                left: 100px;
                bottom:52px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                opacity: 0;
                visibility: hidden;
            }
            .search-input{
                opacity: 0;
                visibility: hidden;
                margin-top: 10vh;
                width: calc(100% - 40px);
                margin-left: 20px;
                display: block;
                border: 1px solid #E9E9EB;
                .si__search-icon{
                    padding: 0!important;
                    margin-top: 14px;
                    svg{
                        width: 20px;
                        path{
                            fill:#F35597;
                        }
                    }
                }
                .cn-input-group{
                    input{
                        background: inherit;
                        border: none;
                        box-shadow: none;
                        height: 52px;
                        font-size: 17px;
                        line-height: 18px;
                        font-weight: 400;
                        color: #797882;
                        padding-left: 16px;
                        &::placeholder{
                            color: #797882;
                        }
                    }
                }
                .mble-guest-close{display: none !important}
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .valentines-screen2{
            .cn__valentines2{
                position: relative;
                width: 100vw;
                height: auto;
                min-height: 100%;
                background: #f7f7f7;
                font-family: $primary-font;
            
                .cn__valentines2__logo{
                    position: relative;
                    margin-top: 34px;
                    margin-left: 50px;
                    width: 133px;
                    cursor: pointer;
                }
                .cn__valentines2__until{
                    margin-top: 4vh;
                    margin-left: auto;
                    font-size: 16px;
                    line-height: 19px;
                    text-align:center;
                }
                .cn__valentines2__title{
                    margin-top: 5px;
                    margin-left: auto;
                    font-size: 42px;
                    line-height: 53px;
                    text-align: center;
                    width: 100%;
                    padding: 0 50px;
                    .cn__bf2__sign{
                        font-size: 30px;
                        font-weight: 400;
                        position: relative;
                        top: -16px;
                        left: -5px;
                    }
                }
                .cn__valentines2__info{
                    margin-top: 15px;
                    font-size: 24px;
                    line-height: 29px;
                    font-weight: 500;
                    text-align: center;
                    justify-content: center;
                    p{
                        margin-left: 0;
                    }
                }
                .banner-search{
                    position: relative;
                    top:unset;
                    left: unset;
                    transform: none !important;
                    right: auto;
                    margin: 0 auto;
                    margin-top: 4vh;
                }
                .cn__valentines__timer{
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                    bottom: unset;
                    margin-top: 10vh;
                }
                .cn__valentines2__markedinfo{
                    position: relative;
                    margin-top: 3vh;
                    margin-left: 30px;
                    left: auto;
                    bottom:unset;
                    font-size: 16px;
                    line-height: 19.2px;
                    font-weight: 400;
                }
            } 
        }
    }
    
    @media screen and (max-width: 600px) {
        .valentines-screen2{
            .cn__valentines2{    
                .cn__valentines2__logo{
                    position: relative;
                    margin-top: 30px;
                    margin-left: 20px;
                }
                .cn__valentines2__until{
                    display: none;
                }
                .cn__valentines2__title{
                    margin-top: 10vh;
                    font-size: 28px;
                    line-height: 35px;
                    letter-spacing: -0.01em;
                    padding: 0 20px;
                    .cn__bf2__sign{
                        font-size: 19px;
                        line-height: 36px;
                        font-weight: 700;
                        top: -8px;
                        margin-left: 0;
                    }
                }
                .cn__valentines2__info{
                    margin-top: 10px;
                    font-size: 20px;
                    line-height: 19px;
                    font-weight: 400;
                    text-align: center;
                    height: 50px;
                }
                .banner-search{
                    width: 100%;
                    padding: 0 20px;
                    margin-top: 10vh;
                    background: transparent;
                    position: relative;
                    top: unset;
                    left: unset;
                    transform: none !important;
                    box-shadow: none;
                    .banner-search-inner{display: none;}
                    .search-input{
                        display: block;
                        border: 1px solid #E9E9EB;
                        .si__search-icon{
                            padding: 0!important;
                            margin-top: 14px;
                            svg{
                                width: 20px;
                                path{
                                    fill:#F35597;
                                }
                            }
                        }
                        input{
                            background: inherit;
                            border: none;
                            box-shadow: none;
                            height: 52px;
                            font-size: 17px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #797882;
                            padding-left: 16px;
                            &::placeholder{
                                color: #797882;
                            }
                        }
                    }
                    .guests-dd-box{
                        .mble-guest-close{display: none;}
                        .add-child{
                            .primary-input{
                                border: 1px solid #ACABB4;
                            }
                        }
                    }
                    .primary-input.input-empty{
                        background: inherit !important;
                        border-color: inherit !important;
                    }
                }
                .cn__valentines__timer{
                    position: absolute;
                    bottom: 52px;
                    margin-top: 0;
                    font-size: 12px;
                    .cn__valentines__timer__time{
                        .cn__valentines__timer__time__info p:first-of-type {
                            font-size: 32px;
                            line-height: 38px;      
                        }
                    }
                }
                .cn__valentines2__markedinfo{
                    position: absolute;
                    bottom: 16px;
                    margin-left: 20px;
                    line-height: 19px;
                    letter-spacing: -0.065em;
                    font-weight: 400;
                    font-size: 12px;
                    color: #797882;
                }
            } 
        }
    }

    @media screen and (max-height: 550px) {
        .valentines-screen2{
            height: 717px;
        }
    }
    /*-------- SCREEN 3 --------*/
    
    .valentines-screen3{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .cn__valentines__ended{
            position: relative;
            width: 100%;
            height: 100%;
        
            .shapes{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: opacity .6s ease;
                }
        
            }
        
            .cn__valentines__home{
                position: absolute;
                top: 34px;
                left: 50px;
                cursor: pointer;
                z-index: 3;
                opacity: 0;
                .cn__valentines__home__logo{
                    width: 133px;
                }
            }
            &__infowrapper{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                .cn__valentines__ended__info{
                    width: 547px;
                    margin-right: 85px;
                    h1{
                        font-family: $secondary-font;
                        font-weight: 900;
                        font-size: 50px;
                        line-height: 52px;
                        letter-spacing: -0.03em;
                        -webkit-text-stroke: 1px;
                        opacity: 0;
                        transform: translate(0,20px);
                    }
                    h2{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.01em;
                        opacity: 0.6;
                        margin-top: 10px;
                        opacity: 0;
                        transform: translate(0,20px);
                    }
                }
                .mobBanner{
                    width: 100%;
                    opacity: 0;
                    margin-top: 60px;
                    padding: 0 20px;
                    .search-input{
                        display: block;
                        border: 1px solid #E9E9EB;
                        .si__search-icon{
                            padding: 0!important;
                            margin-top: 14px;
                            svg{
                                width: 20px;
                                path{
                                    fill:#F35597;
                                }
                            }
                        }
                        .cn-input-group{
                            input{
                                background: inherit;
                                border: none;
                                box-shadow: none;
                                height: 52px;
                                font-size: 17px;
                                line-height: 18px;
                                font-weight: 400;
                                color: #797882;
                                padding-left: 16px;
                                &::placeholder{
                                    color: #797882;
                                }
                            }
                        }
                        .mble-guest-close{display: none !important}
                    }
                }
            }
            .banner-text-form{
                background: transparent;
                padding: 0;
                opacity: 0;
                transform: translate(20px,0);
                .banner-search{
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                    .banner-search-inner{
                        background: #ffffff;
                    }
                }
            }
        }
        .tiles-section{
            .card__info__price-prev{display: none;}
            .card__info__discount{display: none !important;}
        }
    }

    @media screen and (max-width: 1136px) and (min-width: 601px){
        .valentines-screen3{
            .cn__valentines__ended{
                &__infowrapper{
                    flex-direction: column;
                    .cn__valentines__ended__info{
                        width: 498px;
                        margin-right: 0;
                        h1{
                            text-align: center;
                        }
                        h2{
                            text-align: center;
                        }
                    }
            
                }
                .banner-text-form{
                    min-height: auto;
                    margin-top: 50px;
                    transform: translate(0,40px);
                }
            } 
        }
    }

    @media screen and (max-width: 600px){
        .valentines-screen3{
            .cn__valentines__ended{
                .cn__valentines__home{
                    top: 30px;
                    left: 20px;
                }
                &__infowrapper{
                    flex-direction: column;
                    .cn__valentines__ended__info{
                        width: 100%;
                        margin-right: 0;
                        padding: 0 20px;
                        //margin-top: -120px;
                        h1{
                            font-size: 32px;
                            line-height: 36px;
                            text-align: center;
                            letter-spacing: -0.03em;
                        }
                        h2{
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            letter-spacing: -0.01em;
                        }
                    }
            
                }
                .banner-text-form{
                    min-height: auto;
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    transform: translate(0,40px);
                }
            } 
        }
    }

    @media screen and (max-height: 550px) {
        .valentines-screen3{
            height: 717px;
            .cn__valentines__ended{
                &__infowrapper{
                    position: relative;
                    height: auto;
                    top: auto;
                    padding: 97px 0;
                }
            }
        }
    }
}
