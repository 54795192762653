form,
.form {
    $self: &;
    &__field {
        position: relative;
        margin-bottom: 20px;
    }
    &__row {
        position: relative;
        margin-bottom: 20px;
    }
    &.form--inline {
        display: flex;
        justify-content: space-between;
    }
    .error {
        color: var(--color-red);
        font-size: 14px;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px !important;
    }
    .col-two {
        grid-column-gap: 10px;
    }
}

.form-inline-insert {
    position: relative;
    .form {
        .form__row {
            &:first-child {
                margin-bottom: 15px;
                .n-input-text input {
                    &:active,
                    &:focus {
                        border-top: none;
                        border-left: none;
                        border-right: none;
                    }
                }
            }
            &:last-child {
                margin: 0px;
                display: flex;
                justify-content: space-between;
                .n-btn {
                    padding: 0px;
                    &.n-btn--cancel {
                        font-weight: 400 !important;
                    }
                    &:last-child {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.inline-name-form {
    .form__row:last-child {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
        .n-btn {
            padding-left: 0px;
            margin-right: 0px;
        }
    }
}

.n-calendar {
    .DayPicker {
        box-shadow: none;
        margin: auto;
    }
    .DayPicker_weekHeader {
        top: 54px;
    }
    .DayPicker_weekHeader_li {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 19px;
        /* identical to box height, or 119% */
        text-align: center;
        font-variant: all-small-caps;
    }
    .CalendarMonth_caption {
        strong {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .CalendarMonth_table {
        border-spacing: 0;
        tbody {
            tr {
                td {
                    border-top: 1px solid #EEEEF0;
                    border-left: 1px solid #EEEEF0;
                    border-right: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    &:not(.CalendarDay__blocked_out_of_range) {
                        border-top: 1px solid #ACABB4;
                        border-left: 1px solid #ACABB4;
                        border-right: 1px solid transparent;
                        border-bottom: 1px solid transparent;
                    }
                    &.CalendarDay__highlighted_calendar {
                        background: #EEEEF0 !important;
                    }
                    &:last-child {
                        border-right: 1px solid #EEEEF0;
                    }
                    &.CalendarDay__selected {
                        color: white !important;
                    }
                    &.CalendarDay {
                        &:not(.CalendarDay__selected_span, .CalendarDay__selected, .CalendarDay__selected_end, .CalendarDay__blocked_out_of_range):hover {
                            color: var(--color-pure-black);
                            border: 1px solid var(--color-pure-black);
                        }
                    }
                    &.CalendarDay {
                        &:not(.CalendarDay__selected_span, .CalendarDay__selected, .CalendarDay__selected_end, .CalendarDay__blocked_out_of_range) {
                            border-top: 1px solid #ACABB4;
                            border-left: 1px solid #ACABB4;
                        }
                    }
                    &.CalendarDay.CalendarDay__default {
                            &:last-child {
                                &:not( .CalendarDay__blocked_out_of_range) {
                                    border-right: 1px solid #ACABB4;
                                }
                            }
                        background: transparent;
                        @include tablet {
                            background: var(--color-white);
                        }
                    }
                    &.CalendarDay__selected,
                    &.CalendarDay__selected:active,
                    &.CalendarDay__selected:hover {
                        background: var(--color-primary);
                        border-top: 1px solid var(--color-pure-black);
                        border-bottom: 1px solid var(--color-pure-black);
                        color: var(--color-pure-black);
                        &:before {
                            display: none;
                        }
                        &.CalendarDay__selected_start {
                            border-left: 1px solid var(--color-pure-black);
                            background: #F35597 !important;
                            color: white;
                        }
                        &.CalendarDay__selected_end {
                            border-right: 1px solid var(--color-pure-black);
                            background: #F35597 !important;
                            color: var(--color-white);
                        }
                    }
                    &.CalendarDay__selected_span,
                    &.CalendarDay__hovered_span {
                        background: #F35597 !important;
                        color: var(--color-white);
                        border-top: 1px solid var(--color-pure-black);
                        border-bottom: 1px solid var(--color-pure-black);
                        &:before {
                            display: none;
                        }
                    }
                    .calender-date {
                        &--blocked {
                            .date {
                                color: #ACABB4;
                            }
                        }
                    }
                }
                &:first-child {
                    td:not(.CalendarDay) {
                        border-top: none;
                        border-left: none;
                    }
                }
                &:last-child {
                    td {
                        &:not(.CalendarDay__blocked_out_of_range) {
                            border-bottom: 1px solid #ACABB4;
                        }
                        &:not(.CalendarDay) {
                            border-bottom: none;
                            border-right: none;
                        }
                        &:not(.CalendarDay)+td {
                            border-left: none;
                        }
                        &.CalendarDay__selected,
                        &.CalendarDay__selected:active,
                        &.CalendarDay__selected:hover {
                            background: var(--color-white);
                            border-top: 1px solid var(--color-pure-black) !important;
                            border-bottom: 1px solid var(--color-pure-black) !important;
                            color: var(--color-pure-black);
                            &:before {
                                display: none;
                            }
                            &.CalendarDay__selected_start {
                                border-left: 1px solid var(--color-pure-black);
                                color: white;
                            }
                            &.CalendarDay__selected_end {
                                border-right: 1px solid var(--color-pure-black);
                            }
                        }
                        &.CalendarDay__selected_span,
                        &.CalendarDay__hovered_span {
                            background: #F35597;
                            border-top: 1px solid var(--color-pure-black);
                            border-bottom: 1px solid var(--color-pure-black);
                            color: var(--color-pure-black);
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .CalendarDay {
        position: relative;
        padding: 0px;
        border-radius: 0px !important;
        .date {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            position: absolute;
            top: 10px;
            left: 7px;
        }
        .price {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            position: absolute;
            right: 6px;
            bottom: 6px;
        }
    }
    .CalendarDay__highlighted_calendar {
        background: #cacccd !important;
        box-sizing: border-box;
        border: 1px solid transparent !important;
        .date {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-decoration-line: line-through;
            color: #797882;
        }
    }
    @include mobile {
        padding-top: 35px;
        max-width: 100%;
        width: 100%;
        height: calc(100vh - 370px);
        max-height: calc(100vh - 370px);
        .DayPicker__withBorder {
            box-shadow: none !important;
        }
        .CalendarMonth,
        .DayPicker,
        .CalendarMonthGrid,
        .DayPicker_weekHeader__verticalScrollable {
            background: transparent;
        }
        .CalendarMonth_caption__verticalScrollable {
            text-align: left;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
        }
        .CalendarMonth_caption__verticalScrollable {
            padding-top: 45px;
            padding-bottom: 7px;
        }
        .CalendarDay__default {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            height: calc(var(--item-height-calendar) * 1.5) !important;
            // &:not(.CalendarDay__blocked_calendar) {
            //     &:hover {
            //         background: var(--color-shade-grey);
            //         color: var(--color-black);
            //     }
            // }
            // &:not(.CalendarDay__selected_span) {
            //     &:hover {
            //         border-radius: var(--radius-calendar);
            //     }
            // }
        }
        .CalendarDay__today {
            color: var(--color-bright-purple);
        }
        .CalendarDay__blocked_calendar {
            background: transparent !important;
        }
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end),
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end):active,
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end):hover {
            //background: transparent !important;
            &:before {
                background: transparent;
            }
        }
        .CalendarDay__selected_start {
            &::before {
                position: absolute;
                content: " ";
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                z-index: -1;
            }
            // &+.CalendarDay__blocked_minimum_nights {
            //     background: var(--color-shade-grey);
            // }
            // &.CalendarDay__selected_start_in_hovered_span,
            // &:hover {
            //     background: var(--color-shade-grey);
            //     border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
            //     color: var(--color-white) !important;
            // }
        }
        .CalendarDay__hovered_span {
            // background: var(--color-shade-grey) !important;
            // border-radius: 0;
            // &:hover {
            //     border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
            // }
            &.CalendarDay__lastDayOfWeek {
                // border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
                &:before {
                    display: none;
                }
            }
            &.CalendarDay__firstDayOfWeek {
                // border-top-left-radius: var(--radius-calendar) !important;
                // border-bottom-left-radius: var(--radius-calendar) !important;
                &:before {
                    display: none;
                }
            }
        }
        // .CalendarMonth_table>tbody>tr:first-child td:not([class])+td.CalendarDay__default {
        //     &.CalendarDay__selected_span,
        //     &.CalendarDay__hovered_span {
        //         border-top-left-radius: var(--radius-calendar) !important;
        //         border-bottom-left-radius: var(--radius-calendar) !important;
        //     }
        // }
        .CalendarDay__after_hovered_start {
            // border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0;
            &.CalendarDay__blocked_minimum_nights {
                border-radius: 0px;
            }
        }
        .CalendarDay__selected_span {
            border-radius: 0px;
            &.CalendarDay__lastDayOfWeek {
                // border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
                &:before {
                    display: none;
                }
            }
            &.CalendarDay__firstDayOfWeek {
                // border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
                &:before {
                    display: none;
                }
            }
            // &.CalendarDay__firstDayOfWeek.CalendarDay__lastDayOfWeek {
            //     border-radius: var(--radius-calendar) !important;
            // }
            &.CalendarDay__blocked_calendar,
            &.CalendarDay__blocked_calendar:active,
            &.CalendarDay__blocked_calendar:hover {
                // background: var(--color-shade-grey);
                &:before {
                    background: transparent;
                }
            }
        }
        .CalendarDay__selected_end {
            // background: var(--color-shade-grey);
            // border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
            // color: var(--color-white) !important;
            &.CalendarDay__firstDayOfWeek {
                background: transparent !important;
            }
            &::before {
                // border-radius: var(--radius-calendar);
                // background: var(--color-vibrant-pink) !important;
                position: absolute;
                content: " ";
                display: block !important;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                z-index: -1;
            }
        }
        // .CalendarDay__selected_start+.CalendarDay__selected_span,
        // .CalendarDay__selected_span {}
        .DayPicker_transitionContainer__verticalScrollable {
            height: 100% !important;
            overflow-x: hidden;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            width: var(--container-width-calendar) !important;
        }
        .DayPickerNavigation__verticalDefault::before {
            display: none;
        }
        .DayPickerNavigation__verticalDefault {
            border-radius: 0px;
            box-shadow: 0 0 10px #e9e9eb;
            left: -3px;
            bottom: 0px;
            width: calc(100% + 6px);
            border: none;
        }
        .DayPickerNavigation__verticalScrollable_prevNav {
            display: none;
        }
        .DayPicker_weekHeader__verticalScrollable {
            border-bottom: none;
            // top: -5px;
            width: var(--container-width-calendar);
            margin: 0 auto;
            position: unset;
            display: block;
            // width: calc(100% - 58px);
            // padding: 0px !important;
            // left: 20px;
            ul {
                li {
                    small {
                        color: var(--color-dark-grey);
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}

.n-input-textarea {
    font-family: $primary-font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    border: 1px solid var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 8px;
    resize: vertical;
    background: transparent;
    color: var(--color-black);
    &:not(:placeholder-shown) {
        background: transparent;
        color: var(--color-black);
    }
    &:placeholder-shown {
        background: var(--color-light-grey);
        border: 1px solid var(--color-light-grey);
    }
}

.n-input-text {
    $self: &;
    position: relative;
    >label {
        display: block;
    }
    >input {
        background: #E9E9EB;
        border: 1px solid #E9E9EB;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none !important;
        padding: 9px 15px;
        width: 100%;
        font-family: $primary-font;
        &:focus,
        &:not(:placeholder-shown) {
            background: var(--color-white);
        }
        &:placeholder-shown {
            text-overflow: ellipsis;
        }
    }
    > input:-internal-autofill-selected {
        background-color: var(--color-white) !important;
    }
    &--label-on-fill {
        >#{$self}__label {
            padding: .125rem .25rem;
            pointer-events: none;
            position: absolute;
            left: 1rem;
            top: .638rem;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            opacity: 0;
        }
        >input:focus+label,
        >input:not(:placeholder-shown)+label {
            background-color: #fff;
            opacity: 1;
            -webkit-transform: translateY(-100%) translateX(-.3rem);
            transform: translateY(-100%) translateX(-.3rem);
            font-size: .75rem;
            line-height: .75rem;
            color: #797882;
        }
    }
    &--prefix {
        >input {
            padding-left: 24px;
        }
        >.n-input-text_prefix {
            position: absolute;
            left: 15px;
            top: 7px;
            color: var(--color-dark-grey);
        }
    }
    &--fill {
        >.n-input-text_prefix {
            color: var(--color-color);
        }
    }
}


/**
* Checkbox
*/

.n-checkbox {
    >label {
        display: flex;
        align-items: center;
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        input {
            opacity: 0;
            width: 11px;
            height: 11px;
        }
        >.text {
            display: flex;
            flex-direction: column;
            width: 100%;
            >span:first-child {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black)
            }
        }
        >.icon-checkbox {
            width: 18px;
            height: 18px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
    .n-checkbox-switch {
        display: flex;
        margin-right: 10px;
        input[type=checkbox] {
            height: 0;
            width: 0;
            visibility: hidden;
        }
        label {
            cursor: pointer;
            text-indent: -9999px;
            width: 48px;
            height: 28px;
            background: var(--color-subtle-grey);
            display: block;
            border-radius: 14px;
            position: relative;
        }
        label:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 9px;
            transition: 0.3s;
        }
        input:checked+label {
            background: var(--color-black);
        }
        input:checked+label:after {
            left: calc(100% - 5px);
            transform: translateX(-100%);
        }
        label:active:after {
            width: 48px;
        }
    }
    &--disabled {
        pointer-events: none;
        opacity: 0.32;
    }
}

.n-checkbox-list {
    >strong {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
    }
    >ul {
        list-style: none;
        li {
            .n-checkbox {
                >label {
                    padding: 18px 10px;
                    >.text {
                        >span:first-child {
                            width: 100%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        small {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 18px;
                            color: var(--color-dark-grey);
                        }
                    }
                }
            }
            &:hover {
                border-radius: 10px;
                background: var(--color-shade-grey);
            }
        }
    }
    .n-btn--adding {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        flex-direction: row-reverse;
        padding: 8px 9px;
        .ic-icon {
            margin-left: 0px;
            margin-right: 10px;
            path {
                stroke: var(--color-bright-purple);
            }
        }
    }
}


/**
* Radiobox
*/

.n-radiobox {
    >label {
        display: flex;
        align-items: center;
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        input {
            opacity: 0;
            width: 11px;
            height: 11px;
        }
        >.text {
            display: flex;
            flex-direction: column;
            width: 100%;
            >span:first-child {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-black)
            }
        }
        >.icon-radiobox {
            width: 20px;
            height: 20px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.n-radiobox-list {
    >strong {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-black);
    }
    >ul {
        list-style: none;
        li {
            .n-radiobox {
                >label {
                    padding: 10px;
                    // padding: 18px 10px;
                    >.text {
                        >span:first-child {
                            width: 100%;
                            // white-space: nowrap;
                            // text-overflow: ellipsis;
                            // overflow: hidden;
                        }
                        small {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 18px;
                            color: var(--color-dark-grey);
                        }
                    }
                }

            }
            &.disabled {
                .text {
                    span {
                        color: var(--color-dark-grey);
                    }
                }

                &:hover {
                    background: none;
                }    
            }

            &:hover {
                border-radius: 10px;
                background: var(--color-shade-grey);
            }
        }
    }
    .n-btn--adding {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        flex-direction: row-reverse;
        padding: 8px 9px;
        .ic-icon {
            margin-left: 0px;
            margin-right: 10px;
            path {
                stroke: var(--color-bright-purple);
            }
        }
    }
}

.n-input-counter {
    display: flex;
    flex-direction: column;
    position: relative;
    .n-input-counter__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            background: transparent;
            border: none;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            outline: none !important;
            cursor: pointer;
            &:hover:not(:disabled) {
                //background: #EEEEF0;
            }
            &:disabled {
                opacity: 0.5;
                cursor: initial;
            }
            &.btn-decrease {
                svg {
                    position: relative;
                    //top: -4px;
                }
            }
        }
        >span {
            min-width: 25px;
            text-align: center;
        }
        .n-input-counter_label {
            background-color: var(--color-white);
            transform: translateY(-145%) translateX(.7rem);
            padding-left: 4px;
            padding-right: 4px;
            font-size: .75rem;
            line-height: .75rem;
            color: var(--color-dark-grey);
            white-space: nowrap;
            position: absolute;
        }
    }
    &.n-input-counter--disabled {
        .n-input-counter_value {
            color: #757575;
        }
    }
    &.n-input-counter--labeled {
        .n-input-counter__wrapper {
            border: 1px solid var(--color-light-grey);
            border-radius: 6px;
            .btn-decrease {
                margin-left: 7px;
            }
            .btn-increase {
                margin-right: 7px;
            }
            .btn-decrease,
            .btn-increase {
                &:hover {
                    background: initial;
                }
            }
        }
    }
}

.n-input-counter {
    .n-input-counter__wrapper {
        .btn-decrease,
        .btn-increase {
            display: flex;
            justify-content: center;
            align-items: center;
            .counter-minus-plus-icon {
                transform: scale(0.625);
            }
        }
    }
}


.counter-carret-icon {
    width: 12px;
    height: 12px;

    path {
        stroke-width: 2.4;
    }

    &.counter-carret-icon--dec {
        transform: rotate(90deg);
    }

    &.counter-carret-icon--asc {
        transform: rotate(-90deg);
    }
}

.n-box-picker {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: wrap;
    .n-box-item {
        padding: 20px;
        border-radius: 15px;
        background-color: var(--color-white);
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        border: 1px solid transparent;
        color: var(--color-dark-grey);
        white-space: nowrap;
        margin-left: 20px;
        margin-bottom: 25px;
        strong {
            display: block;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
            color: var(--color-black);
        }
        &:hover,
        &.selected {
            border-color: var(--color-bright-purple);
        }
    }
    &--three-col {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        .n-box-item {
            margin: 0px;
        }
    }
    &--two-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        .n-box-item {
            margin: 0 0 20px 0;
        }
    }
    &--wrap-content {
        .n-box-item {
            white-space: initial;
        }
    }
}

.n-select-picker {
    $self: &;
    border: 1px solid var(--color-light-grey);
    background: var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    width: 100%;
    position: relative;
    .input-value {
        display: flex;
        justify-content: space-between;
        padding: 6px 16px;
        // padding: 6px 10px;
        border-radius: 6px;
        cursor: pointer;
        >span {
            display: flex;
            align-items: center;
            path {
                stroke: var(--color-dark-grey);
            }
        }
        .text-group {
            max-width: calc(100% - 16px);
        }
        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: var(--color-dark-grey);
        }
        >.ic-icon {
            @include align-center;
            position: relative;
            transform: rotate(0deg);
            transition: transform 0.4s ease-in-out;
        }
        .left-icon {
            path {
                stroke: var(--color-dark-grey);
            }
        }
    }
    .input-dropdown {
        position: absolute;
        background-color: var(--color-white);
        top: calc(100% + 1px);
        right: 0px;
        width: 100%;
        border-radius: 15px;
        padding: 5px 0;
        z-index: -1;
        opacity: 0;
        overflow-y: auto;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        .n-radiobox-list>ul li {
            .n-radiobox>label {
                padding: 10px 15px;
            }
            &:hover {
                border-radius: 0;
            }
        }
        ul {
            list-style: none;
            padding: 0px;
            li {
                color: var(--color-black);
                cursor: pointer;
                position: relative;
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                &:hover {
                    background: var(--color-shade-grey);
                }
            }
        }
        .n-btn {
            font-weight: 600;
            flex-direction: row-reverse;
            padding-left: 26px;
            .ic-icon {
                margin-left: 0px;
                margin-right: 5px;
                width: 24px;
                height: 24px;
                position: relative;
                top: -3px;
            }
            path {
                stroke: var(--color-bright-purple);
            }
        }
        .input-dropdown-form {
            padding: 0 30px;
            .form__field {
                &:last-child {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0px;
                    .n-btn {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
            }
        }
    }
    &.label-on-fill {
        #{$self}__label {
            padding: .125rem .25rem;
            pointer-events: none;
            position: absolute;
            left: 1rem;
            top: .638rem;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            opacity: 0;
        }
    }
    &.active {
        .input-value {
            >.ic-icon {
                transform: rotate(180deg);
            }
        }
        .input-dropdown {
            opacity: 1;
            z-index: 9;
        }
    }
    &.filled {
        // border-color: #ACABB4;
        background: transparent;
        .input-value {
            .text {
                color: var(--color-black);
            }
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
            }
        }
        &.label-on-fill {
            #{$self}__label {
                background-color: #fff;
                opacity: 1;
                -webkit-transform: translateY(-100%) translateX(-.3rem);
                transform: translateY(-100%) translateX(-.3rem);
                font-size: .75rem;
                line-height: .75rem;
                color: #797882;
            }
        }
    }
    &:hover {
        // border-color: var(--color-dark-grey);
    }
}

.n-input-guest-room-picker {
    border: 1px solid var(--color-light-grey);
    //background: var(--color-light-grey);
    background: transparent;
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    width: 100%;
    position: relative;
    .input-value {
        display: flex;
        justify-content: space-between;
        padding: 7px 10px;
        border-radius: 6px;
        cursor: pointer;
        >span {
            display: flex;
            align-items: center;
            path {
                stroke: var(--color-dark-grey);
            }
        }
        .text-group {
            max-width: calc(100% - 16px);
        }
        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: var(--color-dark-grey);
        }
        >.ic-icon {
            @include align-center;
            position: relative;
            transform: rotate(0deg);
            transition: transform 0.4s ease-in-out;
        }
        .left-icon {
            margin-right: 5px;
            path {
                stroke: var(--color-subtle-grey);
            }
        }
    }
    .input-dropdown_big_size {
        min-width: 462px !important;
    }
    .input-dropdown {
        position: absolute;
        background-color: var(--color-white);
        top: calc(100% + 6px);
        right: -4px;
        width: 100%;
        min-width: 326px;
        border-radius: 15px;
        padding: 30px;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        ul {
            list-style: none;
            .zero_children{
                display: none;
            }
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 20px 0;
                border-bottom: 0.5px solid #E9E9EB;

                &:first-child{
                    padding: 0 0 20px 0;
                }
                &:last-child{
                    padding: 20px 0 0 0;
                    border-bottom: none;
                }

                >span {
                    //@include text-normal;

                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: 0.01em;
                    color: #242526;

                    small {
                        display: block;
                        font-weight: 400;
                        color: var(--color-dark-grey);
                    }
                }
                &.divider {
                    display: none;
                    border-bottom: 1px solid #E9E9EB;
                }
                &.li-input {
                    display: grid;
                    grid-template-columns: calc(100% - 35px) 35px;
                    grid-column-gap: 5px;
                    .n-input-text {
                        width: 100%;
                    }
                    .ic-icon {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        @include align-center;
                        cursor: pointer;
                        &:hover {
                            background: #EEEEF0;
                        }
                    }
                    >.error {
                        display: block;
                        width: 100%;
                        padding-left: 5px;
                        grid-area: 2/1/2/ span 2;
                    }
                }
                &:last-child {
                    margin: -15px 0 -20px 0;
                    button {
                        padding: 15px 0px;
                        border-radius: 20px;
                        color: white;
                        background-color: #F35597;
                        font-size: 1rem;
                        font-weight: 500;
                        border: none;
                        width: 40%;
                        height: 45px;
                        cursor: pointer;
                        outline: none;
                        margin-left: auto;
                        margin-right: auto;
                        justify-content: center;
                        align-items: center;
                    }
                }
                &.limit {
                    margin-bottom: 8px;
                    small {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: 0.02em;
                        color: var(--color-dark-grey);
                    }
                }
            }
        }
        .n-input-counter {
            .n-input-counter__wrapper {
                >span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                    padding: 10px 16px;
                    background: #EEEEF0;
                    border-radius: 50%;
                    &.n-input-counter--disabled{
                        color:#ACABB4;
                    }
                }
            }
        }
        .n-btn--save {
            font-weight: 600;
            &:disabled {
                opacity: 0.3;
                cursor: initial;
            }
        }
    }
    &--label-on-fill {
        >.n-input-guest-room-picker__label {
            padding: .125rem .25rem;
            pointer-events: none;
            position: absolute;
            left: 1rem;
            top: .638rem;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            opacity: 0;
        }
        >input:focus+label,
        >input:not(:placeholder-shown)+label {
            background-color: #fff;
            opacity: 1;
            -webkit-transform: translateY(-100%) translateX(-.3rem);
            transform: translateY(-100%) translateX(-.3rem);
            font-size: .75rem;
            line-height: .75rem;
            color: #797882;
        }
    }
    &.open-up {
        .input-dropdown {
            top: auto;
            bottom: calc(100% + 6px);
        }
    }
    &.active {
        .input-value {
            >.ic-icon {
                transform: rotate(180deg);
            }
        }
        .input-dropdown {
            opacity: 1;
            z-index: 9;
            pointer-events: initial;
        }
    }
    &.filled {
        border-color: #ACABB4;
        background: transparent;
        .input-value {
            .text {
                color: var(--color-black);
            }
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
            }
        }
        > .n-input-guest-room-picker__label {
            background-color: #fff;
            opacity: 1;
            -webkit-transform: translateY(-110%) translateX(-.3rem);
            transform: translateY(-110%) translateX(-.3rem);
            font-size: .75rem;
            line-height: .75rem;
            color: #797882;
        }
    }
}

.n-modal--input-guest-room-picker {
    ul {
        list-style: none;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            >span {
                @include text-normal;
                color: #242526;
                small {
                    display: block;
                    color: var(--color-dark-grey);
                }
            }
            &.divider {
                border-bottom: 1px solid #E9E9EB;
            }
            &.li-input {
                display: grid;
                grid-template-columns: calc(100% - 35px) 35px;
                grid-column-gap: 5px;
                .n-input-text {
                    width: 100%;
                }
                .ic-icon {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    @include align-center;
                    cursor: pointer;
                    &:hover {
                        background: #EEEEF0;
                    }
                }
                >.error {
                    display: block;
                    width: 100%;
                    padding-left: 5px;
                    grid-area: 2/1/2/ span 2;
                    color: var(--color-red);
                }
            }
            &.limit {
                margin-bottom: 8px;
                small {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    color: var(--color-dark-grey);
                }
            }
            &.actions {
                margin: -15px 0 -20px 0;
                button {
                    padding: 15px 0px;
                }
            }
        }
        .n-input-counter {
            min-width: 120px;
            button {
                background: var(--color-light-grey);
            }
            .n-input-counter__wrapper {
                >span {
                    color: var(--color-black);
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
    .n-modal__footer {
        display: flex;
        justify-content: space-between;
        padding: 5px 28px 20px 28px;
        .n-btn {
            padding: 4px 22px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .n-btn--save {
            font-weight: 600;
            border-radius: 8px;
            &:disabled {
                opacity: 0.3;
                cursor: initial;
            }
            height: rem(46);
			line-height: rem(46);
			@media (min-width: 601px) {
				height: rem(40);
				line-height: rem(40);
			}
			border-radius: 20px;
			color: white;
			background-color: #F35597;
			font-size: 1rem;
			font-weight: 500;
			border: none;
			width: 60%;
			cursor: pointer;
			outline: none;
        }
    }
    &.n-modal--fullscreen {
        .n-modal__footer {
            padding: 20px;
        }
        .center-save {
            display: flex;
            justify-content: center;
       
        }
    }
}

:root {
    --item-height-calendar: auto;
    --radius-calendar: 4px;
}

@media only screen and (max-width: 330px) {
     :root {
        --container-width-calendar: 300px;
        --item-height-calendar: 43px;
        --radius-calendar: 4px;
    }
}

@media only screen and (min-width: 330px) and (max-width: 380px) {
     :root {
        --container-width-calendar: 355px;
        --item-height-calendar: 48px;
        --radius-calendar: 4px;
    }
}

@media only screen and (min-width: 380px) and (max-width: 420px) {
     :root {
        --container-width-calendar: 390px;
        --item-height-calendar: 53px;
        --radius-calendar: 4px;
    }
}

@media only screen and (min-width: 420px) {
     :root {
        --container-width-calendar: 420px;
        --item-height-calendar: 56px;
        --radius-calendar: 4px;
    }
}

@media only screen and (min-width: 560px) {
     :root {
        --container-width-calendar: 560px;
        --item-height-calendar: 77px;
        --radius-calendar: 4px;
    }
}


/* from 600px to higher would be tablet and desktop view */

@media only screen and (min-width: 600px) {
     :root {
        --container-width-calendar: auto;
        --item-height-calendar: 41px;
        --radius-calendar: 4px;
    }
}

.n-input-date-range-picker {
    $self: &;
    border: 1px solid var(--color-light-grey);
    background: var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    position: relative;
    .input-value {
        display: flex;
        justify-content: flex-start;
        padding: 6px 10px;
        border-radius: 6px;
        cursor: pointer;
        .text {
            padding-top: 2px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 24px);
            color: var(--color-dark-grey);
        }
        .left-icon {
            margin-right: 5px;
            path {
                stroke: var(--color-subtle-grey);
            }
            rect {
                fill: var(--color-subtle-grey);
            }
        }
    }
    &.label-on-fill {
        #{$self}__label {
            padding: .125rem .25rem;
            pointer-events: none;
            position: absolute;
            left: 1rem;
            top: .638rem;
            -webkit-transition: .2s;
            transition: .2s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            opacity: 0;
        }
    }
    .input-dropdown {
        background: var(--color-white);
        position: absolute;
        top: 100%;
        margin-top: 0;
        // top: calc(100% + 6px);
        right: -4px;
        min-width: 346px;
        border-radius: 15px;
        z-index: -1;
        opacity: 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        pointer-events: none;
    }
    .input-dropdown-calendar {
        height: calc(100vh - 230px);
        margin-top: 46px;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        .DayPicker__withBorder {
            box-shadow: none !important;
        }
        .CalendarDay__default {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            //height: var(--item-height-calendar) !important;
            // &:not(.CalendarDay__blocked_calendar) {
            //     &:hover {
            //         background: transparent;
            //         color: var(--color-black);
            //         @media screen and (max-width: 600px) {
            //             color: var(--color-white);
            //         }
            //     }
            // }
            // &:not(.CalendarDay__selected_span) {
            //     &:hover {
            //         border-radius: var(--radius-calendar);
            //     }
            // }
        }
        .CalendarDay__today {
            // color: var(--color-vibrant-pink);
            @media screen and (max-width: 600px) {
                display: grid;
                left: 50%;
                position: relative;
                transform: translateX(-50%);
                place-items: center;
            }
        }
        .CalendarDay__blocked_calendar {
            //background: transparent !important;
        }
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end),
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end):active,
        .CalendarDay__blocked_calendar:not(.CalendarDay__selected_span, .CalendarDay__selected_end):hover {
            //background: #fff !important;
            &:before {
                background: transparent;
            }
        }
        .CalendarDay__selected_start,
        .CalendarDay__selected_start:hover {
            /* border-radius: var(--radius-calendar) !important;
            background: var(--color-vibrant-pink) !important;
            color: var(--color-white) !important; */
            // &::before {
            //     border-radius: var(--radius-calendar);
            //     background: var(--color-vibrant-pink) !important;
            //     position: absolute;
            //     content: " ";
            //     width: 100%;
            //     height: 100%;
            //     top: 0px;
            //     left: 0px;
            //     z-index: -1;
            // }
            // &+.CalendarDay__blocked_minimum_nights {
            //     background: var(--color-shade-grey);
            // }
            // &.CalendarDay__selected_start_in_hovered_span,
            // &:hover {
            //     background: var(--color-shade-grey);
            //     border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
            //     color: var(--color-white) !important;
            // }
        }
        // .CalendarDay__hovered_span {
        //     background: var(--color-shade-grey) !important;
        //     border-radius: 0;
        //     &:hover {
        //         border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
        //     }
        //     &.CalendarDay__lastDayOfWeek {
        //         border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
        //         &:before {
        //             display: none;
        //         }
        //     }
        //     &.CalendarDay__firstDayOfWeek {
        //         border-top-left-radius: var(--radius-calendar) !important;
        //         border-bottom-left-radius: var(--radius-calendar) !important;
        //         &:before {
        //             display: none;
        //         }
        //     }
        // }
        .CalendarMonth_table>tbody>tr:first-child td:not([class])+td.CalendarDay__default {
            &.CalendarDay__selected_span,
            &.CalendarDay__hovered_span {
                // border-top-left-radius: var(--radius-calendar) !important;
                // border-bottom-left-radius: var(--radius-calendar) !important;
            }
        }
        .CalendarDay__after_hovered_start {
            border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0;
            &.CalendarDay__blocked_minimum_nights {
                border-radius: 0px;
            }
        }
        .CalendarDay__selected_span {
            border-radius: 0px;
            &.CalendarDay__lastDayOfWeek {
                border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
                &:before {
                    display: none;
                }
            }
            &.CalendarDay__firstDayOfWeek {
                border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
                &:before {
                    display: none;
                }
            }
            &.CalendarDay__firstDayOfWeek.CalendarDay__lastDayOfWeek {
                border-radius: var(--radius-calendar) !important;
            }
            &.CalendarDay__blocked_calendar,
            &.CalendarDay__blocked_calendar:active,
            &.CalendarDay__blocked_calendar:hover {
                background: var(--color-shade-grey);
                &:before {
                    background: transparent;
                }
            }
        }

        .CalendarDay__selected_end,
        .CalendarDay__selected_end:hover {
            /* border-radius: var(--radius-calendar) !important;
            background: var(--color-vibrant-pink) !important;
            color: var(--color-white) !important; */
            // background: var(--color-shade-grey);
            // border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
            // color: var(--color-white) !important;
            // &.CalendarDay__firstDayOfWeek {
            //     background: transparent !important;
            // }
            // &::before {
            //     border-radius: var(--radius-calendar);
            //     background: var(--color-vibrant-pink) !important;
            //     position: absolute;
            //     content: " ";
            //     display: block !important;
            //     width: 100%;
            //     height: 100%;
            //     top: 0px;
            //     left: 0px;
            //     z-index: -1;
            // }
        }
        .CalendarDay__selected_start+.CalendarDay__selected_span,
        .CalendarDay__selected_span {}
        .DayPicker_transitionContainer__verticalScrollable {
            height: 100% !important;
            overflow-x: hidden;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            // width: var(--container-width-calendar) !important;
        }
        .DayPickerNavigation__verticalDefault::before {
            display: none;
        }
        .DayPickerNavigation__verticalDefault {
            border-radius: 0px;
            box-shadow: 0 0 10px #e9e9eb;
            left: -3px;
            bottom: 0px;
            width: calc(100% + 6px);
            border: none;
        }
        .DayPickerNavigation__verticalScrollable_prevNav {
            display: none;
        }
        .DayPicker_weekHeader__verticalScrollable {
            border-bottom: none;
            top: -5px;
            ul {
                li {
                    small {
                        color: var(--color-dark-grey);
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: small-caps;
                        font-feature-settings: "c2sc";
                        text-transform: uppercase;
                        // font-size: 16px;
                        // line-height: 19px;
                    }
                }
            }
        }
    }
    .input-dropdown-calendar_ios {
        .DayPickerNavigation__verticalDefault  {
            margin-bottom: 70px;
        }
    }
    .input-dropdown-footer {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        border-top: 1px solid var(--color-field-grey);
        .n-btn {
            border-radius: 8px;
            padding: 4px 15px;
        }
    }
    .input-dropdown-form {
        display: grid;
        grid-template-columns: 1fr 30px 1fr;
        grid-column-gap: 15px;
        padding: 0 20px;
        max-width: 414px;
        margin-left: auto;
        margin-right: auto;
        >span {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-dark-grey);
        }
    }
    &.n-modal {
        border: none;
        background: none;
        &.n-modal--fullscreen {
            .n-modal__header {
                padding: 15px;
                height: 54px;
            }
            .n-modal__dialog {
                background: var(--color-white);
            }
            .n-modal__content {
                padding: 0px !important;
                overflow-y: hidden !important;
                position: relative;
                // min-height: 100vh;
                // min-height: -webkit-fill-available;
                // max-height: calc(calc(var(--vh, 1vh) * 100) - 130px);
                // height: calc(calc(var(--vh, 1vh) * 100) - 130px);
                > .input-dropdown-form {
                    height: 20px;
                }
                > .input-dropdown-calendar {
                    height: 100%;
                    margin-top: 0px !important;
                    > .DayPicker {
                        overflow: hidden;
                        position: relative;
                        .DayPicker_weekHeader__verticalScrollable {
                            top: 20px !important;
                            background: var(--color-white);
                            // padding-top: 10px !important;
                        }
                        // >div {
                        //     overflow: hidden;
                        //     >div {
                        //         position: absolute;
                        //         height: 100%;
                        //         width: 100%;
                        //         > .DayPicker_weekHeaders {
                        //             height: 30px;
                        //             display: flex;
                        //             padding-top: 10px;
                        //             background: var(--color-white);
                        //             z-index: 99;
                        //             > .DayPicker_weekHeader__verticalScrollable {
                        //                 top: 0px;
                        //                 position: relative;
                        //                 width: 100%;
                        //                 min-width: 100vw;
                        //                 height: 30px;
                        //                 margin-top: 0px;
                        //             }
                        //         }
                        //         > .DayPicker_focusRegion {
                        //             height: calc(100% - 30px);
                        //         }
                        //     }
                        // }
                    }
                }
            }
            .input-dropdown-footer {
                .btn--cancel {
                    color: var(--color-dark-grey) !important;
                }
                .btn--save {
                    font-weight: 600;
                }
            }
            .n-modal__footer {
                z-index: 92;
            }
        }
    }
    &.horizontal {
        .input-dropdown {
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .input-dropdown-calendar {
            overflow: hidden;
            // padding-bottom: 38px;
            margin-top: 0px;
            height: auto;
            max-width: 100%;
            .DayPicker_transitionContainer {
                overflow: visible !important;
            }
        }
    }
    &.disabled-prev-nav {
        .DayPickerNavigation_leftButton__horizontalDefault {
            opacity: 0.3;
            pointer-events: none;
        }
    }
    &.active {
        .input-dropdown {
            opacity: 1;
            z-index: 9;
            pointer-events: initial;
        }
    }
    &.open-up {
        .input-dropdown {
            top: auto;
            bottom: calc(100% + 6px);
            .DateRangePicker_picker__directionRight {
                right: 0px !important;
            }
        }
    }
    &.open-left {
        .input-dropdown {
            right: auto;
            left: 0px;
            .DateRangePicker_picker__directionLeft {
                left: 0;
                right: 0px !important;
                top: 0px !important;
            }
        }
    }
    &.filled {
        border-color: #ACABB4;
        background: transparent;
        .input-value {
            .text {
                color: var(--color-black);
            }
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
                rect {
                    fill: var(--color-black-grey);
                }
            }
        }
        &.label-on-fill {
            #{$self}__label {
                background-color: #fff;
                opacity: 1;
                -webkit-transform: translateY(-100%) translateX(-.3rem);
                transform: translateY(-100%) translateX(-.3rem);
                font-size: .75rem;
                line-height: .75rem;
                color: #797882;
            }
        }
    }
    &.double-date {
        .input-value {
            padding: 5px 10px;
            .text {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 50px 1fr;
                justify-content: space-between;
                align-items: center;
                >span {
                    height: 24px;
                    line-height: 24px;
                }
            }
        }
    }
    &.error {
        border: 2px solid var(--color-red) !important;
        .input-value {
            padding: 5px 10px;
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
                rect {
                    fill: var(--color-black-grey);
                }
            }
        }
    }
}


/*
.n-input-date-range-picker {
    border: 1px solid var(--color-light-grey);
    background: var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    .input-value {
        display: flex;
        justify-content: flex-start;
        padding: 6px 10px;
        border-radius: 6px;
        cursor: pointer;
        .text {
            padding-top: 2px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 24px);
            color: var(--color-dark-grey);
        }
        .left-icon {
            path {
                stroke: var(--color-dark-grey);
            }
        }
    }
    .input-dropdown {
        background: var(--color-white);
        width: 628px;
        position: absolute;
        top: calc(100% + 6px);
        right: -4px;
        min-width: 346px;
        border-radius: 15px;
        padding: 5px;
        height: 380px;
        z-index: -1;
        opacity: 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        pointer-events: none;
        .DateRangePicker {
            .DayPicker__withBorder {
                box-shadow: none;
            }
            .DateRangePicker_picker__directionRight {
                top: 0px !important;
            }
            .DayPicker_transitionContainer {
                min-height: 360px;
            }
            .DateRangePickerInput {
                width: auto;
                display: flex !important;
                background: transparent;
                max-width: 300px;
                .DateInput {
                    opacity: 0;
                    height: 0px;
                    overflow: hidden;
                }
                .DateRangePickerInput_arrow {
                    opacity: 0;
                    height: 0px;
                    overflow: hidden;
                }
            }
            .DateInput_fangStroke {
                stroke: #ffffff;
            }
            .DayPicker_weekHeader {
                top: 51px;
            }
            .DayPickerNavigation_button__horizontalDefault {
                top: 8px;
            }
            .CalendarMonth_caption {
                padding-bottom: 46px;
                padding-top: 12px;
            }
            .CalendarDay__blocked_calendar,
            .CalendarDay__blocked_calendar:active,
            .CalendarDay__blocked_calendar:hover {
                color: #ACABB4;
                text-decoration: line-through;
                background: transparent;
            }
        }
    }
    &.open-up {
        .input-dropdown {
            top: auto;
            bottom: calc(100% + 6px);
            .DateRangePicker_picker__directionRight {
                right: 0px !important;
            }
        }
    }
    &.open-left {
        .input-dropdown {
            right: auto;
            left: 0px;
            .DateRangePicker_picker__directionLeft {
                left: 0;
                right: 0px !important;
                top: 0px !important;
            }
        }
    }
    &.active {
        .input-dropdown {
            opacity: 1;
            z-index: 9;
            pointer-events: initial;
        }
    }
    &.filled {
        border-color: #ACABB4;
        background: transparent;
        .input-value {
            .text {
                color: var(--color-black);
            }
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
            }
        }
    }
    &.error {
        border: 2px solid var(--color-red) !important;
        .input-value {
            padding: 5px 10px;
        }
    }
}

.n-modal--input-date-range-picker {
    .DateRangePicker_picker {
        top: calc(100% + 30px) !important;
        width: 100%;
    }
    .DateInput_input {
        box-shadow: none;
    }
    &.n-modal--fullscreen {
        .n-modal__header {
            padding: 5px;
            height: 30px;
        }
    }
    .DayPickerNavigation__verticalDefault::before {
        display: none;
    }
    .n-modal__dialog {
        background: var(--color-white);
    }
    .n-modal__content {
        padding: 15px 10px 20px !important;
    }
    .n-modal__footer {
        display: flex;
        justify-content: space-between;
        padding: 20px !important;
        z-index: 4;
        .n-btn--save {
            font-weight: 600;
            &:disabled {
                opacity: 0.3;
                cursor: initial;
            }
        }
    }
    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
        color: #ACABB4;
        text-decoration: line-through;
        background: transparent;
    }
    .DateRangePickerInput_arrow {
        color: var(--color-dark-grey);
    }
    .DateInput {
        margin-bottom: 5px;
    }
    @media (min-width: 360px) and (max-width: 375px) {
        .DateRangePicker__block {
            width: 340px;
            margin: auto;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            min-width: 340px;
        }
        .CalendarDay__default {
            height: 44px !important;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }
        .DayPicker_transitionContainer__vertical {
            overflow-x: hidden;
        }
        .DayPicker_weekHeader {
            top: 5px;
            width: 336px;
            ul {
                li {
                    small {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    @media (min-width: 376px) {
        .DayPicker_transitionContainer {
            height: calc(100vh - 200px) !important;
        }
        .DayPicker_weekHeader__verticalScrollable {
            border: none !important;
        }
        .DayPickerNavigation__verticalScrollable_prevNav {
            display: none;
        }
        .DateRangePicker__block {
            width: 356px;
            margin: auto;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            min-width: 356px;
        }
        .CalendarDay__default {
            height: 48px !important;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }
        .DayPicker_transitionContainer__vertical {
            overflow-x: hidden;
        }
        .DayPicker_weekHeader {
            top: 5px;
            width: 336px;
            // padding: 0px !important;
            // margin-left: -166px !important;
            ul {
                li {
                    small {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
*/

.n-input-tag-selector {
    ul {
        list-style: none;
        display: flex;
        li {
            border-radius: 15px;
            background: var(--color-shade-grey);
            padding: 5px 10px;
            margin: 0 7px 0 0;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: var(--color-black);
            cursor: pointer;
            &.active {
                background: var(--color-dark-grey);
                color: var(--color-white);
            }
        }
    }
}

.input {
    &--date-range-picker {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border: 1px solid #ACABB4;
        box-sizing: border-box;
        border-radius: 6px;
        height: 38px;
        .ic-icon {
            margin-right: 6px;
        }
        .DateRangePicker {
            width: 118px;
            .DateRangePickerInput {
                width: auto;
                display: flex !important;
                .DateInput {
                    .DateInput_input {
                        height: 34px;
                        box-shadow: none;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
                .DateRangePickerInput_arrow {
                    background: transparent;
                    height: 35px;
                    width: 20px;
                    overflow: visible;
                    position: relative;
                    text-indent: unset;
                    font-size: 28px;
                }
            }
        }
    }
}