.pagination {
    @include align-center;
    position: relative;
    ul {
        display: flex;
        padding: 0;
        list-style: none;
        li {
            @include align-center;
            @include text-normal;
            line-height: 22px;
            position: relative;
            height: 30px;
            min-width: 30px;
            background: transparent;
            cursor: pointer;
            .ic-icon,
            .ic-icon svg {
                @include sqr_30;
            }
            &:first-child {
                margin-left: 15px;
            }
            &:last-child {
                margin-left: 15px;
            }
            &:hover {
                background: transparent;
            }
            &.active {
                background: #242526;
                border-radius: 15px;
                color: #FCFCFC;
            }
            &.disabled {
                opacity: 0.3;
                cursor: initial;
            }
        }
    }
}