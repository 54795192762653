.LNPRoomDetail-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 946px;
    margin: 0px auto;

    
    .n-radiobox-list > ul li {
        &:hover {
            background-color: transparent;
        }
        &, label {
            cursor: pointer;
        }
    }
    

    .LNPRoomDetail-vr-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

    .LNPRoomDetail-vr-container-bathrooms  {
        display: flex;

        .LNPRoomDetail-container-item-form-components-flexItems {
            display: flex;
            flex-direction: column;
            @media (min-width:1061px) {
                justify-content: space-between;
                flex-direction: row;   
                .n-input-text {
                    width:374px;
                    max-width: 100%;
                } 
                .mt-28 {
                    margin: 0px;
                    
                }
            }  
        }

        > div {
            min-width: calc(50% - 433px);
            flex: 1;

            &.LNPRoomDetail-container-item {
                min-width: 433px;
                max-width: 433px;
            }
        }

        &.full-container {
            > div {
                min-width: calc(50% - 905px);
                flex: 1;
    
                &.LNPRoomDetail-container-item { 
                    min-width: 433px;
                    max-width: 905px;
                    @media (min-width:1061px) {
                        min-width: 905px;    
                    }
                }
            }
        }
    }


    .LNPRoomDetail-container-item {
        background: #FFFFFF;
        border: 1px solid #E9E9EB;
        border-radius: 15px;
        // min-height:656px;
        width: 433px;
        max-width: 100%;
        padding:20px;
        margin-bottom: 20px;
        margin-right: 15px;
        margin-left: 15px;

        .LNPRoomDetail-container-item-header {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #7868C7;
            margin-bottom: 18px;
        }


        .LNPRoomDetail-container-item-form {
            h4 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: -0.01em;
                color: #242526;
                margin-bottom: 18px;
            }

            .LNPRoomDetail-container-item-form-components {

                // .bathroomsContainer {
                    .bathroom-radiobox, .bedroomType-radiobox {
                         ul {
                            display: flex;
                            flex-wrap: wrap;
                            margin:0px -10px;
                         }     
                    }
                // }

                h5 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #242526;
                    span {
                        color: #797882;
                    }
                }

                .n-select-picker .input-value
                { 
                    padding: 8px 16px;
                }
                .n-btn--adding {
                    font-family: var(--primary-font);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    flex-direction: row-reverse;
                    padding: 8px 2px;
                    .ic-icon {
                        margin-left: 0px;
                        margin-right: 10px;
                        svg {
                            path {
                                stroke: var(--color-bright-purple); 
                            }
                        }
                    }
                }


                .LNPRoomDetail-container-item-form-components-selectbox {
                    button {
                        appearance: none;
                        outline: none;
                        border:none;
                    }

                    button.LNPRoomDetail-container-item-form-components-selectedBedType {


                        .LNPRoomDetail-container-item-form-components-selectedBedType-wrapper {
                            display: flex;
                            justify-content: space-between;
                            width:100%;
                            align-items: center;
                        }
                        padding:0px 16px;
                        border: 1px solid var(--color-light-grey);
                        background: #fff;
                        box-sizing: border-box;
                        border-radius: 6px;
                        height: 38px;
                        line-height: 38px;
                        width: 100%;
                        position: relative;
                        label {
                            padding: 0.125rem 0.25rem;
                            pointer-events: none;
                            position: absolute;
                            left: 1rem;
                            top: 0.638rem;
                            transition: 0.2s;
                            transition-timing-function: ease;
                            background-color: #fff;
                            opacity: 1;
                            transform: translateY(-100%) translateX(-0.3rem);
                            font-size: 0.75rem;
                            line-height: 0.75rem;
                            color: #797882;
                        }
                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            color: #797882;
                        }
                        svg {
                            cursor: pointer;
                        }
                   }
                

                   button.LNPRoomDetail-container-item-form-components-modalHandler {
                        display: flex;
                        justify-content: space-between;
                        padding:0px 16px;
                        cursor: pointer;
                        border: 1px solid var(--color-light-grey);
                        background: var(--color-light-grey);
                        box-sizing: border-box;
                        border-radius: 6px;
                        height: 38px;
                        line-height: 38px;
                        width: 100%;
                        position: relative;
                        
                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            color: #797882;
                        }
                        &:hover {
                            border-color: var(--color-dark-grey);
                        }

                        &.selected {
                            background: #fff;
                            p {
                                    padding: 0.125rem 0.25rem;
                                    pointer-events: none;
                                    position: absolute;
                                    left: 1rem;
                                    top: 0.638rem;
                                    transition: 0.2s;
                                    transition-timing-function: ease;
                                    background-color: #fff;
                                    opacity: 1;
                                    -webkit-transform: translateY(-100%) translateX(-0.3rem);
                                    -moz-transform: translateY(-100%) translateX(-0.3rem);
                                    transform: translateY(-100%) translateX(-0.3rem);
                                    font-size: 0.75rem;
                                    line-height: 0.75rem;
                                    color: #797882;
                            }
                        }
                        


                   }
                }

                
            }
        }
    }


.n-modal--bedsBath {
    .n-checkbox > label input {
        padding:0px;
        height:0px;
    }
    .n-checkbox > label > .icon-checkbox {
        height:20px;
        width:20px;
        margin-top:-2px;
        svg {
            width: 20px;
            height: 20px;
            display: block;
        }
    }
    .n-modal__dialog {
        max-width:330px;
    }
    .n-modal__footer--empty {
        padding:0px;
    }

    .n-modal__content {
        padding: 30px 0px;
    }

    .n-checkbox-list.n-modal--bedsBath-checkbox {
        margin:0px -0px;
        .form-inline-insert {
            > button {
                padding: 0px 7px;
                margin: 20px 0px;
            }

            .form {
                padding:0px 8px;
            }



            
        }
    }

    .n-modal--bedsBath-footer {
        padding:24px 26px 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .n-modal--bedsBath-footer-actions--cancel  {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #242526;
            appearance: none;
            cursor: pointer;
            background-color: transparent;
            border: none;
        }
        .n-modal--bedsBath-footer-actions--add  {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: right;
            color: #E86993;
            appearance: none;
            cursor: pointer;
            border: none;
            background-color: transparent;
            
            &:disabled {
                opacity: 0.9;
                color: #ccc;
                cursor: not-allowed;
            }
        }
    }
}