//------------------- DESKTOP -------------------

.cn__currency__card {
    position: relative;
    display: flex;
    padding: 10px 16px;
    border-radius: 18px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &__title{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #242526;
        text-overflow: ellipsis;
        overflow: hidden;

        span{
            color: #4C9AA5;
            font-weight: 600;
        }
    }

    svg{
        display: none;
        flex-shrink: 0;

        path {
            stroke: #4C9AA5;
        }
    }

    &:hover{
        background: #F7F7F7;
    }

    &.cn__currency__card--active{
        background: #FDE9E0;

        .cn__currency__card__title{
            span{
                color: #4C9AA5;
            }
        }

        svg{
            display: block;

            path {
                stroke: #4C9AA5;
            }    
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){

    .cn__currency__card {
        position: relative;
        display: flex;
        padding: 10px 16px;
        border-radius: 18px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &__titlemob{
            font-size: 14px;
            line-height: 150%;
            // line-height: 1;
            color: #242526;

            &__title{
                font-weight: 400;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }

            &__subtitle{
                font-weight: 600;
                color: #4C9AA5;
                transition: color 0.3s ease;
                margin-top: 5px;
            }
        }
    
        &.cn__currency__card--active{
            
            .cn__currency__card__titlemob{
                width: calc(100% - 10px);
                &__title{
                    width: calc(100% - 10px);
                    // font-weight: 600;
                }
                &__subtitle{
                    color: #4C9AA5;
                }
            }
    
        }
    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__currency__card {
        position: relative;
        display: flex;
        padding: 10px 16px;
        border-radius: 30px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &__titlemob{
            font-size: 14px;
            line-height: 150%;
            // line-height: 1.2;
            color: #242526;

            &__title{
                font-weight: 400;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }

            &__subtitle{
                font-weight: 600;
                color: #4C9AA5;
                transition: color 0.3s ease;
            }
        }
    
        &.cn__currency__card--active{
            
            .cn__currency__card__titlemob{
                width: calc(100% - 10px);
                &__title{
                    width: calc(100% - 10px);
                    // font-weight: 600;
                }
                &__subtitle{
                    color: #4C9AA5;
                }
            }
    
        }
    }
}