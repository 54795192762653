:root {
  --newsrrom-height: 100vh;
}

//DESKTOP//
.cn__newsroom {
  position: absolute;
  width: 100%;

  //Footer
  .cn__shortfooter__wrapper .cn__shortfooter{
    padding: 0 50px 30px;
    @media screen and (max-width: 1136px) {
      padding: 0 30px 30px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 20px 30px;
    }
  }
  //Cookie banner
  .cn__cookie-wrapper{
    .cn__cookie{
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
        outline: 1px solid #DFE2E5;
    }
  }
  &__loading {
    .newsroom__loader{
      position: absolute;
      width: 100%;
      height: var(--newsrrom-height);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__main {
    position: relative;
    display: table;
    width: 100%;
    max-width: 886px;
    margin: 70px auto 115px;

    &__heading {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;

      letter-spacing: -0.02em;
      margin-top: 60px;
    }
    &__block1 {
      &__subfeatures {
        position: relative;
        width: 100%;
        display: inline-grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        margin-top: 40px;
      }
    }
    &__block2 {
      &__general {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        margin-top: 40px;
      }
      &__banner {
        text-align: center;
        height: 70px;
        margin-top: 40px;
        background: $color-white;
        border-radius: 10px;
        opacity: 0;
        transform: translateY(20px);
        border: solid 2px rgba(0,0,0,0.05);
        &__heading {
          font-size: 16px;
          line-height: 20px;
          padding: 25px;
        }
        a {
          color: $color-purple;
          font-weight: 500;
        }
      }
    }
    &__button {
      cursor: pointer;
      margin-top: 94px;
      align-items: center;
      padding: 10px 15px;
      border: none;
      min-height: 40px;
      float: right;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $color-black;
      background: #EEEEF0;
      border: solid 2px rgba(0,0,0,0.05);
      border-radius: 10px;
      outline: 0;
      min-width: 112px;
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 0.3s ease, background-color 0.6s ease, transform 0.6s ease;
      position: relative;
      .loader-circle{
        background: linear-gradient(0deg,#EEEEF0 20%,#2A04E1 100%);
        .loader_circle-core{
          background: #EEEEF0;
        }
      }
      &:disabled {
        opacity: 0;
        visibility: hidden;
      }
      &__loader {
        .loader_circle {
          width: 20px;
          height: 20px;
          padding: 2px;
        }
      }
      &.nrbutton__active{
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
//TABLET//
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .cn__newsroom {
    &__main {
      padding: 0 30px;
      margin: 50px auto 110px;
      &__block1 {
        &__subfeatures {
          margin-top: 25px;
        }
      }

      &__button {
        margin-top: 50px;
      }
    }
  }
}

//MOBILE//
@media screen and (max-width: 600px) {
  .cn__newsroom {
    &__main {
      padding: 0 20px;
      margin: 50px auto 100px;
      &__block1 {
        &__subfeatures {
          grid-template-columns: 1fr;
          grid-row-gap: 25px;
        }
      }

      &__button {
        margin: 100px auto 0;
        display: block;
        float: none;
      }
    }
  }
}
