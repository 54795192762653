.cn__amenity_icon-tooltip {
  background-color: $color-black;
  font-family: $primary-font;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: rem(10);
  line-height: rem(12);
  padding: rem(10);
}

.cn__amenity_icon-arrow {
  &::before {
    background-color: $color-black;
  }
}

.cn__gp__icon{
    .cn__gp__icon-svg {
      circle, rect {
          fill: $color-slate-grey;
      }

      path {
          stroke: $color-slate-grey;
      }

      &.parking {
          path {
            fill: $color-slate-grey;
          }
      }

      &.washing-machine {
          .washing-mashine-outlined-rect-1, .washing-mashine-outlined-circle-1 {
            stroke: $color-slate-grey;
            fill: transparent;
          }
          path {
            stroke: $color-slate-grey;
            fill: $color-slate-grey;
          }
          .washing-mashine-outlined-rect-2, .washing-mashine-outlined-circle-2 {
            fill: $color-slate-grey;
          }
      }

      &.services {
          path:last-child {
            fill: $color-slate-grey;
          }
      }

      &.pool {
          .pool-outlined-path-3, .pool-outlined-path-4 {
            fill: $color-slate-grey;
            stroke: transparent;
          }
          path {
              stroke: $color-slate-grey;
          }
          rect {
              fill: $color-slate-grey;
          }
      }

      &.bath {
          .bath-outlined-path-2 {
            fill: $color-slate-grey;
          }
      }

      &.cigarette {
          .cigarette-crossed-path-3, .cigarette-crossed-path-4 {
            fill: $color-slate-grey;
            stroke: transparent;
          }
      }

      &.lotos {
          path:last-child {
            fill: $color-slate-grey;
            stroke: transparent;
          }
      }

      &.refrigerator,
      &.car-and-airplane,
      &.oven,
      &.stove,
      &.balcony,
      &.Bar,
      &.BBQ-grill,
      &.On-site-restaurant,
      &.spoon-and-fork {
          path {
            fill: $color-slate-grey;
            stroke: transparent;
          }
      }

      &.toy {
          mask {
            path {
              stroke: initial;
            }
          }
          path, .toy-outlined-rect-1 {
            fill: $color-slate-grey;
            stroke: transparent;
          }
          .toy-outlined-rect-2, .toy-outlined-rect-3 {
            fill: transparent;
            stroke: $color-slate-grey;
          }
      }

      &.wheelchair {
          path {
            fill: $color-slate-grey;
            stroke: transparent;
          }
          ellipse {
            fill: $color-slate-grey;
          }
      }

      &.barbell {
          rect {
            stroke: $color-slate-grey;
          }
          mask {
            path {
              stroke: transparent;
            }
          }
          path {
            fill: $color-slate-grey;
            stroke: transparent;
          }
      }
    }
    width: 24px;
    height: 24px;
    margin-right: 5px;
    &:last-child{
        margin-right: 0;
    }
    img{
        filter: brightness(1) !important;
        width: 24px !important;
        height: 24px !important;
    }
}