.PresetDateRangePicker_panel {
    padding: 0 22px 11px
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer
  }
  .PresetDateRangePicker_button:active {
    outline: 0
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
  }
  .SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
  }
  .SingleDatePickerInput__rtl {
    direction: rtl
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2
  }
  .SingleDatePickerInput__block {
    display: block
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 6px
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 9px
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block
  }
  .SingleDatePicker__block {
    display: block
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0
  }
  .SingleDatePicker_picker__directionRight {
    right: 0
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2;
    display: none !important;
  }
  .DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
  }
  .DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    line-height: 18px;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
      font-weight: 600;
    }
    //padding: 11px 12px;
    // @media screen and (min-width: 601px) and (max-width: 715px) {
    //   padding: 8px 6px;
    //   width: 30px !important;
    // }

  }
  .CalendarDay:active {
    outline: 0
  }
  .CalendarDay__defaultCursor {
    cursor: default
  }
  .CalendarDay__default {
    color: #242526;
    background: #fff;
    outline: none !important;
  }
  .CalendarDay__default:hover {
    // background: #F35597;
    // color: #fff;
    // border-radius: 100%;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
  }
  .CalendarDay__outside:hover {
    border: 0
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    color: #cacccd
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    // background: #fff;
    // color: #cacccd
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
  }
  
  .CalendarDay__selected_span {
    background: #EEEEF0;
    color: #242526;
    padding-right: 0px;
    }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #EEEEF0;
    color: #242526;
    border-radius: 0;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    // background: #F35597;
    // color: #fff;
    // outline: none;
    // border-radius: 100%;
    // position:relative;
    // z-index: 2;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #EEEEF0;
    border: none;
    color: #242526;
  }
  .CalendarDay__hovered_span:active {
    background: #EEEEF0;
    border: none;
    color: #242526;
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    // background: #cacccd;
    // color: #82888a
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    color: #ACABB4;
  }
  .CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
  }
  .CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
  }


  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  .CalendarMonth_table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 2px;
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate
  }
  .CalendarMonth_caption {
    color: #242526;
    font-size: 16px;
    text-align: center;
    padding-top: 16px;
    line-height: 150%;
    // padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial;
    font-weight: 500;
    >strong {
      font-weight: 600;
    }
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
  }
  .CalendarMonthGrid__animating {
    z-index: 1
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
  }
  .CalendarMonthGrid__vertical,
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
    @media screen and (min-width: 300px) and (max-width: 600px) {
      height: 128px;
      overflow: hidden;
    }
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2
  }
  .DayPickerNavigation__horizontal {
    height: 0
  }
  .DayPickerNavigation__verticalScrollable_prevNav {
    z-index: 1
  }
  .DayPickerNavigation__verticalDefault {
    position: fixed;
    width: calc(100% - 2.5rem);
    height: 52px;
    bottom: 58px;
    left: 1.25rem;
    border-radius: 10px 10px 0 0;
    border: 2px solid #E9E9EB;
    background: $color-white;
    &::before{
      position: absolute;
      content: "";
      height: 100%;
      width: 2px;
      background: #ebeaeb;
      left: 50%;
      z-index: 2;
    }
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative
  }
  .DayPickerNavigation__bottom {
    height: auto
  }
  .DayPickerNavigation__bottomDefault {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
  }
  .DayPickerNavigation_button__default {
    color: #757575
  }
  .DayPickerNavigation_button__disabled {
    background-image: none !important;
    cursor: default;
  }
  .DayPickerNavigation_button__disabled:focus,
  .DayPickerNavigation_button__disabled:hover {
    border: none;
  }
  .DayPickerNavigation_button__disabled:active {
    background: 0 0
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 12px;
    // top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
  }
  .DayPickerNavigation_bottomButton__horizontalDefault {
    position: static;
    margin: -10px 22px 30px
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault,
  .DayPickerNavigation_prevButton__verticalScrollableDefault {
    width: 100%
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 1.25rem;
    fill: #484848;
    path{
      stroke: #797882;
      stroke-width: 40px;
    }
  }
  .DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left;
    // @media screen and (min-width: 601px) and (max-width: 715px) {
    //   width: 500px !important;
    //   & > div > div {
    //     width: 500px !important;
    //   }
    // }
  }
  .DayPicker__horizontal {
    background: #fff
  }
  .DayPicker__verticalScrollable {
    height: 100%
  }
  .DayPicker__hidden {
    visibility: hidden
  }
  .DayPicker__withBorder {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 5px 0px;
    // @media screen and (min-width: 300px) and (max-width: 1136px) {
    //   box-shadow: none;
    // }
    @media screen and (min-width: 300px) and (max-width: 600px) {
      box-shadow: none;
    }
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
  }
  .DayPicker_portal__vertical {
    position: initial
  }
  .DayPicker_focusRegion {
    outline: 0
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
  }
  .DayPicker_weekHeaders {
    position: relative
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px
  }
  .DayPicker_weekHeader {
    color: #797882;
    position: absolute;
    top: 54px;
    z-index: 2;
    text-align: left;
    @media screen and (min-width: 601px) {
      text-transform: uppercase;
      font-variant: small-caps;
    }
    // @media screen and (min-width: 601px) and (max-width: 715px) {
    //   left: 0px !important;
    //   &:last-child {
    //     left: 240px !important;
    //   }
    // }
    // @media screen and (min-width: 300px) and (max-width: 600px) {
    @media screen and (min-width: 300px) and (max-width: 763px) {
      color: #797882;
      position: absolute;
      background: #ffffff;
      top: 85px;
      z-index: 2;
      text-align: left;
      padding: 0px 13px !important;
      margin-top: -25px;
    }
  }
  .DayPicker_weekHeader__vertical {
    left: 50%
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
    
    .DayPicker_weekHeader_ul {
      display: inline-block;
    }
  }
  .DayPicker_weekHeader_ul {
    display: flex;
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;

    .DayPicker_weekHeader_li{
      flex: 1;
      // @media screen and (min-width: 601px) and (max-width: 715px) {
      //   width: 30px !important;
      // }
    }
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    // @media screen and (min-width: 601px) and (max-width: 715px) {
    //   width: 500px !important;
    // }
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height .2s ease-in-out;
    -moz-transition: height .2s ease-in-out;
    transition: height .2s ease-in-out
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%;
    @media screen and (min-width: 300px) and (max-width: 600px) {
      // height: 100vh !important;
      overflow: auto;
    }
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
    .DayPickerNavigation {
      display: none;
    }
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    vertical-align: middle
  }
  .DateInput__block {
    width: 100%
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
  }
  .DateInput_input {
    font-family: $primary-font;
    width: 100%;
    border-radius: 0.375rem;
    box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
    font-size: 1.0625rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #242526;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 2.375rem;
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
  }
  .DateInput_input__regular {
    font-weight: auto
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2;
    top: 43px !important;
    display: none;
  }
  .DateInput_fangShape {
    fill: #fff
  }
  .DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: grid !important;
    grid-template-columns: 1fr 30px 1fr;
    align-items: center;
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2
  }
  .DateRangePickerInput__rtl {
    direction: rtl
  }
  .DateRangePickerInput__block {
    display: block
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    width: 11px;
    height: 10px;
    background: url(#{$path-img}date-icon.svg);
    overflow: hidden;
    text-indent: -100px;
    .DateRangePickerInput_arrow_svg {
      display: none
    }
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .DateRangePicker {
    position: relative;
    display: block
  }
  .DateRangePicker__block {
    display: block
  }
  .DateRangePicker_picker {
    z-index: 2;
    background-color: #fff;
    position: absolute;
    border-radius: 20px;
    @media screen and (max-width:600px){
      border-radius: 0;
    }
  }
  .DateRangePicker_picker__rtl {
    direction: rtl
  }
  .DateRangePicker_picker__directionLeft {
    left: 0
  }
  .DateRangePicker_picker__directionRight {
    right: 0
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }

  .calendar-input-react-date {
    margin-bottom: 1.25rem;
      &.open-center{
        .DayPickerKeyboardShortcuts_show {
          display: none;
      }
         .DateRangePicker_picker {
              left:auto !important;
              right: 0;
              @media screen and (min-width: 601px) and (max-width: 1136px) {
                left: -52% !important;
            }
          }
          .DateInput_fang{
            left: 20%;
            top: 42px !important;
            .DateInput_fangStroke {
              stroke: #fff;
              fill: transparent;
              box-shadow: 0px -10px 9px rgba(0, 0, 0, 0.1);
          }
          }
       }
}

.calendar-input-react-date .DateInput_input {
    height: 38px;
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $primary-font;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    @media screen  and (max-width: 600px) {
      height: 46px;
    }
}

.search-input .home-search-mobile span {
    width: auto;
    float: none;
    text-align: left;
}

.breadcrumbs ul li a {
    cursor: pointer;
}

.date-section {
    text-align: center;
    padding: 1.25rem;
    .DateRangePicker{
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.6s ease;
    }

}

.DateRangePicker {
    @media screen and (min-width: 1024px) {
        width: 100%;
        .DateRangePickerInput {
            display: flex;
            align-items: center;
        }
    }
    .DateInput {
        @media screen and (min-width: 1024px) {
            width: 100%;
        }
    }
}

.guests-dd-box {
    display: block;
}

.banner-text-form .banner-search .search-input .DateRangePicker input {
  padding-left: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: #242526;
  width: 100%;
  box-shadow: none;
}


.CalendarDay__selected_start + .CalendarDay__selected_span {
  // box-shadow: -21px 0 #EEEEF0;
}
.CalendarDay__selected_start.CalendarDay__lastDayOfWeek + .CalendarDay__selected_span {
  box-shadow: unset;
}

.CalendarDay__selected_span + .CalendarDay__selected_end {
  // box-shadow: -13px 0 #EEEEF0;
}
.CalendarDay__selected_span + .CalendarDay__selected_end.CalendarDay__firstDayOfWeek {
  box-shadow: unset;
}

.CalendarDay__selected_span.CalendarDay__lastDayOfWeek {
  border-radius: 0 4px 4px 0;
}
.CalendarDay__selected_span.CalendarDay__firstDayOfWeek {
  border-radius: 4px 0 0 4px;
}


.date-section{
  .DateInput_fang {
    display: none;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }
  .DateRangePicker_picker {
    width: 100%;
}
  .DayPicker_weekHeader_ul{
    font-size: 14px;
    line-height: 19px;
  }
  .CalendarMonth {
    padding: 0 !important;
  }
  .CalendarMonthGrid {
    width: 100% !important;
  }
  .CalendarMonth_table {
    width: 100%;
  }
  .DayPicker_weekHeader {
    width: 100%;
    padding: 0 0 20px !important;
    top: 0;
    text-transform: uppercase;
    left: 0;
    margin-left: 0 !important;
    position: relative;
  }

  .CalendarMonth_caption {
    color: #000000;
    font-size: 16px;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    caption-side: initial;
    font-weight: 500;
}


  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #F35597;
    color: #fff;
    border-radius: 100%;
    border: none;
  }
  .CalendarDay__selected_span {
    background: #EEEEF0;
    border: none;
    color: #242526;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end{
      z-index: 1;
      position: relative;
}

}

.CalendarDay__today{
  color: #F35597;
  &.CalendarDay__selected{
    color: #fff;
  }
}

.DayPickerNavigation_leftButton__horizontalDefault,
.DayPickerNavigation_rightButton__horizontalDefault{
  background-image: url(#{$path-img}banner-next.svg);
  background-repeat: no-repeat;
  background-position: left center;
    svg{
      visibility: hidden;
    }
    &:focus {
      outline: none !important;
    }
}

.DayPickerNavigation_rightButton__horizontalDefault{
  background-image: url(#{$path-img}banner-prev.svg);
  background-position: right center;
}

@media screen  and (max-width: 600px) {
  .calendar-mble-popup {
    &.DateRangePicker_picker {
      position: fixed;
      top: 0 !important;
      left: 0 !important;
      right: 0;
      bottom: 0;
      z-index: 9;
      overflow: auto;
      border-radius: 0;
      .DayPicker {
        height: 100vh;
        padding: 0;
      }
      .popup-mobile-back {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        padding: 1.25rem 1.25rem 1.25rem 1.25rem;
        background: $color-white;
        @include box-shadow();
        @include body-text();
        color: $color-black;
        cursor: default;
        img {
          float: left;
        }
      }
      .DayPicker_focusRegion {
        height: 100%;
        padding: 0;
        overflow: auto;
      }
    }
  }
}



// SRP ADDED SHAYAN LDP CALENDAR BEHAVIOUR

@media screen  and (min-width: 601px) {
    .CalendarDay__default {
      &:not(.CalendarDay__blocked_calendar) {
        &:hover {
          // background: var(--color-shade-grey);
          // color: var(--color-black);
        }
      }
      // &:not(.CalendarDay__selected_span) {
      //   &:hover {
      //     border-radius: var(--radius-calendar);
      //   }
      // }
    }
    .CalendarDay__today {
      color: var(--color-vibrant-pink);
    }
    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
      // background: #fff !important;
      // &:before {
      //   background: transparent;
      // }
    }
    .CalendarDay__selected_start {
      // &::before {
      //   border-radius: var(--radius-calendar);
      //   background: var(--color-vibrant-pink);
      //   position: absolute;
      //   content: ' ';
      //   width: 100%;
      //   height: 100%;
      //   top: 0px;
      //   left: 0px;
      //   z-index: -1;
      // }
      &.CalendarDay__selected_start_in_hovered_span,
      &:hover {
        // background: var(--color-shade-grey);
        // border-radius: var(--radius-calendar) 0 0 var(--radius-calendar);
        // color: var(--color-white) !important;
      }
    }
    // .CalendarDay__hovered_span {
    //   background: var(--color-shade-grey) !important;
    //   border-radius: 0;
    //   &:hover {
    //     border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
    //   }
    //   &.CalendarDay__lastDayOfWeek {
    //     border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
    //     &:before {
    //       display: none;
    //     }
    //   }
    //   &.CalendarDay__firstDayOfWeek {
    //     border-top-left-radius: var(--radius-calendar) !important;
    //     border-bottom-left-radius: var(--radius-calendar) !important;
    //     &:before {
    //       display: none;
    //     }
    //   }
    // }
    .CalendarMonth_table
      > tbody
      > tr:first-child
      td:not([class])
      + td.CalendarDay__default {
      &.CalendarDay__selected_span,
      &.CalendarDay__hovered_span {
        // border-top-left-radius: var(--radius-calendar) !important;
        // border-bottom-left-radius: var(--radius-calendar) !important;
      }
    }
    .CalendarDay__after_hovered_start {
      border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0;
      &.CalendarDay__blocked_minimum_nights {
        border-radius: 0px;
      }
    }
    .CalendarDay__selected_span {
      border-radius: 0px;
      &.CalendarDay__lastDayOfWeek {
        border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
        // &:before {
        //   display: none;
        // }
      }
      &.CalendarDay__firstDayOfWeek {
        border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
        // &:before {
        //   display: none;
        // }
      }
      &.CalendarDay__firstDayOfWeek.CalendarDay__lastDayOfWeek {
        border-radius: var(--radius-calendar) !important;
      }
    }
    .CalendarDay__selected_end {
      background: var(--color-shade-grey);
      border-radius: 0 50% 50% 0;
      color: var(--color-white) !important;
      &.CalendarDay__firstDayOfWeek {
        background: transparent !important;
      }
      // &::before {
      //   border-radius: var(--radius-calendar);
      //   background: var(--color-vibrant-pink);
      //   position: absolute;
      //   content: ' ';
      //   display: block !important;
      //   width: 100%;
      //   height: 100%;
      //   top: 0px;
      //   left: 0px;
      //   z-index: -1;
      // }
    }
    .DayPicker_transitionContainer__verticalScrollable {
      overflow-x: hidden;
    }
    .CalendarMonthGrid__vertical,
    .CalendarMonthGrid__vertical_scrollable {
      // width: var(--container-width-calendar) !important;
    }
    .DayPickerNavigation__verticalDefault::before {
      display: none;
    }
    .DayPickerNavigation__verticalDefault {
      border-radius: 0px;
      box-shadow: 0 0 10px #e9e9eb;
      left: -3px;
      bottom: 0px;
      width: calc(100% + 6px);
      border: none;
    }
    .DayPickerNavigation__verticalScrollable_prevNav {
      display: none;
    }
    .DayPicker_weekHeader__verticalScrollable {
      // border-bottom: none;
      // top: -5px;
      ul {
        li {
          small {
            // color: var(--color-dark-grey);
            // font-family: $primary-font;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 19px;
          }
        }
      }
    }
}
