.search-input {
	@include input-group-box();	 
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    border: none; 
    position: relative;
    
    &.cn-input-empty{
        background: $color-light-gray;
        border: none;
        .input-empty{
          background:none;
          border: none; 
        } 
      } 
      input{ 
        background: none;
        border-radius: 0.375rem; 
        border: none;
        @media screen  and (max-width: 600px) {
          border-radius: 0.5rem;
        }  
      } 
    span {
        width: 2.5rem;
        float: left;
        text-align: center;
        padding: 0.625rem 0 0 0;
        @media screen  and (max-width: 600px) {
          padding: 0.95rem 0 0 0 !important;
        }
    }
     input {
        width: calc(100% - 40px);
        padding: 0;
        border: none;
        @include box-shadow-none();
    }
    input:focus{
        outline: none;
        border: none;
    } 

    .si__search-icon{
      width: 2.5rem;
      float: left;
      text-align: center;
      padding: 0.625rem 0 0 0;
      position: absolute;
      top: 0;
      right: 0;
    }
}