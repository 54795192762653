.drop-down {
    border-radius: rem(6);
    // padding-bottom: rem(10);
    .drop-down_toggle {
        border-radius: inherit;
        transition: .3s ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: rem(350);
        border: none;
        outline: none;
        background: transparent;
        padding: rem(15) rem(20);
        font-family: $primary-font;
        font-weight: 400;
        color: $color-black;
        font-size: rem(14);

        .carret-icon {
            transition: inherit;
            transform: rotate(-90deg);
        }

        &:hover {
            background: $color-light-gray-input;
        }
    }

    .drop-down_inner {
        // margin-right: rem(10);
        transition: .3s ease-in-out;
        overflow: hidden;
    }

    &.drop-down__active {
        overflow: hidden;
        background-color: $color-white;
        .drop-down_inner {
            height: unset !important;
        }
        .drop-down_toggle {
            background: $color-white;
            font-weight: 600;
            .outer_info-content {
                font-size: 14px;
                color: $color-pure-black;
                font-weight: 600;
            }
            .carret-icon {
                transform: rotate(0);
            }
        }
    }

    &.drop-down__disabled {
        .drop-down_toggle {
            &:hover {
                background-color: $color-white;
            }
            cursor: initial;
            opacity: .6;
            .carret-icon {
                visibility: hidden;
            }
        }
    }

    .inner-wrapper-layout {
        background: $color-white;
        border-bottom-left-radius: rem(6);
        border-bottom-right-radius: rem(6);
    }
}
