//---------MOBILE---------
.cn__cnt__slider__recommended{
    position: relative;
    .cn__cnt__slider__recommended__slider{
        .slick-track{
            padding-top: 30px !important;
        }
        .slick-arrow{
            top: unset;
            bottom: -32px;
            width: 28px;
            height: 28px;
            border-width: 2px;
        svg{
            height: 10px;
        }
        &.slick-prev{
            right: 38px;
        }
        }
    }
    .simplebar-track.simplebar-horizontal {
        background: #FAF6E7 !important;
        visibility: visible !important;
    }
}

//---------TABLET---------
@media screen and (min-width:601px){
    .cn__cnt__slider__recommended {
       
    } 
}


//---------DESKTOP---------
@media screen and (min-width:1136px){
    .cn__cnt__slider__recommended {
        
    }
}