@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp5{
    position: relative;
    width: 100%;
    background: #F1EFEE;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: dvw(100) 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__box{
        position: relative;
        display: grid;
        grid-template-columns: 46% 54%;
        align-items: center;

        &__sec1{
            position: relative;
            width: 100%;
            height: dvw(404);

            &__active{
                position: absolute;
                width: dvw(419);
                left: 50%;
                border-radius: dvw(5);
                transform: translateX(-50%);
                z-index: 1;
                box-shadow: 0px 32px 28px -21px rgba(0, 0, 0, 0.48);
                transition: all 0.3s ease;
            }
            &__pos1{
                position: absolute;
                width: dvw(419);
                border-radius: dvw(5);
                left: 50%;
                transform: translateX(-85%) scale(0.65);
                opacity: 0.5;
                box-shadow: 0px 30px 28px -20px #8D8D8D;
                transition: all 0.3s ease;
            }
            &__pos3{
                position: absolute;
                width: dvw(419);
                border-radius: dvw(5);
                left: 50%;
                transform: translateX(-15%) scale(0.65);
                opacity: 0.5;
                box-shadow: 0px 30px 28px -20px #8D8D8D;
                transition: all 0.3s ease;
            }
        }

        &__sec2{
            position: relative;
            padding-left: dvw(64);

            &__menu{
                position: relative;
                display: grid;
                grid-template-columns: repeat(3,minmax(0,1fr));

                &__opt{
                    font-size: dvw(23);
                    line-height: 1.2;
                    color: #0F294D;
                    text-align: center;
                    padding-bottom: dvw(15);
                    cursor: pointer;

                    &.cn__lyp__lyp5__box__sec2__menu__opt--active{
                        position: relative;
                        font-weight: 700;

                        &::after{
                            position: absolute;
                            content: '';
                            bottom: -(dvw(1));
                            left: 0;
                            width: 100%;
                            height: dvw(3);
                            background: #0F294D;
                        }
                    }
                }

                &::after{
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: dvw(1);
                    background: #0F294D;
                }
            }

            &__info{
                position: relative;
                margin-top: dvw(57);

                &__title{
                    font-weight: 600;
                    font-size: dvw(28);
                    line-height: 1;
                    color: #0F294D;
                }
                &__subtitle{
                    font-size: dvw(16);
                    line-height: 1.5;
                    letter-spacing: 0.01em;
                    color: #000000;
                    margin-top: dvw(10);
                    min-height: dvw(72);
                }
            }
        }
    }
}

@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp5{
    
        &__wrapper{
            padding: 100px;
        }
    
        &__box{
    
            &__sec1{
                height: 404px;
    
                &__active{
                    width: 419px;
                    border-radius: 16px;
                }
                &__pos1{
                    width: 419px;
                    border-radius: 16px;
                }
                &__pos3{
                    width: 419px;
                    border-radius: 16px;
                }
            }
    
            &__sec2{
                padding-left: 64px;
    
                &__menu{
    
                    &__opt{
                        font-size: 23px;
                        padding-bottom: 17px;
                    }

                }
    
                &__info{
                    margin-top: 57px;
    
                    &__title{
                        font-size: 28px;
                    }
                    &__subtitle{
                        font-size: 16px;
                        margin-top: 10px;
                        min-height: 72px;
                    }
                }
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp5{

        &__wrapper{
            padding: 7vw 30px;
        }

    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp5{

        &__wrapper{
            padding: mvw(60) 0px;
        }

        &__box{
            display: block;
    
            &__sec2{    
                &__menu{
                    width: calc(100% - 40px);
                    left: 20px;

                    &__opt{
                        font-size: mvw(16);
                        padding-bottom: mvw(15);
    
                        &.cn__lyp__lyp5__box__sec2__menu__opt--active{
                            &::after{
                                bottom: -(mvw(1));
                                height: mvw(3);
                            }
                        }
                    }
    
                    &::after{
                        height: mvw(1);
                    }
                }
                &__info{
                    margin-top: 0;
                    padding: 0 20px;
    
                    &__title{
                        font-size: mvw(23);
                        line-height: 1.2;
                        min-height: mvw(56);
                    }
                    &__subtitle{
                        font-size: mvw(16);
                        margin-top: mvw(10);
                        min-height: mvw(144);
                    }
                }
            }

            &__boxes{
                position: relative;
                width: 100%;
                overflow-x: hidden;
                -ms-overflow-style: none;
                scrollbar-width: none;
                scroll-behavior: smooth;
                &::-webkit-scrollbar {
                    display: none;
                }

                &__wrapper{
                    position: relative;
                    display: inline-flex;
                    padding-top: mvw(29);
                    padding-bottom: mvw(33);

                    &__opt{
                        width: mvw(162);
                        height: mvw(156);
                        margin-left: mvw(10);
                        opacity: 0.5;
                        flex-shrink: 0;
                        transition: all 0.3s ease;

                        &:first-child{
                            margin-left: 20px;
                        }
                        &.cn__lyp__lyp5__box__boxes__wrapper__opt--active{
                            transform: translateY(-5px);
                            opacity: 1;
                            box-shadow: 0px 32px 28px -21px rgba(0, 0, 0, 0.48);
                        }
                    }

                    &__last{
                        position: relative;
                        width: 20px;
                    }
                }
            }
        }
    }
}