.timezone-input-component {
    position: relative;
    .timezone-dropper-component {
        position: absolute;
        left:0px;
        right:0px;
        max-width: 286px;
        height: 260px;
        background-color: $color-white;
        overflow-y: hidden;
        box-shadow: 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding-bottom: 15px;
        z-index: 9;
        &.top {
            bottom:110%;
        }
        &.bottom {
            top:110%;
        }

        .timezone-search-container {
            background: $color-white;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px;
            height: 40px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            margin-bottom: 0px;
            input {
                color: $color-dark-grey;
                margin-left: 10px;
                width: 100%;
                &:focus, &:hover, &:active {
                    outline: none;
                } 
            }
            .search-icon {
                * {
                    fill: $color-dark-grey; 
                }
            }
        }

        .timezone-list-container {

            .timezone-list-groups {
                &:first-child {
                    margin-top: 25px;
                }
                padding:0px 22px;
                
                h6 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 8px 20px;
                    color: $color-black;
                }
                .timezone-list-item {
                    background-color: transparent;
                    cursor: pointer;
                    margin-bottom: 3px;
                    padding: 8px 20px;
                    border-radius: 18px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    color: $color-black;
                    display: flex;
                    justify-content: space-between;
                    transition: 300ms ease;
                    .highlighted_text {
                        background-color: #e8699373; 
                    }
                    &:hover, &.active {
                        background-color: $color-champagne-pink;
                    }
                }
            }
            
        }
    }
}