@import './tools/_mixins';

:root {
    --v-height: 100vh;
}

.cn__hp__hero{
    position: relative;
    width: 100%;
    height: var(--v-height);
    background: #242526;
    &__img{
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .cn__image-default{
        filter: brightness(0.9);
        object-position: center;
    }
    canvas{
        filter: blur(1px);
    }
    &__covid{
        position: absolute;
        bottom: 16px;
        left: 100px;
        @media screen and (min-width:1920px){
            left: calc(50% - 860px);
        }
    }
    .cn__hp__hero__verification{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-white;
        backdrop-filter: blur(30px);
        box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
        border-radius: 20px;
        z-index: 2;
        padding: 20px;
        padding-top: 8px;
            @media screen and (max-width:600px){
                padding: 10px;
                width: 95%;
            }
        .cn__hp__hero__verification__close{
            text-align: end;
            color: $color-black;
                span{
                    cursor: pointer;
                }
        }
       .cn__hp__hero__verification__logo{
            margin-top: 7px;
            text-align: center;
       }
       .cn__hp__hero__verification__title{
            margin-top: 5px;
            margin-bottom: 17px;
            text-align: center;
            color: $color-black;
            font-weight: 600;
            font-size: 32px;
                @media screen and (max-width:600px){
                    font-size: 18px;
                }
       }
       .cn__hp__hero__verification__subtilte{
            margin-top: 10px;
            margin-bottom: 23px;
            text-align: center;
            font-size: 18px;
            font-weight: 400px;
            color: $color-dark-grey;
            max-width: 730px;
                @media screen and (max-width:600px){
                    font-size: 12px;
                }
       }
       .cn__hp__hero__verification__action{
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
                span{
                        text-align: center;
                        padding: 10px;
                        background: linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%);
                        border-radius: 30px;
                        width: 282px;
                        color: $color-white;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 16px;
                            @media screen and (max-width:600px){
                                width: 120px;
                            }
                }
        }
    }
}

@include desktop2{
    .cn__hp__hero{
        height: 900px;
    }
}

// Christmas inclusions
//DESKTOP 1280
/* Enable for christmas */
/*@media (min-width: 1280px) {
    .cn__hp__hero{
        .cn__image-default{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
        }
    }
}

@media (min-width: 1366px) {
    .cn__hp__hero{
        .cn__image-default{
            object-position: bottom;
        }
    }
}

@media (min-width: 1440px) {
    .cn__hp__hero{
        .cn__image-default{
            object-position: bottom;
        }
    }
}

@media (min-width: 1536px) {
    .cn__hp__hero{
        .cn__image-default{
            object-position: bottom;
        }
    }
}

@media (min-width: 1920px) {
    .cn__hp__hero{
        .cn__image-default{
            object-position: bottom;
        }
    }
}*/

// end Christmas inclusions

//------------------- TABLET -------------------

@media screen and (min-width:600px) and (max-width:1136px){
    .cn__hp__hero{
        max-height: 595px;
        &__covid{
            padding: 0 30px;
            left: 0;
        }
    }
}

//TABLET 601
@media screen and (min-width:601px) and (max-width:763px){
    .cn__hp__hero{
        max-height: 796px;
        .cn__image-default{
            width: 200%;
            height: 200%;
            object-fit: cover;
            //object-position: -22vw -10vh;
            object-position: bottom;
        }
    }
}
//TABLET 764
@media screen and (min-width:764px) and (max-width:1135px){
    .cn__hp__hero{
        max-height: 796px;
        .cn__image-default{
            width: 100%;
            height: 100%;
            object-fit: cover;
            //object-position: center;
            object-position: bottom;
        }
    }
}


//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__hero{

        .cn__image-default{
            width: 100%;
            height: 100%;
            // Christmas inclusions
            object-position: top;
            // Enable for christmas
            // object-position: bottom;
            // end Christmas inclusions
        }
        &__covid{
            width: 100%;
            top: 0;
            padding: 0;
            left: 0;
        }
        &__info{
            position: absolute;
            bottom: 4vh;
            width: 100%;
            text-align: center;
            font-weight: 500;
            font-size: 4.5vw;
            line-height: 7vw;
            color: #FFFFFF;
            display: none;
            &__divider{
                width: 10px;
                height: 2px;
                border-radius: 5px;
                background: #FFFFFF;
                margin: 0 auto;
            }
        }

        .cn__hp__subscribe{
            position: absolute;
            margin: 0;
            bottom: 16px;
            z-index: 2;
        }

    }
}