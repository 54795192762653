.listings-hotel {
    &.listings-hotel--mobile {
        .listings-info {
            .features-booking {
                margin-top: 0;
            }
        }
    }
    .features-booking {
        margin-bottom: 20px;
        .list--left-icon {
            margin-bottom: 20px;
            li {
                @include text_body-text-bold;
                color: var(--color-black);
                path {
                    fill: var(--color-black);
                }
            }
        }
        .booking-summary {
            padding: 25px 40px;
            background: var(--color-white);
            box-sizing: border-box;
            border-radius: 8px;
            h4 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;
                color: var(--color-pure-black);
                margin-bottom: 30px;
            }
            .form__field {
                margin-bottom: 20px;
            }
        }
        .summary-section {
            padding-top: 30px;
        }
        @include desktop {
            display: grid;
            align-items: center;
            grid-template-columns: 5fr 3fr;
            grid-column-gap: 20px;
            .booking-summary {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;
            }
            .summary-section {
                align-self: flex-start;
                padding-top: 0px;
                .list--left-icon {
                    margin-bottom: 25px;
                }
            }
        }
    }


    .card--multi-rooms,
    .card--room-option {
        position: relative;
        padding: 20px;
        margin: 0 -20px 20px;
        >.card__title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: var(--color-black);
        }
        >.card__content {
            padding: 20px 0;
            display: grid;
            grid-row-gap: 20px;
            >.hotel-message-loading {
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%);
                max-width: 882px;
                width: 100%;
                padding:0px 45px;
                z-index: auto;
                >.hotel-message-innerwrap {
                    width: 100%;
                    max-width: 882px;
                    background: #F7F7F7;
                    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
                    border-radius: 20px;
                    padding:32px;
                    overflow: hidden;
                    position: relative;

                    @keyframes cloud-1 {
                        0% {
                            left:-25%;
                            top:25px;
                        }
                        25% {
                            top:40px;
                        }
                        50% {
                            top:10px;
                        }

                        70% {
                            top:40px;
                        }
                        100% {
                            left:100%;
                            top:25px;
                        }
                    }

                    @keyframes cloud-2 {
                        0% {
                            right:-20%;
                            bottom:10px;
                        }
                        25% {
                            bottom:40px;
                        }
                        50% {
                            bottom:10px;
                        }

                        70% {
                            bottom:40px;
                        }
                        100% {
                            right:100%;
                            bottom:25px;
                        }
                    }

                    @keyframes cloud-3 {
                        0% {
                            right:-30%;
                            top:25px;
                        }
                        25% {
                            top:40px;
                        }
                        50% {
                            top:10px;
                        }

                        70% {
                            top:40px;
                        }
                        100% {
                            right:100%;
                            top:25px;
                        }
                    }

                    @keyframes birds-flap {
                        0% {
                            transform: translateY(-10px);
                        }
                        50% {
                            transform: translateY(30px);
                        }
                        100% {
                            transform: translateY(-10px);

                        }
                    }
                    >.hotel-message-bg {
                        position: absolute;
                        left:0px;
                        right:0px;
                        bottom:0px;
                        top:0px;

                        .hotel-message-clouds {
                            position: absolute;

                            &.cloud-1 {
                                left:-25%;
                                top:25px;
                                animation: cloud-1 25s linear infinite;
                            }
                            &.cloud-2 {
                                right:-20%;
                                bottom:10px;
                                animation: cloud-2 45s linear infinite;
                            }
                            &.cloud-3 {
                                right:-30%;
                                top:10px;
                                animation: cloud-3 30s linear infinite;
                            }
                        }
                    }
                    >.hotel-message-content {
                        position: relative;
                        z-index: 2;
                        text-align: center;
                        .hotel-message-logoicon {
                            position: relative;
                            img {
                                transform: translateY(-10px);
                                animation: birds-flap 5s ease infinite;
                            }
                        }
                        h2 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 28px;
                            color: #242526;
                            line-height: normal;
                            margin:25px 0px 30px 0px;
                        }
                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 23px;
                            color: #F35597;
                            margin-bottom: 60px;
                            max-width: 635px;
                            display: inline-block;
                        }
                        @media screen and (max-width:601px) {
                            h2 {
                                font-size: 23px;
                            }
                            p {
                                font-weight: 500;
                                font-size: 14px;
                                margin-bottom: 15px;
                            }
                        }
                    }

                }
                >.hotel-message-innerwrap_noproperty{
                    background: var(--color-white);
                    position: absolute;
                    line-height: 120%;
                    display: flex;
                    column-gap: 20px;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    width: 95%;
                    color: #F0574B;
                    @include mobile {
                        font-weight: 400;
                        font-size: 14px;
                    }
                    @include tablet {
                        font-weight: 500;
                        font-size: 18px;
                    }
                    @include desktop {
                        font-weight: 400;
                        font-size: 18px;
                    }
                    .message-ohno {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 120%;
                        color: #1E2A3B;
                        margin-bottom: 8px;
                    }
                    .img-ohno{
                        margin-bottom: 10px;
                    }
                    .back-to-listing{
                        background: #F47048;
                        border-radius: 10px;
                        text-align: center;
                        letter-spacing: 0.01em;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 150%;
                        color: #FFFFFF;
                        margin-top: 10px;
                        width: 200px;
                        height: 44px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        place-content: center;
                    }
                        @include mobile {
                            width: 80%;
                        }
                        @include tablet {
                            width: 90%;
                        }
            }
            }
            >.rooms {
                grid-area: 1 / 1;
                display: grid;
                grid-row-gap: 25px;
                margin: -20px;
                padding: 20px;
            }
            >.room {
                margin: -20px;
                padding: 20px;
                h4 {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 21px;
                    font-variant: all-small-caps;
                }
            }
            >.details {
                padding-top: 25px;
                border-top: 1px solid #E9E9EB;
                .expandable-list__footer .n-btn {
                    width: 100%;
                }
            }
            .expandable-list__title {
                @include text-subtitle;
                font-weight: 500;
            }
            @include desktop {
                grid-template-columns: 4fr 1fr;
                grid-column-gap: 40px;
                border-top: 1px solid #E9E9EB;
                border-bottom: 1px solid #E9E9EB;
                >.rooms {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(3, 1fr);
                    grid-column-gap: 20px;
                }
                >.details {
                    grid-area: 1 / 2;
                    border-top: none;
                    padding-top: 0px;
                    .expandable-list__footer .n-btn {
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        path {
                            fill: var(--color-dark-grey);
                        }
                    }
                }
            }
        }
        >.card__footer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .n-btn {
                justify-content: center;
                align-items: center;
                min-width: 160px;
                font-weight: 600;
            }
            summary {
                >strong {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                    color: var(--color-black);
                }
                >span {
                    margin-left: 3px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: var(--color-dark-grey);
                    .ic-icon {
                        @include sqr_16;
                    }
                }
            }
            small {
                color: var(--color-dark-grey);
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                @include mobile {
                    grid-area: 2/2;
                    align-self: flex-end;
                    text-align: end;
                    margin-top: 10px;
                }
            }
            @include tablet {
                padding: 30px 0 0;
                display: block;
                summary {
                    display: inline;
                    >strong {
                        margin-bottom: 0px;
                        display: inline;
                    }
                    >span {
                        display: inline;
                    }
                }
                .n-btn {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    width: auto;
                }
            }
        }
        .expandable-list {
            &__header {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: var(--color-dark-grey);
                font-variant: small-caps;
                text-transform: none;
            }
            li {
                display: flex;
                @include text-normal;
                .ic-icon {
                    @include sqr_16;
                }
                span {
                    display: flex;
                    align-items: center;
                }
                small {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    color: var(--color-dark-grey);
                }
            }
        }
        @include tablet {
            margin: 0 0 20px;
        }
        @include desktop {
            margin: 0 0 20px;
        }
    }
    .card--multi-rooms {
        >.card__content {
            >.details {
                @include tablet {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
                @include desktop {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    .expandable-list__footer .n-btn {
                        width: auto;
                    }
                }
            }
        }
    }
    .card--room-option {
        margin-top: 60px;
        >.card__content {
            grid-template-columns: auto;
            @include desktop {
                grid-template-columns: 5fr 4fr;
                border-top: none;
                .expandable-list__content li {
                    margin-bottom: 10px;
                }
            }
        }
        >.card__footer {
            border: none;
        }
    }
    .n-btn--show-all-rooms {
        justify-content: center;
        margin-bottom: 25px;
    }
    .description-list {
        h5 {
            @include text-subtitle;
            letter-spacing: unset;
            font-feature-settings: unset;
            font-variant: unset;
        }
        > div {
            p {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .expandable-card-content {
        summary {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            @include desktop {
                line-height: 22px;
            }
        }
        .card__footer .n-btn {
            font-weight: 600;
        }
    }
    .map-container {
        height: 430px;
        border-radius: 10px;
        margin-bottom: 15px;
        .listing-details-map {
            border-radius: 10px;
        }
        .map-control {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            &.map-pin {
                top: 0;
            }
            &.map-viewtype {
                top: 45px;
            }
            &.map-fullscreen {
                display: none;
            }
        }
        .search_container {
            z-index: 1;
        }
        @include mobile {
            // Enabled due to https://cuddlynest.atlassian.net/browse/FE-4493
            // .map-control {
            //     &.map-pin {
            //         display: none;
            //     }
            //     &.map-viewtype {
            //         top: 0;
            //     }
            // }
            .map-zoom-controls {
                transform: initial;
                top: 0;
                margin-top: 20px;
                display: none;
            }
        }
        // @include desktop {
        //     max-width: min(830px, 100%);
        // }
    }
    .card--services {
        .expandable-list__title {
            margin-bottom: 0px;
        }
        .expandable-list__content li {
            margin-bottom: 8px;
        }
        .expandable-list__footer {
            .n-btn {
                path {
                    fill: var(--color-dark-grey);
                }
            }
        }
    }
    .card--location {
        p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        small {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: var(--color-dark-grey);
        }
        em {
            font-family: $primary-font;
            font-style: italic;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: var(--color-dark-grey);
        }

        /* TODO: once Usman will deliver POI uncomment
        .card--location__map {
            @include desktop {
                display: grid;
                grid-template-columns: 5fr 3fr;
                .card--neighborhood {
                    grid-area: 2 / 1;
                }
            }
        } */
        .card--neighborhood {
            max-width: 770px;
        }
    }
    .not-available {
        color: var(--color-red);
    }
    .p-cancellation-policy {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .list-detail-modal .list>strong {
        font-weight: 500;
    }
    @include mobile {
        .room--big-img {
            h4 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.01em;
            }
        }
    }
    /*@include tablet {
        .nav {
            overflow-x: auto;
            overflow-y: hidden;
            li {
                white-space: nowrap;
            }
        }
    }
    @include desktop {
        .nav {
            overflow: hidden;
        }
    }*/
    @media screen and (max-width: 900px) {
        .unavailable-card-wrapper.unavailable-card-wrapper {
            top: -45%;
        }
    }
    @media screen and (max-width: 600px) {
        .unavailable-card-wrapper.unavailable-card-wrapper {
            top: -100%;
            .unavailable-card {
                padding: 18px 24px;
            }
            .unavailable-card-text {
                font-size: 14px;
            }
            .unavailable-card-buttons-btn.unavailable-card-buttons-btn {
                &--filled {
                    margin-bottom: 6px;
                }
                &--hollow {
                    border: unset;
                    text-decoration: underline;
                }
            }
        }
    }
    .unavailable-card-wrapper {
        width: 100%;
        position: absolute;
        z-index: 2;
        top: -60%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .unavailable-card {
        width: 940px;
        max-width: 90vw;
        padding: 40px;
        background-color: var(--color-white);
        border-radius: 20px;
        box-shadow: 0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12);

        &--appear-animation {
            animation: fade 0.3s ease;
        }

        .unavailable-card-symb {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .unavailable-card-title {
            color: var(--color-cn-pink);
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin: 24px 0;
        }
        .unavailable-card-text {
            color: var(--color-dark-grey);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin: 24px auto;
            width: 780px;
            max-width: 90%;
        }
        .unavailable-card-buttons {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .unavailable-card-buttons-btn {
                background: none;
                border: none;
                outline: none;
                width: 230px;
                padding: 10px 0;
                margin: 0 14px;
                display: flex;
                justify-content: center;
                border-radius: 100px;

                &--filled {
                    background: var(--color-cn-pink);
                    color: var(--color-white);
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    letter-spacing: 0.16px;
                }
                &--hollow {
                    color: var(--color-cn-pink);
                    border: 0.5px solid var(--color-cn-pink);
                    color: var(--pinks-pink-cuddly-nest, #F35597);
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    letter-spacing: 0.16px;
                }
            }
            .unavailable-card-buttons-btn:hover {
                cursor: pointer;
            }
        }
    }
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.listings-hotel-price-breakdown {
    h5 {
        text-align: center;
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 25px;
        color: var(--color-black);
        @include desktop {
            color: var(--color-shade-grey);
        }
    }
    ul {
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            @include desktop {
                color: var(--color-shade-grey);
            }
            &.divider {
                height: 1px;
                margin: 25px 0;
                background: #E9E9EB;
            }
            strong {
                font-weight: 600;
            }
            > span {
                &:last-child {
                    padding-left: 10px;
                }
            }
        }
    }
}

.n-modal-all-rooms {
    .n-modal__header {
        justify-content: flex-start;
        p {
            display: none;
            white-space: nowrap;
        }
        .n-input-tag-selector {
            ul {
                flex-flow: initial;
                overflow-x: scroll;
                max-width: 100%;
                li {
                    margin: 0 7px 7px 0;
                    white-space: nowrap;
                }
            }
            @include mobile {
                overflow-x: scroll;
            }
        }
        .n-modal__close {
            display: none;
        }
        @include tablet {
            .n-modal__close {
                display: initial;
            }
            .n-input-tag-selector {
                ul {
                    overflow-x: auto;
                }
            }
        }
        @include desktop {
            .n-input-tag-selector {
                ul {
                    max-width: 490px;
                    flex-wrap: wrap;
                }
            }
        }
    }
    .n-modal__cont.n-btn--show-all-roomsent {
        padding-top: 0px;
    }
    .list-rooms {
        >li {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            >.n-btn {
                padding: 0px;
                color: var(--color-black);
                justify-content: flex-start;
                @include desktop {
                    justify-content: flex-end;
                }
            }
        }
        .room {
            grid-template-columns: 150px 1fr;
            grid-template-rows: 20px 1fr auto;
            grid-row-gap: 20px;
            grid-column-gap: 10px;
            >h4 {
                cursor: pointer;
                grid-area: 1 / 1 / 1/ -1;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include mobile {
                    .ic-icon {
                        display: initial;
                    }
                }
            }
            >img {
                grid-area: 2 / 1;
            }
            >.list {
                grid-area: 2 / 2;
                ul {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(4, 24px);
                }
            }
            .details {
                display: flex;
                justify-content: flex-start;
                grid-area: 3 / 1 / 3 / -1;
            }
        }
    }
    .n-modal__content {
        padding: 5px 30px;
    }
    @include tablet {
        .list-rooms {
            .room {
                grid-template-columns: 225px 1fr;
            }
        }
    }
    @include desktop {
        .n-modal__header {
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.02em;
                color: var(--color-black);
                display: initial;
            }
            .n-input-tag-selector {
                padding-left: 12px;
                ul li {
                    margin: 0 7px 0 0;
                }
            }
        }
        .list-rooms {
            .room {
                grid-template-columns: 150px 1fr;
                grid-template-rows: 20px 80px 1fr;
                grid-gap: 10px;
                >h4 {
                    cursor: pointer;
                    grid-area: 1 / 2;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                >img {
                    grid-area: 1 / 1 / 2 / 1;
                }
                >.list {
                    grid-area: 2 / 2;
                    ul {
                        grid-template-columns: 120px 1fr;
                        grid-template-rows: repeat(2, 24px);
                    }
                }
                .details {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.list-rooms {
    list-style: none;
    >li {
        padding: 25px 0px;
        border-bottom: 1px solid var(--color-light-grey);
    }
    .room {
        .details {
            button {
                font-weight: normal;
                color: var(--color-black);
            }
        }
    }
}

.n-modal--room-detail {
    .img-cover {
        width: 100%;
        height: 184px;
    }
    .not-available {
        color: var(--color-red);
    }
    .slider-masonry--item-1,
    .slider-masonry--item-2 {
        ul {
            min-height: 184px;
            max-height: 184px;
        }
    }
    .slick-slider {
        margin: 0 -20px;
        .carousel-slider__item {
            img {
                width: 100%;
                height: 235px;
                object-fit: cover;
            }
        }
    }
    h2 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 20px 0 15px 0;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    .list--amenities {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 25px;
        .list>strong {
            color: var(--color-dark-grey);
            margin-bottom: 10px;
        }
    }
    .room-booking-option {
        background: #fff;
        border-radius: 15px;
        margin-bottom: 15px;
        padding: 15px;
        grid-template-columns: 1fr;
        .list {
            ul {
                grid-gap: 10px;
                li {
                    color: var(--color-black);
                }
            }
        }
        .details {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            margin-top: 20px;
            .price {
                grid-area: 1 / 1 / 2 / 1;
            }
            small {
                color: var(--color-dark-grey);
                margin-top: 10px;
                grid-area: 2 / 2;
            }
            .n-btn {
                justify-content: center;
                grid-area: 1/2;
                font-weight: 600;
            }
        }
    }
    @include desktop {
        .list--amenities {
            grid-template-columns: 5fr 3fr;
            .list {
                margin: 0px;
                >strong {
                    color: var(--color-dark-grey);
                    margin-bottom: 15px;
                }
                >ul {
                    grid-gap: 10px;
                }
            }
            .n-btn {
                margin-top: 38px;
                justify-content: flex-end;
                align-items: flex-end;
                font-weight: 400;
            }
        }
        .room-booking-option {
            .list ul {
                grid-gap: 15px;
            }
            display: grid;
            grid-template-columns: 2fr 1fr;
            padding: 20px;
            .details {
                margin-top: 0px;
                display: flex;
                flex-direction: column;
                >div,
                >small {
                    text-align: right;
                }
                .n-btn {
                    margin-top: 10px;
                }
            }
        }
    }
}


