:root {
    --help-top-position: 100px;
    background: $color-white;
}
    .cn__review__wrapper{
        position: absolute;
        width: 100%;
        overflow-x: unset;
        background: $color-white;
        .cn__review{
            h1{
                font-weight: 600;
                font-size: 28px;
                color: $color-pure-black;
                margin-top: 20px;
                margin-left: 50px;
            }
            p {
                font-weight: 400;
                font-size: 23px;
                color: $color-pure-black;
                max-width: 890px;
            }
            position: relative;
            width: 100%;
            margin-top: 69px;
            padding: 0 50px;
            max-width: 1440px;
            margin: 0 auto;
            padding-top: calc(89px + 50px);
            padding-bottom: 70px;
            .cn__review__headerpolicy {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 50px;
                h1 {
                     margin-right: 50px;
                     font-weight: 500;
                     font-size: 44px;
                }
                p {
                    margin-top: 30px;

                }
            }
            .cn__review__guestNester {
                .cn__review__guestNester__paragraph {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }
                    .cn__review__guestNester__box {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 40px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        ul {
                            margin-top: 20px;
                            max-width: 890px;
                            margin-left: 20px;
                            width: 100%;
                            padding-left: 35px;
                            padding-right: 35px;
                            padding-bottom: 35px;
                            li {
                                color: $color-slate-grey;
                                font-weight: 400;
                                font-size: 18px;
                                margin-top: 8px;
                            }
                        }
                        
                        .cn__review__guestNester__box__guest {
                            width: 421px;
                            border: 1px solid #E9E9EB;
                            box-shadow: 0px 4px 4px 0px #00000040;
                            border-radius: 15px;
                            .cn__review__guestNester__box__nester__detail__guesttitle {
                                color: $color-dark-grey;
                                font-weight: 400;
                                font-size: 16px;
                                padding-left: 25px;
                                padding-right: 25px;
                                padding-top: 15px;
                            }
                   
                            .cn__review__guestNester__box__guest__title{
                                background: $color-purple;
                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;
                                margin-bottom: 10px;
                                span {
                                    font-size: 28px;
                                    font-weight: 500;
                                    color: $color-white;
                                    margin-left: 20px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                }
                            }
                        }
                        .cn__review__guestNester__box__nester {
                            width: 421px;
                            border: 1px solid #E9E9EB;
                            box-shadow: 0px 4px 4px 0px #00000040;
                            border-radius: 15px;
                            .cn__review__guestNester__box__nester_titlecontainer {
                                padding-left: 25px;
                                padding-right: 25px;
                            }
                            .cn__review__guestNester__box__nester__detail__nestertitle {
                                color: $color-dark-grey;
                                font-weight: 400;
                                font-size: 16px;
                                padding-top: 15px;
                            }
                            .cn__review__guestNester__box__nester__title {
                                background: $color-primary;
                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;
                                margin-bottom: 10px;
                                span {
                                    font-size: 28px;
                                    font-weight: 500;
                                    color: $color-white;
                                    margin-left: 20px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }
            .cn__review__reviewguidline {
                margin-bottom: 30px;
                .cn__review__reviewguidline_paragraph {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        margin-top: 30px;
                    }
                    ul {
                        margin-top: 20px;
                        max-width: 890px;
                        margin-left: 20px;
                        width: 100%;
                        li {
                            color: $color-slate-grey;
                            font-weight: 400;
                            font-size: 18px;
                            margin-top: 6px;
                        }
                    }
                    .cn__review_reviewguidline__subtitle {
                        font-size: 23px;
                        font-weight: 500;
                        width: 100%;
                    }
            }
            }
            .cn__review__reviewmodefication {
                .cn__review__reviewmodefication__paragraph {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    p {
                        margin-top: 30px;
                        a {
                            color: $color-purple;
                            text-decoration: underline;
                        }
                    }
            }
            }
//--------------------------- TABLET ---------------------------//
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                padding: 0 25px;
                overflow: hidden;
                padding-top: 100px;
                p {
                    font-size: 18px;
                }
                .cn__review__headerpolicy {
                    h1 {
                        font-weight: 500;
                        font-size: 32px;
                        margin: 0px;
                    }
                    p {
                        margin-top: 10px;
                    }
                }
                .cn__review__guestNester{
                    h1 {
                        margin: 0px;
                    }
                    .cn__review__guestNester__paragraph{
                        margin-top: 10px;
                        p {
                            margin: 0px;
                            padding-left: 30px;
                            font-size: 18px;
                        }
                        .cn__review__guestNester__box {
                            
                            .cn__review__guestNester__box__guest{
                                width: 47%;
                            }
                            .cn__review__guestNester__box__nester{
                                width: 47%;
                            }
                        }
                    }
                }
                .cn__review__reviewguidline {
                    h1{
                        margin: 0px;
                        margin-bottom: 10px;
                    }
                    .cn__review__reviewguidline_paragraph {
                        ul {
                            margin: 0px;
                            margin-left: 40px;
                        }
                        p {
                            margin: 0px;
                            padding-left: 30px;
                        }
                        .cn__review_reviewguidline__subtitle{
                            margin-top: 15px;
                        }
                    }
                }
                .cn__review__reviewmodefication{
                    margin-bottom: 30px;
                    h1{
                        margin: 0px;
                    }
                    p{
                        margin-top: 5px;
                    }
                    .cn__review__reviewmodefication__paragraph {
                        padding-left: 20px;
                    }
                }
            }
//------------------------ MOBILE ------------------------//
            @media screen and (max-width: 660px) {
                padding: 0 15px;
                padding-top: 90px;
                overflow: hidden;
                h1{
                    font-weight: 600;
                    font-size: 18px;
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                }
                .cn__review__headerpolicy {
                    h1 {
                        font-weight: 700;
                        font-size: 18px;
                        margin: 0px;
                    }
                    p {
                        margin-top: 10px;
                    }
                }
                .cn__review__guestNester{
                    h1 {
                        margin: 0px;
                    }
                    .cn__review__guestNester__paragraph{
                        p {
                            margin: 0px;
                            padding-left: 10px;
                        }
                        .cn__review__guestNester__box {
                            justify-content: center;
                            ul {
                                li {
                                    font-size: 14px;
                                }
                            }
                            .cn__review__guestNester__box__guest{
                            width: 95%;
                            .cn__review__guestNester__box__nester__detail__guesttitle {
                                font-size: 14px;
                            }
                            }
                            .cn__review__guestNester__box__nester{
                                width: 95%;
                                .cn__review__guestNester__box__nester__detail__nestertitle{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .cn__review__reviewguidline {
                    h1{
                        margin: 0px;
                    }
                    .cn__review__reviewguidline_paragraph {
                        ul {
                            margin: 0px;
                            margin-left: 20px;
                            li {
                                font-size: 14px;
                            }
                        }
                        p {
                            margin: 0px;
                            padding-left: 10px;
                        }
                        .cn__review_reviewguidline__subtitle {
                            font-size: 16px; 
                            font-weight: 600;
                            margin-top: 10px;
                        }
                    }
                }
                .cn__review__reviewmodefication{
                    h1{
                        margin: 0px;
                    }
                    p{
                        margin-top: 5px;
                    }
                    .cn__review__reviewmodefication__paragraph {
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    .cn__review__wrapper .cn__review__desktop__info__block1__category{
        margin-top: 20px;
        visibility: hidden;
    }