.property-title {
    .property-title_product-title {
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.02em;
        font-weight: 600;
        overflow: hidden;
        line-clamp: 2;
        box-orient: vertical;
        display: flex;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (min-width: 1136px) {
            font-size: 32px;
            line-height: 36px;
            margin-bottom: 21px;
            padding-bottom: 0.10em;
        }
    }

    .property-title_sub-header {
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;

        .property-title_sub-header-main {
            display: grid;
            grid-template-columns: repeat(2, max-content);
           // align-items: center;
            // margin-right: 5px;
            // padding-right: 10px;
        }

        .property-title_sub-header-type {
            line-height: 21px;
            font-family: var(--primary-font);
            font-weight: 600;
            letter-spacing: .01em;
            line-height: 19px;
            white-space: nowrap;
            font-size: 14px;
        }

        .star-rate {
            margin-left: 5px;
            path {
                fill: var(--color-moderate-orange);
            }
        }

        .property-title_sub-header-additional {
            display: inline-block;
            color: var(--color-dark-grey);
            font-size: 14px;
        }
        .property-title_sub-header-link {
            opacity: 0;
            animation: appear .3s ease-in-out forwards;
            color: var(--color-purple);
            font-size: 14px;
            text-decoration: underline;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.01em;
            cursor: pointer;
            &.property-title_sub-header-link--regular {
                display: flex;
            }
        }
        .property-title_sub-header-separator{
            color: #ACABB4;
            margin: 0 10px;
            font-weight: 300;
        }
    }

    &.property-title--mobile {
        margin-top: 20px;
        padding-bottom: 0;

        .property-title_product-title {
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.01em;
            margin-bottom: 10px;
        }

        .property-title_sub-header {
            margin: 0;
            display: block;
            .property-title_sub-header-main {
                padding-right: 0;
                border-right: none;
            }
            .property-title_sub-header-additional,
            .property-title_sub-header-link {
                margin-top: 12px;
                border-left: none;
                padding-left: 0;
                display: block;
                white-space: initial;
            }
            .property-title_sub-header-link {
                width: max-content;
                margin-top: 24px;
            }


            display: grid;
            grid-template-columns: 1fr 1fr;

            .property-title_sub-header-additional{
                order: 3;
                width: 100%;
                grid-column: 1/3 ;
            }
            .property-title_sub-header-link{
                margin-top: 0;
                justify-self: flex-end;
            }

        }
    }
}
.property-title_sub-header-link_popper {
    &.MuiTooltip-popper {
        z-index: initial;
    }
}
.property-title_sub-header-link_tooltip {
    &.MuiTooltip-tooltip {
        pointer-events: all;
        user-select: none;
        background-color: var(--color-alice-blue);
        color: var(--color-slate-grey);
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        &.property-title_sub-header-link_tooltip--mobile {
            .tooltip-cta {
                display: flex;
                max-width: 136px;
                align-items: flex-start;
            }
        }
    }
}
.property-title_sub-header-link_tooltip-arrow {
    &.MuiTooltip-arrow {
        color: var(--color-alice-blue);
    }
}

//Tablet Breakpoint
@media (min-width:600px) and (max-width:1136px) {
    .property-title_sub-header {
        width: 90%;
        margin-bottom: 20px!important;
    }
}