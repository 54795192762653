.n-btn {
    font-family: $primary-font;
    border: 1px solid transparent;
    background: transparent;
    display: flex;
    font-style: normal;
    /* font-weight: 400;
    font-size: 16px;
    line-height: 20px; */
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    justify-content: flex-start;
    border-radius: 6px;
    padding: 8px 22px;
    color: var(--color-dark-grey);
    outline: none;
    cursor: pointer;
    .ic-icon {
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }
    .n-btn--loading {
        height: 20px;
    }
    &--disabled {
        // opacity: 0.3;
        color: var(--color-dark-grey);
        pointer-events: none;
    }
    &--full-width {
        width: 100%;
    }
    &--left-icon {
        flex-direction: row-reverse;
        .ic-icon {
            margin-left: 0px;
        }
    }
    &--nopadding {
        padding-left: 0px;
        padding-right: 0px;
    }
    &--space-between {
        justify-content: space-between;
    }
    /**
    * COLORS
    */
    &--color-black {
        color: var(--color-black);
    }
    &--color-bright-purple {
        color: var(--color-bright-purple);
    }
    &--color-fill-bright-purple {
        background: var(--color-bright-purple);
        color: var(--color-white);
    }
    &--color-vibrant-pink {
        color: var(--color-vibrant-pink);
    }
    &--color-cn-pink {
        color: var(--color-cn-pink);
    }
    &--color-fill-vibrant-pink {
        background: var(--color-vibrant-pink);
        color: var(--color-white);
    }
    &--color-purple {
        color: var(--color-purple);
    }
    &--color-fill-purple {
        color: var(--color-white);
        background: var(--color-purple);
    }
    &--color-shade-grey {
        color: var(--color-shade-grey);
    }
    &--color-fill-shade-grey {
        color: var(--color-black);
        background: var(--color-shade-grey);
    }
    &--color-subtle-grey {
        color: var(--color-subtle-grey);
    }
    &--color-fill-subtle-grey {
        color: var(--color-white);
        background: var(--color-subtle-grey);
    }
    &--color-shade-gray {
        color: var(--color-shade-gray);
    }
    &--color-fill-shade-gray {
        color: var(--color-black);
        background: var(--color-shade-gray);
    }
    &--color-fill-pink-red-gradient {
        color: var(--color-white);
        background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
    }
    &--color-cn-pink-violet {
        color: var(--color-white);
        background: linear-gradient(92deg, #F35597 1.81%, #7868C7 148.68%);
    }

    /**
    * Styles
    */
    &--adding-purple {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        flex-direction: row-reverse;
        padding: 8px 9px;
        color: var(--color-bright-purple);
        .ic-icon {
            margin-left: 0px;
            margin-right: 5px;
            path {
                stroke: var(--color-bright-purple);
            }
        }
    }
}