.n-clickable-wizard {
    .n-clickable-wizard_header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 28px;

        .n-clickable-wizard_slide-trigger {
            margin-top: 20px;
            margin-right: 20px;
            transition: .3s ease-in-out;
            background-color: var(--color-shade-grey);
            border-radius: 10px;
            padding: 10px 15px;

            &.n-clickable-wizard_slide-trigger--active {
                background-color: var(--color-light-grey);
                color: var(--color-black);
                font-weight: bold;

                .ic-icon {
                    path {
                        stroke: var(--color-black);
                        fill: var(--color-black);
                    }
                }
            }
        }
    }

    .n-clickable-wizard_content {
        overflow-x: hidden;
        margin-bottom: 50px;
    }
}