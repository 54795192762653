@import '../mixins/global-mixins.scss'; 

.plusminus-input { 
	background: transparent; 
	position: relative;
	padding: 0 2.375rem;
	height:2.375rem;
	@include primary-input();
	@media screen  and (max-width: 600px) {
		height: 3rem;
	}
	input {
		padding: 0 0;
		width: 100%;
		text-align: center;
		height: 2.25rem;
		background: transparent;
		@media screen  and (max-width: 600px) { 
			height: 2.875rem;
		}
		&[type=number] {
			-moz-appearance: textfield;
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
		&:focus {
			outline: none;
		}
	}
	button {
		position: absolute;
		top: 0;
		display: block;
		width: 2.125rem;
		height: 2.125rem;
		background: transparent;
		border: $color-transparent;
		outline: none;
		cursor: pointer;
		border-radius: 0.375rem; 
		@media screen  and (max-width: 600px) {
			border-radius: 0.5rem;
			height: 2.875rem !important;
			width: 2.875rem !important;
		}
		&:before{ 
			transition: ease-in-out 0.5s;
			background-color: #F2F1F2;
			border-radius: 100%;
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			visibility: hidden;
			width: 0%;
			height: 0%;
			transform: translate(-50%, -50%);  
		}
		&:hover::before{
			visibility: visible;
			width: calc(100% - (2px));
			height: calc(100% - (2px)); 
		}
		&:disabled:hover::before{
			visibility: hidden; 
		}
		svg { 
			display:inline-block;
			vertical-align: middle;
			position: relative; 
		}
		&.disabled {
			svg {
				path { 
					stroke: $color-subtle-gray;
				}
			} 
		}
		&:disabled {
			svg {
				path { 
					stroke: $color-subtle-gray;
				}
			} 
		}
		&.quantity-up {
			right: 0;
		}
		&.quantity-down {
			left: 0;
		}
	}
}