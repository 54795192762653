@import "./tools/mixins";
@import "../../settings/colors";

//------------------------ DESKTOP ------------------------//

.my_bookings__loader {
	height: 50vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.booking__notexist {
	display: flex;
    flex-direction: column;
    place-items: center;
	.booking__notexist__hero {
		color: $color-pure-black;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 150%; 
		letter-spacing: 0.16px;
		margin-top: 20px;
		text-align: center;
	}
	.booking__notexist__subtitle {
		color: $color-pure-black;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: 0.14px;
		margin-top: 11px;
		margin-bottom: 40px;
		text-align: center;
		@media screen and (max-width: 680px) {
			width: 400px;
		}
	}
	a {
		width: 80%;
		height: 45px;
		border-radius: 20px;
		padding: 8px 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		cursor: pointer;
		background: linear-gradient(92.37deg, $color-primary 1.81%, $color-purple 148.68%);
		color: $color-light-gray-shade;
		p {
			font-size: 16px;
			font-weight: 600;
			line-height: 150%; 
			letter-spacing: 0.16px;
		}
	}
}

.cn__booking__details {
	position: relative;
	width: 96%;
	background: #ffffff;
	border: 0.069vw solid #dfe2e5;
	box-shadow: 0 0.347vw 2.084vw rgba(231, 228, 228, 0.5);
	border-radius: 1.042vw;
	padding: 1.389vw;
	margin-inline: 0%;

	&__pageTitle {
		display: flex;
		justify-content: space-between;
		padding-left: 6%;
		margin-bottom: 10px;
		width: 100%;
		&__text {
			display: flex;
			h2 {
				margin-left: 25px;
				font-size: 12px;
				font-weight: 600;
			}
			svg {
				margin-top: 5px;
				cursor: pointer;
			}
		}
		&__closeIcon {
			height: 15px;
			width: 15px;
		}

		@media screen and (max-width: 680px) {
			display: none;
		}
	}

	&__header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 0.833vw;
		border-bottom: 0.069vw solid #ededed;

		&__title {
			font-weight: 600;
			font-size: 1.251vw;
			line-height: 1;
			color: #242526;
		}

		svg {
			width: 0.833vw;
			height: auto;
			cursor: pointer;
		}
	}

	&__topbanner {
		position: relative;
		margin-top: 2.084vw;
		padding: 0.694vw 1.042vw;
		background: #6fcf97;
		border-radius: 0.694vw;
		width: 100%;
		display: flex;
		align-items: flex-start;

		svg {
			width: 3.334vw;
			height: auto;
			margin-right: 1.042vw;
			flex-shrink: 0;
		}

		&__info {
			position: relative;
			font-size: 1.112vw;
			line-height: 1.5;
			letter-spacing: 0.01em;
			color: #ffffff;

			&__title {
				font-weight: 600;
			}

			&__subtitle {
				position: relative;
			}
		}
	}

	&__guestdetails {
		position: relative;
		background: #ffffff;
		border: 0.069vw solid #dfe2e5;
		box-shadow: 0 0.347vw 2.084vw rgba(231, 228, 228, 0.5);
		border-radius: 1.042vw;
		margin-top: 0.694vw;
		padding: 1.389vw;

		&__title {
			font-weight: 600;
			font-size: 1.251vw;
			line-height: 1;
		}

		&__info {
			position: relative;
			margin-top: 2.084vw;

			&__guest {
				font-size: 0.972vw;
				line-height: 1;
				letter-spacing: 0.01em;
				margin-top: 1.042vw;
			}
		}
	}

	&__messageButton {
		border-radius: 20px;
		width: 70%;
		padding-inline: 32px;
		padding-block: 12px;
		border: none;
		background-color: $color-primary;
		color: $color-white;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		text-align: left;
		display: flex;
		flex-wrap: nowrap;
		// padding-right: 0px;

		&__text {
			padding-left: 28px;
		}
	}

	&__reviewButton {
		border-radius: 20px;
		width: 70%;
		padding-inline: 32px;
		padding-block: 12px;
		border: none;
		background-color: #7868c7;
		color: $color-white;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		text-align: left;
		display: flex;
		flex-wrap: nowrap;
		// padding-right: 0px;

		&__text {
			padding-left: 28px;
		}
	}

	&__icons {
		path {
			fill: #eeeef0;
		}
	}

	&__cancelButton {
		border-radius: 20px;
		width: 70%;
		padding-inline: 32px;
		padding-block: 12px;
		border: none;
		background-color: #d83232;
		color: $color-white;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		text-align: left;
		display: flex;
		// padding-right: 0px;

		&__text {
			padding-left: 28px;
		}
	}

	.cn__booking__details__messageButton,
	.cn__booking__details__cancelButton,
	.cn__booking__details__reviewButton {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		/* identical to box height, or 24px */
		margin:5px auto;
		text-align: center;
		letter-spacing: 0.01em;

	color: #f7f7f7;
	}

	&__mobile {
		&__buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 21px;
			padding-block: 16px;
			padding-inline: 11px;
			@media screen and (max-width: 680px) {
				flex-direction: column;
			}
			&__breadcrumbs {
				@media screen and (max-width: 680px) {
					display: none !important;
				}
			}
			.cn__booking__details__messageButton,
			.cn__booking__details__cancelButton,
			.cn__booking__details__reviewButton {
				@media screen and (max-width: 680px) {
					width: stretch;
					max-width: 90%;
					margin-inline: auto;
				}
				width: 165px;
				height: 45px;
				font-size: 12px;
				padding-inline: 15px;
				text-align: left;
				display: flex;
				align-items: center;
				justify-content: center;

				&__text {
					padding-left: 8px;
				}
			}
		}
	}

	&__payment {
		padding: 33px 15px 15px 15px;

		&__title {
			font-weight: 600;
			font-size: 18px;
			color: #000000;
		}

		&__lists {
			position: relative;
			margin-top: 20px;
		}

		&__list {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 400;
			font-size: 16px;
			color: #000000;
			margin-top: 15px;
			z-index: 1;
			&__paragraph {
				font-size: 14px;
				margin-left: 20px;
				margin-top: 5px;
				font-weight: 200;
			}
			&__info {
				// width: 80%;
				display: flex;
				align-items: center;

				&__title {
					margin-right: 0.694vw;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					color: #64748b;
					font-weight: 600;
				}
				&__more {
					position: relative;
					display: inline-block;
					margin-left: 5px;

					svg {
						width: 16px;
						@media screen and (max-width: 600px) {
							width: unset;
						}
						height: auto;
					}
					&:hover {
							p {
								opacity: 1 !important;
								visibility: unset !important;
								@media screen and (max-width: 600px) {
									margin-left: 10px;
								}
							}
					}
					&__info {
						position: absolute;
						top: 50%;
						left: 2.431vw;
						transform: translateY(-50%);
						width: 14vw;
						background: #e9e9eb;
						border-radius: 0.347vw;
						padding: 0.347vw 0.694vw;
						font-size: 0.694vw;
						line-height: 1.5;
						text-align: center;
						letter-spacing: 0.01em;
						color: #797882;
						opacity: 0;
						visibility: hidden;
						transition: visibility 1s ease, opacity 0.3s ease, left 0.6s ease;
						@media screen and (max-width: 600px) {
							width: 200px;
							font-size: 12px;
						}
						&::after {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -0.347vw;
							content: "";
							width: 0;
							height: 0;
							border-right: solid 0.347vw #e9e9eb;
							border-bottom: solid 0.347vw transparent;
							border-top: solid 0.347vw transparent;
						}
					}

					svg {
						cursor: pointer;
					}

					svg:hover
						+ .cn__checkout__bookingdetails__payment__list__info__more__info {
						opacity: 1;
						left: 1.736vw;
						visibility: visible;
					}
				}
			}

			&__total {
				color: #64748b;
				font-weight: 600;
			}
		}

		&__divider {
			position: relative;
			width: 100%;
			height: 0.069vw;
			background: #e9e9eb;
			margin: 1.389vw 0 2.084vw;
		}

		&__extra {
			position: relative;
			margin-top: 2.084vw;

			&__title {
				line-height: 1;
				letter-spacing: 0.01em;
				color: #64748b;
				font-weight: 600;
			}

			&__info {
				font-weight: 300;
				font-size: 15px;
				line-height: 1.5;
				gap: 2vw;
				text-transform: capitalize;
				letter-spacing: 0.01em;
				margin-top: 15px;
				display: flex;
				justify-content: space-between;

				* {
					list-style-type: none;
				}
				// p:nth-child(2) {
				// list-style-type:disc;
				// display:list-item;
				// }

				p {
					span {
						font-size: 0.85vw;
						font-style: italic;
					}
				}
			}
		}
	}

	&__payment {
		position: relative;
		background: #ffffff;
		margin-top: 20px;
		padding-bottom: 0.694vw;
		padding-top: unset !important;
		border-radius: 20px;
		width: unset !important;
		left: 0 !important;
		border: none !important;

		&__container {
			&__receipt {
				hr {
					border: 0.5px solid #e9e9eb;
				}
				.cn__dashboard_cancellation_reason_modal_body_reciept-button {
					margin-inline: auto;
				}
			}
		}

		@media screen and (min-width: 1100px) {
			&__container {
				&__receipt {
					hr {
						border: 0.5px solid #e9e9eb;
					}
					padding: 20px;
					.cn__dashboard_cancellation_reason_modal_body_reciept-button {
						margin-inline: 11px;
						width: -moz-stretch; /* WebKit-based browsers will ignore this. */
						width: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
						width: stretch;
						align-self: center;
					}
				}
			}
		}

		@media screen and (min-width: 660px) {
			&__container {
				width: 39%;
				flex: 1;
				margin-top: 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				align-content: center;
				gap: 10px;
			}

			&__details {
				height: auto;
				border: 0.069vw solid #dfe2e5;
				border-radius: 1.042vw;
				width: 100%;
			}
		}

		&__bottom {
			position: relative;
			width: calc(100% - (1.389vw * 2));
			left: 1.389vw;
			margin-top: 1.389vw;
			border-top: 0.069vw solid #e9e9eb;
			display: flex;
			justify-content: center;
		}
	}
	&__freecancellation {
		position: relative;
		background: #ffffff;
		border: 0.5px solid #2B8A4B;
		box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
		border-radius: 8px;
		margin-top: 0.694vw;
		min-height: 35px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 12px;
		padding-right: 20px;
		&__title {
			color: #000000;
			font-weight: 300;
			span {
				font-weight: 600;
			}
		}
		&__cancellationtype {
			gap: 2px;
			color: #2B8A4B;
			font-weight: 600;
			align-items: center;
			gap: 10px;
			display: flex;
			margin-left: 19px;
    		min-width: 141px;
		}
	}
	&__nonrefundable {
		position: relative;
		background: #ffffff;
		border: 0.5px solid #F30C78;
		box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
		border-radius: 8px;
		margin-top: 0.694vw;
		min-height: 35px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 12px;
		padding-right: 20px;
		&__title {
			color: #000000;
			font-weight: 300;
			span {
				font-weight: 600;
			}
		}
		&__cancellationtype {
			gap: 2px;
			color: #F30C78;
			font-weight: 600;
			align-items: center;
			gap: 10px;
			display: flex; 
			margin-left: 19px;
    		min-width: 141px;
			svg {
				path {
					fill: #F30C78;
				}
			}
		}
	}
	&__partialrefundable {
		position: relative;
		background: #ffffff;
		border: 0.5px solid #EFB008;
		box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
		border-radius: 8px;
		margin-top: 0.694vw;
		min-height: 35px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 12px;
		padding-right: 20px;
		&__title {
			color: #000000;
			font-weight: 300;
			span {
				font-weight: 600;
			}
		}
		&__cancellationtype {
			gap: 2px;
			color: #EFB008;
			font-weight: 600;
			align-items: center;
			gap: 10px;
			display: flex;
			margin-left: 19px;
    		min-width: 141px;
			svg {
				path {
					fill: #EFB008;
				}
			}
		}
	}
	&__location {
		position: relative;
		background: #ffffff;
		border: 0.069vw solid #dfe2e5;
		box-shadow: 0 0.347vw 2.084vw rgba(231, 228, 228, 0.5);
		border-radius: 1.042vw;
		margin-top: 0.694vw;
		padding: 1.389vw;

		&__title {
			font-weight: 600;
			font-size: 18px;
			line-height: 1;
		}

		&__subtitle {
			position: relative;
			margin-top: 1.389vw;
			display: flex;
			align-items: center;
			font-size: 12px;
			line-height: 1;
			letter-spacing: 0.01em;
			color: #797882;

			&__divider {
				position: relative;
				width: 0.069vw;
				height: 0.972vw;
				background: #acabb4;
				margin: 0 0.833vw;
			}

			&__right {
				display: flex;
				align-items: center;

				svg {
					width: 0.694vw;
					height: auto;
					margin-right: 0.694vw;
				}
			}
		}

		&__map {
			position: relative;
			width: 100%;
			border-radius: 0.694vw;
			height: 14.381vw;
			background: #dfe2e5;
			margin-top: 1.389vw;
			min-height: unset;

			&__receipt_map {
				min-height: 14.381vw;
			}
		}

		&__buttons {
			position: relative;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 0.694vw;
			margin-top: 2.084vw;

			&__button {
				position: relative;
				display: flex;
				align-items: center;
				background: #ffffff;
				border: 0.069vw solid #dfe2e5;
				border-radius: 2.779vw;
				justify-content: center;
				padding: 0.694vw 0;
				font-size: 14px;
				letter-spacing: 0.01em;
				color: #000000;
				cursor: pointer;

				svg {
					height: 1.112vw;
					width: auto;
					margin-right: 0.694vw;
				}
			}
		}
	}

	&__bottombuttons {
		position: relative;
		background: #ffffff;
		border: 0.069vw solid #dfe2e5;
		box-shadow: 0 0.347vw 2.084vw rgba(231, 228, 228, 0.5);
		border-radius: 1.042vw;
		margin-top: 0.694vw;
		padding: 1.389vw;

		&__button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			&__info {
				display: flex;
				align-items: center;

				svg {
					width: 1.112vw;
					height: auto;
					margin-right: 1.389vw;
				}

				&__title {
					font-size: 1.112vw;
					line-height: 1;
					letter-spacing: 0.01em;
				}

				&__loader {
					color: var(--color-white);
					margin-right: 1.389vw;

					svg {
						width: 1.112vw;
						height: auto;
					}
				}
			}
		}

		&__divider {
			position: relative;
			width: 100%;
			height: 0.069vw;
			background: #e9e9eb;
			margin: 1.389vw 0;
		}
	}
	@media screen and (min-width: 660px) {
		margin-inline: 2%;

		&__mobile {
			&__buttons {
				width: 100%;
				.cn__booking__details__messageButton,
				.cn__booking__details__cancelButton {
					font-size: 12px;
					width: 165px;
					height: 45px;
				}
			}
		}

		&__pageTitle {
			padding-left: 0;
			margin-bottom: 0px;
			h2 {
				margin-left: 25px;
				font-size: 18px;
				font-weight: 600;
			}
			svg {
				margin-top: 5px;
				cursor: pointer;
			}
		}

		&__flexContainer {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		&__container {
			width: 100%;
		}

		// .cn__booking__details__messageButton,
		// .cn__booking__details__cancelButton,
		// .cn__booking__details__reviewButton {
		// 	font-size: 14px;
		// }
	}

	@media screen and (min-width: 1160px) {
		margin-inline: 2%;

		&__pageTitle {
			padding-left: 0;
			margin-bottom: 0px;
			h2 {
				margin-left: 25px;
				font-size: 18px;
				font-weight: 600;
			}
			svg {
				margin-top: 5px;
				cursor: pointer;
			}
		}

		&__flexContainer {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 10px;
		}

		&__container {
			width: 60%;
		}
	}
}

@media screen and (min-width: 1001px) {
	.cn__booking__details__mobile__buttons {
		padding-inline: 28px;
		justify-content: flex-start;
	}
}

//------------------------ TABLET ------------------------//

@media screen and (min-width: 600px) and (max-width: 1000px) {
	.cn__booking__details__primary_container {
		padding-inline: 97.5px;

		.cn__booking__details {
			margin-inline: 0;
			width: 100%;
		}
		.cn__booking__details__mobile__buttons {
			padding-block: 16px !important;
		}
	}
}

//------------------------ MOBILE ------------------------//
@media screen and (max-width: 660px) {
	.cn__booking__details {
		position: relative;
		width: 100%;
		max-width: unset;
		margin: 0 auto;
		background: #ffffff;
		border: none;
		box-shadow: unset;
		border-radius: 0;
		padding: 0;

		&__header {
			padding: 6.5vw 20px;
			border-bottom: 0.266vw solid #ededed;
			box-shadow: 0px 1.334vw 3.2vw -1.334vw rgba(24, 39, 75, 0.06);

			&__title {
				font-size: 4.8vw;
			}

			svg {
				width: 2.67vw;
			}
		}

		&__topbanner {
			margin-top: 5.334vw;
			padding: 2.67vw 4vw;
			border-radius: 2.67vw;
			width: calc(100% - 40px);
			left: 20px;
			align-items: center;

			svg {
				width: 12.8vw;
				margin-right: 2.67vw;
			}

			&__info {
				font-size: 3.74vw;
			}
		}

		&__guestdetails {
			width: calc(100% - 40px);
			left: 20px;
			border: 0.266vw solid #dfe2e5;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 4vw;
			margin-top: 5.334vw;
			padding: 5.334vw;

			&__title {
				font-size: 4.8vw;
			}

			&__info {
				margin-top: 8vw;

				&__guest {
					font-size: 3.74vw;
					margin-top: 4vw;
				}
			}
		}

		&__payment {
			background: #ffffff;
			width: calc(100% - 40px);
			left: 20px;
			border: 0.266vw solid #dfe2e5;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 4vw;
			margin-top: 5.334vw;
			padding-bottom: 5.334vw;
			&__container {
				&__receipt {
					margin-bottom: 25px;
				}
			}
			&__bottom {
				width: calc(100% - (5.334vw * 2));
				left: 5.334vw;
				margin-top: 5.334vw;
				border-top: 0.266vw solid #e9e9eb;

				&__button {
					margin-top: 2.67vw;
					margin-bottom: 5.334vw;
					padding: 2.67vw 6.252vw;
					border: 0.266vw solid #dfe2e5;
					border-radius: 10.666vw;
					width: 100%;
					justify-content: center;

					svg {
						width: 3.2vw;
						margin-right: 4.27vw;
					}

					&__title {
						font-size: 3.74vw;
					}
				}
			}
		}
		&__freecancellation {
			width: calc(100% - 40px);
			left: 20px;
			border: 0.5px solid #2B8A4B;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 8px;
			margin-top: 5.334vw;
			padding: 5.334vw;
			font-size: 14px;
			flex-direction: column;
			align-items: unset;
			gap: 5px;
			&__cancellationtype { 
				margin-left: unset;
			}
		}
		&__partialrefundable {
			width: calc(100% - 40px);
			left: 20px;
			border: 0.5px solid #EFB008;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 8px;
			margin-top: 5.334vw;
			padding: 5.334vw;
			font-size: 14px;
			flex-direction: column;
			align-items: unset;
			gap: 5px;
			&__cancellationtype { 
				margin-left: unset;
			}
		}
		&__nonrefundable {
			width: calc(100% - 40px);
			left: 20px;
			border: 0.5px solid #F30C78;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 8px;
			margin-top: 5.334vw;
			padding: 5.334vw;
			font-size: 14px;
			flex-direction: column;
			align-items: unset;
			gap: 5px;
			&__cancellationtype { 
				margin-left: unset;
			}
		}
		&__location {
			width: calc(100% - 40px);
			left: 20px;
			border: 0.266vw solid #dfe2e5;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 4vw;
			margin-top: 5.334vw;
			padding: 5.334vw;

			&__title {
				font-size: 4.8vw;
			}

			&__subtitle {
				margin-top: 4vw;
				display: block;
				font-size: 3.2vw;

				&__divider {
					display: none;
				}

				&__right {
					margin-top: 2.67vw;

					svg {
						width: 2.67vw;
						height: auto;
						margin-right: 1.334vw;
					}
				}
			}

			&__map {
				border-radius: 4.27vw;
				height: 62.4vw;
				margin-top: 4.27vw;
				&__receipt_map {
					min-height: 62.4vw;
				}
			}

			&__buttons {
				grid-column-gap: 1.334vw;
				margin-top: 8vw;

				&__button {
					border: 0.266vw solid #dfe2e5;
					border-radius: 10.666vw;
					padding: 2.67vw 0;
					font-size: 3.74vw;

					svg {
						height: 4.27vw;
						margin-right: 2.67vw;
					}
				}
			}
		}

		&__bottombuttons {
			width: calc(100% - 40px);
			border: 0.266vw solid #dfe2e5;
			box-shadow: 0 1.334vw 8vw rgba(231, 228, 228, 0.5);
			border-radius: 4vw;
			margin: 5.334vw auto;
			padding: 5.334vw;

			&__button {
				&__info {
					svg {
						width: 4.27vw;
						margin-right: 5.334vw;
					}

					&__title {
						font-size: 4.27vw;
					}
					&__title2 {
						color: #64748b;
					}
					&__loader {
						color: var(--color-white);
						margin-right: 5.334vw;
						svg {
							width: 4.27vw;
							height: auto;
						}
					}
				}
			}

			&__divider {
				height: 0.266vw;
				margin: 5.334vw 0;
			}
		}
	}
}

//------------------------ MOBILE ------------------------//
@include mobile {
	.cn__booking__details__mobile__buttons__breadcrumbs {
		display: none !important;
	}
	.cn__checkout__bookingdetails {
		position: relative;
		width: 100%;
		top: 0;
		display: table;
		margin-top: 20px;
		padding: 0;
		background: #ffffff;
		border: none;
		box-shadow: none;
		border-radius: 0;

		&__card {
			margin-top: 5.334vw;
			background: unset;
			border: none;
			border-radius: 0;
			display: block;
			overflow: unset;

			&__img {
				width: 100%;
				height: 62.934vw;
				margin-top: 5.334vw;

				&__box {
					top: 4vw;
					left: 20px;

					&__rate {
						font-size: 4.27vw;
						padding: 2.67vw;
						border-radius: 2.67vw 2.67vw 2.67vw 1.334vw;
					}

					&__info {
						margin-left: 2.67vw;

						&__type {
							font-size: 4.27vw;
						}

						&__reviews {
							font-size: 3.74vw;
							margin-top: 1.334vw;
						}
					}
				}
			}

			&__info {
				padding: 15px 30px 20px;
				width: 100%;

				&__title {
					font-size: 4.27vw;
					padding: 0 20px;
				}

				&__type {
					font-size: 3.74vw;
					margin-top: 4vw;
					padding: 0 20px;
				}

				&__typerooms {
					font-size: 3.74vw;
					margin-top: 4vw;
					padding: 0 20px;

					&__title {
						margin-right: 1.334vw;
					}

					svg {
						margin-right: 0.534vw;
						width: 3.2vw;
						height: auto;
					}
				}

				&__dates {
					margin-top: 4vw;
					padding: 0 20px;

					&__opt {
						&:first-child {
							svg {
								width: 3.2vw;
								height: auto;
							}
						}
						&:last-child {
							svg {
								width: 4.27vw;
								height: auto;
							}
						}
						&__title {
							margin-left: 1.6vw;
							font-size: 3.74vw;
						}
					}

					&__divider {
						width: 0.267vw;
						height: 3.74vw;
						margin: 0 4vw;
					}
				}

				&__confirmation {
					margin-top: 5.334vw;
					font-size: 4.27vw;
					padding: 0 20px;
					justify-content: space-between;

					&__wrapper {
						display: flex;
						align-items: center;
					}

					&__code {
						margin-left: 8vw;
					}

					svg {
						width: 3.74vw;
						margin-left: 4vw;
						flex-shrink: 0;
					}
				}

				&__time {
					margin-top: 5.334vw;
					padding: 0 20px;

					&__opt {
						svg {
							width: 3.74vw;
							height: auto;
						}

						&__title {
							margin-left: 1.6vw;
							font-size: 3.2vw;
						}
					}

					&__divider {
						width: 0.267vw;
						height: 3.74vw;
						margin: 0 2.67vw;
					}
				}

				&__change {
					margin-top: 6.134vw;
					justify-content: flex-start;
					padding: 0 20px;

					&__title {
						font-size: 3.74vw;
						margin-right: 1.334vw;
					}

					svg {
						width: 1.867vw;
						height: auto;
					}
				}
			}
		}

		&__standard,
		&__deluxe {
			position: relative;
			width: calc(100% - 40px);
			left: 20px;
			margin-top: 5.334vw;
			padding: 5.334vw;
			background: #ffffff;
			border: 0.134vw solid #dfe2e5;
			box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
			border-radius: 4vw;

			&__titlebox {
				&__title {
					font-size: 4.8vw;
				}
				&__title2 {
					color: #64748b;
				}

				&__amenity {
					border-radius: 4.8vw;
					padding: 1.334vw 2.67vw;
					font-size: 2.67vw;

					svg {
						width: 2.67vw;
						height: auto;
					}

					&__title {
						margin-left: 1.334vw;
					}
				}
			}

			&__amenityinfo1,
			&__amenityinfo2 {
				margin-top: 8vw;

				&__amenity {
					font-size: 3.74vw;

					svg,
					img {
						width: 4.27vw;
						height: 4.27vw;
					}

					&__title {
						margin-left: 2.67vw;
					}
				}

				&__divider {
					width: 0.267vw;
					height: 2.74vw;
					margin: 0 2.67vw;
				}
			}

			&__form {
				margin-top: 5.334vw;

				&__title {
					font-size: 3.74vw;
				}
			}

			&__amenityinfo2 {
				display: block;

				&__amenity {
					margin-top: 4.27vw;
				}

				&__divider {
					display: none;
				}
			}
		}

		&__payment {
			position: relative;
			width: calc(100% - 40px);
			left: 20px;
			padding: 5.334vw;
			background: #ffffff;
			border: 0.134vw solid #dfe2e5;
			box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
			border-radius: 4vw;
			margin-top: 5.334vw;

			&__title {
				font-size: 4.8vw;
			}

			&__lists {
				margin-top: 8vw;
			}

			&__list {
				font-size: 4.27vw;
				margin-top: 4vw;

				&__info {
					&__title {
						margin-right: 2.67vw;
					}
					&__more {
						&__info {
							left: 9.334vw;
							width: 40vw;
							border-radius: 1.334vw;
							padding: 1.334vw 2.67vw;
							font-size: 2.67vw;

							&::after {
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: -1.334vw;
								content: "";
								width: 0;
								height: 0;
								border-right: solid 1.334vw #e9e9eb;
								border-bottom: solid 1.334vw transparent;
								border-top: solid 1.334vw transparent;
							}
						}

						svg {
							width: 3.467vw;
							height: auto;
						}

						svg:hover
							+ .cn__checkout__bookingdetails__payment__list__info__more__info {
							opacity: 1;
							left: 6.667vw;
							visibility: visible;
						}
					}
				}
			}

			&__extra {
				margin-top: 8vw;

				&__title {
					font-size: 4.27vw;
				}

				&__info {
					font-size: 4.27vw;
					margin-top: 4vw;
					p {
						span {
							font-size: 2.13vw;
						}
					}
				}
			}

			&__divider {
				height: 0.267vw;
				margin: 5.334vw 0 8vw;
			}
		}
	}
}
