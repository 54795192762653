@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp4{
    position: relative;
    width: 100%;
    background: var(--color-background);
    padding: dvw(150) 0;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__box{
        position: relative;
        display: grid;
        grid-template-columns: 24% 76%;

        &__sec1{
            position: relative;
            &__info{
                position: sticky;
                top: 35vh;

                &__title{
                    font-weight: 700;
                    font-size: dvw(54);
                    line-height: 1.1;
                    color: #000000;
                }
            }
        }
        &__sec2{
            position: relative;
            display: grid;
            grid-template-columns: repeat(3,minmax(0,1fr));
            gap: dvw(20);
        }
    }
}


@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp4{
        padding: 150px 0;
    
        &__box{
            position: relative;
            display: grid;
            grid-template-columns: 24% 76%;
    
            &__sec1{
                &__info{
                    &__title{
                        font-size: 54px;
                    }
                }
            }
            &__sec2{
                gap: 20px;
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp4{

        &__wrapper{
            padding: 0 30px;
        }

        &__box{
            position: relative;
            display: grid;
            grid-template-columns: 24% 76%;

            &__sec1{
                position: relative;
                &__info{
                    position: sticky;
                    top: 35vh;

                    &__title{
                        font-weight: 700;
                        font-size: 4.1vw;
                        line-height: 1.1;
                        color: #000000;
                    }
                }
            }
            &__sec2{
                position: relative;
                display: grid;
                grid-template-columns: repeat(3,minmax(0,1fr));
                gap: 1.3vw;
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp4{
        padding: 0 0 6px;

        &__wrapper{
            padding: 0;
        }

        &__box{
            position: relative;
            display: block;

            &__sec1{
                &__info{    
                    &__title{
                        font-size: mvw(32);
                        line-height: 1;
                        padding: 0 20px;
                    }
                }
            }

            .cn__slider{
                position: relative;
                margin-top: mvw(20);
    
                .cn__slider__cards{
                    overflow-x: unset;
                    padding: 5px 20px mvw(60) 30px;
                }
                .simplebar-track{
                    display: none;
                }
            }
        }
    }
}