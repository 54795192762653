@import '/src/styles/settings/colors';
@import '/src/styles/generic/global/font.scss';

.snack-action-btn {
    appearance: none;
    background-color: unset;
    border: none;
    color: #8f7bf3;
    // color: #7869C7;
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    // padding: 4px;

    &:hover {
        cursor: pointer;
    }
}