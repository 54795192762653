.cn__cnt__slider__slider{
    .slick-list{
        overflow: hidden;
        width: calc(100% + 20px);
        margin-left: -10px;
    }
    .slick-track{
        padding: 25px 0 30px;
    }
    .slick-arrow{
        background-color: transparent;
        transform-origin: center center;
        border: 3px solid rgba(121, 120, 130, 0.6);;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg{
            height: 14px;
            path{
                stroke: rgba(121, 120, 130, 0.6);;
                stroke-width: 4px;
            }
        }
        &.slick-next{
            position: absolute;
            right: 0;
            display: inline-flex;
        }
        &.slick-prev{
            position: absolute;
            right: 55px;
            display: inline-flex;
            left: auto;
            svg{
                margin-left: -4px;
            }
        }
    }
    .slick-slide{
        padding: 0 10px;
    }
}