.breadcrumbs {
    z-index: 1;
    margin-left: 6px;
}

.breadcrumbs ul {
    list-style-type: none;
    li {
        float: left;
        padding: 0 0.375rem 0 0;
        &:after {
            content: '\003E';
            margin: 0 0 0 0.375rem;
            top: 1px;
            position: relative;
        }
        &:last-child {
            padding-right: 0;
        }
        &:last-child::after {
            display: none;
        }
        a {
            font-family: $primary-font;
            /* font-style: normal;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: 0.03em;
            font-variant: all-small-caps;
            color: var(--color-dark-grey); */
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: #242526;
            font-feature-settings: unset;
            font-variant: unset;
        }
    }
}