/* //------------------- DESKTOP / TABLET -------------------

.cn__message{
    position: fixed;
    width: 100%;
    max-width: 300px;
    top: 30px;
    right: -100vw;
    background: #FFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 20px 15px;
    z-index: 100;
    border: solid 1px rgba(0,0,0,0.09);
    transition: all 0.6s ease;
    p{
        pointer-events: none;
        &.cn__message__title{
            font-size: 14px;
            font-weight: bold;
        }
        &.cn__message__subtitle{
            font-size: 12px;
            line-height: 1.3;
            font-weight: normal;
            margin-top: 8px;
        }
    }
    &__close{
        position: absolute;
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
        display: grid;
        place-items: center;
        top: -10px;
        left: -10px;
        cursor: pointer;
        opacity: 0;
        border: solid 1px rgba(0,0,0,0.09);
        transition: opacity 0.4s ease;
        img{
            width: 8px;
        }
    }
    &:hover{
        .cn__message__close{
            opacity: 1;
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__message{
        width: calc(100% - 40px);
        max-width: unset;
        top: 20px;
    }  
} */





//------------------- DESKTOP / TABLET -------------------

.cn__message{
    position: relative;
    background: #E8EFF9;
    border: 1px solid #64748B;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    max-width: 550px;

    p{
        pointer-events: none;
        &.cn__message__title{
            font-weight: 600 !important;
            font-size: 23px !important;
            line-height: 1.2 !important;
            text-align: center;
            color: #E76B74 !important;
            width: auto !important;
        }
        &.cn__message__subtitle{
            font-size: 16px !important;
            line-height: 1.5 !important;
            text-align: center;
            letter-spacing: 0.01em !important;
            color: #64748B !important;
            margin-top: 20px;
            width: auto !important;
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__message{
        p{
            pointer-events: none;
            &.cn__message__title{
                font-size: 14px !important;
                letter-spacing: 0.01em !important;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
            &.cn__message__subtitle{
                font-size: 12px !important;
                margin-top: 10px !important;
                margin-bottom: 0 !important;
            }
        }
    }  
}