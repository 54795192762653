
.date-picker-dates {
    position: relative;
    display: flex;
    gap: 0.25em;
    padding: 0.5em 0;
    font-size: 1.125rem;

    .date-picker-arrow {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .date-picker-date {
        position: relative;
        transition: border-bottom 0.2s ease-in-out;

        .date-picker-date-btn {
            background: none;
            padding: 0.125em;
            width: 7em;
            height: unset;
            flex: 0 0 auto;
            color: var(--color-black);
            // color: var(--color-subtle-grey);
            // color: var(--color-field-grey);
            font-family: inherit;
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            // line-height: 150%;
        }

        &::before {
            content: '';
            position: absolute;
            width: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            height: 2px;
            background: var(--color-vibrant-pink);
            border-radius: 1px;
            transition: width 0.2s ease;
        }

        &--focused {
            &::before {
                width: 80%;
            }
        }

        &--filled {
            .date-picker-date-btn {
                color: var(--color-black);
            }
        }

        &:hover {
            .date-picker-date-btn {
                cursor: pointer;

                &:disabled {
                    cursor: default;
                }
            }
        }
    }
}