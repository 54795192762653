.cn__gp__values {
  margin: 40px auto 100px;
  &__main-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    text-align: center;

    &__value {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__img-section {
        width: 64px;
        height: 64px;

        text-align: center;
      }
      &__title {
        font-size: 16px;
        margin-top: 15px;
        line-height: 16px;
        font-weight: 500;
        text-align: center;
      }
      &__description {
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        margin-top: 5px;
      }
    }
  }
}

//-------------- TABLET --------------

@media screen and (max-width:1136px){
  .cn__gp__values {
    width: calc(100% - 60px);
    margin: 50px auto 100px;
  }
}