.search-input {
    // cursor: initial;
    width: 100%;
    // box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
    // border-radius: rem(6);
    // padding-top: rem(20);
    // padding-bottom: rem(20);
    box-shadow: none;
    border: none;
    font-family: $primary-font;
    font-weight: 400;
    font-size: rem(16);
    .search-input_container {
        position: relative;
        // margin-left: rem(20);
        // margin-right: rem(20);
        margin: auto;
        max-width: rem(330);
        .search-icon, .close-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        .search-icon {
            left: rem(10);
        }
        .close-icon {
            right: rem(15);
            cursor: pointer;
        }
        .search_input {
            transition: .3s;
            width: 100%;
            background-color: #E9E9EB;
            outline: none;
            border-radius: rem(6);
            padding-right: rem(30);
            padding-left: rem(30);
            color: $color-black;
            box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, .2);
            border: rem(1) solid #E9E9EB;
            &:not([value='']) {
                background-color: $color-white;
            }
            &:focus {
                background-color: $color-white;
            }
        }
    }
    // .search_empty {
    //     margin-top: rem(20);
    //     text-align: center;
    //     padding-left: rem(20);
    //     padding-right: rem(20);
    //     .search_empty-title {
    //         font-weight: 500;
    //     }
    //     .search_empty-desc {
    //         color: $color-dark-grey;
    //         margin-top: rem(5);
    //         text-align: left;
    //     }
    // }
    .search_suggestions {
        position: absolute;
        z-index: 1;
        box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
        border-radius: rem(6);
        padding: 1rem;
        background: #FFFFFF;
        top: rem(41);
        width: 100%;
        // защита от пидораса
        span {
            float: none;
        }
        // конец защиты от пидораса
        .search_suggestion {
            text-align: left;
            padding-left: rem(15);
            padding-right: rem(15);
            border-radius: rem(6);
            line-height: rem(40);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            cursor: pointer;
            &:hover {
                background-color: #EEEEF0;
            }
        }
        .search_suggestion__highlight {
            font-weight: 500;
            background-color: #EEEEF0;
        }
    }
}
