
@import "../../settings/colors";

.cn__dashboard_wishlist_delete_modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	max-width: 437px;
    padding: 20px;
    padding-bottom: 10px;

	&_heading {
		font-weight: 600;
		font-size: 23px;
		line-height: 120%;
        text-align: center;
	}

	&_buttonRow {
		display: flex;
		justify-content: center;
		padding-top: 40px;
        gap: 40px;
		&_btn {
			border: none;
			border-radius: 20px;
			padding-inline: 40px;
			padding-block: 5px;
			font-weight: 600;
			font-size: 16px;
			cursor: pointer;
		}
		.deleteButton {
			background: $color-primary;
			color: white;
		}
        .cancelButton {
            background: $color-light-gray-input;
            color: $color-dark-grey;
        }
	}
}
