.cn__hp__eid-banner__wrapper {
    width: 100%;
    position: relative;
    max-width: 1920px;
    padding: 0 100px;
    margin: 48px auto;
}
.cn__hp__eid-banner {
    position: relative;
    width: 100%;
    height: 140px;
    border-radius: 25px;
    overflow: hidden;
    padding: 42px 76px;
    display: flex;
    justify-content: space-between;

    .cn__image-picture {
        .cn__image-default {
            object-position: 50% 66%;
        }
    }
}
.cn__hp__eid-banner-left {
    flex: 2;
    position: relative;
    display: flex;
    align-items: center;
}
.cn__hp__eid-banner-right {
    flex: 1;
    position: relative;
    display: flex;
    // margin-right: 8px;
    justify-content: flex-end;
    align-items: center;
}
.cn__hp__eid-banner-text {
    font-size: 27px;
    line-height: 110%;
    font-weight: 300;
    max-width: 460px;
    color: $color-white;

    >span {
        font-weight: 600;
        white-space: nowrap;
    }
}
.cn__hp__eid-banner-btn {
    border: none;
    appearance: none;
    // height: 38px;
    // width: 38px;
    border-radius: 30px;
    border: 1px solid $color-white;
    background: none;
    background-color: rgba($color: $color-white, $alpha: 0);
    padding: 6px 42px;
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    transition: background-color 0.2s ease;
}
.cn__hp__eid-banner-btn:hover {
    cursor: pointer;
    background-color: rgba($color: $color-white, $alpha: 0.1);
}

@media screen and (max-width:1136px) {
    .cn__hp__eid-banner__wrapper {
        margin: 60px auto;
        padding: 0 30px;
    }
    .cn__hp__eid-banner {
        padding: 26px 38px;
    }
    .cn__hp__eid-banner-text {
        font-size: 22px;
    }
    .cn__hp__eid-banner-btn {
        padding: 6px 22px;
    }
}

@media screen and (max-width:600px) {
    .cn__hp__eid-banner__wrapper {
        padding: 0 20px;
    }
    .cn__hp__eid-banner {
        height: 100px;
        padding: 26px 28px;
    }
    .cn__hp__eid-banner-text {
        font-size: 13px;
        font-weight: 400;
    }
    .cn__hp__eid-banner-btn {
        padding: 4px 12px;
        font-size: 11px;
    }
}
