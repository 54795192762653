@import '../../global/mixins/global-mixins.scss';
.popup-content-wrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    transform: translate(0,-98vh);
    transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
    @media screen  and (max-width: 600px) {
        transform: translate(100vw,0);
    }
    .primary-input{
        background-color: $color-light-gray;
        color:$color-dark-grey;
        border: 1px solid $color-light-gray;
        box-shadow: none;
        transition: all 0.3s ease;
        &:focus{
            background-color: $color-white !important;
            border:2px solid $color-bright-purple;
            color: $color-black;
        }
        &:-webkit-autofill{
            background-color: $color-white !important;
        }
    }
}
.login-popup {
    .model-inner {
        max-width: 21.9375rem;
        padding-top: 2.875rem;
        padding-bottom: 1.875rem;
        max-height: 98vh;
        border-radius: 20px;
        overflow: hidden;
        @media screen  and (max-width: 600px) {
            padding-top: 1.875rem;
            padding-bottom: 3.75rem;
            max-height: 100vh;
            max-width: 100%;
            border-radius: 0;
        }
        .modal-container{
            position: relative;
            width: 100%;
            height: 100%;
            @media screen  and (max-width: 600px) {
                position: relative;
                width: 100%;
                height: 100%;
            }
        }
    }
    .popup-content {
        width: 100%;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        max-height: calc(100vh - 6.875rem);
        overflow: hidden;
        @media screen  and (max-width: 600px) {
            max-height: 100%;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    .login-heading {
        width: 100%;
        vertical-align: top;
        display: none;
        text-align: center;
        padding: 0 0 1.5625rem 0;
        margin: 0;
        @include h2-font();
        color: $color-black;
        @media screen  and (max-width: 600px) {
            display: inline-block;
        }
    }
    .fb-btn, .google-btn {
        font-family: $primary-font;
        @include body-text();
        line-height: 2.375rem;
        text-align: center;
        height: 2.375rem;
        padding: 0;
        cursor: pointer;
        border: none;
        border-radius: 0.375rem;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-decoration: none;
        outline: none;
        width: 100%;
        margin: 0 0 1.25rem 0;
        span {
            width: 2.875rem;
            height: 2.375rem;
            text-align: center;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -2.5rem;
            @media screen  and (max-width: 600px) {
                height: 2.875rem;
            }
            img {
                width: auto;
                height: auto;
            }
        }
        @media screen  and (max-width: 600px) {
            height: 2.875rem;
            line-height: 2.875rem;
            border-radius: 0.5rem;
        }
    }
    .fb-btn {
        background: #1877F2;
        color: $color-white;
        &:hover {
            background: #0b64d8;
            color: $color-white;
        }
    }
    .google-btn {
        background: $color-white;
        color: $color-black;
        border: 0.0625rem solid $color-subtle-gray;
        &:hover {
            background: $color-light-gray;
            color: $color-black;
        }
    }
    .middle-border-text {
        display: inline-block;
        text-align: center;
        position: relative;
        margin: 0.625rem 0 1.25rem 0;
        width: 100%;
        &::after {
            content: '';
            width: 100%;
            display: inline-block;
            vertical-align: top;
            position: absolute;
            left: 0;
            top: 0.625rem;
            border-bottom: 0.0625rem solid $color-light-gray-bw-border;
            z-index: -1;
        }
        span {
            display: inline-block;
            text-align: center;
            background: $color-subtle-white;
            @include body-text();
            color: $color-subtle-gray;
            padding: 0 0.625rem;
            @media screen  and (max-width: 600px) {
                background: $color-white;
                padding: 0 0.3125rem;
            }
        }
    }
    .cn-input-group {
        margin: 0 0 0.9375rem 0;
    }
    .remember-checkbox {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin: 0 0 1.875rem 0;
    }
    .btn-primary {
        min-width: auto;
        width: 7.5rem;
        margin: 0 auto 0.9375rem;
        display: block;
        display: flex;
        align-items: center;
        background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
    }
    .forgot-password {
        text-align: center;
        width: 100%;
        vertical-align: top;
        display: inline-block;
        padding: 0;
        margin: 0 0 1.5625rem 0;
        a {
            @include body-text();
            color: $color-bright-purple;
            &:hover {
                @include body-emphasized();
                color: $color-bright-purple;
            }
        }
    }
    .register-account {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        text-align: left;
        padding: 1.25rem 0 0 0;
        margin: 0;
        @include body-text();
        border-top: 0.0625rem solid $color-light-gray-bw-border;
        color: $color-black;
        @media screen  and (max-width: 600px) {
            padding-bottom: 1.25rem;
        }
        a {
            @include body-text();
            color: $color-bright-purple;
            &:hover {
                @include body-emphasized();
                color:$color-bright-purple;
            }
        }
    }
    .register-login{
        text-align: center;
        margin-top: 0.3125rem;
        a {
            @include body-text();
            color: $color-bright-purple;
            &:hover {
                @include body-emphasized();
                color:$color-bright-purple;
            }
        }
    }
}
