.modal-multi-image {
    .n-modal__content {
        padding: 5px;
    }
    .carousel-slider__main-slider {
        margin: 0 -7px;
    }
    &.n-modal {
        .slick-slider {
            .carousel-slider__item {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.n-img {
    img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: fill;
    }
    &__cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 6px;
        display: flex;
        @include align-center;
        opacity: 0;
    }
    &--has-item {
        cursor: pointer;
        img {
            object-fit: cover;
        }
    }
    &--with-cover {
        &:hover {
            img {
                filter: blur(8px);
            }
            .n-img__cover {
                opacity: 1;
            }
        }
    }
}