@import '../../global/mixins/global-mixins.scss';
 .rating-stars-box{
     width: 100%;
        ul{
            margin: 0;
            padding: 0;
            display: flex; 
            justify-content: center;
            li{
                list-style: none;
                padding-right: 5px;
                &:last-child{
                    padding-right: 0;  
                }
            }
        }
 }
 