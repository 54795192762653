.cn__covid{
    position: relative;
    display: flex;
    align-items: center;
    background: $color-white;
    backdrop-filter: blur(30px);
    border-radius: 25px;
    justify-content: center;
    padding: 10px 15px;


    .cn__covid--title{
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.01em;
        color:#F26161;
        margin-right: 3px;
    }
    a{
        display: flex;
        align-items: center;
        color: $color-black;
        .cn__covid--description{
            font-weight: 600;
            font-size: 12px;
            line-height: 1;
            letter-spacing: 0.01em;
            margin-right: 4px;
            span{
                color:#F44A3A;
            }
        }
        .cn__covid--arrow--wrapper{
            .cn__covid--arrow{
                position: relative;
                width: 100%;
                top: 0px;
                font-size: 12px;
                font-weight: bold;
                transition: all 0.3s ease;
            }
        }
    }
}
@media (max-width: 600px) {
    .cn__covid{
       background: #242526;
        flex-direction: column;
        align-items: flex-start;

        padding: 15px 20px;
        width: 100%;
        border-radius: 0;

        .cn__covid--title{
            margin-right: 0;
            margin-bottom: 4px;
        }
        a{
            color: #E9E9EB;
            .cn__covid--arrow--wrapper{
                .cn__covid--arrow{
                    path{
                        fill: $color-white;
                    }
                }
            }
        }
    }
}

//Movement class
.cn__covid--move{
    margin-left: 2px;
}