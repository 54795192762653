.listings-header {
    #onboarding-popper {
        z-index: 0 !important;
    }
    .listings-title {
        &.listings-title--desktop {
            margin-bottom: 22px;
        }

        &.listings-title--tablet {
            .pane-toolbar {
                &.pane-toolbar--desktop-with-title {
                    .actions-list {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    // .listings-title {
    //     /*&.listings-title--mobile {
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         justify-content: space-between;
    //         padding: 0 20px;
    //         position: fixed;
    //         top: 0;
    //         left: 0;
    //         z-index: 2;
    //         background-color: var(--color-subtle-white);
    //         width: 100%;
    //         min-height: 36px;

    //         // .listings-header_back-action {
    //         //     padding: 0;
    //         //     .ic-icon {
    //         //         width: 30px;
    //         //         height: 30px;
    //         //         margin: 0;
    //         //         svg {
    //         //             width: inherit;
    //         //             height: inherit;
    //         //         }
    //         //     }
    //         // }
    //         // .actions-list {
    //         //     margin: 0;
    //         //     transform: translateY(3px);
    //         // }
    //     }*/
    //     padding: 0;
    //     display: flex;
    //     flex-direction: column;
    //     /*h1 {
    //         margin-bottom: 5px;
    //         letter-spacing: -0.01em;
    //         @include text_small-header;
    //         font-weight: 700;
    //     }
    //     .sub-header {
    //         margin-bottom: 15px;
    //         strong {
    //             font-family: $primary-font;
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 18px;
    //             letter-spacing: 0.03em;
    //             font-variant: all-small-caps;
    //             line-height: 19px;
    //             white-space: nowrap;
    //             margin-right: 6px;
    //             margin-bottom: 5px;
    //         }
    //         .breadcrumbs {
    //             width: 100%;
    //             margin-left: 0px;
    //             ul li {
    //                 font-size: 18px;
    //             }
    //         }
    //         .star-rate {
    //             path {
    //                 fill: var(--color-black-grey);
    //             }
    //         }
    //     }*/
    //     /*.actions-list {
    //         list-style: none;
    //         display: flex;
    //         margin-top: -10px;
    //         >li {
    //             margin-left: 20px;
    //             .n-btn {
    //                 color: #242526;
    //                 font-weight: normal;
    //                 padding: 6px 8px;
    //                 @include align-center;
    //                 cursor: pointer;
    //                 .ic-icon {
    //                     @include sqr_24;
    //                 }
    //                 &:hover {
    //                     background-color: var(--color-light-grey);
    //                 }
    //             }
    //         }
    //         .n-tooltip__inner {
    //             padding: 15px 0px;
    //             width: 240px;
    //             li {
    //                 padding-left: 20px;
    //             }
    //         }
    //     }
    //     .actions-share-list {
    //         margin: 0px;
    //         z-index: 999;
    //         ul {
    //             grid-row-gap: 0px;
    //         }
    //         li {
    //             padding: 10px;
    //             cursor: pointer;
    //             color: var(--color-black);
    //             &:hover {
    //                 background: var(--color-shade-grey);
    //             }
    //         }
    //     } */
    //     @include tablet {
    //         h1 {
    //             margin-bottom: 10px;
    //         }
    //         .sub-header {
    //             grid-column: 1 / 5;
    //             display: flex;
    //             flex-direction: row;
    //             margin-bottom: 9px;
    //             .sub-header_main {
    //                 display: inline-block;
    //                 padding-right: 5px;
    //                 white-space: nowrap;
    //             }
    //             .sub-header_additional {
    //                 white-space: nowrap;
    //                 display: inline-block;
    //                 padding-left: 5px;
    //                 border-left: 1px solid var(--color-dark-grey);
    //                 color: var(--color-dark-grey);
    //             }
    //             .breadcrumbs {
    //                 margin: 0 0 0 5px;
    //                 width: auto;
    //                 position: relative;
    //                 padding-left: 0.5rem;
    //                 &:after {
    //                     content: '';
    //                     width: 0.25rem;
    //                     height: 0.25rem;
    //                     background: #807D82;
    //                     border-radius: 50%;
    //                     position: absolute;
    //                     left: 0;
    //                     top: 0.5rem;
    //                 }
    //             }
    //             strong {
    //                 margin-bottom: 0px;
    //             }
    //         }
    //     }
    //     // @include desktop {
    //     // padding: 0 0 20px;
    //     // display: grid;
    //     // grid-template-columns: repeat(4, 4fr) 200px;
    //     h1 {
    //         grid-column: 1 / 6;
    //         overflow: hidden;
    //         display: -webkit-box;
    //         -webkit-line-clamp: 2;
    //         -webkit-box-orient: vertical;
    //         @include text_big-header;
    //     }
    //         // .actions-list {
    //         //     grid-column: 5 / -1;
    //         //     justify-self: flex-end;
    //         // }
    //     // }
    // }
    // .listings-mobile-title {
    //     margin-top: 20px;
    //     padding-bottom: 0;
    //     h1 {
    //         font-size: 22px;
    //         letter-spacing: -0.01em;
    //     }
    //     .sub-header {
    //         margin: 0;
    //         display: grid;
    //         grid-column: 1 / -1;
    //         .sub-header_main {
    //             * {
    //                 font-size: 1rem;
    //                 font-variant: initial;
    //                 text-transform: uppercase;
    //             }
    //         }
    //         .sub-header_additional {
    //             margin-top: 12px;
    //             font-size: 1rem;
    //             color: var(--color-dark-grey);
    //         }
    //     }
    // }
    .listings-gallery-wrapper {
        position: relative;
    }
    .listings-gallary {
        overflow: hidden;
        position: relative;
        margin: 0 -20px -10px;
        padding-top: 62px;
        /*.actions-list {
            display: flex;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
            list-style: none;
            li {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #FCFCFC;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                cursor: pointer;
                .ic-icon {
                    width: 24px;
                    height: 24px;
                }
            }
            @include tablet {
                right: 35px;
            }
        } */
        .slick-slider {
            // filter: brightness(.8);
            .carousel-slider__item {
                img {
                    width: 100%;
                    height: 236px;
                    object-fit: cover;
                }
            }
        }
        @include tablet {
            margin: 0 -20px 0;
            padding: 0 20px;
            .slider-masonry ul {
                min-height: 350px;
            }
        }
        @include desktop {
            margin: 0px;
            padding: 0px;
            .slider-masonry ul {
                min-height: 490px;
            }
            .slider-masonry li {
                cursor: initial;
            }
            .slider-masonry--has-more li {
                cursor: pointer;
            }
        }


        .slick-pagination {
            position: absolute;
            color: var(--color-white);
            bottom: 0;
            right: 0;
            z-index: 1;
            margin: 20px;
            padding: 0px;
            width:60px;
            overflow: hidden;
            .slick-pagination-slider {
                width:max-content;
                display: flex;
                min-width: 100%;
                align-items: center;
                position: relative;
                left:24px;
                transition: 100ms ease;
                .dot {
                    width:7px;
                    height:7px;
                    transition: 100ms ease;
                    background:#fff;
                    border-radius: 50%;
                    margin:0px 5px 0px 0px;
                    &.active-dot {
                        background-color: #F35597;
                    }
                }
            }
        }
    }
    .n-btn--wishlist {
        path {
            fill: rgb(85, 87, 89);
        }
    }
}

.n-modal--gallery,
.modal-multi-image {
    .n-modal__header {
        .n-modal__logo {
            position: absolute;
            left: 30px;
            top: 32px;
        }
        .n-modal__close-action {
            position: absolute;
            right: 30px;
            top: 32px;
            cursor: pointer;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: var(--color-pure-black);
            display: flex;
            justify-content: center;
            align-items: center;
            .ic-icon {
                margin-right: 3px;
            }
        }
        .n-modal__close {
            display: none;
        }
        &:after {
            display: none;
        }
    }
    .n-modal__dialog {
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0px;
        border-radius: 0px;
    }
    .n-modal__content {
        max-height: calc(100vh - 90px);
        height: calc(100vh - 90px);
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .carousel-slider--gallery {
            width: min(1280px, 100%);
            display: grid;
            grid-template-rows: 1fr 145px;
            grid-template-columns: 100%;
            .carousel-slider__main-slider {
                .carousel-slider__item {
                    text-align: center;
                    background: #F7F7F7;
                    .inner {
                        height: calc(calc(var(--vh, 1vh) * 100) - 235px) !important;
                        max-width: min(1100px, 100%) !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        padding-top: 30px;
                        margin: auto;
                    }
                    img {
                        object-fit: contain;
                        max-height: calc(calc(var(--vh, 1vh) * 100) - 270px);
                        max-width: min(1100px, 100%) !important;
                    }
                    p {
                        width: 100%;
                        padding: 0px !important;
                        margin: 0 auto;
                        text-align: center;
                        position: absolute;
                        left: 0px;
                        bottom: auto;
                        top: 0px;
                    }
                }
            }
            .carousel-slider__navigation {
                align-self: center;
                width: min(1100px, 100%);
                margin: 0 auto;
                padding: 0;
                > .slick-slider {
                    overflow: hidden;
                    .slick-arrow {
                        box-shadow: none;
                        border-radius: 0px;
                        height: 100%;
                        position: absolute;
                        width: 60px;
                        top: 49%;

                        .ic-icon {
                            top: calc(50% - 12px);
                        }
                        &.slick-prev {
                            background: linear-gradient(90deg, rgba(247,247,247,1) 40%, rgba(0,0,0,0) 100%);
                            left: 0px;
                            .ic-icon {
                                left: 0px;
                            }
                        }
                        &.slick-next {
                            background: linear-gradient(90deg, rgba(0,0,0,0) 0%,  rgba(247,247,247,1) 60%);
                            right: 0px;
                            .ic-icon {
                                left: auto;
                                right: 0px;
                            }
                        }
                    }
                    .carousel-slider__item {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        @include mobile {
            padding: 25px 5px 5px 5px;
            .slick-slider {
                width: 100%;
                .inner {
                    text-align: center;
                    min-height: calc(calc(var(--vh, 1vh) * 100) - 270px);
                    height: calc(calc(var(--vh, 1vh) * 100) - 270px);
                    max-width: 100%;
                    background: #F7F7F7;
                    padding: 40px 5px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-height: calc(calc(var(--vh, 1vh) * 100) - 290px);
                        max-width: 100%;
                        object-fit: contain;
                    }
                    p {
                        position: absolute;
                        top: 5px;
                        left: 0px;
                        width: calc(100% - 10px);
                    }
                }
                .slick-dots {
                    bottom: -27px;
                    white-space: nowrap;
                }
            }
        }
        @include onlyTablet {
            padding: 25px 5px 5px 5px;
            .slick-slider {
                width: 100%;
                .inner {
                    text-align: center;
                    min-height: calc(calc(var(--vh, 1vh) * 100) - 270px);
                    height: calc(calc(var(--vh, 1vh) * 100) - 270px);
                    max-width: 100%;
                    background: #F7F7F7;
                    padding: 40px 5px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-height: calc(calc(var(--vh, 1vh) * 100) - 290px);
                        max-width: 100%;
                        object-fit: contain;
                    }
                    p {
                        position: absolute;
                        top: 5px;
                        left: 0px;
                        width: calc(100% - 10px);
                    }
                }
                .slick-dots {
                    bottom: -27px;
                }
            }
        }
    }
    .slick-slider {
        margin: 0 -20px;
    }
    .carousel-slider .slick-dots {
        bottom: 70px;
    }
    @include desktop {
        .carousel-slider__main-slider .carousel-slider__item img {
            width: auto !important;
        }
    }
}

/*.n-mobil-action--share {
    .list {
        grid-row-gap: 0;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
            padding: 20px 0;
            color: var(--color-black);
            &:first-child {
                border-bottom: 1px solid var(--color-light-grey);
            }
        }
    }
}*/