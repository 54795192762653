.cn__dashboard__breadcrumbs {
	&__container {
		display: flex;
		// padding-left: 28px;
		// padding-top: 25px;
		&__itemRow {
			display: flex;
			> span {
				padding-inline: 12px;
				font-size: 26px;
				margin-top: -10px;
				color: #acabb4;
			}
			> div {
				cursor: pointer;
				font-weight: 500;
				font-size: 12px;
				line-height: 150%;
				/* identical to box height, or 18px */

				letter-spacing: 0.01em;

				color: #000000;
			}
            .final {
                font-weight: 600;
            }
		}
	}
}
