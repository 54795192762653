.wizard {
    $self: &;
    &--vertical {
        display: flex;
        #{$self}__steps {
            width: 250px;
            list-style: none;
            padding: 88px 0 68px 0;
            background-color: var(--color-light-grey);
            &__inner {
                height: fit-content;
                list-style-type: none;
                max-height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                // height: 100%;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-around;
            }
            li {
                cursor: pointer !important;
                &:hover {
                    cursor: pointer !important;
                }
    
                position: relative;
                // display: flex;
                // flex-direction: column;
                // margin: 0 auto;
                // width: 200px;
                label {
                    cursor: pointer !important;
                    &:hover {
                        cursor: pointer !important;
                    }
    
                    // display: inline-block;
                    line-height: 3rem;
                    outline: none;
    
                    padding: 0 1rem;
                    // padding-left: 6px;
                    border-left: 5px solid transparent;
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    // line-height: 150%;
                    // font-size: 16px;
                    // line-height: 20px;
                    transition: border-color 0.2s linear;

                    .suffix-icon {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex: 1;

                        .ic-icon {
                            margin: 0;
                        }
                    }
                }
                .ic-icon {
                    display: block;
                    height: 24px;
                    width: 24px;
                    min-width: 24px;
                    line-height: 32px;
                    border-radius: 2px;
                    box-sizing: border-box;
                    text-align: center;
                    margin-right: 10px;
                }
                .icon--checked {
                    position: absolute;
                    top: 0px;
                    right: -24px;
                }
                .n-btn--step-continue {
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    width: 74px;
                    padding: 0px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    margin-left: 49px;
                    margin-top: 7px;
                    cursor: pointer;
                }
                &.active {
                    > label {
                        border-left-color: var(--color-vibrant-pink);
                        font-weight: bold;
                        .ic-icon {
                            path,
                            circle,
                            rect {
                                // stroke: var(--color-black);
                            }
                        }
                        .text {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            // line-height: 150%;
                        }
                    }
                }
            }
            li:hover,
            li:hover > label,
            li:hover > label > svg {
                cursor: pointer;
            }
        }
        #{$self}__container {
            width: calc(100% - 250px);
            display: flex;
            flex-direction: column;
            &__top {
                padding: 0;
                // padding: 0 20px;
                padding-top: 88px;
                flex: 1;
                overflow-y: hidden;
                overflow-x: hidden;
                // height: 100vh;
                // max-height: 100vh;
                // overflow-y: scroll;
            }
        }
        #{$self}__head {
            // margin-top: 20px;
            display: block;
            margin: 20px 0;
            // height: 70px;
            padding: 0 5px;
            h4 {
                font-family: $primary-font;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                letter-spacing: -0.01em;
                margin-bottom: 5px;
                color: var(--black);
            }
            p {
                font-family: $primary-font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: var(--color-dark-grey);

                a {
                    color: var(--color-bright-purple);
                    font-weight: 600;
                }
            }
        }
        #{$self}__content {
            padding: 0 20px;
            overflow-y: auto;
            overflow-x: hidden;
            // scrollbar-gutter: stable both-edges;
            width: 100%;
            height: 100%;
            // padding-top: 88px;
            // padding: 0 5px;
            // display: block;
            @media screen and (min-width: 1620px) {
                padding: 0 calc((100vw - 1590px) / 2);
            }
        }
        #{$self}__footer {
            // height: 88px;
            height: 68px;
            // height: clamp(50px, 7vh, 85px);
            border-top: 1px solid var(--color-light-grey);
            display: flex;
            justify-content: space-between;
            // padding: clamp(5px, 1vh, 15px) 0;
            &__continue-skip {
                display: flex;
            }
        }
    }
    &__mui__loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background: rgba(100, 100, 100, 0.1);
    }
    &__loading {
        position: absolute;
        left: 0px;
        top: -80px;
        width: 100%;
        height: calc(100% + 80px);
        background: rgba(100, 100, 100, 0.3);
        z-index: 9;
    }
    &--small-sidebar {
        .wizard__aside--expanded {}
        #{$self}__steps {
            position: absolute;
            width: 64px;
            height: 100%;
            padding: 88px 0 68px 0;
            // padding: 106px 10px 0;
            transition: all 0.3s ease-in-out;
            z-index: 3;

            li {
                .text {
                    opacity: 0;
                    white-space: nowrap;
                    pointer-events: none;
                    transition: all 0.3s ease-in-out;
                }
                .icon--checked {
                    display: none;
                }
                .n-btn--step-continue {
                    display: none;
                }
            }

            &--expanded {
                width: 250px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
                li {
                    .text {
                        opacity: 1;
                        pointer-events: initial;
                    }
                    .icon--checked {
                        display: none;
                    }
                    .n-btn--step-continue {
                        display: block;
                    }
                }
            }

            // &:hover {
            //     width: 250px;
            //     box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            //     li {
            //         .text {
            //             opacity: 1;
            //             pointer-events: initial;
            //         }
            //         .icon--checked {
            //             display: none;
            //         }
            //         .n-btn--step-continue {
            //             display: block;
            //         }
            //     }
            //     // + div {
            //     //     filter: blur(20px);
            //     //     backdrop-filter: blur(20px);
            //     // }
            // }
        }
        #{$self}__container {
            padding-left: 64px;
            width: 100%;
        }
    }
    &--mobile-mode {
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;
        display: block;
        // display: grid;
        // grid-template-columns: 1fr;
        // grid-template-rows: 156px calc(100vh - 156px);
        #{$self}__steps {
            background-color: var(--color-white);
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            width: 100vw;
            max-width: 280px;
            // height: 100vh;
            height: 100%;
            position: absolute;
            padding-top: 55px;
            z-index: 9;
            left: -100vw;
            transition: all 0.3s ease-in-out;
            &__footer {
                background: var(--color-white);
                position: absolute;
                left: 0px;
                width: 100%;
                bottom: 0px;
                height: 68px;
                display: flex;
                justify-content: center;
                align-items: center;
                .n-btn {
                    width: 100%;
                    justify-content: center;
                    font-family: $primary-font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--color-black);
                    .ic-icon {
                        position: absolute;
                        left: 0px;
                    }
                }
            }
            &__inner {
                border-top: 1px solid var(--color-light-grey);
            }
            &--active {
                left: 0px;
            }
        }
        #{$self}__header {
            display: flex;
            flex-direction: column;
            z-index: 2;
            background: var(--color-white);
            .wizard__header__menu-upper {
                height: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                >.ic-icon {
                    margin-left: 20px;
                }
                .n-btn {
                    padding: 0;
                    margin-right: 20px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
            .wizard__header__menu {
                // padding-top: 15px;
                // height: 58px;
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                // grid-template-rows: 1fr 5px;
                >.ic-icon {
                    // margin-left: 20px;
                }
                .n-btn {
                    // margin-right: 20px;
                    // width: 140px;
                    // padding: 0 20px;
                    // justify-self: flex-end;
                }
                #{$self}__header__progress {
                    width: 100%;
                    height: 5px;
                    background: var(--color-light-grey);
                    // grid-area: 2/1/2/ span2;
                    position: relative;
                    &__inner {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        background-color: var(--color-bright-purple);
                        border-radius: 3px;
                        height: 5px;
                    }
                }
            }
            &__steps {
                padding: 16px 0;
                border-bottom: 1px solid var(--color-light-grey);
                // padding-top: 20px;
                ul {
                    background: var(--color-light-grey);
                    padding: 20px;
                    margin: auto;
                    list-style: none;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: center;
                    max-width: 250px;
                    border-radius: 15px;
                    li {
                        display: none;
                        white-space: nowrap;
                        justify-content: center;
                        align-items: center;
                        &.active {
                            display: flex;
                        }
                        .text {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: var(--color-dark-grey);
                        }
                        .nexts,
                        .prevs {
                            font-family: $primary-font;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: var(--color-dark-grey);
                            display: flex;
                            align-items: normal;
                            path {
                                stroke: var(--color-dark-grey);
                            }
                        }
                    }
                }
            }
        }
        #{$self}__container {
            padding: 0px;
            width: 100%;
            max-width: 100vw;
            height: calc(100% - 157px);
            // height: 100%;
            &__top {
                flex: 1;
                padding: 0;
                // height: calc(100vh - 187px - 66px);
                // max-height: calc(100vh - 187px - 66px);
            }
            .wizard__content {
                padding: 0;
            }
            >#{$self}__footer {
                .n-btn--wizard-continue {
                    font-weight: 600;
                }
            }
        }
    }
}

.progress-wizard {
    position: relative;
    &__content {
        width: 100%;
        height: 600px;
        position: relative;
        overflow: hidden;
        &__item {
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: -100%;
            opacity: 1;
            transition: all 0.5s ease-in-out;
            &.active {
                bottom: 0px;
            }
            &.past {
                bottom: 50%;
                opacity: 0;
                pointer-events: none;
            }
            &.prev {
                bottom: 50%;
                opacity: 0.6;
                pointer-events: initial;
            }
        }
    }
}

@media screen and (max-width: 1136px) {
    .wizard {
        $self: &;
        &--vertical {
            #{$self}__steps {
                &__inner {
                }
                li {
                    label {
                        // padding-left: 0;
                    }
                }
            }
        }
    }
}