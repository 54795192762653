//------------------- DESKTOP -------------------
.cn__hp__heading{
    &__title{
        font-weight: 600;
        font-size: 32px;
        line-height: 1.2;
        color: #000000;
    }
    &__subtitle{
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #000000;
        margin-top: 15px;
        font-feature-settings: unset;
        font-variant: unset;
        text-transform: unset;
    }
    &.cn__hp__heading--left{
        text-align: left;
    }
    &.cn__hp__heading--center{
        text-align: center;
        .cn__hp__heading__subtitle{
            margin: 15px auto 0;
        }
    }
}

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__heading{
        padding: 0 30px;
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__heading{
        padding: 0 20px;
        &__title{
            font-size: 23px;
            line-height: 28px;
        }
    }
}
