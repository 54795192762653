@import './tools/_mixins';
@import './tools/_functions';


//------------------- DESKTOP -------------------

.cn__aboutus{
    position: absolute;
    width: 100%;
    height: 100%;
    --color-background: #FAFAFA;

    //--- Header
    .header{
        position: absolute;
        width: 100%;
        background: transparent;
        border: none;
        &.header-aboutus{
            background: #FFFFFF;
        }
        &.sticky{
            position: fixed;
        }
        .max-width-wrapper{
            max-width: 1440px;
            padding: 0 50px;

            //-- Logo
            .logo-pink, .logo-purple{
                fill: #FFFFFF;
            }

            //--Header options
            .li--blog, .li--currency{
                display: none;
            }
        }
    }

    //--- Footer
    .cn__footer__wrapper{
        background: var(--color-background);
    }

    //-- AU1
    .cn__aboutus__au1{
        .cn__image-default{
            object-position: center 67%;
        }
        .cn__image-picture{
            top: 0;
            left: 0;
        }
    }

    //-- AU2
    .cn__aboutus__au2{
        .cn__slider{
            margin-top: 3vw;
            .cn__card{
                &:hover{
                    .auiw{
                        fill: #7868C7;
                    }
                    .auiw{
                        stroke: #7868C7;
                    }
                    .auib{
                        fill: #FFFFFF;
                    }
                }
            }
        }
    }

    //--LY2
    &__general{
        background: #FAFAFA;

        &__wrapper{
            position: relative;
            max-width: 1440px;
            margin: 0 auto;
            padding: dvw(242) 100px 0;
    
            &__title{
                position: relative;
                text-align: center;
                font-weight: 600;
                font-size: dvw(42);
                line-height: 1.2;
                text-align: center;
            }
        }
    }
    .cn__lyp__lyp2__wrapper{
        padding-top: dvw(50);
        padding-bottom: dvw(150);
    }
    .cn__lyp__lyp2__title, .cn__lyp__lyp2__subtitle, .cn__lyp__lyp2__subtitle2, .cn__lyp__button{
        display: none;
    }
    .cn__lyp__lyp2__info{
        margin: 0 auto;
    }

    //AU5
    .cn__aboutus__au5{
        .slick-track{
            display: flex;
        }
        .slick-slide{
            flex-shrink: 0;
            padding: 0 4vw;
        }
        .slick-center{
            .cn__aboutus__au5__slider__card{
                opacity: 1;
                background: #F35597;
                box-sizing: border-box;
                box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.1);
                border: 0.5px solid rgba(100, 116, 139, 0.5);
                transition: all 0.6s ease;
                transition-delay: 0.6s;
                color: white;
                font-weight: 400;
                font-size: 16px;
               p{
                   color: #FFFFFF;
               }
            }
        }
        .slick-dots{
            bottom: -5vw;
        }
        .slick-dots li button:before{
            background: #E5E5E5;
        }
        .slick-dots li.slick-active button:before{
            background: #EF5DA8;
        }
    }

    //AU7
    .cn__aboutus__au7{
        //--------- simplebar ----------
        .simplebar-content-wrapper{
            scroll-behavior: smooth;
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .simplebar-track.simplebar-horizontal{
            background: transparent;
            height: 24px;
            width: calc(100% - 7vw);
            left: 3.5vw;
            bottom: -14px;
            overflow: visible;
            pointer-events: all;
            cursor: pointer;
            z-index: 0;
            &::before{
                position: absolute;
                content: '';
                width: calc(100%);
                height: 100%;
                left: -20px;
                z-index: -1;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 0.139vw;
                bottom: 1px;
                background: #E9E9EB;
                border-radius: 5px;
                z-index: -1;
            }
            .simplebar-scrollbar{
                width: 100%;
                height: 0.278vw;
                top: auto;
                bottom: 0;
                border-radius: 5px;
                background: rgba(244, 102, 162, 0.8);
                &::before{
                    display: none;
                }
            }
        }
    }

    //-- Footer
    .cn__footer{
        max-width: 1440px !important;
    }
}

@include desktop2{
    .cn__aboutus{
    
        //--LY2
        &__general{
    
            &__wrapper{
                padding: 242px 100px 0;
        
                &__title{
                    font-size: 44px;
                }
            }
        }
        .cn__lyp__lyp2__wrapper{
            padding-top: 50px;
            padding-bottom: 150px;
        }

        //AU5
        .cn__aboutus__au5{
            .slick-slide{
                flex-shrink: 0;
                padding: 0 63px;
            }
            .slick-dots{
                bottom: -64px;
            }
        }

        //AU7
        .cn__aboutus__au7{

            .simplebar-track.simplebar-horizontal{
                width: calc(100% - 100px);
                left: 50px;
                &::after{
                    height: 2px;
                }
                .simplebar-scrollbar{
                    height: 4px;
                }
            }
        }
    } 
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__aboutus{
    
        //--- Header
        .header{

            .max-width-wrapper{
                padding: 0;
            }
        }

        //-- AU1
        .cn__aboutus__au1{
            .cn__image-default{
                object-position: center 75%;
            }
        }

        //-- AU2
        .cn__aboutus__au2{
            .cn__slider{
                margin-top: 2vw;

                .cn__slider__cards{
                    overflow-x: unset;
                    padding: 5px 20px 60px 30px;
                }
                .simplebar-track{
                    display: none;
                }
                .cn__card{
                    // height: 466px;
                    // width: 278px;
                    &:hover{
                        .auiw{
                            fill: white;
                        }
                        .auiw{
                            stroke: white;
                        }
                        .auib{
                            fill: #7868C7;
                        }
                    }
                }
            }
        }

        //--LY2
        &__general{
    
            &__wrapper{
                padding: 16vw 30px 0;
            }
        }
        .cn__lyp__lyp2__info{
            box-shadow: 0px 15px 90px -15px rgb(0 0 0 / 25%);
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__aboutus{

        //--- Header
        .header{

            //-- Logo
            .cnword{
                display: none;
            }
            .logo-pink, .logo-purple{
                fill: #FFFFFF;
            }

            //---hamburguer
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #FFFFFF !important;
            }
            .hm-opened {
                .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                    background: #242526 !important;
                }
            }
        }

        //-- AU1
        .cn__aboutus__au1{
            .cn__image-default{
                object-position: -20vw top;
                width: 126%;
            }
        }

        //--LY2
        &__general{
    
            &__wrapper{
                padding: mvw(60) 20px 0;
        
                &__title{
                    width: 100%;
                    text-align: left;
                    font-size: 32px;
                    max-width: mvw(295);
                    font-weight: 500;
                }
            }
        }
        .cn__lyp__lyp2__wrapper{
            padding-top: 0;
            .cn__slider__cards{
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 15px;
                div {
                    width: 100%;
                    background: white;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    text-align: center;
                    .cn__lyp__card1__title{
                        font-weight: 500;
                        font-size: 44px;
                        background: linear-gradient(180deg, #F35597 0%, #7868C7 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    .cn__lyp__card1__subtitle{
                        font-weight: 500;
                        font-size: 18px;
                        color: #797882
                    }
                }
            }
        }
        

        //AU5
        .cn__aboutus__au5{
            .cn__slider__cards{
                padding-top: 6vw;
            }
        }

        //AU7
        .cn__aboutus__au7{

            .simplebar-track.simplebar-horizontal{
                width: calc(100% - 40px);
                left: 20px;
                &::after{
                    height: 0.535vw;
                }
                .simplebar-scrollbar{
                    height: 1.07vw;
                }
            }
        }
    }
}