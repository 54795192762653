.cn__cancellation__wrapper{
  position: relative;
  width: 100%;
  min-height: calc(100vh - 276px);
  padding-top: 100px;
}

.cn__cancel__main {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  &__heading {
    font-weight: 500;
    font-size: 44px;
    line-height: 52.8px;
    letter-spacing: -0.04em;
    color: #000000;
  }
  &__para {
    font-size: 23px;
    line-height: 27.6px;
    font-weight: 400;
    color: #000000;
    margin-top: 25px;
    &__custom {
      color: $color-custom-blue;
    }
    &__flexible {
      color: $color-success;
    }
    &__moderate {
      color: $color-moderate-orange;
    }
    &__rigid {
      color: $color-rigid-red;
    }
    &__non-refundable {
      color: $color-subtle-grey;
    }
  }
}


@media only screen and (min-width:601px) and (max-width: 718px) {
  .cn__cancel__main {
    padding: 0 30px;
    &__heading {
      font-weight: 400;
      font-size: 32px;
      line-height: 38.4px;
      letter-spacing: -0.025em;
    }
    &__para {
      font-weight: 400;
      font-size: 18px;
      line-height: 21.6px;
    }
  }
}


@media only screen  and (max-width: 600px) {
  .cn__cancellation__wrapper{
    min-height: auto;
    margin-top: 0;
    padding-top: 100px;
  }
  .cn__cancel__main {
    padding: 0 20px;
    max-width: 90%;
    &__heading {
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      letter-spacing: -0.025em;
    }
    &__para {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}
