//--------------------------- DESKTOP/TABLET ---------------------------//
.cn__holi__card{
    position: relative;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 2px 2px rgb(87 75 144 / 10%);
    transition: all .35s ease;

    &__imgsection{
        position: relative;
        width: 100%;
        height: 18.657vw;
        max-height: 250px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__infosection{
        position: relative;
        width: 100%;
        background: #FFFFFF;
        padding: 10px 20px 15px;
        &__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                width:99%;
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #242526;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-right: 10px;
            }
            img{
                transition: transform .5s ease;
            }
        }
        &__data{
            font-size: 16px;
            line-height: 19px;
            color: #ACABB4;
            margin-top: 5px;
            span{
                font-weight: 500;
                color: #242526;
            }
        }
    }

    &:hover{
        box-shadow: 0 30px 20px -20px rgb(87 75 144 / 15%);
        transform: translateY(-5px);
        .cn__holi__card__infosection__wrapper img{
            transform: translateX(5px);
        }
    }

}

.cn__holi__card2{
    position: relative;
    width: 100%;
    height: 29.478vw;
    max-height: 395px;
    cursor: pointer;
    perspective: 1000px;
    &__inner{
        position: absolute;
        width: 100%;
        height: 100%;
        transition: transform 0.6s ease-in-out;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d; 
		-moz-transform-style: preserve-3d; 
        &__front{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            overflow: hidden;
            transform: rotateY(0deg);
            z-index:2;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            p{
                position: absolute;
                font-weight: 900;
                font-size: 30px;
                line-height: 28px;
                text-align: center;
                letter-spacing: -0.04em;
                text-transform: uppercase;
                color: #FFFFFF;
                padding: 0 40px;
            }
        }
        &__back{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 40px 25px 30px 30px;
            border-radius: 20px;
            overflow: hidden;
            transform: rotateY(180deg);
            z-index: 1;
            a{
                display: block;
                margin-top: 2vw;
                color: #242526;
                &:first-child{
                    margin-top: 0;
                }
            }
            &__option{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                opacity: 0.6;
                transition: opacity 0.3s ease;
                p{
                    width: 60%;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                img{
                    transition: transform .5s ease;
                }
                &:hover{
                    opacity: 1;
                    img{
                        transform: translateX(5px);
                    }
                }
            }
        }
        &__face{
            position: absolute;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
    &.holi__white{
        .cn__holi__card2__inner__front{
            p{
                color: #000000;
                span{
                    color: #FFFFFF;
                }
            }
        }
        .cn__holi__card2__inner__back{
            background: radial-gradient(52.51% 52.51% at 50.04% 52.51%, #FFFFFF 0%, #EDECEB 100%);
        }
    }
    &.holi__green{
        .cn__holi__card2__inner__front{
            p{
                span{
                    color:#50F875;
                }
            }
        }
        .cn__holi__card2__inner__back{
            background: radial-gradient(52.51% 52.51% at 50.04% 52.51%, #C8ECD6 0%, #A4D7B8 100%);
        }
    }
    &.holi__turquoise{
        .cn__holi__card2__inner__front{
            p{
                span{
                    color:#05DBE6;
                }
            }
        }
        .cn__holi__card2__inner__back{
            background: radial-gradient(52.51% 52.51% at 50.04% 52.51%, #ADD9E6 0%, #89CADC 100%);
        }
    }
    &.holi__golden{
        .cn__holi__card2__inner__front{
            p{
                span{
                    color:#F7D248;
                }
            }
        }
        .cn__holi__card2__inner__back{
            justify-content: unset;
            background: radial-gradient(52.51% 52.51% at 50.04% 52.51%, #FFEDC0 0%, #F1D898 100%);
        }
    }
    &:hover{
        .cn__holi__card2__inner{
            transform: rotateY(180deg);
        }
    }
}

.cn__holi__card3{
    position: relative;
    border-radius: 20px;
    height: 18.657vw;
    max-height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.6);
    }
    &__info{
        position: absolute;
        color: #FFFFFF;
        &__title{
            font-weight: 800;
            font-size: 24px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.04em;
            text-transform: uppercase;
        }
        &__subtitle{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
        }
    }
}

//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__holi__card{    
        &__infosection{
            padding: 10px 15px 10px;
            &__wrapper{
                p{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    padding-right: 0;
                }
                img{
                    display: none;
                }
            }
            &__data{
                font-size: 13px;
                line-height: 14px;
                span{
                    font-weight: 700;
                }
            }
        }   
        &:hover{
            box-shadow: unset;
            transform: unset;
        }
    
    } 
    .cn__holi__card2{
        &__inner{
            &__front{
                p{
                    font-size: 24px;
                    line-height: 24px;
                    padding: 0 30px;
                }
            }
            &__back{
                padding: 20px 10px;
                border-radius: 20px;
                &__option{
                    p{
                        width: 80%;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 16px;
                    }
                    img{
                        width: 12px;
                    }
                    &:hover{
                        opacity: 0.6;
                        img{
                            transform: unset;
                        }
                    }
                }
            }
        }
    }
    .cn__holi__card3{
        &__info{
            padding: 0 10px;
            &__title{
                font-size: 16px;
                line-height: 19px;
            }
            &__subtitle{
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}

//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__holi__card{
        position: relative;
        width: 43.2vw;
        flex-shrink: 0;
        box-shadow: 0 2px 20px rgb(87 75 144 / 20%);
        transition: unset;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
        display: table;
    
        &__imgsection{
            height: 28.27vw;
        }
        &__infosection{
            padding: 10px 10px 15px;
            border-radius: 0 0 20px 20px;
            &__wrapper{
                p{
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &__data{
                font-size: 14px;
                color: #797882;
                margin-top: 7px;
                span{
                    font-weight: 600;
                }
            }
        } 
        &:hover{
            box-shadow: unset;
            transform: unset;
            .cn__holi__card__infosection__wrapper img{
                transform: unset;
            }
        }
    
    }
    .cn__holi__card2{
        position: relative;
        width: 89.5vw;
        height: 74.5vw;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
        margin-left: 2.65vw;
        display: table;
        &:first-child{
            margin-left: 5.3vw;
        }
    
        &__inner{
            &__back{
                padding: 20px;
                justify-content: unset;
                a{
                    margin-left:0 !important;
                    margin-top: 8vw;
                    &:first-child{
                        margin-top: 0 !important;
                    }
                }
                &__option{
                    p{
                        width: 65%;
                        font-size: 16px;
                        line-height: 19px;
                    }
                    &:hover{
                        opacity: 0.6;
                        img{
                            transform: unset;
                        }
                    }
                }
            }
        }
    }
    .cn__holi__card3{
        position: relative;
        width: 43.2vw;
        height: 46.935vw;
        flex-shrink: 0;
        box-shadow: 0 2px 20px rgb(87 75 144 / 20%);
        transition: unset;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
        &__info{
            &__title{
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.02em;
                margin-top: 14vw;
            }
            &__subtitle{
                margin-top: 4vw;
                padding: 0 24px;
            }
        }
    }
}