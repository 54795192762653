.modelComponent {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 9999;
	width: 100%;
    height: 100%;
	top: 0%;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 500ms ease;
    overflow-y: auto;

	.modelComponent_content {
		transition: 500ms ease;
		background: $color-white;
		max-width: 90%;
		min-width: 320px;
		padding: 20px 20px 30px 20px;
		border-radius: 15px;
		position: relative;
		top: 0%;
		display: flex;
		flex-direction: column;

		&_header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media screen and (max-width: 660px) {
				margin-right: 12px;
			}
			&_title {
				font-weight: 600;
				font-size: 18px;
				line-height: 120%;
			}
		}

		.modelComponent_Close {
			position: relative;
			height: 22px;
			.modelComponent_Close_icon {
				position: absolute;
				right: 0px;
				width: 22px;
				height: 22px;
				fill: $color-black;
				cursor: pointer;
			}
		}

		.modelComponent_content {
			height: auto;
			overflow-y: auto;
		}
	}

	.plus-content {
		top: 17.5% !important;
		@media screen and (min-height: 1100px) {
			top: 0px !important;
		}
		@media screen and (max-width: 660px) {
			.modelComponent_content_header {
				padding-inline: 20px !important;
			}
			padding-inline: 0 !important;
			max-width: 100vw !important;
			top: 50% !important;
			overflow-x: hidden;
		}
	}

	&.modelComponent-animate {
		opacity: 1;
		.modelComponent_content {
			top: 0px;
			@media screen and (max-width: 600px) {
				max-width: 96vw;
				padding: 20px 5px 20px 5px;
			}
		}
	}
}
