//------------------- DESKTOP -------------------

.cn__CTA__bookinfo3__wrapper{
        display: flex;
        column-gap: 33px;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
        margin-left: 20px;
        @media screen and (max-width: 600px) {
            align-items: flex-start;
            column-gap: 13px;
            margin-left: 30px;
            margin-right: 30px;
        }
        .cn__CTA__bookinfo3__wrapper__text{
            font-weight: 500;
            font-size: 23px;
            line-height: 120%;
            color: $color-pure-black;
            @media screen and (max-width: 600px) {
                font-size: 16px;
                color: $color-black;
            }
        }
}