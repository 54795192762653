.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    svg.drawer-close-icon {
        width: 32px;
        height: 32px; 
        path {
            stroke-width: 0.8px;
            stroke: $color-pure-black; 
        }
    }
}
.sidenav-container {
    .sidenav-listing-itemText-unreadCounts {
        background: $color-primary;
        width:20px;
        height:20px;
        display: inline-block;
        border-radius: 20px;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        line-height: 20px;
    }
    .sidenav-listing {
        height: 38px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:15px 0px; 
        transition: padding 300ms ease;
        border-radius: 10px;
        background-color: transparent;        

        .sidenav-listing-itemIcons {
            min-width: 40px;
        }

        .sidenav-listing-itemText {
            span {
                font-size: 14px;
                color: $color-pure-black;
                transition: color 300ms ease;
            }
        }

        .sidenav-listing-chevronRightIcon {
            position: relative;
            transition: fill 300ms ease;
            fill:$color-subtle-grey;
        }

        &.sidenav-active, &:hover {
            background-color: $color-shade-gray;
            padding:0px 15px;

            .sidenav-listing-chevronRightIcon {
                fill: $color-slate-grey; 
            }
            .sidenav-listing-itemText {
                span {
                    color: $color-slate-grey;
                    font-weight: 600;
                }
            }
        }
    }

    
}