//------------------- MOBILE -------------------

.cn__currency__mobile{
    position: relative;
    margin-top: 40px;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__info{
        position: relative;
        display: flex;
        align-items: center;

        &__info{
            margin-left: 16px;

            &__title{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #242526;
            }
            &__subtitle{
                margin-top: 3px;
                font-weight: 400;
                color: #acabb4;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__portal{
        position: fixed;
        width: 100vw;
        height: 100%;
        left: 100vw;
        background: #FFFFFF;
        z-index: 5000;
        transition: left 0.4s cubic-bezier(0.2, 0.8, 0.4, 1);
        overflow: hidden;

        &.cn__currencymobile--active{
            left: 0;
        }

        &__header{
            position: relative;
            padding: 30px 20px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg{
                z-index: 2;
            }

            &__title{
                position: absolute;
                width: calc(100% - 40px);
                text-align: center;
                //font
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: -0.01em;
                color: #242526;
            }
        }

        &__search{
            position: relative;
            padding: 20px;
            box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.06);

            &__wrapper{
                position: relative;
                padding: 0 16px;
                background: #E9E9EB;
                box-sizing: border-box;
                border-radius: 30px;

                svg{
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-53%);
                    -moz-transform: translateY(-53%);
                    transform: translateY(-53%);
                    right: 16px;
                    z-index: 2;
                }

                input{
                    position: relative;
                    width: calc(100% - 30px);
                    font-size: 14px;
                    line-height: 17px;
                    background: #E9E9EB;
                    color: #797882;
                    outline: none;

                    &:focus-visible{
                        outline: none;
                    }

                    &::placeholder{
                        color: #ACABB4;
                    }
                }
            }
        }  

        &__currencies{
            position: relative;
            width: 100%;
            height: calc(100% - 134px);
            overflow: scroll;

            &__title{
                position: relative;
                padding: 20px 36px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #242526;
            }

            &__top{
                position: relative;
                width: calc(100% - 40px);
                left: 20px;
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-gap: 20px;
                padding-bottom: 20px;
                border-bottom: 0.5px solid #E9E9EB;
            }
            &__bottom{
                position: relative;
                width: calc(100% - 40px);
                left: 20px;
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-gap: 20px;
                padding-bottom: 20px;
            }
        }

        &__searchcurrencies{
            position: relative;
            width: 100%;
            height: calc(100vh - 134px);
            overflow: scroll;
            padding: 20px;
            
            &__empty{
                position: relative;
                width: 100%;
                max-width: 178px;
                margin: 0 auto;
                text-align: center;
                font-size: 14px;
                line-height: 17px;
                color: #242526;
            }
        }
    }
}