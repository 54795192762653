.cn__image-picture{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cn__image-default{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.9);
    @media (max-width: 600px) {
        filter: brightness(0.8);
    }
    transition: opacity .6s ease;
    &.cn__image--notloaded{
        opacity: 0;
    }
}