//--------------------------- DESKTOP/TABLET ---------------------------//
.cn__holihomepage{
    position: absolute;
    width: 100%;
    height: 100%;
    //Header
    .header{
        .max-width-wrapper{
            max-width: 1340px;
            .header-grid{
                grid-template-columns: 50px repeat(7, 1fr 20px) 1fr 50px;
                .header-nav ul li a {
                    color: #FFFFFF;
                    svg {
                        .blog1,.blog2{
                            stroke: $color-white !important;
                        }
                        rect{
                            &.rect1{
                                stroke: $color-white;
                            }
                            &.rect2{
                                fill: $color-white;
                            }
                        }
                        path,circle {
                            stroke: $color-white;
                        }
                        .listsvg{
                            stroke: $color-white !important;
                            fill:none !important;
                        }
                    }
                }
                .header-nav ul li a svg path{
                    fill: #FFFFFF;
                    stroke: none;
                }
            }
        }
        .logo-pink, .logo-purple{
            fill:#FFFFFF !important;
        }
        .hamburguer-menu{
            .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                background: #FFFFFF !important;
            }
        }
        &.sticky{
            .max-width-wrapper{
                .header-grid{
                    .header-nav ul li a {
                        color: #242526;
                        svg {
                            .blog1,.blog2{
                                stroke: $color-black !important;
                            }
                            rect{
                                &.rect1{
                                    stroke: $color-black;
                                }
                                &.rect2{
                                    fill: $color-black;
                                }
                            }
                            path,circle {
                                stroke: $color-black;
                            }
                            .listsvg{
                                stroke: $color-black !important;
                                fill:none !important;
                            }
                        }
                    }
                    .header-nav ul li a svg path{
                        fill: #242526;
                        stroke: none;
                    }
                }
            }
            .logo-pink{
                fill:#fa368b !important;
            } 
            .logo-purple{
                fill:#4c35bc !important;
            }
            .hamburguer-menu{
                .hamburguer-menu-lin1, .hamburguer-menu-lin2{
                    background: #FFFFFF !important;
                }
            }
        }
    }
    //General Title
    .cn__holi__sectitle{
        font-weight: 800;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #242526;
        font-feature-settings: unset;
        font-variant: unset;
        text-transform: unset;
    }
    .cn__holi__secsubtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(36, 37, 38, 0.5);
        margin-top: 10px;
    }
    //Travel Benefits Component
    .why-section{
        margin: 126px auto;
        padding: 0 50px;
        max-width: 1340px;
    }
    //Subscribe
    .subscribe-section{
        padding: 0;
        width: 100%;
        max-width: 1340px;
        margin: 126px auto 100px;
        padding: 0 50px;
        .subscribe-section-con{
            background: radial-gradient(52.51% 52.51% at 50.04% 52.51%, #2B6DAE 0%, #014C96 100%);
            .heading-section{
                h2{
                    font-family: $primary-font;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 34px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #FFFFFF;
                    max-width: 290px;
                    span{
                        font-weight: bold;
                        -webkit-text-stroke: 0;
                    }
                }
                p{
                    max-width: 463px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.9);
                    margin-bottom: 70px;
                }
            }
        }
    }
    //Seo section
    .seo-section{
        padding: 0 50px;
        margin: 100px auto;
        max-width: 1340px;
    }
    //Footer
    .footer{
        .max-width-wrapper{
            max-width: 1340px;
        }
    }
}
//Omnisearch for holi page
.holiomni{
    .omnisearch_input-wrapper_mobile {
        position: absolute;
        width: 100%;
        top: 0;
    }
    .omnisearch_input-wrapper_mobile_input-container{
        top: 48vh;
        margin: 0;
    }
}




//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__holihomepage{
        //Header
        .header{
            .max-width-wrapper{
                .header-grid{
                    grid-template-columns: 30px repeat(7, 1fr 20px) 1fr 30px;
                }
            }
        }
        //Travel Benefits Component
        .why-section{
            margin: 70px auto;
            padding: 0 30px;
        }
        //Subscribe
        .subscribe-section{
            margin: 70px auto;
            padding: 0 30px;
        }
        //Seo section
        .seo-section{
            padding: 0 30px;
            margin: 70px auto;
        }
    }
}
//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__holihomepage{
        overflow-x: hidden;
        //Header
        .header{
            .header-nav ul li a {
                color: #FFFFFF;
            }
            .logo-pink, .logo-purple{
                fill:#FFFFFF !important;
            }
        }
        //General Title
        .cn__holi__sectitle{
            font-size: 28px;
            line-height: 34px;
            padding: 0 20px;
        }
        .cn__holi__secsubtitle{
            padding: 0 20px;
        }
        //Travel Benefits Component
        .why-section{
            margin: 40px auto 0;
            padding: 0;
        }
        //Subscribe
        .subscribe-section{
            max-width: unset;
            margin: 40px auto 0;
            padding: 0 20px;
            .subscribe-section-con{
                .heading-section{
                    h2{
                        font-size: 28px;
                        line-height: 30px;
                        letter-spacing: -0.03em;
                    }
                    p{
                        margin-bottom: 50px;
                    }
                }
            }
        }
        //Seo section
        .seo-section{
            padding: 0 20px;
            margin: 50px auto 30px;
            max-width: unset;
        }
    }
    //Omnisearch for holi page
    .holiomni{
        .omnisearch_input-wrapper_mobile_input-container{
            top: 80vw;
            margin: 0 auto;
            width: calc(100% - 40px);
        }
    }
}