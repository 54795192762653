.product-promo-alert {
    align-items: center;

    &.MuiAlert-root {
        font-family: var(--primary-font);
        border-radius: 15px;
    }

    &.MuiAlert-standardWarning {
        background-color: var(--color-blondie-yellow);
        padding: 20px 35px;
        color: var(--color-prussian-blue);
        letter-spacing: 0.01em;
        line-height: 150%;
        font-size: 16px;
        font-weight: normal;

        .a-other-city {
            color: var(--color-red);
            text-decoration: underline;
        }
    }

    &.MuiAlert-outlinedSuccess {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        border-color: var(--color-field-grey);
        color: var(--color-black);
        box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
    }
}
.product-discount-alert {
    align-items: center;

    &.MuiAlert-root {
        font-family: var(--primary-font);
        border-radius: 15px;
    }

    &.MuiAlert-outlinedSuccess {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: var(--color-very-light-green);
        border-radius: 15px;
        border: 0.5px solid var(--color-very-light-green);
        background: var( --color-light-green);
        box-shadow: 0px 5px 30px 0px rgba(231, 228, 228, 0.50);
    }
}
