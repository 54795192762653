.navTabs-container {
    border-top:solid 1px #EDEDED; 
    border-bottom:solid 1px #EDEDED; 
    width:100%;
    padding:0px 0px;
    overflow: hidden;
    min-height: 84px;
    .simplebar-horizontal {
        height: 2px;
        .simplebar-scrollbar {
            height: 100%;
            top:-1px;
            &:before {
                background: rgba(0,0,0,0.32);
            }
        }
    }
    
    nav {
        display: flex;
        flex-direction: row;
        width:max-content;
        padding:0px 20px;
        min-height: 80px;
        align-items: center;
        .navTabs-buttons-container {
            margin: 0px 5px;
            &:first-child {
                margin-left: 0px;
            }

            &:last-child {
                margin-right: 0px;
            }
            .navTabs-buttons {
                height:44px;
                padding:0px 15px;
                border-radius: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: initial;
                border:none;
                transition: all 100ms ease;
                background: transparent;
                font-size:14px;
                cursor:pointer;
                font-weight: 400;
                font-family: $primary-font;
                color: #000000;
                svg {
                    margin-right: 10px;
                }
                &:hover, &.active {
                    background: $color-shade-gray;
                }
            }
        }
    }
}