@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}


/**
* Size
*/

@mixin sqr_16 {
    width: 16px;
    height: 16px;
}

@mixin sqr_24 {
    width: 24px;
    height: 24px;
}

@mixin sqr_30 {
    width: 30px;
    height: 30px;
}


/**
* Alignments
*/

@mixin align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin align-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/**
* text
*/

@mixin text-normal {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

@mixin text-500 {
    @include text-normal;
    font-weight: 500;
}

@mixin text_big-header {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
}

@mixin text_body-text {
    font-family: $primary-font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

@mixin text_body-text-bold {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

@mixin text_small-header {
    font-family: $primary-font;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
}

@mixin text-subtitle {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    color: var(--color-black-grey);
    letter-spacing: 0.03em;
    font-variant: all-small-caps;
}


/**
* Responsive
*/

@mixin mobile {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 600px) {
        @content;
    }
}

@mixin onlyTablet {
    @media only screen and (min-width: 600px) and (max-width: 1135px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1136px) {
        @content;
    }
}

@mixin large_desktop {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}