.cn__terms__wrapper{
    position: absolute;
    width: 100%;
    background: $color-white;
    .header{
        .sub-menu-currency{
            z-index: 1 !important;
        }
    }
    .cn__shortfooter__wrapper .cn__shortfooter{
        padding: 0 50px 30px;
        @media screen and (max-width: 1136px) {
            padding: 0 30px 30px;
        }
        @media screen and (max-width: 600px) {
            padding: 0 20px 30px;
        }
    }
}

.cn__terms{
    position: relative;
    width: 100%;
    padding: 0 50px;
    max-width: 1600px;
    margin: 40px auto 100px;
    background: $color-white;
    padding-top: 88px;
    //z-index: -1;
    &__title{
        font-weight: bold;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: #000000;
    }
    &__blocks{
        position: relative;
        margin-top: 32px;
        display: grid;
        grid-template-columns: 23.88% 49.25%;
        grid-column-gap: 14.17%;
        &__block1{

            position: sticky;
            top: 32px;
            left: 0;
            align-self: flex-start;
            padding-bottom: 100px;

            &__title{
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #242526;
                text-transform: uppercase;
            }
            ol{
                list-style: none;
                counter-reset: my-awesome-counter;
                li{
                    display: table;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                    color: #ACABB4;
                    margin-top: 2px;
                    counter-increment: my-awesome-counter;
                    margin-left: 24px;
                    cursor: pointer;
                    transition: color 0.3s ease;
                    &:first-child{
                        margin-top: 16px;
                    }
                    &::before{
                        content: counter(my-awesome-counter) ". ";
                        margin-left: -24px;
                        position: absolute;
                    }
                    &:hover{
                        color: #242526;
                    }
                    &.block1__item__active{
                        color: #242526;
                    }
                }
            }
        }
        &__block2{
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #242526;
            &__item{
                &__title{
                    font-weight: 500;
                }
            }
        }
    }
    &__scrollup{
        position: fixed;
        bottom: 30px;
        right: 30px;
        width: 44px;
        opacity: 0;
        visibility: hidden;
        height: 44px;
        border-radius: 50%;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.15);
        z-index: 1;
        cursor: pointer;
        svg{
            margin-top: -2px;
        }
    }
}

//--------------------------- TABLET ---------------------------//
@media screen and (min-width:601px) and (max-width:1136px){
    .cn__terms{
        padding: 0 30px;
        padding-top: 50px;
        &__blocks{
            grid-template-columns: 1fr;
            grid-column-gap: 0;
            grid-row-gap: 30px;
            &__block1{
                position: relative;
                top: 0;
                padding-bottom: 0;
            }
        }
    }
}
//--------------------------- MOBILE ---------------------------//
@media screen and (max-width:600px){
    .cn__terms{
        padding: 0 20px;
        padding-top: 50px;
        &__title{
            font-size: 22px;
            line-height: 26px;
            letter-spacing: -0.01em;
        }
        &__blocks{
            grid-template-columns: 1fr;
            grid-column-gap: 0;
            grid-row-gap: 30px;
            &__block1{
                position: relative;
                top: 0;
                padding-bottom: 0;
            }
        }
        &__scrollup{
            position: fixed;
            bottom: 20px;
            right: 20px;
        }
    }
}