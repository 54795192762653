@import "../../settings/colors";

.cn__dashboard_confirm_cancellation_modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 437px;
	padding: 10px 20px 0 20px;

	&_heading {
		align-self: flex-start;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.01em;
		color: #000000;
	}

	&_subheading {
		align-self: flex-start;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.01em;
		color: #797882;
	}

	&_buttonRow {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-top: 22px;

		&_btn {
			width: 156px;
			padding: 10px 0;
			border-radius: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			cursor: pointer;
		}
		
		.deleteButton {
			border: none;
			background: linear-gradient(92.14deg, #f35597 1.79%, #7868c7 99.81%);
			color: #ffffff;
		}

		.cancelButton {
			border: 1.3px solid #797882;
			background-color: unset;
			color: #797882;
		}
	}
}

.cn__dashboard_cancellation_reason_modal {
	max-width: 599px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&_body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
		margin-top: 10px;
		gap: 20px;

		width: 599px;
		max-width: 100%;

		/* Bright-white */

		background: #ffffff;
		border: 0.5px solid #dfe2e5;
		box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
		border-radius: 15px;
		&_form {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 8px 16px;
			cursor: pointer;
			&_label {
				cursor: pointer;
			}
			&_input {
				appearance: revert;
				height: 16px;
				width: 16px;
				padding: none;
				cursor: pointer;
			}
		}
		.active_form_row {
			background: #eeeef0;
			border-radius: 33px;
		}
		&_text-area {
			background: #f7f7f7;
			border: 0.5px solid #dfe2e5;
			border-radius: 15px;
			padding: 20px;
		}
		&_button {
			width: 322px;
			height: 45px;
			background: linear-gradient(92.14deg, #f35597 1.79%, #7868c7 99.81%);
			border-radius: 20px;
			color: white;
			border: none;
			align-self: center;
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			align-self: center;
			margin-top: 40px;
		}

		&_reciept-button {
			display: flex;
			align-self: center;
			align-items: center;
			justify-content: center;
			padding: 8px 12px 8px 8px;
			gap: 10px;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			/* identical to box height, or 21px */

			letter-spacing: 0.01em;

			/* Blacks/Black */

			color: #000000;

			width: 295px;
			height: 40px;

			/* Bright-white */

			background: #ffffff;
			border: 0.5px solid #dfe2e5;
			border-radius: 100px;
		}
	}
}

.refund-review {
	background: #d83232;
}

.reason-body {
	align-items: center;
	text-align: center;
}
