///
/// FORM
/// <form>
///============================================================================
:root {
  --color-black: #242526;
  --color-black-grey: #555759;
  --color-dark-grey: #797882;
  --color-light-grey: #e9e9eb;
  --color-subtle-white: #f7f7f7;
  --color-subtle-grey: #acabb4;
  --color-vibrant-pink: #E86993;
  --color-red: #f44a3a;
  --color-heavy-red: #da1414;
  --color-bright-white: #feefef;
  --color-shade-grey: #eeeef0;
  --color-purple: #574b90;
  --color-bright-purple: #4c35bc;
  --color-white: #ffffff;
  // Radius
  --radius-default: 15px;
  // Font
  --text_16_500_dark-grey: {
    font-size: 16;
    font-weight: ;
  }
}
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

/**
* Size
*/

@mixin sqr_16 {
  width: 16px;
  height: 16px;
}

@mixin sqr_24 {
  width: 24px;
  height: 24px;
}

@mixin sqr_30 {
  width: 30px;
  height: 30px;
}

/**
* Alignments
*/

@mixin align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin align-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/**
* text
*/

@mixin text-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

@mixin text-500 {
  @include text-normal;
  font-weight: 500;
}

@mixin text_big-header {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
}

@mixin text_body-text {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

@mixin text_body-text-bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@mixin text_small-header {
  font-family: $primary-font;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}

@mixin text-subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: var(--color-black-grey);
  letter-spacing: 0.03em;
  font-variant: all-small-caps;
}

/**
* Responsive
*/

@mixin mobile {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1136px) {
    @content;
  }
}

@mixin large_desktop {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
.align-center {
  @include align-center;
}

.text-500 {
  @include text-500;
}

.color--dark-grey {
  color: var(--color-dark-grey);
}

.col-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

form,
.form {
  $self: &;
  &__field {
    position: relative;
    margin-bottom: 20px;
  }
  &.form--inline {
    display: flex;
    justify-content: space-between;
  }
  .error {
    color: var(--color-red);
    font-size: 14px;
    font-weight: 400 !important;
    -webkit-text-stroke: 0px !important;
  }
  .col-two {
    grid-column-gap: 10px;
  }
}

.n-input-textarea {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  border: 1px solid var(--color-light-grey);
  box-sizing: border-box;
  border-radius: 8px;
  resize: vertical;
  background: transparent;
  color: var(--color-black);
  &:not(:placeholder-shown) {
    background: transparent;
    color: var(--color-black);
  }
  &:placeholder-shown {
    background: var(--color-light-grey);
    border: 1px solid var(--color-light-grey);
  }
}

.n-input-text {
  $self: &;
  position: relative;
  label {
    display: block;
  }
  input {
    background: #e9e9eb;
    border: 1px solid #e9e9eb;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none !important;
    padding: 9px 15px;
    width: 100%;
    font-family: $primary-font;
    &:focus,
    &:not(:placeholder-shown) {
      background: #fff;
    }
  }
  &--label-on-fill {
    #{$self}__label {
      padding: 0.125rem 0.25rem;
      pointer-events: none;
      position: absolute;
      left: 1rem;
      top: 0.638rem;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
      opacity: 0;
    }
    input:focus + span,
    input:not(:placeholder-shown) + span {
      background-color: #fff;
      opacity: 1;
      -webkit-transform: translateY(-100%) translateX(-0.3rem);
      transform: translateY(-100%) translateX(-0.3rem);
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: #797882;
    }
  }
}


.n-input-guest-room-picker {
  border: 1px solid var(--color-light-grey);
  background: var(--color-light-grey);
  box-sizing: border-box;
  border-radius: 6px;
  height: 38px;
  width: 100%;
  position: relative;
  .input-value {
    display: flex;
    justify-content: space-between;
    padding: 7px 10px;
    border-radius: 6px;
    cursor: pointer;
    > span {
      display: flex;
      align-items: center;
      path {
        stroke: var(--color-dark-grey);
      }
    }
    .text-group {
      max-width: calc(100% - 16px);
    }
    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--color-dark-grey);
    }
    > .ic-icon {
      @include align-center;
      position: relative;
      transform: rotate(0deg);
      transition: transform 0.4s ease-in-out;
    }
    .left-icon {
      path {
        stroke: var(--color-dark-grey);
      }
    }
  }
  .input-dropdown {
    position: absolute;
    background-color: var(--color-white);
    top: calc(100% + 6px);
    right: -4px;
    width: 100%;
    min-width: 326px;
    border-radius: 15px;
    padding: 30px;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    ul {
      list-style: none;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        > span {
          @include text-normal;
          color: #242526;
          small {
            display: block;
            color: var(--color-dark-grey);
          }
        }
        &.divider {
          border-bottom: 1px solid #e9e9eb;
        }
        &.li-input {
          display: flex;
          .n-input-text {
            width: calc(100% - 40px);
          }
          .ic-icon {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            @include align-center;
            cursor: pointer;
            &:hover {
              background: #eeeef0;
            }
          }
        }
        &:last-child {
          margin: -15px 0 -20px 0;
          button {
            padding: 15px 0px;
          }
        }
        &.limit {
          margin-bottom: 8px;
          small {
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: var(--color-dark-grey);
          }
        }
      }
    }
    .n-btn--save {
      font-weight: 600;
      &:disabled {
        opacity: 0.3;
        cursor: initial;
      }
    }
  }
  &.open-up {
    .input-dropdown {
      top: auto;
      bottom: calc(100% + 6px);
    }
  }
  &.active {
    .input-value {
      > .ic-icon {
        transform: rotate(180deg);
      }
    }
    .input-dropdown {
      opacity: 1;
      z-index: 9;
      pointer-events: initial;
    }
  }
  &.filled {
    border-color: #acabb4;
    background: transparent;
    .input-value {
      .text {
        color: var(--color-black);
      }
      .left-icon {
        path {
          stroke: var(--color-black-grey);
        }
      }
    }
  }
}

.n-modal--input-guest-room-picker {
  ul {
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      > span {
        @include text-normal;
        color: #242526;
        small {
          display: block;
          color: var(--color-dark-grey);
        }
      }
      &.divider {
        border-bottom: 1px solid #e9e9eb;
      }
      &.li-input {
        display: flex;
        .n-input-text {
          width: calc(100% - 40px);
        }
        .ic-icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          @include align-center;
          cursor: pointer;
          &:hover {
            background: #eeeef0;
          }
        }
      }
      &.limit {
        margin-bottom: 8px;
        small {
          font-family: $primary-font;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.02em;
          color: var(--color-dark-grey);
        }
      }
      &.actions {
        margin: -15px 0 -20px 0;
        button {
          padding: 15px 0px;
        }
      }
    }
  }
  .n-modal__footer {
    display: flex;
    justify-content: space-between;
    padding: 5px 28px 20px 28px;
    .n-btn {
      padding: 4px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .n-btn--save {
      font-weight: 600;
      border-radius: 8px;
      &:disabled {
        opacity: 0.3;
        cursor: initial;
      }
    }
  }
  &.n-modal--fullscreen {
    .n-modal__footer {
      padding: 20px;
    }
  }
}

:root {
  --item-height-calendar: auto;
  --radius-calendar: 50%;
}

@media only screen and (max-width: 330px) {
  :root {
    --container-width-calendar: 300px;
    --item-height-calendar: 43px;
    --radius-calendar: 50%;
  }
}

@media only screen and (min-width: 330px) and (max-width: 380px) {
  :root {
    --container-width-calendar: 355px;
    --item-height-calendar: 48px;
    --radius-calendar: 50%;
  }
}

@media only screen and (min-width: 380px) and (max-width: 420px) {
  :root {
    --container-width-calendar: 390px;
    --item-height-calendar: 53px;
    --radius-calendar: 50%;
  }
}

@media only screen and (min-width: 420px) {
  :root {
    --container-width-calendar: 420px;
    --item-height-calendar: 56px;
    --radius-calendar: 50%;
  }
}

@media only screen and (min-width: 560px) {
  :root {
    --container-width-calendar: 560px;
    --item-height-calendar: 77px;
    --radius-calendar: 50%;
  }
}

/* from 600px to higher would be tablet and desktop view */

@media only screen and (min-width: 600px) {
  :root {
    --container-width-calendar: auto;
    --item-height-calendar: 41px;
    --radius-calendar: 50%;
  }
}

.n-input-date-range-picker {
  border: 1px solid var(--color-light-grey);
  background: var(--color-light-grey);
  box-sizing: border-box;
  border-radius: 6px;
  height: 38px;
  position: relative;
  .input-value {
    display: flex;
    justify-content: flex-start;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    .text {
      padding-top: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 24px);
      color: var(--color-dark-grey);
    }
    .left-icon {
      path {
        stroke: var(--color-dark-grey);
      }
    }
  }
  .input-dropdown {
    background: var(--color-white);
    position: absolute;
    top: calc(100% + 6px);
    right: -4px;
    min-width: 346px;
    border-radius: 15px;
    z-index: -1;
    opacity: 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }
  .input-dropdown-calendar {
    height: calc(100vh - 230px);
    margin-top: 46px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    .DayPicker__withBorder {
      box-shadow: none !important;
    }
    .CalendarDay__default {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      //height: var(--item-height-calendar) !important;
      // &:not(.CalendarDay__blocked_calendar) {
      //   &:hover {
      //     background: var(--color-shade-grey);
      //     color: var(--color-black);
      //   }
      // }
      // &:not(.CalendarDay__selected_span) {
      //   &:hover {
      //     border-radius: var(--radius-calendar);
      //   }
      // }
    }
    // .CalendarDay__selected_start+.CalendarDay__selected_span::before,
    // .CalendarDay__selected_span::before {
    //     background: transparent;
    // }
    // .CalendarDay__today {
    //   color: var(--color-bright-purple);
    // }
    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
      //background: #fff !important;
      &:before {
        background: transparent;
      }
    }
    .CalendarDay__selected_start {
      //background: transparent;
      // &::before {
      //   border-radius: var(--radius-calendar);
      //   background: var(--color-vibrant-pink);
      //   position: absolute;
      //   content: ' ';
      //   width: 100%;
      //   height: 100%;
      //   top: 0px;
      //   left: 0px;
      //   z-index: -1;
      // }
      &.CalendarDay__selected_start_in_hovered_span,
      &:hover {
        // background: var(--color-shade-grey);
        // border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
        //color: var(--color-white) !important;
      }
    }
    // .CalendarDay__hovered_span {
    //   background: var(--color-shade-grey) !important;
    //   border-radius: 0;
    //   &:hover {
    //     border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
    //   }
    //   &.CalendarDay__lastDayOfWeek {
    //     border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
    //     &:before {
    //       display: none;
    //     }
    //   }
    //   &.CalendarDay__firstDayOfWeek {
    //     border-top-left-radius: var(--radius-calendar) !important;
    //     border-bottom-left-radius: var(--radius-calendar) !important;
    //     &:before {
    //       display: none;
    //     }
    //   }
    // }
    .CalendarMonth_table
      > tbody
      > tr:first-child
      td:not([class])
      + td.CalendarDay__default {
      &.CalendarDay__selected_span,
      &.CalendarDay__hovered_span {
        // border-top-left-radius: var(--radius-calendar) !important;
        // border-bottom-left-radius: var(--radius-calendar) !important;
      }
    }
    .CalendarDay__after_hovered_start {
      border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0;
      &.CalendarDay__blocked_minimum_nights {
        border-radius: 0px;
      }
    }
    .CalendarDay__selected_span {
      border-radius: 0px;
      &.CalendarDay__lastDayOfWeek {
        border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
        &:before {
          display: none;
        }
      }
      &.CalendarDay__firstDayOfWeek {
        border-radius: var(--radius-calendar) 0 0 var(--radius-calendar) !important;
        &:before {
          display: none;
        }
      }
      &.CalendarDay__firstDayOfWeek.CalendarDay__lastDayOfWeek {
        border-radius: var(--radius-calendar) !important;
      }
    }
    .CalendarDay__selected_end {
      //border-radius: 0 var(--radius-calendar) var(--radius-calendar) 0 !important;
      //color: var(--color-white) !important;
      // &.CalendarDay__firstDayOfWeek {
      //   background: transparent !important;
      // }
      // &::before {
      //   border-radius: var(--radius-calendar);
      //   background: var(--color-vibrant-pink);
      //   position: absolute;
      //   content: ' ';
      //   display: block !important;
      //   width: 100%;
      //   height: 100%;
      //   top: 0px;
      //   left: 0px;
      //   z-index: -1;
      // }
    }
    .CalendarDay__selected_start + .CalendarDay__selected_span,
    .CalendarDay__selected_span {
    }
    .DayPicker_transitionContainer__verticalScrollable {
      // height: 100% !important;
      overflow-x: hidden;
    }
    .CalendarMonthGrid__vertical,
    .CalendarMonthGrid__vertical_scrollable {
      // width: var(--container-width-calendar) !important;
    }
    .DayPickerNavigation__verticalDefault::before {
      display: none;
    }
    .DayPickerNavigation__verticalDefault {
      border-radius: 0px;
      box-shadow: 0 0 10px #e9e9eb;
      left: -3px;
      bottom: 0px;
      width: calc(100% + 6px);
      border: none;
    }
    .DayPickerNavigation__verticalScrollable_prevNav {
      display: none;
    }
    .DayPicker_weekHeader__verticalScrollable {
      border-bottom: none;
      top: -5px;
      ul {
        li {
          small {
            color: var(--color-dark-grey);
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            // font-size: 16px;
            // line-height: 19px;
          }
        }
      }
    }
  }
  .input-dropdown-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    .n-btn {
      border-radius: 8px;
      padding: 4px 15px;
    }
  }
  .input-dropdown-form {
    display: grid;
    grid-template-columns: 1fr 30px 1fr;
    grid-column-gap: 15px;
    padding: 0 20px;
    max-width: 414px;
    margin-left: auto;
    margin-right: auto;
    > span {
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-dark-grey);
    }
    .input-dropdown-form_startdate {
      margin-left: auto;
    }
  }
  &.n-modal {
    border: none;
    background: none;
    &.n-modal--fullscreen {
      .n-modal__header {
        padding: 15px;
        height: 54px;
      }
      .n-modal__dialog {
        background: var(--color-white);
      }
      .n-modal__content {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
      }
      .input-dropdown-footer {
        .btn--cancel {
          color: var(--color-dark-grey) !important;
        }
        .btn--save {
          font-weight: 600;
        }
      }
    }
  }
  &.horizontal {
    .input-dropdown {
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .input-dropdown-calendar {
      overflow: hidden;
      // padding-bottom: 38px;
      margin-top: 0px;
      height: auto;
      max-width: 100%;
      .DayPicker_transitionContainer {
        overflow: visible !important;
      }
    }
  }
  &.disabled-prev-nav {
    .DayPickerNavigation_leftButton__horizontalDefault {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  &.active {
    .input-dropdown {
      opacity: 1;
      z-index: 9;
      pointer-events: initial;
    }
  }
  &.open-up {
    .input-dropdown {
      top: auto;
      bottom: calc(100% + 6px);
      .DateRangePicker_picker__directionRight {
        right: 0px !important;
      }
    }
  }
  &.open-left {
    .input-dropdown {
      right: auto;
      left: 0px;
      .DateRangePicker_picker__directionLeft {
        left: 0;
        right: 0px !important;
        top: 0px !important;
      }
    }
  }
  &.filled {
    border-color: #acabb4;
    background: transparent;
    .input-value {
      .text {
        color: var(--color-black);
      }
      .left-icon {
        path {
          stroke: var(--color-black-grey);
        }
      }
    }
  }
  &.error {
    border: 2px solid var(--color-red) !important;
    .input-value {
      padding: 5px 10px;
    }
  }
}

/*
.n-input-date-range-picker {
    border: 1px solid var(--color-light-grey);
    background: var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    .input-value {
        display: flex;
        justify-content: flex-start;
        padding: 6px 10px;
        border-radius: 6px;
        cursor: pointer;
        .text {
            padding-top: 2px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 24px);
            color: var(--color-dark-grey);
        }
        .left-icon {
            path {
                stroke: var(--color-dark-grey);
            }
        }
    }
    .input-dropdown {
        background: var(--color-white);
        width: 628px;
        position: absolute;
        top: calc(100% + 6px);
        right: -4px;
        min-width: 346px;
        border-radius: 15px;
        padding: 5px;
        height: 380px;
        z-index: -1;
        opacity: 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        pointer-events: none;
        .DateRangePicker {
            .DayPicker__withBorder {
                box-shadow: none;
            }
            .DateRangePicker_picker__directionRight {
                top: 0px !important;
            }
            .DayPicker_transitionContainer {
                min-height: 360px;
            }
            .DateRangePickerInput {
                width: auto;
                display: flex !important;
                background: transparent;
                max-width: 300px;
                .DateInput {
                    opacity: 0;
                    height: 0px;
                    overflow: hidden;
                }
                .DateRangePickerInput_arrow {
                    opacity: 0;
                    height: 0px;
                    overflow: hidden;
                }
            }
            .DateInput_fangStroke {
                stroke: #ffffff;
            }
            .DayPicker_weekHeader {
                top: 51px;
            }
            .DayPickerNavigation_button__horizontalDefault {
                top: 8px;
            }
            .CalendarMonth_caption {
                padding-bottom: 46px;
                padding-top: 12px;
            }
            .CalendarDay__blocked_calendar,
            .CalendarDay__blocked_calendar:active,
            .CalendarDay__blocked_calendar:hover {
                color: #ACABB4;
                text-decoration: line-through;
                background: transparent;
            }
        }
    }
    &.open-up {
        .input-dropdown {
            top: auto;
            bottom: calc(100% + 6px);
            .DateRangePicker_picker__directionRight {
                right: 0px !important;
            }
        }
    }
    &.open-left {
        .input-dropdown {
            right: auto;
            left: 0px;
            .DateRangePicker_picker__directionLeft {
                left: 0;
                right: 0px !important;
                top: 0px !important;
            }
        }
    }
    &.active {
        .input-dropdown {
            opacity: 1;
            z-index: 9;
            pointer-events: initial;
        }
    }
    &.filled {
        border-color: #ACABB4;
        background: transparent;
        .input-value {
            .text {
                color: var(--color-black);
            }
            .left-icon {
                path {
                    stroke: var(--color-black-grey);
                }
            }
        }
    }
    &.error {
        border: 2px solid var(--color-red) !important;
        .input-value {
            padding: 5px 10px;
        }
    }
}

.n-modal--input-date-range-picker {
    .DateRangePicker_picker {
        top: calc(100% + 30px) !important;
        width: 100%;
    }
    .DateInput_input {
        box-shadow: none;
    }
    &.n-modal--fullscreen {
        .n-modal__header {
            padding: 5px;
            height: 30px;
        }
    }
    .DayPickerNavigation__verticalDefault::before {
        display: none;
    }
    .n-modal__dialog {
        background: var(--color-white);
    }
    .n-modal__content {
        padding: 15px 10px 20px !important;
    }
    .n-modal__footer {
        display: flex;
        justify-content: space-between;
        padding: 20px !important;
        z-index: 4;
        .n-btn--save {
            font-weight: 600;
            &:disabled {
                opacity: 0.3;
                cursor: initial;
            }
        }
    }
    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
        color: #ACABB4;
        text-decoration: line-through;
        background: transparent;
    }
    .DateRangePickerInput_arrow {
        color: var(--color-dark-grey);
    }
    .DateInput {
        margin-bottom: 5px;
    }
    @media (min-width: 360px) and (max-width: 375px) {
        .DateRangePicker__block {
            width: 340px;
            margin: auto;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            min-width: 340px;
        }
        .CalendarDay__default {
            height: 44px !important;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }
        .DayPicker_transitionContainer__vertical {
            overflow-x: hidden;
        }
        .DayPicker_weekHeader {
            top: 5px;
            width: 336px;
            ul {
                li {
                    small {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    @media (min-width: 376px) {
        .DayPicker_transitionContainer {
            height: calc(100vh - 200px) !important;
        }
        .DayPicker_weekHeader__verticalScrollable {
            border: none !important;
        }
        .DayPickerNavigation__verticalScrollable_prevNav {
            display: none;
        }
        .DateRangePicker__block {
            width: 356px;
            margin: auto;
        }
        .CalendarMonthGrid__vertical,
        .CalendarMonthGrid__vertical_scrollable {
            min-width: 356px;
        }
        .CalendarDay__default {
            height: 48px !important;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }
        .DayPicker_transitionContainer__vertical {
            overflow-x: hidden;
        }
        .DayPicker_weekHeader {
            top: 5px;
            width: 336px;
            // padding: 0px !important;
            // margin-left: -166px !important;
            ul {
                li {
                    small {
                        font-family: $primary-font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
*/

.n-input-tag-selector {
  ul {
    list-style: none;
    display: flex;
    li {
      border-radius: 15px;
      background: var(--color-shade-grey);
      padding: 5px 10px;
      margin: 0 7px 0 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.02em;
      color: var(--color-black);
      cursor: pointer;
      &.active {
        background: var(--color-dark-grey);
        color: var(--color-white);
      }
    }
  }
}

.input {
  &--date-range-picker {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    border: 1px solid #acabb4;
    box-sizing: border-box;
    border-radius: 6px;
    height: 38px;
    .ic-icon {
      margin-right: 6px;
    }
    .DateRangePicker {
      width: 118px;
      .DateRangePickerInput {
        width: auto;
        display: flex !important;
        .DateInput {
          .DateInput_input {
            height: 34px;
            box-shadow: none;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        .DateRangePickerInput_arrow {
          background: transparent;
          height: 35px;
          width: 20px;
          overflow: visible;
          position: relative;
          text-indent: unset;
          font-size: 28px;
        }
      }
    }
  }
}
.n-btn {
  font-family: $primary-font;
  border: 1px solid transparent;
  background: transparent;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-start;
  border-radius: 6px;
  padding: 8px 22px;
  color: var(--color-dark-grey);
  outline: none;
  cursor: pointer;
  &.n-btn--back {
    font-weight: 400;
  }
  .ic-icon {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
  .n-btn--loading {
    height: 20px;
  }
  &--disabled {
    // opacity: 0.3;
    color: var(--color-dark-grey);
    pointer-events: none;
  }
  &--full-width {
    width: 100%;
  }
  &--left-icon {
    flex-direction: row-reverse;
    .ic-icon {
      margin-left: 0px;
    }
  }
  &--nopadding {
    padding-left: 0px;
    padding-right: 0px;
  }
  &--space-between {
    justify-content: space-between;
  }
  &--color-black {
    color: var(--color-black);
  }
  &--color-bright-purple {
    color: var(--color-bright-purple);
  }
  &--color-vibrant-pink {
    color: var(--color-vibrant-pink);
  }
  &--color-fill-vibrant-pink {
    background: var(--color-vibrant-pink);
    color: var(--color-white);
  }
  &--color-purple {
    color: var(--color-purple);
  }
  &--color-fill-purple {
    color: var(--color-white);
    background: var(--color-purple);
  }
  &--color-subtle-grey {
    color: var(--color-subtle-grey);
  }
  &--color-fill-subtle-grey {
    color: var(--color-white);
    background: var(--color-subtle-grey);
  }
}
.form__footer {
  .n-btn {
    &--disabled {
      color: var(--color-dark-grey);
      pointer-events: none;
    }
  }
}
.n-modal {
  $self: &;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: -9;
  pointer-events: none;
  &--active {
    z-index: 991;
    opacity: 1;
    pointer-events: auto;
    .modal-dialog {
      margin: 2.75rem auto 0;
      pointer-events: auto;
    }
  }
  &__cover {
    background: rgba(100, 100, 100, 0.5);
    transition: all 0.1s ease-in-out;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  &__dialog {
    background: #f7f7f7;
    border-radius: 15px;
    min-height: 150px;
    margin: 30px auto;
    transition: all 0.4s ease-in-out;
    max-width: 660px;
    width: 90%;
    z-index: 992;
    &__title {
      position: relative;
      display: flex;
      padding: 10px 15px;
      .back {
        height: 40px;
        width: 40px;
        padding: 7px;
        margin: 0 10px 0 0;
        cursor: pointer;
      }
      .close {
        background: #aaa;
        position: absolute;
        top: 7px;
        right: 7px;
        padding: 5px 7px;
        border-radius: 50%;
        cursor: pointer;
        width: 34px;
        height: 34px;
        z-index: 999;
        cursor: pointer;
        svg {
          height: 12px;
          width: 12px;
        }
      }
      h1 {
        margin: 0px;
      }
    }
    &__body {
      position: relative;
      padding: 10px 15px;
    }
  }
  &__header {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px;
    &:after {
      width: calc(100% - 60px);
      left: 30px;
      bottom: 0px;
      position: absolute;
      content: ' ';
      height: 1px;
      background-color: var(--color-light-grey);
    }
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.01em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 30px;
    }
    #{$self}__back {
      position: absolute;
      left: 0px;
      top: 27px;
      cursor: pointer;
      flex-direction: row-reverse;
      @include text-normal;
      align-items: center;
      padding-top: 0px;
      .ic-icon {
        height: 30px;
        width: 30px;
        margin: 0px;
      }
    }
  }
  &__footer {
    background: #f7f7f7;
    &--empty {
      padding: 10px;
      border-radius: 0 0 15px 15px;
    }
  }
  &__content {
    padding: 30px;
    overflow-y: auto;
    // 88px for header, 10px for footer, 60px for margin
    max-height: calc(100vh - 88px - 10px - 60px);
  }
  &__close {
    position: absolute;
    right: 30px;
    top: 32px;
    cursor: pointer;
  }
  &--fullscreen {
    #{$self}__dialog {
      margin: 0px;
      border-radius: 0px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
    }
    #{$self}__header {
      padding: 30px;
      height: 80px;
      max-height: 80px;
      &:after {
        display: none;
      }
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.01em;
      }
      #{$self}__close {
        top: 20px;
        right: 20px;
      }
    }
    #{$self}__content {
      padding: 20px;
      height: 100%;
      max-height: calc(100vh - 80px);
      overflow-x: hidden;
    }
    #{$self}__footer {
      padding: 0px;
      border: 1px solid #e9e9eb;
      box-sizing: border-box;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      height: 80px;
      max-height: 80px;
      position: relative;
      #{$self}__back {
        cursor: pointer;
        @include text-normal;
        width: 100%;
        padding: 25px;
        justify-content: center;
        .ic-icon {
          height: 30px;
          width: 30px;
          margin: 0px;
          position: absolute;
          left: 10px;
          top: 21px;
        }
      }
    }
    #{$self}--has-header {
      #{$self}__content {
        max-height: calc(100vh - 160px);
      }
    }
  }
  &--not-scrollable {
    #{$self}__content {
      overflow: hidden;
    }
  }
}
