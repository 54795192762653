//---------DESKTOP---------
.cn__cnt__what {
  position: relative;
  font-size: 24px;
  margin: 0 auto;
  padding-top: 200px;
  width: 100%;

  & h1 {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -0.03em;
    color: #ed2f33;
  }
  & h4 {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #1d1c1f;
    margin-top: 54px;
  }
  & p {
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: rgba(29, 28, 31, 0.45);
    width: 87%;
    margin-top: 30px;
    & span {
      color: #ed2f33;
      text-decoration: underline;
      a{
        color: inherit;
      }
    }
  }

  & .cn__cnt__what_near_1 {
    margin-top: 50px;
    & h4 {
      margin-top: 0;
    }
    .cn__cnt__what_near_p {
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: rgba(29, 28, 31, 0.45);
    }
  }
  & .cn__cnt__what_near_2 {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    .slick-track{
      padding: 0;
    }
    .cnt__card2{
      height: 173px;
    }
  }
}
 //---------TABLET---------
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .cn__cnt__what {
    padding:0 30px;
    padding-top: 200px;
    & p {
      width: 100%;
    }
    & .cn__cnt__what_near_2 {
      margin-top: 15px;
      grid-column-gap: 10px;
      .slick-track{
        padding: 0;
      }
      .slick-slide{
        padding: 0 5px;
      }
      .cnt__card2{
        height: 14.28vw;
      }
    }
  }
}
//---------MOBILE---------
@media screen and (max-width: 600px) {
  .cn__cnt__what {
    margin: 0 auto;
    padding: 0;
    padding-top: 100px;

    & h1 {
      font-size: 32px;
      line-height: 36px;
      padding: 0 20px;
    }
    & h4 {
      margin-top: 40px;
      font-size: 24px;
      line-height: 26px;
      padding: 0 20px;
    }
    & p {
      padding: 0 20px;
      width: 100%;
      margin-top: 20px;
      font-size: 19px;
      line-height: 23px;

    }

    & .cn__cnt__what_near_1 {
      margin-top: 40px;
      & h4 {
        margin-top: 0;
      }
      .cn__cnt__what_near_p {
        font-size: 17px;
        line-height: 21px;
      }
    }
    & .cn__cnt__what_near_2 {
      margin-top: 0;
      display: unset;
      .slidermobile__wrapper{
        padding: 15px 20px 20px 0;
      }
    }

    .cnt__card2{
      height: 162px;
    }
  }
}
