.card--booking-form {
    padding: 20px;
    // padding: 20px 20px 30px 20px;
    .list {
        list-style: none;
        li {
            @include align-space-between;
            margin-bottom: 20px;
            &:last-child {
                margin-top: 10px;
            }
            &.total-price {
                .left-text,
                span {
                    font-weight: 500;
                    color: var(--color-black);
                }
            }
            .left-text {
                display: flex;
            }
            span {
                @include text-normal;
                @include align-center;
                .ic-icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    p {
        @include text-normal;
    }
    .n-btn--book {
        margin-bottom: 20px;

        @media screen  and (max-width: 600px) {
            background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
            border-radius: 30px;
        }
    }
    &__header {
        .list {
            li {
                color: var(--color-black);
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        .fade-book { 
            background: var(--color-fieldinactive-grey) !important; 
            color: var(--color-dark-grey);
        }
        .n-btn {
            justify-content: center;
        }
        p {
            display: flex;
            justify-content: flex-start;
            >span {
                height: 29px;
            }
            &:last-child {
                justify-content: flex-end;
            }
        }
        .not-available {
            justify-content: center !important;
            color: var(--color-red);
            grid-area: 1 / 1 / 1 / end;
            text-align: center;
            padding-bottom: 20px;
        }
    }
    @include tablet {
        &__content {
            @media (min-width: 600px) {
                display: flex;
            }
            @media (min-width: 618px) {
                display: grid;
            }
            grid-template-columns: 2fr 1fr;
            .input-order {
                form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px;
                }
                p {
                    justify-content: flex-start;
                }
            }
            .input-detail {
                p {
                    @media (min-width: 618px) {
                        font-size: 14px;
                    }

                    @media (min-width: 700px) {
                        font-size: 1rem;
                    }
                    justify-content: flex-end;
                }
                .n-btn {
                    margin-top: 15px;
                    margin-bottom: 0px;
                    margin-left: auto;
                    background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                    border-radius: 30px;
                }
            }
        }
    }
    @media (min-width: 1154px) {
        opacity: 0;
        animation: appear .3s ease-in-out forwards;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0px 2px 20px rgba(36, 37, 38, 0.15);
        &__content {
            @include align-center;
            display: flex;
            flex-direction: column;
            padding-top: 30px;
            .fade-book {
                background: var(--color-fieldinactive-grey) !important; 
                color: var(--color-dark-grey);
            }
            .n-btn {
                margin-bottom: 10px;
                @include align-center;
                height: 44px;
                width: 100%;
                /* border-radius: 10px;
                background-image: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%); */
                background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                border-radius: 30px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: var(--color-dark-grey);
            }
        }
        .card--booking-form__header {
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 20px;
        }
    }
}

.n-modal-price {
    .list {
        list-style: none;
        margin-bottom: 40px;
        li {
            @include align-space-between;
            margin-bottom: 20px;
            color: var(--color-black);
            &:last-child {
                margin-top: 10px;
            }
            &.divider {
                height: 1px;
                margin: 25px 0;
                background: #E9E9EB;
            }
            &.total-price {
                .left-text,
                span {
                    font-weight: 500;
                }
            }
            .left-text {
                @include text-normal;
                @include align-center;
                font-weight: 400;
            }
            span {
                .ic-icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    @include tablet {
        .n-modal__content {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

.booking-form__aditional-charge {
    p {
        color: var(--color-dark-grey);
        margin-bottom: 20px;
    }
    .list {
        li {
            margin-bottom: 20px;
            span {
                font-family: $primary-font;
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: var(--color-dark-grey);
            }
            &:last-child {
                margin: 0px;
            }
        }
    }
}