//------------------- DESKTOP -------------------

.cn__hp__gonearby{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;

    .cn__slider{
        position: relative;
        margin-top: 15px;
    }

    /* &__slider1{
        position: relative;
        margin-top: 25px;
        width: 100%;
        .slick-list{
            overflow: hidden;
            margin-left: -8px;
            padding: 10px 0;
        }
        .slick-track{
            display: inline-flex;
        }
        .slick-arrow{
            background-color: transparent;
            transform-origin: center center;
            border: 2px solid rgba(121, 120, 130, 0.6);;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 0;
            svg{
                height: 10px;
                path{
                    stroke: rgba(121, 120, 130, 0.6);;
                    stroke-width: 4px;
                }
            }
            &.slick-next{
                position: absolute;
                right: 0;
                top: -44px;
                display: inline-flex;
                svg{
                    margin-left: 2px;
                }
            }
            &.slick-prev{
                position: absolute;
                right: 38px;
                top: -44px;
                display: inline-flex;
                left: auto;
                svg{
                    margin-left: -2px;
                }
            }
            &.slick-disabled{
                opacity: 0.5;
            }
        }
    } */
    &__slider1not{
        position: relative;
        width: 100%;
        height: 300px;
        background: #F8F2FC;
        border-radius: 20px 0 0 20px;
        margin-top: 25px;
    }
    /* &__slider__card{
        position: relative;
        flex-shrink: 0;
        width: 15vw;
        height: 20.834vw;
        max-width: 216px;
        max-height: 300px;
        overflow: hidden;
        border-radius: 15px;
        transition: all 0.35s ease;
        a{
            color: #242526;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: brightness(0.9);
                transition: all 0.35s ease;
            }
            .cn__hp__gonearby__slider__card__info{
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 1.1vw;
                color: #FFFFFF;
                &__title{
                    font-weight: 900;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: -0.04em;
                    text-transform: uppercase;
                }
                &__subtitle{
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 19px;
                    color: rgba(255, 255, 255, 0.8);
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        &:hover{
            transform: translateY(-5px);
            box-shadow: 0px 15px 10px -10px rgba(125,125,125,0.6);
            a{
                img{
                    filter: brightness(0.95);
                }
            }
        }
    } */

    //------------ SLIDER WRAPPER-----------
    /* .slidermobile__wrapper{
        display: inline-flex!important;
        width: 100%;
        overflow-x: scroll;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        padding: 25px 0 30px;
        scroll-padding: 0 0 0 20px;
    }
    @media screen and (max-width:1136px){
        .slidermobile__wrapper{
            padding: 0 30px 10px 0;
            scroll-padding: 0 0 0 30px;
        }
    }

    @media screen and (max-width:600px){
        .slidermobile__wrapper{
            padding: 0 20px 10px 0;
            display: flex;
            width: 100%;
            overflow-x: unset;
            -ms-scroll-snap-type: unset;
            scroll-snap-type: unset;
            scroll-padding: unset;
        }
    }
    .simplebar-track.simplebar-horizontal{
        background: #FFFFFF;
        visibility: visible !important;
        height: 10px;
        z-index: 0;
        .simplebar-scrollbar{
            width: 100%;
            background: #FFFFFF;
            &::before{
                width: 100%;
                background:#FFFFFF;
            }
        }
    } */
    //------------ SLIDER WRAPPER-----------
}
//DESKTOP 1280
@media screen and (min-width:1280px){
    .cn__hp__gonearby{
        &__slider1{
            .slick-list{
                margin-left: -10px;
            }
            .slick-slide {
                padding: 0 10px;
            }
        }
        &__slider1not{
            height: 23.438vw;
            max-height: unset;
            margin-top: 42px;
        }
        &__slider__card{
            width: 14.532vw;
            height: 23.438vw;
            max-width: unset;
            max-height: unset;
        }
    }
}
//DESKTOP 1366
@media screen and (min-width:1366px){
    .cn__hp__gonearby{
        &__slider1not{
            height: 21.962vw;
            max-height: unset;
            margin-top: 42px;
        }
        &__slider__card{
            width: 15.301vw;
            height: 21.962vw;
            max-width: unset;
            max-height: unset;
        }
    }
}
//DESKTOP 1440
@media screen and (min-width:1440px){
    .cn__hp__gonearby{
        &__slider1not{
            height: 20.834vw;
            margin-top: 42px;
        }
        &__slider__card{
            width: 15vw;
            height: 20.834vw;
            max-width: unset;
            max-height: unset;
        }
    }
}
//DESKTOP 1536
@media screen and (min-width:1536px){
    .cn__hp__gonearby{
        &__slider1not{
            height: 19.532vw;
            margin-top: 42px;
        }
        &__slider__card{
            width: 15.3vw;
            height: 19.532vw;
            max-width: unset;
            max-height: unset;
        }
    }
}
//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__gonearby{
        &__slider1{
            margin-top: 42px;
            .slick-slide {
                padding: 0 12.5px;
            }
        }
        &__slider1not{
            height: 423px;
            margin-top: 42px;
        }
        &__slider__card{
            width: 305px;
            height: 423px;
            max-width: unset;
            max-height: unset;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__hp__gonearby{
        padding: 0;
        margin: 60px auto;
        &__slider1{
            display: block;
        }
        &__slider1not{
            width: calc(100% - 30px);
            margin-left: 30px;
        }
        &__slider__card{
            width: 208px;
            height: 289px;
            flex-shrink: 0;
            margin-left: 15px;
            scroll-snap-align: start;
            scroll-snap-stop: normal;
            &:first-child{
                margin-left: 30px;
            }
            a{
                .cn__hp__gonearby__slider__card__info{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 20px;
                    color: #FFFFFF;
                    &__title{
                        font-weight: 900;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: -0.04em;
                        text-transform: uppercase;
                    }
                    &__subtitle{
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 19px;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            &:hover{
                transform: none;
                box-shadow: none;
                a{
                    img{
                        filter: brightness(0.7);
                    }
                }
            }
        }
    }
}


//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__hp__gonearby{
        margin: 60px auto 74px auto;
        &__slider1{
            margin-top: 30px;
        }
        &__slider1not{
            width: calc(100% - 20px);
            height: 210px;
            margin-top: 30px;
            margin-left: 20px;
        }
        &__slider__card{
            width: 150px;
            height: 167px;
            margin-left: 10px;
            &:first-child{
                margin-left: 20px;
            }
            a{
                .cn__hp__gonearby__slider__card__info{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 15px;
                    color: #FFFFFF;
                    &__title{
                        font-size: 18px;
                    }
                }
            }
        }
        /* .simplebar-track.simplebar-horizontal{
            background: #FFFFFF;
            visibility: visible !important;
            height: 24px;
            width: calc(100% - 40px);
            bottom: -14px;
            margin-left: 20px;
            overflow: visible;
            pointer-events: all;
            cursor: pointer;
            z-index: 0;
            &::before{
                position: absolute;
                content: '';
                width: calc(100% + 40px);
                height: 100%;
                left: -20px;
                background: #FFFFFF;
                z-index: -1;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                bottom: 1px;
                background: #F4F4F5;
                border-radius: 5px;
                z-index: -1;
            }
            .simplebar-scrollbar{
                width: 100%;
                height: 4px;
                top: auto;
                bottom: 0;
                border-radius: 5px;
                background: #f466a2;
                &::before{
                    display: none;
                }
            }
        } */
    }
}
