:root {
    --help-top-position: 100px;
    background: $color-white;
}

//--------------------------- MOBILE ---------------------------//
.cn__help__wrapper{
    position: absolute;
    width: 100%;
    overflow-x: hidden;
    background: $color-white;
    //Footer
    .cn__shortfooter__wrapper .cn__shortfooter{
        padding: 0 50px 30px;
        @media screen and (max-width: 1136px) {
            padding: 0 30px 30px;
            padding-top: 88px;
        }
        @media screen and (max-width: 600px) {
            padding: 0 20px 30px;
            padding-top: 88px;
        }
    }
    .cn__help{
        position: relative;
        width: 100%;
        padding: 0 20px;
        padding-top: 88px;
        
        &__mobile{
            position: relative;
            display: table;
            width: 100%;
            color: #000000;
            &__intro{
                display: table;
                width: 100%;
            }
            &__title{
                position: relative;
                font-weight: 700;
                font-size: 32px;
                line-height: 43px;
                letter-spacing: -0.025em;
                margin-top: 50px;
                text-align: center;
            }
            &__cards{
                position: relative;
                margin-top: 50px;
                &__card{
                    position: relative;
                    width: 100%;
                    border: 1px solid ;
                    border-radius: 15px;
                    padding: 25px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;
                    &:first-child{
                        margin-top: 0;
                    }
                    &:last-child{
                        margin-bottom: 58px;
                    }
                    &__info{
                        p{
                            font-weight: 700;
                            font-size: 25px;
                            line-height: 20px;
                            text-align: right;
                            color: #242526;
                            margin-top: 10px;
                        }
                    }
                }
            }
            &__detail{
                position: relative;
                margin-top: var(--help-top-position);
                left: 100vw;
                width: calc(100% + 40px);
                height: 100%;
                padding: 0 20px;
                padding-bottom: 10px;
                background: $color-white;
                transition: left 0.6s ease;
                &__title{
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                    z-index: 0;
                    svg{
                        z-index: 2;
                    }
                    p{
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-weight: bold;
                        font-size: 22px;
                        line-height: 26px;
                        letter-spacing: -0.01em;
                        color: #000000;
                    }
                }
                &__search{
                    position: relative;
                    margin-top: 30px;
                    z-index: 0;
                    svg{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-53%);
                        left: 13px;
                        z-index: 2;
                    }
                    span{
                        cursor: pointer;
                        svg{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-53%);
                            left:93%;
                            z-index: 2;
                        }
                    }
                    input{
                        position: relative;
                        font-family: $primary-font;
                        width: 100%;
                        height: 50px;
                        border-radius: 16px;
                        padding: 0 39px 0 44px;
                        border: 1px solid;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        &::placeholder{
                            color: #555759;
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                }
                &__select{
                    position: relative;
                    margin-top: 30px;
                    select{
                        position: relative;
                        height: 50px;
                        background: $color-grey-pure;
                        border-radius: 15px;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        color: #242526;
                        padding: 0 15px;
                        background-image: url('/static/staticpages/help/down-arrow.svg');
                        background-repeat: no-repeat;
                        background-position: 92% 54%;
                        &:focus{
                            outline: none;
                        }
                    }
                }
                &__questions{
                    &__nofind{
                        position: relative;
                        margin-top: 80px;
                        text-align: center;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 40px;
                        color: #242526;
                        span{
                            color: $color-romantic-pink;
                            word-break: break-word;
                        }
                    }
                    .cn__help__faq__questions__question{
                        position: relative;
                        &:last-child{
                            border-bottom: none;
                        }
                        .cn__help__faq__questions__question__closed{
                            position: relative;
                            width: 100%;
                            padding: 25px 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            h3{
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 500;
                                max-width: calc(100% - 40px);
                                font-feature-settings: unset;
                                font-variant: unset;
                                text-transform: unset;
                                letter-spacing: 0px !important;
                            }
                            svg{
                                width: 14px;
                                height: 14px;
                            }
                        }
                        .cn__help__faq__questions__question__answer{
                            position: relative;
                            margin-bottom: 0;
                            max-height: 0;
                            transition: max-height 0.6s ease 0.3s;
                            p{
                                font-size: 14px;
                                line-height: 22px;
                                font-weight: 400;
                                opacity: 0;
                                transition: all 0.6s ease 0s;
                                color: #797882;
                                pointer-events: none;
                                strong{
                                    font-weight: 700;
                                }
                                a{
                                    color: #7868C7;
                                    font-weight: 500;
                                }
                                a[href]{
                                    cursor: pointer;
                                    pointer-events: visible;
                                }
                                ul{
                                    margin-left:7%;
                                }
                            }
                        }
                    }
                    .cn__help__faq__questions__question.helpfaq__active{
                        padding-bottom:30px;
                        .cn__help__faq__questions__question__closed{
                          svg{
                            transform: rotate(90deg);
                          }
                        }
                        .cn__help__faq__questions__question__answer{
                          max-height: 1000px;
                          transition: max-height 0.6s ease;
                          transition-delay: 0;
                          p{
                            transition: opacity 0.6s ease;
                            transition-delay: 0.6s;
                            opacity:1;
                          }
                        }
                    }
                }
                &__info{
                    position: relative;
                    margin-bottom: 58px;
                    background-image: url('/static/img/help/help_footer.png');
                    background-repeat: no-repeat;
                    width: 265px;
                    height: 100%;
                    border-radius: 33px;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    flex-direction: column;
                    padding-left: 10px;
                    padding-right: 10px;
                    &__title{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 26px;
                        letter-spacing: -0.01em;
                        color: $color-white;
                        text-align: center;
                        margin-top: 15px;
                    }
                    &__subtitle{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        color: $color-white;
                    }
                    button{
                        position: relative;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                        cursor: pointer;
                        border-radius: 8px;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        text-align: center;
                        border: none;
                        padding: 10px 20px;
                        color: $color-white;
                        a{
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
    //Cookie banner
    .cn__cookie-wrapper{
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); 
            outline: 1px solid #DFE2E5;
        }
    }
}
//--------------------------- TABLET ---------------------------//
@media screen and (min-width: 601px) and (max-width: 1136px) {
    .cn__help__wrapper{
        background: $color-white;
        .cn__help{
            margin-top: 69px;
            padding: 0;
            padding-top: 88px;
            &__mobile{
                min-height: calc(100vh - 519px);
                &__intro{
                    max-width: 359px;
                    margin: 0 auto;
                }
                &__title{
                    margin-top: 80px;
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 52px;
                    text-align: center;
                    letter-spacing: -0.04em;
                }
                &__cards{
                    margin-top: 40px;
                }
                &__detail{
                    width: 100%;
                    padding: 0 30px;
                    padding-bottom: 80px;
                    &__search{
                        max-width: 450px;
                        margin: 30px auto 0;
                    }
                    &__select{
                        max-width: 344px;
                        margin: 30px auto 0;
                    }
                    &__questions{
                        max-width: 344px;
                        margin: 0 auto;
                    }
                    &__info{
                            max-width: 494px;
                            height:141px;
                            margin-bottom: 60px;
                            display: flex;
                            flex-direction: row;
                            padding-top: 15px;
                            align-items: center;
                            justify-content: space-around;
                            width: 1066px;
                            justify-content: space-around;
                            &__title{
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 21px;
                                letter-spacing: -0.01em;
                                color: $color-white;
                                text-align: left;
                                margin-left: auto;
                                margin-right: auto;
                                width: 132px;
                            }
                            &__subtitle{
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 21px;
                                text-align: left;
                                color: $color-white;
                                width: 181px;
                            }
                            button{
                                position: relative;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                                cursor: pointer;
                                border-radius: 8px;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 18px;
                                text-align: center;
                                border: none;
                                padding: 10px 20px;
                                color: $color-white;
                                a{
                                    color: $color-white;
                                }
                            }
                    }
                }
            }
        }
    }
}
//--------------------------- DESKTOP ---------------------------//
@media screen and (min-width: 1136px) {
    .cn__help__wrapper{
        position: absolute;
        width: 100%;
        overflow-x: unset;
        background: $color-white;

        .cn__help{
            position: relative;
            width: 100%;
            margin-top: 69px;
            padding: 0 50px;
            padding-top: 88px;
            max-width: 1440px;
            margin: 0 auto;
            &__desktop{
                position: relative;
                display: table;
                width: 100%;
                &__title{
                    font-weight: bold;
                    font-size: 48px;
                    line-height: 52px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    color: #000000;
                    margin-top: 80px;
                }
                &__search{
                    position: relative;
                    max-width: 820px;
                    margin: 40px auto 0;
                    border: 1px solid #242526;
                    border-radius: 16px;
                    svg{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-53%);
                        left: 13px;
                        z-index: 1;
                    }
                    span{
                        cursor: pointer;
                        svg{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-53%);
                            left:96%;
                            z-index: 2;
                        }
                    }
                    input{
                        position: relative;
                        font-family: $primary-font;
                        width: 96%;
                        height: 50px;
                        border-radius: 16px;
                        padding: 0 15px 0 44px;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        &::placeholder{
                            color: #555759;
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                }
                &__categories{
                    position: relative;
                    max-width: 448px;
                    margin: 19px auto 0;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    &__category{
                        position: relative;
                        border: solid;
                        border-width: 1px;
                        border-radius: 10px;
                        padding: 5px;
                        color: black;
                        display: flex;
                        align-items: center;
                        opacity: 0.6;
                        cursor: pointer;
                        transition: opacity 0.3s ease;
                        &::after{
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0px;
                            bottom: -5px;
                            height: 4px;
                            border-radius: 2px;
                            background: #F35597;
                            content: '';
                            margin-top: 5px;
                            transition: width 0.3s ease;
                        }
                        &:hover{
                            opacity: 1;
                        }
                        svg{
                            margin-right: 5px;
                        }
                        p{
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 16px;
                            padding: 3px;
                        }
                        &.cn__help__active{
                            background: $color-gray-hp;
                            border: 1px solid #797882;
                            border-radius: 10px;
                            color: white;
                            padding: 5px;
                        }
                    }
                }
                &__info{
                    position: relative;
                    width: 100%;
                    margin-top: 50px;
                    display: grid;
                    grid-template-columns: 1fr 660px 1fr;
                    grid-column-gap: 20px;
                    &__block1{
                        position: sticky;
                        top: 50px;
                        left: 0;
                        align-self: flex-start;
                        padding-bottom: 100px;
                        &__title{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: #242526;
                        }
                        &__category{
                            position: relative;
                            font-size: 16px;
                            line-height: 20px;
                            color: #ACABB4;
                            margin-top: 15px;
                            cursor: pointer;
                            display: table;
                            transition: color 0.3s ease;
                            &.block1__category__active{
                                color: #242526;
                            }
                        }
                    }
                    &__block2{
                        &__nofind{
                            position: relative;
                            margin-top: 50px;
                            text-align: center;
                            font-weight: 700;
                            font-size: 32px;
                            line-height: 46px;
                            color: #242526;
                            span{
                                color: $color-romantic-pink;
                                word-break: break-word;
                            }
                        }
                        &__category{
                            position: relative;
                            border-radius: 15px;
                            padding: 5px 30px 10px 30px;
                            transform: translate(0,40px);
                            opacity: 0;
                            transition: opacity 0.3s ease, transform 0.6s ease;
                            hr{
                                border: 0.2px solid #EEEEF0;
                                padding: 0px;
                                margin: 0px;  
                                width: 660px;
                                margin-left: -30px
                            }
                            .cn__help__desktop__info__block2__category_hero{
                                display: flex;
                                justify-content: space-between;
                                svg{
                                    cursor: pointer;
                                }
                            }
                            &:first-child{
                                margin-top: 0;
                            }
                            &__title{
                                font-weight: 600;
                                font-size: 23px;
                                line-height: 26px;
                                letter-spacing: -0.01em;
                                color: #242526;
                            }
                            &__questions{
                                margin-top: 10px;
                                .cn__help__faq__questions__question{
                                    position: relative;
                                    transition: all 0.3s ease;
                                    background: #F4F4F4;
                                    border-radius: 12px;
                                    margin-top: 14px;
                                    padding-left: 20px;
                                    padding-right: 20px;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                    .cn__help__faq__questions__question__closed{
                                        position: relative;
                                        width: 100%;
                                        padding: 20px 0;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        cursor: pointer;
                                        h3{
                                            font-size: 16px;
                                            line-height: 20px;
                                            max-width: calc(100% - 40px);
                                            font-feature-settings: unset;
                                            font-variant: unset;
                                            text-transform: unset;
                                            letter-spacing: 0px !important;
                                            font-weight: 500;
                                            align-items: center;
                                            color: $color-pure-black;
                                        }
                                        svg{
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                    .cn__help__faq__questions__question__answer{
                                        position: relative;
                                        margin-bottom: 0;
                                        max-height: 0;
                                        transition: max-height 0.6s ease 0.3s;
                                        p{
                                            font-size: 16px;
                                            line-height: 22px;
                                            font-weight: 400;
                                            opacity: 0;
                                            transition: opacity 0.6s ease 0s;
                                            color: #797882;
                                            pointer-events: none;
                                            strong{
                                                font-weight: 700;
                                            }
                                            a{
                                                color: #7868C7;
                                                font-weight: 500;
                                            }
                                            a[href]{
                                                cursor: pointer;
                                                pointer-events: visible;
                                            }
                                            ul{
                                                margin-left:7%;
                                            }
                                        }
                                    }
                                }
                                .cn__help__faq__questions__question.helpfaq__active{
                                    padding-bottom:34px;
                                    .cn__help__faq__questions__question__closed{
                                      svg{
                                        transform: rotate(90deg);
                                      }
                                    }
                                    .cn__help__faq__questions__question__answer{
                                      max-height: 1000px;
                                      p{
                                        transition: opacity 0.6s ease;
                                        transition-delay: 0.6s;
                                        opacity:1;
                                      }
                                    }
                                }
                            }
                        }
                        &__more{
                            background-image: url('/static/img/help/help_footer.png');
                            background-repeat: no-repeat;
                            position: relative;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 87px;
                            margin-bottom: 67px;
                            padding-bottom: 100px;
                            display: flex;
                            flex-direction: row;
                            padding-top: 15px;
                            align-items: center;
                            justify-content: space-around;
                            width: 1066px;
                            justify-content: space-around;
                            &__title{
                                font-weight: bold;
                                font-size: 32px;
                                line-height: 30px;
                                letter-spacing: -0.01em;
                                color: $color-white;
                                font-weight: 700;
                                width: 306px;
                            }
                            &__subtitle{
                                font-weight: 700;
                                font-size: 28px;
                                line-height: 30px;
                                color: $color-white;
                                margin-top: 15px;
                                width: 317px;
                            }
                            button{
                                position: relative;
                                display: block;
                                cursor: pointer;
                                margin: 20px 0 0;
                                background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                                border-radius: 14px;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 30px;
                                text-align: center;
                                border: none;
                                height: 40px;
                                width: 191px;
                                color: $color-white;
                                a{
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cn__help__wrapper .cn__help__desktop__info__block1__category{
        margin-top: 20px;
        visibility: hidden;
    }
}