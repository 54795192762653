.cn__CTA__loadingsrp__wrapper {
    opacity: 0;
    animation: appear .3s ease-in-out forwards;
    &.cn__CTA__loadingsrp__wrapper--mobile,
    &.cn__CTA__loadingsrp__wrapper--tablet {
        padding-left: rem(20);
        padding-right: rem(20);
    }
    &.cn__CTA__loadingsrp__wrapper--mobile {
        .cn__CTA__loadingsrp {
            border-radius: 15px;
            padding: 20px;
            align-items: flex-start;

            .cn__CTA__loadingsrp__info{

                .cn__CTA__loadingsrp__info__title{
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
    }
}

.cn__CTA__loadingsrp{
    border: 1px solid $color-field-grey;
    box-shadow: 0 5px 30px rgba(231, 228, 228, 0.5);
    border-radius: 20px;
    background: #FFFFFF;
    padding: 20px;
    display: flex;
    align-items: center;

    &__icon{
        position: relative;
        flex-shrink: 0;
        width: 56px;
        height: 56px;

        &__circle{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(243, 85, 151, 0.11);
            border-radius: 50%;
            display: grid;
            place-items: center;

            &__clock{
                position: relative;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 4px solid #F35597;

                &__hour{
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 3px);
                    transform: translate(-50%,-50%);
                    width: 10px;
                    height: 4px;
                    border-radius: 5px;
                    background: #F35597;
                }
                &__minutes{
                    position: absolute;
                    top: calc(50% - 3px);
                    left: 50%;
                    transform: translate(-50%,-50%) rotate(0deg);
                    transform-origin: 50% 80%;
                    width: 4px;
                    height: 10px;
                    border-radius: 5px;
                    background: #F35597;
                    animation-name: clock;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;

                    @keyframes clock {
                        from{
                            transform: translate(-50%,-50%) rotate(0deg);
                        }
                        to{
                            transform: translate(-50%,-50%) rotate(360deg);
                        }
                    }
                }
            }
        }
    }

    &__info{
        position: relative;
        margin-left: 20px;
        width: 100%;

        &__title{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #242526;
        }

        &__loader{
            position: relative;
            margin-top: 20px;
            width: 100%;
            border-radius: 5px;
            background: #EEEEF0;
            overflow: hidden;
        }
    }
}

.cn__CTA__pseudo__link {
    cursor: pointer;
}
