.filters-date-picker {
    display: flex;
    align-items: center;
    border-radius: rem(6);
    width: rem(210);
    transition: .3s ease-in-out;
    &:hover {
        background: $color-light-gray-input;
    }

    .filters-date-picker_icon {
        height: rem(24);
        width: rem(24);
        margin-right: rem(5);
    }

    .filters-date-picker_toggle-wrapper {
        margin-left: rem(5);
        position: relative;
        .filters-date-picker_toggle {
            border: none;
            background: none;
            outline: none;
            .carret-icon {
                transition: .3s ease-in-out;
                cursor: pointer;
            }
        }
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: none;
        }
    }

    &.date-picker__selected {
        background: $color-white;
    }
    &.date-picker__active {
        background: $color-white;
        .filters-date-picker_toggle-wrapper {
            &::before {
                display: block;
            }
            .filters-date-picker_toggle {
                .carret-icon {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    .DateRangePicker {
        background: transparent;
        flex: 0 0;
        user-select: none;
        .DateRangePicker_picker {
            z-index: 3;
        }
        .DateRangePickerInput {
            background: inherit;
            @media (min-width: 601px) {
                grid-template-columns: 1fr auto 1fr;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .DateInput {
                background: inherit;
                .DateInput_input {
                    cursor: pointer;
                    background: transparent;
                    transition: .3s ease-in-out;
                    // &:focus {
                    //     background: $color-white;
                    //     text-align: initial;
                    // }
                    padding: 0;
                    box-shadow: none;
                    // font-weight: 500;
                    font-family: $primary-font;
                    text-align: center;
                    &::placeholder {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
            }
        }

    }
}