@import '../../global/mixins/global-mixins.scss';
.cities-section-wrapper{
    position: relative;
    //padding-top: 9.375rem;
    padding-top: 195px;
    grid-column: 1/18;
    .csw-wrapper{
        background: #100D21;
        padding: 50px 0 96px;
        .slick-slider{
            padding: 0 92.5px;
            @media screen  and (max-width: 1136px) {
                margin-left: 0;
                padding: 0 0 0 30px;
            }
            @media screen  and (max-width: 600px) {
                margin-left: 0;
                padding: 0 0 0 20px;
            }
        }
    }
    @media screen and (min-width: 601px) and (max-width: 1136px) {
        padding-top: 70px;
        .csw-wrapper{
            background: #100D21;
            padding: 50px 0 20px 0px;
        }
    }
    @media screen  and (max-width: 600px) {
        padding-top: 40px;
        .csw-wrapper{
            padding: 30px 0 20px;
        }
    }
}
.cities-section {
    width: 100%;
    padding-top: 0 !important;
    z-index: 2;
    /* padding-left: 6.25rem !important;
    padding-right: 6.25rem !important; */

    /* .slick-next{
        right: 0 !important;
    }
    .slick-prev{
        right: 54px !important;
    } */
    
    @media screen  and (max-width: 1136px) {
        padding-left: 0 !important;
    }
    position: relative;
    /* &.section-slider{ 
        @media screen  and (max-width: 600px) {
                .slick-slide {
                    width:16.1875rem !important;
                }
        }
    } */
    /* .slick-arrow{
        &.slick-prev{
            right: auto;
            left: 0px;
        }
        &.slick-next{
            left: 76px;
            right: auto;
        }
    } */
    .heading-section {
        max-width: 610px;
        float: right;
        text-align: right;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        align-items: flex-end;
        margin-right: 100px;
        margin-right: 100px;
        /*@media screen and (min-width: 1919px) {
            margin-right: 18.125rem;
        }
        @media screen and (min-width: 1136px) and (max-width: 1919px) {
            margin-right: 3.125rem;
        }*/
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            margin: 0 0 0 12.3%;
            max-width: 71.5%;
            text-align: center;
            padding: 0 0;
            float: none;
            display: block;
        }
        @media screen  and (max-width: 600px) {
            float: none;
            margin: 0;
            padding: 0 1.25rem;
            max-width: 100%;
            text-align: center;
            //padding: 0 1.25rem 0 0;
            display: block;
        }
        h2 {
            font-family: $secondary-font;
            
            font-size: 48px;
            line-height: 115%;
            letter-spacing: -0.02em;

            color: #F7F7F7;
            margin: 0 0 1.25rem 0;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 2.5rem;
            }
            @media screen  and (max-width: 600px) {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 1.5625rem;
            }
            &.desktop {
                @media screen and (max-width: 1146px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 1146px) {
                    display: none;
                }
                @media screen  and (max-width: 600px) {
                    margin-bottom: 0;
                }
            }
            br {
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                //color: $color-primary;
                font-weight: 900;
            }
        }
        p {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            
            color: #ACABB4;
            margin: 0 0 0.625rem 0;
            @media screen and (min-width: 601px) and (max-width: 1146px) {
                margin: 00;
            };
            @media screen  and (max-width: 600px) {
                display: none;
            }
        }
    }
    .lSSlideOuter {
        clear: both;
    }
    .country-box {
        //transition: margin ease 0.1s, ;
        transition: all .35s ease;
        box-shadow: 0px 1px 2px rgba(36, 37, 38, 0.2);
        border-radius: 20px;
        background: #312F40;
        @include box-shadow-light();
        width: 100%;
        @media screen  and (max-width: 600px) {
            //width: 249px;
            width: 100%;
        }
        overflow: hidden;
        margin: 0 auto 0.625rem;
        .country-box-top {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 17.362vw;
            max-height: 250px;
            overflow: hidden;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                height: 250px;
            } 
            @media screen  and (max-width: 600px) {
                height: 106px;
            } 
            div{
                height: 100%;
            }
            .width-100{
                height: inherit;
                max-height: inherit;
            }
            a, span {
                display: block;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    filter: grayscale(0);
                    @media screen  and (max-width: 1136px) {
                        filter: grayscale(0);
                    }
                }
            }
        }
        .country-box-bottom {
            display: inline-block;
            vertical-align: top;
            width: 100%; 
            //padding: 0.625rem 1.875rem 1.1875rem 1.5625rem;
            padding: 10px 20px 15px 25px;
            @media screen and (min-width: 300px) and (max-width: 600px) {
                //padding: 0.625rem 1.25rem 0.625rem 1.25rem;
                padding: 10px 10px 15px 10px;
            }
            position: relative;
            h4 {
                color: #f7f7f7;
                height: 1.9375rem;
                //overflow: hidden;
                margin: 0;
                @media screen and (min-width: 300px) and (max-width: 600px) { 
                    height: auto;
                }
                .arrow { 
                    @media screen and (min-width: 300px) and (max-width: 600px) { 
                        width: 16px;
                        height: 12px;
                    }
                    svg{
                        path{
                            fill: #F7F7F7;
                        }
                    }
                }
                a {
                    /* font-size: 1.625rem;
                    line-height: 1.9375rem;
                    font-weight: 500;
                    font-variant: small-caps;
                    text-transform: lowercase; */

                    font-family: $secondary-font;
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 25px;

                    color: #F7F7F7;
                    width: 100%;
                    /*display: inline-block;
                    vertical-align: top;
                    text-decoration: none;*/
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @media screen and (min-width: 300px) and (max-width: 600px) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                    img {
                        float: right;
                        //margin-top: 0.4375rem;
                    }
                    svg{
                        path{
                            fill: #F7F7F7;
                        }
                    }
                }
            }
        }
        /*&:hover {
            background: $color-subtle-white;
            .country-box-top {
                a {
                    img {
                        filter: grayscale(1);
                    }
                }
            }
        }*/
    }

    .section-slider{
        .slick-slide{
            @media screen and (min-width: 601px) and (max-width: 1146px) {
                width: 252px !important;
            }
            @media screen  and (max-width: 600px) {
                width: 172px !important;
            }
        }
    }
}