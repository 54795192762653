@import './tools/_functions';

//------------------- DESKTOP -------------------

.cn__lyp__lyp3{
    position: relative;
    width: 100%;
    background: var(--color-background);
    padding: dvw(150) 0 0;

    &__wrapper{
        position: relative;
        width: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__box{
        position: relative;
        width: 100%;
        background: #F2F6FF;
        border-radius: dvw(15);
        padding: dvw(60) dvw(40);
        display: grid;
        grid-template-columns: 42% 58%;
        align-items: center;

        &__sec1{
            position: relative;
            color: #000000;
            padding-right: dvw(67);

            &__title{
                font-weight: bold;
                font-size: dvw(54);
                line-height: 1.2;
                
                span{
                    color: #F35597;
                }
            }
            &__subtitle{
                font-size: dvw(23);
                line-height: 1.2;
                margin-top: dvw(20);

                span{
                    font-weight: 600;
                }
            }
            &__subtitle2{
                font-size: dvw(18);
                line-height: 1.2;
                margin-top: dvw(50);
            }
        }

        &__sec2{
            position: relative;
            display: flex;
            align-items: center;

            &__opt1{
                position: relative;
                display: inline-block;
                background: #FFFFFF;
                border: dvw(1) solid #DFE2E5;
                box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25);
                border-radius: dvw(15);
                color: #27364B;
                padding: 4.5vw 1.4vw 3.975vw;
                max-width: 44%;
                z-index: 1;

                &__title{
                    font-weight: 600;
                    font-size: 1.8vw;
                    text-align: center;
                    line-height: 1;
                }
                &__glinetop{
                    position: relative;
                    width: calc(100% + 1.4vw);
                    height: 1px;
                    background: #94A3B8;
                    margin-top: 2.25vw;
                }
                &__graphics{
                    position: relative;
                    margin: 0 auto;
                    width: 8.889vw;
                    height: 13.264vw;
                    background: #F8F8F8;
                    color: #94A3B8;
                    text-align: center;

                    &__name{
                        position: relative;
                        height: 88%;
                        font-size: dvw(11);
                        line-height: 1;
                        display: grid;
                        place-items: center;

                        &::before{
                            position: absolute;
                            content: '';
                            top: 5%;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1px;
                            height: 35%;
                            background: #94A3B8;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            bottom: 5%;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1px;
                            height: 35%;
                            background: #94A3B8;
                        }
                    }
                    &__margin{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 12%;
                        background: #8675FF;
                        border-radius: 0px 10px 0px 0px;

                        &::before{
                            position: absolute;
                            content: '';
                            width: 40%;
                            height: 1px;
                            background: #8675FF;
                            top: 0;
                            left: -40%;
                        }
                    }
                    &__marginnumber{
                        position: absolute;
                        left: -40%;
                        bottom: 12%;
                        font-weight: 600;
                        font-size: dvw(23);
                        line-height: 1.2;
                        color: #64748B;
                    }
                }
                &__glinebottom{
                    position: relative;
                    width: calc(100% + 1.4vw);
                    height: 1px;
                    background: #94A3B8;
                }
                &__subtitle{
                    font-size: dvw(16);
                    line-height: 1.5;
                    text-align: center;
                    letter-spacing: 0.01em;
                    margin-top: 2.85vw;

                    span{
                        font-weight: 600;
                    }
                }
            }
            &__opt2{
                position: relative;
                display: inline-block;
                background: #FFFFFF;
                box-shadow: 0px 70px 90px -41px rgba(0, 0, 0, 0.25);
                border-radius: 0 dvw(15) dvw(15) 0;
                color: #94A3B8;
                padding: 4vw 5.4vw 4.8vw 3.4vw;
                max-width: 56%;

                &__title{
                    font-weight: 600;
                    font-size: 1.8vw;
                    text-align: center;
                    line-height: 1;
                }
                &__glinetop{
                    position: relative;
                    width: calc(100% + 3.4vw);
                    left: -3.4vw;
                    height: 1px;
                    background: #94A3B8;
                    margin-top: 2.25vw;

                    &::after{
                        position: absolute;
                        content: '100%';
                        top: 0;
                        right: 0;
                        top: -0.5vw;
                        right: -19%;
                        font-size: 1vw;
                        line-height: 1;
                    }
                }
                &__graphics{
                    position: relative;
                    margin: 0 auto;
                    width: 8.889vw;
                    height: 13.264vw;
                    background: #F8F8F8;
                    color: #94A3B8;
                    text-align: center;

                    &__name{
                        position: relative;
                        height: 60%;
                        font-size: dvw(11);
                        line-height: 1;
                        display: grid;
                        place-items: center;

                        &::before{
                            position: absolute;
                            content: '';
                            top: 5%;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1px;
                            height: 35%;
                            background: #94A3B8;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            bottom: 5%;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 1px;
                            height: 35%;
                            background: #94A3B8;
                        }
                    }
                    &__margin{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 40%;
                        background: #FF708B;
                        border-radius: 0px 10px 0px 0px;

                        &::before{
                            position: absolute;
                            content: '';
                            width: 40%;
                            height: 1px;
                            background: #FF708B;
                            top: 0;
                            left: -40%;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            width: 40%;
                            height: 1px;
                            background: #FF708B;
                            bottom: 36%;
                            left: -40%;
                        }
                    }
                    &__marginnumber, &__marginnumber2{
                        position: absolute;
                        left: -40%;
                        bottom: 15%;
                        font-weight: 600;
                        font-size: dvw(23);
                        line-height: 1.2;
                    }
                    &__marginnumber2{
                        bottom: 40%;
                    }
                }
                &__glinebottom{
                    position: relative;
                    width: calc(100% + 3.4vw);
                    left: -3.4vw;
                    height: 1px;
                    background: #94A3B8;

                    &::after{
                        position: absolute;
                        content: '0%';
                        top: 0;
                        right: 0;
                        top: -0.5vw;
                        right: -14%;
                        font-size: 1vw;
                        line-height: 1;
                    }
                }
                &__subtitle{
                    font-size: dvw(18);
                    line-height: 1.5;
                    text-align: center;
                    letter-spacing: 0.01em;
                    margin-top: 2.85vw;

                    span{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp3{
        padding: 150px 0 0;
    
        &__box{
            padding: 60px 40px;
    
            &__sec1{
                position: relative;
                color: #000000;
                padding-right: 61px;
    
                &__title{
                    font-size: 54px;
                }
                &__subtitle{
                    font-size: 23px;
                    margin-top: 20px;
                }
                &__subtitle2{
                    font-size: 18px;
                    margin-top: 50px;
                }
            }
    
            &__sec2{
    
                &__opt1{

                    padding: 60px 35px 53px;
                    max-width: 44%;
                    z-index: 1;
    
                    &__title{
                        font-size: 23px;
                    }
                    &__glinetop{
                        width: calc(100% + 35px);
                        margin-top: 30px;
                    }
                    &__graphics{
                        width: 128px;
                        height: 191px;
    
                        &__name{
                            font-size: 12px;
                        }

                        &__marginnumber{
                            font-size: 23px;
                        }
                    }
                    &__glinebottom{
                        width: calc(100% + 35px);
                    }
                    &__subtitle{
                        font-size: 16px;
                        margin-top: 38px;
                    }
                }
                &__opt2{
                    padding: 38px 90px 64px 32px;
    
                    &__title{
                        font-size: 23px;
                    }
                    &__glinetop{

                        width: calc(100% + 32px);
                        left: -32px;
                        margin-top: 30px;
    
                        &::after{
                            top: -0.5vw;
                            right: -19%;
                            font-size: 12px;
                        }
                    }
                    &__graphics{
                        width: 128px;
                        height: 191px;
    
                        &__name{
                            font-size: 12px;
                        }
                        &__marginnumber, &__marginnumber2{
                            font-size: 23px;
                        }
                    }
                    &__glinebottom{
                        width: calc(100% + 32px);
                        left: -32px;
    
                        &::after{
                            top: -0.5vw;
                            right: -14%;
                            font-size: 12px;
                        }
                    }
                    &__subtitle{
                        font-size: 16px;
                        margin-top: 29px;
                    }
                }
            }
        }
    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp3{

        &__wrapper{
            padding: 0 30px;
        }

    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp3{
        padding: mvw(60) 0;

        &__wrapper{
            padding: 0 20px;
        }

        &__box{
            padding: mvw(20) mvw(16) mvw(40);
            grid-template-columns: 1fr;
            border-radius: mvw(15);
    
            &__sec1{
                padding-right: 0;
    
                &__title{
                    font-size: mvw(32);
                    max-width: mvw(255);
                }
                &__subtitle{
                    font-size: mvw(16);
                    margin-top: mvw(20);
                }
            }
    
            &__sec2{
                width: 100%;
                margin-top: mvw(20);
                background: #FFFFFF;
                border-radius: mvw(15) mvw(15) 0 0;
                overflow: hidden;
    
                &__opt1{
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    padding: mvw(20) mvw(10) mvw(15) mvw(20);
                    max-width: 50%;
                    width: 50%;
                    z-index: 1;
    
                    &__title{
                        font-size: mvw(14);
                        text-align: right;
                    }
                    &__glinetop{
                        display: none;
                    }
                    &__graphics{
                        width: mvw(75);
                        height: mvw(192);
                        margin-top: mvw(17);
                        float: right;
    
                        &__name{
                            p{
                                display: none;
                            }
    
                            &::before{
                                height: 55%;
                            }
                        }
                        &__margin{
    
                            &::before{
                                width: 60%;
                                left: -60%;
                            }
                        }
                        &__marginnumber{
                            left: -60%;
                            font-size: mvw(14);
                            bottom: 15%;
                        }
                    }
                    &__glinebottom{
                        display: none;
                    }
                    &__subtitle{
                        display: none;
                    }
                }
                &__opt2{
                    max-width: 50%;
                    width: 50%;
                    border-radius: 0;
                    padding: mvw(20) mvw(20) mvw(15);
    
                    &__title{
                        font-size: mvw(14);
                        text-align: right;
                    }
                    &__glinetop{
                        display: none;
                    }
                    &__graphics{
                        width: mvw(75);
                        height: mvw(192);
                        margin-top: mvw(17);
                        float: right;
    
                        &__name{
                            p{display: none;}
    
                            &::before{
                                height: 55%;
                            }
                        }
                        &__margin{
    
                            &::before{
                                width: 60%;
                                left: -60%;
                            }
                            &::after{
                                width: 60%;
                                left: -60%;
                            }
                        }
                        &__marginnumber, &__marginnumber2{
                            left: -60%;
                            font-size: mvw(14);
                            bottom: 17%;
                        }
                        &__marginnumber2{
                            bottom: 42%;
                        }
                    }
                    &__glinebottom{
                        display: none;
                    }
                    &__subtitle{
                        display: none;
                    }
                }
            }

            &__legend{
                background: #FFFFFF;
                border-radius: 0 0 mvw(15) mvw(15);
                padding: mvw(20);
                color: #94A3B8;

                &__title{
                    position: relative;
                    padding-left: mvw(20);
                    font-size: mvw(12);
                    line-height: 1;

                    &::before{
                        position: absolute;
                        content: '';
                        width: mvw(10);
                        height: mvw(10);
                        border-radius: 50%;
                        border: mvw(1) solid rgba(148, 163, 184, 0.5);
                        background: #F8F8F8;
                        top: -0.1vw;
                        left: -1vw;
                    }
                }
            }
            
            &__bottomp{
                position: relative;
                margin-top: mvw(20);
                font-size: mvw(16);
                line-height: 1.2;
            }
        }
    }
}