//------------------- DESKTOP -------------------

.cn__card3{
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;

    &__img{
        position: relative;
        width: 100%;
        height: 13.195vw;
        border-radius: 20px;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        .cn__image-default{
            transition: all 1s ease;
            border-radius: 20px;
        }

        &__title{
            position: relative;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            padding: 0 20px;
            font-weight: 900;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.04em;
            text-transform: uppercase;
            color: #FFFFFF;
            transition: transform .6s ease;
        }
    }

    &:hover{
        .cn__card3__img{
            .cn__image-default{
                transform: scale(1.05);
                filter: brightness(.95);
            }
            &__title{
                transform: translateY(-5px);
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__card3{
        &__img{
            height: 24vw;
        }
        &:hover{
            .cn__card3__img{
                img{
                    transform: none;
                    filter: brightness(.7);
                }
                &__title{
                    transform: none;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__card3{

        &__img{
            height: 64vw;
        }

    }
}
