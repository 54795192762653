//------------------- DESKTOP -------------------

.cn__hp__values{
    position: relative;
    width: 100%;
    max-width: 1100px;
    padding: 0;
    margin: 150px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 132px;


    &__value{
        position: relative;
        text-align: center;

        &:nth-child(2){
            &::before{
                position: absolute;
                content: '';
                width: 0.5px;
                height: 30%;
                background: #797882;
                top: 100px;
                left: -66px;
                opacity: 0.5;
            }
            &::after{
                position: absolute;
                content: '';
                width: 0.5px;
                height: 30%;
                background: #797882;
                top: 100px;
                right: -66px;
                opacity: 0.5;
            }
        }

        &__img{
            position: relative;
            width: 75px;
            height: 75px;
            box-shadow: 0px 8px 20px -7px rgba(0, 0, 0, 0.14);
            border-radius: 30px;
            display: grid;
            place-items: center;
            margin: 0 auto;
        }

        img{
            display: block;
            margin: 0 auto;
            height: 45px;
        }
        &__title{
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2;
            margin-top: 25px;
            color: $color-cn-pink;
        }
        &__subtitle{
            font-size: 14px;
            line-height: 1.5;
            color: #797882;
            margin-top: 10px;
        }
    }
}

@media screen and (min-width:1920px){
    .cn__hp__values{
        max-width: 1536px;
    }
}


//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__values{
        padding: 0 30px;
        margin: 60px auto;
        grid-column-gap: 88px;
        &__value{
            &:nth-child(2){
                &::before{
                    left: -44px;
                }
                &::after{
                    right: -44px;
                }
            }
        }
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:763px){
    .cn__hp__values{

        padding: 0 40px;
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 35px;


        &__value{

            display: flex;
            width: 100%;

            &:nth-child(2){
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }

            &__img{
                flex-shrink: 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            img{
                display: block;
                margin: 0 auto;
                height: 26px;
            }

            &__info{
                margin-left: 24px;
                margin-top: 8px;
            }

            &__title{
                text-align: left;
                margin-top: 0;
            }
            &__subtitle{
                text-align: left;
            }
        }
    }
}