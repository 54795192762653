@import '../../global/mixins/global-mixins.scss';
.cabin-section {
    width: 100%;
    position: relative;
    .heading-section {
        max-width: 52.82rem;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            max-width: 71.5%;
        }
        @media screen  and (max-width: 600px) {
            max-width: 100%;
            padding: 0 1.25rem;
        }
        h2 {
            font-family: $secondary-font;
            
            font-size: 48px;
            line-height: 115%;
            letter-spacing: -0.02em;

            margin: 0 0 1.25rem 0;
            max-width: 52.82rem;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                font-size: 2.5rem;
                line-height: 100%;
                max-width: 33rem;
            }
            @media screen  and (max-width: 600px) { 
                font-size: 2rem;
                line-height: 2rem;
                margin-bottom: 1.25rem;
            }
            &.desktop {
                @media screen and (max-width: 1136px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 1136px) {
                    display: none;
                }
            }
            br {
                @media screen  and (max-width: 600px) {
                    display: none;
                }
            }
            span {
                //color: $color-primary;
                font-weight: 900;
            }
        }
        p {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            color: $color-dark-grey;
            margin: 0 auto 2.5rem;
            max-width: 38.25rem;
           
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                margin-bottom: 1.875rem;
                max-width: 34.25rem;
            }
            @media screen  and (max-width: 600px) {
                &.mobile {
                    padding: 0 10%;
                    margin-bottom: 30px;
                }
            }
            &.desktop {
                @media screen and (max-width: 1136px) {
                    display: none;
                }
            }
            &.mobile {
                @media screen and (min-width: 1136px) {
                    display: none;
                }
            }
        }
    }
    .cabin-inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        .cabin-inner-desk{
            @media screen and (max-width: 1136px) {
                display: none;
            }
        }
        .cabin-inner-mob{ 
            @media screen and (min-width: 1136px) {
                display: none;
            }
        }
    }
    .country-box {
        position: relative;
        border-radius: 0.625rem;
        background: $color-white;
        float: left;
        overflow: hidden;
        cursor: default;
        .flip-container{
            height: 14.125rem !important;
            width: 100%;
        }
        transition: all 0.6s ease;
        @media screen and (min-width: 1136px) {
            border: 0.1875rem solid $color-white;
        }
        @media screen  and (max-width: 1136px) {
            @include box-shadow-light();
        }
        overflow: hidden;
        @media screen and (min-width: 1136px) {
            width: 26.9%;
            margin-bottom: 5.5625rem;
            margin-right: 9.6%;
            &:nth-child(3n+3) {
                margin-right: 0;
            }
            &:nth-child(4), 
            &:nth-child(5), 
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
        @media screen and (min-width: 601px) and (max-width: 1136px) {
            width: 24.1%;
            margin-bottom: 3.125rem;
            margin-right: 13.8%;
            &:nth-child(3n+3) {
                margin-right: 0;
            }
            &:nth-child(4), 
            &:nth-child(5), 
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
        @media screen  and (max-width: 600px) {
            width: 48.5%;
            margin-bottom: 1.25rem;
            margin-right: 3%;
            &:nth-child(2n+2) {
                margin-right: 0;
            }
            &:nth-child(5), 
            &:nth-child(6) {
                margin-bottom: 0;
            }
        }
        .flipper{
            height: 100%;
        }
        .country-box-top {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            height: 14.125rem;
            overflow: hidden;
            position: relative;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                height: 6.5625rem;
            }
            @media screen  and (max-width: 600px) {
                height: 6.5625rem;
            }
            div {
                height: 100%;
            }
            a, span {
                display: block;
                height: 100%;
                span{
                    width: 100%;
                    height: 100%;
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: all 0.6s ease;
                }
            }
            h4 {
                font-family:$secondary-font ;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 1rem;
                text-align: center;
                //text-shadow: 0 1px 0 $color-black;
                transition: all 0.6s ease;
                @media screen  and (max-width: 1136px) {
                    display: none;
                }
                a {
                    @include big-text();
                    line-height: 2.375rem;
                    font-weight: 500;
                    color: $color-white;
                    height: auto;
                }
            }
            .country-box-gradient{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background: linear-gradient(180deg, rgba(36,37,38,0) 0%, rgba(36,37,38,0.13) 25%, rgba(36,37,38,0.25) 50%, rgba(36,37,38,0.38) 75%, rgba(36,37,38,0.5) 100%);
            }
        }
        .country-box-bottom {
            display: inline-block;
            vertical-align: top;
            width: 100%; 
            padding: 0.5625rem 0;
            position: relative;
            height: 2.3125rem;
            text-align: center; 
            background-color: rgba(255,255,255,0.5);
            border-radius: 10px;
            @media screen and (min-width: 601px) and (max-width: 1136px) {
                background-color: $color-white;
                border-radius: 0px;
            }
            h4 {
                color: $color-black;
                overflow: hidden;
                margin: 0;
                a {
                    font-size: 1rem;
                    line-height: 1.1875rem;
                    font-weight: 500;
                    color: $color-black;
                    width: 100%;
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    font-variant: small-caps;
                    text-transform: lowercase;
                }
            }
          &.back{
            position: relative;
            color: #fff;
            display: flex;
            align-items: center;
            text-align: center;
            padding: 1.25rem;
            flex-direction: column;
            font-size: 1.063rem;
            opacity: 0;
            transition: all 0.6s ease;
              h4 {
                font-family: $secondary-font;
                color: $color-black;
                margin: auto;
                font-size: 1.125rem;
                line-height: 1.438rem;
                font-weight: normal
             }
             a{ 
                color: $color-black;
                display: block; 
                }
          }
        }
        &:hover {
            @media screen and (min-width: 1136px) {
                transform: translateY(-5px);
                .country-box-top {
                    h4 {
                        opacity: 0;
                    }
                    a{
                        span{
                            img{
                                filter: blur(8px);
                                -webkit-filter: blur(8px);
                            }
                        }
                    }
                }
                .country-box-bottom {
                    
                    &.back{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .cabin-item-wrapper{
        position: relative;
        height: 285px;
        border-radius: 10px;
        border: 3px solid $color-white;
        margin-right: 10px;
        width: auto !important;
        display: block !important;
       
        .cabin-slider-item{
            width: 100%;
            height: 100%;
            border-radius: 10px;          
            a{
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 10px;  
                display: flex;
                justify-content: center;
                align-items: center;
                .csi__img{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                }
                .csi__name{
                    z-index: 2;
                    font-family: $secondary-font;
                    font-size: 1.875rem;
                    line-height: 2.375rem;
                    font-weight: 700;
                    color:$color-white;
                    //text-shadow: 0 1px 0 $color-black;
                }
                .country-box-gradient{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background: linear-gradient(180deg, rgba(36,37,38,0) 0%, rgba(36,37,38,0.13) 25%, rgba(36,37,38,0.25) 50%, rgba(36,37,38,0.38) 75%, rgba(36,37,38,0.5) 100%);
                }
            }
        }
        
    }
    .slick-slider{
        @media screen  and (max-width: 600px) {
            padding-left: 1.25rem;
            .slick-list{
                overflow: hidden;
            }
        }
    }
}
