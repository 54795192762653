// .grs-label {
//     margin: 17px;
//     display: grid;
//     grid-template-columns: repeat(2, auto);
//     align-items: center;
//     column-gap: 9px;
//     font-size: 1rem;

//     &.listings-grs {
//         position: absolute;
//         left: 0;
//         bottom: 0;
//         z-index: 1;

//         @include desktop {
//             right: 0;
//         }
//     }


//     &.grs-label--light {
//         .grs-rating_number {
//             padding: 10px;
//             background-color: var(--color-white);
//             border-radius: 10px 10px 10px 5px;
//             color: var(--color-black);
//             font-weight: 600;
//         }

//         .grs-rating_container {
//             color: var(--color-white);

//             .grs-rating_adjective {
//                 font-weight: 600;
//                 color: var(--color-white);
//             }
//             .grs-rating_reviews {
//                 color: var(--color-white);
//             }
//         }
//     }

//     &.grs-label--primary {
//         left: initial;
//         bottom: initial;
//         top: 0;
//         height: 58px;
//         padding: 10px;
//         padding-top: 7px;
//         background-color: var(--color-white);
//         direction: rtl;
//         border-radius: 20px;
//         right: 15px;
//         .grs-rating_number {
//             background-color: var(--color-purple);
//             color: var(--color-white);
//             border-radius: 11px 11px 11px 2px;
//             width: 45px;
//             height: 43px;
//             font-weight: 600;
//             padding: 12px;
//         }

//         .grs-rating_container {
//             color: var(--color-dark-grey);

//             .grs-rating_adjective {
//                 color: var(--color-purple);
//                 font-weight: 500;
//                 font-size: 14px;
//                 line-height: 150%;
//             }

//             .grs-rating_reviews {
//                 display: flex;
//                 flex-direction: row-reverse;
//                 justify-content: flex-end;
//                 font-size: 10px;
//                 span:first-child {
//                     margin-right: 4px;
//                 }
//             }
//         }
//     }
// }

.page-listings {
    .grs-label {
        position: absolute;
        z-index: 1;

        &.grs-label--primary {
            top: 20px;
            right: 20px;
            left: unset;
            bottom: unset;
            margin: 0;
            background-color: $color-white;
            padding: 3px 16px;
            display: flex;
            column-gap: 1rem;
            font-size: 1rem;
            line-height: 150%;
            font-weight: 600;
            color: $color-pure-black;
            border-radius: 10px;

            .grs-rating_number {
                padding: 0;
                background-color: unset;
                display: flex;
                align-items: center;
                color: inherit;
            }

            .grs-rating_container {
                .grs-rating_adjective {
                    color: inherit;
                }
                .grs-rating_reviews {
                    color: inherit;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        &.grs-label--light {
            bottom: 33px;
            left: 20px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.70);
            backdrop-filter: blur(2px);
            padding: 3px 12px;
            display: flex;
            column-gap: 12px;
            font-size: 14px;
            line-height: 150%;
            font-weight: 600;
            color: $color-pure-black;
            border-radius: 10px;

            .grs-rating_number {
                padding: 0;
                background-color: unset;
                display: flex;
                align-items: center;
                color: inherit;
            }

            .grs-rating_container {
                .grs-rating_adjective {
                    color: inherit;
                }
                .grs-rating_reviews {
                    color: inherit;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1136px) {
    .page-listings {
        .grs-label {    
            &.grs-label--primary {
                // right: 36px;
            }
        }
    }    
}