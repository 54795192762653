//------------------- DESKTOP -------------------

.cn__card7__wrapper {
    position: relative;
}

.cn__card7{
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(87,75,144,0.15);
    transition: transform .35s ease, box-shadow .35s ease ;
    height: calc(var(--card-width) * 1.5);

    @media (min-width: 1380px) {
        height:dvw(422);
    }

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0,0,0);


    .cn__image-default {
        transition: all 0.35s ease;
        border-radius: 16px;
    }

    &__info {
        margin: 9px 0 0 var(--card-gap);
        padding: 10px 0;
        background-color: $color-white;

        &__title {
            font-weight: 700;
            font-size: 23px;
            line-height: 120%;
            color: $color-pure-black;
            margin-bottom: 5px;
        }
        &__subtitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: $color-pure-black;
            &__bold {
                font-weight: 600;
            }
        }
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 0 30px 20px -20px rgba(87,75,144, 0.15);
    }

}

@media screen and (min-width:1921px){
    .cn__card7{
        height:563px;
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){

    .cn__card7{
        width: 270px !important;
        height: 405px;
        &:hover{
            transform: none;
            img{
                filter: brightness(0.7);
            }
        }
    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){

    .cn__card7 {
        width: 163px !important;
        height: 113px;
        border-radius: 10px 10px 0 0;

        .cn__image-default {
            border-radius: 10px 10px 0 0;
        }

        &__info {
            margin: 0 0 0 10px;
            padding: 10px;
            border-radius: 0 0 10px 10px;
            border: 1px solid rgba(100, 116, 139, 0.5);
            border-top: none;
            box-shadow: 0 4px 2px -4px grey;
            border-bottom: 3px solid #bdb5b5;
            &__title {
                font-weight: 700;
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }

            &__subtitle {
                font-size: 12px;
                line-height: 150%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* Limit to 2 lines */
                -webkit-box-orient: vertical;
            }
        }
    }

}
