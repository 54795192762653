@import './tools/_mixins';
@import './tools/_functions';

//------------------- DESKTOP -------------------


.cn__lyp__lyp1{
    padding-top: 88px;
    position: relative;
    width: 100%;
    height: 100%;
    background: #E8EDED;
    z-index: 1;

    &__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 100px;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__sec1{
        position: relative;
        width: 50%;
        top: 2vw;
        padding-right: dvw(38);

        &__title{
            font-weight: 700;
            font-size: dvw(54);
            line-height: 1.2;
            color: #242526;

            span{
                color: #F35597;
            }
        }

        &__subtitle{
            font-size: dvw(23);
            line-height: 1.3;
            color: #000000;
            margin-top: dvw(20);
            max-width: dvw(573);

            span{
                font-weight: 600;
            }
        }
    }

    &__sec2{
        position: relative;
        top: 96px;
        width: 50%;
        height: 100%;
        border-radius: dvw(15);
        overflow: hidden;
        box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.35), 80px 80px 150px rgba(0, 0, 0, 0.25);
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #DBDFDF;
        background-size: cover;

    }
}

@media screen and (min-width:1441px){ //---- For high screen resolution making everything pixel perfect
    .cn__lyp__lyp1{
        height: 768px;
    
        &__sec1{
            padding-right: 38px;
    
            &__title{
                font-size: 54px;
            }
    
            &__subtitle{
                font-size: 23px;
                padding-right: 47px;
                max-width: 573px;
            }
        }

    }
}


//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__lyp__lyp1{
        height: 67vw;

        &__wrapper{
            padding: 0 30px;
        }
    
        &__sec1{
    
            &__title{
                font-size: 4.5vw;
            }
    
            &__subtitle{
                font-size: 2.2vw;
            }
        }

    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__lyp__lyp1{
        height: auto;
        background: var(--color-background);

        &::after{
            position: absolute;
            content: '';
            width: 100%;
            height: 80%;
            background: #E8EDED;
            top: 0;
            left: 0;
            z-index: -1;
        }

        &__wrapper{
            width: 100%;
            height: 100%;
            padding: 0;
            display: block;
        }
    
        &__sec1{
            position: relative;
            width: 100%;
            padding: 0 20px;
            top: unset;
            transform: unset;
            padding-top: 5px;
    
            &__title{
                font-size: mvw(44);
                line-height: 1.2;
                max-width: mvw(305);
            }
    
            &__subtitle{
                font-size: mvw(16);
                line-height: 1.5;
                margin-top: mvw(20);
                max-width: 100%;
            }
        }
    
        &__sec2{
            position: relative;
            width: calc(100% - 40px);
            height: mvw(290);
            margin:  mvw(20) auto 0 ;
            top: unset;
            right: unset;
            box-shadow: none;
            border-radius: mvw(15);
        }
    }
}