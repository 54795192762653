.product-amenities-group{
    grid-template-columns: repeat(5, minmax(min-content,1fr)) !important;

    @media screen  and (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(min-content, 1fr)) !important;
        display: grid;
        flex-wrap: wrap;
    }
    @media screen and (min-width: 600px) and (max-width: 890px) {
        grid-template-columns: repeat(3, minmax(min-content, 1fr)) !important;
      }

    div {
        justify-self: baseline;
        margin: 0;
        width: 100%;
    }
}
.product-amenities {
    .amenity-chip {
        font-family: var(--primary-font);
        color: var(--color-black);
        border: 0.5px solid #DFE2E5;
        border-radius: 8px;
        display: flex;
        height: max-content;
        place-items: normal;
        align-items: center;
        width: 100%;
        .MuiChip-icon {
            margin-left: 5px;
            width: 54px !important;
            height: 54px !important;
        }
        .makeStyles-img-2{
            width: 54px;
            height: 54px
        };
        .jss2 {
            width: 54px;
            height: 54px;
        }
        .amenity-chip_img {
            width: 54px !important;
            height: 54px !important;
        }
        .MuiChip-label {
            white-space: pre-wrap;
            width: 101px;
            padding: 0px;
            text-align: left;
            padding: 0px;
            padding-left: 11px;
            font-size: 12px;
            color: var(--color-black);
            font-weight: 400;
            word-break: break-word;
            line-height: 14.4px;
            @media screen  and (max-width: 350px) {
                width: 80px;
            }
        }
        .amenity-chip_lablel {
            white-space: pre-wrap;
            width: 101px;
            padding: 0px;
            text-align: left;
            padding: 0px;
            padding-left: 11px;
            font-size: 12px;
            color: var(--color-black);
            font-weight: 400;
            word-break: break-word;
            line-height: 14.4px;
            @media screen  and (max-width: 350px) {
                width: 80px;
            }
        }
        .amenity-chip_infoicon {
            align-self: flex-start;
            margin-top: 5px;
            margin-right: 3px;
            svg path {
                fill: var(--color-cn-pink);
            }
        }
        &.amenity-chip--black {
            background-color: transparent;
        }
    }
    .product-show-all-amenities_trigger {
        font-family: var(--primary-font);
        color: var(--color-dark-grey);
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .carret-icon{
            width: 10px;
            path{
                stroke: #797882;
            }
        }
    }
    .product-show-all-amenities_trigger_mob {
        font-family: var(--primary-font);
        color: white;
        background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 98%;
        padding: 10px;
    }
    .product-amenity-category_title {
        color: var(--color-black);
    }
}


.product-show-all-amenities_title {
    font-family: var(--primary-font);
}
.product-show-all-amenities_close {
    path {
        stroke: var(--color-black);
        stroke-linecap: round;
    }
}