//------------------- DESKTOP -------------------

.cn__hp__join__wrapper{
    position: relative;
    width: 100%;
    max-width: 1920px;
    padding: 0 100px;
    margin: 100px auto;
    .cn__hp__join{
        position: relative;
        width: 100%;
        height: 401px;
        border-radius: 16px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 50px;
        display: flex;
        align-items: center;
        &__info{
            position: relative;
            width: 68%;
            max-width: 477px;
            color: #FFFFFF;
            &__title{
                font-weight: 700;
                font-size: 32px;
                line-height: 1.2;
            }
            &__subtitle{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                margin-top: 15px;
            }
            button{
                background: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                margin-top: 30px;
            }
        }
    }

}

//DESKTOP 1920
@media screen and (min-width:1920px){
    .cn__hp__join__wrapper{
        .cn__hp__join{
            height: 421px;
        }
    }
}

//------------------- TABLET -------------------

@media screen and (max-width:1136px){
    .cn__hp__join__wrapper{
        margin: 60px auto;
        padding: 0 30px;
        .cn__hp__join{
            // background: url('https://img.cuddlynest.com/images/assets/homepage/lyp-tablet-2.jpg') no-repeat center center;
            background-size: cover;
            padding: 40px;
            align-items: flex-start;
            &__info{
                button{
                    margin-top: 30px;
                }
            }
        }
    }
}


//------------------- MOBILE -------------------

@media screen and (max-width:763px){
    .cn__hp__join__wrapper{
        padding: 0 20px;
        .cn__hp__join{
            // background: #F0F8FF;
            padding: 20px;
            align-items: flex-start;
            justify-content: center;
            height: auto;
            &__info{
                width: 100%;
                max-width: 100%;
                color: $color-black;
                &__title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    max-width: 257px;
                    @media screen and (min-width:601px) and (max-width:764px){
                        max-width: unset;
                    }
                    margin: 0 auto;
                }
                &__subtitle{
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: rgba(29, 28, 31, 0.45);
                    margin-top: 11px;
                }
                button{
                    margin: 0 auto;
                    margin-top: 26px;
                }
            }
        }
    }
}