.n-input-drop-down {
    position: relative;
    border-radius: 6px;
    padding: 9px 15px;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 20px;
    background-color: var(--color-light-grey);
    border: 1px solid var(--color-light-grey);

    .n-input-drop-down_main-text {
        // width: 100%;
        // background-color: var(--color-light-grey);
        // border: 1px solid var(--color-light-grey);
        // box-sizing: border-box;
        // border-radius: 6px;
        // outline: none;
        // padding: 9px 15px;
        // width: 100%;
        // font-family: $primary-font;
        // height: 38px;
        // font-size: 1rem;
        // line-height: 1.25rem;


        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--color-dark-grey);

    }

    .n-input-text_label {
        padding: .125rem .25rem;
        pointer-events: none;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        transition: .2s ease;
        opacity: 0;
        visibility: hidden;
    }

    .n-input-drop-down_end-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);
        right: 10px;
        transition: transform .3s ease-in-out;
    }

    &.n-input-drop-down--opened {
        background-color: var(--color-white);

        .n-input-drop-down_end-icon {
            transform: translateY(-50%) rotate(-180deg);
        }

        .n-input-text_label {
            background-color: var(--color-white);
            opacity: 1;
            transform: translateY(-170%) translateX(-0.3rem);
            font-size: 0.75rem;
            line-height: 0.75rem;
            color: var(--color-dark-grey);
            visibility: visible;
        }

        .n-radio-button-popover {
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }

    &.n-input-drop-down--filled {
        background-color: var(--color-white);
        .n-input-drop-down_main-text {
            color: var(--color-black);
        }
        .n-input-text_label {
            background-color: var(--color-white);
            opacity: 1;
            transform: translateY(-170%) translateX(-0.3rem);
            font-size: 0.75rem;
            line-height: 0.75rem;
            color: var(--color-dark-grey);
            visibility: visible;
        }
    }

    .n-radio-button-popover {
        visibility: hidden;
        position: absolute;
        opacity: 0;
        width: 100%;
        top: calc(100% + 1px);
        left: 0;
        transition: opacity .3s ease-in-out;
        position: absolute;
        background-color: var(--color-white);
        border-radius: 15px;
        padding: 15px 0;
        z-index: -1;
        opacity: 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .15);

        .radio {
            & input {
                &:hover {
                    box-shadow: inset 0 0 0 1px var(--color-black);
                }

                &:checked {
                    box-shadow: inset 0 0 0 6px var(--color-black);
                }
            }
        }
    }

    .n-text-field-cancellable {
        .n-action-add {
            margin-left: 30px;
        }
        .inline-name-form {
            padding: 0 30px;
            margin-top: 15px;

            .form__row {
                margin-bottom: 0;

                &:last-child {
                    .n-btn {
                        &:first-child {
                            color: var(--color-black);
                            font-weight: normal;
                        }

                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.n-action-add {
    font-weight: 600;
    flex-direction: row-reverse;
    padding-left: 0;
    .ic-icon {
        margin-left: 0;
        path {
            stroke: var(--color-bright-purple);
        }
    }
}